import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Property from 'models/properties/Property';
import User from 'models/users/User';

@Model()
class PropertyTransfer extends ApplicationRecord {
  static jsonapiType = 'property_transfers';

  @Attr() status: string;
  @Attr() receivingLandlordEmail: string;

  @Attr() senderSignature: string;
  @Attr() receiverSignature: string;

  @Attr() receiverIsNewLandlord: boolean;

  @Attr() propertyId: string;
  @Attr({persist: false}) receivingUserId: string;
  @Attr({persist: false}) sendingUserId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('properties') property: Property;
  @BelongsTo('users') sendingUser: User;
  @BelongsTo('users') receivingUser: User;
}

export default PropertyTransfer;
