import React from 'react';

import InspectionAction from 'models/inspections/InspectionAction';
import {titleize} from 'utilities/StringHelpers';

const Action = ({
  action,
  onClick,
}: {
  action: InspectionAction;
  onClick: () => void;
}) => (
  <a
    className="mt-2 flex flex-col border-l-4 border-warning w-full cursor-pointer"
    onClick={onClick}>
    <div className="bg-orange-100 pl-2 pb-1 rounded-r-lg">
      <strong className="text-sm text-warning">
        {titleize(action.actionType)} Action
      </strong>
      <p>{action.action}</p>
    </div>
  </a>
);

export default Action;
