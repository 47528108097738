import {HttpError} from 'components_sb/miscellaneous';

const UnauthorisedPage = (): JSX.Element => (
  <HttpError
    code={401}
    title="Unauthorised"
    description="You are not authorised to view this page. If you believe this has
    happened in error, please refresh the page or try logging out and
    back in."
  />
);

export default UnauthorisedPage;
