import {useMemo} from 'react';

import moment from 'moment';
import {HiOutlineDocumentDuplicate} from 'react-icons/hi';
import {MdEditDocument} from 'react-icons/md';

import {Card} from 'components_sb/layout';
import {RenewalStatusCard} from 'components/tenancy/RenewalStatusCard';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {Action} from 'types/actions';
import {DATE_FORMAT} from 'utilities/DateHelpers';

const TenancyRenewalCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const cardActions = useMemo<Action[]>(
    () =>
      tenancy && tenancy.activeTenancyContinuation
        ? [
            {
              label: 'Choose renewal option',
              icon: HiOutlineDocumentDuplicate,
              linkTo: `tenancies/${tenancy.id}/tenancy-renewals/${tenancy.activeTenancyContinuation.id}/edit`,
            },
          ]
        : [],
    [tenancy],
  );

  if (property.tenanciesCount === 0) {
    return null;
  }
  if (!tenancy) {
    return null;
  }

  if (!tenancy.isActive) {
    return null;
  }

  if (tenancy.activeTenancyContinuation) {
    const renewal = tenancy.activeTenancyContinuation;
    const endDate = tenancy.endDate;
    if (!renewal.landlordRequestedAction) {
      return (
        <Card
          title="Renewal Options"
          className="mt-8 border-t-3 border-info"
          actions={cardActions}
          icon={MdEditDocument}>
          <p>
            Its time to choose what you would like to do when this tenancy ends
            on {moment(endDate).format(DATE_FORMAT)}. Click the button below to
            see your options.
          </p>
        </Card>
      );
    } else {
      return <RenewalStatusCard renewal={renewal} />;
    }
  }

  return null;
};

export default TenancyRenewalCard;
