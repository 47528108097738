import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import {errorsFor} from 'components/forms_fields/Helpers';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';
import TrackingService from 'services/TrackingService';

interface Base64FileFieldProps
  extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  formik: FormikProps<any>;
  labelProps?: FieldLabelProps;
}

const Base64FileField = ({
  formik,
  name,
  labelProps,
  ...rest
}: Base64FileFieldProps) => {
  let baseClass =
    'block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100';
  if (formik.errors[name]) {
    baseClass += ' border border-danger';
  }
  if (rest.className) {
    rest.className = baseClass + rest.className;
  } else {
    rest.className = baseClass;
  }
  const errors = errorsFor(formik, name);

  return (
    <>
      {labelProps && <FieldLabel {...labelProps} />}
      <input
        className="input"
        name={name}
        onChange={(e) => {
          const file = e.target.files[0];
          const reader = new FileReader();

          reader.onload = (readerEvent) => {
            const base64 = readerEvent.target.result.toString();
            formik.setFieldValue(name, base64);

            TrackingService.trackEvent(TrackingService.Event.SelectFile, {
              field: name,
            });
          };

          reader.readAsDataURL(file);
        }}
        type="file"
        {...rest}
      />
      <InlineError error={errors} />
    </>
  );
};

export default Base64FileField;
