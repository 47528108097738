import {HiOutlineSparkles} from '@react-icons/all-files/hi/HiOutlineSparkles';
import {useQuery} from 'react-query';

import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';

import BillingMethodSummary from './BillingMethodSummary';

interface SmartRentSummary {
  (props: {
    selectable?: boolean;
    selected?: boolean;
    onSelect?: () => void;
  }): JSX.Element;
}

const SmartRentSummary: SmartRentSummary = ({
  selectable,
  selected = false,
  onSelect = () => {
    ('');
  },
}) => {
  const {
    isLoading,
    isError,
    data: properties,
  } = useQuery(`fetch-smart-rent-properties`, async () => {
    const {data} = await Property.select({
      tenancies: ['id', 'street_address'],
    })
      .where({
        payment_method_type: 'rent',
      })
      .all();
    return data;
  });

  return (
    <BillingMethodSummary
      title="Keyhook Smart Rent"
      icon={HiOutlineSparkles}
      properties={properties}
      propertiesLoading={isLoading}
      propertiesError={isError}
      selectable={selectable}
      selected={selected}
      onSelect={onSelect}>
      <Paragraph disableMargin size="sm">
        Automatically deduct Keyhook fees from your rental income.
      </Paragraph>
    </BillingMethodSummary>
  );
};

export default SmartRentSummary;
