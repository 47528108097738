import * as Sentry from '@sentry/react';
import chalk from 'chalk';
import {sentenceCase} from 'change-case';
import {omit} from 'lodash';
import pluralize from 'pluralize';
import {toast} from 'react-toastify';
import {SpraypaintBase} from 'spraypaint';
import {type SaveOptions} from 'spraypaint/lib-esm/model';

import {TARGET_ENV} from 'globals/app-globals';

/**
 * Log the errors to the console if we're not in the production environement.
 * (i.e. in staging and development)
 */
const consoleLoggingEnabled = TARGET_ENV !== 'production';

/**
 * Log the errors to Sentry if we're not in the development environment.
 * (i.e. in staging and production)
 */
const sentryLoggingEnabled = TARGET_ENV !== 'development';

/**
 * Custom options that can be passed to the saveResource function.
 */
interface CustomSaveOptions {
  showSuccessToast?: boolean;
  showErrorToast?: boolean;
}

/**
 * Provides the same functionality as calling .save() on a Spraypaint resource
 * instance, but with the added benefit of contained handling for errors and
 * notifying the user of any issues.
 * @returns {boolean} Whether or not the resource was saved successfully.
 */
export const saveResource = async <T extends SpraypaintBase>(
  /**
   * The spraypaint resource instance to save.
   */
  resource: T,
  /**
   * Extend the base Spraypaint save options with our own additional options.
   */
  options?: SaveOptions<T> & CustomSaveOptions,
): Promise<boolean> => {
  /**
   * Attempt to save the resource.
   */
  let saved = false;
  try {
    saved = await resource.save(
      /**
       * Pass any Spraypaint options provided and exclude the custom options.
       */
      omit(options ?? {}, ['showSuccessToast', 'showErrorToast']),
    );
  } catch (error) {
    saved = false;
  }

  /**
   * Get the resource type.
   */
  const {type: resourceTypePlural} = resource.resourceIdentifier;

  /**
   * Convert the resource type from the JSON API plural form to the singular form.
   */
  const resourceTypeSingular = pluralize.singular(resourceTypePlural);

  /**
   * Convert the singular form to a format suitable for toast messages.
   */
  const resourceTypeReadable = resourceTypeSingular.replaceAll('_', ' ');

  /**
   * Handle resource successfully saved.
   */
  if (saved) {
    /**
     * Log the successful save to the console if enabled.
     */
    if (consoleLoggingEnabled) {
      const message =
        chalk.bold(chalk.magenta('[SPRAYPAINT]')) +
        chalk.green(`\nSuccessfully saved ${resourceTypeReadable} resource!`);
      console.log(message, {
        id: resource.id,
      });
    }

    /**
     * Notify the user the resource was saved successfully.
     * (will not notify by default if unspecified)
     */
    const showSuccessToast = options?.showSuccessToast ?? false;
    if (showSuccessToast) {
      toast.success(
        sentenceCase(`${resourceTypeReadable} successfully saved!`),
      );
    }

    /**
     * Return that the resource was saved successfully.
     */
    return true;
  }

  /**
   * Notify the user there was an issue saving.
   * (will not notify by default if unspecified)
   */
  const showErrorToast = options?.showErrorToast !== false;
  if (showErrorToast) {
    toast.error(
      sentenceCase(
        `Sorry, there was an issue saving your ${resourceTypeReadable}.`,
      ),
    );
  }

  /**
   * Get the full message for each of the errors.
   */
  const errorMessages = Object.values(resource.errors).reduce(
    (fullMessages, error) => [...fullMessages, error.fullMessage],
    [],
  );

  /**
   * Log the errors to the console if enabled.
   */
  if (consoleLoggingEnabled) {
    const message =
      chalk.bold(chalk.magenta('[SPRAYPAINT]')) +
      chalk.red(`\nFailed to save ${resourceTypeReadable} resource.`);
    console.error(
      message,
      {
        id: resource.id,
      },
      errorMessages,
    );
  }

  /**
   * Log the errors to Sentry if enabled.
   */
  if (sentryLoggingEnabled) {
    Sentry.withScope((scope) => {
      /**
       * Set relevant context on the error scope.
       */
      scope.setTag('action', 'save_resource');
      scope.setExtra('resourceType', resourceTypeSingular ?? 'N/A');
      scope.setExtra('resourceId', resource.id ?? 'N/A');
      /**
       * Log each of the error messages individually with the above scope context.
       */
      for (const errorMessage of errorMessages) {
        Sentry.captureException(new Error(errorMessage));
      }
    });
  }

  /**
   * Return that the resource failed to save.
   */
  return false;
};
