import {Attr, BelongsTo, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Inspection from 'models/inspections/Inspection';
import User from 'models/users/User';

@Model()
class InspectionSignOff extends ApplicationRecord {
  static jsonapiType = 'inspection_sign_offs';

  @Attr() accepted: boolean;
  @Attr() notes: string;

  @Attr() role: string;
  @Attr() signature: string;

  @Attr({persist: false}) wasAutoSigned: boolean;

  @Attr() inspectionId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('inspections') inspection: Inspection;
  @BelongsTo('users') user: User;
}

export default InspectionSignOff;
