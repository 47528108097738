import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import RentPayment from 'models/payments/RentPayment';
import TenancyMembership from 'models/properties/TenancyMembership';
import RenterProfile from 'models/users/RenterProfile';

@Model()
class LatePaymentNotification extends ApplicationRecord {
  static jsonapiType = 'late_payment_notifications';

  @Attr({persist: false}) notifiedOn: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr({persist: false}) rentPaymentId: string;
  @Attr({persist: false}) renterProfileId: string;
  @Attr({persist: false}) tenancyMembershipId: string;

  @BelongsTo('rent_payments') rentPayment: RentPayment;
  @BelongsTo('renter_profiles') renterProfile: RenterProfile;
  @BelongsTo('tenancy_memberships') tenancyMembership: TenancyMembership;
}

export default LatePaymentNotification;
