import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import clsx from 'clsx';
import {motion, AnimatePresence} from 'framer-motion';

import {SpinningLoader} from 'components_sb/feedback';

interface ContextValue {
  isActive: boolean;
  activate: () => void;
  deactivate: () => void;
}

const Context = createContext<ContextValue>({} as ContextValue);

export {Context as FullScreenLoaderContext};

interface ProviderProps {
  children: ReactNode;
}

const Provider: FunctionComponent<ProviderProps> = ({children}) => {
  const [isActive, setIsActive] = useState(false);
  const activate = useCallback(() => setIsActive(true), []);
  const deactivate = useCallback(() => setIsActive(false), []);

  return (
    <Context.Provider
      value={{
        isActive,
        activate,
        deactivate,
      }}>
      {children}
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            className={clsx(
              'fixed z-100 top-0 left-0',
              'w-screen h-screen',
              'flex items-center justify-center',
              'select-none',
            )}>
            {/* Background layer */}
            <div className="bg-white bg-opacity-50 w-full h-full absolute top-0 left-0" />
            {/* Loader */}
            <motion.div
              initial={{scale: 0}}
              animate={{scale: 1}}
              exit={{scale: 0}}
              className="p-12 bg-white drop-shadow-xl rounded-2xl">
              <SpinningLoader size="lg" color="brand" />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Context.Provider>
  );
};

export default {
  Provider,
};
