import React, {useMemo} from 'react';

import bankAccountValidator from '@fnzc/nz-bank-account-validator';
import {Form, Formik, FormikHelpers} from 'formik';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import {InlineError} from 'components_sb/feedback';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Tenancy from 'models/properties/Tenancy';
import RenterProfile from 'models/users/RenterProfile';
import NotFoundPage from 'pages/shared/errors/NotFoundPage';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {toCurrency} from 'utilities/StringHelpers';

type Signature = {
  date: string;
  klass: string;
  id: string;
  signature: string;
  name: string;
};

type FormValues = {
  tenantBondSplits: Record<string, number>;
  tenantSignatures: Record<string, Signature>;
  bankAccountName: string;
  bankAccountNumber: string;
};

const EditEndOfTenancyPage = () => {
  const {tenancyId} = useParams();

  const navigate = useNavigate();

  const {currentUser} = useAuth();

  const {data, isLoading, error} = useQuery(
    `tenancy-${tenancyId}-end-of-tenancy`,
    async () => {
      const tenancy = await Tenancy.includes([
        'end_of_tenancy',
        'property',
        {tenancy_memberships: 'renter'},
      ]).find(tenancyId);
      return tenancy.data;
    },
  );
  const {data: renterProfile, isLoading: renterProfileIsLoading} = useQuery(
    `user-${currentUser.id}-renter-profile`,
    async () => {
      const profile = await RenterProfile.where({
        userId: currentUser.id,
      }).first();
      return profile.data;
    },
  );

  const defaultSplits = useMemo(() => {
    if (data) {
      if (
        data.endOfTenancy.tenantBondSplits &&
        Object.keys(data.endOfTenancy.tenantBondSplits).length ==
          data.tenancyMemberships.length
      ) {
        return data.endOfTenancy.tenantBondSplits;
      } else {
        const defaultSplits = {} as Record<string, number>;
        data.tenancyMemberships.forEach((tm) => {
          defaultSplits[tm.id.toString()] = Number(tm.bondSplit || 0.0);
        });

        return defaultSplits;
      }
    } else {
      return {};
    }
  }, [data]);

  const isHeadTenant = useMemo(
    () => data && data.headTenantId === currentUser.id,
    [data, currentUser],
  );

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const attrs = {...values};

    if (!isHeadTenant) {
      delete attrs.tenantBondSplits;
    }

    const bankAccountName = attrs.bankAccountName;
    const bankAccountNumber = attrs.bankAccountNumber;
    delete attrs.bankAccountName;
    delete attrs.bankAccountNumber;

    renterProfile.assignAttributes({
      bankAccountName,
      bankAccountNumber,
    });
    const bankResult = await renterProfile.save();
    if (!bankResult) {
      for (const [key, value] of Object.entries(renterProfile.errors)) {
        actions.setFieldError(key, value.fullMessage);
      }
      actions.setSubmitting(false);
      return;
    }

    const eot = data.endOfTenancy;
    eot.assignAttributes(attrs);

    const result = await eot.save();

    if (result) {
      toast.success('Bond refund successfully signed');
      navigate(`/tenancies/${tenancyId}`, {replace: true});
    } else {
      toast.error('Bond refund could not be saved');
      for (const [key, value] of Object.entries(eot.errors)) {
        actions.setFieldError(key, value.fullMessage);
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading || renterProfileIsLoading) {
    return (
      <PageWrapper title="Bond Refund" backEnabled>
        <LoadingView />
      </PageWrapper>
    );
  } else if (!data.endOfTenancy) {
    return (
      <PageWrapper title="Bond Refund" backEnabled>
        <NotFoundPage />
      </PageWrapper>
    );
  } else {
    const showForm =
      isHeadTenant ||
      Object.keys(data.endOfTenancy.tenantBondSplits).length > 0;

    return (
      <PageWrapper title="Bond Refund" backEnabled>
        <Card title={`${data.property.streetAddress} bond refund`}>
          <Paragraph>
            Your landlord has specified that they wish to refund{' '}
            {toCurrency(data.endOfTenancy.bondRefundAmount)}, the total bond for
            this tenancy was {toCurrency(data.bond)}.{' '}
            {data.endOfTenancy.bondDisputeAmount > 0 &&
              'There is a bond dispute of ' +
                toCurrency(data.endOfTenancy.bondDisputeAmount) +
                ' which will be handled separately.'}
            {data.endOfTenancy.bondRefundReasons.length > 0 &&
              'These reasons have been specified: ' +
                data.endOfTenancy.bondRefundReasons.join(', ')}
          </Paragraph>

          {data.endOfTenancy.messageFromLandlord && (
            <div>
              <Paragraph>
                Your landlord has provided the following message for you:
              </Paragraph>
              <div className="italic">
                <Paragraph secondary>
                  {data.endOfTenancy.messageFromLandlord}
                </Paragraph>
              </div>
            </div>
          )}

          <Paragraph>
            If you are happy with this decision from your landlord, then please
            sign the form below. Otherwise, please contact your landlord
            directly through the chat to discuss. Once all tenants have signed,
            the bond refund form will automatically be sent to Tenancy Services
            for processing.
          </Paragraph>

          {!showForm && (
            <Paragraph>
              The head tenant has not yet specified how the bond should be
              split. Once they have done so, you will be able to sign the bond
              refund form.
            </Paragraph>
          )}

          {!isHeadTenant &&
            Object.keys(data.endOfTenancy.tenantBondSplits).length > 0 && (
              <div>
                <Paragraph>
                  The head tenant has specified the bond should be split as
                  such:
                </Paragraph>
                <div>
                  {data.tenancyMemberships.map((tm) => (
                    <div key={tm.id}>
                      <Paragraph>
                        {tm.renter.name} -{' '}
                        {toCurrency(
                          data.endOfTenancy.tenantBondSplits[tm.id.toString()],
                        )}
                      </Paragraph>
                    </div>
                  ))}
                </div>
              </div>
            )}

          {showForm && (
            <Formik
              initialValues={{
                tenantSignatures: {
                  [currentUser.id]: {
                    signature: '',
                    date: moment().format('YYYY-MM-DD'),
                    id: currentUser.id,
                    klass: 'User',
                    name: currentUser.name,
                  },
                },
                tenantBondSplits: defaultSplits,
                bankAccountName: renterProfile.bankAccountName || '',
                bankAccountNumber: renterProfile.bankAccountNumber || '',
              }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                tenantBondSplits: Yup.object().test(
                  'is-correct-amount',
                  'Bond splits must add up to the refund amount',
                  (value) => {
                    const total = Object.values(value).reduce(
                      (acc, curr) => acc + Number(curr),
                      0,
                    );
                    return total === Number(data.endOfTenancy.bondRefundAmount);
                  },
                ),
                tenantSignatures: Yup.object().test(
                  'is-signed',
                  'Please sign your signature',
                  (value) => {
                    const sig = value[currentUser.id] as Signature;
                    return sig && sig.signature && sig.signature.length > 0;
                  },
                ),
                bankAccountName: Yup.string()
                  .min(2)
                  .required()
                  .label('Account Name'),
                bankAccountNumber: Yup.string()
                  .required()
                  .test(
                    'is-valid-format',
                    'Must be a valid NZ account. Please include dashes.',
                    (value) => {
                      if (!value) return false;

                      const parts = value.split('-');
                      if (parts.length === 4) {
                        return bankAccountValidator.isValidNZBankNumber(
                          parts[0],
                          parts[1],
                          parts[2],
                          parts[3],
                        );
                      } else {
                        return false;
                      }
                    },
                  )
                  .label('Account Number'),
              })}>
              {(formik) => (
                <Form>
                  {isHeadTenant && (
                    <div className="my-2">
                      <strong>
                        Please specify how the bond should be split between
                        tenants.
                      </strong>
                      <Paragraph secondary size="sm">
                        If your tenancy was started through Keyhook, the
                        original amounts of bond each tenant paid will
                        automatically be displayed. However these may need to be
                        adjusted if your landlord is claiming part of the bond.
                      </Paragraph>
                      {data.tenancyMemberships.map((tm) => (
                        <div key={tm.id} className="mt-2">
                          <label className="label flex justify-start items-center">
                            <span className="label-text mr-2">
                              {tm.renter.name}
                            </span>
                          </label>
                          <input
                            type="number"
                            step="0.01"
                            value={formik.values.tenantBondSplits[tm.id]}
                            className="input input-bordered w-full"
                            onChange={(e) => {
                              const splits = formik.values.tenantBondSplits;
                              splits[tm.id] = Number(e.target.value);
                              formik.setFieldValue('tenantBondSplits', splits);
                            }}
                          />
                        </div>
                      ))}

                      <InlineError
                        name="tenantBondSplits"
                        error={formik.errors.tenantBondSplits}
                      />
                    </div>
                  )}

                  <div className="my-4">
                    <strong>Bank Account Information</strong>
                    <Paragraph>
                      This is the account that your refund will be paid into.
                    </Paragraph>

                    <InputField
                      labelProps={{title: 'Account Name'}}
                      name="bankAccountName"
                      formik={formik}
                      placeholder="eg: John Smith"
                      autoCapitalize="words"
                    />
                    <InputField
                      labelProps={{title: 'Account Number'}}
                      name="bankAccountNumber"
                      formik={formik}
                      placeholder="eg: 02-1234-5678900-00 include dashes"
                    />
                  </div>

                  <div>
                    <SignatureModalInput
                      mode="manual"
                      name="tenantSignatures"
                      signature={
                        formik.values.tenantSignatures[currentUser.id].signature
                      }
                      onSave={(signatureString) => {
                        const signature =
                          formik.values.tenantSignatures[currentUser.id];

                        signature.signature = signatureString;

                        formik.setFieldValue('tenantSignatures', {
                          [currentUser.id]: signature,
                        });
                      }}
                    />
                    <InlineError
                      name="tenantSignatures"
                      error={formik.errors.tenantSignatures}
                    />
                  </div>

                  <div className="mt-8">
                    <SubmitButton
                      formik={formik}
                      text="Save and submit"
                      submittingText="Saving"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Card>
      </PageWrapper>
    );
  }
};

export default EditEndOfTenancyPage;
