import {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {IconType} from '@react-icons/all-files';
import {HiCheckCircle} from '@react-icons/all-files/hi/HiCheckCircle';
import clsx from 'clsx';
import {FormikProps, useFormik} from 'formik';
import {useResizeDetector} from 'react-resize-detector';

import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';
import {Action} from 'types/actions';

/**
 * The object shape of the options that are provided to
 * the GridSelect component.
 */
export interface GridSelectOption {
  id: string;
  label: string;
  description?: string;
  icon?: string | IconType;
}

/**
 * A selectable option within the grid.
 */
const GridSelectOption = ({
  label,
  description,
  icon: Icon,
  size,
  selected,
  disableTicks,
  onClick,
}: GridSelectOption & {
  size: 'sm' | 'base' | 'lg';
  selected: boolean;
  disableTicks: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}) => (
  <button
    type="button"
    className={clsx(
      'relative flex flex-row justify-center items-center',
      'border-2 flex-1 cursor-pointer',
      'rounded-xl',
      'transition-all duration-200',
      'scale-100',
      'active:scale-95',
      'ring-0',
      'focus:ring-2',
      size === 'sm' && 'gap-x-2 p-2',
      size === 'base' && 'gap-x-2 p-3',
      size === 'lg' && 'gap-x-4 p-6',
      selected
        ? clsx(
            'text-brand-500',
            'border-brand-500 hover:border-brand-600 active:border-brand-500',
            'bg-transparent hover:bg-brand-100 hover:bg-opacity-20',
          )
        : clsx(
            'text-brand-850',
            'border-brand-75',
            'bg-white hover:bg-brand-25 active:bg-white',
          ),
    )}
    onClick={onClick}>
    {/* Selected indicator */}
    {!disableTicks && (
      <HiCheckCircle
        className={clsx(
          'absolute text-green-600',
          'transition duration-300',
          ['sm', 'base'].includes(size) && 'w-5 h-5 top-1 right-1',
          size === 'lg' && 'w-6 h-6 top-2 right-2',
          selected ? 'scale-100' : 'scale-0',
        )}
      />
    )}
    {/* Icon */}
    {Icon && (
      <div className="flex-shrink-0">
        {typeof Icon === 'string' ? (
          <img
            src={Icon}
            alt={label}
            className={clsx(
              ['sm', 'base'].includes(size) && 'w-6 h-6',
              size === 'lg' && 'w-10 h-10',
            )}
          />
        ) : (
          <Icon
            className={clsx(
              'transition-all duration-200',
              selected ? 'text-brand-500' : 'text-brand-850',
              ['sm', 'base'].includes(size) && 'w-6 h-6',
              size === 'lg' && 'w-10 h-10',
            )}
          />
        )}
      </div>
    )}
    {/* Text */}
    <div
      className={clsx(
        'text-left flex flex-col gap-y-1',
        'transition-all duration-200',
        selected ? 'text-brand-500' : 'text-brand-850',
      )}>
      <div
        className={clsx(
          'font-medium leading-snug',
          size === 'sm' && 'text-sm',
          size === 'base' && 'text-base',
          size === 'lg' && 'text-lg',
        )}>
        {label}
      </div>
      {description && (
        <p className={clsx('text-xs opacity-70')}>{description}</p>
      )}
    </div>
  </button>
);

/**
 * The type of value for single choice.
 */
type SingleChoiceValue = string | null;

/**
 * The type of value for multiple choice.
 */
type MultipleChoiceValue = string[];

/**
 * Condtional props for the GridSelect component when in manual select
 * mode, based on whether multiple choice select is also enabled.
 */
type GridSelectManualSelectModeConditionalProps =
  /**
   * Single choice select.
   */
  | {
      options: GridSelectOption[];
      actions?: never;
      name?: string;
      multiple: false;
      value: SingleChoiceValue;
      onChange: (value: SingleChoiceValue) => void;
      onClick?: never;
      // allowCustom?: boolean; // TODO
      form?: never;
    }
  /**
   * Multiple choice select.
   */
  | {
      options: GridSelectOption[];
      actions?: never;
      name?: string;
      multiple: true;
      value: MultipleChoiceValue;
      onChange: (value: MultipleChoiceValue) => void;
      onClick?: never;
      // allowCustom?: boolean; // TODO
      form?: never;
    };

/**
 * Props for the GridSelect component when in manual button mode.
 */
type GridSelectManualButtonsModeProps = {
  options: GridSelectOption[];
  actions?: never;
  name?: never;
  multiple?: never;
  value?: never;
  onChange?: never;
  onClick?: (value: SingleChoiceValue) => void;
  // allowCustom?: boolean; // TODO
  form?: never;
};

/**
 * Props for the GridSelect component when in actions mode.
 */
type GridSelectActionsModeProps = {
  options?: never;
  actions: Action[];
  name?: never;
  multiple?: never;
  value?: never;
  onChange?: never;
  onClick?: (value: SingleChoiceValue) => void;
  // allowCustom?: never; // TODO
  form?: never;
};

/**
 * Props for the GridSelect component when in Formik mode.
 */
type GridSelectFormikModeProps =
  /**
   * Both single and multiple choice select.
   */
  {
    options: GridSelectOption[];
    actions?: never;
    name: string;
    multiple: true | false;
    value?: never;
    onChange?: never;
    onClick?: never;
    allowCustom?: boolean;
    form: ReturnType<typeof useFormik> | FormikProps<any>;
  };

type GridSelectModeProps =
  | ({
      mode: 'manual:select';
    } & GridSelectManualSelectModeConditionalProps)
  | ({
      mode: 'manual:buttons';
    } & GridSelectManualButtonsModeProps)
  | ({
      mode: 'actions';
    } & GridSelectActionsModeProps)
  | ({
      mode: 'formik';
    } & GridSelectFormikModeProps);

/**
 * Props for the GridSelect component itself.
 */
export type GridSelectProps = GridSelectModeProps & {
  minColumnWidth?: number;
  // maxColumnWidth?: number; // TODO
  minColumns?: number;
  maxColumns?: number;
  size?: 'sm' | 'base' | 'lg';

  /**
   * Disables the green ticks that appear for selected items.
   */
  disableTicks?: boolean;

  /**
   * Ensures that there will always be a full row of options.
   * (e.g. 3 options in a 2 x 2 grid layout will insted display as 1 x 4)
   * NOTE: This will override the minColumns and maxColumns props
   * if necessary.
   */
  preventEmptyCells?: boolean;

  /**
   * Properties to pass to the FieldLabel component.
   */
  labelProps?: FieldLabelProps;
};

type GridSelectComponent = FunctionComponent<GridSelectProps>;

/**
 * A select input in the format of cards within a grid layout.
 */
const GridSelect: GridSelectComponent = ({
  name,
  mode,
  options,
  // allowCustom = false, // TODO
  multiple,
  minColumnWidth = 150,
  // maxColumnWidth = Infinity, // TODO
  minColumns = 1,
  maxColumns = Infinity,
  size = 'base',
  disableTicks = false,
  preventEmptyCells = false,
  labelProps,
  /**
   * We leave some props to be deconstructed later since
   * there are props conditional to the mode.
   */
  ...props
}) => {
  /**
   * Where the value is retrieved from depends on the mode.
   */
  const value = useMemo(
    () => (mode === 'formik' ? props.form.values[name] : props.value),
    [mode, props.form, props.value, name],
  );

  /**
   * Handle clicking on one of the options.
   */
  const onClickOption = useCallback(
    (id: string) => {
      /**
       * Handle 'non-select' based modes (i.e. button mode).
       */
      if (mode === 'manual:buttons') {
        props.onClick(id);
        return;
      }

      /**
       * Determine the change handler to use for 'select' based modes.
       */
      let handleChange;
      if (mode === 'formik') {
        handleChange = (newValue: any) => {
          props.form.setFieldValue(name, newValue);
        };
      } else if (mode === 'manual:select') {
        handleChange = (newValue: any) => props.onChange(newValue);
      }

      /**
       * When multiple choice is enabled, selecting an option will toggle it.
       */
      if (multiple === true) {
        handleChange(
          value?.includes(id)
            ? (value as string[]).filter((item) => item !== id)
            : [...(value ?? []), id],
        );
      } else if (multiple === false) {
        /**
         * When single choice is enabled, selecting an option will replace the
         * current option, or have no effect if the option is already selected.
         */
        if (value !== id) {
          handleChange(id);
        }
      }
    },
    [multiple, props, value, mode, name],
  );

  const [initialised, setInitialised] = useState(false);

  const {width: containerWidth, ref: containerRef} = useResizeDetector({
    handleWidth: true,
    handleHeight: false,
  });

  /**
   * Evaluate the number of columns based on the available width.
   * If no minimum column width has been specified, default to
   * only using one column.
   */
  const columns = useMemo(() => {
    if (containerWidth !== undefined) {
      if (!initialised) {
        setInitialised(true);
      }
      /**
       * Evalute the number of columns.
       */
      let result = containerWidth / minColumnWidth;

      /**
       * Round the evaluated number down to the nearest integer.
       */
      result = Math.floor(result);

      /**
       * If the rounded number of columns is less than 1, default to 1.
       */
      result = Math.max(result, 1);

      /**
       * Ensure the evaluated number of columns does not exceed
       * the number of options available.
       */
      if (result > options.length) {
        result = Math.min(result, options.length);
      }

      /**
       * Ensure there are at least the minimum number of columns if specified.
       */
      result = Math.max(result, minColumns);

      /**
       * Limit the number of columns to the maximum if specified.
       */
      result = Math.min(result, maxColumns);

      /**
       * If the preventEmptyCells prop is enabled, ensure that the resulting
       * number of columns will not cause empty cells.
       */
      if (preventEmptyCells) {
        /**
         * Reduce the number of columns until there will be no empty cells (based
         * on the number of options provided and the number of columns evaluated).
         */
        while (options.length % result) {
          result -= 1;
        }
      }

      /**
       * Return the final evaluated number of columns.
       */
      return result;
    }
    return null;
  }, [
    containerWidth,
    options,
    minColumns,
    maxColumns,
    initialised,
    minColumnWidth,
    preventEmptyCells,
  ]);

  return (
    <div className="w-full flex-1 flex flex-col">
      {labelProps && <FieldLabel {...labelProps} />}
      <div
        ref={containerRef}
        className={clsx('grid gap-4 grid-col')}
        style={{
          gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
        }}>
        {initialised &&
          options.map((option) => (
            <GridSelectOption
              {...option}
              key={option.id}
              selected={
                mode === 'manual:buttons' ||
                (multiple
                  ? !!value && !!value?.includes(option.id)
                  : value === option.id)
              }
              size={size}
              disableTicks={disableTicks || mode === 'manual:buttons'}
              onClick={() => onClickOption(option.id)}
            />
          ))}
      </div>
    </div>
  );
};

export default GridSelect;
