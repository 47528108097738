import {Model, Attr} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';

@Model()
class SearchLocation extends ApplicationRecord {
  static jsonapiType = 'search_locations';

  @Attr() suburb: string | null;
  @Attr() city: string;
  @Attr() country: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;
}

export default SearchLocation;
