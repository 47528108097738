import {useState} from 'react';

import {Browser} from '@capacitor/browser';
import {XIcon} from '@heroicons/react/outline';
import {FileIcon, defaultStyles} from 'react-file-icon';

import {Button} from 'components_sb/buttons';
import {Divider, ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import AccountingReceipt from 'models/accounting/AccountingReceipt';
import AccountingRecord from 'models/accounting/AccountingRecord';

const AccountingRecordDetailModal: ModalDefinition = {
  title: 'Transaction Details',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [],
  },
  ContentComponent: (props) => {
    const {accountingRecord} = props;
    const [deleteingId, setDeleteingId] = useState('-1');

    const [model, setModel] = useState<AccountingRecord>(accountingRecord);

    const confirmDeleteReceipt = async (receipt: AccountingReceipt) => {
      setDeleteingId(receipt.id);
    };

    const deleteReceipt = async (receipt: AccountingReceipt) => {
      const result = await receipt.destroy();

      if (result) {
        model.accountingReceiptsCount -= 1;
        model.accountingReceipts = model.accountingReceipts.filter(
          (r) => r.id !== receipt.id,
        );

        setDeleteingId('-1');
        setModel(model);
      }
    };

    const openReceipt = async (receipt: AccountingReceipt) => {
      await Browser.open({url: receipt.attachment});
    };

    return (
      <>
        <div>
          <strong>Notes</strong>

          <Paragraph>{model.notes}</Paragraph>
          {!model.notes && (
            <Paragraph>
              You have not added any notes for this transaction
            </Paragraph>
          )}
        </div>

        <div className="mt-2">
          <strong>Receipts</strong>
          {model.accountingReceiptsCount === 0 && (
            <Paragraph>
              You have not added any receipts for this transaction
            </Paragraph>
          )}

          {model.accountingReceipts.map((receipt) => {
            const filename = receipt.metadata.filename;
            const extension = filename?.split('.').pop();
            return (
              <div key={receipt.id}>
                <div className="mt-2 flex justify-between items-center">
                  <div className="flex justify-start items-center gap-4">
                    <div
                      className="h-10 w-10 cursor-pointer"
                      onClick={() => openReceipt(receipt)}>
                      <FileIcon
                        extension={extension}
                        {...defaultStyles[
                          extension as keyof typeof defaultStyles
                        ]}
                      />
                    </div>
                    <div className="mt-2">
                      <a
                        className="link link-primary"
                        onClick={() => openReceipt(receipt)}>
                        {filename}
                      </a>

                      {deleteingId === receipt.id && (
                        <div className="mt-2">
                          <Paragraph>
                            Are you sure you want to delete this receipt?
                          </Paragraph>

                          <Button
                            mode="manual"
                            onClick={() => deleteReceipt(receipt)}
                            label="Delete"
                            category="danger"
                            size="sm"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <a
                    className="cursor-pointer"
                    onClick={() => confirmDeleteReceipt(receipt)}>
                    <XIcon className="w-4 h-4" />
                  </a>
                </div>

                <Divider />
              </div>
            );
          })}
        </div>
      </>
    );
  },
};

export default AccountingRecordDetailModal;
