import clsx from 'clsx';

import {removeUnderscores, titleize} from 'utilities/StringHelpers';

interface Tags {
  (props: {children: string[]}): JSX.Element;
}

/**
 * A series of words displayed as tags/chips.
 */
const Tags: Tags = ({children: tags}) => {
  return (
    tags &&
    tags.length && (
      <div className="flex flex-row flex-wrap gap-2">
        {tags.map((tag) =>
          // Ensure the tag is a valid string
          !tag || typeof tag !== 'string' ? null : (
            <div
              key={tag}
              className={clsx(
                'rounded-full  bg-transparent',
                'leading flex items-center',
                'px-3 py-1',
                'text-xs font-semibold text-brand-300',
                'border-2 border-brand-300',
                'uppercase',
                'opacity-70',
                'leading-none',
              )}>
              {titleize(removeUnderscores(tag))}
            </div>
          ),
        )}
      </div>
    )
  );
};
export default Tags;
