import {FunctionComponent, useMemo} from 'react';

import clsx from 'clsx';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';

import {HeadData} from '../data-types';

interface HeadProps {
  data: HeadData;
  hasConcealedColumns: boolean;
  concealedColumnIndexes: number[];
}

type HeadComponent = FunctionComponent<HeadProps>;

const Head: HeadComponent = ({
  data,
  hasConcealedColumns,
  concealedColumnIndexes,
}) => {
  const primaryColumns = useMemo(
    () =>
      data.columns.filter(
        (column) => !concealedColumnIndexes.includes(column.index),
      ),
    [data, concealedColumnIndexes],
  );
  return (
    <thead className="bg-brand-50">
      <tr>
        {primaryColumns.map((column) => (
          <th
            key={column.index}
            style={{
              minWidth: column.minWidth ?? 0,
            }}
            className={clsx(
              'text-sm sm:text-base',
              'py-4 px-6 text-left font-semibold text-brand-850 whitespace-nowrap',
            )}>
            <span>{column.children}</span>
            <HelpTextPresenter>{column.helpText}</HelpTextPresenter>
          </th>
        ))}
        {hasConcealedColumns && <th className="w-[62px] sm:w-28" />}
      </tr>
    </thead>
  );
};

export default Head;
