import {ElementType} from 'react';

import {type FormikProps} from 'formik';
import Toggle from 'react-toggle';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';

const ToggleField = ({
  formik,
  label,
  name,
  helpText,
  ...rest
}: {
  formik: FormikProps<any>;
  name: string;
  label: string | ElementType;
  id?: string;
  helpText?: string;
}) => {
  let id;
  if (rest.id) {
    id = rest.id;
  } else {
    id = name;
  }

  const handleChange = () => {
    formik.setFieldValue(name, !formik.values[name]);
    TrackingService.trackEvent(TrackingService.Event.ToggleOption, {
      field: name,
    });
  };

  return (
    <div className="form-control">
      <label className="cursor-pointer label">
        <div className="label-text">
          <span>{`${label} `}</span>
          <HelpTextPresenter>{helpText}</HelpTextPresenter>
        </div>
        <Toggle
          {...rest}
          id={id}
          defaultChecked={formik.values[name]}
          onChange={handleChange}
        />
      </label>
      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default ToggleField;
