import {isMobile} from 'react-device-detect';

import {Paragraph} from 'components_sb/typography';
import {Tour} from 'providers/ToursProvider';

const LandlordWelcome: Tour = {
  id: 'landlord_welcome',
  steps: [
    {
      selector: "[data-tour='page-header']",
      stepInteraction: false,
      disableActions: false,
      content: (
        <>
          <Paragraph>This is your property dashboard.</Paragraph>
          <Paragraph secondary>
            Here you'll have an overview of your entire portfolio and any
            actions that need your attention.
          </Paragraph>
        </>
      ),
    },
    // {
    //   selector: "[data-tour='demo-property-item']",
    //   stepInteraction: false,
    //   disableActions: false,
    //   content: (
    //     <>
    //       <Paragraph>Here's an example property.</Paragraph>
    //       <Paragraph secondary>
    //         Once you've added your own property later, it will appear here like
    //         this.
    //       </Paragraph>
    //     </>
    //   ),
    // },
    // {
    //   selector: "[data-tour='demo-property-item-stats']",
    //   stepInteraction: false,
    //   disableActions: false,
    //   content: (
    //     <>
    //       <Paragraph>
    //         For each property you add, you can see a quick summary of the
    //         important tenancy details.
    //       </Paragraph>
    //     </>
    //   ),
    // },
    {
      selector: "[data-tour='demo-property-item']",
      stepInteraction: true,
      disableActions: true,
      // Show the finger tap animation when entering step
      action: () => {
        const fingerTapElement = document.getElementById(
          'demo-card-finger-tap',
        );
        if (fingerTapElement) {
          fingerTapElement.style.display = 'flex';
        }
      },
      // Hide the finger tap animation when leaving step
      actionAfter: () => {
        const fingerTapElement = document.getElementById(
          'demo-card-finger-tap',
        );
        if (fingerTapElement) {
          fingerTapElement.style.display = 'none';
        }
      },
      content: (
        <>
          <Paragraph>
            {`${
              isMobile ? 'Tap' : 'Click'
            } your example property to keep exploring!`}
          </Paragraph>
        </>
      ),
    },
    {
      selector: "[data-tour='demo-property-card']",
      stepInteraction: false,
      disableActions: false,
      content: (
        <>
          <Paragraph>Welcome to your demo property!</Paragraph>
        </>
      ),
    },
    {
      selector: "[data-tour='demo-action-banners']",
      stepInteraction: false,
      disableActions: false,
      content: (
        <>
          <Paragraph>
            Important action items appear at the top of your property page.
          </Paragraph>
        </>
      ),
    },
    {
      selector: "[data-tour='demo-inspections-card']",
      stepInteraction: false,
      disableActions: false,
      content: (
        <>
          <Paragraph>
            You have one recently completed inspection to view.
          </Paragraph>
        </>
      ),
    },
    {
      selector: "[data-tour='demo-maintenance-requests-card']",
      stepInteraction: false,
      disableActions: false,
      content: (
        <>
          <Paragraph>
            Both you and your tenants can submit maintenance requests to
            Keyhook's maintenance team. It looks like you have two requests to
            view.
          </Paragraph>
        </>
      ),
    },
    {
      selector: "[data-tour='demo-property-card']",
      stepInteraction: false,
      disableActions: false,
      content: (
        <>
          <Paragraph>
            Have a play around with the example property yourself!
          </Paragraph>
          <Paragraph secondary>
            {`When you're ready to get started, ${
              isMobile ? 'tap' : 'click'
            } the button at the bottom of the screen to set up your own property.`}
          </Paragraph>
        </>
      ),
    },
  ],
};

export default LandlordWelcome;
