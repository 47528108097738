import {useCallback, useMemo} from 'react';

import {MinusIcon, PlusIcon} from '@heroicons/react/outline';
import clsx from 'clsx';
import {type FormikProps} from 'formik';

import {errorsFor} from 'components/forms_fields/Helpers';
import {Button} from 'components_sb/buttons';
import {InlineError} from 'components_sb/feedback';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';

interface CountableNumberFieldProps {
  form: FormikProps<any>;
  labelProps: FieldLabelProps;
  name: string;
  incrementValue?: number;
  align?: 'left' | 'center';
}

const CountableNumberField = ({
  form,
  labelProps,
  name,
  incrementValue = 1,
  align = 'left',
}: CountableNumberFieldProps) => {
  const errors = errorsFor(form, name);

  /**
   * Get the current value for the field from the form instance.
   */
  const value = useMemo(() => form.values[name], [form, name]);

  /**
   * Handling incrementing the value.
   */
  const handleClickIncrement = useCallback(() => {
    const currentValue = form.values[name];
    if (currentValue) {
      form.setFieldValue(name, currentValue + incrementValue);
    } else {
      form.setFieldValue(name, incrementValue);
    }
  }, [form, name, incrementValue]);

  /**
   * Handling decrementing the value.
   */
  const handleClickDecrement = useCallback(() => {
    const currentValue = form.values[name];
    if (currentValue) {
      form.setFieldValue(name, Math.max(0, currentValue - incrementValue));
    } else {
      form.setFieldValue(name, 0);
    }
  }, [form, name, incrementValue]);

  return (
    <div
      className={clsx(
        'flex flex-col gap-y-2 w-full',
        align === 'left' && 'items-start',
        align === 'center' && 'items-center',
      )}>
      {labelProps && <FieldLabel {...labelProps} />}
      <div className="flex items-center justify-between w-44">
        <Button
          category="secondary"
          size="base"
          format="icon"
          icon={MinusIcon}
          mode="manual"
          onClick={handleClickDecrement}
        />
        <span className="inline-block mx-4 text-lg text-brand-850">
          {value}
        </span>
        <Button
          category="secondary"
          size="base"
          format="icon"
          icon={PlusIcon}
          mode="manual"
          onClick={handleClickIncrement}
        />
      </div>
      <InlineError error={errors} />
    </div>
  );
};

export default CountableNumberField;
