import {useState, useEffect, useCallback} from 'react';

import {useLocalStorage} from 'usehooks-ts';

import useAuth, {AccountRole} from 'services/useAuth';

type ActiveAccountRole = AccountRole | null;

type UserSettings = {
  [key: string]: any; // Allow any other properties
  activeAccountRole?: ActiveAccountRole | undefined;
};

const useLocalUserSettings = (): UserSettings => {
  const {userIsLoggedIn, currentUser} = useAuth();

  /**
   * Store the user settings in local storage.
   */
  const [userSettings, setUserSettings] = useLocalStorage<UserSettings>(
    'user_settings',
    {},
  );

  /**
   * Update the locally stored user settings by merging the provided
   * settings with the existing settings.
   */
  const updateUserSettings = useCallback(
    (newSettings: UserSettings) => {
      const updatedSettings = {...userSettings, ...newSettings};
      setUserSettings(updatedSettings);
    },
    [userSettings, setUserSettings],
  );

  /**
   * Auto select a role from the current user's roles, or clear
   * the role if there is no user logged in.
   */
  useEffect(() => {
    if (userIsLoggedIn) {
      const firstRole =
        currentUser.roles && currentUser.roles.length > 0
          ? currentUser.roles[0]
          : null;
      if (firstRole && userSettings.activeAccountRole !== firstRole) {
        updateUserSettings({activeAccountRole: firstRole});
      }
    } else if (userSettings.activeAccountRole) {
      /**
       * Clear the active account role if the user is not logged in
       * but there is an active account role set
       */
      updateUserSettings({activeAccountRole: undefined});
    }
  }, [
    userIsLoggedIn,
    currentUser,
    userSettings.activeAccountRole,
    updateUserSettings,
  ]);

  /**
   * A helper function to directly set the current user's active
   * account role.
   */
  const setActiveAccountRole = (role: AccountRole) => {
    const updatedSettings = {...userSettings, activeAccountRole: role};
    updateUserSettings(updatedSettings);
  };
  return {...userSettings, setActiveAccountRole};
};

export default useLocalUserSettings;
