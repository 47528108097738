import {Fragment, useCallback, useEffect, useMemo, useState} from 'react';

import {paramCase} from 'change-case';
import {pick} from 'lodash';
import flattenChildren from 'react-flatten-children';
import {useResizeDetector} from 'react-resize-detector';

import {HeadData, RowData, RowGroup} from './data-types';
import Head from './Head/Head';
import PseudoHead, {
  elementIsHeadElement,
  type PseudoHeadElement,
  type PseudoHeadInputElement,
} from './Head/PseudoHead';
import PseudoHeadItem from './Head/PseudoHeadItem';
import PseudoRow, {
  elementIsRowElement,
  type PseudoRowElement,
  type PseudoRowInputElement,
} from './Row/PseudoRow';
import PseudoRowItem from './Row/PseudoRowItem';
import PseudoRowSeparator, {
  elementIsRowSeparatorElement,
  PseudoRowSeparatorElement,
  PseudoRowSeparatorInputElement,
} from './Row/PseudoRowSeparator';
import Row from './Row/Row';
import RowSeparator from './Row/RowSeparator';

type PseudoTableInput = {
  /**
   * Require a Head element and a minimum of one Row element.
   */
  0: PseudoHeadInputElement;
  1: PseudoRowInputElement | PseudoRowSeparatorInputElement;
  /**
   * Require the first element to be a Head element, and any following
   * elements to be Row elements.
   */
} & [PseudoHeadInputElement, ...PseudoRowInputElement[]];

type DefragmentedTableChildren = Array<PseudoHeadElement | PseudoRowElement>;

interface TableProps {
  children: PseudoTableInput;
}

/**
 * Flexible and responsive custom table component.
 */
const Table = ({children}: TableProps) => {
  const defragmentedChildren = useMemo<DefragmentedTableChildren>(
    () => flattenChildren(children) as DefragmentedTableChildren,
    [children],
  );

  /**
   * The head element will always be the first child.
   */
  const pseudoHeadElement = useMemo<PseudoHeadElement>(() => {
    const element = defragmentedChildren[0];
    if (elementIsHeadElement(element)) {
      return element;
    } else {
      throw new Error(
        'First child element of Table component is not a valid TableHead component.',
      );
    }
  }, [defragmentedChildren]);

  /**
   * The row elements will always be the children following
   * the head element.
   */
  const pseudoRowElements = useMemo<
    Array<PseudoRowElement | PseudoRowSeparatorElement>
  >(() => {
    const elements = defragmentedChildren.slice(1, defragmentedChildren.length);

    /**
     * Ensure that all row elements are valid.
     */
    if (
      elements.some(
        (element) =>
          !elementIsRowElement(element) &&
          !elementIsRowSeparatorElement(element),
      )
    ) {
      throw new Error(
        'Non-initial child element of Table component is not a valid PseudoRow component.',
      );
    }

    return elements;
  }, [defragmentedChildren]);

  /**
   * Get the props from each of the head item elements.
   */
  const headData = useMemo<HeadData>(
    () => ({
      columns: (pseudoHeadElement as PseudoHeadElement).props.children.map(
        (child, index) => ({
          index,
          ...child.props,
        }),
      ),
    }),
    [pseudoHeadElement],
  );

  /**
   * Parse the pseudo row input elements into data for rendering the rows.
   * - Detect any row separators and group following rows by the separator.
   * - Get the props from each of the row item elements within each of the row elements.
   */
  const rowsData = useMemo<RowData[]>(() => {
    let currentRowGroup: RowGroup | null = null;
    return pseudoRowElements.reduce((data, pseudoRowElement, index) => {
      if (elementIsRowSeparatorElement(pseudoRowElement)) {
        currentRowGroup = {
          /**
           * Group ID is converted to param-case if not already
           * adhering to param-case.
           */
          id: paramCase(pseudoRowElement.props.id),
          title: pseudoRowElement.props.title,
        };
        return data;
      } else if (elementIsRowElement(pseudoRowElement)) {
        return [
          ...data,
          {
            index,
            group: currentRowGroup,
            columns: pseudoRowElement.props.children.map((child, index) => ({
              index,
              ...child.props,
            })),
          },
        ];
      }
    }, []);
  }, [pseudoRowElements]);

  /**
   * Validate the there are an equal number of columns in each row
   * as there are are number of columns in the head.
   */
  useEffect(() => {
    const totalHeadColumns = headData.columns.length;
    if (
      rowsData.some((rowData) => rowData.columns.length !== totalHeadColumns)
    ) {
      throw new Error(
        'The Table component requires that number of columns in each row are equal to the number of columns in the head.\n' +
          'If a row item does not have any data, set the children of the RowItem component to null instead of omitting it.',
      );
    }
  }, [headData, rowsData]);

  const [concealedColumnIndexes, setSecondaryColumnIndexes] = useState([]);

  /**
   * Whether there are any columns that are "concealed" (columns
   * that are shown as expandable content due to lack of screen space).
   */
  const hasConcealedColumns = useMemo<boolean>(
    () => concealedColumnIndexes.length > 0,
    [concealedColumnIndexes],
  );

  /**
   * Whether the head of the table should be hidden.
   */
  const hideHead = useMemo<boolean>(
    () => concealedColumnIndexes.length === headData.columns.length - 1,
    [concealedColumnIndexes, headData],
  );

  /**
   * The total number of "primary" columns (the columns that
   * are not currently concealed).
   */
  const totalPrimaryColumns = useMemo<number>(
    () =>
      hasConcealedColumns
        ? headData.columns.length - concealedColumnIndexes.length + 1
        : headData.columns.length,
    [headData.columns, hasConcealedColumns, concealedColumnIndexes],
  );

  /**
   * A reference to the element containing the table.
   */
  const container = useResizeDetector<HTMLDivElement>({
    handleWidth: true,
    handleHeight: false,
  });

  /**
   * Checks if the content of the table is overflowing the container.
   */
  const getOverflowStatus = useCallback(() => {
    const {current: containerElement} = container.ref;
    return (
      containerElement &&
      containerElement.scrollWidth > containerElement.clientWidth
    );
  }, [container]);

  const [concealWidths, setConcealWidths] = useState<number[]>([]);

  /**
   * "Conceals" the column at the far right of the table.
   * (This sets the column to show within the expanded content
   * rather than be rendered as a real column in the table.
   */
  const concealColumn = useCallback(() => {
    /**
     * Find the next column to conceal, excluding the first (primary) column,
     * and prioritising columns that are last.
     */
    const columnToConceal = headData.columns
      .slice(1, headData.columns.length)
      .reverse()
      .find((column) => !concealedColumnIndexes.includes(column.index));

    if (columnToConceal) {
      setSecondaryColumnIndexes((current) => [
        columnToConceal.index,
        ...current,
      ]);
      setConcealWidths((current) => [
        container.ref.current.clientWidth,
        ...current,
      ]);
    }
  }, [headData, concealedColumnIndexes, container]);

  /**
   * Reveals the first "concealed" column.
   * (This sets the column to become a real column again rather than
   * being showing within the expanded content.)
   */
  const revealColumn = useCallback(() => {
    if (concealedColumnIndexes.length > 0) {
      setSecondaryColumnIndexes((current) => current.slice(1, current.length));
      setConcealWidths((current) => current.slice(1, current.length));
    }
  }, [concealedColumnIndexes]);

  /**
   * When the container width changes, check if there is enough space
   * to reveal any of the "concealed" columns and if so, then reveal
   * the column(s).
   */
  useEffect(() => {
    for (
      let concealWidthIndex = 0;
      concealWidthIndex < concealWidths.length;
      concealWidthIndex += 1
    ) {
      if (container.width > concealWidths[concealWidthIndex]) {
        revealColumn();
      } else {
        break;
      }
    }
  }, [container.width, concealWidths, revealColumn]);

  /**
   * Check whether the container of the table is overflowing and
   * "conceal" any overflowing columns.
   */
  useEffect(() => {
    const isOverflowing = getOverflowStatus();
    if (isOverflowing) {
      concealColumn();
    }
  }, [container, getOverflowStatus, concealColumn]);

  return (
    <div
      ref={container.ref}
      className="my-4 rounded-xl border-2 border-brand-50 overflow-hidden w-full relative">
      <table className="w-full max-w-full table-auto">
        {/* Head */}
        {!hideHead && (
          <Head
            data={headData}
            hasConcealedColumns={hasConcealedColumns}
            concealedColumnIndexes={concealedColumnIndexes}
          />
        )}
        {/* Rows */}
        <tbody className="text-left font-normal text-brand-850 bg-white">
          {rowsData.map((rowData, index) => {
            const shouldAddRowSeparator =
              rowData.group &&
              (index > 0
                ? rowsData[index - 1].group?.id !== rowData.group.id
                : true);
            return (
              <Fragment key={rowData.index}>
                {/* Row separator */}
                {shouldAddRowSeparator && (
                  <RowSeparator
                    group={rowData.group}
                    colSpan={totalPrimaryColumns}
                  />
                )}
                {/* Row */}
                <Row
                  data={rowData}
                  headData={headData}
                  totalPrimaryColumns={totalPrimaryColumns}
                  hasConcealedColumns={hasConcealedColumns}
                  concealedColumnIndexes={concealedColumnIndexes}
                />
                {/* Divider */}
                {index < rowsData.length - 1 && (
                  <tr>
                    <td
                      className="h-[2px] bg-brand-50"
                      colSpan={totalPrimaryColumns}
                    />
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

/**
 * Export all table components.
 */
export default {
  Table,
  TableRow: PseudoRow,
  TableRowSeparator: PseudoRowSeparator,
  TableRowItem: PseudoRowItem,
  TableHead: PseudoHead,
  TableHeadItem: PseudoHeadItem,
};
