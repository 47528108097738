import {useCallback, useEffect, useMemo, useState} from 'react';

import {FormikConfig, FormikValues, useFormik} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import airConditioningIcon from 'assets/img/listing-tag-icons/air-conditioning.png';
import bathIcon from 'assets/img/listing-tag-icons/bath.png';
import dishwasherIcon from 'assets/img/listing-tag-icons/dishwasher.png';
import dryerIcon from 'assets/img/listing-tag-icons/dryer.png';
import electricHeatingIcon from 'assets/img/listing-tag-icons/electric-heating.png';
import fridgeIcon from 'assets/img/listing-tag-icons/fridge.png';
import furnishedIcon from 'assets/img/listing-tag-icons/furnished.png';
import heatPumpIcon from 'assets/img/listing-tag-icons/heat-pump.png';
import outdoorSpaceIcon from 'assets/img/listing-tag-icons/outdoor-space.png';
import ovenIcon from 'assets/img/listing-tag-icons/oven.png';
import parkingIcon from 'assets/img/listing-tag-icons/parking.png';
import washerIcon from 'assets/img/listing-tag-icons/washer.png';
import ListingWalkthroughMoreTagsField from 'components/forms_fields/ListingWalkthroughMoreTagsField';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {GridSelect, type GridSelectOption} from 'components_sb/input';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type TagsFormValues = {
  tags: string[];
};

const tagOptions: GridSelectOption[] = [
  {id: 'fridge', label: 'Fridge', icon: fridgeIcon},
  {id: 'oven', label: 'Oven', icon: ovenIcon},
  {id: 'dishwasher', label: 'Dishwasher', icon: dishwasherIcon},
  {id: 'washer', label: 'Washer', icon: washerIcon},
  {id: 'dryer', label: 'Dryer', icon: dryerIcon},
  {id: 'heat_pump', label: 'Heat Pump', icon: heatPumpIcon},
  {
    id: 'air_conditioning',
    label: 'Air Conditioning',
    icon: airConditioningIcon,
  },
  {id: 'bath', label: 'Bath', icon: bathIcon},
  {id: 'parking', label: 'Parking', icon: parkingIcon},
  {
    id: 'private_outdoor_space',
    label: 'Outdoor Space',
    icon: outdoorSpaceIcon,
  },
  {
    id: 'electric_heating',
    label: 'Electric Heating',
    icon: electricHeatingIcon,
  },
  {
    id: 'furnished',
    label: 'Furnished',
    icon: furnishedIcon,
  },
];

const TagsStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartKeyPropertyFeaturesStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: TagsFormValues) => {
      setSubmitting(true);

      /**
       * Set changes on the listing.
       */
      listing.assignAttributes(formValues);

      /**
       * Save the changes to the listing.
       */
      if (!(await saveResource(listing))) {
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = 'listing_tags';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.ListProperty_CompleteKeyPropertyFeaturesStep,
        {
          propertyId: property.id,
          listingId: listing.id,
        },
      );

      setSubmitting(false);

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );
    },
    [listing, property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        tags: listing.tags || [],
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        tags: Yup.array().min(0).label('Tags'),
      }),
    }),
    [handleSubmit, listing],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      fullWidth
      title="Highlight your property's key features..."
      subtitle="This will allow more tenants to find your property and help our AI write a better description for you.">
      <GridSelect
        mode="manual:select"
        multiple
        minColumnWidth={220}
        maxColumns={4}
        options={tagOptions}
        value={form.values.tags}
        onChange={(value) => form.setFieldValue('tags', value)}
      />
      <ListingWalkthroughMoreTagsField formik={form} name="tags" />
    </StepContainer>
  );
};

export default TagsStep;
