import React from 'react';

import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';

const Note = ({
  attachment,
  onClick,
}: {
  attachment: InspectionItemAttachment;
  onClick: () => void;
}) => (
  <a
    className="mt-2 flex flex-col border-l-4 border-info w-full cursor-pointer"
    onClick={onClick}>
    <div className="bg-blue-50 pl-2 pb-1 rounded-r-lg">
      <strong className="text-sm text-info">Note</strong>
      <p>{attachment.notes}</p>
    </div>
  </a>
);

export default Note;
