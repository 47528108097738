import React, {Fragment, useState} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {SubmitButton, TextareaField} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import OpenHome from 'models/listings/OpenHome';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

type FormValues = {
  landlordNotes: string;
};

const OpenHomeDetailPage = () => {
  usePageVisit('OpenHomeDetailPage');
  const {id} = useParams();

  const queryClient = useQueryClient();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedAttendee, setSelectedAttendee] =
    useState<OpenHomeAttendee | null>(null);

  const {data, isLoading, error} = useQuery(`open-home-${id}`, async () => {
    const openHome = await OpenHome.includes({
      open_home_attendees: 'user',
    }).find(id);

    return openHome.data;
  });

  const closeModal = () => {
    setSelectedAttendee(null);
    setModalIsOpen(false);
  };

  const handleNotesSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const oha = selectedAttendee;

    if (oha) {
      oha.assignAttributes(formValues);

      const result = await oha.save();

      if (result) {
        toast.success(`Notes for ${oha.user.name} successfully saved!`);

        queryClient.setQueryData(`open-home-${id}`, data);

        closeModal();
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Open Home Attendees">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.openHomeAttendees.length === 0) {
      return (
        <PageWrapper title="Open Home Attendees">
          <Card title="Open Home Attendees">
            <Paragraph>
              Currently there are no RSVP's for this open home.
            </Paragraph>
          </Card>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Open Home Attendees">
          <Card
            title={`Open Home Attendees (${data.openHomeAttendees.length})`}>
            <Paragraph>
              These are all the people who have RSVP'd that they will attend
              your open home.
            </Paragraph>

            {data.openHomeAttendees.map((oha) => (
              <div
                className="flex justify-between items-center gap-2 mb-4"
                key={oha.id}>
                <div className="flex justify-start items-center gap-2">
                  <div>
                    <UserAvatar user={oha.user} size="10" />
                  </div>
                  <div>
                    <strong>{oha.user.name}</strong>
                    <p className="text-gray-500 text-sm">
                      RSVP'd on {moment(oha.createdAt).format(DATE_TIME_FORMAT)}
                    </p>
                    {oha.landlordNotes && (
                      <div className="mt-2">
                        <strong className="block text-sm">Notes:</strong>
                        <p className="text-sm">{oha.landlordNotes}</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="float-right">
                  <button
                    className="btn btn-sm btn-neutral"
                    onClick={() => {
                      setSelectedAttendee(oha);
                      setModalIsOpen(true);
                    }}>
                    Add Notes
                  </button>
                </div>
              </div>
            ))}
          </Card>

          <Transition appear show={modalIsOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-50 overflow-y-auto"
              onClose={closeModal}>
              <div className="min-h-screen text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="inline-block align-middle" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  className="inline-block py-8 w-full"
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95">
                  <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                    <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900">
                        Add Notes For{' '}
                        {selectedAttendee ? selectedAttendee.user.name : null}
                      </Dialog.Title>
                      <span className="absolute left-3 top-3">
                        <button onClick={closeModal}>
                          <XIcon className="w-5 h-5" />
                        </button>
                      </span>
                    </div>

                    <div className="flex-grow overflow-y-auto">
                      <div className="px-10">
                        <p className="mt-2">
                          Add any notes about this potential tenant. These notes
                          are private and are only viewable by you.
                        </p>

                        <div className="mt-4">
                          <Formik
                            initialValues={{
                              landlordNotes:
                                selectedAttendee &&
                                selectedAttendee.landlordNotes
                                  ? selectedAttendee.landlordNotes
                                  : '',
                            }}
                            onSubmit={handleNotesSubmit}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={Yup.object().shape({
                              landlordNotes: Yup.string()
                                .required()
                                .label('Notes')
                                .min(1)
                                .max(5000),
                            })}>
                            {(formik) => (
                              <Form className="mb-8">
                                <TextareaField
                                  name="landlordNotes"
                                  labelProps={{
                                    title: 'Notes',
                                    size: 'base',
                                  }}
                                  formik={formik}
                                  placeholder="Write any notes in here..."
                                  rows={6}
                                />

                                <SubmitButton
                                  text="Save Notes"
                                  submittingText="Saving"
                                  formik={formik}
                                  className="mt-4"
                                />
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>

                    <div className="p-2 flex-shrink-0 bg-neutral-100 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                      <button
                        className="btn btn-outline btn-secondary btn-sm"
                        onClick={closeModal}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </PageWrapper>
      );
    }
  }

  return <div>OpenHomeDetailPage</div>;
};

export default OpenHomeDetailPage;
