import React, {useCallback, useImperativeHandle} from 'react';

import {useFormik} from 'formik';
import {isEmpty} from 'lodash';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {TextareaField} from 'components/forms_fields';
import InspectionMediaPreview from 'components/inspection/media_items/InspectionMediaPreview';
import {ModalDefinition} from 'components_sb/layout';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import useInspectionStore from 'stores/InspectionStore';

type FormValues = {
  notes: string;
};

const InspectionAddNotesFromAttachmentModal: ModalDefinition = {
  title: 'Edit note',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, inspectionItem, attachment} = props;
    const {currentUser} = useAuth();

    const [inspectionItems, setInspectionItems] = useInspectionStore(
      (state) => [state.inspectionItems, state.setInspectionItems],
    );

    const inspection = useInspectionStore((state) => state.inspection);

    const shouldCreateNew = attachment.userId !== currentUser.id;

    const handleSubmit = useCallback(
      async (formValues: FormValues, formikHelpers: any) => {
        let result: boolean;
        let attach: InspectionItemAttachment;

        // This is to do with object references.
        const item = inspectionItems.find((i) => i.id === inspectionItem.id);

        if (shouldCreateNew) {
          attach = new InspectionItemAttachment(formValues);
          attach.attachment = attachment.attachment;
          attach.userId = currentUser.id;
          attach.inspectionItemId = inspectionItem.id;

          result = await attach.save();

          attach.user = new User({
            id: currentUser.id,
            name: currentUser.name,
            avatar: currentUser.avatar,
            email: currentUser.email,
          });
          attach.user.isPersisted = true;
        } else {
          attach = item.inspectionItemAttachments.find(
            (a) => a.id === attachment.id,
          );
          attach.notes = formValues.notes;

          result = await attach.save();
        }

        if (result) {
          if (shouldCreateNew) {
            item.inspectionItemAttachments.push(attach);
          }

          setInspectionItems([...inspectionItems]);

          inspection.deleteSignOffs();
          formikHelpers.setSubmitting(false);
          closeModal();
          toast.success('Note successfully updated!');
        } else {
          for (const key in attach.errors) {
            const err = attach.errors[key]?.fullMessage;
            formikHelpers.setFieldError(key, err);
          }
        }
      },
      [
        closeModal,
        currentUser,
        inspection,
        inspectionItem,
        inspectionItems,
        attachment,
        setInspectionItems,
        shouldCreateNew,
      ],
    );

    const formik = useFormik<FormValues>({
      initialValues: {
        notes: shouldCreateNew ? '' : attachment.notes,
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        notes: Yup.string().required().min(5).label('Notes'),
      }),
    });

    const onSave = useCallback(async () => {
      const result = await formik.validateForm();
      if (isEmpty(result)) {
        const {setSubmitting, setFieldError} = formik;
        await handleSubmit(formik.values, {setSubmitting, setFieldError});
      }
      return false;
    }, [formik, handleSubmit]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <>
        <div className="mt-2">
          <InspectionMediaPreview
            source={attachment}
            onClick={() => {
              ('');
            }}
          />
        </div>

        <div className="mt-2">
          <TextareaField
            formik={formik}
            labelProps={{title: 'Notes', size: 'base'}}
            name="notes"
            placeholder="Write your notes here"
          />
        </div>
      </>
    );
  },
};

export default InspectionAddNotesFromAttachmentModal;
