import {useCallback, useMemo} from 'react';

import moment from 'moment';
import {BiUndo} from 'react-icons/bi';
import {TbHomeDollar} from 'react-icons/tb';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {toCurrency} from 'utilities/StringHelpers';

const RentPriceChangesCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  const undoRentPriceChange = useCallback(async () => {
    const rentChange = tenancy.unprocessedRentPriceChanges[0];
    if (rentChange) {
      const result = await rentChange.destroy();
      if (result) {
        toast.success('You have successfully removed this rent change!');
        await queryClient.invalidateQueries([
          'property',
          {id: property.id, context: 'detail-page'},
        ]);
      }
    }
  }, [tenancy, property.id, queryClient]);

  const confirmUndoRentPriceChange = useCallback(async () => {
    setConfirmationOptions({
      title: 'Remove Rent Change',
      message: 'Are you sure you want to undo this rent change?',
      buttonTitle: 'Remove',
      action: undoRentPriceChange,
      color: 'error',
    });
  }, [undoRentPriceChange, setConfirmationOptions]);

  const cardActions = useMemo<Action[]>(
    () => [
      {
        label: 'Undo Rent Change',
        icon: BiUndo,
        onClick: confirmUndoRentPriceChange,
      },
    ],
    [confirmUndoRentPriceChange],
  );

  if (property.tenanciesCount === 0) {
    return null;
  }
  if (!tenancy) {
    return null;
  }

  if (!tenancy.isActive) {
    return null;
  }

  if (
    tenancy.unprocessedRentPriceChanges &&
    tenancy.unprocessedRentPriceChanges.length > 0
  ) {
    const rentChange = tenancy.unprocessedRentPriceChanges[0];

    return (
      <Card title="Rent Change" actions={cardActions} icon={TbHomeDollar}>
        <Paragraph>
          The rent for this tenancy will change to{' '}
          {toCurrency(rentChange.newTotalRent)} from{' '}
          {moment(rentChange.effectiveDate).format(DATE_FORMAT)}.
        </Paragraph>

        {rentChange.isTemporaryRentReduction && (
          <Paragraph>
            This is a temporary rent reduction. The rent will change back to{' '}
            {toCurrency(rentChange.oldTotalRent)} on{' '}
            {moment(rentChange.reductionEndDate).format(DATE_FORMAT)}.
          </Paragraph>
        )}
      </Card>
    );
  }
  return null;
};

export default RentPriceChangesCard;
