import {HiOutlineUserGroup} from 'react-icons/hi';

import TenancyMembershipsTable from 'components/tenancy/TenancyMembershipsTable';
import TenancyRequestsTable from 'components/tenancy/TenancyRequestsTable';
import {Card} from 'components_sb/layout';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import {Title} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const TenantsCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => (
  <Card icon={HiOutlineUserGroup} title="Tenants">
    {tenancy ? (
      <div className="flex flex-col gap-y-6">
        <div>
          <Title size="sm" level="h3">
            Pending Invitations
          </Title>
          <TenancyRequestsTable
            tenancyRequests={tenancy.tenancyRequests}
            tenancyInvites={tenancy.tenancyInvites}
            propertyId={property.id}
          />
        </div>
        <div>
          <Title size="sm" level="h3">
            Active Tenants
          </Title>
          <TenancyMembershipsTable
            tenancyMemberships={tenancy.tenancyMemberships}
            headTenantId={tenancy.headTenantId}
          />
        </div>
      </div>
    ) : (
      <EmptyContentPlaceholder
        type="people"
        message="There is no current tenancy for this property"
      />
    )}
  </Card>
);

export default TenantsCard;
