import {MimeType} from '../types';

/**
 * Determines the MIME type of a remote file by performing a HEAD request
 * to a file URL and returns the MIME type from the response.
 * @param url The URL of the remote file.
 * @returns The MIME type of the file as a string, or null if it could not be determined.
 */
const getMimeTypeFromUrl = async (url: string): Promise<MimeType> => {
  let mimeType = null;
  try {
    const response = await fetch(url, {method: 'HEAD'});
    mimeType = response.headers.get('content-type');
  } catch (error) {
    console.log('Error determining MIME type for file at URL: ', url, error);
  }
  return mimeType ?? null;
};

export default getMimeTypeFromUrl;
