import {HTMLProps} from 'react';

import {MinusIcon, PlusIcon} from '@heroicons/react/outline';
import {type FormikProps} from 'formik';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import {InlineError} from 'components_sb/feedback';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';

interface InputFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  labelProps: FieldLabelProps;
  name: string;
  incrementValue?: number;
}

const CountableMoneyField = ({
  formik,
  labelProps,
  name,
  incrementValue = 5,
}: InputFieldProps) => {
  const errors = errorsFor(formik, name);
  let value = valueFor(formik, name);
  value = Number(value);

  const handleClickIncrement = () => {
    const currentValue = value;
    if (currentValue) {
      formik.setFieldValue(name, currentValue + incrementValue);
    } else {
      formik.setFieldValue(name, incrementValue);
    }
  };

  const handleClickDecrement = () => {
    const currentValue = value;
    if (currentValue) {
      formik.setFieldValue(name, Math.max(0, currentValue - incrementValue));
    } else {
      formik.setFieldValue(name, 0);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {labelProps && <FieldLabel {...labelProps} />}
      <div className="flex items-center justify-between">
        <button
          className="w-12 h-12 rounded-full flex items-center justify-center border border-neutral-400 bg-white focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickDecrement}>
          <MinusIcon className="w-6 h-6" />
        </button>

        <input
          type="text"
          pattern="[0-9]*"
          className="input input-bordered rounded-xl text-center h-24 border-brand-75 border-1 text-3xl font-semibold mx-4"
          value={value}
          onChange={(e) =>
            formik.setFieldValue(name, Number(e.currentTarget.value))
          }
        />

        <button
          className="w-12 h-12 rounded-full flex items-center justify-center border border-neutral-400 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickIncrement}>
          <PlusIcon className="w-6 h-6" />
        </button>
      </div>

      {errors && (
        <InlineError error={typeof errors === 'string' ? errors : null} />
      )}
    </div>
  );
};

export default CountableMoneyField;
