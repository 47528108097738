let baseUrl: string;

export const {TARGET_ENV, PINPAYMENTS_PUBLIC_KEY, APP_VERSION, LOCAL_IP} =
  process.env;

const localHost = LOCAL_IP || 'localhost';

if (TARGET_ENV === 'development') {
  baseUrl = `http://${localHost}:8000`;
} else if (TARGET_ENV === 'test') {
  baseUrl = `http://${localHost}:8080`;
} else if (TARGET_ENV === 'staging') {
  baseUrl = 'https://staging-api.keyhook.com';
} else if (TARGET_ENV === 'production') {
  baseUrl = 'https://api.keyhook.com';
} else {
  baseUrl = `http://${localHost}:8000`;
}

export const BASE_URL = baseUrl;
export const API_NAMESPACE = '/api/v1';

export const API_URL = BASE_URL + API_NAMESPACE;
