import {useEffect, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Alert} from 'components_sb/feedback';
import {API_URL} from 'globals/app-globals';
import Listing, {ListingStatus} from 'models/listings/Listing';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

import DescriptionCard from './DescriptionCard';
import ListingApplicationCard from './ListingApplicationCard';
import ListingEnquireCard from './ListingEnquireCard';
import LocationCard from './LocationCard';
import MainCard from './MainCard';
import OpenHomesCard from './OpenHomesCard';
import Photos from './Photos';
import RestrictionsCard from './RestrictionsCard';

const ListingDetailPage = () => {
  usePageVisit('ListingDetailPage');

  const {currentUser} = useAuth();

  const {id: listingId} = useParams();

  const {
    data: listing,
    error,
    isLoading: isLoadingListing,
  } = useQuery(`listing-${listingId}`, async () => {
    const listing = await Listing.includes(['listing_photos', 'open_homes'])
      .selectExtra(['landlord_id'])
      .order({'open_homes.start_time': 'asc'})
      .order({'listing_photos.order_index': 'asc'})
      .find(listingId);

    return listing.data;
  });

  useTitle(listing ? listing.streetAddress : '');

  useEffect(() => {
    if (listing && listing.id) {
      try {
        fetch(`${API_URL}/listings/${listing.id}/view.json`, {method: 'PATCH'});
      } catch (e) {
        console.log(e);
      }
    }
  }, [listing]);

  const currentUserOwnsListing = useMemo(
    () => listing?.landlordId === currentUser?.id,
    [listing, currentUser],
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoadingListing) {
    return <LoadingView />;
  } else {
    return (
      <PageWrapper hideHeader={!Capacitor.isNativePlatform()} title="Listing">
        {/* Historical */}
        <Alert
          show={listing.isHistorical}
          type="warning"
          title="This listing is no longer active."
          description="Tenants have already been found or the landlord has chosen to withdraw it."
        />

        {/* Under offer */}
        <Alert
          show={listing.isUnderOffer}
          type="info"
          title="This property is currently under offer."
          description="You may still enquire or apply, but the landlord may not respond."
        />

        {/* Pending approval */}
        {currentUserOwnsListing &&
          listing.status === ListingStatus.PendingApproval && (
            <Alert
              type="info"
              title="Your listing has been submitted and is pending approval."
              description="You will be notified once it has been approved."
              actions={[
                {
                  label: 'Manage Listing',
                  linkTo: `/properties/${listing.propertyId}/listings/${listing.publicId}`,
                },
              ]}
            />
          )}

        {/* Active */}
        {currentUserOwnsListing && listing.status === ListingStatus.Active && (
          <Alert
            type="success"
            title="Your listing is live!"
            description="Below is how your listing will appear to potential tenants."
            actions={[
              {
                label: 'Manage Listing',
                linkTo: `/properties/${listing.propertyId}/listings/${listing.publicId}`,
              },
            ]}
          />
        )}

        <div
          className="nc-ListingStayDetailPage mt-10 mb-10"
          data-nc-id="ListingStayDetailPage">
          <Photos listing={listing} />
          <div className="mt-11 flex flex-col lg:flex-row gap-x-6">
            {/* MAIN CONTENT */}
            <div className="w-full lg:w-3/5 xl:w-2/3 flex flex-col">
              <MainCard listing={listing} />
              <DescriptionCard listing={listing} />
              <RestrictionsCard listing={listing} />
              <LocationCard listing={listing} />
              <OpenHomesCard
                currentUserOwnsListing={currentUserOwnsListing}
                listing={listing}
              />
            </div>

            {/* SIDEBAR */}
            <div className="flex flex-col w-full lg:w-2/5 xl:w-1/3">
              <ListingEnquireCard listing={listing} />
              <ListingApplicationCard listing={listing} />
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
};

export default ListingDetailPage;
