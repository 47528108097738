import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useFloating,
  useInteractions,
  offset,
  shift,
  useClick,
  useDismiss,
} from '@floating-ui/react';
import clsx from 'clsx';
import {Collapse, UnmountClosed} from 'react-collapse';
import {HiChevronDown} from 'react-icons/hi';
import {IconType} from 'react-icons/lib';

import {Button} from 'components_sb/buttons';
import {Popover} from 'components_sb/layout';
import {FieldLabel} from 'components_sb/typography';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';

import FieldIcon from '../../../../../pages/shared/listings/home/BasicFilters/FieldIcon';

interface FilterWrapperProps {
  inline?: boolean;
  label: string;
  icon: IconType;
  valueLabel?: string;
  onClearFilter: () => void;
  children: ReactNode;
}

const FilterWrapper: FunctionComponent<FilterWrapperProps> = ({
  inline,
  label,
  icon,
  valueLabel,
  onClearFilter,
  children: controls,
}) => {
  const floatingMode = useTailwindBreakpoint('mdlg');

  const [floatingControlsOpen, setFloatingControlsOpen] = useState(false);
  const [collapsibleControlsOpen, setCollapsibleControlsOpen] = useState(false);

  /**
   * Handle closing either the floating or collapsible controls if necessary
   * when switching between modes (based on screen size).
   */
  useEffect(() => {
    if (floatingMode) {
      if (collapsibleControlsOpen) {
        setCollapsibleControlsOpen(false);
      }
    } else {
      if (floatingControlsOpen) {
        setFloatingControlsOpen(false);
      }
    }
  }, [floatingMode, collapsibleControlsOpen, floatingControlsOpen]);

  /**
   * Create a floating instance.
   */
  const floating = useFloating({
    open: floatingControlsOpen,
    onOpenChange: setFloatingControlsOpen,
    placement: 'bottom',
    middleware: [
      /**
       * Add a gap between the button and the popover.
       */
      offset(5),

      /**
       * Keep the popover in view of the window along the x-axis.
       */
      shift({
        /**
         * Ensure at least 10px of horizontal padding when shifting.
         */
        padding: 10,
      }),
    ],
  });

  const click = useClick(floating.context);
  const dismiss = useDismiss(floating.context);

  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
  ]);

  /**
   * Toggle the collapsible controls when the button is clicked.
   */
  const onButtonClick = useCallback(() => {
    setCollapsibleControlsOpen((current) => !current);
  }, []);

  const buttonModeProps = useMemo(
    () =>
      floatingMode
        ? {
            ref: floating.refs.setReference,
            ...getReferenceProps(),
          }
        : {
            onClick: onButtonClick,
          },
    [floatingMode, floating, getReferenceProps, onButtonClick],
  );

  const clearFilterButton = useMemo(
    () => (
      <Collapse isOpened={!!valueLabel}>
        <div className={clsx(inline ? 'pb-2' : 'pt-4')}>
          <Button
            label="Clear filter"
            category="tertiary"
            size="sm"
            fillWidth
            mode="manual"
            onClick={onClearFilter}
          />
        </div>
      </Collapse>
    ),
    [onClearFilter, valueLabel, inline],
  );

  return inline ? (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-2 h-8 mb-1">
        {<FieldLabel size="base" title={label} icon={icon} />}
        {clearFilterButton}
      </div>
      {controls}
    </div>
  ) : (
    <>
      <button
        role="button"
        className={clsx(
          'transition-all duration-300',
          'bg-transparent hover:bg-brand-50 active:bg-brand-75',
          'outline-none border-none',
          'flex flex-row items-center',
          'gap-x-4',
          'px-6 xl:px-8',
          'py-6 mdlg:py-0',

          // Column vs bar styles
          'justify-between mdlg:justify-start',
          'h-auto mdlg:h-full',
          'w-full mdlg:w-auto',
          'rounded-none mdlg:rounded-full',
          'justify-start mdlg:justify-center',
          'shadow-none mdlg:focus:shadow-2xl',
        )}
        {...buttonModeProps}>
        <div className={clsx('flex flex-row items-center', 'gap-x-4')}>
          <FieldIcon icon={icon} />
          <div className="flex flex-col text-left">
            <div
              className={clsx(
                'whitespace-nowrap',
                'transition-all duration-300',
                'origin-left',
                valueLabel
                  ? clsx('text-brand-850 text-opacity-70 scale-90')
                  : clsx('text-brand-500 text-opacity-100 scale-100'),

                'font-medium',
                'text-base',
              )}>
              {label}
            </div>
            <UnmountClosed isOpened={!!valueLabel}>
              <div className="text-brand-850 font-medium text-base pt-1">
                {valueLabel}
              </div>
            </UnmountClosed>
          </div>
        </div>
        <div className={clsx('flex mdlg:hidden')}>
          <HiChevronDown
            className={clsx(
              'w-6 h-6 text-brand-850',
              'transition-transform duration-300',
              collapsibleControlsOpen ? 'rotate-180' : 'rotate-0',
            )}
          />
        </div>
      </button>

      {floatingMode ? (
        <Popover
          width={450}
          contentClassName="flex flex-col p-6"
          floating={floating}
          getFloatingProps={getFloatingProps}>
          {controls}
          {clearFilterButton}
        </Popover>
      ) : (
        <div className="flex flex-col w-full">
          <UnmountClosed isOpened={collapsibleControlsOpen}>
            <div className="w-full p-6 box-border">
              {controls}
              {clearFilterButton}
            </div>
          </UnmountClosed>
        </div>
      )}
    </>
  );
};

export default FilterWrapper;
