import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Tenancy from 'models/properties/Tenancy';

@Model()
class EndOfTenancy extends ApplicationRecord {
  static jsonapiType = 'end_of_tenancies';

  @Attr() bondNumber: string;

  @Attr() bondDisputeAmount: number;
  @Attr() bondRefundAmount: number;
  @Attr() bondRefundReasons: string[];

  @Attr() processed: boolean;

  @Attr() landlordSignature: string;

  @Attr() messageFromLandlord: string;

  @Attr() anyTenantsSigned: boolean;
  @Attr() currentUserSigned: boolean;

  @Attr() tenantBondSplits: Record<string, number>;
  @Attr() tenantSignatures: Record<string, string>;

  @Attr() tenancyEndDate: string;

  @Attr() tenancyId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
}

export default EndOfTenancy;
