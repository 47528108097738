import {useCallback, useMemo} from 'react';

import {sentenceCase} from 'change-case';
import {useFormik} from 'formik';
import {omit} from 'lodash';
import {Collapse} from 'react-collapse';
import {useCookies} from 'react-cookie';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import 'yup-phone';
import LandlordDashboardScreenshot from 'assets/img/iphone-mobile-landlord-dashboard.png';
import {Button} from 'components_sb/buttons';
import {Alert} from 'components_sb/feedback';
import {TextField} from 'components_sb/input';
import {AuthFormLayout} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import useReferralCodeCookie from 'hooks/useReferralCodeCookie';
import useAuth, {
  RegistrationFormData,
  accountTypeMapping,
} from 'services/useAuth';
import SocialAuth, {SocialAuthProviderType} from 'utilities/auth/SocialAuth';
import {SocialAuthUserData} from 'utilities/auth/SocialAuth/providers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {isFullName} from 'utilities/StringHelpers';

interface RegisterPageProps {
  /**
   * The types of account a user can register for.
   */
  accountType: 'landlord' | 'tenant';
}

/**
 * A set of form error message mappings.
 */
interface ErrorMessageMapping {
  /**
   * The keyword to find within the 'reason' to activate the mapping.
   */
  keyword: string;
  /**
   * The message that replaces the 'reason' returned for the error.
   */
  message: string;
}

/**
 * Key/value pairings of a form field name to a set of form error
 * message mappings.
 */
interface ErrorMessageMappings {
  [key: string]: ErrorMessageMapping[];
}

/**
 * Define mappings here to override the 'reason' string for the error
 * messages for particular fields.
 */
const ERROR_MESSAGE_MAPPINGS: ErrorMessageMappings = {
  email: [
    {
      keyword: 'taken',
      message: 'There is already an account registered to this email address',
    },
  ],
};

const RegisterPage = ({accountType}: RegisterPageProps) => {
  useTitle('Register');
  usePageVisit('RegisterPage');

  /**
   * Load the external scripts required for social authentication.
   */
  SocialAuth.useAuthScripts();

  const referralCode = useReferralCodeCookie();

  const isLandlord = useMemo(() => accountType === 'landlord', [accountType]);

  const {registerUser} = useAuth();

  const showGenericError = useCallback(() => {
    toast.error(
      `There was an issue registering your account. Please try again later or contact us for support.`,
    );
  }, []);

  const handleFormSubmit = useCallback(
    async (formData: RegistrationFormData, actions: any) => {
      let response;
      try {
        /**
         * Attempt the registration request.
         */
        response = await registerUser('email', {
          userData: formData,
          /**
           * Include the account type and referral code (if applicable)
           * alongside the form data.
           */
          accountType: accountTypeMapping[accountType],
          referralCode: referralCode.isValid ? referralCode.value : undefined,
        });
      } catch (error) {
        /**
         * Error performing the registration request.
         */
        showGenericError();
        /**
         * Reset the submitting state.
         */
        actions.setSubmitting(false);
        return;
      }

      /**
       * Request performed successfully.
       */
      const {responseObject, status} = response;

      if (status === 200) {
        /**
         * Request was performed successfully and the server did not return errors.
         */

        /**
         * Clear the referral code cookie.
         */
        referralCode.clear();
      } else {
        /**
         * Request was performed successfully but the server returned errors.
         */

        /**
         * Initial error response object (before message mappings applied).
         */
        const {errors} = responseObject;

        /**
         * Server returned error messages to display.
         */
        if (errors) {
          /**
           * Replace any matched errors with messages from the defined mapping.
           */
          for (const [fieldName, reasons] of Object.entries(errors)) {
            const mappings = ERROR_MESSAGE_MAPPINGS[fieldName] ?? [];
            errors[fieldName] = (reasons as string[]).map(
              (reason: string) =>
                mappings.find(({keyword}: {keyword: string}) =>
                  reason.includes(keyword),
                ).message ?? `${sentenceCase(fieldName)} reason`,
            );
          }

          /**
           * Set error messages on the applicable fields.
           */
          for (const fieldName in errors) {
            const messages = errors[fieldName];
            for (const message of messages) {
              actions.setFieldError(fieldName, message);
            }
          }
        } else {
          /**
           * No specific errors to display were returned by the server.
           */
          showGenericError();
        }
      }
      /**
       *  Reset the submitting state.
       */
      actions.setSubmitting(false);
    },
    [accountType, referralCode, registerUser, showGenericError],
  );

  const onSocialAuthSuccess = useCallback(
    async (provider: SocialAuthProviderType, userData: SocialAuthUserData) => {
      try {
        /**
         * Attempt the registration request.
         */
        const response = await registerUser(provider, {
          userData,
          /**
           * Include the account type and referral code (if applicable)
           * alongside the social auth data.
           */
          accountType: accountTypeMapping[accountType],
          referralCode: referralCode.isValid ? referralCode.value : undefined,
        });

        /**
         * Request performed successfully.
         */
        const {status} = response;

        if (status === 200) {
          /**
           * Request was performed successfully and the server did not return errors.
           */

          /**
           * Clear the referral code cookie.
           */
          referralCode.clear();
        } else {
          /**
           * Request was performed successfully but the server returned errors.
           */
          throw new Error();
        }
      } catch (error) {
        /**
         * Error performing the registration request.
         */
        showGenericError();
      }
    },
    [accountType, registerUser, showGenericError, referralCode],
  );

  const form = useFormik({
    onSubmit: handleFormSubmit,
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      password: '',
    },
    validationSchema: Yup.object().shape(
      {
        name: Yup.string()
          .required('Please enter your full name')
          .min(1, 'Must be 1 character or more')
          .max(128, 'Must be 128 characters or less')
          .test(
            'two-names-plus',
            'Please enter both your first and last name',
            (value) => {
              if (!value) {
                return false;
              }
              return isFullName(value);
            },
          ),
        email: Yup.string()
          .email('Invalid email address')
          .required('Please enter your email address'),
        phoneNumber: Yup.string().when('phoneNumber', {
          is: (value: any) => !value,
          then: Yup.string().notRequired(),
          otherwise: Yup.string().phone(
            'NZ',
            false, // Not strict
            'Please enter a valid phone number',
          ),
        }),
        password: Yup.string()
          .required('Please enter a password for your account')
          .min(6, 'Your password must be at least 6 characters')
          .max(128, 'Your password must be 128 characters or less'),
      },
      [['phoneNumber', 'phoneNumber']],
    ),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <AuthFormLayout
      title={`Register as a ${isLandlord ? 'Landlord' : 'Tenant'}`}
      description={`${
        isLandlord
          ? 'Self-manage easily in less than 5 minutes.'
          : 'Set up an account to help create healthier tenancies.'
      }`}
      supportingContent={
        <Alert
          show={referralCode.isValid}
          title={
            referralCode.details.referrer?.name
              ? `${referralCode.details.referrer.name} has invited you to Keyhook!`
              : "You've been invited to Keyhook!"
          }
          description={[
            `This referral provides you with ${referralCode.details.duration} ${referralCode.details.period} of Keyhook for FREE!`,
            'Your referral code will be automatically applied when registering below.',
          ]}
          icon={
            referralCode.details.referrer?.avatar ? (
              <img
                className="w-10 h-10 rounded-full"
                src={referralCode.details.referrer?.avatar}
                alt={`${referralCode.details.referrer?.name} Profile Picture`}
              />
            ) : undefined
          }
        />
      }
      socialAuthButtonConfig={{
        action: 'register',
        onSuccess: onSocialAuthSuccess,
        onError: showGenericError,
      }}
      appPreviewConfig={
        isLandlord
          ? {
              screenshot: LandlordDashboardScreenshot,
            }
          : null
      }>
      <form className="flex flex-col items-center gap-y-8">
        <div className="w-full flex flex-col gap-y-8">
          <TextField
            name="name"
            label="Full name"
            type="text"
            size="base"
            mode="formik"
            form={form}
            placeholder="Enter your full name here..."
            required
          />
          <TextField
            name="email"
            label="Email"
            type="email"
            size="base"
            mode="formik"
            form={form}
            placeholder="Enter your email address here..."
            required
          />
          {isLandlord && (
            <TextField
              name="phoneNumber"
              label="Phone number"
              type="text"
              size="base"
              mode="formik"
              form={form}
              placeholder="Enter your phone number here..."
            />
          )}
          <TextField
            name="password"
            label="Password"
            description="(6+ characters)"
            type="password"
            size="base"
            mode="formik"
            form={form}
            placeholder="Enter a password here..."
            required
          />
        </div>
        <Paragraph>
          <span>
            {`By clicking the button below, you acknowledge that you accept the `}
          </span>
          <Link openInNewTab to="https://www.keyhook.com/terms-of-use">
            Terms of Service
          </Link>
          <span>{` and `}</span>
          <Link openInNewTab to="https://www.keyhook.com/privacy-policy">
            Privacy Policy
          </Link>
          <span>.</span>
        </Paragraph>
        <Button
          label="Register"
          category="primary"
          size="base"
          mode="formik"
          form={form}
          loadingLabel="Registering..."
        />
      </form>
      <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
        <Link to="/login">Already have an account?</Link>
        <Link to={`/register/${isLandlord ? 'tenant' : 'landlord'}`}>
          {`I want to register as a ${isLandlord ? 'tenant' : 'landlord'}`}
        </Link>
      </span>
    </AuthFormLayout>
  );
};

export default RegisterPage;
