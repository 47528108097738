import {useEffect} from 'react';

import {Button} from 'components_sb/buttons';
import {Link} from 'components_sb/navigation';
import {Title} from 'components_sb/typography';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {usePageVisit, useTitle} from 'utilities/hooks';

import KeyHandoverAnimation from '../../../../animated-svgs/key-handover';

const WelcomePage = () => {
  useTitle('Welcome');
  usePageVisit('WelcomePage');

  /**
   * Start animation shortly after mount
   */
  useEffect(() => {
    window.setTimeout(
      () => document.querySelector('svg').classList.add('animated'),
      1000,
    );
  }, []);

  return (
    <div className="h-full w-full flex flex-col justify-center items-center mt-6 px-6 sm:px-8 md:px-10 lg:px-16">
      <div className="w-full max-w-3xl mx-20 flex flex-col gap-y-4 sm:gap-y-8 justify-center items-center">
        <div className="text-left md:text-center">
          <Title level="h1" size="2xl">
            Welcome to Keyhook
          </Title>
          <p className="text-brand-850 text-opacity-70 text-xl font-medium">
            Let's start creating better tenancies together.
          </p>
        </div>
        <div className="w-full max-w-xs sm:max-w-sm xl:max-w-md">
          <KeyHandoverAnimation />
        </div>
        <div className="flex flex-col items-center gap-y-6 w-full text-sm">
          <Paragraph>
            To learn more and get started, let us know what best describes you:
          </Paragraph>
          <div className="w-full flex flex-col md:flex-row gap-x-6 gap-y-4 max-w-md md:max-w-2xl">
            <Button
              label="I'm a landlord"
              category="primary"
              size="lg"
              mode="link"
              linkTo="/register/landlord"
            />
            <Button
              label="I'm a tenant"
              category="primary"
              size="lg"
              mode="link"
              linkTo="/register/tenant"
            />
          </div>
          <Link color="brand" to="/login">
            Already using Keyhook? Click here to log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
