import React, {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';
import TrackingService from 'services/TrackingService';

interface InlineSelectFieldProps extends HTMLProps<HTMLSelectElement> {
  formik: FormikProps<any>;
  labelProps: FieldLabelProps;
}
const InlineSelectField = ({
  formik,
  labelProps,
  name,
  children,
  ...rest
}: InlineSelectFieldProps) => {
  return (
    <div>
      <div className="flex flex-col">
        {labelProps && <FieldLabel {...labelProps} />}

        <select
          className="bg-white rounded-lg font-medium"
          name={name}
          onChange={(e) => {
            formik.setFieldValue(name, e.target.value);
            TrackingService.trackEvent(TrackingService.Event.SelectOption, {
              field: name,
            });
          }}
          value={formik.values[name]}
          {...rest}>
          {children}
        </select>
      </div>
      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default InlineSelectField;
