import {
  FunctionComponent,
  ReactElement,
  ReactFragment,
  ReactNode,
  createElement,
  isValidElement,
} from 'react';

export interface PseudoRowItemProps {
  children: ReactNode;
}

export type PseudoRowItemElement = ReactElement<PseudoRowItemProps>;

export type PseudoRowItemInputElement = PseudoRowItemElement | ReactFragment;

export type PseudoRowItemComponent = FunctionComponent<PseudoRowItemProps>;

const PseudoRowItem: PseudoRowItemComponent = () => <></>;

export default PseudoRowItem;

/**
 * Input element type guard.
 */
export const elementIsRowItemElement = (
  element: unknown,
): element is PseudoRowItemElement => {
  return (
    isValidElement(element) &&
    element.type === createElement(PseudoRowItem).type
  );
};
