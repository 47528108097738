import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  useFormik,
  FormikConfig,
  FormikValues,
} from 'formik';
import {AnimatePresence, motion} from 'framer-motion';
import {HiPlus} from 'react-icons/hi';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import InsurancePolicyField from 'components/forms_fields/InsurancePolicyField';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {Button} from 'components_sb/buttons';
import {InlineError} from 'components_sb/feedback';
import BooleanSelect from 'components_sb/input/GridSelect/BooleanSelect';
import useScroll from 'hooks/useScroll';
import {InsurancePolicy} from 'models/properties/InsurancePolicy';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {isDefined} from 'utilities/MiscHelpers';
import {saveResource} from 'utilities/SpraypaintHelpers';

const InsuranceStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartInsuranceStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: any, actions: FormikHelpers<any>) => {
      setSubmitting(true);

      /**
       * Set changes on the property.
       */
      property.assignAttributes(formValues);
      property.lastOnboardingStepCompleted = 'new_insurance';
      property.setProfileStepAsCompleted('insurance_policies');

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteInsuranceStep,
        {
          propertyId: property.id,
        },
      );

      setSubmitting(false);
      actions.setSubmitting(false);
    },
    [property, queryClient],
  );

  const policies = useMemo<InsurancePolicy[]>(
    () => property.insurancePolicies || ([] as InsurancePolicy[]),
    [property],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        insuranceCoversTenants: isDefined(property.insuranceCoversTenants)
          ? property.insuranceCoversTenants
          : false,
        insurancePolicies: policies,
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        insuranceCoversTenants: Yup.boolean().label('Insurance Covers Tenants'),
        insurancePolicies: Yup.array()
          .of(
            Yup.object().shape({
              policy_name: Yup.string()
                .required()
                .min(3)
                .max(40)
                .label('Policy Name'),
              insurer: Yup.string().required().min(2).max(40).label('Insurer'),
              excess_amount: Yup.number()
                .min(1)
                .required()
                .label('Excess Amount'),
              yearly_cost: Yup.number()
                .optional()
                .nullable()
                .label('Yearly Cost')
                .min(0),
            }),
          )
          .min(0)
          .label('Insurance Policies')
          .optional(),
      }),
    }),
    [handleSubmit, policies, property],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  const handleAddPolicy = useCallback(() => {
    let policies = form.values.insurancePolicies;
    if (!policies) {
      policies = [] as InsurancePolicy[];
    }
    policies.push({
      policy_name: '',
      insurer: '',
      excess_amount: 0,
      yearly_cost: '',
    });
    form.setFieldValue('insurancePolicies', policies);
  }, [form]);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      title="Add any insurance policies for your property"
      subtitle="NZ law requires the insurance information for a property to be included with any leases">
      <AnimatePresence>
        {form.values.insurancePolicies.map((policy: any, index: number) => (
          <motion.div
            key={index}
            className="w-full"
            initial={{opacity: 0}}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0,
              },
            }}>
            <InsurancePolicyField formik={form} index={index} />
          </motion.div>
        ))}
      </AnimatePresence>

      {form.errors.insurancePolicies &&
        typeof form.errors.insurancePolicies === 'string' && (
          <InlineError error={form.errors.insurancePolicies} />
        )}

      <Button
        label={
          form.values.insurancePolicies.length > 0
            ? 'Add another policy'
            : 'Add a policy'
        }
        icon={HiPlus}
        category="primary"
        size="base"
        mode="manual"
        onClick={handleAddPolicy}
      />

      <div className="w-full">
        <BooleanSelect
          labelProps={{
            title: 'Insurance covers tenants?',
            helpText:
              "Whether there is insurance covering this rental property that is relevant to tenant's liability for damage to premises, including damage to body corporate facilities.",
            size: 'xl',
          }}
          preset="yes/no"
          mode="formik"
          name="insuranceCoversTenants"
          form={form}
        />
      </div>
    </StepContainer>
  );
};

export default InsuranceStep;
