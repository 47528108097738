import {lazy, Suspense} from 'react';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import useAuth from 'services/useAuth';

const Worker: any = lazy(() =>
  import('@react-pdf-viewer/core').then((module) => {
    return {default: module.Worker};
  }),
);
const Viewer = lazy(() =>
  import('@react-pdf-viewer/core').then((module) => {
    return {default: module.Viewer};
  }),
);

import '@react-pdf-viewer/core/lib/styles/index.css';

const DocumentPreviewPage = () => {
  const params = new URLSearchParams(window.location.search);
  const documentUrl = params.get('url');
  const {currentUser} = useAuth();

  // const toolbarPluginInstance = toolbarPlugin();
  // const {renderDefaultToolbar, Toolbar} = toolbarPluginInstance;

  // const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
  //   ...slot,
  //   // These slots will be empty
  //   Download: () => <></>,
  //   SwitchTheme: () => <></>,
  //   Print: () => <></>,
  //   Open: () => <></>,
  //   CurrentPageInput: () => <></>,
  //   GoToFirstPage: () => <></>,
  //   GoToLastPage: () => <></>,
  //   GoToNextPage: () => <></>,
  //   GoToPreviousPage: () => <></>,
  //   NumberOfPages: () => <></>,
  //   Rotate: () => <></>,
  //   ShowProperties: () => <></>,
  //   SwitchScrollMode: () => <></>,
  //   SwitchSelectionMode: () => <></>,
  //   SwitchSelectionModeMenuItem: () => <></>,
  //   SwitchScrollModeMenuItem: () => <></>,
  //   RotateBackwardMenuItem: () => <></>,
  //   RotateForwardMenuItem: () => <></>,
  //   GoToFirstPageMenuItem: () => <></>,
  //   GoToLastPageMenuItem: () => <></>,
  // });

  return (
    <PageWrapper title="Document Preview">
      <Suspense fallback={<div>Loading...</div>}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          {/* <Toolbar>{renderDefaultToolbar(transform)}</Toolbar> */}
          <Viewer
            fileUrl={documentUrl}
            // plugins={[toolbarPluginInstance]}
            httpHeaders={
              currentUser
                ? {
                    'X-USER-TOKEN': currentUser.meta.authenticationToken,
                    'X-USER-EMAIL': currentUser.email,
                  }
                : {}
            }
            renderLoader={() => (
              <div>
                <LoadingView />
              </div>
            )}
            renderError={() => (
              <div>
                <p>
                  There was an issue trying to load this document, please try
                  again later.
                </p>
              </div>
            )}
          />
        </Worker>
      </Suspense>
    </PageWrapper>
  );
};

export default DocumentPreviewPage;
