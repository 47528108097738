import {useImperativeHandle} from 'react';

import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';

const PrepareTenancyInfoModal: ModalDefinition = {
  title: 'Prepare your tenancy',
  buttonsConfig: {
    cancel: {
      label: 'Got it',
    },
  },

  ContentComponent: ({closeModal}, ref) => {
    useImperativeHandle(ref, () => ({
      onCancel: closeModal,
    }));
    return (
      <>
        <Paragraph>
          These next steps involve preparing the details and lease for your
          tenancy.
        </Paragraph>
        <Paragraph>
          We've filled in some of the information for you based on the details
          you provided us for your listing.
        </Paragraph>
        <Paragraph>
          If you need to make changes, you can do so by clicking on the back
          button at the bottom of the page to step through the previous steps.
        </Paragraph>
      </>
    );
  },
};

export default PrepareTenancyInfoModal;
