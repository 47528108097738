import {useCallback, useContext} from 'react';

import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';

import {OBFS, OnboardingFlow} from 'constants/onboarding-flow-steps';
import Property from 'models/properties/Property';
import {FullScreenLoaderContext} from 'providers/FullScreenLoader';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

const useSelectOnboardingTypeHandler = ({property}: {property: Property}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const fullScreenLoader = useContext(FullScreenLoaderContext);

  const onSelectOnboardingType = useCallback(
    async (
      selectedType: Exclude<OnboardingFlow, OnboardingFlow.Undetermined>,
    ) => {
      /**
       * Enable the full screen loader.
       */
      fullScreenLoader.activate();

      /**
       * Set the selection of onboarding type as completed and
       * the selected onboarding type itself.
       */
      property.setOnboardingFlow(selectedType);
      property.setOnboardingStepAsCompleted(OBFS.SelectOnboardingType);

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        fullScreenLoader.deactivate();
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track selection of each onboarding type.
       */
      const selectionEvent = {
        [OnboardingFlow.NewListing]:
          TrackingService.Event.AddProperty_SelectAdvertise,
        [OnboardingFlow.MigrateTenancy]:
          TrackingService.Event.AddProperty_SelectMigrateTenancy,
        [OnboardingFlow.NewTenancy]:
          TrackingService.Event.AddProperty_SelectNewTenancy,
      }[selectedType];

      if (selectionEvent) {
        TrackingService.trackEvent(selectionEvent, {
          propertyId: property.id,
        });
      }

      /**
       * Track general completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.AddProperty_CompleteOnboardTypeStep,
        {
          propertyId: property.id,
        },
      );

      /**
       * Track completion of the add property flow (since it is then passed to
       * either the listing, new or migrate tenancy flows).
       */
      TrackingService.trackEvent(
        TrackingService.Event.AddProperty_CompleteFlow,
        {
          propertyId: property.id,
        },
      );

      /**
       * Track starting the new flow.
       */
      const newFlowEvent = {
        advertise: TrackingService.Event.ListProperty_StartFlow,
        migrate: TrackingService.Event.MigrateTenancy_StartFlow,
        new: TrackingService.Event.NewTenancy_StartFlow,
      }[selectedType];

      if (newFlowEvent) {
        TrackingService.trackEvent(newFlowEvent, {
          propertyId: property.id,
        });
      }

      /**
       * Disable the full screen loader.
       */
      fullScreenLoader.deactivate();

      /**
       * The the property ID in local storage and navigate
       * to the onboarding flow.
       */
      localStorage.setItem('new-property-id', property.id);
      navigate('/properties/new');
    },
    [fullScreenLoader, navigate, property, queryClient],
  );

  return onSelectOnboardingType;
};

export default useSelectOnboardingTypeHandler;
