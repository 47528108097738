import {useCallback, useMemo} from 'react';

import {BiBath} from '@react-icons/all-files/bi/BiBath';
import {BiBed} from '@react-icons/all-files/bi/BiBed';
import {BiCar} from '@react-icons/all-files/bi/BiCar';
import moment from 'moment';
import {HiOutlineShare} from 'react-icons/hi';

import ShareListingModal from 'components/listing/ShareListingModal';
import {Card, Modal} from 'components_sb/layout';
import {CardBannerConfig} from 'components_sb/layout/Card/Card';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Listing, {ListingStatus} from 'models/listings/Listing';
import {Action} from 'types/actions';

const {useModal} = Modal.Imperative;

const pluralize = (num: number, str: string) => {
  if (num <= 1) {
    return str;
  } else {
    return `${str}s`;
  }
};

interface MainCardProps {
  listing: Listing;
}

const MainCard = ({listing}: MainCardProps) => {
  const isMobile = !useTailwindBreakpoint('sm');

  const openModal = useModal();

  const showShareModal = useCallback(
    () => openModal(ShareListingModal, {listing}),
    [openModal, listing],
  );

  /**
   * Actions that are applicable to and accessible
   * from within the card.
   */
  const cardActions = useMemo<Action[]>(
    () => [
      {
        label: 'Share',
        icon: HiOutlineShare,
        onClick: showShareModal,
      },
    ],
    [showShareModal],
  );

  const banner = useMemo<CardBannerConfig | undefined>(() => {
    if (listing.status === ListingStatus.Active) {
      return {
        text: 'Available',
        color: 'green',
      };
    }

    if (listing.status === ListingStatus.UnderOffer) {
      return {
        text: 'Under Offer',
        color: 'amber',
      };
    }

    if (listing.status === ListingStatus.Completed) {
      return {
        text: 'Rented',
        color: 'gray',
      };
    }

    return undefined;
  }, [listing?.status]);

  return (
    <Card
      title={listing.listingTitle}
      subtitle={`${listing.suburb}, ${listing.city}`}
      tags={[listing.propertyType]}
      actions={cardActions}
      banner={banner}>
      <div className="flex flex-col gap-y-2 mt-2">
        <div className="flex justify-start flex-col md:flex-row md:gap-10">
          <div>
            <span className="text-secondary text-sm">Rent</span>
            <h6 className="text-primary text-xl font-semibold">
              ${Number(listing.rentAmount).toFixed(0)}{' '}
              <span className="text-sm">/ week</span>
            </h6>
          </div>

          {listing.bondAmount && (
            <div>
              <span className="text-secondary text-sm">Bond</span>
              <h6 className="text-primary text-xl font-semibold">
                ${Number(listing.bondAmount).toFixed(0)}
              </h6>
            </div>
          )}

          <div>
            <span className="text-secondary text-sm">Available From</span>
            <h6 className="text-primary text-xl font-semibold">
              {moment(listing.startDate).format('DD MMM YYYY')}
            </h6>
          </div>

          {listing.endDate && (
            <div>
              <span className="text-secondary text-sm">Lease Until</span>
              <h6 className="text-primary text-xl font-semibold">
                {moment(listing.endDate).format('DD MMM YYYY')}
              </h6>
            </div>
          )}
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center flex-row justify-start space-x-4 sm:space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-2">
            <BiBed className="w-5 h-5" />
            <span className=" ">
              {listing.bedrooms}{' '}
              <span className="inline-block">
                {isMobile
                  ? pluralize(listing.bedrooms, 'Bed')
                  : pluralize(listing.bedrooms, 'Bedroom')}
              </span>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <BiBath className="w-5 h-5" />
            <span className=" ">
              {listing.bathrooms}{' '}
              <span className="inline-block">
                {isMobile
                  ? pluralize(listing.bathrooms, 'Bath')
                  : pluralize(listing.bathrooms, 'Bathroom')}
              </span>
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <BiCar className="w-5 h-5" />
            <span className="">
              {listing.garages}{' '}
              <span className="inline-block">
                {pluralize(listing.garages, 'Garage')}
              </span>
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MainCard;
