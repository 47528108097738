import {useCallback, useImperativeHandle, useState} from 'react';

import {useFormik} from 'formik';
import type {FormikHelpers} from 'formik';
import {isEmpty} from 'lodash';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {TextField} from 'components_sb/input';
import {ModalDefinition} from 'components_sb/layout';
import LandlordProfile from 'models/users/LandlordProfile';
import useAuth from 'services/useAuth';

type FormValues = {
  promoString: string;
};

const PromoCodeEntryModal: ModalDefinition = {
  title: 'Enter promo or referral code',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Submit',
          loading: 'Submitting',
        },
        handle: 'onSubmit',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, landlordProfile} = props;

    const handleSubmit = async (
      formValues: FormValues,
      actions: FormikHelpers<FormValues>,
    ) => {
      const {landlordSubscription} = landlordProfile;

      landlordSubscription.promoString = formValues.promoString;

      const savedSubscription = await landlordSubscription.save();

      if (savedSubscription) {
        actions.setSubmitting(false);
        toast.success('Your promo code has been added!');
        return true;
      } else {
        for (const key of Object.keys(landlordSubscription.errors)) {
          const message = landlordSubscription.errors[key].fullMessage;
          if (message.includes('not found')) {
            actions.setFieldError(key, 'The code you have entered is invalid');
          } else {
            actions.setFieldError(key, message);
          }
        }
        actions.setSubmitting(false);
        return false;
      }
    };

    const formik = useFormik<FormValues>({
      initialValues: {
        promoString: '',
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        promoString: Yup.string().required(
          'Please enter a code or cancel if you do not have one',
        ),
      }),
    });

    const onSubmit = useCallback(async () => {
      const success = !!(await formik.submitForm());
      if (success) {
        return true;
      } else {
        throw Error();
      }
    }, [formik, closeModal]);

    useImperativeHandle(ref, () => ({
      onSubmit,
    }));

    return (
      <div className="mt-2">
        <TextField
          name="promoString"
          type="text"
          size="base"
          mode="formik"
          form={formik}
          placeholder="Enter your promo or referral code here..."
          required
        />
      </div>
    );
  },
};

export default PromoCodeEntryModal;
