import {useCallback, useEffect, useMemo, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {HiOutlineSparkles} from '@react-icons/all-files/hi/HiOutlineSparkles';
import {RiHandCoinLine} from '@react-icons/all-files/ri/RiHandCoinLine';
import clsx from 'clsx';
import {type FormikHelpers, useFormik} from 'formik';
import {isMobile} from 'react-device-detect';
import {type IconType} from 'react-icons/lib';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {Alert, InlineError} from 'components_sb/feedback';
import {OBFS} from 'constants/onboarding-flow-steps';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

import StepContainer from './StepContainer';

type RentMethod = 'managed' | 'direct';

interface Option {
  id: RentMethod;
  label: string;
  description: string[];
  icon: IconType;
}

/**
 * Options for handling rent.
 */
const OPTIONS: Option[] = [
  {
    id: 'managed',
    label: 'I want to use Keyhook Smart Rent',
    description: [
      'Your tenants pay their rent directly to Keyhook where we then forward it on to your nominated bank account.',
      /**
       * Only refer to how fees are deducted when not on a native platform.
       */
      Capacitor.isNativePlatform()
        ? null
        : 'Keyhook fees are automatically deducted from your rental income.',
    ],
    icon: HiOutlineSparkles,
  },
  {
    id: 'direct',
    label: 'I want to handle my rental income directly',
    description: [
      'Your tenants pay their rent directly to your nominated bank account.',
      /**
       * Only refer to how fees are deducted when not on a native platform.
       */
      Capacitor.isNativePlatform()
        ? 'Keyhook fees are not automatically deducted from your rental income.'
        : 'You will need to add a credit card later to cover Keyhook fees.',
    ],
    icon: RiHandCoinLine,
  },
];

type FormValues = {
  /**
   * Whether the user wants to handle rental income via Smart Rent
   * or by themselves directly.
   */
  managedRentEnabled: boolean;
};

const RentMethodStep: OnboardingFlowStepComponent = ({step, property}) => {
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState(false);
  const {scrollToTop} = useScroll('root-scroll-container');

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    let event;
    if (step === OBFS.MigrateRentMethod) {
      event = TrackingService.Event.MigrateTenancy_StartRentMethodStep;
    } else if (step === OBFS.NewRentMethod) {
      event = TrackingService.Event.NewTenancy_StartRentMethodStep;
    }
    if (event) {
      TrackingService.trackEvent(event, {
        propertyId: property.id,
        tenancyId: tenancy?.id,
      });
    }
  }, [step, property.id, tenancy?.id, scrollToTop]);

  /**
   * Determine whether the user invited their tenant in
   * a previous step.
   */
  const hasInvitedTenant = useMemo(
    () => tenancy.tenancyRequests.length > 0,
    [tenancy],
  );

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      /**
       * Don't proceed if the user has chosen to use Smart Rent
       * but are in the migrate tenancy flow and have not invited their tenant.
       */
      if (
        step === OBFS.MigrateRentMethod &&
        formValues.managedRentEnabled &&
        !hasInvitedTenant
      ) {
        return;
      }

      setSubmitting(true);

      const {managedRentEnabled} = formValues;

      /**
       * Set the chosen rent method on the tenancy for the property.
       */
      tenancy.managedRentEnabled = managedRentEnabled;

      /**
       * For the migrate tenancy flow, if they have not invited any tenants,
       * then this will be the last step, so we set the status to active and
       * the commencement date as today.
       * For the new tenancy flow, we leave it as a draft.
       */
      if (step === OBFS.MigrateRentMethod && !hasInvitedTenant) {
        tenancy.assignAttributes({
          status: 'active',
          startDate: new Date().toString(),
        });
      }

      /**
       * Save the changes to the tenancy.
       */
      if (!(await saveResource(tenancy))) {
        setSubmitting(false);
        return;
      }

      /**
       * The chosen rent method determines how fees will be charged to the
       * user, so we must also update the payment method type on the property.
       */
      property.paymentMethodType = managedRentEnabled ? 'rent' : 'card';

      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = step;

      /**
       * Save the changes to the property.
       */
      const savedProperty = await saveResource(property);

      if (savedProperty) {
        /**
         * Update the property data in the query cache.
         */
        queryClient.setQueryData(
          ['property', {id: property.id, context: 'onboarding-flow'}],
          property,
        );

        /**
         * Track completion of the step.
         */
        let event;
        if (step === OBFS.MigrateRentMethod) {
          event = TrackingService.Event.MigrateTenancy_StartRentMethodStep;
        } else if (step === OBFS.NewRentMethod) {
          event = TrackingService.Event.NewTenancy_StartRentMethodStep;
        }
        if (event) {
          TrackingService.trackEvent(event, {
            propertyId: property.id,
            tenancyId: tenancy.id,
          });
        }

        /**
         * Track completion of the flow if currently in the migration flow
         * and the user has not invited any tenants.
         */
        if (step === OBFS.MigrateRentMethod && !hasInvitedTenant) {
          TrackingService.trackEvent(
            TrackingService.Event.MigrateTenancy_CompleteFlow,
            {
              propertyId: property.id,
              tenancyId: tenancy.id,
            },
          );
          localStorage.removeItem('new-property-id');
          toast.success('Your property has been successfully set up!');
          navigate('/properties/' + property.id, {replace: true});
        }
      } else {
        for (const key of Object.keys(property.errors)) {
          const message = property.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
      }
      setSubmitting(false);
    },
    [step, hasInvitedTenant, property, tenancy, queryClient, navigate],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik<FormValues>({
    initialValues: {
      managedRentEnabled: tenancy.managedRentEnabled ?? null,
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      managedRentEnabled: Yup.boolean()
        .nullable()
        .required(
          'Please select how you would like to handle your incoming rent.',
        ),
    }),
  });

  /**
   * Handle selecting the rent method type.
   */
  const setManagedRentEnabled = useCallback(
    (managedRentEnabled: boolean) => {
      form.setFieldValue('managedRentEnabled', managedRentEnabled);
    },
    [form],
  );

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Determine whether the user has chosen to use Smart Rent
   * but are in the migrate tenancy flow and have not invited their tenant.
   */
  const illegallyChosenSmartRent = useMemo(
    () =>
      step === OBFS.MigrateRentMethod &&
      form.values.managedRentEnabled &&
      !hasInvitedTenant,
    [step, form, hasInvitedTenant],
  );

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
        disabled: illegallyChosenSmartRent,
      },
    },
  });

  console.log('tenancy', tenancy);

  return (
    <StepContainer
      fullWidth
      align="center"
      title="How would you like to handle your incoming rent?"
      subtitle="Manage your rental income the way that works best for you.">
      <Alert
        type="warning"
        show={illegallyChosenSmartRent}
        unmountOnClose={false}
        title="Keyhook Smart Rent is only available if you invite your tenant."
        description={`You can invite your tenant to Keyhook by going to the previous step via the back button below.`}
      />
      {/* TODO: Migrate to use GridSelect component */}
      <div className="flex flex-col items-center space-y-4">
        {OPTIONS.map(({id, label, description, icon: Icon}) => {
          const {managedRentEnabled} = form.values;
          const selected =
            typeof managedRentEnabled === 'boolean' &&
            (id === 'managed' ? !!managedRentEnabled : !managedRentEnabled);
          return (
            <div
              key={id}
              onClick={() => setManagedRentEnabled(id === 'managed')}
              className={clsx(
                'flex flex-col justify-center px-4 py-6 border-2 rounded-xl w-full md:w-1/2 cursor-pointer',
                'transition-color duration-200',
                selected
                  ? 'text-brand-500 border-brand-500 bg-brand-50'
                  : 'text-brand-850 border-brand-75 bg-white',
              )}>
              <div className="flex justify-start items-center gap-4">
                <Icon className="w-8 h-8 flex-shrink-0" />
                <div>
                  <p className="font-semibold mb-2">{label}</p>
                  <p className="text-sm">{description[0]}</p>
                  {description[1] && (
                    <p className="text-sm mt-2">{description[1]}</p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div>
          <InlineError error={form.errors.managedRentEnabled} />
        </div>
      </div>
    </StepContainer>
  );
};

export default RentMethodStep;
