import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Tenancy from 'models/properties/Tenancy';
import TenancyMembership from 'models/properties/TenancyMembership';

@Model()
class BondPayment extends ApplicationRecord {
  static jsonapiType = 'bond_payments';

  @Attr({persist: false}) amountDue: number;
  @Attr({persist: false}) amountPaid: number;
  @Attr({persist: false}) amountPendingClearance: number | null;

  @Attr({persist: false}) receivedAt: string;

  @Attr({persist: false}) reference: string;

  @Attr({persist: false}) status: string;

  @Attr({persist: false}) tenancyId: string;
  @Attr({persist: false}) tenancyMembershipId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
  @BelongsTo('tenancy_memberships') tenancyMembership: TenancyMembership;
}

export default BondPayment;
