import {useCallback, useMemo, useState} from 'react';

import clsx from 'clsx';
import {BsPersonAdd, BsPersonGear} from 'react-icons/bs';
import {
  HiOutlineArrowCircleRight,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
  HiOutlinePencil,
  HiOutlineTrash,
} from 'react-icons/hi';
import {RiBankLine} from 'react-icons/ri';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';

import PageWrapper from 'components/PageWrapper';
import AfterAddPropertyAlert from 'components/property/landlord/detail_page_cards/AfterAddPropertyAlert';
import BondStatusCard from 'components/property/landlord/detail_page_cards/BondStatusCard';
import DocumentsCard from 'components/property/landlord/detail_page_cards/DocumentsCard';
import EndOfTenancyCard from 'components/property/landlord/detail_page_cards/EndOfTenancyCard';
import InspectionsCard from 'components/property/landlord/detail_page_cards/InspectionsCard';
import MainActions from 'components/property/landlord/detail_page_cards/MainActions';
import MaintenanceRequestsCard from 'components/property/landlord/detail_page_cards/MaintenanceRequestsCard';
import PendingTenancyCard from 'components/property/landlord/detail_page_cards/PendingTenancyCard';
import PretenancyInspectionAlert from 'components/property/landlord/detail_page_cards/PretenancyInspectionAlert';
import RentPriceChangesCard from 'components/property/landlord/detail_page_cards/RentPriceChangesCard';
import RentStatusCard from 'components/property/landlord/detail_page_cards/RentStatusCard';
import TenancyRenewalCard from 'components/property/landlord/detail_page_cards/TenancyRenewalCard';
import TenancySelectCard from 'components/property/landlord/detail_page_cards/TenancySelectCard';
import TenantsCard from 'components/property/landlord/detail_page_cards/TenantsCard';
import CompleteProfileSection from 'components/property/landlord/profile_blocks/CompleteProfileSection';
import LandlordPropertyDetailCard from 'components/property/landlord/PropertyDetailCard';
import PropertyStatCards from 'components/property/PropertyStatCards';
import PrepareNextTenancyModal from 'components/tenancy/PrepareNextTenancyModal';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const PropertyDetailPage = () => {
  usePageVisit('PropertyDetailPage');
  const {id} = useParams();

  const navigate = useNavigate();

  const [selectedTenancyId, setSelectedTenancyId] = useState<
    string | undefined
  >(undefined);
  const [prepareNextTenancyModalOpen, setPrepareNextTenancyModalOpen] =
    useState(false);

  const {
    isLoading,
    isSuccess,
    data: property,
    error,
  } = useQuery(
    ['property', {id, context: 'detail-page'}],
    async () => {
      const res = await Property.select({
        // Select everything in properties
        tenancies: [
          'status',
          'total_rent',
          'rental_period',
          'start_date',
          'end_date',
          'next_inspection_date',
          'external_bond_id',
          'external_bond_filed',
          'ready_to_be_filed',
          'is_new',
          'bond',
          'head_tenant_id',
          'property_id',
        ],
        users: ['name', 'avatar', 'email'],
        tenancy_requests: ['name', 'renter_email', 'created_at', 'accepted'],
        documents: [
          'document',
          'document_type',
          'private',
          'name',
          'documentable_type',
          'created_at',
        ],
        inspections: [
          'finalized_date',
          'estimated_date',
          'status',
          'done_by',
          'inspection_period_type',
          'inspection_actions_count',
        ],
        room_plans: ['id'],
        listings: ['id', 'public_id', 'status', 'created_at'],
      })
        .selectExtra(['extra_information'])
        .includes([
          'documents',
          'listings',
          {
            tenancies: [
              {tenancy_memberships: 'renter'},
              {tenancy_requests: 'renter'},
              {tenancy_invites: 'renter'},
              {inspections: 'inspection_actions'},
              'documents',
              'active_tenancy_continuation',
              'service_requests',
              'unprocessed_rent_price_changes',
              'unpaid_landlord_payments',
              'end_of_tenancy',
            ],
          },
          'room_plan',
        ])
        .order({'tenancies.start_date': 'desc', 'listings.created_at': 'desc'})
        .where({
          'listings.status': ['draft', 'pending_approval', 'active', 'paused'],
        })
        .find(id);
      return res.data;
    },
    {retry: 1, refetchOnWindowFocus: false},
  );

  const tenancy: Tenancy | undefined = useMemo(() => {
    if (!property) {
      return undefined;
    }

    if (selectedTenancyId) {
      if (selectedTenancyId === '-1') {
        return undefined;
      } else {
        return property.tenancies.find((t) => t.id === selectedTenancyId);
      }
    } else {
      const t = property.tenancies.find(
        (t) => t.status === 'active' || t.status === 'active_periodic',
      );
      if (t) {
        return t;
      } else if (property.tenancies.length > 0) {
        return property.tenancies[0];
      } else {
        return undefined;
      }
    }
  }, [property, selectedTenancyId]);

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const deleteProperty = useCallback(async () => {
    if (property) {
      const tenancyCount = property.tenancies.filter((t) =>
        ['active', 'active_periodic', 'ended'].includes(t.status),
      ).length;

      if (tenancyCount > 0) {
        toast.error("You can't delete a property with active tenancies.");
      } else {
        const result = await property.destroy();
        if (result) {
          toast.success('Property deleted successfully.');
          navigate('/', {replace: true});
        } else {
          toast.error(
            'There was an error deleting your property, please contact support if you would like assistance.',
          );
        }
      }
    }
  }, [navigate, property]);

  const confirmDeleteProperty = useCallback(() => {
    setConfirmationOptions({
      title: 'Delete your property',
      message:
        'Are you sure you want to do this? This action cannot be undone.',
      buttonTitle: 'Delete',
      color: 'error',
      action: deleteProperty,
    });
  }, [setConfirmationOptions, deleteProperty]);

  useTitle(property ? property.streetAddress : '');

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !isSuccess
        ? null
        : [
            /**
             * Edit property.
             */
            {
              label: 'Edit property',
              icon: HiOutlinePencil,
              linkTo: `edit`,
            },

            /**
             * Tenancy related actions:
             */
            ...(tenancy && tenancy.id
              ? [
                  /**
                   * Invite tenants.
                   */
                  ...(tenancy.isSigned
                    ? [
                        {
                          label: 'Invite tenants',
                          icon: BsPersonAdd,
                          linkTo: `tenancies/${tenancy.id}/invite-tenants`,
                        },
                      ]
                    : []),

                  /**
                   * Landlord Payments
                   */

                  {
                    label: 'Rent Ledger',
                    icon: HiOutlineCurrencyDollar,
                    linkTo: `tenancies/${tenancy.id}/payments`,
                  },

                  /**
                   * Change rent.
                   */
                  ...(tenancy.unprocessedRentPriceChanges?.length === 0 &&
                  tenancy.isActive
                    ? [
                        {
                          label: 'Change rent',
                          icon: HiOutlineCurrencyDollar,
                          linkTo: `tenancies/${tenancy.id}/rent-changes/new`,
                        },
                      ]
                    : []),

                  /**
                   * Give Notice
                   */
                  ...(tenancy.isActive
                    ? [
                        {
                          label: 'Give Notice',
                          icon: HiOutlineDocumentText,
                          linkTo: `tenancies/${tenancy.id}/notices/new`,
                        },
                      ]
                    : []),

                  /**
                   * View notices
                   * */
                  {
                    label: 'View notices',
                    icon: HiOutlineDocumentDuplicate,
                    linkTo: `tenancies/${tenancy.id}/notices`,
                  },
                ]
              : []),

            /**
             * Manage preferred tradespeople.
             */
            {
              label: 'Manage preferred tradespeople',
              icon: BsPersonGear,
              linkTo: `preferred-tradespeople`,
            },

            /**
             * View bank account information.
             */
            {
              label: 'View bank account information',
              icon: RiBankLine,
              linkTo: `bank-account`,
            },

            /**
             * Property related actions:
             */
            ...(property && property.id
              ? [
                  /**
                   * Edit inspection template.
                   */
                  ...(property.roomPlan
                    ? [
                        {
                          label: 'Edit inspection template',
                          icon: HiOutlinePencil,
                          linkTo: `inspection-template`,
                        },
                      ]
                    : []),

                  /**
                   * Prepare next tenancy.
                   */
                  ...(property.tenanciesCount >= 1
                    ? [
                        {
                          label: 'Prepare next tenancy',
                          icon: HiOutlineArrowCircleRight,
                          onClick: () => setPrepareNextTenancyModalOpen(true),
                        },
                      ]
                    : []),

                  /**
                   * Delete property.
                   */
                  ...(property.tenancies.filter((t) =>
                    ['active', 'active_periodic', 'ended'].includes(t.status),
                  ).length === 0
                    ? [
                        {
                          label: 'Delete property',
                          icon: HiOutlineTrash,
                          onClick: confirmDeleteProperty,
                        },
                      ]
                    : []),
                ]
              : []),
          ],
    [tenancy, isSuccess, property, confirmDeleteProperty],
  );

  const lgBreakpoint = useTailwindBreakpoint('md');

  return error ? (
    errorViewForError(error)
  ) : (
    <PageWrapper
      title="Property"
      loading={isLoading}
      actions={pageActions}
      showButtonsAsDropDown={true}
      dropdownTitle="Actions"
      disablePadding={{
        banner: !lgBreakpoint,
        content: false,
      }}
      banner={{
        container: ({children}) => (
          <div className="relative">
            {/* Blurred main image as the background banner on large screens */}
            <div className="hidden md:flex absolute z-0 top-0 left-0 w-full h-full overflow-hidden">
              <div
                className="bg-cover bg-center blur-lg -mx-6 -my-6"
                style={{
                  backgroundImage: `url(${property.mainImage})`,
                  /**
                   * Extend the image outside of the container so that we don't
                   * see the blurred edges.
                   */
                  width: 'calc(100% + 48px)',
                  height: 'calc(100% + 48px)',
                }}
              />
            </div>
            {/* Add an inner shadow to the bottom of the banner on large screens */}
            <div
              className={clsx(
                'hidden md:flex',
                'absolute z-10 bottom-0 left-0',
                'w-full h-16',
                'bg-gradient-to-t from-neutral-800 to-transparent',
                'origin-bottom',
                'scale-y-100 opacity-[30%]',
              )}
            />
            {/* Render the banner content at the same width as the page content */}
            <div
              className={clsx(
                'py-0 md:py-8 flex items-center w-full h-full relative',
                'border-b-2 md:border-none border-brand-50',
              )}>
              {children}
            </div>
          </div>
        ),
        content: (
          <LandlordPropertyDetailCard property={property} tenancy={tenancy} />
        ),
      }}>
      {/* The below alert is only shown after redirecting from adding a property */}
      <AfterAddPropertyAlert />

      {/* The key actions the landlord can perform on the property */}
      <MainActions property={property} tenancy={tenancy} />

      {/* Key stats about the property */}
      <PropertyStatCards property={property} />

      <PretenancyInspectionAlert tenancy={tenancy} />

      {property && tenancy && !tenancy.isDraft && (
        <CompleteProfileSection property={property} tenancy={tenancy} />
      )}

      <TenancySelectCard
        property={property}
        tenancy={tenancy}
        setSelectedTenancyId={setSelectedTenancyId}
      />

      <EndOfTenancyCard property={property} tenancy={tenancy} />
      <TenancyRenewalCard property={property} tenancy={tenancy} />
      <RentPriceChangesCard property={property} tenancy={tenancy} />

      <RentStatusCard property={property} tenancy={tenancy} />

      <BondStatusCard property={property} tenancy={tenancy} />

      <PendingTenancyCard property={property} tenancy={tenancy} />

      <InspectionsCard tenancy={tenancy} />
      <MaintenanceRequestsCard tenancy={tenancy} />
      <DocumentsCard property={property} tenancy={tenancy} />
      <TenantsCard property={property} tenancy={tenancy} />

      {property && property.tenanciesCount >= 1 ? (
        <PrepareNextTenancyModal
          property={property}
          isOpen={prepareNextTenancyModalOpen}
          setIsOpen={setPrepareNextTenancyModalOpen}
        />
      ) : null}
    </PageWrapper>
  );
};

export default PropertyDetailPage;
