import {useCallback, useEffect, useMemo, useState} from 'react';

import clsx from 'clsx';
import {
  type FormikHelpers,
  useFormik,
  FormikConfig,
  FormikValues,
} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import InlineMoneyField from 'components/forms_fields/InlineMoneyField';
import ToggleButtonGroup from 'components/forms_fields/ToggleButtonGroup';
import {GridSelect, TextField} from 'components_sb/input';
import NumberField from 'components_sb/input/NumberField/NumberField';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

import StepContainer from '../common/StepContainer';

const rentalPeriods = ['Weekly', 'Fortnightly'];

type FormValues = {
  totalRent: string;
  bond: number;
  rentalPeriod: 'Weekly' | 'Fortnightly';
  weeksRentInAdvance: number;
};

const FinancialsStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartRentDetailsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id, scrollToTop]);

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the tenancy.
       */
      tenancy.assignAttributes(formValues);

      /**
       * Save the changes to the tenancy.
       */
      const savedTenancy = await saveResource(tenancy);

      if (savedTenancy) {
        /**
         * Set changes on the property.
         */
        property.lastOnboardingStepCompleted = 'new_financials';
        property.setProfileStepAsCompleted('property_documents');
        property.setProfileStepAsCompleted('tenancy_information');
        if (property.tenancies.length === 0) {
          property.tenancies = [tenancy];
        } else {
          property.tenancies.unshift(tenancy);
        }

        /**
         * Save the changes to the property.
         */
        if (!(await saveResource(property))) {
          setSubmitting(false);
          return;
        }

        /**
         * Update the property data in the query cache.
         */
        queryClient.setQueryData(
          ['property', {id: property.id, context: 'onboarding-flow'}],
          property,
        );

        /**
         * Track completion of the step.
         */
        TrackingService.trackEvent(
          TrackingService.Event.NewTenancy_CompleteRentDetailsStep,
          {
            propertyId: property.id,
          },
        );

        setSubmitting(false);
      } else {
        for (const key of Object.keys(tenancy.errors)) {
          const message = tenancy.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
      }
    },
    [property, queryClient, tenancy],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        totalRent: tenancy ? tenancy.totalRent : '0',
        bond: tenancy ? tenancy.bond : '0',
        rentalPeriod: tenancy ? tenancy.rentalPeriod : 'Weekly',
        weeksRentInAdvance: tenancy ? tenancy.weeksRentInAdvance : '0',
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        bond: Yup.number()
          .min(0)
          .required()
          .nullable()
          .label('Bond')
          .test(
            'max-bond-is-allowed',
            'Bond can not be greater than 4 weeks rent',
            function (value) {
              if (!value || value == 0) {
                return true;
              }
              const rentalPeriod = this.parent.rentalPeriod;
              const rent = this.parent.totalRent;
              if (rentalPeriod === 'Weekly') {
                return value <= rent * 4;
              } else if (rentalPeriod === 'Fortnightly') {
                return value <= rent * 2;
              } else {
                return true;
              }
            },
          ),
        totalRent: Yup.number().nullable().min(1).required().label('Rent'),
        rentalPeriod: Yup.string()
          .oneOf(rentalPeriods)
          .required()
          .label('Rental Period'),
        weeksRentInAdvance: Yup.number()
          .min(0)
          .max(2)
          .required()
          .label('Rent in advance'),
      }),
    }),
    [handleSubmit, tenancy],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  const quickSetBond = (numberOfWeeks: number) => {
    if (form.values.totalRent && !!form.values.totalRent) {
      let rentAmount: number;
      if (form.values.rentalPeriod === 'Weekly') {
        rentAmount = Number(form.values.totalRent);
      } else {
        rentAmount = Number(form.values.totalRent) / 2.0;
      }
      const bondAmount = rentAmount * numberOfWeeks;
      form.setFieldValue('bond', bondAmount);

      TrackingService.trackEvent(TrackingService.Event.QuickSetBond, {
        numberOfWeeks,
      });
    }
  };

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    // TODO: Add a subtitle
    <StepContainer title="Next up, rent information..." subtitle="">
      <InlineMoneyField
        formik={form}
        name="totalRent"
        label="Rent amount"
        placeholder="E.g. $500"
      />

      <GridSelect
        mode="manual:select"
        labelProps={{
          title: 'Frequency',
          size: 'xl',
        }}
        multiple={false}
        minColumns={2}
        options={[
          {
            id: 'Weekly',
            label: 'Weekly',
          },
          {
            id: 'Fortnightly',
            label: 'Fortnightly',
          },
        ]}
        value={form.values.rentalPeriod}
        onChange={(value) => form.setFieldValue('rentalPeriod', value)}
      />

      <InlineMoneyField
        formik={form}
        name="bond"
        label="Bond amount"
        placeholder="E.g. $1,000"
        additional={
          <div className="flex justify-start items-center gap-4">
            <div className="dropdown dropdown-start">
              <label
                tabIndex={0}
                className={clsx(
                  'whitespace-nowrap',
                  'btn btn-sm',
                  (!form.values.totalRent ||
                    form.values.totalRent === '' ||
                    form.values.totalRent === '0') &&
                    'btn-disabled',
                )}>
                Quick Set
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                {[1, 2, 3, 4].map((numWeeks) => (
                  <li key={numWeeks}>
                    <a
                      className="!text-sm"
                      onClick={() => quickSetBond(numWeeks)}>
                      {numWeeks} Weeks Rent
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }
      />

      <GridSelect
        mode="manual:select"
        labelProps={{
          title: 'Rent in advance',
          size: 'xl',
        }}
        multiple={false}
        minColumns={3}
        options={[
          {
            id: '0',
            label: 'None',
          },
          {
            id: '1',
            label: '1 Week',
          },
          {
            id: '2',
            label: '2 Weeks',
          },
        ]}
        value={
          typeof form.values.weeksRentInAdvance === 'number'
            ? form.values.weeksRentInAdvance.toString()
            : null
        }
        onChange={(value: string) =>
          form.setFieldValue('weeksRentInAdvance', parseInt(value))
        }
      />
    </StepContainer>
  );
};

export default FinancialsStep;
