import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import LandlordPaymentExpense from 'models/payments/LandlordPaymentExpense';
import RentPayment from 'models/payments/RentPayment';
import Tenancy from 'models/properties/Tenancy';

@Model()
class LandlordPayment extends ApplicationRecord {
  static jsonapiType = 'landlord_payments';

  @Attr({persist: false}) grossAmount: string;
  @Attr({persist: false}) expensesAmount: string;
  @Attr({persist: false}) netAmount: string;

  @Attr({persist: false}) amountOfRentPaid: string;
  @Attr({persist: false}) pendingAmount: string;

  @Attr({persist: false}) paymentDate: string;

  @Attr({persist: false}) reference: string;

  @Attr({persist: false}) rentCollectionDate: string;

  @Attr({persist: false}) sentAt: string;

  @Attr({persist: false}) status: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr({persist: false}) tenancyId: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
  @HasMany('landlord_payment_expenses')
  landlordPaymentExpenses: LandlordPaymentExpense[];
  @HasMany('rent_payments') rentPayments: RentPayment[];
}

export default LandlordPayment;
