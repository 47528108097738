import React from 'react';

import moment from 'moment';

import {Card} from 'components_sb/layout';
import useInspectionStore from 'stores/InspectionStore';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const InspectionInProgressCard = () => {
  const inspection = useInspectionStore((state) => state.inspection);

  const date =
    inspection.doneBy === 'landlord'
      ? moment(inspection.finalizedDate).format(DATE_TIME_FORMAT)
      : moment(inspection.finalizedDate).format(DATE_FORMAT);

  return (
    <Card title="Inspection in progress">
      <p className="mt-2">
        This inspection is scheduled for {date}. You will be notified once it's
        complete and ready to be reviewed.
      </p>
    </Card>
  );
};

export default InspectionInProgressCard;
