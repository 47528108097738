import {useCallback, useImperativeHandle} from 'react';

import {useFormik} from 'formik';
import type {FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {ResizingImageField} from 'components/forms_fields';
import {ModalDefinition} from 'components_sb/layout';

type FormValues = {
  mainImage: string;
};

const ChangePhotoModal: ModalDefinition = {
  title: 'Change your property photo',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, property} = props;

    const queryClient = useQueryClient();

    const handleSubmit = async (
      formValues: FormValues,
      actions: FormikHelpers<FormValues>,
    ) => {
      property.mainImage = formValues.mainImage;

      const result = await property.save();

      if (result) {
        await queryClient.invalidateQueries([
          'property',
          {id: property.id, context: 'detail-page'},
        ]);

        actions.setSubmitting(false);
        closeModal();
        toast.success('Photo successfully updated!');
      } else {
        for (const key of Object.keys(property.errors)) {
          const message = property.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
        actions.setSubmitting(false);
      }
    };

    const formik = useFormik<FormValues>({
      initialValues: {
        mainImage: '',
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        mainImage: Yup.string().min(1).label('Main Image'),
      }),
    });

    const onSave = useCallback(async () => {
      formik.submitForm();
      return false;
    }, [formik]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <div className="mt-2">
        <ResizingImageField
          formik={formik}
          name="mainImage"
          label="Select a photo"
          accept=".png,.jpeg,.jpg"
          width={1000}
          height={600}
        />
      </div>
    );
  },
};

export default ChangePhotoModal;
