import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import {Time} from '@internationalized/date';
import {DateValue, useTimeField} from '@react-aria/datepicker';
import {useLocale} from '@react-aria/i18n';
import {useTimeFieldState} from '@react-stately/datepicker';
import clsx from 'clsx';
import {FormikProps, useFormik} from 'formik';
import * as Yup from 'yup';

import {InlineError} from 'components_sb/feedback';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';

import Segment from './Segment';

export const yupTimeSchema = Yup.object({
  hour: Yup.number(),
  minute: Yup.number(),
  second: Yup.number(),
  millisecond: Yup.number(),
});

interface TimePickerProps {
  /**
   * The name of the field.
   */
  name: string;
  /**
   * An instance of a formik Form.
   */
  form: ReturnType<typeof useFormik> | FormikProps<any>;
  /**
   * Props to pass through to the FieldLabel component.
   */
  labelProps?: FieldLabelProps;
}

/**
 * A custom user-friendly time picker component.
 */
const TimePicker: FunctionComponent<TimePickerProps> = ({
  name,
  labelProps,
  form,
}) => {
  const {locale} = useLocale();

  /**
   * Update the value for the field in the form when the value changes.
   */
  const handleChange = useCallback(
    (value: Time) => {
      form.setFieldValue(name, value);
    },
    [form, name],
  );

  /**
   * Get the current value for the field from the form.
   */
  const formValue = useMemo(() => form.values[name], [form, name]);

  const fieldState = useTimeFieldState({
    value: formValue,
    onChange: handleChange,
    locale,
  });

  const ref = useRef();

  const {fieldProps} = useTimeField(
    {
      'aria-label': labelProps?.title ?? undefined,
    },
    fieldState,
    ref,
  );

  /**
   * Determine the error messages to display.
   */
  const errorMessages = useMemo<string[]>(() => {
    let errors: string[] = [];
    errors = [form.getFieldMeta(name).error];
    return errors.filter((error) => !!error);
  }, [form, name]);

  return (
    <div className="flex flex-col items-start">
      {labelProps && <FieldLabel htmlFor={name} {...labelProps} />}
      <div
        {...fieldProps}
        ref={ref}
        className={clsx(
          'flex flex-row',
          'w-full max-w-full box-border', // Size
          'transition-all duration-300', // Transition
          'bg-brand-50 hover:bg-brand-75 ', // Background
          'placeholder-brand-800 placeholder-opacity-30', // Placeholder
          'outline-none border-none ring-1 ring-brand-75', // Untouched
          'focus:ring-2 focus:ring-brand-500', // Focus
          // Padding
          'px-4',
          // Height
          'h-12',
          // Roundness
          'rounded-lg',
          // Error
          errorMessages.length && '!ring-2 !ring-error',
        )}>
        {fieldState.segments.map((segment, i) => (
          <Segment key={i} segment={segment} fieldState={fieldState} />
        ))}
      </div>
      <div
        className={clsx(
          'transition-all duration-300',
          errorMessages.length ? 'pt-2' : 'pt-0',
        )}>
        <InlineError error={errorMessages[0]} />
      </div>
    </div>
  );
};

export default TimePicker;
