import {ReactNode, useMemo} from 'react';

import clsx from 'clsx';

import getLinkComponent from 'utilities/getLinkComponent';

const classes = {
  // Classes for all permutations
  base: {
    main: clsx(
      'cursor-pointer',
      'overflow-ellipsis',
      'text-base font-medium',
      'group transition duration-300',
      'inline-block', // Prevents filling full width
    ),
    underline: clsx(
      'block',
      'max-w-0',
      'group-hover:max-w-full',
      'transition-all',
      'duration-500',
      'h-0.5',
    ),
  },
  // Classes based on color
  color: {
    label: {
      brand: 'text-brand-500',
      white: 'text-white',
    },
    underline: {
      brand: 'bg-brand-500',
      white: 'bg-white',
    },
  },
};

interface LinkProps {
  /**
   *  The text shown as the link.
   */
  children: string | ReactNode;
  /**
   * The path or URL to navigate to.
   */
  to: string;
  /**
   *  The color of the link.
   */
  color?: 'brand' | 'white';
  /**
   * Opens the link in a new tab instead of the current tab.
   */
  openInNewTab?: boolean;
  /**
   * Whether the link text should be able to span muliple lines.
   */
  allowWrap?: boolean;
}

/**
 *  A type of button intended for navigation or actions that
 *  do not directly affect the current page/flow.
 */
const Link = ({
  children,
  to,
  color = 'brand',
  openInNewTab = false,
  allowWrap,
}: LinkProps) => {
  const LinkComponent = useMemo(() => getLinkComponent(to), [to]);
  return (
    <span className="inline-block relative">
      <LinkComponent
        to={to}
        target={openInNewTab ? '_blank' : '_self'}
        className={clsx(classes.base.main, classes.color.label[color])}>
        <span
          className={clsx(
            allowWrap ? 'whitespace-normal' : 'whitespace-nowrap',
          )}>
          {children}
        </span>
        <span
          className={clsx(
            classes.base.underline,
            classes.color.underline[color],
          )}></span>
      </LinkComponent>
    </span>
  );
};

export default Link;
