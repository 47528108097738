import {useMemo} from 'react';

import {Browser} from '@capacitor/browser';
import {HiOutlineDocumentAdd, HiOutlineDocumentText} from 'react-icons/hi';

import DocumentsTable from 'components/document/DocumentsTable';
import {Card} from 'components_sb/layout';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import Document from 'models/properties/Document';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {Action} from 'types/actions';

const DocumentsCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const showDocument = async (document: Document) => {
    await Browser.open({url: document.document, windowName: document.name});
  };

  /**
   * Concatenate the property and tenancy documents.
   */
  const documents = useMemo(
    () => (property?.documents ?? []).concat(tenancy?.documents ?? []),
    [property, tenancy],
  );

  /**
   * Actions that are applicable to and accessible
   * from within the card.
   */
  const cardActions = useMemo<Action[]>(
    () => [
      {
        label: 'Add document',
        icon: HiOutlineDocumentAdd,
        linkTo: 'documents/new',
      },
    ],
    [],
  );

  return (
    <Card icon={HiOutlineDocumentText} title="Documents" actions={cardActions}>
      {documents.length ? (
        <DocumentsTable
          documents={documents}
          clickHandler={showDocument}
          showParent={true}
          tenancyisMigrated={!!tenancy && !tenancy.isNew}
          queryKey={['property', {id: property.id, context: 'detail-page'}]}
        />
      ) : (
        <EmptyContentPlaceholder
          type="documents"
          message="There are currently no documents for this property"
        />
      )}
    </Card>
  );
};

export default DocumentsCard;
