import {ReactNode, useEffect, useMemo} from 'react';

import {
  useFloating,
  offset,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  ReferenceType,
  Placement,
  size,
} from '@floating-ui/react';

import {Popover} from 'components_sb/layout';

export interface PopoverHookProps {
  open: boolean;
  onOpenChange?: (open: boolean, event?: Event) => void;

  /**
   * If width is set to 'reference', the popover will be the
   * same width as the the reference element.
   */
  width?: number | 'reference';

  contentClassName?: string;

  placement?: Placement;

  content: ReactNode;
}

export interface PopoverHookReturn {
  element: ReactNode;
  referenceRef: ((node: ReferenceType) => void) &
    ((node: ReferenceType) => void);
  getReferenceProps: (
    userProps?: React.HTMLProps<Element>,
  ) => Record<string, unknown>;
}

interface PopoverHook {
  (props: PopoverHookProps): PopoverHookReturn;
}

const usePopover: PopoverHook = ({
  open,
  onOpenChange,
  content,
  width,
  contentClassName = '',
  placement = 'bottom',
}) => {
  /**
   * Create a floating instance.
   */
  const floating = useFloating({
    open,
    onOpenChange,
    placement,
    middleware: [
      /**
       * Add a gap between the reference and the popover.
       */
      offset(5),

      /**
       * Keep the popover in view of the window along the x-axis.
       */
      shift({
        /**
         * Ensure at least 10px of horizontal padding when shifting.
         */
        padding: 10,
      }),

      size({
        apply({rects, elements}) {
          let evaluatedWidth = 'auto';
          if (typeof width === 'number') {
            evaluatedWidth = `${width}px`;
          } else if (width === 'reference') {
            evaluatedWidth = `${rects.reference.width}px`;
          }
          Object.assign(elements.floating.style, {
            width: evaluatedWidth,
          });
        },
      }),
    ],
  });

  const click = useClick(floating.context);
  const dismiss = useDismiss(floating.context);

  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
  ]);

  const element = useMemo(
    () => (
      <Popover
        contentClassName={contentClassName}
        floating={floating}
        getFloatingProps={getFloatingProps}>
        {content}
      </Popover>
    ),
    [floating, getFloatingProps, content, contentClassName],
  );

  return {
    element,
    referenceRef: floating.refs.setReference,
    getReferenceProps,
  };
};

export default usePopover;
