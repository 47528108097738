import {useImperativeHandle, useState} from 'react';

import {ModalDefinition} from 'components_sb/layout';
import InspectionItem from 'models/inspections/InspectionItem';
import useInspectionStore from 'stores/InspectionStore';

const AddInspectionItemModal: ModalDefinition = {
  title: 'Add an item',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const [itemName, setItemName] = useState('');

    const [inspection, inspectionItems, setInspectionItems, selectedRoomKey] =
      useInspectionStore(
        ({
          inspection,
          inspectionItems,
          setInspectionItems,
          selectedRoomKey,
        }) => [
          inspection,
          inspectionItems,
          setInspectionItems,
          selectedRoomKey,
        ],
      );

    const onSave = async () => {
      const item = new InspectionItem();
      item.inspectionId = inspection.id;
      item.room = selectedRoomKey;
      item.name = itemName;
      item.orderIndex = props.orderIndex;

      const result = await item.save();

      if (result) {
        inspectionItems.push(item);
        setInspectionItems([...inspectionItems]);
      } else {
        console.log(item.errors);
      }

      inspection.deleteSignOffs();
    };

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <>
        <p className="text-secondary">Enter the name of the item</p>
        <div className="mt-2">
          <input
            className="input input-bordered w-full"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            placeholder="Item name..."
          />
        </div>
      </>
    );
  },
};

export default AddInspectionItemModal;
