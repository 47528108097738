import {camelCase, isArray, transform, isObject} from 'lodash';

export const isDefined = (val: any) => {
  if (val === null || val === undefined) {
    return false;
  } else {
    return true;
  }
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const camelize = (obj: Record<string, unknown>) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = isArray(target) ? key : camelCase(key);
      result[camelKey] = isObject(value)
        ? camelize(value as Record<string, unknown>)
        : value;
    },
  );
