import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import NewRentPriceChangeForm from 'components/rent_price_changes/NewRentPriceChangeForm';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Tenancy from 'models/properties/Tenancy';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const NewRentPriceChangePage = () => {
  useTitle('Rent Change');
  usePageVisit('NewRentPriceChangePage');

  const {id, propertyId} = useParams();

  const {data, isLoading, error} = useQuery(
    `tenancy-${id}-new-rent-change`,
    async () => {
      const tenancy = await Tenancy.select([
        'id',
        'start_date',
        'end_date',
        'rental_period',
        'total_rent',
      ]).find(id);

      return tenancy.data;
    },
  );
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSuccess = async () => {
    await queryClient.invalidateQueries([
      'property',
      {id: propertyId, context: 'detail-page'},
    ]);
    navigate(`/properties/${propertyId}`, {replace: true});
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Rent Change">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Rent Change">
        <Card title="Rent Change" className="overflow-visible">
          <Paragraph>
            Please note that under the new NZ tenancy laws, you are only allowed
            to increase rent once every 12 months. You must also provide your
            tenants with a minimum of 60 days notice.
          </Paragraph>
          <Paragraph>
            Keyhook also only allow days that match up with the day of the week
            your tenants currently pay rent.
          </Paragraph>

          <NewRentPriceChangeForm tenancy={data} onSuccess={onSuccess} />
        </Card>
      </PageWrapper>
    );
  }
};

export default NewRentPriceChangePage;
