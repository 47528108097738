import ApplicationRecord from 'models/ApplicationRecord';
import Associate from 'models/associates/Associate';
import AssociatePayment from 'models/associates/AssociatePayment';
import ChatMessage from 'models/ChatMessage';
import Conversation from 'models/Conversation';
import Inspection from 'models/inspections/Inspection';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import InspectionSignOff from 'models/inspections/InspectionSignOff';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import ExternalListingAd from 'models/listings/ExternalListingAd';
import Listing from 'models/listings/Listing';
import ListingPhoto from 'models/listings/ListingPhoto';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import OpenHome from 'models/listings/OpenHome';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import RentalApplication from 'models/listings/RentalApplication';
import RentalApplicationApplicant from 'models/listings/RentalApplicationApplicant';
import SearchLocation from 'models/listings/SearchLocation';
import BondPayment from 'models/payments/BondPayment';
import Expense from 'models/payments/Expense';
import LandlordPayment from 'models/payments/LandlordPayment';
import LandlordPaymentExpense from 'models/payments/LandlordPaymentExpense';
import LandlordSubscription from 'models/payments/LandlordSubscription';
import LatePaymentNotification from 'models/payments/LatePaymentNotification';
import RentPayment from 'models/payments/RentPayment';
import Subscription from 'models/payments/Subscription';
import Document from 'models/properties/Document';
import EndOfTenancy from 'models/properties/EndOfTenancy';
import FormalNotice from 'models/properties/FormalNotice';
import Property from 'models/properties/Property';
import PropertyTransfer from 'models/properties/PropertyTransfer';
import RentPriceChange from 'models/properties/RentPriceChange';
import RoomPlan from 'models/properties/RoomPlan';
import Tenancy from 'models/properties/Tenancy';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyMembership from 'models/properties/TenancyMembership';
import TenancyRequest from 'models/properties/TenancyRequest';
import PreferredTradesman from 'models/service_requests/PreferredTradesman';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import ServiceRequestAttachment from 'models/service_requests/ServiceRequestAttachment';
import ServiceRequestQuote from 'models/service_requests/ServiceRequestQuote';
import UserBlock from 'models/UserBlock';
import LandlordProfile from 'models/users/LandlordProfile';
import PromoCode from 'models/users/PromoCode';
import PushDevice from 'models/users/PushDevice';
import RenterProfile from 'models/users/RenterProfile';
import User from 'models/users/User';

ApplicationRecord;

InspectionAction.jsonapiType;
Inspection.jsonapiType;
InspectionItem.jsonapiType;
InspectionItemAttachment.jsonapiType;
InspectionSignOff.jsonapiType;

EnquiryMessage.jsonapiType;
EnquiryThread.jsonapiType;
ExternalListingAd.jsonapiType;
Listing.jsonapiType;
ListingPhoto.jsonapiType;
ListingRentalApplication.jsonapiType;
OpenHome.jsonapiType;
OpenHomeAttendee.jsonapiType;
RentalApplication.jsonapiType;
RentalApplicationApplicant.jsonapiType;
SearchLocation.jsonapiType;

BondPayment.jsonapiType;
Expense.jsonapiType;
LandlordPayment.jsonapiType;
LandlordPaymentExpense.jsonapiType;
LandlordSubscription.jsonapiType;
LatePaymentNotification.jsonapiType;
RentPayment.jsonapiType;
Subscription.jsonapiType;

Document.jsonapiType;
Property.jsonapiType;
PropertyTransfer.jsonapiType;
RentPriceChange.jsonapiType;
RoomPlan.jsonapiType;
Tenancy.jsonapiType;
TenancyContinuation.jsonapiType;
TenancyInvite.jsonapiType;
TenancyMembership.jsonapiType;
TenancyRequest.jsonapiType;
FormalNotice.jsonapiType;
EndOfTenancy.jsonapiType;

PreferredTradesman.jsonapiType;
ServiceRequest.jsonapiType;
ServiceRequestAttachment.jsonapiType;
ServiceRequestQuote.jsonapiType;

LandlordProfile.jsonapiType;
PushDevice.jsonapiType;
RenterProfile.jsonapiType;

User.jsonapiType;
ChatMessage.jsonapiType;
Conversation.jsonapiType;
UserBlock.jsonapiType;
PromoCode.jsonapiType;

Associate.jsonapiType;
AssociatePayment.jsonapiType;
