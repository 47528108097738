import {FunctionComponent} from 'react';

import {IconType} from '@react-icons/all-files';

interface ListingCardCountProps {
  count: number;
  icon: IconType;
}

const ListingCardCount: FunctionComponent<ListingCardCountProps> = ({
  count,
  icon: Icon,
}) => {
  return count === 0 ? null : (
    <div className="flex flex-row items-center justify-center gap-x-1">
      <Icon className="w-5 h-5" />
      <span className="text-base font-medium">{count}</span>
    </div>
  );
};

export default ListingCardCount;
