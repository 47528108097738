import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Associate from 'models/associates/Associate';

@Model()
class AssociatePayment extends ApplicationRecord {
  static jsonapiType = 'associate_payments';

  @Attr({persist: false}) amount: number;
  @Attr({persist: false}) currency: string;
  @Attr({persist: false}) numUsers: number;
  @Attr({persist: false}) paidAt: string;
  @Attr({persist: false}) periodEndDate: string;
  @Attr({persist: false}) periodStartDate: string;
  @Attr({persist: false}) status: string;

  @Attr({persist: false}) associateId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('associate') associate: Associate;
}

export default AssociatePayment;
