import {FunctionComponent, useCallback, useMemo, useState} from 'react';

import {TbBuildingCommunity} from 'react-icons/tb';

import GridSelect from 'components_sb/input/GridSelect/GridSelect';
import {
  PROPERTY_TYPE_OPTIONS as OPTIONS,
  getPlaceholderValue,
} from 'constants/listings-search';

import FilterWrapper from './shared/FilterWrapper';
import {FilterProps} from './types';

type PropertyTypesFilterProps = FilterProps;

const PropertyTypesFilter: FunctionComponent<PropertyTypesFilterProps> = ({
  form,
  name,
  inline = false,
}) => {
  const hasModifiedDefault = useMemo(
    () => form.values[name] !== undefined,
    [form, name],
  );

  const value = useMemo(
    () => (!hasModifiedDefault ? getPlaceholderValue(name) : form.values[name]),
    [hasModifiedDefault, form, name],
  );

  const onChange = useCallback(
    (values: string[]) => {
      form.setFieldValue(name, values);
    },
    [form, name],
  );

  const valueLabel = useMemo(() => {
    if (!hasModifiedDefault) {
      return null;
    } else if (value.length === OPTIONS.length) {
      return 'Any';
    } else {
      return `${value.length} selected`;
    }
  }, [hasModifiedDefault, value]);

  const clearFilter = useCallback(() => {
    /**
     * We can't use setFieldValue here, because Formik will delete
     * the entry from the form values if we are setting it to undefined.
     */
    form.setFormikState((current) => ({
      ...current,
      values: {
        ...current.values,
        [name]: undefined,
      },
    }));
  }, [form, name]);

  return (
    <FilterWrapper
      inline={inline}
      label="Property type"
      icon={TbBuildingCommunity}
      valueLabel={valueLabel}
      onClearFilter={clearFilter}>
      <GridSelect
        multiple
        size="sm"
        mode="manual:select"
        preventEmptyCells
        maxColumns={2}
        minColumnWidth={140}
        options={OPTIONS}
        value={value}
        onChange={onChange}
      />
    </FilterWrapper>
  );
};

export default PropertyTypesFilter;
