import {
  FunctionComponent,
  ReactElement,
  ReactFragment,
  createElement,
  isValidElement,
} from 'react';

import {type PseudoHeadItemElement} from './PseudoHeadItem';

type PseudoHeadChildren = PseudoHeadItemElement[];

export interface PseudoHeadProps {
  children: PseudoHeadChildren;
}

export type PseudoHeadElement = ReactElement<PseudoHeadProps>;

export type PseudoHeadInputElement = PseudoHeadElement | ReactFragment;

export type PseudoHeadComponent = FunctionComponent<PseudoHeadProps>;

const PseudoHead: PseudoHeadComponent = () => <></>;

export default PseudoHead;

/**
 * Input element type guard.
 */
export const elementIsHeadElement = (
  element: unknown,
): element is PseudoHeadElement => {
  return (
    isValidElement(element) && element.type === createElement(PseudoHead).type
  );
};
