import {useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import {HeaderButton} from 'components/navbar/Header';
import PageWrapper from 'components/PageWrapper';
import RentalApplicationDetailView from 'components/rental_application/RentalApplicationDetailView';
import {Action} from 'types/actions';
import RentalApplication from 'models/listings/RentalApplication';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const RentalApplicationDetailPage = () => {
  usePageVisit('RentalApplicationDetailPage');
  const {id} = useParams();

  const {data, isLoading, isSuccess, error} = useQuery(
    `rental-application-${id}`,
    async () => {
      const app = await RentalApplication.includes([
        'rental_application_applicants',
        'user',
      ])
        .order({'rental_applications.id': 'asc'})
        .find(id);

      return app.data;
    },
  );

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !id
        ? null
        : [
            {
              label: 'Edit application',
              linkTo: `/rental-applications/${id}/edit`,
            },
          ],
    [id],
  );

  return (
    <PageWrapper title="Rental Application" actions={pageActions}>
      {!!error && errorViewForError(error)}
      {isLoading && <LoadingView />}
      {isSuccess && <RentalApplicationDetailView rentalApplication={data} />}
    </PageWrapper>
  );
};

export default RentalApplicationDetailPage;
