import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';
import {Collapse} from 'react-collapse';
import {HiChevronDown} from 'react-icons/hi';
import {MdOutlineSubdirectoryArrowRight} from 'react-icons/md';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';

import {HeadData, RowColumnData, RowData} from '../data-types';

interface RowColumnProps {
  column: RowColumnData;
}

type RowColumnComponent = FunctionComponent<RowColumnProps>;

const RowColumn: RowColumnComponent = ({column}) => {
  console.log();
  return (
    <td key={column.index} className="py-6 px-6 overflow-hidden">
      {column.children}
    </td>
  );
};

interface RowProps {
  data: RowData;
  headData: HeadData;
  totalPrimaryColumns: number;
  hasConcealedColumns: boolean;
  concealedColumnIndexes: number[];
}

type RowComponent = FunctionComponent<RowProps>;

const Row: RowComponent = ({
  data,
  headData,
  totalPrimaryColumns,
  hasConcealedColumns,
  concealedColumnIndexes,
}) => {
  const {index: rowIndex, columns} = data;
  const [expanded, setExpanded] = useState(false);

  /**
   * Reset expanded state if there is no longer any
   * content to expand.
   */
  useEffect(() => {
    if (!hasConcealedColumns && expanded) {
      setExpanded(false);
    }
  }, [hasConcealedColumns, expanded]);

  const toggleExpanded = useCallback(
    () => setExpanded((current) => !current),
    [],
  );

  const conditionalRowProps = useMemo(
    () =>
      hasConcealedColumns
        ? {
            role: hasConcealedColumns ? 'button' : undefined,
            onClick: toggleExpanded,
          }
        : {},
    [hasConcealedColumns, toggleExpanded],
  );

  return (
    <>
      <tr
        key={rowIndex}
        className={clsx(
          'rounded-xl',
          'transition-all duration-200',
          hasConcealedColumns &&
            clsx(
              'scale-100 active:scale-[0.98]',
              'hover:bg-brand-25 active:bg-brand-50',
            ),
        )}
        {...conditionalRowProps}>
        {columns.map((column) =>
          concealedColumnIndexes.includes(column.index) ? null : (
            <RowColumn key={column.index} column={column} />
          ),
        )}
        {hasConcealedColumns && (
          <td className="w-[62px] sm:w-28">
            <span
              className={clsx(
                'my-auto pr-4',
                'flex flex-row items-center justify-end gap-1',
                'text-brand-500 hover:text-brand-600',
              )}>
              <span
                className={clsx(
                  'hidden sm:flex',
                  'font-medium text-sm',
                  'transition-colors duration-200',
                )}>
                {expanded ? 'Hide' : 'Details'}
              </span>
              <HiChevronDown
                className={clsx(
                  'flex-shrink-0',
                  'my-auto',
                  'w-7 h-7 sm:w-6 sm:h-6',
                  'transition-all duration-200',
                  expanded ? 'rotate-180' : 'rotate-0',
                )}
              />
            </span>
          </td>
        )}
      </tr>
      {hasConcealedColumns && (
        <tr>
          <td colSpan={totalPrimaryColumns}>
            <span className="flex-1 w-full">
              {/* TODO: Find a way to use ReactCollapse as a span rather than a div to succeed element nesting validation */}
              <Collapse isOpened={expanded} className="w-full">
                <span className="py-4 px-6 flex justify-start gap-x-4 flex-1">
                  <MdOutlineSubdirectoryArrowRight className="hidden sm:flex w-6 h-6 mb-2 text-brand-100" />
                  <span className="flex flex-col gap-y-6 flex-1">
                    {concealedColumnIndexes.map((columnIndex) => (
                      <span
                        key={columnIndex}
                        className="flex flex-col xs:flex-row justify-between items-start gap-x-2 gap-y-2">
                        <span className="text-sm sm:text-base font-semibold text-brand-850">
                          {headData.columns[columnIndex].children}
                          <HelpTextPresenter>
                            {headData.columns[columnIndex].helpText}
                          </HelpTextPresenter>
                        </span>
                        <span className="hidden xs:flex flex-1 h-[2px] bg-brand-50 mt-3 mx-4" />
                        <span className="text-right">
                          {columns[columnIndex].children}
                        </span>
                      </span>
                    ))}
                  </span>
                </span>
              </Collapse>
            </span>
          </td>
        </tr>
      )}
    </>
  );
};

export default Row;
