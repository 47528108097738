import {useCallback, useState} from 'react';

import {AiFillCheckCircle} from '@react-icons/all-files/ai/AiFillCheckCircle';
import {Form, Formik, type FormikHelpers} from 'formik';
import {HiOutlineChat} from 'react-icons/hi';

import {InputField, SubmitButton, TextareaField} from 'components/forms_fields';
import {Alert} from 'components_sb/feedback';
import {Card} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import Listing, {ListingStatus} from 'models/listings/Listing';
import useAuth from 'services/useAuth';

type EnquiryFormValues = {
  name: string;
  email: string;
  message: string;
};

const ListingEnquireCard = ({listing}: {listing: Listing}) => {
  const [messageSent, setMessageSent] = useState(false);

  const {userIsLoggedIn, currentUser} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  const handleEnquiryFormSubmit = useCallback(
    async (
      values: EnquiryFormValues,
      actions: FormikHelpers<EnquiryFormValues>,
    ) => {
      actions.setSubmitting(true);
      setMessageSent(false);

      const message = new EnquiryMessage({
        name: values.name,
        email: values.email,
        message: values.message,
      });

      if (userIsLoggedIn) {
        message.userId = currentUser.id;
      }

      const thread = new EnquiryThread({
        name: values.name,
        email: values.email,
        listingId: listing.id,
      });
      thread.enquiryMessages = [message];
      const result = await thread.save({with: 'enquiryMessages'});

      if (result) {
        setMessageSent(result);
        actions.resetForm();
      } else {
        if (thread.errors.email) {
          actions.setFieldError(
            'email',
            'You have already enquired about this listing, please reply via your enquiry inbox or email.',
          );
        }
      }

      actions.setSubmitting(false);
    },
    [currentUser, listing, userIsLoggedIn],
  );

  return (
    <Card id="enquiry-card" icon={HiOutlineChat} title="Enquire">
      {listing.isHistorical ? (
        <Paragraph>Enquriries cannot be made for inactive listings.</Paragraph>
      ) : (
        <>
          {activeAccountRole === 'Landlord' ? (
            <Paragraph>
              You cannot enquire about a property with a Landlord account.
            </Paragraph>
          ) : (
            <div className="!mt-0">
              {messageSent && (
                <Alert
                  type="success"
                  title="Your message has been sent!"
                  description={
                    userIsLoggedIn
                      ? 'Replies will be sent to your enquiry inbox.'
                      : 'Any replies from the landlord will be emailed to you.'
                  }
                />
              )}

              <Formik
                initialValues={{
                  name: currentUser?.name || '',
                  email: currentUser?.email || '',
                  message: '',
                }}
                onSubmit={handleEnquiryFormSubmit}>
                {(formik) => (
                  <Form className="flex flex-col gap-y-6">
                    <div>
                      <InputField
                        name="name"
                        formik={formik}
                        labelProps={{
                          title: 'Your name',
                        }}
                        placeholder="E.g. John Smith"
                        disabled={!!currentUser}
                      />
                    </div>

                    <div>
                      <InputField
                        name="email"
                        formik={formik}
                        labelProps={{
                          title: 'Email address',
                        }}
                        placeholder="E.g. johnsmith@gmail.com"
                        type="email"
                        disabled={!!currentUser}
                      />
                    </div>

                    <div>
                      <TextareaField
                        id="enquiry-message-field"
                        name="message"
                        formik={formik}
                        labelProps={{
                          title: 'Message',
                          size: 'base',
                        }}
                        placeholder="Your message in here..."
                      />
                    </div>

                    <div>
                      <SubmitButton
                        formik={formik}
                        text="Send"
                        submittingText="Sending..."
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default ListingEnquireCard;
