import {useEffect, useRef, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {PhotographIcon} from '@heroicons/react/outline';
import TextareaAutosize from 'react-textarea-autosize';
import {toast} from 'react-toastify';

import ChatMessage from 'models/ChatMessage';
import type {ChatChannel} from 'services/PusherService';
import TrackingService from 'services/TrackingService';
import {convertBase64} from 'utilities/FileUtils';

const ChatInputBox = ({channel}: {channel: ChatChannel}) => {
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const textarea = useRef<HTMLTextAreaElement>(null);

  const updatePadding = () => {
    if (textarea && Capacitor.isNativePlatform()) {
      const paddingEl = document.getElementById('chat-input-padder');
      paddingEl.style.height = `${textarea.current.clientHeight}px`;
    }
  };

  useEffect(() => {
    updatePadding();
  }, [textarea]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSubmitting(true);

    if (message.length > 0) {
      const chatMessage = new ChatMessage({
        content: message,
        chatableId: channel.model.id,
        chatableType: channel.identifier.replace('Channel', ''),
      });
      const result = await chatMessage.save();

      if (result) {
        TrackingService.trackEvent(TrackingService.Event.SendTextChatMessage);
      } else {
        toast.error('There was an issue sending your message');
      }
    }

    setMessage('');
    setSubmitting(false);
  };

  const handleImageRead = async (event: any) => {
    setSubmitting(true);
    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    const message = new ChatMessage({
      media: base64,
      chatableId: channel.model.id,
      chatableType: channel.identifier.replace('Channel', ''),
    });
    const result = await message.save();
    setSubmitting(false);

    if (result) {
      TrackingService.trackEvent(TrackingService.Event.SendImageChatMessage);
    } else {
      toast.error('There was an issue sending your message');
    }
  };

  let containerCss = 'border-t-3 border-gray-200 px-2 pt-2 pb-4 bg-white ';
  if (Capacitor.isNativePlatform()) {
    containerCss += ' fixed bottom-0 left-0 right-0 z-50';
  }

  return (
    <div className={containerCss}>
      <form className="relative flex shrink" onSubmit={handleSubmit}>
        <TextareaAutosize
          placeholder="Your message here..."
          className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-300 pl-6 bg-gray-100 rounded-xl border-gray-200 pr-20"
          value={message}
          minRows={2}
          maxRows={10}
          ref={textarea}
          onChange={({target}) => {
            setMessage(target.value);
          }}
          onHeightChange={updatePadding}
        />
        <input
          className="hidden"
          type="file"
          id="chat-file-upload"
          accept=".png,.jpeg,.jpg"
          onChange={handleImageRead}
          disabled={submitting}
        />
        <div className="absolute right-0 items-center inset-y-0 flex">
          <label
            htmlFor="chat-file-upload"
            className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none mr-1 cursor-pointer">
            <PhotographIcon className="w-6 h-6" />
          </label>
          <button
            type="submit"
            disabled={submitting}
            className="inline-flex items-center justify-center rounded-full h-10 w-10 mr-2 transition duration-500 ease-in-out text-white bg-primary hover:bg-primary-focus focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 transform rotate-90">
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatInputBox;
