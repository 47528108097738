import {FunctionComponent, InputHTMLAttributes, useMemo} from 'react';

import clsx from 'clsx';
import {IconType} from 'react-icons/lib';

type Omitted = 'className' | 'size';

export interface InputStyleProps {
  size?: 'sm' | 'md' | 'lg';
  prefixContent?: string | IconType;
}

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, Omitted> &
  InputStyleProps;

const Input: FunctionComponent<Props> = ({
  size = 'md',
  prefixContent,
  ...props
}) => {
  const hasPrefixContent = useMemo(() => !!prefixContent, [prefixContent]);
  return (
    <div className="flex-1">
      {hasPrefixContent && <div>hasPrefixContent</div>}
      <input
        className={clsx(
          'w-full max-w-full box-border',
          'transition-all duration-300',
          'bg-brand-50 hover:bg-brand-75 ',
          'placeholder-brand-800 placeholder-opacity-30',
          'outline-none border-none ring-1 ring-brand-75',
          'focus:ring-2 focus:ring-brand-500',

          size === 'sm' && clsx('text-base', 'px-2', 'h-10', 'rounded-md'),
          size === 'md' && clsx('text-base', 'px-4', 'h-12', 'rounded-lg'),
          size === 'lg' && clsx('text-lg', 'px-6', 'h-14', 'rounded-xl'),
        )}
        {...props}
      />
    </div>
  );
};

export default Input;
