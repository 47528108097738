import {FunctionComponent, ReactEventHandler, useMemo, useState} from 'react';

import clsx from 'clsx';
import {motion} from 'framer-motion';

import heroImage1 from 'assets/img/listings/home/listings-hero-image-1.jpg';
import heroImage2 from 'assets/img/listings/home/listings-hero-image-2.jpg';
import heroImage3 from 'assets/img/listings/home/listings-hero-image-3.jpg';

interface BackgroundImageProps {
  index: number;
  image: string;
  onLoad: ReactEventHandler<HTMLImageElement>;
}

const BackgroundImage: FunctionComponent<BackgroundImageProps> = ({
  index,
  image,
  onLoad,
}) => (
  <motion.img
    onLoad={onLoad}
    className={clsx(
      'w-full',
      'max-w-full flex-1',
      'object-contain rounded-3xl',
    )}
    src={image}
    alt={`Hero Photo ${index}`}
    variants={{
      hidden: {opacity: 0, y: 100, filter: 'blur(100px)'},
      show: {opacity: 1, y: 0, filter: 'blur(0px)'},
    }}
    transition={{
      ease: 'easeOut',
      filter: {
        duration: 2,
      },
      opacity: {
        duration: 3,
      },
      y: {
        duration: 2,
      },
    }}
  />
);

const HeroBackgroundImages = () => {
  const [totalImagesLoaded, setTotalImagesLoaded] = useState<number>(0);

  const onImageLoad = () => {
    setTotalImagesLoaded((current) => current + 1);
  };

  const allImagesLoaded = useMemo(
    () => totalImagesLoaded === 3,
    [totalImagesLoaded],
  );

  return (
    <motion.div
      initial="hidden"
      animate={allImagesLoaded ? 'show' : 'hidden'}
      variants={{
        show: {
          transition: {
            staggerChildren: 0.25,
          },
        },
      }}
      className={clsx(
        'p-8',
        'box-border',
        'max-h-full',
        'max-w-[40vw] xl:max-w-lg',
        'hidden mdlg:flex',
        'flex-row',
        'gap-x-8',
        'items-end',
        'relative',
      )}>
      {/* Left column */}
      <div className={clsx('h-full max-h-full', 'flex flex-col', 'gap-y-8')}>
        <BackgroundImage
          index={1}
          image={heroImage1}
          onLoad={() => onImageLoad()}
        />
        <BackgroundImage
          index={2}
          image={heroImage2}
          onLoad={() => onImageLoad()}
        />
      </div>
      {/* Right column */}
      <div className={clsx('h-full max-h-full', 'flex flex-col', 'pb-8')}>
        <BackgroundImage
          index={3}
          image={heroImage3}
          onLoad={() => onImageLoad()}
        />
      </div>
    </motion.div>
  );
};

export default HeroBackgroundImages;
