import {FocusEvent} from 'react';

import clsx from 'clsx';
import {useFormik} from 'formik';
import {
  HighlightWithinTextarea,
  type Highlight,
} from 'react-highlight-within-textarea';

import {type FieldError, InlineError} from 'components_sb/feedback';
import {FieldLabel} from 'components_sb/typography';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: {
    input: clsx(
      'w-full max-w-full box-border', // Size
      'transition-all duration-300', // Transition
      'bg-brand-50 hover:bg-brand-75 ', // Background
      'placeholder-brand-800 placeholder-opacity-30', // Placeholder
      'outline-none border-none ring-1 ring-brand-75', // Untouched
      'focus:ring-2 focus:ring-brand-500', // Focus
    ),
  },

  // TODO: Classes for disabled state
  // disabled: clsx(
  //   // Disable interaction
  //   'pointer-events-none',
  //   // Opacity
  //   'opacity-50',
  // ),

  // Classes for error state
  error: {
    // These classes are set as important to override default hover/focus states
    input: clsx('!ring-2 !ring-error'),
  },
  // Classes based on size
  size: {
    label: {
      base: clsx(
        // Text
        'text-base',
      ),
      lg: clsx(
        // Text
        'text-base',
      ),
    },
    input: {
      base: clsx(
        // Text
        'text-base',
        // Padding
        'px-4',
        'py-2',
        // Roundness
        'rounded-lg',
      ),
      lg: clsx(
        // Text
        'text-base',
        // Padding
        'px-6',
        // Roundness
        'rounded-xl',
      ),
    },
  },
};

interface BaseProps {
  /**
   * The unique name for the input field.
   */
  name: string;
  /**
   * The text shown above the input field.
   */
  label?: string;
  /**
   * Supporting text to help desribe the purpose or requirement of the field.
   */
  description?: string;
  /**
   * The strategy for handling field events and states.
   */
  mode: 'manual' | 'formik';
  /**
   * The text shown above the input field.
   */
  placeholder?: string;
  /**
   * Whether a value is required for the input field.
   */
  required?: boolean;
}

type ConditionalModeProps =
  /**
   * Types when mode is 'manual'
   */
  | {
      mode: 'manual';
      value: string | number;
      onBlur?: () => void;
      onChange: (val: string) => void;
      error?: FieldError;
      onClick: () => void;
      form?: never;
    }
  /**
   * Types when mode is 'formik'
   */
  | {
      mode: 'formik';
      value?: never;
      onBlur?: never;
      onChange?: never;
      error?: never;
      onClick?: never;
      form: ReturnType<typeof useFormik>;
    };

interface HighlightLibraryProps {
  highlight?: Highlight;
}

/**
 * Conditional types combined with the base types
 */
type TextAreaFieldProps = BaseProps &
  ConditionalModeProps &
  HighlightLibraryProps;

/**
 * Properties that wrapper components must provide to the base component.
 */
interface WrapperOutput {
  value: string;
  onBlur: (event: FocusEvent<any, Element>) => void;
  onChange: (val: string) => void;
  error?: FieldError;
}

/**
 * The minimum properties that wrapper components must receive from
 * the base component.
 */
interface BaseWrapperInput {
  children: (props: WrapperOutput) => JSX.Element;
}

/**
 * A component that wraps the based component to provide functionality
 * based on particular configuration.
 */
interface WrapperComponent {
  (props: BaseWrapperInput): JSX.Element;
}

/**
 * Wraps the base component to enable manual control.
 */
const ManualWrapper = ({
  children,
  ...props
}: BaseWrapperInput & WrapperOutput) => {
  /**
   * For the manual wrapper, the input must include the base properties
   * but also include the expected output for direct passing
   */
  return children({...props});
};

/**
 * Wraps the base component to enable support for
 * integration with the current Formik context.
 */
const FormikWrapper = ({
  name,
  form,
  children,
  ...props
}: BaseWrapperInput & {
  name: string;
  form: ReturnType<typeof useFormik>;
}) => {
  const {value, onBlur} = form.getFieldProps(name);
  const {error} = form.getFieldMeta(name);

  const onChange = (val: string) => form.setFieldValue(name, val);

  return children({
    value,
    onBlur,
    onChange,
    error,
    ...props,
  });
};

/**
 * Primary UI component for text-based input.
 */
const HighlightTextAreaField = (props: TextAreaFieldProps) => {
  // These properties are consistent across all wrapper types
  const {
    name,
    label,
    description = null,
    mode,
    placeholder = '',
    required = false,
    highlight,
  } = props;

  /**
   * Select the wrapper based on the mode
   */
  const Wrapper: WrapperComponent = {
    manual: ManualWrapper,
    formik: FormikWrapper,
  }[mode];

  return (
    <Wrapper {...props}>
      {({value, onBlur, onChange, error}: WrapperOutput) => (
        <div className="flex-1 flex flex-col gap-y-2 relative">
          {label && (
            <FieldLabel
              title={label}
              description={description}
              htmlFor={name}
              required={required}
            />
          )}
          <div
            className={clsx(
              classes.base.input,
              classes.size.input['base'],
              !!error && classes.error.input,
            )}>
            <HighlightWithinTextarea
              onChange={onChange}
              value={value}
              onBlur={onBlur}
              placeholder={placeholder}
              highlight={highlight}
            />
          </div>
          {/* If the error is a boolean, a message does not need to be shown */}
          <InlineError
            error={typeof error === 'string' ? error : null}
            name={name}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default HighlightTextAreaField;
