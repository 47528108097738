import {useCallback, useMemo} from 'react';

import {CalendarIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {RiDoorOpenLine} from 'react-icons/ri';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import LoadingView from 'components/common/LoadingView';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Listing from 'models/listings/Listing';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import useAuth from 'services/useAuth';

interface OpenHomesCardProps {
  listing: Listing;
  currentUserOwnsListing: boolean;
}

const OpenHomesCard = ({
  listing,
  currentUserOwnsListing,
}: OpenHomesCardProps) => {
  const {userIsLoggedIn} = useAuth();

  const {activeAccountRole} = useLocalUserSettings();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const filteredOpenHomes = useMemo(
    () =>
      listing.openHomes.filter((oh) => moment(oh.startTime).isAfter(moment())),
    [listing.openHomes],
  );

  /**
   * Scroll the enquiry card into view and focus the message field.
   */
  const onRequestPrivateViewing = useCallback(() => {
    /**
     * Smoothly scroll to the enquiry card.
     */
    document
      .getElementById('enquiry-card')
      ?.scrollIntoView({behavior: 'smooth'});

    /**
     * Allow the scroll to complete before focusing the message field and entering
     * a default message.
     */
    setTimeout(() => {
      const inputElement = document.getElementById('enquiry-message-field');
      if (inputElement) {
        inputElement.focus();
        inputElement.innerText =
          'Hi, I would like to arrange a private viewing of this property please.';
        /**
         * Invoke a change event to ensure the text is detected by the form.
         */
        const event = new Event('input', {bubbles: true});
        inputElement.dispatchEvent(event);
      }
    }, 1000);
  }, []);

  const {data: openHomeAttendees, isLoading: openHomeAttendeesIsLoading} =
    useQuery(
      `listing-${listing.id}-open-home-attendees`,
      async () => {
        const ohas = await OpenHomeAttendee.where({
          open_home_id: listing.openHomes.map((oh) => oh.id),
        }).all();

        const map = {} as Record<string, OpenHomeAttendee>;
        if (ohas.data) {
          for (const oha of ohas.data) {
            map[oha.openHomeId] = oha;
          }
        }

        return map;
      },
      {
        enabled: userIsLoggedIn && listing && listing.openHomes.length > 0,
      },
    );

  const unattendOpenHome = async (openHomeId: string) => {
    const oha = openHomeAttendees[openHomeId];

    if (oha) {
      const result = await oha.destroy();

      if (result) {
        toast.success('You are no longer attending this open home.');
        const attendees = openHomeAttendees;
        delete attendees[openHomeId];
        queryClient.setQueryData(
          `listing-${listing.id}-open-home-attendees`,
          attendees,
        );
      } else {
        toast.error('There was an errror trying to RSVP to this open home.');
      }
    }
  };

  const attendOpenHome = async (openHomeId: string) => {
    if (!userIsLoggedIn) {
      toast.error('Please login or create an account to attend this open home');
      localStorage.setItem('back-url', window.location.pathname);

      navigate('/register/tenant');
    } else {
      const openHome = listing.openHomes.find((oh) => oh.id === openHomeId);

      if (openHome) {
        const oha = new OpenHomeAttendee({openHomeId: openHomeId});

        const result = await oha.save();

        if (result) {
          toast.success("You have successfully RSVP'd to this open home!");
          const attendees = openHomeAttendees;
          attendees[openHomeId] = oha;
          queryClient.setQueryData(
            `listing-${listing.id}-open-home-attendees`,
            attendees,
          );
        } else {
          toast.error('There was an errror trying to RSVP to this open home.');
        }
      }
    }
  };

  return (
    <Card
      icon={RiDoorOpenLine}
      title="Open Homes"
      subtitle="Find a time to view this property">
      {filteredOpenHomes.length > 0 ? (
        // Listing has open homes
        <>
          {openHomeAttendeesIsLoading ? (
            // Loading open home attendees
            <LoadingView />
          ) : (
            // List of open homes
            <>
              <div>
                {filteredOpenHomes.map((oh) => {
                  const st = moment(oh.startTime);
                  const et = moment(oh.endTime);
                  const isAttending = userIsLoggedIn
                    ? false
                    : !!(openHomeAttendees ?? {})[oh.id];
                  return (
                    <div
                      key={oh.id}
                      className="flex justify-between items-center mb-4">
                      <div className="flex justify-start items-center">
                        <div className="mr-2">
                          <CalendarIcon className="w-6 h-6 text-primary" />
                        </div>
                        <div>
                          <span className="font-medium">
                            {moment(oh.startTime).format('dddd DD MMMM')}
                          </span>
                          <p className="text-brand-850 opacity-70">
                            {st.format('hh:mm a')} - {et.format('hh:mm a')}
                          </p>
                        </div>
                      </div>

                      <div>
                        {isAttending ? (
                          <button
                            className="btn btn-sm btn-neutral"
                            onClick={() => unattendOpenHome(oh.id)}>
                            Unattend
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-neutral"
                            onClick={() => attendOpenHome(oh.id)}>
                            Attend
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {!currentUserOwnsListing && activeAccountRole !== 'Landlord' && (
                <>
                  <Paragraph>
                    Please contact the Landlord if you would like to arrange a
                    private viewing.
                  </Paragraph>
                  <Button
                    label={`Request a private viewing`}
                    category="primary"
                    size="base"
                    mode="manual"
                    onClick={onRequestPrivateViewing}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        // Listing does not have any open homes
        <>
          <Paragraph>
            There are currently no open homes for this property.
          </Paragraph>
          {!currentUserOwnsListing && activeAccountRole !== 'Landlord' && (
            <>
              <Paragraph>
                Please contact the Landlord if you would like to arrange a
                private viewing.
              </Paragraph>
              <Button
                label={`Request a private viewing`}
                category="primary"
                size="base"
                mode="manual"
                onClick={onRequestPrivateViewing}
              />
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default OpenHomesCard;
