import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import PageWrapper from 'components/PageWrapper';
import {ResultMessage, SpinningLoader} from 'components_sb/feedback';
import PrivateViewing from 'models/listings/PrivateViewing';
import {usePageVisit} from 'utilities/hooks';

import PrivateViewingForm from './shared/PrivateViewingForm';

const EditPrivateViewingPage = () => {
  usePageVisit('EditPrivateViewing');

  /**
   * Get the public listing ID from the URL.
   */
  const {listingId: listingPublicId} = useParams();

  /**
   * Get the ID of the private viewing from the URL.
   */
  const {privateViewingId} = useParams();

  /**
   * Fetch the private viewing.
   */
  const {
    data: privateViewing,
    isLoading: isLoadingPrivateViewing,
    isError: errorLoadingPrivateViewing,
    isSuccess: successfullyLoadedPrivateViewing,
  } = useQuery(
    ['listing', 'private-viewing', privateViewingId],
    async () => (await PrivateViewing.find(privateViewingId)).data,
  );

  return (
    <PageWrapper title="Edit Private Viewing">
      {isLoadingPrivateViewing && (
        <div className="flex-1 flex items-center justify-center">
          <SpinningLoader size="base" color="brand" />
        </div>
      )}
      {errorLoadingPrivateViewing && (
        <ResultMessage type="error">
          Sorry, there was an issue loading the details for this private
          viewing.
        </ResultMessage>
      )}
      {successfullyLoadedPrivateViewing && (
        <PrivateViewingForm
          mode="edit"
          listingPublicId={listingPublicId}
          privateViewing={privateViewing}
        />
      )}
    </PageWrapper>
  );
};

export default EditPrivateViewingPage;
