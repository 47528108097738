import {useCallback, useImperativeHandle} from 'react';

import {sentenceCase} from 'change-case';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import TenantLoginModal from 'components/auth/TenantLoginModal';
import {SocialAuthButton} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {Divider, Modal, ModalDefinition} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import {Paragraph, Title} from 'components_sb/typography';
import useAuth from 'services/useAuth';
import SocialAuth, {SocialAuthProviderType} from 'utilities/auth/SocialAuth';
import {SocialAuthUserData} from 'utilities/auth/SocialAuth/providers';
import {isFullName} from 'utilities/StringHelpers';

type FormValues = {
  name: string;
  email: string;
  password: string;
};

/**
 * A set of form error message mappings.
 */
interface ErrorMessageMapping {
  /**
   * The keyword to find within the 'reason' to activate the mapping.
   */
  keyword: string;
  /**
   * The message that replaces the 'reason' returned for the error.
   */
  message: string;
}

/**
 * Key/value pairings of a form field name to a set of form error
 * message mappings.
 */
interface ErrorMessageMappings {
  [key: string]: ErrorMessageMapping[];
}

/**
 * Define mappings here to override the 'reason' string for the error
 * messages for particular fields.
 */
const ERROR_MESSAGE_MAPPINGS: ErrorMessageMappings = {
  email: [
    {
      keyword: 'taken',
      message: 'There is already an account registered to this email address',
    },
  ],
};

const {useModal} = Modal.Imperative;

const TenantRegisterModal: ModalDefinition = {
  title: '',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'register',
        label: {
          idle: 'Register',
          loading: 'Registering',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, registerReason, loginReason} = props;

    SocialAuth.useAuthScripts();

    const openModal = useModal();

    const {registerUser} = useAuth();

    const showGenericError = useCallback(() => {
      toast.error(
        `There was an issue registering your account. Please try again later or contact us for support.`,
      );
    }, []);

    const handleFormSubmit = useCallback(
      async (formData: FormValues, actions: any) => {
        let response;
        try {
          // Attempt the registration request
          const {name, email, password} = formData;
          response = await registerUser('email', {
            userData: {
              name,
              email,
              password,
            },
            accountType: 'Renter', // Include the account type alongside the form data
          });
        } catch (error) {
          // Error performing the registration request
          showGenericError();
          // Reset the submitting state
          actions.setSubmitting(false);
          return;
        }

        // Request performed successfully
        const {responseObject, status} = response;

        if (status === 200 || status === 201) {
          closeModal();
        }

        // Request was performed successfully but the server returned errors
        if (status !== 200) {
          // Initial error response object (before message mappings applied)
          const {errors} = responseObject;

          // Server returned error messages to display
          if (errors) {
            // Replace any matched errors with messages from the defined mapping
            for (const [fieldName, reasons] of Object.entries(errors)) {
              const mappings = ERROR_MESSAGE_MAPPINGS[fieldName] ?? [];
              errors[fieldName] = (reasons as string[]).map(
                (reason: string) =>
                  mappings.find(({keyword}: {keyword: string}) =>
                    reason.includes(keyword),
                  ).message ?? `${sentenceCase(fieldName)} reason`,
              );
            }

            // Set error messages on the applicable fields
            for (const fieldName in errors) {
              const messages = errors[fieldName];
              for (const message of messages) {
                actions.setFieldError(fieldName, message);
              }
            }
          }

          // No specific errors to display were returned by the server
          else {
            showGenericError();
          }
        }
        // Reset the submitting state
        actions.setSubmitting(false);
      },
      [registerUser, showGenericError, closeModal],
    );

    const onSocialAuthSuccess = useCallback(
      async (
        provider: SocialAuthProviderType,
        userData: SocialAuthUserData,
      ) => {
        try {
          // Attempt the registration request
          const response = await registerUser(provider, {
            userData,
            accountType: 'Renter', // Include the account type alongside social auth data
          });

          // Request performed successfully
          const {status} = response;

          // Request performed successfully but registration was unsuccessful
          if (status !== 200) {
            throw new Error();
          }

          if (status === 200 || status === 201) {
            closeModal();
          }
        } catch (error) {
          // Error performing the registration request
          showGenericError();
        }
      },
      [registerUser, showGenericError, closeModal],
    );

    const formik = useFormik({
      onSubmit: handleFormSubmit,
      initialValues: {
        name: '',
        email: '',
        password: '',
      },
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .required('Please enter your full name')
          .min(1, 'Must be 1 character or more')
          .max(128, 'Must be 128 characters or less')
          .test(
            'two-names-plus',
            'Please enter both your first and last name',
            (value) => {
              if (!value) {
                return false;
              }
              return isFullName(value);
            },
          ),
        email: Yup.string()
          .email('Invalid email address')
          .required('Please enter your email address'),
        password: Yup.string()
          .required('Please enter a password for your account')
          .min(6, 'Your password must be at least 6 characters')
          .max(128, 'Your password must be 128 characters or less'),
      }),
      validateOnBlur: false,
      validateOnChange: false,
    });

    const onSave = useCallback(async () => {
      formik.submitForm();
      return false;
    }, [formik]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    const showLoginModal = () => {
      closeModal();
      setTimeout(() => {
        openModal(TenantLoginModal, {
          registerReason,
          loginReason,
        });
      }, 200);
    };

    return (
      <div>
        <Title level="h1" subtitle={registerReason}>
          Register
        </Title>

        {/* Social auth section */}
        <div className="flex-1 flex flex-col gap-y-4 justify-center items-center mt-0 max-w-none xl:max-w-sm mx-auto">
          {[
            SocialAuth.Provider.Facebook,
            SocialAuth.Provider.Google,
            SocialAuth.Provider.Apple,
          ].map((provider) => (
            <SocialAuthButton
              key={provider}
              provider={provider}
              config={{
                action: 'register',
                onSuccess: onSocialAuthSuccess,
                onError: showGenericError,
              }}
            />
          ))}
        </div>

        <Divider orientation="horizontal" labelPosition="middle">
          OR
        </Divider>

        <form className="flex flex-col items-center gap-y-8">
          <div className="w-full flex flex-col gap-y-8">
            <TextField
              name="name"
              label="Full name"
              type="text"
              size="base"
              mode="formik"
              form={formik}
              placeholder="Enter your full name here..."
              required
            />
            <TextField
              name="email"
              label="Email"
              type="email"
              size="base"
              mode="formik"
              form={formik}
              placeholder="Enter your email address here..."
              required
            />
            <TextField
              name="password"
              label="Password"
              description="(6+ characters)"
              type="password"
              size="base"
              mode="formik"
              form={formik}
              placeholder="Enter a password here..."
              required
            />
          </div>
          <Paragraph>
            <span>
              {`By clicking the button below, you acknowledge that you accept the `}
            </span>
            <Link openInNewTab to="https://www.keyhook.com/terms-of-use">
              Terms of Service
            </Link>
            <span>{` and `}</span>
            <Link openInNewTab to="https://www.keyhook.com/privacy-policy">
              Privacy Policy
            </Link>
            <span>.</span>
          </Paragraph>
        </form>
        <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
          <a className="link link-primary" onClick={showLoginModal}>
            Already have an account?
          </a>
        </span>
      </div>
    );
  },
};

export default TenantRegisterModal;
