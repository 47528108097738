import {Attr, BelongsTo, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import OpenHome from 'models/listings/OpenHome';
import User from 'models/users/User';

@Model()
class OpenHomeAttendee extends ApplicationRecord {
  static jsonapiType = 'open_home_attendees';

  @Attr() userId: string;
  @Attr() openHomeId: string;

  @Attr() landlordNotes: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('users') user: User;
  @BelongsTo('open_homes') openHome: OpenHome;
}

export default OpenHomeAttendee;
