import {
  SignInWithApple,
  type SignInWithAppleResponse,
  type SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import {Capacitor} from '@capacitor/core';
import {appleAuthHelpers} from 'react-apple-signin-auth';

import {TARGET_ENV} from 'globals/app-globals';

import {ProviderAuthFunction} from '../authenticate';

/**
 * The response returned upon successful authentication.
 */
type SignInResponse = {
  authorization: {
    state: string;
    code: string;
    id_token: string;
  };
  user: {
    email: string | null | undefined;
    name: {
      firstName: string | null | undefined;
      lastName: string | null | undefined;
    };
  };
};

/**
 * The object that is passed on the onSuccess function.
 */
export interface AppleUserData {
  name: string | null;
  jwt: string;
}

let auth: ProviderAuthFunction;

if (Capacitor.isNativePlatform()) {
  /**
   * Authenticate a user via Apple ID.
   */
  auth = (onSuccess, onError) => {
    /**
     * Set the redirect URI to the current page.
     */
    const redirectURI = window.location.href;

    /**
     * Determine the client ID to use based on the environment.
     */
    let clientId: string;
    if (TARGET_ENV === 'staging') {
      clientId = 'com.keyhook.staging-app';
    } else if (TARGET_ENV === 'production') {
      clientId = 'com.keyhook.app';
    } else {
      clientId = 'com.keyhook.dev-app';
    }

    /**
     * Handle the response from succesful authentication and pass
     * releveant data to the callback function.
     */
    const handleSuccess = () => (response: SignInWithAppleResponse) => {
      if (response.response && response.response.identityToken) {
        const jwt = response.response.identityToken;

        const name = response.response.user
          ? response.response.givenName + ' ' + response.response.familyName
          : null;

        onSuccess({name, jwt});
      } else {
        onError();
      }
    };

    const options: SignInWithAppleOptions = {
      clientId: clientId,
      redirectURI: redirectURI,
      scopes: 'email name',
      state: 'state',
      nonce: 'nonce',
    };

    /**
     * Trigger the authentication flow.
     */
    SignInWithApple.authorize(options).then(handleSuccess).catch(onError);
  };
} else {
  /**
   * Authenticate a user via Apple ID.
   */
  auth = (onSuccess, onError) => {
    /**
     * Set the redirect URI to the current page.
     */
    const redirectURI = window.location.href;

    /**
     * Determine the client ID to use based on the environment.
     */
    let clientId: string;
    if (TARGET_ENV === 'staging') {
      clientId = 'com.keyhook.staging-app';
    } else if (TARGET_ENV === 'production') {
      clientId = 'com.keyhook.app';
    } else {
      clientId = 'com.keyhook.dev-app';
    }

    /**
     * Handle the response from succesful authentication and pass
     * releveant data to the callback function.
     */
    const handleSuccess = () => async (response: SignInResponse) => {
      if (response.authorization.id_token) {
        const jwt = response.authorization.id_token;

        const name =
          response.user && response.user.name
            ? response.user.name.firstName + ' ' + response.user.name.lastName
            : null;

        onSuccess({name, jwt});
      } else {
        onError();
      }
    };

    /**
     * Trigger the authentication flow.
     */
    appleAuthHelpers.signIn({
      authOptions: {
        clientId,
        redirectURI,
        scope: 'email name',
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      },
      onSuccess: handleSuccess,
      onError,
    });
  };
}

export const authenticate = auth;
