import {Outlet, Route} from 'react-router';

import BillingDetailsPage from 'pages/landlord/billing/BillingDetailsPage';
import MyAccountPage from 'pages/shared/account/MyAccountPage';
import SettingsPage from 'pages/shared/account/SettingsPage';
import SupportPage from 'pages/shared/account/SupportPage';

const UserRoutes = [
  <Route path="account" element={<Outlet />} key={1}>
    <Route path="my-account" element={<MyAccountPage />} />
    <Route path="settings" element={<SettingsPage />} />
    <Route path="billing" element={<BillingDetailsPage />} />
    <Route path="support" element={<SupportPage />} />
    <Route path="" element={<MyAccountPage />} />
  </Route>,
];

export default UserRoutes;
