import {Route} from 'react-router-dom';

import CancelPrivateViewingPage from 'pages/renter/viewings/CancelPrivateViewingPage';
import ConfirmPrivateViewingPage from 'pages/renter/viewings/ConfirmPrivateViewingPage';
import NotFoundPage from 'pages/shared/errors/NotFoundPage';

const PrivateListingViewingRoutes = (
  <Route path="private-viewings">
    <Route path=":publicAccessToken">
      <Route path="confirm" element={<ConfirmPrivateViewingPage />} />
      <Route path="cancel" element={<CancelPrivateViewingPage />} />
      <Route path="" element={<NotFoundPage />} />
    </Route>
    <Route path="" element={<NotFoundPage />} />
  </Route>
);

export default PrivateListingViewingRoutes;
