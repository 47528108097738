import {useState} from 'react';

import moment from 'moment';

import LightboxGallery from 'components/common/LightboxGallery';
import UserAvatar from 'components/user/UserAvatar';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import ChatMessage from 'models/ChatMessage';
import User from 'models/users/User';

const ReadReceipt = ({
  message,
  senderUserId,
  users,
}: {
  message: ChatMessage;
  senderUserId: string;
  users: User[];
}) => {
  const ids = Object.keys(message.readMarkers);
  const readUsers = users
    .filter((u) => ids.includes(u.id) && u.id !== senderUserId)
    .map((u) => u.name.split(' ')[0]);

  return (
    <div className="mt-1 text-gray-400 text-xs text-right">
      {readUsers.length === 0 ? (
        <span className="font-semibold mr-2">Unread</span>
      ) : (
        <>
          <span className="font-semibold mr-1">Read By:</span>
          {readUsers.join(', ')}
        </>
      )}
    </div>
  );
};

const ChatMessageBubble = ({
  message,
  senderUserId,
  users,
}: {
  message: ChatMessage;
  senderUserId: string;
  users: User[];
}) => {
  const {activeAccountRole} = useLocalUserSettings();

  const name = message.user.name.split(' ')[0];
  const isSender = senderUserId === message.user.id;

  const [isHovering, setIsHovering] = useState(false);

  const time = moment(message.createdAt);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const padding =
    message.media && message.media.length > 0 ? 'p-0' : 'px-4 py-2';

  if (isSender) {
    return (
      <div className="chat-message mb-2">
        <div className="flex items-end justify-end">
          <div
            className="flex flex-col space-y-2 text-xs max-w-xs order-1 items-end"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}>
            <div
              className={`${padding} rounded-lg block rounded-br-none bg-primary text-white text-sm float-right`}>
              <div style={{whiteSpace: 'pre-wrap'}}>{message.content}</div>
              {message.media && (
                <div className="block">
                  <LightboxGallery
                    sources={[
                      {
                        source: message.media,
                        thumbnail: message.mediaPreview,
                        type: 'image',
                      },
                    ]}
                  />
                </div>
              )}
            </div>
            <div className="float-right">
              {isHovering && activeAccountRole === 'Landlord' ? (
                <ReadReceipt
                  message={message}
                  senderUserId={senderUserId}
                  users={users}
                />
              ) : (
                <div className="mt-1 text-gray-400 text-xs text-right">
                  <span className="font-semibold mr-2">You</span>
                  {time.format('DD MMM h:mm a')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="chat-message mb-2">
        <div className="flex items-end">
          <div className="flex flex-col space-y-2 text-xs max-w-xs order-2 items-start">
            <div>
              <span
                className={`${padding} rounded-lg inline-block rounded-bl-none bg-gray-200 text-gray-600 text-sm`}>
                <div style={{whiteSpace: 'pre-wrap'}}>{message.content}</div>
                {message.media && (
                  <div className="block">
                    <LightboxGallery
                      sources={[
                        {
                          source: message.media,
                          thumbnail: message.mediaPreview,
                          type: 'image',
                        },
                      ]}
                    />
                  </div>
                )}
              </span>
              <div className="text-gray-400 text-xs mt-1">
                <span className="font-semibold mr-2">{name}</span>
                {time.format('DD MMM h:mm a')}
              </div>
            </div>
          </div>
          <UserAvatar user={message.user} size="6" className="order-1 mr-2" />
        </div>
      </div>
    );
  }
};

export default ChatMessageBubble;
