/**
 * Clears the entire local storage except for entires with the whitelisted keys.
 * @param whitelistedKeys The keys to preserve in local storage.
 */
const clearLocalStorageWithWhitelist = (whitelistedKeys: string[]) => {
  /**
   * Determine the entries in local storage that should be preserved
   * based on the whitelisted keys.
   */
  const entriesToPreserve = whitelistedKeys
    // Retrieve the corresponding value for each of the keys.
    .map((key) => {
      return {key, value: localStorage.getItem(key)};
    })
    // Filter out any entires that do not have a value.
    .filter(({value}) => value !== null);

  /**
   * Clear the entire contents of local storage.
   */
  localStorage.clear();

  /**
   * Restore the preserved entries.
   */
  entriesToPreserve.forEach(({key, value}) => {
    localStorage.setItem(key, value);
  });
};

export default clearLocalStorageWithWhitelist;
