import 'react-cmdk/dist/cmdk.css';
import {useCallback, useEffect, useState} from 'react';

import {BiBuildingHouse} from '@react-icons/all-files/bi/BiBuildingHouse';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import CommandPalette from 'react-cmdk';
import {isMobile} from 'react-device-detect';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import ManualPropertyAddressModal from 'components/property/landlord/ManualPropertyAddressModal';
import {SpinningLoader} from 'components_sb/feedback';
import {Modal} from 'components_sb/layout';
import {API_URL} from 'globals/app-globals';
import useChangeKeyboardResizeMode from 'hooks/useChangeKeyboardResizeMode';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';

const {useModal} = Modal.Imperative;

const AddPropertyDescription = ({
  setIsOpen,
}: {
  setIsOpen: (b: boolean) => void;
}) => {
  const openModal = useModal();

  /**
   * Show the modal for manually entering an address instead of
   * selecting from the search results.
   */
  const showManualAddressModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      openModal(ManualPropertyAddressModal);
    }, 300);
  };

  return (
    <div className="w-full rounded-lg bg-gradient-to-br from-brand-500 to-brand-400 p-4">
      <h2 className="text-lg font-semibold leading-tight text-white">
        Add your property
      </h2>
      <p className="text-sm text-white/80 font-medium mt-1">
        Start typing your address in the search bar above to add your property.
      </p>
      <p className="text-sm text-white/80 font-medium mt-1">
        Can't find your address? {isMobile ? 'Tap' : 'Click'} the link below to
        add it manually.
      </p>

      <a
        onClick={showManualAddressModal}
        className="link link-secondary mt-3 text-white text-sm block">
        Add address manually
      </a>
    </div>
  );
};

const PropertyLoadingDescription = () => {
  return (
    <div
      data-testid="adding-property-indicator"
      className="w-full rounded-lg bg-gradient-to-br from-brand-500 to-brand-400 p-4">
      <h2 className="text-lg font-semibold leading-tight text-white flex justify-start gap-2 items-center">
        Property is being added <SpinningLoader color="white" size="xs" />
      </h2>
      <p className="text-sm text-white/80 font-medium mt-1">
        Your property is being added, please hold tight for a second.
      </p>
    </div>
  );
};

const AutocompletePropertyAddressModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}) => {
  const [search, setSearch] = useState('');
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);

  /**
   * This is a fix for issues in Capacitor
   * We change the Keyboard resize mode to Body when the modal is open
   * and back to Native when it is closed.
   * This is to prevent the keyboard from pushing the modal up and down
   * when the user is typing.
   */
  useChangeKeyboardResizeMode();

  const {currentUser} = useAuth();

  const navigate = useNavigate();

  /**
   * Track that the add property flow and first step has started
   * when the modal opens.
   */
  useEffect(() => {
    if (isOpen) {
      TrackingService.trackEvent(TrackingService.Event.AddProperty_StartFlow);
      TrackingService.trackEvent(
        TrackingService.Event.AddProperty_StartAddressStep,
      );
    }
  }, [isOpen]);

  /**
   * Fetch and set results for the current search query.
   */
  const filter = useCallback(async (): Promise<any[]> => {
    if (search.length <= 2) {
      setSearchOptions([]);
      return;
    }

    const url = `${API_URL}/addresses.json?query=${search}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const places = data.addresses;

      setSearchOptions(places);
    } catch (e) {
      setSearchOptions([]);
    }
  }, [search]);

  /**
   * Debounce the search query function.
   */
  const query = AwesomeDebouncePromise(filter, 300);

  /**
   * Handle selecting an address.
   */
  const selectAddress = useCallback(
    async (option: any) => {
      if (option) {
        setSelectedAddress(option);
        setSearchOptions([]);

        const url = `${API_URL}/properties/setup_property.json?address_id=${option.id}`;

        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-User-Email': currentUser.email,
              'X-User-Token': currentUser.meta.authenticationToken,
            },
          });

          const data = await response.json();

          if (response.ok) {
            const {property: propertyId} = data;

            /**
             * Track completion of the step.
             */
            TrackingService.trackEvent(
              TrackingService.Event.AddProperty_CompleteAddressStep,
              {
                propertyId,
              },
            );

            /**
             * Track starting the onboarding type selection step.
             */
            TrackingService.trackEvent(
              TrackingService.Event.AddProperty_StartOnboardTypeStep,
              {
                propertyId,
              },
            );

            localStorage.setItem('new-property-id', propertyId);
            navigate(`/properties/${data.property}`, {
              state: {
                afterAddProperty: true,
              },
            });
          } else {
            toast.error(data.errors.join(', '));
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    [currentUser, navigate],
  );

  /**
   * Perform the search query request when the search
   * query value changes.
   */
  useEffect(() => {
    query();
  }, [search]);

  /**
   * Handle the opening and closing state of the modal.
   */
  const onChangeOpen = useCallback(
    (value: boolean) => {
      /**
       * Prevent closing while the property is being added.
       */
      if (selectedAddress && !value) {
        return;
      }

      /**
       * Clear any existing search results.
       */
      setSearchOptions([]);
      /**
       * Clear the search query value.
       */
      setSearch('');
      /**
       * Set the modal to the open state.
       */
      setIsOpen(value);
    },
    [selectedAddress],
  );

  return (
    <div data-testid="autocomplete-property-address-modal">
      <CommandPalette
        onChangeSearch={setSearch}
        onChangeOpen={onChangeOpen}
        search={search}
        isOpen={isOpen}
        page="root"
        placeholder="Start typing your property address...">
        <CommandPalette.Page id="root">
          {searchOptions.length === 0 && (
            <CommandPalette.List>
              {selectedAddress ? (
                <PropertyLoadingDescription />
              ) : (
                <AddPropertyDescription setIsOpen={setIsOpen} />
              )}
            </CommandPalette.List>
          )}
          {searchOptions.length > 0 && (
            <CommandPalette.List heading="Address Suggestions">
              {searchOptions.map((place, index) => {
                const [street, ...rest] = place.text.split(', ');
                return (
                  <div
                    key={index}
                    className="flex items-center space-x-2 sm:space-x-4 py-2 hover:bg-neutral-100 cursor-pointer"
                    onClick={() => selectAddress(place)}>
                    <span className="block text-neutral-400">
                      <BiBuildingHouse className="w-6 h-6" />
                    </span>
                    <div className="flex flex-col">
                      <span className="block font-medium text-neutral-700">
                        {street}
                      </span>
                      <span className="block text-sm text-neutral-500">
                        {rest.join(', ')}
                      </span>
                    </div>
                  </div>
                );
              })}
            </CommandPalette.List>
          )}
        </CommandPalette.Page>
      </CommandPalette>
    </div>
  );
};

export default AutocompletePropertyAddressModal;
