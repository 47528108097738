import {useCallback, useImperativeHandle} from 'react';

import * as Sentry from '@sentry/react';
import {useFormik} from 'formik';
import {isEmpty} from 'lodash';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {TextField} from 'components_sb/input';
import {ModalDefinition} from 'components_sb/layout';

const RenameBillingMethodModal: ModalDefinition = {
  title: 'Rename Billing Method',
  buttonsConfig: {
    actions: [
      {
        id: 'save',
        label: 'Save',
        handle: 'onSave',
      },
    ],
  },
  ContentComponent: ({billingMethod}, ref) => {
    const queryClient = useQueryClient();

    /**
     * Handle the rename action.
     */
    const handleRename = useCallback(
      async ({nickname}: {nickname: string}) => {
        try {
          /**
           * Set and save the new nickname on the billing method instance.
           */
          billingMethod.nickname = nickname;
          await billingMethod.save();
          /**
           * Invalidate the query so that the billing methods list is updated.
           */
          queryClient.invalidateQueries('fetch-user-billing-methods');
          /**
           * Notify the user that the billing method was renamed.
           */
          toast.success('Billing method successfully renamed!');
        } catch (error) {
          /**
           * Log the error in Sentry.
           */
          Sentry.withScope((scope) => {
            scope.setTag('action', 'rename_billing_method');
            Sentry.captureException(error);
          });
          /**
           * Notify the user of the error.
           */
          toast.error('There was an issue renaming this billing method.');
          /**
           * Throw the error to prevent the modal from automatically closing.
           */
          throw error;
        }
      },
      [billingMethod, queryClient],
    );

    const form = useFormik({
      onSubmit: handleRename,
      initialValues: {
        // Prefill the nickname with the current value if set
        nickname: billingMethod.nickname ?? '',
      },
      validationSchema: Yup.string()
        .required('Please enter a nickname for the card')
        .min(3, 'Nicknames must be at least 3 characters')
        .max(25, 'Nicknames must be no more than 25 characters'),
      validateOnBlur: false,
      validateOnChange: false,
    });

    const onSave = useCallback(async () => {
      const result = await form.validateForm();
      if (isEmpty(result)) {
        await handleRename(form.values);
      }
      return false;
    }, [form, handleRename]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <>
        {/* Cardholder name */}
        <TextField
          name="nickname"
          label="Card nickname"
          description="Give your card a custom name to help you identify it in your Keyhook account."
          placeholder='E.g. "My Personal Card"'
          type="text"
          size="base"
          mode="formik"
          form={form}
        />
      </>
    );
  },
};

export default RenameBillingMethodModal;
