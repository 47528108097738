import {ReactNode} from 'react';

import {GoogleOAuthProvider} from '@react-oauth/google';
import {CookiesProvider} from 'react-cookie';
import {SkeletonTheme} from 'react-loading-skeleton';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';

import MobileDrawer from 'components/navbar/MobileDrawer';
import {Toaster} from 'components_sb/feedback';
import {Modal} from 'components_sb/layout';
import AppLinkProvider from 'providers/AppLinkProvider';
import DayjsProvider from 'providers/DayjsProvider';
import FullScreenLoader from 'providers/FullScreenLoader';
import {InspectionUploaderProvider} from 'providers/InspectionUploader';
import {PushPermissionsProvider} from 'providers/PushPermissions';
import {SpraypaintMiddlewareProvider} from 'providers/SpraypaintMiddleware';
import {ToursProvider} from 'providers/ToursProvider';
import {PusherServiceProvider} from 'services/PusherService';
import NavItemBadgeCount from 'utilities/nav-links/NavItemBadgeCount';

import {colors} from './themes/keyhook';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Not ideal
// But we need the queryclient in some hooks
// like when we log out a user
(window as any).queryClient = queryClient;

export default ({children}: {children: ReactNode}) => {
  return (
    <BrowserRouter>
      <DayjsProvider>
        <QueryClientProvider client={queryClient}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <CookiesProvider>
              <SpraypaintMiddlewareProvider>
                <GoogleOAuthProvider clientId="469157937967-86ntkk49m2mjqbf571u9mmkdqh0dae18.apps.googleusercontent.com">
                  <SkeletonTheme
                    baseColor={colors.brand[50]}
                    highlightColor={colors.brand[25]}
                    borderRadius={10}>
                    <FullScreenLoader.Provider>
                      <InspectionUploaderProvider>
                        <Modal.Imperative.Provider>
                          <PushPermissionsProvider>
                            <PusherServiceProvider>
                              <NavItemBadgeCount.Provider>
                                <ToursProvider>
                                  <MobileDrawer.Provider>
                                    <AppLinkProvider>
                                      {children}
                                    </AppLinkProvider>
                                  </MobileDrawer.Provider>
                                </ToursProvider>
                              </NavItemBadgeCount.Provider>
                            </PusherServiceProvider>
                          </PushPermissionsProvider>
                        </Modal.Imperative.Provider>
                      </InspectionUploaderProvider>
                    </FullScreenLoader.Provider>
                  </SkeletonTheme>
                  <Toaster />
                </GoogleOAuthProvider>
              </SpraypaintMiddlewareProvider>
            </CookiesProvider>
          </QueryParamProvider>
        </QueryClientProvider>
      </DayjsProvider>
    </BrowserRouter>
  );
};
