import {useMemo} from 'react';

import clsx from 'clsx';

import theme from '../../themes/keyhook';

interface Userish {
  name: string;
  avatar?: string;
}

/**
 * Use the main brand colour and remove the asterisk
 * from the beginning of the hex code.
 */
const COLOR = theme.colors.brand[500].replace('#', '');

const UserAvatar = ({
  user,
  size,
  className,
}: {
  user: Userish;
  size?: number | string;
  className?: string;
}) => {
  if (!size) {
    size = '16';
  }

  const imageSize = useMemo(() => Math.max(48, Number(size)), [size]);

  const url = useMemo(
    () =>
      user.avatar?.length
        ? user.avatar
        : `https://ui-avatars.com/api/?name=${user.name}&background=${COLOR}&color=fff&rounded=true&size=${imageSize}`,
    [user, imageSize],
  );

  return (
    <div className={clsx(`avatar`, className)}>
      <div className={`rounded-full overflow-hidden w-${size} h-${size}`}>
        <img src={url} />
      </div>
    </div>
  );
};

export default UserAvatar;
