import {FunctionComponent} from 'react';

import clsx from 'clsx';
import {IconType} from 'react-icons/lib';

interface FieldIconProps {
  icon: IconType;
}

const FieldIcon: FunctionComponent<FieldIconProps> = ({icon: Icon}) => (
  <Icon className={clsx('pointer-events-none', 'w-6 h-6', 'text-brand-500')} />
);

export default FieldIcon;
