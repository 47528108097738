import {FunctionComponent, useCallback, useMemo} from 'react';

import {MdOutlineDirectionsCar} from 'react-icons/md';

import {RangeSlider} from 'components_sb/input';
import {
  GARAGES as CONSTANTS,
  getPlaceholderValue,
} from 'constants/listings-search';

import FilterWrapper from './shared/FilterWrapper';
import {FilterProps} from './types';

type GaragesFilterProps = FilterProps;

const GaragesFilter: FunctionComponent<GaragesFilterProps> = ({
  form,
  name,
  inline = false,
}) => {
  const hasModifiedDefault = useMemo(
    () => form.values[name] !== undefined,
    [form, name],
  );

  const value = useMemo(
    () => (!hasModifiedDefault ? getPlaceholderValue(name) : form.values[name]),
    [hasModifiedDefault, form, name],
  );

  const onChange = useCallback(
    (value: [number, number]) => {
      form.setFieldValue(name, value);
    },
    [form, name],
  );

  const valueLabel = useMemo(
    () => (!hasModifiedDefault ? null : `${value[0]} → ${value[1]}`),
    [hasModifiedDefault, value],
  );

  const clearFilter = useCallback(() => {
    /**
     * We can't use setFieldValue here, because Formik will delete
     * the entry from the form values if we are setting it to undefined.
     */
    form.setFormikState((current) => ({
      ...current,
      values: {
        ...current.values,
        [name]: undefined,
      },
    }));
  }, [form, name]);

  return (
    <FilterWrapper
      inline={inline}
      label="Garages"
      icon={MdOutlineDirectionsCar}
      valueLabel={valueLabel}
      onClearFilter={clearFilter}>
      <RangeSlider
        name={name}
        min={CONSTANTS.MIN}
        max={CONSTANTS.MAX}
        step={CONSTANTS.STEP}
        value={value}
        onChange={onChange}
      />
    </FilterWrapper>
  );
};

export default GaragesFilter;
