import {useCallback, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {isMobile} from 'react-device-detect';
import {HiOutlineCreditCard} from 'react-icons/hi';
import {useQuery} from 'react-query';

import AddBillingMethodModal from 'components/payment/AddBillingMethodModal';
import {Button} from 'components_sb/buttons';
import {InlineError, SpinningLoader} from 'components_sb/feedback';
import {Card, Modal} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import useEarliestCreated from 'hooks/spraypaint/useEarliestCreated';
import BillingMethod from 'models/billing/BillingMethod';

import PinCardSummary from './PinCardSummary';
import SmartRentSummary from './SmartRentSummary';

const {useModal} = Modal.Imperative;

const BillingMethodsSection = () => {
  const openModal = useModal();

  /**
   * Handle adding a new billing method.
   */
  const onAddBillingMethod = useCallback(
    () => openModal(AddBillingMethodModal),
    [openModal],
  );

  const billingMethodsQuery = useQuery(
    'fetch-user-billing-methods',
    async () =>
      await BillingMethod.select([
        'billable_type',
        'billable_id',
        'provider',
        'token',
        'nickname',
        'metadata',
      ])
        // .includes({
        //   properties: ['street_address'],
        // })
        .stats({total: 'count'})
        .all(),
    {retry: 1},
  );

  /**
   * Default to an empty array if the query is loading or errored,
   * otherwise return the billing methods from the query response.
   */
  const billingMethods = useMemo(
    () => (!billingMethodsQuery.isSuccess ? [] : billingMethodsQuery.data.data),
    [billingMethodsQuery],
  );

  /**
   * Find the default credit/debit card.
   * TODO: Change this to use the default billing method flag once this
   * has been implemented in the backend.
   */
  const defaultBillingMethod = useEarliestCreated(billingMethods);

  /**
   * Credit card billing is not currently shown on mobile platforms.
   */
  const showCreditCardBilling = useMemo(
    () => !Capacitor.isNativePlatform(),
    [],
  );

  return (
    <Card icon={HiOutlineCreditCard} title="Billing Methods" className="mb-8">
      {billingMethodsQuery.isLoading && (
        <SpinningLoader size="base" color="brand" />
      )}

      {billingMethodsQuery.isError && (
        <InlineError error="There was an issue loading your billing methods. Please try again later or constact us for support." />
      )}

      {billingMethodsQuery.isFetched && (
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col">
            <Paragraph>
              Properties with their rental income handled via Keyhook Smart Rent
              will have fees automatically deducted from this.
            </Paragraph>
            {showCreditCardBilling && (
              <>
                <Paragraph>
                  If you choose to receive your rental income directly, you are
                  required to add a credit/debit card for payment of any fees.
                </Paragraph>
                <Paragraph>
                  For properties that you have chosen to receive your rental
                  income for directly:
                </Paragraph>
                <ul className="mb-3 list-inside list-disc text-brand-850 text-opacity-70">
                  <li>
                    <Paragraph secondary inline size="sm">
                      You may add multiple credit/debit cards to pay fees
                      separately for different properties.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph secondary inline size="sm">
                      Your default credit/debit card will be used for fees
                      unless you have explicitly set a different card to use for
                      these properties.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph secondary inline size="sm">
                      Your may switch between cards at any time for properties
                      that are already set up with card payments.
                    </Paragraph>
                  </li>
                </ul>

                <Paragraph disableMargin>
                  {`To switch between Keyhook Smart Rent and card payments for a
                    property, please `}
                  <Link to="https://keyhook.com/contact/">
                    get in touch with our support team
                  </Link>
                  .
                </Paragraph>
              </>
            )}
          </div>

          {/* A list of each of the billing methods for the user */}
          <div className="my-4 grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ">
            <SmartRentSummary />
            {showCreditCardBilling ? (
              billingMethods.length > 0 &&
              billingMethods.map((billingMethod) =>
                billingMethod.provider === 'pin_payments' ? (
                  <PinCardSummary
                    key={billingMethod.id}
                    billingMethod={billingMethod}
                    /**
                     * TODO: Remove the isDefault prop once the billing method
                     * instance itself knows whether it is the default.
                     */
                    isDefault={defaultBillingMethod.id === billingMethod.id}
                  />
                ) : (
                  <></> // Unsupported billing method
                ),
              )
            ) : (
              <div>Looking for other billing methods?</div>
            )}
          </div>

          {showCreditCardBilling && (
            <>
              {/* A message for displaying to the user when they have
                no additional billing methods added */}
              {!billingMethods.length && (
                <>
                  <Paragraph>
                    You currently do not have any credit/debit cards added to
                    your account.{' '}
                    {`${isMobile ? 'Tap' : 'Click'} below to add one.`}
                  </Paragraph>
                </>
              )}
              <div className="mt-2">
                <Button
                  label="Add a new credit/debit card"
                  category="primary"
                  size="base"
                  fillWidth={false}
                  mode="manual"
                  onClick={onAddBillingMethod}
                />
              </div>
            </>
          )}
        </div>
      )}
    </Card>
  );
};

export default BillingMethodsSection;
