import React, {useEffect, useMemo, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {Network} from '@capacitor/network';
import {ArrowRightIcon, PlusIcon} from '@heroicons/react/outline';
import {CheckIcon} from '@heroicons/react/solid';
import _ from 'lodash';
import moment from 'moment';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import Toggle from 'react-toggle';

import ChatableMessages from 'components/chat/ChatableMessages';
import {CalendarIcon} from 'components/common/CalendarIcon';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import ActionsList from 'components/inspection/actions/ActionsList';
import AddInspectionAreaModal from 'components/inspection/AddInspectionAreaModal';
import InspectionAddActionModal from 'components/inspection/InspectionAddActionModal';
import InspectionAddNoteModal from 'components/inspection/InspectionAddNoteModal';
import InspectionSignOffForm from 'components/inspection/InspectionSignOffForm';
import NoteList from 'components/inspection/notes/NoteList';
import {Card, Modal} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import InspectionItem from 'models/inspections/InspectionItem';
import {useInspectionUploader} from 'providers/InspectionUploader';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import useInspectionStore, {
  InspectionEventEmitter,
} from 'stores/InspectionStore';
import {titleize} from 'utilities/StringHelpers';

const {useModal} = Modal.Imperative;

const helpTexts = {
  'Lights/Power points':
    'Are all lights and power points in the property working?',
  'Smoke alarms':
    "Test all smoke alarms in the property to check if they're working.",
} as Record<string, string>;

const RoomList = () => {
  const [
    inspection,
    setInspection,
    rawInspectionItems,
    setInspectionItems,
    setSelectedRoomKey,
    database,
  ] = useInspectionStore((state) => [
    state.inspection,
    state.setInspection,
    state.inspectionItems,
    state.setInspectionItems,
    state.setSelectedRoomKey,
    state.database,
  ]);

  const {activeAccountRole} = useLocalUserSettings();

  const inspectionUploader = useInspectionUploader();

  const [unuploadedItems, setUnuploadedItems] = useState(0);
  const [networkConnected, setNetworkConnected] = useState<boolean>(true);

  const navigate = useNavigate();

  const openModal = useModal();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  useEffect(() => {
    Network.addListener('networkStatusChange', (status: any) => {
      setNetworkConnected(status.connected);
    });

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    const updateRecords = async () => {
      await database.createIndex({
        index: {
          fields: ['inspectionId'],
        },
      });

      const records = await database.find({
        selector: {
          inspectionId: inspection.id,
        },
      });

      setUnuploadedItems(records.docs.length);
    };

    if (Capacitor.isNativePlatform()) {
      updateRecords();
    }

    InspectionEventEmitter.on('INSPECTION_UPDATED', updateRecords);

    return () => {
      InspectionEventEmitter.removeListener(
        'INSPECTION_UPDATED',
        updateRecords,
      );
    };
  }, [database, inspection]);

  const inspectionItems = useMemo(
    () => (rawInspectionItems ? _.groupBy(rawInspectionItems, 'room') : {}),
    [rawInspectionItems],
  );

  const hasAllRoom =
    _.has(inspectionItems, 'All') && inspectionItems.All.length > 0;

  const amendmentsCount = useMemo(() => {
    if (inspection?.status === 'awaiting_sign_offs' && rawInspectionItems) {
      let count = 0;
      rawInspectionItems.forEach((item) => {
        count += item.inspectionItemAttachments.filter(
          (attach) => attach.amended,
        ).length;
        count += item.inspectionActions.filter(
          (action) => action.amended,
        ).length;
      });

      return count;
    } else {
      return 0;
    }
  }, [inspection, rawInspectionItems]);

  const handleRoomPress = (key: string) => {
    setSelectedRoomKey(key);
    const roomName = encodeURIComponent(key);

    if (
      inspection.status === 'awaiting_inspection' &&
      Capacitor.isNativePlatform()
    ) {
      navigate(`${roomName}/camera`);
    } else {
      navigate(roomName);
    }
  };

  const uploadPendingItems = () => {
    if (Capacitor.isNativePlatform() && networkConnected) {
      if (!inspectionUploader.isRunning) {
        try {
          inspectionUploader.processQueue(inspection.id);
        } catch (e) {
          console.log(e);
        }
      }
      toast.success('Uploading has started');
    } else {
      toast.error('You are not currently connected to the internet.');
    }
  };

  const showAddAreaModal = () => openModal(AddInspectionAreaModal);

  // const deleteArea = async (key: string) => {
  //   const items = inspectionItems[key];
  //   if (items) {
  //     for (const item of items) {
  //       await item.destroy();
  //     }

  //     const newInspectionItems = rawInspectionItems.filter(
  //       (i: any) => i.room !== key,
  //     );
  //     setInspectionItems(newInspectionItems);

  //     inspection.deleteSignOffs();
  //   }
  // };

  const renderRoom = (roomKey: string, index: number) => {
    const items = inspectionItems[roomKey];

    const IconForRoom = () => {
      if (inspection.completedRooms.includes(roomKey)) {
        if (_.some(items, (item) => item.inspectionActions.length > 0)) {
          return (
            <div className="bg-warning rounded-full w-6 h-6 flex justify-center items-center">
              <span className="text-white w-3 h-3">!</span>
            </div>
          );
        } else {
          return (
            <div className="bg-success rounded-full w-6 h-6 flex justify-center items-center">
              <CheckIcon className="text-white w-4 h-4" />
            </div>
          );
        }
      } else {
        return <ArrowRightIcon className="w-4 h-4" />;
      }
    };

    if (roomKey === 'All') {
      return <React.Fragment key={index} />;
    } else {
      const border = inspection.completedRooms.includes(roomKey)
        ? 'border-success border-3'
        : 'border-none';
      return (
        <button
          type="button"
          className={`btn btn-secondary bg-base-200 ${border} flex flex-nowrap justify-between items-center leading-tight`}
          key={index}
          onClick={() => handleRoomPress(roomKey)}>
          <span className="flex-shrink truncate">{roomKey}</span>
          <IconForRoom />
        </button>
      );
    }
  };

  const toggleItem = (item: InspectionItem) => {
    item.toggleState = !item.toggleState;
    item.save();

    setInspectionItems([...rawInspectionItems]);

    inspection.deleteSignOffs();
  };

  const showAddNoteModal = (item: InspectionItem) =>
    openModal(InspectionAddNoteModal, {inspectionItem: item});

  const showAddActionModal = (item: InspectionItem) =>
    openModal(InspectionAddActionModal, {inspectionItem: item});

  const renderToggleItem = (item: InspectionItem, index: number) => {
    const helpText = helpTexts[item.name];
    return (
      <div key={index} className={index > 0 ? 'mt-4' : ''}>
        <div className="flex justify-between items-center">
          <strong>
            {item.name} <HelpTextPresenter>{helpText}</HelpTextPresenter>
          </strong>
          <Toggle
            checked={item.toggleState}
            onChange={() => toggleItem(item)}
          />
        </div>

        {!item.toggleState && (
          <div className="mt-2 mb-4 flex justify-start gap-4">
            <button
              className="btn btn-sm btn-info"
              onClick={() => showAddNoteModal(item)}>
              Add Note
            </button>

            <button
              className="btn btn-sm btn-warning"
              onClick={() => showAddActionModal(item)}>
              Add Action
            </button>
          </div>
        )}

        <NoteList inspection={inspection} item={item} />
        <ActionsList inspection={inspection} item={item} />
      </div>
    );
  };

  const confirmFinishInspection = () => {
    if (
      inspection.completedRooms.length ===
      Object.keys(inspectionItems).length - 1
    ) {
      finishInspection();
    } else {
      setConfirmationOptions({
        color: 'success',
        title: 'Finish Inspection',
        message:
          'Are you sure you want to finish this inspection? It looks like some areas may be incomplete.',
        buttonTitle: 'Confirm',
        action: finishInspection,
      });
    }
  };

  const finishInspection = async () => {
    inspection.status = 'awaiting_sign_offs';

    const result = await inspection.save();
    if (result) {
      setInspection(inspection);
    }
  };

  const showAmendments = () => navigate('revisions');

  let title: string;
  if (inspection.inspectionPeriodType === 'pre_tenancy') {
    title = 'Initial Inspection';
  } else if (inspection.inspectionPeriodType === 'final') {
    title = 'Final Inspection';
  } else {
    title = 'Routine Inspection';
  }
  return (
    <div>
      <Card title={title} className="mt-4">
        <div className="flex justify-start items-center gap-4 ">
          <CalendarIcon date={new Date(inspection.finalizedDate)} />
          <div>
            <p className="text-secondary">
              {moment(inspection?.finalizedDate).format(
                inspection?.newInspectionTimeFormat,
              )}
            </p>
            <p className="text-secondary">
              {titleize(inspection.doneBy)}, {titleize(inspection.reportType)}
            </p>
          </div>
        </div>
      </Card>

      {unuploadedItems > 0 && (
        <div
          className="alert bg-brand-850 text-white shadow-lg mt-4 cursor-pointer"
          onClick={uploadPendingItems}>
          There are {unuploadedItems} un-uploaded photos/videos for this
          inspection. Click here to upload them.
        </div>
      )}

      <Card title="Select an area">
        {inspection.status === 'awaiting_sign_offs' && (
          <p className="mt-2">
            Please note, making any changes will void any existing signatures.
          </p>
        )}

        <div className="mt-4 grid grid-cols-2 gap-2">
          {Object.keys(inspectionItems).map(renderRoom)}

          {activeAccountRole === 'Landlord' && (
            <button
              type="button"
              className="btn btn-secondary bg-neutral-content border-none flex justify-between items-center"
              onClick={showAddAreaModal}>
              <span className="flex-shrink">Add Area</span>
              <PlusIcon className="w-4 h-4" />
            </button>
          )}

          {inspection.status === 'awaiting_sign_offs' && (
            <button
              type="button"
              className="btn btn-secondary bg-neutral-content border-none flex justify-between items-center"
              onClick={showAmendments}>
              <span className="flex-shrink">Revisions</span>

              <div className="bg-error rounded-full w-6 h-6 flex justify-center items-center">
                <span className="text-white text-sm">{amendmentsCount}</span>
              </div>
            </button>
          )}
        </div>
      </Card>

      {hasAllRoom && (
        <Card className="mt-4">
          <div>{inspectionItems.All.map(renderToggleItem)}</div>
        </Card>
      )}

      {inspection.status === 'awaiting_inspection' && (
        <button
          type="button"
          className="mt-4 btn btn-block btn-success"
          onClick={confirmFinishInspection}>
          Finish Inspection
        </button>
      )}

      {inspection.status === 'awaiting_sign_offs' && (
        <>
          <InspectionSignOffForm />
          <div>
            <ChatableMessages
              chatableId={inspection.id}
              chatableType="Inspection"
              disableMedia
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RoomList;
