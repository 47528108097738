import {useEffect, useMemo} from 'react';

import {appleAuthHelpers} from 'react-apple-signin-auth';
import {useScript} from 'usehooks-ts';

/**
 * Handles initialisation of the external scripts required for
 * authentication via social providers.
 */
const useAuthScripts = () => {
  useEffect(() => {
    console.log('Loading social auth provider scripts...');
  }, []);

  // Facebook script
  const facebookStatus = useScript(
    'https://connect.facebook.net/en_US/sdk.js',
    {
      removeOnUnmount: false,
    },
  );

  // Google script
  const googleStatus = useScript('https://accounts.google.com/gsi/client', {
    removeOnUnmount: false,
  });

  // Apple script
  const appleStatus = useScript(appleAuthHelpers.APPLE_SCRIPT_SRC, {
    removeOnUnmount: false,
  });

  const allScriptsReady = useMemo(
    () =>
      facebookStatus === 'ready' &&
      googleStatus === 'ready' &&
      appleStatus === 'ready',
    [facebookStatus, googleStatus, appleStatus],
  );

  useEffect(() => {
    if (allScriptsReady) {
      console.log('Social auth provider scripts loaded!');
    }
  }, [allScriptsReady]);

  return {
    ready: allScriptsReady,
  };
};

export default useAuthScripts;
