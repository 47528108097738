import React from 'react';

import {HiPlus} from '@react-icons/all-files/hi/HiPlus';
import clsx from 'clsx';

import '@dotlottie/player-component';

const AddPropertyCard = ({
  showAddressModal,
}: {
  showAddressModal: () => void;
}) => {
  return (
    <div className="flex flex-col justify-center mb-10">
      <button
        onClick={showAddressModal}
        className={clsx(
          'relative h-56 flex justify-center items-center md:space-y-0 rounded-xl overflow-hidden p-3 w-full mx-auto bg-white',
          'transition-all duration-300',
          'border-4 border-dashed',
          'border-brand-100 hover:border-brand-300',
          'text-brand-100 hover:text-brand-300',
        )}>
        <div className="flex flex-col gap-y-2 items-center justify-center">
          <HiPlus className="h-10 w-10" />
          <div className="text-2xl font-medium">Add Property</div>
        </div>
      </button>
    </div>
  );
};

export default AddPropertyCard;
