import React, {useState} from 'react';

import {Form, Formik} from 'formik';
import type {FormikHelpers} from 'formik';
import FsLightbox from 'fslightbox-react';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {SubmitButton, TextareaField} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import InspectionAction from 'models/inspections/InspectionAction';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {titleize} from 'utilities/StringHelpers';

type FormValues = {
  resolutionNotes: string;
  resolved: boolean;
};

const InspectionActionDetailPage = () => {
  const {actionId, propertyId} = useParams();
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const queryClient = useQueryClient();

  const {activeAccountRole} = useLocalUserSettings();

  const {data, error, isLoading} = useQuery(
    ['inspection-action-detail-', actionId],
    async () => {
      const action = await InspectionAction.includes([
        'inspection_item',
        'user',
      ]).find(actionId);

      return action.data;
    },
  );

  const handleResolutionSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const action = data;
    action.assignAttributes(formValues);

    const result = await action.save();

    if (result) {
      queryClient.setQueryData(['inspection-action-detail-', actionId], action);
      toast.success('Action has been successfully resolved!');
    } else {
      console.log(action.errors);
    }

    actions.setSubmitting(false);
  };

  const renderResolution = () => {
    const action = data;

    const renderForm = () => {
      return (
        <Card title="Resolve this action">
          <Paragraph>
            Please include information as to how this action was resolved.
          </Paragraph>

          <Formik
            initialValues={{resolved: true, resolutionNotes: ''} as FormValues}
            onSubmit={handleResolutionSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              resolved: Yup.boolean().label('Resolved'),
              resolutionNotes: Yup.string().required().label('Notes').min(20),
            })}>
            {(formik) => (
              <Form>
                <TextareaField
                  name="resolutionNotes"
                  labelProps={{
                    title: 'Notes',
                    size: 'base',
                  }}
                  formik={formik}
                  placeholder="Write your notes here..."
                />

                <SubmitButton
                  formik={formik}
                  className="btn-success mt-4"
                  text="Mark as resolved"
                  submittingText="Saving"
                />
              </Form>
            )}
          </Formik>
        </Card>
      );
    };

    const renderResolveByOtherParty = () => {
      return (
        <Card title="Unresolved">
          <Paragraph>
            This action is currently unresolved. It can only be resolved by the{' '}
            {titleize(action.actionType)}
          </Paragraph>
        </Card>
      );
    };

    if (action.resolved) {
      return (
        <Card title="Resolved">
          <Paragraph>This action has been marked as resolved</Paragraph>

          <div className="mt-2">
            <strong>Notes</strong>
            <p>{action.resolutionNotes}</p>
          </div>
        </Card>
      );
    } else {
      if (action.actionType === 'landlord') {
        if (activeAccountRole === 'Landlord') {
          return renderForm();
        } else {
          return renderResolveByOtherParty();
        }
      } else {
        if (activeAccountRole === 'Landlord') {
          return renderResolveByOtherParty();
        } else {
          return renderForm();
        }
      }
    }
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper backEnabled title="Action">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const item = data.inspectionItem;
    return (
      <PageWrapper backEnabled title="Action">
        <Card className="mt-4">
          <div className="flex justify-start gap-4">
            <img
              onClick={() => setLightboxOpen(true)}
              className="bg-black inline-block h-[120px] w-[120px] object-cover cursor-pointer rounded-lg"
              src={data.attachmentThumbnail || data.attachment}
            />

            <div>
              <h2 className="card-title mb-4 text-brand-850">
                {titleize(data.actionType)} Action
              </h2>
              <strong>
                {item.room === 'All'
                  ? item.name
                  : `${item.room} - ${item.name}`}
              </strong>
              <p>
                Added by {data.user.name} -{' '}
                <em className="text-secondary">
                  {moment(data.updatedAt).fromNow()}
                </em>
              </p>
              <div className="divider"></div>
              <p>{data.action}</p>
            </div>
          </div>
        </Card>

        {data.serviceRequestId && (
          <Card title="Maintenance Request">
            <Paragraph>
              Press the button below to view the maintenance request for this
              action.
            </Paragraph>

            <Link
              to={`/properties/${propertyId}/service-requests/${data.serviceRequestId}`}
              className="btn btn-neutral mt-4">
              View Maintenance Request
            </Link>
          </Card>
        )}

        {renderResolution()}

        <FsLightbox
          toggler={lightboxOpen}
          sources={[data.attachment]}
          thumbs={[data.attachmentThumbnail]}
          slide={1}
          captions={[
            <div key={data.id} className="flex flex-col">
              <h5 className="text-white text-lg">
                {item.room} - {item.name}
              </h5>
              <p className="text-white text-md">
                <strong>{titleize(data.actionType)} Action: </strong>
                {data.action}
              </p>
            </div>,
          ]}
        />
      </PageWrapper>
    );
  }
};

export default InspectionActionDetailPage;
