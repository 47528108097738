/**
 * https://react-spectrum.adobe.com/react-aria/useTimeField.html
 */

import {FunctionComponent, useRef} from 'react';

import {useDateSegment} from '@react-aria/datepicker';
import {type DateSegment, type DateFieldState} from '@react-stately/datepicker';
import clsx from 'clsx';

interface SegmentProps {
  segment: DateSegment;
  fieldState: DateFieldState;
}

const Segment: FunctionComponent<SegmentProps> = ({segment, fieldState}) => {
  const ref = useRef();
  const {segmentProps} = useDateSegment(segment, fieldState, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth:
          segment.maxValue != null && String(segment.maxValue).length + 'ch',
      }}
      className={clsx(
        'group',
        'flex-1',
        'flex flex-col',
        'text-base',
        'outline-none',
        'text-center',
        'tabular-nums',
        'relative',
        'text-brand-850 focus:text-white',
      )}>
      <span
        className={clsx(
          'flex-1',
          'flex items-center justify-center text-center',
          'w-full',
          'rounded-lg my-2',
          'transition-colors duration-300',
          'bg-transparent group-focus:bg-brand-500',
        )}>
        <span
          className={
            segment.isPlaceholder || !segment.isEditable
              ? 'opacity-50'
              : 'opacity-100'
          }>
          {segment.isPlaceholder ? segment.placeholder : segment.text}
        </span>
      </span>
    </div>
  );
};

export default Segment;
