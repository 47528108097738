import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionSignOff from 'models/inspections/InspectionSignOff';
import Tenancy from 'models/properties/Tenancy';
import useInspectionStore from 'stores/InspectionStore';
import {DATE_FORMAT, LONG_DATE_TIME_FORMAT} from 'utilities/DateHelpers';

@Model()
class Inspection extends ApplicationRecord {
  static jsonapiType = 'inspections';

  @Attr() estimatedDate: string;
  @Attr() finalizedDate: string;

  @Attr() failedItemsCount: number;

  @Attr() inspectionType: string;
  @Attr() status: string;

  @Attr() reportType?: 'detailed' | 'basic';
  @Attr() doneBy?: 'landlord' | 'tenant';

  @Attr() completedRooms: string[];

  @Attr() inspectionPeriodType: string;

  @Attr() tenancyId: string;

  @Attr({persist: false}) inspectionActionsCount: number;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
  @HasMany('inspection_items') inspectionItems: InspectionItem[];
  @HasMany('inspection_items') failedInspectionItems: InspectionItem[];
  @HasMany('inspection_sign_offs') inspectionSignOffs: InspectionSignOff[];
  @HasMany('inspection_actions') inspectionActions: InspectionAction[];
  @HasMany('inspection_actions')
  unresolvedInspectionActions: InspectionAction[];

  async deleteSignOffs(): Promise<void> {
    if (this.status === 'awaiting_sign_offs') {
      // TODO: Convert to hooks
      const setInspection = useInspectionStore.getState().setInspection;
      if (this.inspectionSignOffs && this.inspectionSignOffs.length > 0) {
        const signoffs = this.inspectionSignOffs;
        signoffs.forEach(async (signoff) => {
          await signoff.destroy();
        });

        this.inspectionSignOffs = [];
        setInspection(this);
      }
    }
  }

  get inspectionTimeFormat() {
    if (this.inspectionType === 'in_person') {
      return LONG_DATE_TIME_FORMAT;
    } else {
      return DATE_FORMAT;
    }
  }

  get newInspectionTimeFormat() {
    if (this.doneBy === 'landlord') {
      return LONG_DATE_TIME_FORMAT;
    } else {
      return DATE_FORMAT;
    }
  }
}

export default Inspection;
