import React from 'react';

import InspectionItem from 'models/inspections/InspectionItem';

const InspectionItemTitleWithCounts = ({
  item,
  size = 'large',
}: {
  item: InspectionItem;
  size?: 'large' | 'small';
}) => {
  const attachmentCount = item.inspectionItemAttachments.length;
  const noteCount = item.inspectionItemAttachments.filter(
    (i) => i.notes && i.notes.length > 0,
  ).length;
  const actionCount = item.inspectionActions.length;

  return (
    <div className="flex flex-wrap justify-start items-center gap-2">
      {size === 'large' ? (
        <h2 className="card-title mb-4 text-brand-850">{item.name}</h2>
      ) : (
        <strong>{item.name}</strong>
      )}

      {attachmentCount > 0 && (
        <div className="bg-gray-300 w-6 h-6 flex justify-center items-center rounded-full">
          <span className="text-sm">{attachmentCount}</span>
        </div>
      )}

      {noteCount > 0 && (
        <div className="bg-info w-6 h-6 flex justify-center items-center rounded-full">
          <span className="text-white text-sm">{noteCount}</span>
        </div>
      )}

      {actionCount > 0 && (
        <div className="bg-warning w-6 h-6 flex justify-center items-center rounded-full">
          <span className="text-white text-sm">{actionCount}</span>
        </div>
      )}
    </div>
  );
};

export default InspectionItemTitleWithCounts;
