import React, {useMemo} from 'react';

import {useQuery} from 'react-query';

import {Alert} from 'components_sb/feedback';
import {StatCard} from 'components_sb/layout';
import {OBFS} from 'constants/onboarding-flow-steps';
import ExternalValuation from 'models/insights/ExternalValuation';
import Property from 'models/properties/Property';
import {toCurrency} from 'utilities/StringHelpers';

const PropertyStatCards = ({property}: {property: Property}) => {
  const {data} = useQuery(`property-${property.id}-insights`, async () => {
    const data = await ExternalValuation.order({date_recorded: 'desc'})
      .where({property_id: property.id})
      .per(1)
      .all();

    return data.data && data.data.length > 0 ? data.data[0] : null;
  });

  /**
   * Hide the market value until the onboarding flow has been completed.
   */
  const hideMarketValue = useMemo(
    () => property.currentOnboardingStep !== OBFS.Completed,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [property.lastOnboardingStepCompleted],
  );

  if (!property.extraInformation) {
    return null;
  }

  const pastSales = property.extraInformation.sales;
  if (!pastSales || pastSales.length === 0) {
    return null;
  }

  if (!data) {
    return null;
  }

  const purchasePrice = pastSales[0].price;
  const currentRv = data ? data.extraInformation['capital_value'] : null;
  const estimatedMarketValue = data.valuation;

  if (!purchasePrice || !currentRv || !estimatedMarketValue) {
    return null;
  }

  return (
    <>
      <Alert
        showOnce
        id="property-financial-information-visibility"
        title={
          'Note that the financial information below is only viewable by you - it will not be shown to your tenants.'
        }
      />
      <div className="flex flex-col md:flex-row gap-x-6">
        {/* Purchase Price */}
        <StatCard
          label="Purchase Price"
          helpText="This is the most recent purchase price available. Recent purchases may not be reflected here."
          value={toCurrency(purchasePrice, false)}
        />

        {/* Current RV */}
        <StatCard label="Current RV" value={toCurrency(currentRv, false)} />

        {/* Estimated Market Value */}
        <StatCard
          label="Estimated Market Value"
          helpText={
            hideMarketValue
              ? 'This is the most recent purchase price available. Recent purchases may not be reflected here.'
              : undefined
          }
          value={toCurrency(estimatedMarketValue, false)}
          blur={hideMarketValue}
        />
      </div>
    </>
  );
};

export default PropertyStatCards;
