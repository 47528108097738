import {useCallback, useImperativeHandle, useMemo} from 'react';

import {HiCheckCircle} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import listingPlatforms, {
  PREMIUM_LISTING_FEE_EXCL_GST,
} from 'constants/listing-platforms';
import BillingMethod from 'models/billing/BillingMethod';
import {saveResource} from 'utilities/SpraypaintHelpers';

const platforms = listingPlatforms.filter(({premium}) => premium);

const PremiumListingPlatformsPublishModal: ModalDefinition = {
  title: 'Publish to our premium listing platforms',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'publish',
        label: {
          idle: 'Publish',
          loading: 'Publishing',
        },
        handle: 'onConfirm',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, listing} = props;
    const queryClient = useQueryClient();

    /**
     * Fetch any existing billing methods for the user.
     */
    const billingMethodsQuery = useQuery(
      'fetch-user-billing-methods',
      async () => await BillingMethod.select(['nickname', 'metadata']).all(),
      {retry: 1},
    );

    /**
     * Determine if the user has added any billing methods.
     */
    const hasAddedBillingMethod = useMemo(
      () =>
        billingMethodsQuery.isSuccess &&
        billingMethodsQuery.data.data.length > 0,
      [billingMethodsQuery],
    );

    const onConfirm = useCallback(async () => {
      if (!hasAddedBillingMethod) {
        toast.error(
          'Please add a billing method to publish to Trade Me and homes.co.nz.',
        );
        /**
         * Throw an error to indicate that the modal action failed and stop
         * the modal from automatically closing.
         */
        throw new Error();
      }

      /**
       * Add Trade Me to the chosen external listings.
       */
      listing.chosenExternalListingSites = [
        ...listing.chosenExternalListingSites,
        'trademe.co.nz',
        'homes.co.nz',
      ];

      /**
       * Save the changes to the listing.
       */
      if (!(await saveResource(listing))) {
        /**
         * Throw an error to indicate that the modal action failed and stop
         * the modal from automatically closing.
         */
        throw new Error();
      }

      if (listing.errors?.length) {
        toast.error(
          'There was an error publishing your listing to Trade Me and homes.co.nz.',
        );
        /**
         * Throw an error to indicate that the modal action failed and stop
         * the modal from automatically closing.
         */
        throw new Error();
      }

      /**
       * Invalidate the query for the external listings.
       */
      await queryClient.invalidateQueries([
        'listing-platforms',
        {listingPublicId: listing.publicId},
      ]);

      toast.success(
        'Your listing will be published to Trade Me and homes.co.nz shortly!',
      );
    }, [hasAddedBillingMethod, listing, queryClient]);

    useImperativeHandle(ref, () => ({
      onConfirm,
    }));

    return (
      <div className="mt-2 flex flex-col gap-y-4">
        {!billingMethodsQuery.isSuccess ? (
          <div className="flex-1 flex justify-center">
            <SpinningLoader size="lg" color="brand" />
          </div>
        ) : (
          <>
            <Paragraph>
              {`For `}
              <strong>${PREMIUM_LISTING_FEE_EXCL_GST.toFixed(2)}</strong>
              {` + GST, you may also publish your listing to Trade Me and homes.co.nz.`}
            </Paragraph>
            {hasAddedBillingMethod ? (
              <div className="flex flex-row items-center gap-x-2 mb-4">
                <HiCheckCircle className="flex-shrink-0 text-green-500 w-6 h-6" />
                <Paragraph disableMargin>
                  You have a credit or debit card saved with Keyhook, we will
                  use the default card for your account.
                </Paragraph>
              </div>
            ) : (
              <div className="flex flex-col gap-y-2 mb-4">
                <Paragraph>
                  Please add a credit or debit card to continue.
                </Paragraph>
                <div onClick={closeModal}>
                  <Button
                    label="Add a new credit/debit card"
                    category="primary"
                    size="base"
                    fillWidth={false}
                    mode="link"
                    linkTo="/account/billing"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  },
};

export default PremiumListingPlatformsPublishModal;
