import {useCallback, useEffect, useMemo, useState} from 'react';

import * as Sentry from '@sentry/react';
import {useQuery, useQueryClient} from 'react-query';

import {TARGET_ENV} from 'globals/app-globals';
import User from 'models/users/User';
import useAuth from 'services/useAuth';

interface CurrentUserFlag {
  value: boolean;
  set: (value: boolean) => Promise<void>;
  isReady: boolean;
}

interface UseCurrentUserFlagHook {
  (key: string): CurrentUserFlag;
}

/**
 * A hook for setting and retrieving an arbitrary flag for the current user
 * that persists between devices and auth sessions.
 */
const useCurrentUserFlag: UseCurrentUserFlagHook = (key) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => `current_user_flag:${key}`, [key]);
  const [isUpdating, setIsUpdating] = useState(false);
  const {userIsLoggedIn, currentUser} = useAuth();

  /**
   * Log a warning in development mode if a flag is attempting to be
   * used in a component where there is no logged in user.
   */
  useEffect(() => {
    if (TARGET_ENV !== 'production') {
      if (!currentUser) {
        console.warn(
          `Current user flag '${key}' is being used in a
          component where there is no logged in user.`,
        );
      }
    }
  }, [currentUser, key]);

  /**
   * Fetches the value for the flag from the backend so that the frontend
   * state of the flag is always consistent with the backend state.
   */
  const {
    data: value,
    isLoading,
    isError,
  } = useQuery(queryKey, async () => {
    if (!userIsLoggedIn) {
      return false;
    }
    try {
      const {userInterfaceFlags} = (await User.find(currentUser.id)).data;
      return userInterfaceFlags[key] ?? false;
    } catch (error) {
      /**
       * Capture error if the flag failed to load and default to false.
       */
      Sentry.withScope((scope) => {
        scope.setTag('action', 'fetch_current_user_flag');
        scope.setTag('flag', key);
        Sentry.captureException(error);
      });
      return false;
    }
  });

  /**
   * Sets the flag state for the given key for the current user.
   */
  const set = useCallback(
    async (value: boolean) => {
      /**
       * Ensure there is a logged in user.
       */
      if (!userIsLoggedIn) {
        throw Error(
          `Unable to set flag ${key} to ${value} for current user
          because there is no logged in user.`,
        );
      }
      /**
       * Update the flag in the backend to reflect the new setting.
       */
      try {
        setIsUpdating(true);
        const user = await User.find(currentUser.id);
        user.data.userInterfaceFlags[key] = value;
        await user.data.save();
        /**
         * Invalidate the query to force it to refetch.
         */
        await queryClient.invalidateQueries(queryKey);
      } catch (error) {
        console.log(`Error setting flag ${key} to ${value} for current user.`);
      } finally {
        setIsUpdating(false);
      }
    },
    [userIsLoggedIn, key, queryClient, queryKey, currentUser],
  );

  return {value, set, isReady: !isUpdating && !isLoading && !isError};
};

export default useCurrentUserFlag;
