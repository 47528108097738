import {useEffect, useCallback, useMemo} from 'react';

import type {Channel as PusherChannel} from 'pusher-js';
import type Pusher from 'pusher-js/types/src/core/pusher';
import {toast} from 'react-toastify';
import {create} from 'zustand';

import {API_URL} from 'globals/app-globals';
import ChatMessage from 'models/ChatMessage';
import Conversation from 'models/Conversation';
import Property from 'models/properties/Property';
import useAuth from 'services/useAuth';

type UserJson = {
  avatar: string | null;
  id: string;
  name: string;
};

type ChatMessageJson = {
  content: string;
  created_at: string;
  id: number | string;
  media: string | null;
  read_markers: Record<string, boolean>;
  recipient_ids: number[];
  user: UserJson;
  user_id: string | number;
};

export interface ChatRoom {
  id: string | number;
  type: 'Property' | string; // TODO: Replace string with the actual room types
  currentChatterIds: number[];
  mostRecentChatMessage: ChatMessage;
}

type NewMessage = {
  message: ChatMessageJson;
};

type ChannelIdentifier = 'PropertyChannel' | 'ConversationChannel';

type ChannelModel = Property | Conversation;

interface ChatChannelConstantValues {
  name: string;
  identifier: ChannelIdentifier;
  model: ChannelModel;
  pusherChannel: PusherChannel;
  isAllowedToChat: boolean;
  initialMostRecentChatMessage?: ChatMessage;
}

type ChatChannelStoreData = Omit<
  ChatChannelConstantValues,
  'name' | 'initialMostRecentChatMessage'
> & {
  messages: ChatMessage[];
  mostRecentChatMessage: ChatMessage;
  hasFetchedMessages: boolean;
  unreadCount: number;
  lastFetchedPage: number;
  isOutOfOlderMessages: boolean;
};

export type ChatChannel = ChatChannelStoreData & {
  fetchMessages: (page: number) => Promise<void>;
  markMessagesAsRead: () => Promise<void>;
};

interface CreateChatChannelFunction {
  (props: ChatChannelConstantValues): void;
}

interface ChatChannelStoreState {
  chatChannelsData: {
    [name: string]: ChatChannelStoreData;
  };
  resetChatChannels: () => void;
  createChatChannel: CreateChatChannelFunction;
  setMessages: (name: string, message: ChatMessage[]) => void;
  setMostRecentChatMessage: (
    name: string,
    mostRecentChatMessage: ChatMessage,
  ) => void;
  setHasFetchedMessages: (name: string, hasFetchedMessages: boolean) => void;
  setUnreadCount: (name: string, unreadCount: number) => void;
  setLastFetchedPage: (name: string, lastFetchedPage: number) => void;
  setIsOutOfOlderMessages: (
    name: string,
    isOutOfOlderMessages: boolean,
  ) => void;
}

interface ChatChannelHookProps {
  pusherClient: Pusher;
  rooms: ChatRoom[];
}

interface ChatChannelHook {
  (props: ChatChannelHookProps): ChatChannel[];
}

export const useChatChannelsStore = create<ChatChannelStoreState>((set) => ({
  chatChannelsData: {},
  resetChatChannels: () => set(() => ({chatChannelsData: {}})),
  createChatChannel: ({name, initialMostRecentChatMessage, ...props}) =>
    set(({chatChannelsData}: ChatChannelStoreState) => ({
      chatChannelsData: {
        ...chatChannelsData,
        [name]: {
          /**
           * Set provided constant values
           */
          ...props,

          /**
           * Set initial mutable values
           */
          messages: [],
          mostRecentChatMessage: initialMostRecentChatMessage,
          hasFetchedMessages: false,
          unreadCount: 0,
          lastFetchedPage: null,
          isOutOfOlderMessages: false,
        },
      },
    })),
  setMessages: (name, messages) =>
    set(({chatChannelsData}) => ({
      chatChannelsData: {
        ...chatChannelsData,
        [name]: {
          ...chatChannelsData[name],
          messages,
        },
      },
    })),
  setMostRecentChatMessage: (name, mostRecentChatMessage) =>
    set(({chatChannelsData}) => ({
      chatChannelsData: {
        ...chatChannelsData,
        [name]: {
          ...chatChannelsData[name],
          mostRecentChatMessage,
        },
      },
    })),
  setHasFetchedMessages: (name, hasFetchedMessages) =>
    set(({chatChannelsData}) => ({
      chatChannelsData: {
        ...chatChannelsData,
        [name]: {
          ...chatChannelsData[name],
          hasFetchedMessages,
        },
      },
    })),
  setUnreadCount: (name, unreadCount) =>
    set(({chatChannelsData}) => ({
      chatChannelsData: {
        ...chatChannelsData,
        [name]: {
          ...chatChannelsData[name],
          unreadCount,
        },
      },
    })),
  setLastFetchedPage: (name, lastFetchedPage) =>
    set(({chatChannelsData}) => ({
      chatChannelsData: {
        ...chatChannelsData,
        [name]: {
          ...chatChannelsData[name],
          lastFetchedPage,
        },
      },
    })),
  setIsOutOfOlderMessages: (name, isOutOfOlderMessages) =>
    set(({chatChannelsData}) => ({
      chatChannelsData: {
        ...chatChannelsData,
        [name]: {
          ...chatChannelsData[name],
          isOutOfOlderMessages,
        },
      },
    })),
}));

export const useChatChannels: ChatChannelHook = ({pusherClient, rooms}) => {
  const {currentUser} = useAuth();

  const chatChannelsData = useChatChannelsStore(
    (state) => state.chatChannelsData,
  );

  const resetChatChannels = useChatChannelsStore(
    (state) => state.resetChatChannels,
  );

  const createChatChannel = useChatChannelsStore(
    (state) => state.createChatChannel,
  );

  const setMessages = useChatChannelsStore((state) => state.setMessages);

  const setMostRecentChatMessage = useChatChannelsStore(
    (state) => state.setMostRecentChatMessage,
  );

  const setHasFetchedMessages = useChatChannelsStore(
    (state) => state.setHasFetchedMessages,
  );

  const setUnreadCount = useChatChannelsStore((state) => state.setUnreadCount);

  const setLastFetchedPage = useChatChannelsStore(
    (state) => state.setLastFetchedPage,
  );

  const setIsOutOfOlderMessages = useChatChannelsStore(
    (state) => state.setIsOutOfOlderMessages,
  );

  /**
   * Indicate that a message in the chat channel has been read by the user.
   */
  const markMessagesAsRead = useCallback(
    async (name: string) => {
      /**
       * Get the chat channel data for the specified channel.
       */
      const {identifier, model, unreadCount} = chatChannelsData[name];

      /**
       * Ignore if all messages have already been read.
       */
      if (unreadCount === 0) {
        return;
      }

      const type = identifier.replace('Channel', '');
      const response = await fetch(
        `${API_URL}/chat_rooms/${model.id}.json?room[type]=${type}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser?.meta.authenticationToken,
            'X-USER-EMAIL': currentUser?.email,
          },
        },
      );
      if (response.ok) {
        /**
         * Reset the unread count.
         */
        setUnreadCount(name, 0);
      } else {
        // TODO: Handle error marking messages as read
      }
    },
    [setUnreadCount, chatChannelsData, currentUser],
  );

  /**
   * Callback to handle receiving a new  in the chat channel.
   */
  const receiveMessage = useCallback(
    (name: string, {message: newMessage}: NewMessage) => {
      console.log(name);
      if (
        newMessage &&
        newMessage.recipient_ids.indexOf(Number(currentUser.id)) !== -1
      ) {
        /**
         * Get the chat channel data for the specified channel.
         */
        const {unreadCount: currentUnreadCount, messages: currentMessages} =
          chatChannelsData[name];

        /**
         * Create the new message from the message data.
         */
        const message = new ChatMessage(newMessage);

        /**
         * Add the new message to the start of the current messages array.
         */
        setMessages(name, [message, ...currentMessages]);

        /**
         * Set the new message as the most recent chat message.
         */
        setMostRecentChatMessage(name, message);

        if (message.userId.toString() !== currentUser.id) {
          /**
           * Increment the unread count.
           */
          setUnreadCount(name, currentUnreadCount + 1);

          /**
           * Notify the user of the new message.
           */
          toast.info('You have a new message!');
        }
      }
    },
    [
      chatChannelsData,
      setMessages,
      setMostRecentChatMessage,
      setUnreadCount,
      currentUser,
    ],
  );

  /**
   * Fetch messages for the given 'page' in a chat channel.
   */
  const fetchMessages = useCallback(
    async (name: string, page: number) => {
      /**
       * Get the chat channel data for the specified channel.
       */
      const {
        identifier,
        model,
        messages: currentMessages,
      } = chatChannelsData[name];

      const type = identifier.replace('Channel', '');
      const fetchedMessages = await ChatMessage.where({
        chatable_type: type,
        chatable_id: model.id,
      })
        .includes(['user'])
        .page(page)
        .per(20)
        .all();

      // TODO: Handle error fetching messages above

      const uniqueMessages = (messagesArray: ChatMessage[]) => {
        const uniqueItems = [];
        for (let i = 0; i < messagesArray.length; i++) {
          const item = messagesArray[i];
          if (
            uniqueItems.findIndex((msg: ChatMessage) => msg.id == item.id) ===
            -1
          ) {
            uniqueItems.push(item);
          }
        }
        return uniqueItems;
      };

      if (fetchedMessages.data.length === 0) {
        setHasFetchedMessages(name, true);
        setLastFetchedPage(name, page);
        setIsOutOfOlderMessages(name, true);
      } else {
        if (currentMessages.length === 0) {
          setMessages(name, fetchedMessages.data);
        } else {
          const allMessages = [...currentMessages, ...fetchedMessages.data];
          const dedupedMessages = uniqueMessages(allMessages);
          setMessages(name, dedupedMessages);
        }
        setHasFetchedMessages(name, true);
        setLastFetchedPage(name, page);
      }
    },
    [
      chatChannelsData,
      setHasFetchedMessages,
      setIsOutOfOlderMessages,
      setLastFetchedPage,
      setMessages,
    ],
  );

  /**
   * Bind the new message event to each of the pusher channels.
   */
  useEffect(() => {
    Object.entries(chatChannelsData).forEach(([name, {pusherChannel}]) => {
      pusherChannel.bind('new_message', (msg: NewMessage) =>
        receiveMessage(name, msg),
      );
    });
  }, [chatChannelsData, receiveMessage]);

  /**
   * Define the returned chat channels by:
   * - converting the store data from an key/value structure to an array
   * - appending the required utility functions
   */
  const chatChannels = useMemo<ChatChannel[]>(() => {
    return Object.entries(chatChannelsData).map(([name, chatChannelData]) => ({
      /**
       * Include the name since it is now an array and can't be identified using
       * the name as a key.
       */
      name,

      /**
       * Append data from the chat channels store.
       */
      ...chatChannelData,

      /**
       * Append utility functions, specifying the name for the specific channel.
       */
      fetchMessages: async (page: number) => await fetchMessages(name, page),
      markMessagesAsRead: async () => await markMessagesAsRead(name),
    }));
  }, [chatChannelsData, fetchMessages, markMessagesAsRead]);

  /**
   * Create chat channels for each of the currently provided rooms.
   */
  useEffect(() => {
    /**
     * Reset the channels in the store to avoid creating duplicate
     * channels for the same room.
     */
    resetChatChannels();
    /**
     * Create the chat channels.
     */
    rooms.forEach((room) => {
      if (room.type === 'Property') {
        const isAllowedToChat =
          room.currentChatterIds.indexOf(Number(currentUser.id)) !== -1;
        const name = `private-property-${room.id}`;
        const pusherChannel = pusherClient.subscribe(name);
        createChatChannel({
          name,
          identifier: 'PropertyChannel',
          model: new Property(room),
          pusherChannel,
          isAllowedToChat,
          initialMostRecentChatMessage: room.mostRecentChatMessage
            ? new ChatMessage(room.mostRecentChatMessage)
            : null,
        });
      } else {
        const name = `private-conversation-${room.id}`;
        const pusherChannel = pusherClient.subscribe(name);
        createChatChannel({
          name,
          identifier: 'ConversationChannel',
          model: new Conversation(room),
          pusherChannel,
          isAllowedToChat: true,
          initialMostRecentChatMessage: room.mostRecentChatMessage
            ? new ChatMessage(room.mostRecentChatMessage)
            : null,
        });
      }
    });
  }, [rooms, resetChatChannels, createChatChannel, currentUser, pusherClient]);

  /**
   * Sort the chat channels by most recent activity before returning.
   */
  const sortedChatChannels = useMemo(
    () =>
      chatChannels.sort((channelA, channelB) => {
        return (
          new Date(
            channelB.mostRecentChatMessage
              ? channelB.mostRecentChatMessage.createdAt
              : '1900-01-01',
          ).getTime() -
          new Date(
            channelA.mostRecentChatMessage
              ? channelA.mostRecentChatMessage.createdAt
              : '1900-01-01',
          ).getTime()
        );
      }),
    [chatChannels],
  );

  return sortedChatChannels;
};
