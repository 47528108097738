import {FunctionComponent, useCallback, useMemo} from 'react';

import {useAutoAnimate} from '@formkit/auto-animate/react';
import clsx from 'clsx';
import {Collapse} from 'react-collapse';
import {HiOutlineMapPin} from 'react-icons/hi2';

import {getPlaceholderValue} from 'constants/listings-search';

import FilterWrapper from '../shared/FilterWrapper';
import {FilterProps} from '../types';
import AdvancedLocationSearchField from './AdvancedLocationSearchField';
import LocationChip from './LocationChip';

type MultipleLocationsFilterProps = FilterProps;

const MultipleLocationsFilter: FunctionComponent<
  MultipleLocationsFilterProps
> = ({form, name, inline = false}) => {
  const hasModifiedDefault = useMemo(
    () => form.values[name] !== undefined,
    [form, name],
  );

  const selectedLocations = useMemo(
    () => (!hasModifiedDefault ? getPlaceholderValue(name) : form.values[name]),
    [hasModifiedDefault, form, name],
  );

  const valueLabel = useMemo(() => {
    if (!hasModifiedDefault) {
      return null;
    }
    return selectedLocations.length === 1
      ? selectedLocations[0]
      : `${selectedLocations.length} locations`;
  }, [hasModifiedDefault, selectedLocations]);

  /**
   * Clear the filter.
   */
  const clearFilter = useCallback(() => {
    /**
     * We can't use setFieldValue here, because Formik will delete
     * the entry from the form values if we are setting it to undefined.
     */
    form.setFormikState((current) => ({
      ...current,
      values: {
        ...current.values,
        [name]: undefined,
      },
    }));
  }, [form, name]);

  /**
   * Removes a selected location from the filter.
   */
  const onRemoveLocation = useCallback(
    (location: string) => {
      const newLocations = form.values[name].filter(
        (l: string) => l !== location,
      );
      if (newLocations.length === 0) {
        clearFilter();
      } else {
        form.setFieldValue(name, newLocations);
      }
    },
    [form, name, clearFilter],
  );

  const onAddLocation = useCallback(
    (location: string) => {
      let newLocations: string[];

      if (!hasModifiedDefault) {
        newLocations = [location];
      } else {
        /**
         * Only add the locatin if it has not already been added.
         */
        newLocations = selectedLocations.includes(location)
          ? selectedLocations
          : [...selectedLocations, location];
      }

      form.setFieldValue(name, newLocations);
    },
    [form, name, selectedLocations, hasModifiedDefault],
  );

  return (
    <FilterWrapper
      inline={inline}
      label="Location"
      icon={HiOutlineMapPin}
      valueLabel={valueLabel}
      onClearFilter={clearFilter}>
      <div className={clsx('flex-1 flex flex-col items-start')}>
        <div className="w-full max-w-lg">
          <AdvancedLocationSearchField onSelectLocation={onAddLocation} />
        </div>
        <Collapse isOpened={hasModifiedDefault}>
          <div
            className={clsx(
              'w-full flex-1 pt-4',
              'flex flex-row flex-wrap',
              'gap-x-4 gap-y-2 min-h-12',
              'items-center',
            )}>
            {selectedLocations.map((location: string) => (
              <LocationChip
                key={location}
                label={location}
                onRemove={() => {
                  onRemoveLocation(location);
                }}
              />
            ))}
          </div>
        </Collapse>
      </div>
    </FilterWrapper>
  );
};

export default MultipleLocationsFilter;
