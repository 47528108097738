import {ReactNode} from 'react';
const TenancyClause = ({
  title,
  clause,
  icon,
}: {
  title: ReactNode;
  clause: string;
  icon?: any;
}) => {
  return (
    <div className="flex flex-1 items-center py-3 pr-2">
      {icon && <img src={icon} className="w-8 h-8 text-gray-200 mr-2" />}
      <label>
        <h4>{title}</h4>
        <p className="text-sm text-base-content text-opacity-60">{clause}</p>
      </label>
    </div>
  );
};

export default TenancyClause;
