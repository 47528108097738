import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import PageWrapper from 'components/PageWrapper';
import {ResultMessage, SpinningLoader} from 'components_sb/feedback';
import Listing from 'models/listings/Listing';
import {usePageVisit} from 'utilities/hooks';

import PrivateViewingForm from './shared/PrivateViewingForm';

const NewPrivateViewingPage = () => {
  usePageVisit('NewPrivateViewing');

  /**
   * Get the public listing ID from the URL.
   */
  const {listingId: listingPublicId} = useParams();

  /**
   * We need the private ID of the listing to create a private viewing, and
   * we only have the public ID from the URL. So we need to fetch the listing
   * first to obtain the private ID to then provide to the form.
   */
  const {
    data: listing,
    isLoading: isLoadingListing,
    isError: errorLoadingListing,
    isSuccess: successfullyLoadedListing,
  } = useQuery(
    ['listing', listingPublicId],
    async () => (await Listing.find(listingPublicId)).data,
  );

  return (
    <PageWrapper title="Schedule Private Viewing">
      {isLoadingListing && (
        <div className="flex-1 flex items-center justify-center">
          <SpinningLoader size="base" color="brand" />
        </div>
      )}
      {errorLoadingListing && (
        <ResultMessage type="error">
          Sorry, there was an issue loading the details for this private
          viewing.
        </ResultMessage>
      )}
      {successfullyLoadedListing && (
        <PrivateViewingForm
          mode="new"
          listingPublicId={listingPublicId}
          listingPrivateId={listing.id}
        />
      )}
    </PageWrapper>
  );
};

export default NewPrivateViewingPage;
