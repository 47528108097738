import {
  FunctionComponent,
  ReactElement,
  ReactFragment,
  createElement,
  isValidElement,
} from 'react';

export interface PseudoRowSeparatorProps {
  id: string;
  title: string;
}

export type PseudoRowSeparatorElement = ReactElement<PseudoRowSeparatorProps>;

export type PseudoRowSeparatorInputElement =
  | PseudoRowSeparatorElement
  | ReactFragment;

export type PseudoRowSeparatorComponent =
  FunctionComponent<PseudoRowSeparatorProps>;

const PseudoRowSeparator: PseudoRowSeparatorComponent = () => <></>;

export default PseudoRowSeparator;

/**
 * Input element type guard.
 */
export const elementIsRowSeparatorElement = (
  element: unknown,
): element is PseudoRowSeparatorElement => {
  return (
    isValidElement(element) &&
    element.type === createElement(PseudoRowSeparator).type
  );
};
