import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';

@Model()
class ListingPhoto extends ApplicationRecord {
  static jsonapiType = 'listing_photos';

  @Attr() orderIndex: number;

  @Attr() photo: string;

  @Attr() listingId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('listings') listing: Listing;
}

export default ListingPhoto;
