import {useState} from 'react';

import {useQuery} from 'react-query';
import {useNavigate} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import ChatMessage from 'models/ChatMessage';
import Conversation from 'models/Conversation';
import {usePusherService} from 'services/PusherService';
import useAuth from 'services/useAuth';
import {usePageVisit, useTitle} from 'utilities/hooks';

const NewChatPage = () => {
  useTitle('New Conversation');
  usePageVisit('NewChatPage');

  const navigate = useNavigate();

  const {fetchChannels} = usePusherService();

  const [selectedContact, setSelectedContact] = useState(null);
  const [message, setMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const {activeAccountRole} = useLocalUserSettings();
  const {currentUser} = useAuth();
  const {data, isLoading} = useQuery(
    `${activeAccountRole}-contacts`,
    async () => {
      const response = await fetch(API_URL + '/contacts.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
          'X-ACCOUNT-TYPE': activeAccountRole,
        },
      });

      const json = await response.json();
      return json.contacts;
    },
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsSaving(true);
    const convo = new Conversation({receiverId: selectedContact.id});
    const msg = new ChatMessage({content: message});
    convo.chat_messages = [msg] as any;

    const result = await convo.save({with: 'chatMessages'});
    if (result) {
      setMessage('');
      await fetchChannels();
      setIsSaving(false);
      const key = 'Conversation-' + convo.id.toString();
      navigate(`/chat/${key}`);
    } else {
      console.log(convo.errors);
    }
  };

  if (isLoading) {
    return (
      <PageWrapper title="New Conversation">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="New Conversation">
        <Card title="New Conversation" className="mt-2">
          {data.length === 0 ? (
            <>
              <Paragraph>You do not have any contacts on Keyhook.</Paragraph>
              <Paragraph>
                {activeAccountRole === 'Landlord' &&
                  'Once your tenants are on Keyhook, you can choose to chat with them from here.'}
                {activeAccountRole === 'Renter' &&
                  'Once you are part of a tenancy, you will be able to message your landlord or fellow tenants from here.'}
              </Paragraph>
            </>
          ) : (
            <Paragraph>
              Select a contact from below to start a new conversation with them.
            </Paragraph>
          )}

          <strong className="block font-semibold text-xl mt-2">Contacts</strong>
          {data.map((contact: any) => (
            <div
              className={`flex justify-start items-center py-2 border-b-2 border-gray-100 cursor-pointer ${
                selectedContact &&
                selectedContact.id === contact.id &&
                'bg-gray-100'
              }`}
              onClick={() => setSelectedContact(contact)}
              key={contact.id}>
              <UserAvatar user={contact} size="10" />
              <strong
                className={`block ml-2 ${
                  selectedContact &&
                  selectedContact.id === contact.id &&
                  'text-primary'
                }`}>
                {contact.name}
              </strong>
            </div>
          ))}

          <form onSubmit={handleSubmit} className="mt-2">
            <strong className="block font-semibold text-xl mt-2 mb-4">
              Message
            </strong>
            <textarea
              className="textarea textarea-bordered w-full"
              placeholder="Type your message"
              value={message}
              onChange={({target}) => setMessage(target.value)}
            />
            <button
              className="btn btn-neutral btn-block mt-4"
              disabled={isSaving || !selectedContact || message.length === 0}
              type="submit">
              Send
            </button>
          </form>
        </Card>
      </PageWrapper>
    );
  }
};

export default NewChatPage;
