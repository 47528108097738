import clsx from 'clsx';

/**
 * Ensures that at least 'vertical' or 'horizontal' are provided
 * as props, and that they are not provided at the same time.
 */
type DirectionProps =
  | {
      vertical: boolean;
      horizontal?: never;
    }
  | {
      vertical?: never;
      horizontal: boolean;
    };

interface FormFieldsContainer {
  (props: DirectionProps & {children: JSX.Element[]}): JSX.Element;
}

/**
 * A container for any type of form field to ensure a consistent
 * layout of fields within forms.
 */
const FormFieldsContainer: FormFieldsContainer = ({
  children,
  vertical,
  horizontal,
}) => {
  /**
   * Ensure that false is not directly passed to
   * either 'vertical' or 'horizontal' props.
   */
  if (
    [vertical, horizontal].some(
      (direction) => typeof direction === 'boolean' && !direction,
    )
  ) {
    throw new Error(
      'FormFieldsContainer must not be provided false values for direction props.',
    );
  }
  return (
    <div
      className={clsx(
        'flex gap-x-4 gap-y-2',
        vertical && 'flex-col',
        horizontal && 'flex-row',
      )}>
      {children}
    </div>
  );
};

export default FormFieldsContainer;
