import React, {useMemo} from 'react';

import {ChevronRightIcon} from '@heroicons/react/outline';
import clsx from 'clsx';
import {Link} from 'react-router-dom';

import {Paragraph, Title} from 'components_sb/typography';

interface IconBaseProps extends React.SVGAttributes<SVGElement> {
  children?: React.ReactNode;
  size?: string | number;
  color?: string;
  title?: string;
}
type IconType = (props: IconBaseProps) => JSX.Element;

const MobileListItem = ({
  link,
  onClick,
  icon: Icon,
  title,
  subtitle,
  showArrowIcon = true,
}: {
  link?: string;
  onClick?: () => void;
  icon?: IconType;
  title: string;
  subtitle: React.ReactNode;
  showArrowIcon?: boolean;
}) => {
  const content = useMemo(
    () => (
      <>
        <div className="flex justify-start gap-2 items-center text-left">
          {Icon && <Icon className="w-6 h-6 text-brand-500" />}
          <div>
            <Title size="xs" level="h3" color="brand">
              {title}
            </Title>
            <Paragraph size="sm" secondary>
              {subtitle}
            </Paragraph>
          </div>
        </div>

        {showArrowIcon && (
          <ChevronRightIcon className="w-6 h-6 text-brand-100" />
        )}
      </>
    ),
    [showArrowIcon, Icon, subtitle, title],
  );

  const innerClassName = useMemo(
    () =>
      clsx(
        'flex justify-between items-center py-4',
        'border-b-2 border-brand-50 last:border-b-0',
        'bg-white',
      ),
    [],
  );

  return (
    <li>
      {onClick ? (
        <button className={innerClassName} onClick={onClick}>
          {content}
        </button>
      ) : (
        <Link className={innerClassName} to={link}>
          {content}
        </Link>
      )}
    </li>
  );
};

export default MobileListItem;
