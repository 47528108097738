import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import NewTenancyInviteForm from 'components/tenancy_invites/NewTenancyInviteForm';
import {Card} from 'components_sb/layout';
import Tenancy from 'models/properties/Tenancy';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const NewTenancyInvitePage = () => {
  usePageVisit('NewTenancyInvitePage');
  useTitle('Invite Tenant');

  const {id: tenancyId} = useParams();

  const {data, isLoading, error} = useQuery(
    `tenancy-${tenancyId}-invite-tenants`,
    async () => {
      const t = await Tenancy.includes('property')
        .select({
          tenancies: ['id', 'is_new'],
          properties: ['street_address'],
        })
        .find(tenancyId);

      return t.data;
    },
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Invite Tenant" backEnabled>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Invite Tenant" backEnabled>
        <Card title="Invite Tenant">
          <NewTenancyInviteForm tenancy={data} />
        </Card>
      </PageWrapper>
    );
  }
};

export default NewTenancyInvitePage;
