import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {InlineError} from 'components_sb/feedback';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import {usePageVisit, useTitle} from 'utilities/hooks';

const PropertyTransferConfirmationPage = () => {
  useTitle('Property Transfer Confirmation');
  usePageVisit('PropertyTransferConfirmationPage');

  const {confirmationToken} = useParams();

  const {data, isLoading} = useQuery(
    `property-transfer-confirmation-${confirmationToken}`,
    async () => {
      const response = await fetch(
        `${API_URL}/property_transfers/${confirmationToken}/confirm.json`,
      );

      if (response.ok) {
        return response.json();
      } else {
        return 'error';
      }
    },
    {
      enabled: !!confirmationToken,
    },
  );

  if (!confirmationToken) {
    return (
      <PageWrapper title="Property Transfer">
        <Card title="Property Transfer">
          <Paragraph>
            We could not confirm this property transfer as no confirmation token
            was present. Please make sure you click the button in the email sent
            to you.
          </Paragraph>
        </Card>
      </PageWrapper>
    );
  }

  if (isLoading) {
    return (
      <PageWrapper title="Property Transfer">
        <LoadingView />
      </PageWrapper>
    );
  } else if (data === 'error') {
    return (
      <PageWrapper title="Property Transfer">
        <Card title="Property Transfer">
          <InlineError error="Sorry, there was an error confirming this property transfer." />
        </Card>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Property Transfer">
        <Card title="Property Transfer">
          <Paragraph>
            You have successfully confirmed the transfer of {data.data.address}{' '}
            to {data.data.to_user}, they will need to accept this transfer
            before the property is actually transferred to their account. We
            will handle notifying them.
          </Paragraph>
          <Paragraph>You can now close this window.</Paragraph>
        </Card>
      </PageWrapper>
    );
  }
};

export default PropertyTransferConfirmationPage;
