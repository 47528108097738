import {ReactNode, useEffect, useMemo, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {useLocation} from 'react-router';

import Header from 'components/navbar/Header';
import BottomTabBar from 'components_sb/navigation/BottomTabBar/BottomTabBar';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useScrollPosition from 'hooks/useScrollPosition';
import {Action} from 'types/actions';

import LoadingView from './common/LoadingView';

const landlordTabRoutes = new Set(['/', '/chat', '/financials', '/settings']);
const renterTabRoutes = new Set(['/', '/chat', '/listings', '/settings']);

const allTabRoutes = new Set([...landlordTabRoutes, ...renterTabRoutes]);

interface PageWrapper {
  (props: {
    title: string;
    loading?: boolean;
    actions?: Action[];
    hideHeader?: boolean;
    children: ReactNode;
    showButtonsAsDropDown?: boolean;
    dropdownTitle?: string;
    backEnabled?: boolean;
    disableMaxWidth?: boolean;
    disablePadding?: boolean | {banner: boolean; content: boolean};
    disableVerticalMargin?: boolean;
    banner?: {
      container: (props: {children: ReactNode}) => JSX.Element;
      content: ReactNode;
    };
  }): JSX.Element;
}

const PageWrapper: PageWrapper = ({
  title,
  loading = false,
  actions,
  hideHeader = false,
  children,
  showButtonsAsDropDown = false,
  dropdownTitle,
  backEnabled = false,
  disableMaxWidth = false,
  disablePadding = false,
  disableVerticalMargin = false,
  banner = null,
}) => {
  useEffect(() => {
    document.title = `${title} - Keyhook`;
    return () => {
      document.title = 'Keyhook';
    };
  }, [title]);

  const [hasLoadedScrollPosition, setHasLoadedScrollPosition] = useState(false);
  const {activeAccountRole} = useLocalUserSettings();

  const {pathname} = useLocation();
  const scrollPosition = useScrollPosition('root-scroll-container');

  useEffect(() => {
    const storageValue = sessionStorage.getItem(pathname);
    const position = storageValue ? Number(storageValue) : null;

    if (position && !hasLoadedScrollPosition) {
      const rootScrollContainer = window.document.getElementById(
        'root-scroll-container',
      );

      if (rootScrollContainer) {
        const scrollContainerContent = rootScrollContainer.firstElementChild;
        scrollContainerContent.scrollTop = position;
        setHasLoadedScrollPosition(true);
      }
    }
    return () => {
      sessionStorage.setItem(pathname, scrollPosition.toString());
    };
  }, [pathname, scrollPosition, hasLoadedScrollPosition]);

  if (Capacitor.isNativePlatform() && !allTabRoutes.has(pathname)) {
    backEnabled = true;
  }

  const BannerContainer = banner ? banner.container : null;

  const padding = useMemo(() => {
    const padded = {x: 'px-6 lg:px-8', y: 'py-6 lg:py-8'};
    const unpadded = {x: '', y: ''};
    return typeof disablePadding === 'boolean'
      ? {
          banner: disablePadding ? unpadded : padded,
          content: disablePadding ? unpadded : padded,
        }
      : {
          banner: disablePadding.banner ? unpadded : padded,
          content: disablePadding.content ? unpadded : padded,
        };
  }, [disablePadding]);

  return (
    <div className="flex-1 w-full flex flex-col">
      {!hideHeader && (
        <Header
          title={title}
          actions={actions}
          showButtonsAsDropDown={showButtonsAsDropDown}
          dropdownTitle={dropdownTitle}
          backEnabled={backEnabled}
        />
      )}

      {loading ? (
        <div className="flex-1 flex items-center justify-center">
          <LoadingView />
        </div>
      ) : (
        <>
          <main className="w-full flex-1 flex flex-col">
            {banner && (
              <BannerContainer>
                <div
                  className={clsx(
                    'w-full max-w-7xl',
                    'mx-auto',
                    padding.banner.x,
                    padding.banner.y,
                  )}>
                  {banner.content}
                </div>
              </BannerContainer>
            )}
            <div
              className={clsx(
                'w-full',
                !disableMaxWidth && 'max-w-7xl',
                'flex-1 flex flex-col',
                'mx-auto',
                !disableVerticalMargin && 'mb-8 lg:mb-12',
                padding.content.x,
                padding.content.y,
              )}>
              {children}
            </div>
          </main>

          {/* Mobile tabs */}
          {Capacitor.isNativePlatform() && (
            <>
              {activeAccountRole === 'Landlord' &&
                landlordTabRoutes.has(pathname) && (
                  <>
                    {/* This is extra padding for the bottom tab bar so that nothing sits behind it in the scrollview */}
                    <div className="h-20"></div>
                    <BottomTabBar accountType="Landlord" />
                  </>
                )}

              {activeAccountRole === 'Renter' &&
                renterTabRoutes.has(pathname) && (
                  <>
                    {/* This is extra padding for the bottom tab bar so that nothing sits behind it in the scrollview */}
                    <div className="h-20"></div>
                    <BottomTabBar accountType="Renter" />
                  </>
                )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PageWrapper;
