import {useCallback, useEffect, useMemo, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {BiBath} from '@react-icons/all-files/bi/BiBath';
import {BiBed} from '@react-icons/all-files/bi/BiBed';
import {BiCar} from '@react-icons/all-files/bi/BiCar';
import {
  type FormikHelpers,
  FormikConfig,
  FormikValues,
  useFormik,
} from 'formik';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import * as Yup from 'yup';

import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import {Button} from 'components_sb/buttons';
import {InlineError} from 'components_sb/feedback';
import {API_URL} from 'globals/app-globals';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {LeaseDocumentType, TenancyStatus} from 'models/properties/Tenancy';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';
import {toCurrency} from 'utilities/StringHelpers';

import StepContainer from '../common/StepContainer';

type FormValues = {
  landlordSignature: string;
};

const PreviewLeaseStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartPreviewLeaseStep,
      {
        propertyId: property.id,
        tenancyId: tenancy?.id,
      },
    );
  }, [property.id, tenancy?.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const [leaseModalVisible, setLeaseModalVisible] = useState(false);
  const [bondModalVisible, setBondModalVisible] = useState(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the tenancy.
       */
      tenancy.assignAttributes(formValues);

      /**
       * Save the changes to the tenancy.
       */
      if (!(await saveResource(tenancy))) {
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = 'new_preview_lease';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompletePreviewLeaseStep,
        {
          propertyId: property.id,
          tenancyId: tenancy.id,
        },
      );

      setSubmitting(false);
      actions.setSubmitting(false);
    },
    [property, queryClient, tenancy],
  );

  /**
   * We don't want to show the preview for the bond if the user
   * chose the boarding house tenancy lease template.
   */
  const bondPreviewDisabled = useMemo(
    () =>
      tenancy.leaseDocumentType ===
      LeaseDocumentType.TenancyServicesBoardingHouse,
    [tenancy],
  );

  const leaseDocumentUrl = `${API_URL}/tenancies/${tenancy.id}/lease_preview.pdf`;
  const bondDocumentUrl = `${API_URL}/tenancies/${tenancy.id}/bond_preview.pdf`;

  const showLease = () => {
    if (Capacitor.isNativePlatform()) {
      navigate(`/document-previews?url=${leaseDocumentUrl}`);
    } else {
      setLeaseModalVisible(true);
      TrackingService.trackEvent(TrackingService.Event.PreviewLease);
    }
  };

  const showBond = useCallback(() => {
    if (bondPreviewDisabled) {
      return;
    }

    if (Capacitor.isNativePlatform()) {
      navigate(`/document-previews?url=${bondDocumentUrl}`);
    } else {
      setBondModalVisible(true);
      TrackingService.trackEvent(TrackingService.Event.PreviewBond);
    }
  }, [bondPreviewDisabled, navigate, bondDocumentUrl]);

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        landlordSignature: tenancy.landlordSignature || '',
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        landlordSignature: Yup.string().required().label('Signature').min(10),
      }),
    }),
    [handleSubmit, tenancy],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Handle saving a new signature.
   */
  const onSaveSignature = useCallback(
    (signature: string) => {
      form.setFieldValue('landlordSignature', signature);
    },
    [form],
  );

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      title="Review and sign your lease and bond documents"
      subtitle="Please make sure all the details are correct">
      <div
        className="w-full nc-PropertyCardH group relative bg-white border border-neutral-100 rounded-3xl overflow-hidden shadow-xl h-full"
        data-nc-id="PropertyCardH">
        <div className="h-full w-full flex flex-row items-center">
          <div className="hidden lg:block flex-shrink-0 p-3 w-32 h-32 xl:w-64 xl:h-64">
            <img
              src={property.mainImage}
              className="w-auto h-full rounded-2xl overflow-hidden object-cover"
            />
          </div>

          <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
            <div className="space-y-4 w-full">
              <div className="flex items-center">
                <h2 className="text-xl font-medium capitalize">
                  <span className="line-clamp-2">{property.streetAddress}</span>
                </h2>
              </div>
              <p className="text-sm text-secondary !mt-[-4px]">
                {property.suburb}, {property.city}
              </p>

              <div className="flex justify-start gap-4">
                <div className="flex items-center space-x-2">
                  <span className="inline-block">
                    <BiBed className="w-5 h-5" />
                  </span>
                  <span className="text-xs text-neutral">
                    {property.bedrooms}{' '}
                    <span className="hidden sm:inline-block">beds</span>
                  </span>
                </div>

                <div className="flex items-center space-x-2">
                  <span className="inline-block">
                    <BiBath className="w-5 h-5" />
                  </span>
                  <span className="text-xs text-neutral">
                    {property.bathrooms}{' '}
                    <span className="hidden sm:inline-block">baths</span>
                  </span>
                </div>

                {property.garages > 0 && (
                  <div className="flex items-center space-x-2">
                    <span className="inline-block">
                      <BiCar className="w-5 h-5" />
                    </span>
                    <span className="text-xs text-neutral">
                      {property.garages}{' '}
                      <span className="hidden sm:inline-block">garages</span>
                    </span>
                  </div>
                )}
              </div>

              <div className="w-14 border-b border-neutral-100"></div>
              <div className="flex flex-row flex-wrap w-full justify-start items-end gap-x-4 gap-y-2">
                <span className="flex-1 flex items-center justify-center px-3 py-2 border border-accent rounded leading-none text-base font-medium text-accent">
                  {toCurrency(tenancy.totalRent)} / {tenancy.rentalPeriod}
                </span>

                <span className="flex-1 flex items-center justify-center px-3 py-2 border border-accent rounded leading-none text-base font-medium text-accent">
                  {toCurrency(tenancy.bond)} Bond
                </span>
              </div>

              <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 w-full">
                <Button
                  category="secondary"
                  size="sm"
                  format="standard"
                  mode="manual"
                  label="Preview Lease"
                  onClick={showLease}
                />
                {!bondPreviewDisabled && (
                  <Button
                    category="secondary"
                    size="sm"
                    format="standard"
                    mode="manual"
                    label="Preview Bond"
                    onClick={showBond}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!Capacitor.isNativePlatform() && (
        <DocumentPreviewModal
          isVisible={leaseModalVisible}
          setIsVisible={setLeaseModalVisible}
          documentUrl={leaseDocumentUrl}
          title="Lease Preview"
        />
      )}

      {!Capacitor.isNativePlatform() && (
        <DocumentPreviewModal
          isVisible={bondModalVisible}
          setIsVisible={setBondModalVisible}
          documentUrl={bondDocumentUrl}
          title="Bond Preview"
        />
      )}

      <div className="flex flex-col w-full">
        <SignatureModalInput
          mode="manual"
          name="landlordSignature"
          labelProps={{
            title: 'Signature',
            description: [
              'This signature will be used on your lease and bond documents.',
              tenancy.status === TenancyStatus.Pending &&
                'Because you are currently editing this tenancy, we require you to sign again to confirm any changes',
            ],
          }}
          signature={form.values.landlordSignature}
          onSave={onSaveSignature}
        />
        <InlineError error={form.errors.landlordSignature} />
      </div>
    </StepContainer>
  );
};

export default PreviewLeaseStep;
