import {HttpError} from 'components_sb/miscellaneous';

const ServerErrorPage = (): JSX.Element => (
  <HttpError
    code={500}
    title="Server Error"
    description="Something went wrong when generating this page."
  />
);

export default ServerErrorPage;
