import React, {HTMLProps, useState} from 'react';

import {useFormik, type FormikProps} from 'formik';
import _ from 'lodash';

import catIcon from 'assets/img/icons/pet_types/cat.png';
import largeDogIcon from 'assets/img/icons/pet_types/large_dog.png';
import negotiableIcon from 'assets/img/icons/pet_types/negotiable.png';
import smallDogIcon from 'assets/img/icons/pet_types/small_dog.png';
import {GridSelect, GridSelectOption} from 'components_sb/input';
import {FieldLabelProps} from 'components_sb/typography/FieldLabel/FieldLabel';

const PET_TYPES: GridSelectOption[] = [
  {id: 'cat', label: 'Cat', icon: catIcon},
  {id: 'small_dog', label: 'Small Dog', icon: smallDogIcon},
  {id: 'large_dog', label: 'Large Dog', icon: largeDogIcon},
  {id: 'negotiable', label: 'Negotiable', icon: negotiableIcon},
];

interface PetTypeFieldProps {
  name: string;
  form: ReturnType<typeof useFormik> | FormikProps<any>;
  labelProps?: FieldLabelProps;
  size?: 'base' | 'sm' | 'lg';
  allowCustom?: boolean; // TODO
}

const PetTypeField = ({
  name,
  form,
  labelProps,
  size = 'base',
  allowCustom,
}: PetTypeFieldProps) => {
  // const {value} = form.getFieldProps(name);
  // const {error} = form.getFieldMeta(name);

  // const [petTypes, setPetTypes] = useState(
  //   _.union(value, ['cat', 'small_dog', 'large_dog', 'caged_bird']),
  // );
  // const [isEditing, setIsEditing] = useState(false);
  // const [customText, setCustomText] = useState('');

  // const options = petTypes.map((type) => {
  //   return {label: titleize(removeUnderscores(type)), value: type};
  // });

  // const defaultValue = (value as string[]).map((str) => {
  //   return {label: titleize(removeUnderscores(str)), value: str};
  // });

  // const saveCustom = () => {
  //   if (customText.length > 0) {
  //     const formatted = customText.toLowerCase().replaceAll(' ', '_');
  //     form.setFieldValue(name, [...form.values[name], formatted]);
  //     setPetTypes([...petTypes, formatted]);
  //   }
  //   setIsEditing(false);
  // };

  return (
    <GridSelect
      name={name}
      mode="formik"
      form={form}
      labelProps={labelProps}
      multiple
      size={size}
      minColumnWidth={200}
      options={PET_TYPES}
      allowCustom={allowCustom} // TODO
    />
  );
};

// const PetTypeField = ({name, form}: PetTypeFieldProps) => {
//   const {value} = form.getFieldProps(name);
//   const {error} = form.getFieldMeta(name);

//   const [petTypes, setPetTypes] = useState(
//     _.union(value, ['cat', 'small_dog', 'large_dog', 'caged_bird']),
//   );
//   const [isEditing, setIsEditing] = useState(false);
//   const [customText, setCustomText] = useState('');

//   const options = petTypes.map((type) => {
//     return {label: titleize(removeUnderscores(type)), value: type};
//   });

//   const defaultValue = (value as string[]).map((str) => {
//     return {label: titleize(removeUnderscores(str)), value: str};
//   });

//   const saveCustom = () => {
//     if (customText.length > 0) {
//       const formatted = customText.toLowerCase().replaceAll(' ', '_');
//       form.setFieldValue(name, [...form.values[name], formatted]);
//       setPetTypes([...petTypes, formatted]);
//     }
//     setIsEditing(false);
//   };

//   return (
//     <div>
//       <div className="flex justify-start">
//         <Select
//           isMulti={true}
//           options={options}
//           defaultValue={defaultValue}
//           value={defaultValue}
//           onChange={(vals: Option[]) => {
//             form.setFieldValue(
//               name,
//               vals.map((val) => val.value),
//             );
//           }}
//           placeholder="Select what pet types are allowed"
//         />
//         {!isEditing && (
//           <button
//             type="button"
//             className="btn btn-neutral btn-sm h-[38px] ml-2"
//             onClick={() => setIsEditing(true)}>
//             Add Custom
//           </button>
//         )}
//       </div>

//       {isEditing && (
//         <div className="mt-2 form-control">
//           <div className="input-group">
//             <input
//               type="text"
//               className="input input-bordered w-full"
//               placeholder="Add a pet type or something more specific"
//               onChange={(e) => setCustomText(e.target.value)}
//             />

//             <button
//               type="button"
//               className="btn btn-success"
//               onClick={saveCustom}>
//               Save
//             </button>
//           </div>
//         </div>
//       )}

//       <InlineError error={form.errors[name]} />
//     </div>
//   );
// };

export default PetTypeField;
