import {
  useCallback,
  useImperativeHandle,
  useEffect,
  useRef,
  useState,
} from 'react';

import SignatureCanvas from 'react-signature-canvas';

import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import useWindowSize from 'hooks/useWindowResize';

const SignatureModal: ModalDefinition = {
  title: 'Add signature',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'clear',
        label: 'Clear Signature',
        handle: 'onClearSignature',
        closeOnSuccess: false,
      },
      {
        id: 'save',
        label: 'Save Signature',
        handle: 'onSaveSignature',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const [signatureResult, setSignatureResult] = useState('');
    const sigCanvas = useRef<SignatureCanvas>(null);

    const widthRatio = useTailwindBreakpoint('md') ? 3 : 2;
    const screenSize = useWindowSize();

    /**
     * Resizes the signature canvas to fit within the parent container.
     */
    const resizeCanvas = useCallback(() => {
      if (sigCanvas.current) {
        const canvas = sigCanvas.current.getCanvas();
        const parent = canvas.parentElement;

        canvas.width = parent.clientWidth;
        canvas.height = parent.clientWidth / widthRatio;
      }
    }, [widthRatio]);

    // This is a workaround for safari being shit.
    const [refVisible, setRefVisible] = useState(false);
    useEffect(() => {
      resizeCanvas();
    }, [refVisible, resizeCanvas]);

    /**
     * Trigger the canvas resize when the screen width changes.
     */
    useEffect(() => {
      resizeCanvas();
      if (sigCanvas.current) {
        sigCanvas.current.clear();
      }
    }, [screenSize.width, resizeCanvas]);

    /**
     * Update the signature in state when there is a change.
     */
    const setSignatureOnChange = useCallback(() => {
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      setSignatureResult(dataURL);
    }, []);

    /**
     * Clear the entered signature.
     */
    const onClearSignature = useCallback(() => {
      sigCanvas.current.clear();
      setSignatureResult('');
    }, [setSignatureResult, sigCanvas]);

    /**
     * Return the signature result to the function where
     * the modal was opened.
     */
    const onSaveSignature = useCallback(
      () => signatureResult,
      [signatureResult],
    );

    useImperativeHandle(ref, () => ({
      onClearSignature,
      onSaveSignature,
    }));

    return (
      <>
        <Paragraph>Draw your signature in the box below.</Paragraph>
        <div className="canvasWrapper w-full">
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              className: 'border-3 border-brand-100 rounded-lg',
            }}
            ref={(element) => {
              sigCanvas.current = element;
              setRefVisible(!!element);
            }}
            onEnd={setSignatureOnChange}
          />
        </div>
        {/* <button
          type="button"
          className="btn btn-secondary flex-1"
          onClick={clearInput}>
          Clear
        </button>
        <button
          type="button"
          className="btn btn-primary flex-1"
          onClick={saveInput}
          disabled={!isSignatureValid}>
          Save
        </button> */}
      </>
    );
  },
};

export default SignatureModal;
