import {useCallback} from 'react';

import {useField} from 'formik';
import {HiOutlinePencilAlt} from 'react-icons/hi';

import SignatureModal from 'components/modals/SignatureModal';
import {Button} from 'components_sb/buttons';
import {type FieldError, InlineError} from 'components_sb/feedback';
import {Modal} from 'components_sb/layout';
import {FieldLabel} from 'components_sb/typography';
import {FieldLabelProps} from 'components_sb/typography/FieldLabel/FieldLabel';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';

const {useModal} = Modal.Imperative;

interface BaseProps {
  mode: 'manual' | 'formik';
  name: string;
  labelProps?: FieldLabelProps;
}

type ConditionalModeProps =
  /**
   * Types when mode is 'manual'
   */
  | {
      mode: 'manual';
      signature: string;
      onSave: (signature: string) => void;
      error?: FieldError;
    }
  /**
   * Types when mode is 'formik'
   */
  | {
      mode: 'formik';
      signature?: never;
      onSave?: never;
      error?: never;
    };

/**
 * Properties that wrapper components must provide to the base component.
 */
interface WrapperOutput {
  signature: string;
  onSave: (signature: string) => void;
  error?: FieldError;
}

/**
 * The minimum properties that wrapper components must receive from
 * the base component.
 */
interface BaseWrapperInput {
  children: (props: WrapperOutput) => JSX.Element;
}

/**
 * A component that wraps the based component to provide functionality
 * based on particular configuration.
 */
interface WrapperComponent {
  (props: BaseWrapperInput): JSX.Element;
}

/**
 * Wraps the base component to enable manual control.
 */
const ManualWrapper = ({
  children,
  ...props
}: BaseWrapperInput & WrapperOutput) => {
  /**
   * For the manual wrapper, the input must include the base properties
   * but also include the expected output for direct passing
   */
  return children({...props});
};

/**
 * Wraps the base component to enable support for
 * integration with the current Formik context.
 */
const FormikWrapper = ({
  name,
  children,
  ...props
}: BaseWrapperInput & {name: string}) => {
  const [fieldProps, meta] = useField({name});
  const {value, onChange} = fieldProps;
  const {error} = meta;
  /**
   * We need to programatically create a form change event
   * here to simulate a native input field for compatibility
   * with Formik.
   */
  const onSave = useCallback(
    (signature: string) => {
      onChange({
        target: {
          name,
          value: signature,
        },
      });
    },
    [onChange, name],
  );

  return children({
    signature: value,
    onSave: onSave,
    error,
    ...props,
  });
};

/**
 * The inner main input component for handling the signature.
 */
interface InnerInputComponent {
  (
    props: WrapperOutput & {
      name: string;
      labelProps?: FieldLabelProps;
    },
  ): JSX.Element;
}

/**
 * The inner main input component for handling the signature.
 */
const InnerInputComponent: InnerInputComponent = ({
  name,
  signature,
  labelProps,
  onSave,
  error = null,
}) => {
  const {currentUser, userIsLoggedIn} = useAuth();
  const openModal = useModal();

  /**
   * Obtains the signature input via a modal.
   */
  const obtainSignature = useCallback(async () => {
    const signatureResult = await openModal(SignatureModal);
    if (signatureResult) {
      onSave(signatureResult);
      TrackingService.trackEvent(TrackingService.Event.SaveSignature, {
        field: name,
      });
    }
  }, [openModal, onSave, name]);

  return (
    <div className="w-full flex-col space-y-2">
      {labelProps && <FieldLabel {...labelProps} />}
      {signature && (
        <img className="img-fluid border mb-2 bg-white p-2" src={signature} />
      )}
      {userIsLoggedIn && !!signature && (
        <div className="blockquote-footer mb-2">{currentUser.name}</div>
      )}

      <Button
        label={signature ? 'Change signature' : 'Click here to e-sign'}
        icon={HiOutlinePencilAlt}
        category="primary"
        size="base"
        mode="manual"
        onClick={obtainSignature}
      />

      <div>
        <InlineError error={error} />
      </div>
    </div>
  );
};

/**
 * The component that is exposed as the signature input field.
 */
interface SignatureModalField {
  (props: BaseProps & ConditionalModeProps): JSX.Element;
}

/**
 * The component that is exposed as the signature input field.
 */
const SignatureModalInput: SignatureModalField = (props) => {
  const {mode, name, labelProps} = props;

  /**
   * Select the wrapper based on the mode
   */
  const Wrapper: WrapperComponent = {
    manual: ManualWrapper,
    formik: FormikWrapper,
  }[mode];

  return (
    <Wrapper {...props}>
      {({signature, onSave, error}: WrapperOutput) => (
        <InnerInputComponent
          name={name}
          labelProps={labelProps}
          signature={signature}
          onSave={onSave}
          error={error}
        />
      )}
    </Wrapper>
  );
};

export default SignatureModalInput;
