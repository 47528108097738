import Property, {PaymentMethodType} from 'models/properties/Property';

/**
 * Enums for describing each of the separate flows.
 */
enum OnboardingFlow {
  NewListing = 'advertise',
  MigrateTenancy = 'migrate',
  NewTenancy = 'new',

  /**
   * 'Undetermined' is a special value indicating that the onboarding
   * flow can not yet be determined based on the current step.
   * i.e. the user has likely added their property but have
   * not yet selected an onboarding flow type.
   */
  Undetermined = 'undetermined',
}

/**
 * Shared steps.
 */
enum SharedStep {
  /**
   * Steps before selecting the onboarding type.
   */
  AddPropertyAddress = 'add_property_address',
  SelectOnboardingType = 'select_onboarding_type',

  /**
   * 'Completed' is a special value indicating that there is no
   * current step due to completion of one of the flows.
   */
  Completed = 'completed',

  /**
   * 'Undetermined' is a special value indicating that the current step
   * could not be evaluated based on the information currently available
   * on the property.
   */
  Undetermined = 'undetermined',
}

enum NewListingStep {
  /**
   * New listing steps.
   */
  ListingPropertyType = 'listing_property_type',
  ListingGeneralInformation = 'listing_general_information',
  ListingRequirements = 'listing_requirements',
  ListingFinancials = 'listing_financials',
  ListingAvailability = 'listing_availability',
  ListingTags = 'listing_tags',
  ListingPhotos = 'listing_photos',
  ListingDescription = 'listing_description',
  ListingPreview = 'listing_preview',
  ListingPublish = 'listing_publish',
}

/**
 * Migrate tenancy steps.
 */
enum MigrateTenancyStep {
  MigrateRentInformation = 'migrate_rent_information',
  MigrateInviteTenant = 'migrate_invite_tenant',
  MigrateRentMethod = 'migrate_rent_method',
  MigrateBanking = 'migrate_banking',
  MigrateCommencementDate = 'migrate_commencement_date',
}

/**
 * New tenancy steps.
 */
enum NewTenancyStep {
  NewPropertyType = 'new_property_type',
  NewGeneralInformation = 'new_general_information',
  NewRequirements = 'new_requirements',
  NewFinancials = 'new_financials',
  NewRentMethod = 'new_rent_method',
  NewBankDetails = 'new_bank_details',
  NewAvailability = 'new_availability',
  NewPersonalProfile = 'new_personal_profile',
  NewChattels = 'new_chattels',
  NewInsurance = 'new_insurance',
  NewLeaseConditions = 'new_lease_conditions',
  NewPreviewLease = 'new_preview_lease',
  NewHealthyHomes = 'new_healthy_homes',
  NewInviteTenants = 'new_invite_tenants',
}

/**
 * Evaluates the current onboarding flow based on
 * the onboarding current step.
 */
export const getFlowFromStep = (
  step: OnboardingFlowStep,
): OnboardingFlow | null => {
  /**
   * If the step indicates that the flow has been completed,
   * then we return null since there the flow is no longer active.
   */
  if (step === SharedStep.Completed) {
    return null;
  }

  /**
   * Current step is shared between flows so we can't determined
   * the selected flow yet.
   */
  if (Object.values(SharedStep).includes(step as SharedStep)) {
    return OnboardingFlow.Undetermined;
  }

  /**
   * Current step is part of the new listing flow.
   */
  if (Object.values(NewListingStep).includes(step as NewListingStep)) {
    return OnboardingFlow.NewListing;
  }

  /**
   * Current step is part of the migrate tenancy flow.
   */
  if (Object.values(MigrateTenancyStep).includes(step as MigrateTenancyStep)) {
    return OnboardingFlow.MigrateTenancy;
  }

  /**
   * Current step is part of the new tenancy flow.
   */
  if (Object.values(NewTenancyStep).includes(step as NewTenancyStep)) {
    return OnboardingFlow.NewTenancy;
  }
};

/**
 * Merge all of the above enums into one.
 */
const OnboardingFlowSteps = {
  ...SharedStep,
  ...NewListingStep,
  ...MigrateTenancyStep,
  ...NewTenancyStep,
};

/**
 * Export the type of the merged enum values for a high level step type.
 */
export type OnboardingFlowStep =
  (typeof OnboardingFlowSteps)[keyof typeof OnboardingFlowSteps];

/**
 * Export the necessary enums as well as a shorthand versions.
 */
const OBF = OnboardingFlow;
export {OnboardingFlow, OBF};
const OBFS = OnboardingFlowSteps;
export {OnboardingFlowSteps, OBFS};

/**
 * The definition for the ordering of onboarding flow steps.
 */
export const onboardingFlowStepOrder: {
  [key in OnboardingFlowStep]: {
    previous: (property: Property) => OnboardingFlowStep | null;
    next: (property: Property) => OnboardingFlowStep | null;
  };
} = {
  /**
   * ==================================================
   * ================== SHARED FLOW ===================
   * ==================================================
   */

  [OBFS.Completed]: {
    next: () => null,
    previous: () => null,
  },

  [OBFS.Undetermined]: {
    next: () => null,
    previous: () => null,
  },

  [OBFS.AddPropertyAddress]: {
    next: () => OBFS.SelectOnboardingType,
    previous: () => null,
  },

  [OBFS.SelectOnboardingType]: {
    next: ({noTenancyActionType}) => {
      switch (noTenancyActionType) {
        case 'advertise': // New listing
          return OBFS.ListingPropertyType;
        case 'migrate': // Migrate tenancy
          return OBFS.MigrateRentInformation;
        case 'new': // New tenancy
          return OBFS.NewPropertyType;
        default:
          /**
           * noTenancyActionType is invalid or unset.
           * This should not be reachable if configured correctly.
           */
          return OBFS.Undetermined;
      }
    },
    previous: () => OBFS.AddPropertyAddress,
  },

  /**
   * ==================================================
   * ================ NEW LISTING FLOW ================
   * ==================================================
   */
  [OBFS.ListingPropertyType]: {
    next: () => OBFS.ListingGeneralInformation,
    previous: () => OBFS.SelectOnboardingType,
  },

  [OBFS.ListingGeneralInformation]: {
    next: () => OBFS.ListingRequirements,
    previous: () => OBFS.ListingPropertyType,
  },

  [OBFS.ListingRequirements]: {
    next: () => OBFS.ListingFinancials,
    previous: () => OBFS.ListingGeneralInformation,
  },

  [OBFS.ListingFinancials]: {
    next: () => OBFS.ListingAvailability,
    previous: () => OBFS.ListingRequirements,
  },

  [OBFS.ListingAvailability]: {
    next: () => OBFS.ListingTags,
    previous: () => OBFS.ListingFinancials,
  },

  [OBFS.ListingTags]: {
    next: () => OBFS.ListingPhotos,
    previous: () => OBFS.ListingAvailability,
  },

  [OBFS.ListingPhotos]: {
    next: () => OBFS.ListingDescription,
    previous: () => OBFS.ListingTags,
  },

  [OBFS.ListingDescription]: {
    next: () => OBFS.ListingPreview,
    previous: () => OBFS.ListingPhotos,
  },

  [OBFS.ListingPreview]: {
    next: () => OBFS.ListingPublish,
    previous: () => OBFS.ListingDescription,
  },

  [OBFS.ListingPublish]: {
    next: () => OBFS.Completed,
    previous: () => OBFS.ListingPreview,
  },

  /**
   * ==================================================
   * ============== MIGRATE TENANCY FLOW ==============
   * ==================================================
   */
  [OBFS.MigrateRentInformation]: {
    next: () => OBFS.MigrateInviteTenant,
    previous: () => OBFS.SelectOnboardingType,
  },

  [OBFS.MigrateInviteTenant]: {
    next: () => OBFS.MigrateRentMethod,
    previous: () => OBFS.MigrateRentInformation,
  },

  [OBFS.MigrateRentMethod]: {
    next: ({paymentMethodType, tenancies}) => {
      switch (paymentMethodType) {
        case 'rent':
          return OBFS.MigrateBanking;
        case 'card':
          /**
           * If the user has not invited any tenants, then we can skip
           * the commencement date step and instead complete the flow.
           * If they skipped inviting a tenant, then the status of the
           * tenancy will be active instead of a draft.
           */
          return tenancies.find(
            (tenancy) =>
              tenancy.isPending ||
              (tenancy.isDraft && tenancy.tenancyRequests?.length > 0),
          )
            ? OBFS.MigrateCommencementDate
            : OBFS.Completed;
        default:
          /**
           * paymentMethodType is invalid or unset.
           * This should not be reachable if configured correctly.
           */
          return OBFS.Undetermined;
      }
    },
    previous: () => OBFS.MigrateInviteTenant,
  },

  [OBFS.MigrateBanking]: {
    next: () => OBFS.MigrateCommencementDate,
    previous: () => OBFS.MigrateRentMethod,
  },

  [OBFS.MigrateCommencementDate]: {
    next: () => OBFS.Completed,
    previous: ({paymentMethodType}) => {
      switch (paymentMethodType) {
        case 'rent':
          return OBFS.MigrateBanking;
        case 'card':
          return OBFS.MigrateRentMethod;
        default:
          /**
           * paymentMethodType is invalid or unset.
           * This should not be reachable if configured correctly.
           */
          return OBFS.Undetermined;
      }
    },
  },

  /**
   * ==================================================
   * ================ NEW TENANCY FLOW ================
   * ==================================================
   */
  [OBFS.NewPropertyType]: {
    next: () => OBFS.NewGeneralInformation,
    previous: () => OBFS.SelectOnboardingType,
  },

  [OBFS.NewGeneralInformation]: {
    next: () => OBFS.NewRequirements,
    previous: () => OBFS.NewPropertyType,
  },

  [OBFS.NewRequirements]: {
    next: () => OBFS.NewFinancials,
    previous: () => OBFS.NewGeneralInformation,
  },

  [OBFS.NewFinancials]: {
    next: () => OBFS.NewRentMethod,
    previous: () => OBFS.NewRequirements,
  },

  [OBFS.NewRentMethod]: {
    // TODO: Do we need to check if card vs rent?
    next: () => OBFS.NewBankDetails,
    previous: () => OBFS.NewFinancials,
  },

  [OBFS.NewBankDetails]: {
    next: () => OBFS.NewAvailability,
    previous: () => OBFS.NewRentMethod,
  },

  [OBFS.NewAvailability]: {
    next: () => OBFS.NewPersonalProfile,
    previous: () => OBFS.NewBankDetails,
  },

  [OBFS.NewPersonalProfile]: {
    next: () => OBFS.NewChattels,
    previous: () => OBFS.NewAvailability,
  },

  [OBFS.NewChattels]: {
    next: () => OBFS.NewInsurance,
    previous: () => OBFS.NewPersonalProfile,
  },

  [OBFS.NewInsurance]: {
    next: () => OBFS.NewLeaseConditions,
    previous: () => OBFS.NewChattels,
  },

  [OBFS.NewLeaseConditions]: {
    next: () => OBFS.NewPreviewLease,
    previous: () => OBFS.NewInsurance,
  },

  [OBFS.NewPreviewLease]: {
    next: () => OBFS.NewHealthyHomes,
    previous: () => OBFS.NewLeaseConditions,
  },

  [OBFS.NewHealthyHomes]: {
    next: () => OBFS.NewInviteTenants,
    previous: () => OBFS.NewPreviewLease,
  },

  [OBFS.NewInviteTenants]: {
    next: () => OBFS.Completed,
    previous: () => OBFS.NewHealthyHomes,
  },
};
