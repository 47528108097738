import {useEffect} from 'react';

import {useMutation} from 'react-query';
import {useParams} from 'react-router';

import {ResultMessage, SpinningLoader} from 'components_sb/feedback';
import {API_URL} from 'globals/app-globals';

const CancelPrivateViewingPage = () => {
  const {publicAccessToken} = useParams();

  /**
   * Mutation for cancelling the private viewing.
   */
  const {
    mutate: cancelViewing,
    isLoading: isCancelling,
    isError: errorCancelling,
    isSuccess: successfullyCancelled,
  } = useMutation(async () => {
    const response = await fetch(
      `${API_URL}/private_views/${publicAccessToken}/mark_as_cancelled.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to confirm viewing');
    }
  });

  /**
   * Confirm the viewing when the page loads.
   */
  useEffect(() => {
    cancelViewing();
  }, [cancelViewing]);

  return (
    <div>
      {isCancelling && (
        <SpinningLoader
          color="brand"
          size="lg"
          message="Cancelling your private viewing..."
        />
      )}
      {errorCancelling && (
        <ResultMessage type="error">
          Sorry, there was an issue cancelling your private viewing.
        </ResultMessage>
      )}
      {successfullyCancelled && (
        <ResultMessage type="success">
          Your private viewing has been cancelled!
        </ResultMessage>
      )}
    </div>
  );
};

export default CancelPrivateViewingPage;
