export const roundUp = (v: number, n: number): number => {
  return Math.ceil(v * Math.pow(10, n)) / Math.pow(10, n);
};

export const roundMoney = (number: number, precision: number): number => {
  if (precision < 0) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  } else
    return +(Math.round(Number(number + 'e+' + precision)) + 'e-' + precision);
};

export const shareEvenly = (
  totalAmount: number,
  amountOfPeople: number,
): number[] => {
  const totalCents = 100 * totalAmount;
  const baseAmount = Math.floor(totalCents / amountOfPeople);
  let centsShort = totalCents - baseAmount * amountOfPeople;

  const amounts = [];
  for (let i = 0; i < amountOfPeople; i++) {
    amounts.push(baseAmount);
  }

  let i = 0;

  /**
   * Infinite loop until broken.
   */
  for (;;) {
    if (centsShort === 0) {
      break;
    }
    if (i === amounts.length) {
      i = 0;
    }

    amounts[i] += 1;

    centsShort--;
    i += 1;
  }

  return amounts.map((num) => num / 100.0);
};
