import {useCallback, useImperativeHandle, useRef} from 'react';

import {Form, Formik} from 'formik';
import type {FormikProps, FormikValues, FormikHelpers} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {Base64FileField, TextareaField} from 'components/forms_fields';
import {ModalDefinition} from 'components_sb/layout';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import useInspectionStore from 'stores/InspectionStore';

type FormValues = {
  notes: string;
  attachment: string | null;
};

const InspectionAddNoteModal: ModalDefinition = {
  title: 'Add a photo with notes',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const formRef = useRef<FormikProps<FormikValues>>();
    const {currentUser} = useAuth();
    const {closeModal, inspectionItem} = props;
    const [inspectionItems, setInspectionItems] = useInspectionStore(
      (state) => [state.inspectionItems, state.setInspectionItems],
    );

    const handleSubmit = useCallback(
      async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
        const attach = new InspectionItemAttachment(formValues);
        attach.inspectionItemId = inspectionItem.id;

        const result = await attach.save();

        if (result) {
          attach.user = new User({
            id: currentUser.id,
            name: currentUser.name,
            avatar: currentUser.avatar,
            email: currentUser.email,
          });
          attach.user.isPersisted = true;
          attach.userId = currentUser.id;

          /**
           * Update the inspection items store so that it re-renders the UI for rooms etc
           */
          const item = inspectionItems?.find((i) => i.id === inspectionItem.id);
          if (item) {
            item.inspectionItemAttachments.push(attach);
            setInspectionItems(inspectionItems);
          }

          actions.setSubmitting(false);
          closeModal();
          toast.success('Note successfully added!');
        } else {
          for (const key of Object.keys(attach.errors)) {
            const message = attach.errors[key].fullMessage;
            actions.setFieldError(key, message);
          }

          actions.setSubmitting(false);
        }
      },
      [
        currentUser,
        inspectionItem,
        inspectionItems,
        setInspectionItems,
        closeModal,
      ],
    );

    const onSave = useCallback(async () => {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    }, [formRef]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <Formik
        innerRef={formRef}
        initialValues={{notes: '', attachment: null} as FormValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          notes: Yup.string().required().min(5).label('Notes'),
          attachment: Yup.string().required().nullable().label('Photo'),
        })}>
        {(formik) => (
          <Form>
            <div className="mt-2">
              <TextareaField
                formik={formik}
                labelProps={{title: 'Notes', size: 'base'}}
                name="notes"
                placeholder="Write your notes here"
              />
            </div>

            <div className="mt-2">
              <Base64FileField
                formik={formik}
                name="attachment"
                accept=".png,.jpeg,.jpg"
                labelProps={{
                  title: 'Select a photo',
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  },
};

export default InspectionAddNoteModal;
