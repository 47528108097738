import {useState} from 'react';

import moment from 'moment';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';

import emptyBg from 'assets/img/empty_dataset_images/landlord_payments.svg';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import useQueryString from 'hooks/useQueryString';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import LandlordPayment from 'models/payments/LandlordPayment';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {paginationArray} from 'utilities/paginationArray';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const LandlordPaymentIndexPage = () => {
  const navigate = useNavigate();

  const {tenancyId} = useParams();

  const params = useQueryString();
  const page = params.has('page') ? Number(params.get('page')) : 1;

  useTitle('Rent Payments');
  usePageVisit('LandlordPaymentIndexPage');

  const [perPage, setPerPage] = useState(20);

  const isMobile = !useTailwindBreakpoint('sm');

  const tablePaymentsResponse = useQuery(
    [`landlord-payments-table`, page, perPage, tenancyId],
    async () => {
      const payments = await LandlordPayment.page(page)
        .per(perPage)
        .includes({tenancy: 'property'})
        .order({payment_date: 'desc'})
        .select({
          tenancies: ['id'],
          properties: ['street_address'],
        })
        .where({tenancyId})
        .stats({
          total: 'count',
        })
        .all();
      return payments;
    },
    {keepPreviousData: true},
  );

  const setPage = (page: number | string) => navigate(`?page=${page}`);

  if (tablePaymentsResponse.error) {
    return errorViewForError(tablePaymentsResponse.error);
  } else if (tablePaymentsResponse.isLoading && page === 1) {
    return (
      <PageWrapper title="Rent Payments" backEnabled>
        <LoadingView />
      </PageWrapper>
    );
  } else if (
    tablePaymentsResponse.data &&
    tablePaymentsResponse.data.data.length === 0
  ) {
    return (
      <PageWrapper title="Rent Payments" backEnabled>
        <EmptyDataSet
          title="No Received Payments"
          message="You have not received any payments for this property yet. Once payments from tenants have been made, they will show up here."
          image={emptyBg}
        />
      </PageWrapper>
    );
  } else {
    const total = tablePaymentsResponse.data.meta.stats.total.count;
    const maxPages = Math.ceil(total / perPage);

    return (
      <PageWrapper title="Rent Payments" backEnabled>
        <div>
          <div className="flex justify-between items-center">
            <div>
              <small className="text-secondary mt-0 block">
                Click on a {isMobile ? 'card' : 'row'} for more information.
              </small>
              <small className="text-secondary mt-0 block text-xs">
                Received refers to the amount that has been collected and
                cleared our account.
              </small>
              <small className="text-secondary mt-0 mb-2 block text-xs">
                Pending refers to the amount that has been collected but is
                pending bank clearance.
              </small>
            </div>
            <select
              className="select select-bordered select-sm py-0"
              onChange={(e) => setPerPage(Number(e.target.value))}
              value={perPage}>
              {[10, 25, 50, 100].map((count) => (
                <option key={count} value={count}>
                  {count}
                </option>
              ))}
            </select>
          </div>

          {isMobile ? (
            <div className="flex flex-col space-y-2">
              {tablePaymentsResponse.data.data &&
                tablePaymentsResponse.data.data.map((lp) => (
                  <Link key={lp.id} to={lp.id}>
                    <Card>
                      <div className="flex justify-between items-center">
                        <div className="flex flex-col">
                          <strong>
                            {moment(lp.paymentDate).format(DATE_FORMAT)}
                          </strong>
                          <span className="text-sm">
                            {lp.tenancy.property.streetAddress}
                          </span>
                          <span className="text-xs">
                            Status: {titleize(removeUnderscores(lp.status))}
                          </span>
                          <span className="text-xs text-gray-500">
                            Reference: {lp.reference}
                          </span>
                        </div>

                        <div className="flex flex-col items-center">
                          <span className="text-xs text-gray-400">Net</span>
                          <strong className="text-success">
                            {toCurrency(lp.netAmount)}
                          </strong>
                        </div>
                      </div>

                      <div className="grid grid-cols-4">
                        <div className="flex flex-col items-center">
                          <span className="text-xs">Gross</span>
                          <strong className="text-sm">
                            {toCurrency(lp.grossAmount)}
                          </strong>
                        </div>

                        <div className="flex flex-col items-center">
                          <span className="text-xs">Expenses</span>
                          <strong className="text-sm">
                            {toCurrency(lp.expensesAmount)}
                          </strong>
                        </div>

                        <div className="flex flex-col items-center">
                          <span className="text-xs">Received</span>
                          <strong className="text-sm">
                            {toCurrency(lp.amountOfRentPaid || 0.0)}
                          </strong>
                        </div>

                        <div className="flex flex-col items-center">
                          <span className="text-xs">Pending</span>
                          <strong className="text-sm">
                            {toCurrency(lp.pendingAmount || 0.0)}
                          </strong>
                        </div>
                      </div>
                    </Card>
                  </Link>
                ))}
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead className="thead-light">
                  <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Gross</th>
                    <th>Expenses</th>
                    <th>Net</th>
                    <th>Received</th>
                    <th>Pending</th>
                    <th>Reference</th>
                    <th>Property</th>
                  </tr>
                </thead>
                <tbody>
                  {tablePaymentsResponse.data.data &&
                    tablePaymentsResponse.data.data.map((lp) => (
                      <tr
                        key={lp.id}
                        className="hover cursor-pointer"
                        onClick={() => navigate(lp.id)}>
                        <td>{moment(lp.paymentDate).format(DATE_FORMAT)}</td>
                        <td>{titleize(removeUnderscores(lp.status))}</td>
                        <td>{toCurrency(lp.grossAmount)}</td>
                        <td>{toCurrency(lp.expensesAmount)}</td>
                        <td className="text-success">
                          {toCurrency(lp.netAmount)}
                        </td>
                        <td>{toCurrency(lp.amountOfRentPaid || 0.0)}</td>
                        <td className="text-secondary">
                          {toCurrency(lp.pendingAmount || 0.0)}
                        </td>
                        <td>{lp.reference}</td>
                        <td>{lp.tenancy.property.streetAddress}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="flex flex-wrap justify-center mt-8">
          <div className="btn-group">
            <button
              className="btn"
              onClick={() => setPage(page - 1)}
              disabled={page == 0}>
              Prev
            </button>
            {paginationArray(page - 1, maxPages).map((pageIter) =>
              pageIter === '...' ? (
                <button key={pageIter} className="btn btn-disabled">
                  {pageIter}
                </button>
              ) : (
                <button
                  key={pageIter}
                  className={`btn ${
                    page === Number(pageIter) ? 'btn-active' : ''
                  }`}
                  onClick={() => setPage(Number(pageIter))}>
                  {pageIter}
                </button>
              ),
            )}
            <button
              className="btn"
              onClick={() => setPage(page + 1)}
              disabled={tablePaymentsResponse.data.data.length < perPage}>
              Next
            </button>
          </div>
        </div>
      </PageWrapper>
    );
  }
};

export default LandlordPaymentIndexPage;
