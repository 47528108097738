import {FunctionComponent, useCallback, useMemo, useState} from 'react';

import {HiOutlineUsers} from 'react-icons/hi';
import {MdOutlineDirectionsCar} from 'react-icons/md';

import {SingleValueSlider} from 'components_sb/input';
import {
  TENANT_COUNT as CONSTANTS,
  getPlaceholderValue,
} from 'constants/listings-search';

import FilterWrapper from './shared/FilterWrapper';
import {FilterProps} from './types';

type TenantCountFilterProps = FilterProps;

const TenantCountFilter: FunctionComponent<TenantCountFilterProps> = ({
  form,
  name,
  inline = false,
}) => {
  const hasModifiedDefault = useMemo(
    () => form.values[name] !== undefined,
    [form, name],
  );

  const value = useMemo(
    () => (!hasModifiedDefault ? getPlaceholderValue(name) : form.values[name]),
    [hasModifiedDefault, form, name],
  );

  const onChange = useCallback(
    (value: number) => {
      form.setFieldValue(name, value);
    },
    [form, name],
  );

  const valueLabel = useMemo(
    () => (!hasModifiedDefault ? null : value),
    [hasModifiedDefault, value],
  );

  const clearFilter = useCallback(() => {
    /**
     * We can't use setFieldValue here, because Formik will delete
     * the entry from the form values if we are setting it to undefined.
     */
    form.setFormikState((current) => ({
      ...current,
      values: {
        ...current.values,
        [name]: undefined,
      },
    }));
  }, [form, name]);

  return (
    <FilterWrapper
      inline={inline}
      label="Tenants"
      icon={HiOutlineUsers}
      valueLabel={valueLabel}
      onClearFilter={clearFilter}>
      <SingleValueSlider
        name={name}
        min={CONSTANTS.MIN}
        max={CONSTANTS.MAX}
        step={CONSTANTS.STEP}
        value={value}
        onChange={onChange}
      />
    </FilterWrapper>
  );
};

export default TenantCountFilter;
