import {HiOutlineExclamationCircle, HiOutlineUserGroup} from 'react-icons/hi';
import {MdPets} from 'react-icons/md';
import {TbSmoking} from 'react-icons/tb';

import {Card} from 'components_sb/layout';
import Listing from 'models/listings/Listing';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

interface RestrictionsCardProps {
  listing: Listing;
}

const RestrictionsCard = ({listing}: RestrictionsCardProps) => (
  <Card
    icon={HiOutlineExclamationCircle}
    title="Restrictions"
    subtitle="The requirements for renting this property.">
    <div className="flex flex-col gap-y-2 mt-2">
      <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
        <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
          <div className="flex items-center">
            <MdPets className="w-5 h-5 inline-block" />
            <span className="ml-2 inline-block">
              Pets Allowed?
              {listing.allowedPetTypes.length > 0 && (
                <div className="text-secondary text-xs">
                  {listing.allowedPetTypes
                    .map((pet) => titleize(removeUnderscores(pet)))
                    .join(', ')}
                </div>
              )}
            </span>
          </div>

          <span>{listing.petsAllowed ? 'Yes' : 'No'}</span>
        </div>

        <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg mt-2">
          <p className="flex items-center">
            <HiOutlineUserGroup className="w-5 h-5 inline-block" />
            <span className="ml-2 inline-block">Maxium Number of Tenants</span>
          </p>
          <span>{listing.maxTenants}</span>
        </div>

        <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg mt-2">
          <p className="flex items-center">
            <TbSmoking className="w-5 h-5 inline-block" />
            <span className="ml-2 inline-block">Smoking Allowed</span>
          </p>
          <span>{listing.smokingAllowed ? 'Yes' : 'No'}</span>
        </div>
      </div>
    </div>
  </Card>
);

export default RestrictionsCard;
