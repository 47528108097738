import React from 'react';

import {Form, Formik, FormikHelpers} from 'formik';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton, TextareaField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import {InlineError} from 'components_sb/feedback';
import GridSelect from 'components_sb/input/GridSelect/GridSelect';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Tenancy from 'models/properties/Tenancy';
import NotFoundPage from 'pages/shared/errors/NotFoundPage';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {toCurrency} from 'utilities/StringHelpers';

type FormValues = {
  bondNumber: string;
  bondRefundAmount: number;
  bondDisputeAmount: number;
  bondRefundReasons: string[];
  messageFromLandlord: string;
  landlordSignature: string;
};

const REFUND_REASONS = ['Arrears', 'Repairs', 'Cleaning', 'Outgoings', 'Other'];

const EditEndOfTenancyPage = () => {
  const {propertyId, id} = useParams();

  const navigate = useNavigate();

  const {data, isLoading, error} = useQuery(
    `tenancy-${id}-end-of-tenancy`,
    async () => {
      const tenancy = await Tenancy.includes([
        'end_of_tenancy',
        'property',
      ]).find(id);
      return tenancy.data;
    },
  );

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const attrs = {...values};

    if (attrs.messageFromLandlord === '') {
      delete attrs.messageFromLandlord;
    }

    const eot = data.endOfTenancy;
    eot.assignAttributes(attrs);

    const result = await eot.save();

    if (result) {
      toast.success('Bond refund successfully saved');
      navigate(`/properties/${propertyId}`, {replace: true});
    } else {
      toast.error('Bond refund could not be saved');
      for (const [key, value] of Object.entries(eot.errors)) {
        actions.setFieldError(key, value.fullMessage);
      }
    }

    actions.setSubmitting(false);
  };

  const isMobile = !useTailwindBreakpoint('sm');

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Bond Refund" backEnabled>
        <LoadingView />
      </PageWrapper>
    );
  } else if (data && !data.endOfTenancy) {
    return (
      <PageWrapper title="Bond Refund" backEnabled>
        <NotFoundPage />
      </PageWrapper>
    );
  } else {
    const bond = Number(data.bond);
    return (
      <PageWrapper title="Bond Refund" backEnabled>
        <Card title={`${data.property.streetAddress} bond refund`}>
          <Paragraph>
            Fill in the form below to prepare the bond refund for this tenancy.
            This will then be sent to your tenants to sign and will
            automatically be filed with Tenancy Services once ready.
          </Paragraph>
          <Paragraph>
            It is recommended that you complete your final inspection before you
            prepare the bond refund. Any claimed funds from the bond will be
            deposited directly to your bank account by Tenancy Services.
          </Paragraph>

          <Paragraph>
            The bond for this tenancy was {toCurrency(bond)}.
          </Paragraph>

          {data.endOfTenancy.anyTenantsSigned && (
            <Paragraph>
              This bond refund has already been signed by your tenants. You can
              no longer edit it.
            </Paragraph>
          )}

          <Formik
            initialValues={
              {
                bondNumber:
                  data.endOfTenancy.bondNumber || data.externalBondId || '',
                bondRefundAmount: data.endOfTenancy.bondRefundAmount || '',
                bondDisputeAmount: data.endOfTenancy.bondDisputeAmount || '',
                bondRefundReasons: data.endOfTenancy.bondRefundReasons || [],
                messageFromLandlord:
                  data.endOfTenancy.messageFromLandlord || '',
                landlordSignature: '',
              } as FormValues
            }
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              bondNumber: Yup.string().label('Bond Number').min(6),
              bondRefundAmount: Yup.number()
                .max(bond)
                .min(0)
                .required()
                .label('Bond Refund Amount')
                .test(
                  'is valid amount',
                  'The refund amount plus the dispute amount must be less than or equal to the bond amount',
                  function (value) {
                    if (!value || !this.parent.bondDisputeAmount) {
                      return true;
                    }
                    return value + this.parent.bondDisputeAmount <= bond;
                  },
                ),
              bondDisputeAmount: Yup.number()
                .max(bond)
                .min(0)
                .required()
                .label('Hold in dispute Amount')
                .test(
                  'is valid amount',
                  'The dispute amount plus the refund amount must be less than or equal to the bond amount',
                  function (value) {
                    if (!value || !this.parent.bondRefundAmount) {
                      return true;
                    }
                    return value + this.parent.bondRefundAmount <= bond;
                  },
                ),
              bondRefundReasons: Yup.array()
                .min(0)
                .required()
                .label('Reasons for claiming bond')
                .test(
                  'is required if bond is claimed',
                  'You must provide a reason for not refunding the full amount',
                  function (value) {
                    if (!value || !this.parent.bondRefundAmount) {
                      return true;
                    }

                    if (this.parent.bondRefundAmount === bond) {
                      return true;
                    } else {
                      return value.length > 0;
                    }
                  },
                ),
              messageFromLandlord: Yup.string().label('Message from landlord'),
              landlordSignature: Yup.string()
                .label('Landlord signature')
                .required()
                .min(1),
            })}>
            {(formik) => (
              <Form>
                <InputField
                  formik={formik}
                  labelProps={{
                    title: 'Bond Number',
                    helpText:
                      'This is the bond id from tenancy services. It should look like this: 1234567-890',
                  }}
                  name="bondNumber"
                  placeholder="eg: 1234567-890"
                />

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="bondRefundAmount"
                      labelProps={{
                        title: 'Bond Refund Amount',
                        helpText: 'The amount to be refunded to the tenant(s).',
                      }}
                      type="number"
                      step="0.01"
                      prefixLabel="$"
                      placeholder={`eg: ${bond}`}
                      disabled={data.endOfTenancy.anyTenantsSigned}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="bondDisputeAmount"
                      labelProps={{
                        title: 'Amount to hold in dispute',
                        helpText: 'The amount that is being held in dispute.',
                      }}
                      type="number"
                      step="0.01"
                      prefixLabel="$"
                      placeholder="eg: 200"
                      disabled={data.endOfTenancy.anyTenantsSigned}
                    />
                  </FormRowItem>
                </FormRow>

                {formik.values.bondRefundAmount &&
                  Number(formik.values.bondRefundAmount) !== bond && (
                    <div>
                      <div className="mt-4">
                        <label className="label flex justify-start items-center">
                          <span className="label-text mr-2">
                            Reasons for claiming bond
                          </span>
                        </label>
                        <GridSelect
                          multiple
                          mode="manual:select"
                          options={REFUND_REASONS.map((reason) => ({
                            id: reason,
                            label: reason,
                          }))}
                          value={formik.values.bondRefundReasons}
                          onChange={(value) => {
                            data.endOfTenancy.anyTenantsSigned
                              ? null
                              : formik.setFieldValue(
                                  'bondRefundReasons',
                                  value,
                                );
                          }}
                          maxColumns={isMobile ? 3 : 5}
                          size="base"
                        />

                        <InlineError
                          name="bondRefundReasons"
                          error={formik.errors.bondRefundReasons}
                        />
                      </div>

                      <TextareaField
                        name="messageFromLandlord"
                        formik={formik}
                        labelProps={{title: 'Optional message'}}
                        placeholder="Include a message to your tenant(s)."
                        disabled={data.endOfTenancy.anyTenantsSigned}
                      />
                    </div>
                  )}

                <div className="mt-4">
                  {!data.endOfTenancy.anyTenantsSigned && (
                    <SignatureModalInput
                      name="landlordSignature"
                      mode="formik"
                    />
                  )}
                </div>

                {!data.endOfTenancy.anyTenantsSigned && (
                  <div className="mt-4">
                    <SubmitButton
                      formik={formik}
                      text="Save"
                      submittingText="Saving"
                    />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </Card>
      </PageWrapper>
    );
  }
};

export default EditEndOfTenancyPage;
