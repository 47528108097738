import React, {useEffect, useState} from 'react';

import {DocumentAddIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {Base64FileField, SubmitButton} from 'components/forms_fields';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import Document from 'models/properties/Document';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  lease: string;
  bond: string;
  healthyHomes: string;
};

const PropertyDocumentsAction = ({
  property,
  tenancy,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const hasHealthyHomes =
    property.documents.findIndex(
      (d) => d.documentType === 'healthy_homes_report',
    ) !== -1;
  const hasLease =
    tenancy.documents.findIndex((d) => d.documentType === 'lease') !== -1;
  const hasBond =
    tenancy.documents.findIndex((d) => d.documentType === 'bond') !== -1;

  useEffect(() => {
    if (!property.completedProfileSteps.includes(storeKey)) {
      if (hasHealthyHomes && hasLease && hasBond) {
        /**
         * Set changes on the property.
         */
        property.completedProfileSteps.push(storeKey);

        /**
         * Save the changes to the property.
         */
        const savedProperty = saveResource(property);

        if (savedProperty) {
          /**
           * Update the property data in the query cache.
           */
          queryClient.setQueryData(
            ['property', {id: property.id, context: 'detail-page'}],
            property,
          );

          setModalVisible(false);
        }
      }
    }
  }, [hasHealthyHomes, hasLease, hasBond, property, queryClient, storeKey]);

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    // The reason for the deletes is:
    // If the user uploads one doc at a time,
    // it will resubmit those docs again and again
    // and cause duplicates.

    if (formValues.lease && formValues.lease.length > 0) {
      const doc = new Document({
        documentType: 'lease',
        document: formValues.lease,
        name: null,
        documentableType: 'Tenancy',
        documentableId: tenancy.id,
      });

      await doc.save();
      delete formValues.lease;
    }

    if (formValues.bond && formValues.bond.length > 0) {
      const doc = new Document({
        documentType: 'bond',
        document: formValues.bond,
        name: null,
        documentableType: 'Tenancy',
        documentableId: tenancy.id,
      });

      await doc.save();
      delete formValues.bond;
    }

    if (formValues.healthyHomes && formValues.healthyHomes.length > 0) {
      const doc = new Document({
        documentType: 'healthy_homes_report',
        document: formValues.healthyHomes,
        name: null,
        documentableType: 'Property',
        documentableId: property.id,
      });

      await doc.save();
      delete formValues.healthyHomes;
    }

    const result = await property.save();

    if (result) {
      queryClient.invalidateQueries([
        'property',
        {id: property.id, context: 'detail-page'},
      ]);
      toast.success('Property successfully updated!');
    } else {
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const title = 'Property Documents';
  const subtitle = 'Upload your existing documents for this property.';
  const iconBgColor = 'bg-red-400';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={DocumentAddIcon}
        iconBgColor={iconBgColor}
        onClick={() => setModalVisible(true)}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={DocumentAddIcon}
        bgColor={iconBgColor}
        form={
          <Formik
            initialValues={
              {
                lease: '',
                bond: '',
                healthyHomes: '',
              } as FormValues
            }
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              lease: Yup.string().optional().min(1).label('Lease File'),
              bond: Yup.string().optional().min(1).label('Bond File'),
              healthyHomes: Yup.string()
                .optional()
                .min(1)
                .label('Healthy Homes Compliance File'),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col justify-center grow space-y-4">
                    {hasLease ? (
                      <p>Your lease has been uploaded.</p>
                    ) : (
                      <Base64FileField
                        formik={formik}
                        name="lease"
                        accept=".pdf"
                        labelProps={{
                          title: 'Tenancy agreement',
                          description: 'Please upload a PDF file',
                        }}
                      />
                    )}

                    {hasBond ? (
                      <p>Your bond has been uploaded.</p>
                    ) : (
                      <Base64FileField
                        formik={formik}
                        name="bond"
                        accept=".pdf"
                        labelProps={{
                          title: 'Bond documents',
                          description: 'Please upload a PDF file',
                        }}
                      />
                    )}

                    {hasHealthyHomes ? (
                      <p>
                        Your healthy homes compliance report has been uploaded.
                      </p>
                    ) : (
                      <Base64FileField
                        formik={formik}
                        name="healthyHomes"
                        accept=".pdf"
                        labelProps={{
                          title: 'Healthy Homes compliance report',
                          description: 'Please upload a PDF file',
                        }}
                      />
                    )}
                  </div>

                  <SubmitButton
                    formik={formik}
                    text="Save"
                    submittingText="Saving"
                    className={`!rounded-full ${iconBgColor} border-none mt-6`}
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default PropertyDocumentsAction;
