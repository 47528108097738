import facebookLogo from 'assets/img/listing-platform-logos/facebook.png';
import homesLogo from 'assets/img/listing-platform-logos/homes-nz.png';
import keyhookLogo from 'assets/img/listing-platform-logos/keyhook.png';
import mitulaLogo from 'assets/img/listing-platform-logos/mitula.png';
import oneroofLogo from 'assets/img/listing-platform-logos/oneroof.png';
import realestateLogo from 'assets/img/listing-platform-logos/realestate-nz.png';
import rentolaLogo from 'assets/img/listing-platform-logos/rentola.png';
import trademeLogo from 'assets/img/listing-platform-logos/trademe-property.png';
import trovitLogo from 'assets/img/listing-platform-logos/trovit.png';
import Listing from 'models/listings/Listing';

export const PREMIUM_LISTING_FEE_EXCL_GST = 94;

export interface BaseListingPlatformProps {
  /**
   * The URL for the listing platform website used
   * as the main identifier for the platform.
   */
  site: string;
  /**
   * How the platform should be labelled in the UI.
   */
  label: string;
  /**
   * An imported image to be used as the platform's logo.
   */
  logo: string;
  /**
   * Whether the platform requires a paid upgrade.
   */
  premium: boolean;
  /**
   * A function that generates a URL for the the listing platform
   * using the address of the property when no direct link to
   * the listing is available.
   */
  fallbackLinkGenerator?: (listing: Listing) => string;
}

/**
 * Ensures that when implicit is true, implicitDependencies is defined.
 */
type ImplicitListingPlatformProps =
  | {
      /**
       * Whether the platform is handled implicitly. Implicit platforms
       * do not need to be added to the chosen_external_listing_sites
       * array when enabled platforms for a listing since these are
       * automatically processed by default (i.e. Keyhook and Mitula).
       */
      implicit: true;
      /**
       * Sites that this implicit platform depends on, i.e. which site
       * causes this implicit site to be processed. If null, this indicates
       * that the implicit site is always processed.
       */
      publishedBySite: string | null;
    }
  | {
      implicit: false;
      publishedBySite?: never;
    };

type ListingPlatform = BaseListingPlatformProps & ImplicitListingPlatformProps;

const platforms: ListingPlatform[] = [
  {
    site: 'keyhook.com',
    label: 'Keyhook Listings',
    logo: keyhookLogo,
    implicit: true,
    publishedBySite: null, // Posting to Keyhook does not depend on other platforms.
    premium: false,
    fallbackLinkGenerator: (listing: Listing) =>
      `/listings/${listing.publicId}`,
  },
  {
    site: 'realestate.co.nz',
    label: 'Real Estate NZ',
    logo: realestateLogo,
    implicit: false,
    premium: false,
    fallbackLinkGenerator: (listing: Listing) =>
      !listing.streetAddress || !listing.city
        ? null
        : `https://www.realestate.co.nz/residential/rental?by=best-match&k=%22${encodeURIComponent(
            `${listing.streetAddress}, ${listing.city}`,
          )}%22`,
  },
  {
    site: 'oneroof.co.nz',
    label: 'OneRoof',
    logo: oneroofLogo,
    implicit: false,
    premium: false,
  },
  {
    site: 'facebook.com',
    label: 'Facebook',
    logo: facebookLogo,
    implicit: false,
    premium: false,
  },
  {
    site: 'trovit.com',
    label: 'Trovit',
    logo: trovitLogo,
    implicit: false,
    premium: false,
    fallbackLinkGenerator: (listing: Listing) =>
      !listing.streetAddress || !listing.city
        ? null
        : `https://property.trovit.co.nz/index.php/cod.search_homes/type.2/what_d.${encodeURIComponent(
            `${listing.streetAddress}, ${listing.city}`,
          )}/isUserSearch.1`,
  },
  // {
  //   site: 'rentola.com',
  //   label: 'Rentola',
  //   logo: rentolaLogo,
  //   implicit: false,
  //   premium: false,
  // },
  {
    site: 'homes.mitula.co.nz',
    label: 'Mitula Homes',
    logo: mitulaLogo,
    implicit: true,
    publishedBySite: 'trovit.com',
    premium: false,
    fallbackLinkGenerator: (listing: Listing) =>
      !listing.streetAddress || !listing.city
        ? null
        : `https://homes.mitula.co.nz/searchRE/op-2/q-${encodeURIComponent(
            `${listing.streetAddress}, ${listing.city}`,
          )}`,
  },
  {
    site: 'trademe.co.nz',
    label: 'Trade Me Property',
    logo: trademeLogo,
    implicit: false,
    premium: true,
    fallbackLinkGenerator: (listing: Listing) =>
      !listing.streetAddress || !listing.city
        ? null
        : `https://www.trademe.co.nz/a/property/residential/rent/search?search_string=${encodeURIComponent(
            `${listing.streetAddress}, ${listing.city}`,
          )}`,
  },
  {
    site: 'homes.co.nz',
    label: 'homes.co.nz',
    logo: homesLogo,
    implicit: true,
    publishedBySite: 'trademe.co.nz',
    premium: true,
    fallbackLinkGenerator: (listing: Listing) =>
      !listing.streetAddress || !listing.city
        ? null
        : `https://homes.co.nz/map?filter=type:rent`,
  },
];

export default platforms;
