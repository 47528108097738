import {HttpError} from 'components_sb/miscellaneous';

const BadRequestPage = (): JSX.Element => (
  <HttpError
    code={400}
    title="Bad Request"
    description="There was an issue generating this page due to the data provided."
  />
);

export default BadRequestPage;
