import '@dotlottie/player-component';

const LoadingView = () => {
  return (
    <div className="flex justify-center w-full mt-2">
      <dotlottie-player
        autoplay
        loop
        mode="normal"
        src="/assets/lottie/loading-house.lottie"
        style={{height: 250, width: 250}}
      />
    </div>
  );
};

export default LoadingView;
