import {useEffect} from 'react';

import {App, URLOpenListenerEvent} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {useNavigate} from 'react-router';

import useAuth from 'services/useAuth';

const AppLinkProvider = ({children}: {children: React.ReactNode}) => {
  const navigate = useNavigate();

  const {userIsLoggedIn} = useAuth();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://app.keyhook.com/properties/1234
        // slug = /properties/1234
        const slug = event.url.split('app.keyhook.com').pop();
        // Only navigate if the user is logged in
        if (slug && userIsLoggedIn) {
          navigate(slug);
        }
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        App.removeAllListeners();
      }
    };
  }, [userIsLoggedIn]);

  return <>{children}</>;
};

export default AppLinkProvider;
