import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';
import moment from 'moment';
import {useQuery} from 'react-query';
import {Link} from 'react-router-dom';

import emptyImage from 'assets/img/empty_dataset_images/chat.svg';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import EnquiryThread from 'models/listings/EnquiryThread';
import useAuth from 'services/useAuth';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const EnquiryThreadIndexPage = () => {
  usePageVisit('EnquiryThreadIndexPage');
  const {currentUser} = useAuth();

  const {data, error, isLoading} = useQuery(
    `renter-listing-enquiries`,
    async () => {
      const enquiries = await EnquiryThread.includes([
        {enquiry_messages: 'user'},
        'listing',
      ])
        .select({
          listings: ['street_address', 'suburb', 'city', 'main_image'],
        })
        .order({updated_at: 'desc'})
        .all();

      return enquiries.data;
    },
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Your Enquiries">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.length > 0) {
      return (
        <PageWrapper title="Your Enquiries">
          {data.map((thread) => {
            const lastMessage =
              thread.enquiryMessages[thread.enquiryMessages.length - 1];
            return (
              <Link to={thread.id} key={thread.id}>
                <Card className="mb-4 cursor-pointer">
                  <div className="flex justify-between items-center">
                    <div className="flex justify-start gap-4">
                      <div>
                        <img
                          src={thread.listing.mainImage}
                          className="w-40 h-24 object-cover"
                        />
                      </div>
                      <div>
                        <h2 className="card-title mb-0 text-brand-850">
                          {thread.listing.streetAddress}
                        </h2>
                        <span className="block text-secondary text-sm">
                          {thread.listing.suburb}, {thread.listing.city}
                        </span>
                        <span className="block text-secondary text-sm">
                          Last Message{' '}
                          {lastMessage.userId === currentUser.id
                            ? 'Sent'
                            : 'Received'}{' '}
                          at {moment(thread.updatedAt).format(DATE_TIME_FORMAT)}
                        </span>

                        <p className="mt-1">
                          {lastMessage.userId === currentUser.id
                            ? 'You'
                            : lastMessage.user.name}
                          : {lastMessage.message}
                        </p>
                      </div>
                    </div>
                    <div>
                      <AiOutlineRight className="w-5 h-5 text-secondary-300" />
                    </div>
                  </div>
                </Card>
              </Link>
            );
          })}
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Your Enquiries">
          <div className="mt-4">
            <EmptyDataSet
              title="No Enquiries"
              message="You have not enquired about any listings yet."
              image={emptyImage}
            />
          </div>
        </PageWrapper>
      );
    }
  }
};

export default EnquiryThreadIndexPage;
