import React, {useState} from 'react';

import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';

import {Card} from 'components_sb/layout';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const EnquiryThreadDetailPage = () => {
  usePageVisit('EnquiryThreadDetailPage');
  const {id} = useParams();

  const queryClient = useQueryClient();

  const {currentUser} = useAuth();
  const [messageText, setMessageText] = useState('');
  const [isSendingNewMessage, setIsSendingNewMessage] = useState(false);

  const {data, error, isLoading} = useQuery(`enquiry-${id}`, async () => {
    const thread = await EnquiryThread.includes([
      {
        enquiry_messages: 'user',
      },
      'listing',
    ]).find(id);

    return thread.data;
  });

  const sendMessage = async () => {
    setIsSendingNewMessage(true);

    const message = new EnquiryMessage({
      userId: currentUser.id,
      message: messageText,
      enquiryThreadId: id,
    });

    const result = await message.save();
    if (result) {
      setMessageText('');

      const thread = data;
      message.user = new User({
        id: currentUser.id,
        name: currentUser.name,
        avatar: currentUser.avatar,
      });
      message.user.isPersisted = true;
      data.enquiryMessages.push(message);

      queryClient.setQueryData(`enquiry-${id}`, thread);
    }

    setIsSendingNewMessage(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Enquiry">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title={`${data.listing.streetAddress} Enquiry`}>
        {data.enquiryMessages.map((msg) => (
          <Card key={msg.id} className="mb-4">
            {msg.user ? (
              <div className="flex justify-start items-center">
                <div>
                  <UserAvatar user={msg.user} size="12" />
                </div>
                <div className="ml-4">
                  {msg.user.id === currentUser.id ? (
                    <h2 className="card-title mb-0 text-brand-850">You</h2>
                  ) : (
                    <h2 className="card-title mb-0 text-brand-850">
                      {msg.user.name}
                    </h2>
                  )}

                  <span className="block text-secondary text-sm">
                    {moment(msg.createdAt).format(DATE_TIME_FORMAT)}
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <h2 className="card-title mb-0 text-brand-850">{msg.name}</h2>
                <span className="block text-secondary text-sm">
                  {msg.email}
                </span>
                <span className="block text-secondary text-sm">
                  {moment(msg.createdAt).format(DATE_TIME_FORMAT)}
                </span>
              </div>
            )}

            {msg.message.split('\n').map((text, index) => (
              <p className="mt-1" key={index}>
                {text}
              </p>
            ))}
          </Card>
        ))}

        <Card title="Reply">
          <div className="flex justify-between items-center mt-4">
            <textarea
              className="textarea textarea-bordered flex-grow"
              placeholder="Write a message..."
              value={messageText}
              onChange={(e) => setMessageText(e.currentTarget.value)}
              disabled={isSendingNewMessage}
            />

            <button
              className="btn btn-neutral ml-2"
              type="button"
              disabled={messageText.length === 0 || isSendingNewMessage}
              onClick={() => sendMessage()}>
              Send
            </button>
          </div>
        </Card>
      </PageWrapper>
    );
  }
};

export default EnquiryThreadDetailPage;
