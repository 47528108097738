import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import User from 'models/users/User';

@Model()
class PushDevice extends ApplicationRecord {
  static jsonapiType = 'push_devices';

  @Attr() deviceModel: string;
  @Attr() manufacturer: string;
  @Attr() name: string;
  @Attr() os: string;
  @Attr() token: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() userId: string;

  @BelongsTo('users') user: User;
}

export default PushDevice;
