import {Form, Formik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {SubmitButton} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import SignOffView from 'components/inspection/sign_offs/SignOffView';
import {Card} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import InspectionSignOff from 'models/inspections/InspectionSignOff';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import useInspectionStore from 'stores/InspectionStore';

interface SignOffFormAttributes {
  inspectionId: string;
  signature: string;
  role: string;
}

const InspectionSignOffForm = () => {
  const [inspection, setInspection] = useInspectionStore((state) => [
    state.inspection,
    state.setInspection,
  ]);

  const {currentUser} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  let shouldSign = false;
  const hasAlreadySigned =
    inspection.inspectionSignOffs.filter(
      (signOff) => signOff.user.id === currentUser.id,
    ).length > 0;

  if (activeAccountRole === 'Landlord') {
    shouldSign = true;
  } else {
    shouldSign =
      inspection.tenancy.headTenantId === null ||
      inspection.tenancy.headTenantId === currentUser.id;
  }

  const handleSignOffSubmit = async (
    values: SignOffFormAttributes,
    actions: any,
  ) => {
    const signOff = new InspectionSignOff(values);

    const result = await signOff.save();

    if (result) {
      toast.success('You have successfully signed off on this inspection!');

      signOff.user = new User({
        id: currentUser.id,
        name: currentUser.name,
        avatar: currentUser.avatar,
        email: currentUser.email,
      });
      signOff.user.isPersisted = true;

      inspection.inspectionSignOffs.push(signOff);

      actions.setSubmitting(false);

      if (inspection.inspectionSignOffs.length >= 2) {
        inspection.status = 'complete';
      }

      setInspection(inspection);
    }
  };

  return (
    <Card title="Awaiting Sign Offs">
      {inspection.inspectionSignOffs.length > 0 && (
        <div className="mt-4 flex justify-start items-center gap-8 flex-wrap">
          {inspection.inspectionSignOffs.map((signOff) => (
            <SignOffView signOff={signOff} key={signOff.id} />
          ))}
        </div>
      )}

      <p className="mt-2">
        Awaiting signatures. This inspection report will be completed once both
        parties have signed.
      </p>

      {shouldSign && !hasAlreadySigned && (
        <div className="mt-2">
          <Formik
            initialValues={{
              inspectionId: inspection.id,
              signature: '',
              role: activeAccountRole.toLowerCase(),
            }}
            validationSchema={Yup.object().shape({
              inspectionId: Yup.string().required().label('Inspection'),
              signature: Yup.string().required().label('Signature').min(10),
              role: Yup.string().required().oneOf(['renter', 'landlord']),
            })}
            onSubmit={handleSignOffSubmit}
            validateOnBlur={false}
            validateOnChange={false}>
            {(formik) => (
              <Form>
                <strong className="block">Sign off on Inspection</strong>

                <SignatureModalInput
                  mode="formik"
                  name="signature"
                  labelProps={{
                    title: 'Signature',
                  }}
                />

                <SubmitButton
                  className="btn-success mt-4"
                  formik={formik}
                  text="Finalise inspection"
                  submittingText="Saving"
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Card>
  );
};

export default InspectionSignOffForm;
