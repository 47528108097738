/*!
 * array-to-sentence | ISC (c) Shinnosuke Watanabe
 * https://github.com/shinnn/array-to-sentence
 */

interface ArrayToSentenceFunction {
  (
    arr: string[],
    options?: {
      separator?: string;
      lastSeparator?: string;
    },
  ): string;
}

const arrayToSentence: ArrayToSentenceFunction = (arr, options) => {
  options = Object.assign(
    {
      separator: ', ',
      lastSeparator: ' and ',
    },
    options,
  );

  if (arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return arr[0];
  }

  return (
    arr.slice(0, -1).join(options.separator) +
    options.lastSeparator +
    arr[arr.length - 1]
  );
};

export default arrayToSentence;
