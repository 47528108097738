import React, {useState} from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import _ from 'lodash';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import TenantRegisterModal from 'components/auth/TenantRegisterModal';
import LoadingView from 'components/common/LoadingView';
import {
  DateField,
  DOBField,
  InputField,
  PseudoBooleanField,
  SelectField,
  SubmitButton,
  TextareaField,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import RenterPetField from 'components/forms_fields/RenterPetsField';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import {Card, Modal} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import RentalApplication from 'models/listings/RentalApplication';
import RentalApplicationApplicant from 'models/listings/RentalApplicationApplicant';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {useAutoAnimate, usePageVisit, useTitle} from 'utilities/hooks';

type Applicant = {
  name: string;
  dateOfBirth: string;
  email: string;
  weeklyIncome: number | null;
  idType: string;
  idNumber: string;
  idExtraInfo: string;
};

const {useModal} = Modal.Imperative;

const NewRentalApplicationPage = () => {
  usePageVisit('NewRentalApplicationPage');
  useTitle('New Application');

  const [numberOfApplicants, setNumberOfApplicants] = useState(0);
  const [applicantAnimateRef] = useAutoAnimate();
  const [petTypeAnimateRef] = useAutoAnimate();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const openModal = useModal();

  const handleAuthenticatedSubmit = async (
    formValues: any,
    actions: FormikHelpers<any>,
  ) => {
    const attrs = {...formValues};
    for (const key in attrs) {
      const val = attrs[key];
      if (val === '') {
        attrs[key] = null;
      }
    }
    const applicants: RentalApplicationApplicant[] =
      attrs.rentalApplicationApplicants.map((applicant: any) => {
        const applicantAttrs = {...applicant};
        for (const key in applicantAttrs) {
          const val = applicantAttrs[key];
          if (val === '') {
            applicantAttrs[key] = null;
          }
        }
        return new RentalApplicationApplicant(applicantAttrs);
      });
    delete attrs.rentalApplicationApplicants;

    const app = new RentalApplication(attrs);
    app.headTenantSignedAt = moment().format();
    app.rentalApplicationApplicants = applicants;

    const result = await app.save({with: 'rentalApplicationApplicants'});

    if (result) {
      localStorage.removeItem('new-rental-application-form');
      toast.success('Your application has been successfully created!');

      const fromListing = localStorage.getItem('listing-application-info');
      if (fromListing) {
        const listing = JSON.parse(fromListing);

        if (listing.id) {
          const join = new ListingRentalApplication();
          join.listingId = listing.id;
          join.rentalApplicationId = app.id;

          await join.save();

          queryClient.invalidateQueries(
            `rental-application-listing-${listing.id}`,
          );
        }

        if (listing.publicId) {
          navigate(`/listings/${listing.publicId}`);
        }
      } else {
        navigate(`/rental-applications/${app.id}`);
      }
    } else {
      for (const key of Object.keys(app.errors)) {
        const message = app.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
      for (const index in applicants) {
        const applicant = applicants[index];
        if (applicant.errors) {
          for (const key of Object.keys(applicant.errors)) {
            const message = applicant.errors[key].fullMessage;
            const formikKey = `rentalApplicationApplicants[${index}].${key}`;
            actions.setFieldError(formikKey, message);
          }
        }
      }
    }

    actions.setSubmitting(false);
  };

  const showRegisterModal = () =>
    openModal(TenantRegisterModal, {
      registerReason: 'Create an account to save and submit your application.',
      loginReason: 'Log in to save and submit your application.',
    });

  const handleUnauthenticatedSubmit = async () => {
    showRegisterModal();
  };

  const {currentUser, userIsLoggedIn} = useAuth();

  const {data, isLoading, error} = useQuery(
    ['current-renter-', currentUser ? '' : currentUser?.id],
    async () => {
      const user = await User.includes('renter_profile').find(currentUser.id);

      return user.data;
    },
    {
      enabled: userIsLoggedIn,
    },
  );

  const handleApplicantSelectChange = (formik: any, e: any) => {
    e.preventDefault();
    const val = Number(e.target.value);

    const applicants = formik.values.rentalApplicationApplicants;

    if (val < applicants.length) {
      const diff = applicants.length - val;
      _.times(diff, () => {
        applicants.pop();
      });
    } else if (val > applicants.length) {
      const diff = val - applicants.length;
      _.times(diff, () => {
        applicants.push({
          name: '',
          dateOfBirth: null,
          email: '',
          weeklyIncome: null,
          idType: '',
          idNumber: '',
          idExtraInfo: '',
        } as Applicant);
      });
    }

    formik.setFieldValue('rentalApplicationApplicants', applicants);
    setNumberOfApplicants(applicants.length);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="New Rental Application">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="New Rental Application">
        <Formik
          initialValues={{
            headTenantName: data ? data.name : '',
            headTenantEmail: data ? data.email : '',
            headTenantDob: data ? data.renterProfile.birthDate : '',
            headTenantPhoneNumber: data ? data.renterProfile.phoneNumber : '',

            idType: '',
            idNumber: '',
            idExtraInfo: '',

            weeklyIncome: null,
            employer: '',
            yearsEmployedAtCurrentPlace: '',
            headTenantEmployedFullTime: 'Unspecified',

            currentAddress: '',
            currentAddressReasonLeaving: '',
            currentAddressStayLength: '',

            aboutUs: '',

            preferredTenancyLength: null,
            preferredTenancyStartDate: null,
            cantCompleteTenancyReasons: '',
            vehiclesCount: null,
            hasPets: 'Unspecified',
            petTypes: {} as Record<string, number>,

            employerReferenceName: '',
            employerReferenceCompanyName: '',
            employerReferenceRelationship: '',
            employerReferenceContactNumber: '',

            landlordReferenceName: '',
            landlordReferenceRelationship: '',
            landlordReferenceContactNumber: '',

            otherReferenceName: '',
            otherReferenceRelationship: '',
            otherReferenceContactNumber: '',

            additionalTotalOccupants: null,
            rentalApplicationApplicants: [] as Applicant[],
            applicantsCanProvideReferences: 'Unspecified',

            anySmokers: 'Unspecified',
            involvedWithTenancyTribunal: 'Unspecified',
            tenancyTribunalInformation: null,
            pastBondDeductions: 'Unspecified',
            oweMoneyToLandlord: 'Unspecified',
            hasMissedRentPayments: 'Unspecified',
            canPayBondAndAdvance: 'Unspecified',
            wouldLikeHelpWithUtilities: 'Unspecified',
            anyBadCredit: 'Unspecified',
            anyCriminalRecords: 'Unspecified',

            headTenantSignature: null,
          }}
          onSubmit={
            userIsLoggedIn
              ? handleAuthenticatedSubmit
              : handleUnauthenticatedSubmit
          }
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            headTenantName: Yup.string().required().min(2).label('Full Name'),
            headTenantEmail: Yup.string().email().required().label('Email'),
            headTenantPhoneNumber: Yup.string()
              .required()
              .nullable()
              .label('Phone Number'),
            headTenantDob: Yup.string()
              .optional()
              .nullable()
              .label('Date Of Birth')
              .test('is-valid-date', 'Please enter a valid date', (value) => {
                if (!value) return true;

                return (
                  moment(value, 'DD/MM/YYYY').isValid() ||
                  moment(value, 'YYYY-MM-DDD').isValid()
                );
              }),
            additionalTotalOccupants: Yup.number()
              .nullable()
              .min(0)
              .max(20)
              .label('Additional Number of total occupants')
              .required(),
            rentalApplicationApplicants: Yup.array()
              .of(
                Yup.object().shape({
                  name: Yup.string().required().label('Name').min(2),
                  email: Yup.string().required().label('Email'),
                  dateOfBirth: Yup.string()
                    .optional()
                    .nullable()
                    .label('Date Of Birth')
                    .test(
                      'is-valid-date',
                      'Please enter a valid date',
                      (value) => {
                        if (!value) return true;

                        return (
                          moment(value, 'DD/MM/YYYY').isValid() ||
                          moment(value, 'YYYY-MM-DDD').isValid()
                        );
                      },
                    ),
                  weeklyIncome: Yup.number()
                    .optional()
                    .nullable()
                    .label('Weekly Income')
                    .min(0),
                }),
              )
              .min(0)
              .max(5)
              .label('Other Tenants'),
            headTenantSignature: Yup.string()
              .required()
              .min(2)
              .nullable()
              .label('Signature'),
            preferredTenancyLength: Yup.number()
              .optional()
              .nullable()
              .label('Preferred Tenancy Length'),
          })}>
          {(formik) => (
            <Form>
              <Card title="New Rental Application" className="mt-2">
                <Paragraph>
                  Apply for rentals easily with Keyhook. Complete your
                  comprehensive application below and use it to apply for any
                  Keyhook listing.
                </Paragraph>

                <Paragraph>
                  Your information will auto save for this device.
                </Paragraph>
                <Paragraph>Fields with a * are required.</Paragraph>
                <Paragraph>
                  For free assistance with your application, book a call with
                  our support team{' '}
                  <Link to="https://www.keyhook.com/book-a-call" openInNewTab>
                    here.
                  </Link>
                </Paragraph>
              </Card>

              <Card title="Your Basic Information">
                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="headTenantName"
                      labelProps={{
                        title: 'Full name',
                        required: true,
                      }}
                      placeholder="Your full legal name"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <DOBField
                      formik={formik}
                      name="headTenantDob"
                      label="Date Of Birth"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="headTenantEmail"
                      labelProps={{
                        title: 'Email address',
                        required: true,
                      }}
                      placeholder="Your email address"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="headTenantPhoneNumber"
                      labelProps={{
                        title: 'Phone number',
                        required: true,
                      }}
                      placeholder="Your phone number"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <SelectField
                      formik={formik}
                      name="idType"
                      labelProps={{
                        title: 'Identification type',
                      }}>
                      <option value="">Choose an identification type</option>
                      <option value="Passport">Passport</option>
                      <option value="Drivers License">Drivers License</option>
                    </SelectField>
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="idNumber"
                      labelProps={{
                        title: 'Identification number',
                      }}
                      placeholder="E.g. LMZ123"
                    />
                  </FormRowItem>
                  {formik.values.idType === 'Drivers License' && (
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="idExtraInfo"
                        labelProps={{
                          title: 'Version number',
                        }}
                        placeholder="E.g. 653"
                      />
                    </FormRowItem>
                  )}
                </FormRow>

                <h2 className="card-title mt-2 mb-4 text-brand-850">
                  Employment Information
                </h2>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="occupation"
                      labelProps={{
                        title: 'Occupation',
                      }}
                      placeholder="E.g. Policy Analyst"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="weeklyIncome"
                      labelProps={{
                        title: 'Weekly income before tax ($)',
                      }}
                      type="number"
                      min={0}
                      max={50000}
                      step={1}
                      placeholder="E.g. 1835.00"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employer"
                      labelProps={{
                        title: 'Employer',
                      }}
                      placeholder="E.g. Ministry of Health"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="yearsEmployedAtCurrentPlace"
                      labelProps={{
                        title: 'Years employed there',
                      }}
                      placeholder="E.g. 1.5  or 0.5"
                      type="number"
                      min={0}
                      max={100}
                      step={0.5}
                    />
                  </FormRowItem>
                </FormRow>

                <div className="w-full lg:w-1/2">
                  <PseudoBooleanField
                    formik={formik}
                    name="headTenantEmployedFullTime"
                    label="Are you currently employed full time?"
                  />
                </div>

                <h2 className="card-title mt-2 mb-4 text-brand-850">
                  Current Living Situation
                </h2>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddress"
                      labelProps={{
                        title: 'What is your current address?',
                      }}
                      placeholder="E.g. 123 Example Street, Wellington, New Zealand"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddressStayLength"
                      labelProps={{
                        title: 'How long have you stayed there?',
                      }}
                      placeholder="E.g. 7 months"
                    />
                  </FormRowItem>
                </FormRow>

                <TextareaField
                  formik={formik}
                  name="currentAddressReasonLeaving"
                  labelProps={{
                    title: 'What is your reason for leaving?',
                    size: 'base',
                  }}
                  placeholder="E.g. Moving out of home."
                />
              </Card>

              <Card title="About Us">
                <Paragraph>
                  Include a short bio about yourself and any other
                  occupants/tenants.
                </Paragraph>

                <TextareaField
                  formik={formik}
                  name="aboutUs"
                  labelProps={{
                    title: 'Bio',
                    size: 'base',
                  }}
                  placeholder="Share a bit about yourself..."
                  rows={5}
                />
              </Card>

              <Card title="Tenancy Information">
                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="preferredTenancyLength"
                      labelProps={{
                        title: 'Preferred tenancy length (months)',
                      }}
                      placeholder="E.g. 12"
                      type="number"
                      min={1}
                      max={1000}
                      step={1}
                    />
                  </FormRowItem>

                  <FormRowItem>
                    <DateField
                      formik={formik}
                      name="preferredTenancyStartDate"
                      label="Preferred Tenancy Start Date"
                      minDate={new Date()}
                      maxDate={new Date('2100-01-01')}
                    />
                  </FormRowItem>
                </FormRow>

                <TextareaField
                  formik={formik}
                  name="cantCompleteTenancyReasons"
                  labelProps={{
                    title:
                      'Are there any reasons you might not be able to complete the length of the tenancy?',
                    size: 'base',
                  }}
                  placeholder="E.g. Visa or Work permit expiring, possible work relocation etc."
                />

                <InputField
                  formik={formik}
                  name="vehiclesCount"
                  labelProps={{
                    title: 'How many vehicles will be parked at the property?',
                  }}
                  type="number"
                  min={0}
                  max={20}
                  step={1}
                  placeholder="E.g. 2"
                />

                <div className="w-full lg:w-1/2">
                  <PseudoBooleanField
                    formik={formik}
                    name="hasPets"
                    label="Do you or any tenant have pets?"
                  />

                  <div ref={petTypeAnimateRef as any}>
                    {Object.entries(formik.values.petTypes).map(
                      ([key, val], index) => (
                        <span className="block" key={index}>
                          {`${key} x ${val}`}
                        </span>
                      ),
                    )}
                  </div>

                  {formik.values.hasPets === 'Yes' ? (
                    <div>
                      <RenterPetField formik={formik} formikKey="petTypes" />
                    </div>
                  ) : null}
                </div>
              </Card>

              <Card title="References">
                <strong>Employer Reference</strong>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employerReferenceName"
                      labelProps={{
                        title: 'Full name',
                      }}
                      placeholder="E.g. David Smith"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employerReferenceCompanyName"
                      labelProps={{
                        title: 'Company name',
                      }}
                      placeholder="E.g. Ministry of Health"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employerReferenceRelationship"
                      labelProps={{
                        title: 'Relationship',
                      }}
                      placeholder="E.g. Team Leader"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employerReferenceContactNumber"
                      labelProps={{
                        title: 'Contact number',
                      }}
                      placeholder="E.g. 022 123 4576"
                    />
                  </FormRowItem>
                </FormRow>

                <strong>Landlord Reference</strong>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="landlordReferenceName"
                      labelProps={{
                        title: 'Full name',
                      }}
                      placeholder="E.g. David Smith"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="landlordReferenceRelationship"
                      labelProps={{
                        title: 'Relationship',
                      }}
                      placeholder="E.g. Landlord"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="landlordReferenceContactNumber"
                      labelProps={{
                        title: 'Contact number',
                      }}
                      placeholder="E.g. 022 123 4576"
                    />
                  </FormRowItem>
                </FormRow>

                <strong>Other Reference</strong>
                <small className="block text-secondary">
                  This should preferably be another landlord.
                </small>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="otherReferenceName"
                      labelProps={{
                        title: 'Full name',
                      }}
                      placeholder="E.g. David Smith"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="otherReferenceRelationship"
                      labelProps={{
                        title: 'Relationship',
                      }}
                      placeholder="E.g. Landlord"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="otherReferenceContactNumber"
                      labelProps={{
                        title: 'Contact number',
                      }}
                      placeholder="E.g. 022 123 4576"
                    />
                  </FormRowItem>
                </FormRow>
              </Card>

              <Card title="Other Tenants">
                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="additionalTotalOccupants"
                      labelProps={{
                        title: 'Additional number of occupants',
                        required: true,
                      }}
                      type="number"
                      placeholder="Excluding yourself, E.g. 4"
                      min={0}
                      max={20}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <span className="label">
                      <span className="label-text">
                        Additional tenants who will sign the lease*
                      </span>
                    </span>
                    <select
                      className="select select-bordered w-full"
                      value={numberOfApplicants}
                      onChange={(e) => handleApplicantSelectChange(formik, e)}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </FormRowItem>
                </FormRow>

                <div ref={applicantAnimateRef as any}>
                  {formik.values.rentalApplicationApplicants.map(
                    (applicant: any, index: number) => (
                      <div className="mt-4 bg-gray-100 p-4" key={index}>
                        <strong>Additional Tenant {index + 1}</strong>
                        <FormRow>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].name`}
                              labelProps={{
                                title: 'Full name',
                                required: true,
                              }}
                              placeholder="E.g. Jane Smith"
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].email`}
                              labelProps={{
                                title: 'Email address',
                                required: true,
                              }}
                              placeholder="E.g. jane.smith@gmail.com"
                              type="email"
                            />
                          </FormRowItem>
                        </FormRow>
                        <FormRow>
                          <FormRowItem>
                            <DOBField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].dateOfBirth`}
                              label="Date Of Birth"
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].weeklyIncome`}
                              labelProps={{
                                title: 'Weekly income before tax ($)',
                              }}
                              placeholder="E.g. 1835.0"
                              type="number"
                              min={0}
                              max={50000}
                              step={1}
                            />
                          </FormRowItem>
                        </FormRow>
                        <FormRow responsive>
                          <FormRowItem>
                            <SelectField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].idType`}
                              labelProps={{
                                title: 'Identification type',
                              }}>
                              <option value="">
                                Choose an identification type
                              </option>
                              <option value="Passport">Passport</option>
                              <option value="Drivers License">
                                Drivers License
                              </option>
                            </SelectField>
                          </FormRowItem>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].idNumber`}
                              labelProps={{
                                title: 'Identification number',
                              }}
                              placeholder="E.g. LMZ123"
                            />
                          </FormRowItem>
                          {applicant.idType === 'Drivers License' && (
                            <FormRowItem>
                              <InputField
                                formik={formik}
                                name={`rentalApplicationApplicants[${index}].idExtraInfo`}
                                labelProps={{
                                  title: 'Version number',
                                }}
                                placeholder="E.g. 653"
                              />
                            </FormRowItem>
                          )}
                        </FormRow>
                      </div>
                    ),
                  )}
                </div>

                <div className="mt-2 w-full lg:w-1/2">
                  <PseudoBooleanField
                    formik={formik}
                    name="applicantsCanProvideReferences"
                    label="Can all of the tenants above provide up to 3 references?"
                  />
                </div>
              </Card>

              <Card title="About the tenants">
                <Paragraph>
                  This information is voluntary and is not required to complete
                  your application. The questions below refer to all proposed
                  tenants included on this application, including yourself. If
                  you're unsure of an answer, leave unspecified.
                </Paragraph>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="anySmokers"
                    label="Are any of the proposed tenants smokers?"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="involvedWithTenancyTribunal"
                    label="Have any of the proposed tenants been involved with the Tenancy Tribunal?"
                  />
                  {formik.values.involvedWithTenancyTribunal === 'Yes' ? (
                    <TextareaField
                      formik={formik}
                      name="tenancyTribunalInformation"
                      labelProps={{
                        title: 'Please provide further information',
                        size: 'base',
                      }}
                    />
                  ) : null}
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="pastBondDeductions"
                    label="Has any proposed tenant ever had money deducted from their bond?"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="oweMoneyToLandlord"
                    label="Does any proposed tenant currently owe money to a landlord or property manager?"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="hasMissedRentPayments"
                    label="Has any proposed tenant ever missed a rent payment?"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="canPayBondAndAdvance"
                    label="Are you able to pay any rent in advance and bond immediately upon being accepted?"
                  />
                </div>

                {/* <div>
                    <PseudoBooleanField
                      formik={formik}
                      name="wouldLikeHelpWithUtilities"
                      label="Would you like help setting up utilities? I.e., internet, power, gas, etc"
                    />
                  </div> */}

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="anyBadCredit"
                    label="Does any proposed tenant have a low credit score? (Below 500)"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="anyCriminalRecords"
                    label="Do any of the proposed tenants have a criminal record?"
                  />
                </div>
              </Card>

              <Card className="mt-4">
                <Paragraph size="sm" secondary>
                  By confirming your application and signing below, you confirm
                  that all the above information is true and accurate and may be
                  used for a credit and reference check.
                </Paragraph>
                <Paragraph size="sm" secondary>
                  I authorise the Landlord/Property Manager to:
                </Paragraph>
                <ul className="ml-4">
                  <li>
                    <Paragraph size="sm" secondary>
                      &gt; collect, retain and use this information for the
                      purpose of assessing my creditworthiness and suitability
                      for the tenancy; and
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph size="sm" secondary>
                      &gt; disclose information about me, whether collected from
                      me directly or from any other source, to any other credit
                      provider or any credit reporting agency for the purposes
                      of providing or obtaining a credit report (which will
                      involve the credit reporting agency providing information
                      about me to the Landlord/Property Manager).{' '}
                    </Paragraph>
                  </li>
                </ul>

                <Paragraph size="sm" secondary>
                  I understand that the credit reporting agency:
                </Paragraph>
                <ul className=" ml-4 mb-4">
                  <li>
                    <Paragraph size="sm" secondary>
                      &gt; may hold my information on their credit reporting
                      database and use it for providing credit reporting
                      services, and they may disclose my information to their
                      subscribers for the purpose of credit checking or debt
                      collection; and
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph size="sm" secondary>
                      &gt; as part of providing a credit report, may check the
                      Ministry of Justice fines database for any overdue fines
                      Under the Privacy Act 2020, you have the right to ask for
                      a copy of all information held about you, and have the
                      right to request the correction of any incorrect
                      information.
                    </Paragraph>
                  </li>
                </ul>
                <SignatureModalInput
                  mode="formik"
                  name="headTenantSignature"
                  labelProps={{
                    title: 'Signature',
                  }}
                />
                <div className="mt-5">
                  <SubmitButton
                    formik={formik}
                    text="Save Application"
                    submittingText="Saving"
                  />

                  {formik.errors && Object.keys(formik.errors).length > 0 && (
                    <div className="mt-2">
                      <p className="text-sm text-red-500">
                        There were issues with your application, please scroll
                        up to see any fields that need to be addressed
                      </p>
                    </div>
                  )}
                </div>
              </Card>

              <PersistFormikValues
                name="new-rental-application-form"
                persistInvalid={true}
              />
            </Form>
          )}
        </Formik>
      </PageWrapper>
    );
  }
};

export default NewRentalApplicationPage;
