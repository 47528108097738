import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {IoPawOutline} from 'react-icons/io5';
import {TbBuildingCommunity} from 'react-icons/tb';

import PetTypeField from 'components/forms_fields/PetTypeField';
import {getPlaceholderValue} from 'constants/listings-search';

import FilterWrapper from './shared/FilterWrapper';
import {FilterProps} from './types';

type PetsAllowedFilterProps = FilterProps;

const PetsAllowedFilter: FunctionComponent<PetsAllowedFilterProps> = ({
  form,
  name,
  inline = false,
}) => {
  const hasModifiedDefault = useMemo(
    () => form.values[name] !== undefined,
    [form, name],
  );

  const value = useMemo(
    () => (!hasModifiedDefault ? getPlaceholderValue(name) : form.values[name]),
    [hasModifiedDefault, form, name],
  );

  const valueLabel = useMemo(() => {
    if (!hasModifiedDefault) {
      return null;
    } else if (value.length === 0) {
      return 'Any';
    } else {
      return `${value.length} selected`;
    }
  }, [hasModifiedDefault, value]);

  const clearFilter = useCallback(() => {
    /**
     * We can't use setFieldValue here, because Formik will delete
     * the entry from the form values if we are setting it to undefined.
     */
    form.setFormikState((current) => ({
      ...current,
      values: {
        ...current.values,
        [name]: undefined,
      },
    }));
  }, [form, name]);

  return (
    <FilterWrapper
      inline={inline}
      label={'Allowed pets'}
      icon={IoPawOutline}
      valueLabel={valueLabel}
      onClearFilter={clearFilter}>
      <PetTypeField name={name} form={form} size="sm" />
    </FilterWrapper>
  );
};

export default PetsAllowedFilter;
