import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import User from 'models/users/User';

@Model()
class ChatMessage extends ApplicationRecord {
  static jsonapiType = 'chat_messages';

  @Attr() content: string;

  @Attr() media: string;
  @Attr({persist: false}) mediaPreview: string;

  @Attr() readMarkers: object;
  @Attr() recipientIds: number[];

  @Attr() chatableId: string;
  @Attr() chatableType: string;

  @Attr() userId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('users') user: User;
}

export default ChatMessage;
