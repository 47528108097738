import {useEffect, useState} from 'react';

import {useLocation, useNavigate} from 'react-router';

import {Alert} from 'components_sb/feedback';

const AfterAddPropertyAlert = () => {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * The alert indicating that a property has just been added is not
   * shown by default.
   */
  const [showAfterAddPropertyAlert, setShowAfterAddPropertyAlert] =
    useState(false);

  /**
   * When the router location state changes, check for the afterAddProperty
   * flag and show the affirmation alert for adding the property if it is present.
   */
  useEffect(() => {
    /**
     * Set the alert to be shown if the afterAddProperty flag is present
     * in the router location state.
     */
    if ((location.state as any)?.afterAddProperty) {
      setShowAfterAddPropertyAlert(true);

      /**
       * Clear the afterAddProperty flag from the router location state.
       */
      navigate(location.pathname, {replace: true});
    }
  }, [location, navigate]);

  return (
    <Alert
      type="success"
      title="Nice work, you've added your property!"
      show={showAfterAddPropertyAlert}
    />
  );
};

export default AfterAddPropertyAlert;
