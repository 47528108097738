import {MinusIcon, PlusIcon} from '@heroicons/react/outline';
import {useFormik, type FormikProps} from 'formik';
import CreatableSelect from 'react-select/creatable';

import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';

interface Chattelable {
  chattels: Record<string, number>;
  courtesyChattels: Record<string, number>;
}

interface Option {
  readonly label: string;
  readonly value: string;
}

interface GroupedOption {
  readonly label: string;
  readonly options: Option[];
}

const chattelsList: GroupedOption[] = [
  {
    label: 'Kitchen',
    options: [
      {label: 'Oven', value: 'Oven'},
      {label: 'Stove', value: 'Stove'},
      {label: 'Oven/Stove', value: 'Oven/Stove'},
      {label: 'Fridge', value: 'Fridge'},
      {label: 'Freezer', value: 'Freezer'},
      {label: 'Fridge/Freezer', value: 'Fridge/Freezer'},
      {label: 'Dishwasher', value: 'Dishwasher'},
      {label: 'Microwave', value: 'Microwave'},
      {label: 'Range hood', value: 'Range hood'},
    ],
  },
  {
    label: 'General',
    options: [
      {label: 'Fixed floor covering', value: 'Fixed floor covering'},
      {label: 'Light fixture', value: 'Light fixture'},
      {label: 'Heat pump', value: 'Heat pump'},
      {label: 'Air conditioning unit', value: 'Air conditioning unit'},
      {label: 'Panel heater', value: 'Panel heater'},
      {label: 'Ventilation system', value: 'Ventilation system'},
      {label: 'Blinds', value: 'Blinds'},
      {label: 'Curtains', value: 'Curtains'},
      {label: 'Drapes', value: 'Drapes'},
      {label: 'TV aerial', value: 'TV aerial'},
      {label: 'Dehumidifier', value: 'Dehumidifier'},
      {label: 'Garage door remote', value: 'Garage door remote'},
      {label: 'Heat transfer system', value: 'Heat transfer system'},
    ],
  },
  {
    label: 'Furniture',
    options: [
      {label: 'Bookcase', value: 'Bookcase'},
      {label: 'Cabinet', value: 'Cabinet'},
      {label: 'Drawers', value: 'Drawers'},
      {label: 'Shelves', value: 'Shelves'},
      {label: 'BBQ', value: 'BBQ'},
    ],
  },
  {
    label: 'Bathroom',
    options: [{label: 'Heated towel rail', value: 'Heated towel rail'}],
  },
  {
    label: 'Laundry',
    options: [
      {label: 'Dryer', value: 'Dryer'},
      {label: 'Washer', value: 'Washer'},
    ],
  },
];

const ChattelsField = ({
  name,
  formik,
  labelProps,
}: {
  formik: ReturnType<typeof useFormik> | FormikProps<Chattelable>;
  name: 'chattels' | 'courtesyChattels';
  labelProps: FieldLabelProps;
}) => {
  const incrementCount = (chattelName: string) => {
    const chattels = formik.values[name];
    const count = chattels[chattelName] || 0;
    chattels[chattelName] = count + 1;

    formik.setFieldValue(name, chattels);
  };

  const decrementCount = (chattelName: string) => {
    const chattels = formik.values[name];
    const count = chattels[chattelName] || 0;
    if (count === 1) {
      delete chattels[chattelName];
    } else {
      chattels[chattelName] = count - 1;
    }

    formik.setFieldValue(name, chattels);
  };

  const addChattel = (chattelName: string) => {
    const chattels = formik.values[name];
    chattels[chattelName] = 1;
    formik.setFieldValue(name, chattels);
  };

  const chattels = formik.values[name] as Record<string, number>;
  return (
    <div>
      {labelProps && <FieldLabel {...labelProps} />}

      {Object.entries(chattels).map(([name, count]) => (
        <div
          className="flex flex-col justify-center px-4 py-2 border-3 rounded-xl cursor-pointer border-gray-300 mb-2"
          key={name}>
          <div className="flex justify-between items-center">
            <p className="truncate">{name}</p>
            <div className="flex items-center justify-between">
              <button
                className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                type="button"
                onClick={() => decrementCount(name)}>
                <MinusIcon className="w-3 h-3" />
              </button>
              <span className="inline-block mx-4">{count}</span>
              <button
                className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                type="button"
                onClick={() => incrementCount(name)}>
                <PlusIcon className="w-3 h-3" />
              </button>
            </div>
          </div>
        </div>
      ))}

      <CreatableSelect
        isMulti={true}
        options={chattelsList}
        defaultValue={[]}
        value={[]}
        onChange={(vals: Option[]) => {
          vals.map((v) => addChattel(v.value));
        }}
        placeholder="Select a chattel or type your own..."
        formatCreateLabel={(inputVal) => <span>Add {inputVal}...</span>}
      />
    </div>
  );
};

export default ChattelsField;
