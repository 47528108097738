import {Form, Formik, type FormikHelpers} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {
  DatetimeField,
  SubmitButton,
  TextareaField,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import OpenHome from 'models/listings/OpenHome';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

interface Vals {
  notes: string;
  startTime: string;
  endTime: string;
}

const EditOpenHomePage = () => {
  usePageVisit('EditOpenHomePage');
  const {propertyId, listingId, id} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {data, isLoading, error} = useQuery(
    `edit-open-home-${id}`,
    async () => {
      const oh = await OpenHome.find(id);

      return oh.data;
    },
  );

  const handleSubmit = async (
    formValues: Vals,
    actions: FormikHelpers<Vals>,
  ) => {
    const oh = data;
    oh.assignAttributes(formValues);

    const result = await oh.save();

    if (result) {
      toast.success('Open home successfully updated!');

      await queryClient.invalidateQueries(`listing-${listingId}-open-homes`);
      navigate(`/properties/${propertyId}/listings/${listingId}/open-homes`);
    } else {
      for (const key in oh.errors) {
        const message = oh.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title={`Edit Open Home`}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title={`Edit Open Home`}>
        <Card title="Schedule open home">
          <Formik
            initialValues={{
              notes: data.notes,
              startTime: data.startTime,
              endTime: data.endTime,
            }}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              notes: Yup.string().optional().label('Notes'),
              startTime: Yup.string().required().label('Start Time'),
              endTime: Yup.string().required().label('End Time'),
            })}>
            {(formik) => (
              <Form>
                <Paragraph>
                  Any attendees will be notified of the updated time.
                </Paragraph>

                <TextareaField
                  formik={formik}
                  name="notes"
                  labelProps={{
                    title: 'Notes',
                    size: 'base',
                  }}
                  placeholder="Include any notes for potential tenants, e.g., how to access the property."
                />

                <FormRow responsive>
                  <FormRowItem>
                    <DatetimeField
                      formik={formik}
                      name="startTime"
                      label="Start Time"
                      minDate={new Date()}
                      maxDate={new Date('2100-01-01')}
                      inputProps={{
                        placeholder: 'Start Time',
                        className: 'input input-bordered',
                      }}
                      onBlur={() => {
                        if (
                          formik.values.startTime &&
                          formik.values.startTime.length > 0
                        ) {
                          formik.setFieldValue(
                            'endTime',
                            moment(formik.values.startTime)
                              .add(1, 'hour')
                              .toISOString(),
                          );
                        }
                      }}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <DatetimeField
                      formik={formik}
                      name="endTime"
                      label="End Time"
                      minDate={
                        formik.values.startTime
                          ? moment(formik.values.startTime).toDate()
                          : new Date()
                      }
                      maxDate={new Date('2100-01-01')}
                      inputProps={{
                        placeholder: 'End Time',
                        className: 'input input-bordered',
                      }}
                    />
                  </FormRowItem>
                </FormRow>
                <SubmitButton
                  formik={formik}
                  text="Update Open Home"
                  submittingText="Updating..."
                  className="mt-10"
                />
              </Form>
            )}
          </Formik>
        </Card>
      </PageWrapper>
    );
  }
};

export default EditOpenHomePage;
