import {Attr, BelongsTo, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';
import User from 'models/users/User';

@Model()
class PrivateViewing extends ApplicationRecord {
  static jsonapiType = 'private_viewings';

  @Attr() listingId: string;

  @Attr() cancelledAt: string;
  @Attr() cancelledBy: string;

  @Attr() confirmedAt: string;

  @Attr() name: string;
  @Attr() email: string;
  @Attr() publicAccessToken: string;

  @Attr() startsAt: string;
  @Attr() endsAt: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('listings') listing: Listing;
  @BelongsTo('users') user: User;
}

export default PrivateViewing;
