import {FunctionComponent, useCallback, useMemo} from 'react';

import clsx from 'clsx';
import {useQuery} from 'react-query';
import {useDebounce} from 'usehooks-ts';

import {SpinningLoader} from 'components_sb/feedback';
import usePopover, {
  PopoverHookProps,
  PopoverHookReturn,
} from 'components_sb/layout/Popover/usePopover';
import SearchLocation from 'models/listings/SearchLocation';

const constructLocationString = (location: SearchLocation): string => {
  const {suburb, city} = location;
  return `${suburb ?? city}${suburb ? `, ${city}` : ''}`;
};

interface SelectableLocationProps {
  location: SearchLocation;
  onSelect: (locationString: string) => void;
}

const SelectableLocation: FunctionComponent<SelectableLocationProps> = ({
  location,
  onSelect,
}) => {
  const locationString = useMemo(
    () => constructLocationString(location),
    [location],
  );

  const onClick = useCallback(
    () => onSelect(locationString),
    [onSelect, locationString],
  );

  return (
    <li
      className={clsx(
        'w-full',
        'cursor-pointer',
        'text-base text-brand-500',
        'transition-color duration-300',
        'p-6',
        'bg-transparent hover:bg-brand-50 active:bg-brand-75',
      )}
      onClick={onClick}>
      <span>{locationString}</span>
    </li>
  );
};

type LocationSearchPopoverHookProps = Pick<
  PopoverHookProps,
  'open' | 'width'
> & {
  onOpenChange: (open: boolean) => void;
  searchText: string;
  onSelect: (locationString: string) => void;
};

type LocationSearchPopoverHookReturn = PopoverHookReturn;

interface LocationSearchPopoverHook {
  (props: LocationSearchPopoverHookProps): LocationSearchPopoverHookReturn;
}

const useLocationSearchPopover: LocationSearchPopoverHook = ({
  /**
   * Props required for the usePopover hook:
   */
  open,

  /**
   * Invoked when the popover open state changes.
   */
  onOpenChange,

  /**
   * Location search suggestions specific props:
   */
  searchText,

  /**
   * Callback invoked upon selecting a locations
   * from the search results.
   */
  onSelect,

  /**
   * The width for the popover.
   */
  width,
}) => {
  /**
   * Rather than debouncing the query itself, we debouce the
   * search text so that the suggestions are only refetched
   * when this debounced value changes.
   */
  const debouncedSearchText = useDebounce(searchText, 250);

  /**
   * Fetch the search suggestions.
   */
  const {
    data: locations,
    isFetched,
    isPreviousData,
  } = useQuery(
    [searchText],
    async () =>
      (
        await SearchLocation.where({location: debouncedSearchText})
          .order({suburb: 'desc'})
          .per(8)
          .all()
      ).data,
    {
      keepPreviousData: true,
      enabled: open && !!debouncedSearchText,
    },
  );

  /**
   * Render the content for the popover.
   */
  const content = useMemo(
    () => (
      <div className="flex text-brand-850">
        {!isFetched && !isPreviousData ? (
          <div className="flex-1 p-6 flex items-center justify-center">
            <SpinningLoader size="base" color="brand" />
          </div>
        ) : (
          <>
            {locations.length > 0 ? (
              <ul className="flex-1">
                {locations.map((location) => (
                  <SelectableLocation
                    key={location.id}
                    location={location}
                    onSelect={onSelect}
                  />
                ))}
              </ul>
            ) : (
              <div className="text-sm opacity-70 p-6">
                No locations found, try broadening your search or check for
                typos
              </div>
            )}
          </>
        )}
      </div>
    ),
    [isFetched, isPreviousData, locations, onSelect],
  );

  /**
   * Directly return the value returned by the usePopover hook.
   */
  return usePopover({
    open,
    onOpenChange,
    placement: 'bottom-start',
    width,
    content,
  });
};
export default useLocationSearchPopover;
