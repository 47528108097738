import {Model, SpraypaintBase} from 'spraypaint';

import {BASE_URL, API_NAMESPACE} from 'globals/app-globals';

interface Idable extends Record<string, any> {
  id: string;
}

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl = BASE_URL;
  static apiNamespace = API_NAMESPACE;

  get asNavigatable(): Idable {
    const attrs = this.attributes as Idable;
    attrs.id = this.id as string;
    return attrs;
  }
}

export default ApplicationRecord;
