import {HTMLProps, useCallback} from 'react';

import {type FormikProps} from 'formik';
import NumericFormat, {NumberFormatValues} from 'react-number-format';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import TrackingService from 'services/TrackingService';

interface InputFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  label: string;
  name: string;
  allowNegative?: boolean;
  helpText?: string;
  onValuesChange?: (values: NumberFormatValues) => void;
}

const MoneyField = ({
  formik,
  label,
  name,
  helpText,
  allowNegative,
  onValuesChange,
  ...rest
}: InputFieldProps) => {
  const errors = errorsFor(formik, name);
  const value = valueFor(formik, name);

  const classN = errors ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }
  rest.className = 'input input-bordered w-full ' + rest.className;

  if (!rest.type) {
    rest.type = 'text';
  }

  const handleBlur = (e: any) => {
    formik.handleBlur(name);

    const v = value as string | undefined | null;
    if (v && v.toString().length > 0) {
      TrackingService.trackEvent(TrackingService.Event.FillField, {
        field: name,
      });
    }

    if (rest.onBlur) {
      rest.onBlur(e);
    }
  };

  const handleValueChange = useCallback(
    (values: NumberFormatValues) => {
      if (onValuesChange) {
        onValuesChange(values);
      } else {
        formik.setFieldValue(name, values.value);
      }
    },
    [onValuesChange, formik, name],
  );

  return (
    <div className="form-control">
      {label && (
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter>{helpText}</HelpTextPresenter>
        </label>
      )}
      <label className="input-group">
        <span>$</span>
        <NumericFormat
          value={rest.value || value}
          thousandSeparator=","
          allowNegative={allowNegative || false}
          onBlur={handleBlur}
          className={rest.className}
          onValueChange={handleValueChange}
          placeholder={rest.placeholder || ''}
          decimalScale={2}
        />
      </label>

      {errors && (
        <span className="text-red-500">
          <small>{errors}</small>
        </span>
      )}
    </div>
  );
};

export default MoneyField;
