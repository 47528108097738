import {
  ChangeEventHandler,
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import clsx from 'clsx';
import {useFormik} from 'formik';
import {TbMapPin} from 'react-icons/tb';

import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import useLocationSearchPopover from 'utilities/useLocationSearchPopover';

import FieldIcon from '../FieldIcon';

interface BasicLocationSearchFieldProps {
  form: ReturnType<typeof useFormik>;
  name: string;
}

const BasicLocationSearchField: FunctionComponent<
  BasicLocationSearchFieldProps
> = ({name, form}) => {
  /**
   * Create a reference to the input element.
   */
  const inputRef = useRef<HTMLInputElement>();

  const [searchText, setSearchText] = useState('');

  /**
   * Only show search suggestions when the input field is focused
   * and the user has entered at least two characters.
   */
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  /**
   * Update the value for the field on the form instance when
   * there are changes to the input field.
   */
  const onChangeSearchText = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({target: {value}}) => {
      setSearchText(value);
      if (value?.length > 2) {
        setPopoverOpen(true);
      }
    },
    [setSearchText],
  );

  const onSelectLocation = useCallback(
    (locationString: string) => {
      /**
       * Update the text in the search input to match the selected location string.
       */
      setSearchText(locationString);
      /**
       * In the basic search form, we only allow one location to be set,
       * so we initialise an array with only the new value.
       */
      form.setFieldValue(name, [locationString]);
      /**
       * Close the popover.
       */
      setPopoverOpen(false);
    },
    [form, name],
  );

  const mdlgBreakpoint = useTailwindBreakpoint('mdlg');

  /**
   * When in the mobile/column layout, match the width of the
   * reference element (the text field), otherwise in desktop
   * layout, fix the width at 500px.
   */
  const popoverWidth = useMemo(
    () => (mdlgBreakpoint ? 500 : 'reference'),
    [mdlgBreakpoint],
  );

  const locationSearchPopover = useLocationSearchPopover({
    open: searchText?.length > 2 && popoverOpen,
    onOpenChange: setPopoverOpen,
    searchText,
    onSelect: onSelectLocation,
    width: popoverWidth,
  });

  const lgBreakpoint = useTailwindBreakpoint('lg');

  return (
    <>
      <div
        className={clsx(
          'flex-1 flex-shrink-0',
          'w-full h-full relative',
          'transition-all ease-out',
          'duration-500',

          /**
           * Column vs bar styles
           */
          'max-w-none mdlg:max-w-sm mdlg:focus-within:max-w-lg',
        )}
        ref={locationSearchPopover.referenceRef}
        {...locationSearchPopover.getReferenceProps()}>
        {/* Field input */}
        <input
          ref={inputRef}
          name={name}
          value={searchText}
          onChange={onChangeSearchText}
          className={clsx(
            'peer',
            'w-full h-full',
            'rounded-none rounded-t-3xl focus:rounded-b-3xl mdlg:rounded-full mdlg:focus:rounded-full',
            'px-6 mdlg:pr-8',
            'pt-10 mdlg:pt-5 xl:pt-6',
            'pb-4 mdlg:pb-0',
            'pl-16 xl:pl-18',
            'border-box',
            'text-ellipsis',
            'bg-transparent hover:bg-brand-50',
            'transition-all duration-300',
            'outline-none border-none',
            'shadow-none focus:shadow-2xl',
            'text-brand-850 font-medium text-base',
          )}
        />

        {/* Field label */}
        <label
          htmlFor={name}
          className={clsx(
            'pointer-events-none',
            'whitespace-nowrap',
            'absolute left-0',
            '-translate-y-1/2 origin-left',
            'transition-all duration-300',
            'font-medium',
            'text-base',
            'ml-16 xl:ml-18',

            ...(searchText
              ? [
                  [
                    'text-brand-850',
                    'text-opacity-70',
                    'top-6 mdlg:top-7 xl:top-8',
                    'scale-90',
                  ],
                ]
              : [
                  'text-brand-500 peer-focus:text-brand-850',
                  'text-opacity-100 peer-focus:text-opacity-70',
                  'top-1/2 peer-focus:top-6 mdlg:peer-focus:top-7 xl:peer-focus:top-8',
                  'scale-100 peer-focus:scale-90',
                ]),
          )}>
          {mdlgBreakpoint && !lgBreakpoint
            ? 'Search locations...'
            : 'Search for a suburb or city...'}
        </label>

        {/* Field icon */}
        <div className="absolute left-6 mdlg:left-8 top-1/2 -translate-y-1/2">
          <FieldIcon icon={TbMapPin} />
        </div>
      </div>

      {/* Popover showing location search suggestions */}
      {locationSearchPopover.element}
    </>
  );
};

export default BasicLocationSearchField;
