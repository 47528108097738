import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';

import BaseSlider from '../shared/BaseSlider';
import SliderTextInput from '../shared/SliderTextInput';

type InputFieldValue = number | string;

type SingleValue = number;

interface SingleValueSliderProps {
  name: string;
  min: number;
  max: number;
  step: number;
  unitPrefix?: string;
  value: SingleValue;
  onChange: (value: SingleValue) => void;
}

const SingleValueSlider: FunctionComponent<SingleValueSliderProps> = ({
  name,
  min,
  max,
  step,
  value,
  onChange,
  unitPrefix,
}) => {
  const [inputFieldValue, setInputFieldValue] =
    useState<InputFieldValue>(value);

  useEffect(() => {
    setInputFieldValue(value);
  }, [value]);

  const onChangeTextInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      /**
       * The value entered cannot be greater in length (number of chars) than
       * the maximum value.
       */
      if (event.target.value.length > max.toString().length) {
        return;
      }

      setInputFieldValue(event.target.value);

      const asInt = parseInt(event.target.value, 10);
      if (isNaN(asInt)) {
        return;
      }

      const newValue = asInt;

      onChange(newValue);
    },
    [onChange, max],
  );

  const handleSliderChange = useCallback(
    (newValue: number[]) => {
      onChange(newValue[0]);
    },
    [onChange],
  );

  const textInput = useMemo(
    () => (
      <SliderTextInput
        name={`${name}`}
        value={inputFieldValue}
        onChange={onChangeTextInput}
        max={max}
        unitPrefix={unitPrefix}
      />
    ),
    [name, inputFieldValue, onChangeTextInput, max, unitPrefix],
  );

  return (
    <div className="@container flex flex-col gap-y-2 w-full">
      <div
        className={clsx(
          'w-full flex flex-row gap-x-4 items-center text-brand-850 mt-4 @xs:mt-0',
        )}>
        <span className="hidden @xs:flex">{textInput}</span>
        <BaseSlider
          mode="single"
          min={min}
          max={max}
          step={step}
          value={[value]}
          onChange={handleSliderChange}
        />
      </div>
      <div className="flex @xs:hidden flex-row justify-between">
        {textInput}
      </div>
    </div>
  );
};

export default SingleValueSlider;
