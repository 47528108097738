import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Inspection from 'models/inspections/Inspection';
import InspectionItem from 'models/inspections/InspectionItem';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import User from 'models/users/User';

@Model()
class InspectionAction extends ApplicationRecord {
  static jsonapiType = 'inspection_actions';

  @Attr() action: string;
  @Attr() actionType: 'landlord' | 'tenant';

  @Attr({persist: false}) amended: boolean;

  @Attr() attachment: string;
  @Attr({persist: false}) attachmentThumbnail: string;
  @Attr({persist: false}) attachmentMimeType: string | null;

  @Attr() resolved: boolean;
  @Attr() resolutionNotes: string;

  @Attr() shouldCreateMaintenanceRequest: boolean;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() inspectionItemId: string;
  @Attr() userId: string;
  @Attr({persist: false}) inspectionId: string;
  @Attr({persist: false}) serviceRequestId: string;

  @BelongsTo('inspection_items') inspectionItem: InspectionItem;
  @BelongsTo('inspections') inspection: Inspection;
  @BelongsTo('users') user: User;
  @BelongsTo('service_requests') serviceRequest: ServiceRequest;
}

export default InspectionAction;
