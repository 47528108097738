import {FunctionComponent, useCallback} from 'react';

import bytes from 'bytes';
import clsx from 'clsx';
import {isMobile} from 'react-device-detect';
import {Accept, FileRejection, useDropzone} from 'react-dropzone';
import {HiOutlineCloudUpload} from 'react-icons/hi';

import {FileItem, FileItemStatus} from './types';
import {generateFileItemId} from './utils/generate-file-item-id';

interface DropzoneProps {
  /**
   * The types of files that are allowed to be uploaded.
   */
  accept: Accept;

  /**
   * The maximum size per file (in bytes) that is allowed to be uploaded
   */
  maxFileSize?: number;

  /**
   * The maximum number of files that are allowed to be uploaded
   */
  maxFiles?: number;

  /**
   * A callback invoked upon adding files.
   */
  onFilesAdded: (newFileItems: FileItem[]) => void;
}

const Dropzone: FunctionComponent<DropzoneProps> = ({
  accept,
  maxFileSize,
  maxFiles,
  onFilesAdded,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      /**
       * Find any files that were rejected due to exceeding the
       * maximum file size.
       */
      const filesTooLarge = rejectedFiles
        .filter(({errors}) =>
          errors.some((error) => error.code === 'file-too-large'),
        )
        .map(({file}) => file);

      // TODO: Handle files with errors other than 'file-too-large'

      onFilesAdded([
        /**
         * Convert the accepted files into file items.
         */
        ...acceptedFiles.map((file) => ({
          id: generateFileItemId(),
          status: FileItemStatus.Uploading,
          mimeType: file.type,
          url: null,
          uploadedFileData: null,
          localFile: file,
        })),
        /**
         * Convert the files that are too large into file items with
         * the FileSizeTooLarge status.
         */
        ...filesTooLarge.map((file) => ({
          id: generateFileItemId(),
          status: FileItemStatus.FileSizeTooLarge,
          mimeType: file.type,
          url: null,
          uploadedFileData: null,
          localFile: file,
        })),
      ]);
    },
    [onFilesAdded],
  );

  const {getRootProps, getInputProps} = useDropzone({
    accept,
    maxSize: maxFileSize,
    maxFiles,
    onDrop,
  });

  return (
    <div
      {...getRootProps({
        className: clsx(
          'group',
          'cursor-pointer',
          'w-full h-52 rounded-2xl',
          'border-2',
          'transition-all duration-300',
          'bg-white',
          'border-brand-200 hover:border-brand-500',
        ),
      })}>
      <input {...getInputProps()} />
      <div
        className={clsx(
          'w-full h-full flex flex-col gap-y-4 items-center justify-center p-6',
          'transition-all duration-300',
          'text-brand-200 group-hover:text-brand-500',
        )}>
        <HiOutlineCloudUpload className="w-8 h-8"></HiOutlineCloudUpload>
        <span
          className={clsx(
            'text-center',
            'user-select-none',
            'flex flex-col items-center gap-y-2',
          )}>
          <span>
            {isMobile
              ? 'Tap here to upload'
              : `Drag ${
                  maxFiles === 1 ? 'a file' : 'files'
                } here or click to upload`}
          </span>
          {maxFileSize && (
            <span className="text-sm italic">{`(max ${
              maxFiles ? `${maxFiles} files` : ''
            }${maxFiles && maxFileSize ? ', ' : ''}${bytes(
              maxFileSize,
            )} per file)`}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default Dropzone;
