import {FunctionComponent} from 'react';

import {BiBath, BiBed, BiCar} from 'react-icons/bi';

import RoomInfoItem from './RoomInfoItem';

interface RoomInfoProps {
  bedrooms?: number;
  bathrooms?: number;
  garages?: number;
}

type RoomInfoComponent = FunctionComponent<RoomInfoProps>;

const RoomInfo: RoomInfoComponent = ({bedrooms, bathrooms, garages}) => (
  <div className="flex flex-row gap-x-6">
    <RoomInfoItem icon={BiBed} quantity={bedrooms} />
    <RoomInfoItem icon={BiBath} quantity={bathrooms} />
    <RoomInfoItem icon={BiCar} quantity={garages} />
  </div>
);

export default RoomInfo;
