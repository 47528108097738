import React, {Fragment, useState} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {type FormikProps} from 'formik';

import ButtonClose from 'components/chifis_theme/Button/ButtonClose';
import Checkbox from 'components/chifis_theme/Checkbox';
import ListingTags from 'utilities/ListingTags';

const ListingWalkthroughMoreTagsField = ({
  formik,
  name,
}: {
  formik: FormikProps<any>;
  name: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const selectedTags = formik.values[name] as string[];

  const addTag = (tag: string) => {
    const tags = [...selectedTags];
    tags.push(tag);
    formik.setFieldValue(name, tags);
  };

  const removeTag = (tag: string) => {
    const tags = [...selectedTags];
    const newTags = tags.filter((t) => t !== tag);
    formik.setFieldValue(name, newTags);
  };

  const renderMoreFilterItem = (
    data: {
      label: string;
      value: string;
    }[],
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.value}
              name={item.label}
              label={item.label}
              defaultChecked={selectedTags.includes(item.value)}
              onChange={(checked) =>
                checked ? addTag(item.value) : removeTag(item.value)
              }
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.value}
              name={item.label}
              label={item.label}
              defaultChecked={selectedTags.includes(item.value)}
              onChange={(checked) =>
                checked ? addTag(item.value) : removeTag(item.value)
              }
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className="flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer mt-8"
        onClick={openModal}>
        <span>
          Select More Tags{' '}
          {selectedTags.length > 0 &&
            '(' + selectedTags.length.toString() + ' Selected)'}
        </span>
      </div>

      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}>
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              className="inline-block py-8 h-screen w-full"
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900">
                    Select Tags
                  </Dialog.Title>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>

                <div className="flex-grow overflow-y-auto">
                  <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                    {ListingTags.map((tagGroup, index) => (
                      <div className="py-7" key={index}>
                        <h3 className="text-xl font-medium">
                          {tagGroup.label}
                        </h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(tagGroup.options)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="py-2 px-4 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={closeModal}>
                    Done
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ListingWalkthroughMoreTagsField;
