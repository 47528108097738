import {useMemo} from 'react';

import {Browser} from '@capacitor/browser';
import {AiOutlineWarning} from '@react-icons/all-files/ai/AiOutlineWarning';
import Decimal from 'decimal.js';
import moment from 'moment';
import {
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
  HiOutlinePlus,
} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import LoadingView from 'components/common/LoadingView';
import DocumentsTable from 'components/document/DocumentsTable';
import CopyButton from 'components/forms_fields/CopyButton';
import InspectionsTable from 'components/inspection/InspectionsTable';
import PageWrapper from 'components/PageWrapper';
import EndOfTenancyCard from 'components/property/renter/detail_page_cards/EndOfTenancyCard';
import ServiceRequestsTable from 'components/service_request/ServiceRequestsTable';
import TenancyMembershipsTable from 'components/tenancy/TenancyMembershipsTable';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import CompanyInfoGlobals from 'globals/CompanyInfoGlobals';
import Inspection from 'models/inspections/Inspection';
import Document from 'models/properties/Document';
import {LeaseDocumentType, TenancyStatus} from 'models/properties/Tenancy';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyMembership from 'models/properties/TenancyMembership';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const TenancyDetailPage = () => {
  useTitle('Tenancy');
  usePageVisit('TenancyDetailPage');

  const {id} = useParams();

  const queryClient = useQueryClient();

  const {currentUser} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {data, isLoading, error, isSuccess} = useQuery(
    `renter-tenancy-detail-${id}`,
    async () => {
      const res = await TenancyMembership.select({
        tenancy_memberships: [
          'active',
          'rent_split',
          'bond_split',
          'bond_paid',
          'bond_reference',
          'rent_reference',
          'created_at',
        ],
        tenancies: [
          'status',
          'bond',
          'start_date',
          'end_date',
          'total_rent',
          'rental_period',
          'head_tenant_id',
          'created_at',
          'weeks_rent_in_advance',
          'managed_rent_enabled',
          'lease_document_type',
        ],
        properties: ['street_address', 'suburb', 'city', 'main_image'],
        users: ['name', 'avatar'],
        documents: [
          'document',
          'document_type',
          'name',
          'created_at',
          'documentable_id',
          'documentable_type',
        ],
      })
        .includes({
          tenancy: [
            {property: ['documents', 'landlord']},
            {tenancy_memberships: 'renter'},
            {tenancy_invites: 'renter'},
            'inspections',
            'documents',
            'active_tenancy_continuation',
            'service_requests',
            'unprocessed_rent_price_changes',
            'end_of_tenancy',
          ],
          not_paid_rent_payments: 'late_payment_notifications',
        })
        .where({tenancy_id: id, renter_id: currentUser.id})
        .first();

      if (res.data) {
        return res.data;
      } else {
        throw new Error('not found');
      }
    },
    {retry: 1},
  );

  const inspections = useMemo(() => {
    if (data && data.tenancy) {
      return data.tenancy.inspections.filter((i) => !!i.finalizedDate);
    } else {
      return [] as Inspection[];
    }
  }, [data]);

  const navigate = useNavigate();

  const showServiceRequest = (requestId: string) => {
    navigate(`service-requests/${requestId}`);
  };

  const showInspection = (inspectionId: string) => {
    navigate(`inspections/${inspectionId}`);
  };

  const showDocument = async (document: Document) => {
    await Browser.open({url: document.document, windowName: document.name});
  };

  const showUnpaidRentHelp = async () => {
    await Browser.open({
      url: 'https://help.keyhook.com/financials/why-does-keyhook-tell-me-my-rent-is-overdue-after-i-ve-paid-it',
    });
  };

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !isSuccess || !currentUser || !data.tenancy
        ? null
        : [
            ...(data.tenancy.headTenantId === currentUser.id
              ? [
                  {
                    label: 'Update rent amounts',
                    linkTo: `/tenancies/${id}/edit-rent-amounts`,
                  },
                ]
              : []),

            /**
             * Give Notice
             */
            ...(data.tenancy.isActive
              ? [
                  {
                    label: 'Give Notice',
                    icon: HiOutlineDocumentText,
                    linkTo: `notices/new`,
                  },
                ]
              : []),

            /**
             * View notices
             * */
            {
              label: 'View notices',
              icon: HiOutlineDocumentDuplicate,
              linkTo: `notices`,
            },

            ...(data.tenancy.headTenantId === currentUser.id
              ? [
                  {
                    label: 'Invite other tenants',
                    linkTo: `/tenancies/${id}/invite-tenants`,
                  },
                ]
              : []),
          ],
    [data, isSuccess, id, currentUser],
  );

  const confirmDeleteTenancyInvite = async (invite: TenancyInvite) => {
    setConfirmationOptions({
      title: 'Delete Tenant Invite',
      message: 'Are you sure you want to delete the invite for this tenant?',
      buttonTitle: 'Delete',
      action: () => deleteTenancyInvite(invite),
      color: 'error',
    });
  };

  const deleteTenancyInvite = async (invite: TenancyInvite) => {
    const result = await invite.destroy();
    if (result) {
      queryClient.invalidateQueries(`renter-tenancy-detail-${id}`);

      toast.success('You have successfully deleted the invitation.');
    }
  };

  const alerts = () => {
    if (data) {
      const count = data.notPaidRentPayments.length;

      if (count > 0) {
        return (
          <div>
            <div className="alert alert-error shadow-lg text-white mb-4">
              <div>
                <AiOutlineWarning className="w-6 h-6" />
                <span>
                  You have <strong>{count} unpaid rent payment(s)</strong>. If
                  you think this is a mistake,{' '}
                  <a
                    className="text-white underline font-semibold"
                    onClick={showUnpaidRentHelp}>
                    click here
                  </a>
                  .
                </span>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    return null;
  };

  const tenancyRenewalCard = () => {
    let renewal: TenancyContinuation;
    if (data.tenancy && data.tenancy.activeTenancyContinuation) {
      renewal = data.tenancy.activeTenancyContinuation;
    }
    if (!renewal || !renewal.landlordRequestedAction) {
      return null;
    }

    const isHeadTenant = data?.tenancy.headTenantId == currentUser?.id;

    const renderHeadTenantInformation = () => {
      if (!renewal.tenantChosenAction) {
        return (
          <div>
            <Paragraph>
              Your landlord has decided to {renewal.landlordActionString},
              please discuss this with the other tenants and then click the
              button below to make a decision.
            </Paragraph>

            <Paragraph>
              If you do not wish to renew this tenancy after the end date,
              choose the "Give Notice" option from the actions dropdown.
            </Paragraph>

            <Link to={`tenancy-renewals/${renewal.id}/edit`}>
              <button className="btn btn-sm btn-neutral">
                Respond to landlord's decision
              </button>
            </Link>
          </div>
        );
      } else if (
        renewal.landlordRequestedAction === renewal.tenantChosenAction
      ) {
        return (
          <div>
            <Paragraph>
              Your landlord has decided to {renewal.landlordActionString}, which
              you have agreed to.
            </Paragraph>

            {renewal.tenantChosenAction === 'start_new_lease' &&
              !renewal.tenantsWhoHaveSigned.includes(currentUser.id) && (
                <div>
                  <Paragraph>
                    You have not yet signed the new lease, click the button
                    below to review.
                  </Paragraph>

                  <Link to={`tenancy-renewals/${renewal.id}/edit`}>
                    <button className="btn btn-sm btn-neutral">
                      Review New Lease
                    </button>
                  </Link>
                </div>
              )}
          </div>
        );
      } else if (
        renewal.landlordRequestedAction !== renewal.tenantChosenAction
      ) {
        return (
          <Paragraph>
            Your landlord has decided to {renewal.landlordActionString}, however
            you chose to go periodic instead.
          </Paragraph>
        );
      }

      return null;
    };

    const renderRegularTenantInformation = () => {
      if (!renewal.tenantChosenAction) {
        return (
          <Paragraph>
            Your landlord has decided to {renewal.landlordActionString}, please
            discuss this with the other tenants, your head tenant will be
            responsible for confirming or rejecting this.
          </Paragraph>
        );
      } else if (
        renewal.landlordRequestedAction === renewal.tenantChosenAction
      ) {
        return (
          <Paragraph>
            Your landlord has decided to {renewal.landlordActionString}, which
            your head tenant has agreed to.
          </Paragraph>
        );
      } else if (
        renewal.landlordRequestedAction !== renewal.tenantChosenAction
      ) {
        return (
          <Paragraph>
            Your landlord has decided to {renewal.landlordActionString}, however
            your head tenant has chosen to go periodic instead.
          </Paragraph>
        );
      }

      return null;
    };

    const signingStatus = () => {
      if (
        !renewal.tenantChosenAction ||
        renewal.landlordRequestedAction === 'go_periodic' ||
        renewal.tenantChosenAction === 'go_periodic'
      ) {
        return null;
      }

      if (renewal.allTenantsSigned) {
        return <Paragraph>All tenants have signed the renewal.</Paragraph>;
      } else {
        return (
          <div>
            <Paragraph>
              We are currently waiting for all tenants to sign this renewal,
              otherwise the tenancy will go periodic after the end date.
            </Paragraph>

            {!renewal.tenantsWhoHaveSigned.includes(currentUser.id) && (
              <div>
                <Paragraph>
                  You have not yet signed the new lease, click the button below
                  to review and sign.
                </Paragraph>

                <Link to={`tenancy-renewals/${renewal.id}/edit`}>
                  <button className="btn btn-sm btn-neutral">
                    Review New Lease
                  </button>
                </Link>
              </div>
            )}
          </div>
        );
      }
    };

    return (
      <Card title="Renewal Options" className="mt-4 border-t-3 border-info">
        {isHeadTenant
          ? renderHeadTenantInformation()
          : renderRegularTenantInformation()}

        {signingStatus()}
      </Card>
    );
  };

  const rentPriceChangesCard = () => {
    if (
      data.tenancy &&
      data.tenancy.unprocessedRentPriceChanges &&
      data.tenancy.unprocessedRentPriceChanges.length > 0
    ) {
      const rentChange = data.tenancy.unprocessedRentPriceChanges[0];
      const newPersonalRent = new Decimal(data.rentSplit)
        .add(new Decimal(rentChange.newRentSplits[currentUser.id]))
        .toString();

      return (
        <Card title="Rent Change">
          <Paragraph>
            Your landlord has given notice that the rent for this tenancy will
            change to {toCurrency(rentChange.newTotalRent)} from{' '}
            {moment(rentChange.effectiveDate).format(DATE_FORMAT)} onwards.
          </Paragraph>

          <Paragraph>
            Your personal rent will change to {toCurrency(newPersonalRent)},
            please make sure to update any recurring payments.
          </Paragraph>

          {rentChange.isTemporaryRentReduction && (
            <Paragraph>
              This is a temporary rent reduction. The rent will change back to{' '}
              {toCurrency(rentChange.oldTotalRent)} on{' '}
              {moment(rentChange.reductionEndDate).format(DATE_FORMAT)}.
            </Paragraph>
          )}
        </Card>
      );
    } else {
      return null;
    }
  };

  const tenancyInvitesCard = () => {
    if (data.tenancy && data.tenancy.tenancyInvites.length > 0) {
      return (
        <Card title="Invited Tenants">
          <Paragraph>
            These other named tenants have been invited to join the tenancy
            either by the landlord or the head tenant.
          </Paragraph>

          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Tenant</th>
                  <th>Sent</th>
                  <th>Signed Up To Keyhook</th>
                  <th>Accepted Invite</th>
                  {data.tenancy.headTenantId === currentUser.id && <th></th>}
                </tr>
              </thead>
              <tbody>
                {data.tenancy.tenancyInvites.map((req) => (
                  <tr key={req.id}>
                    <td>{req.renter ? req.renter.name : req.email}</td>
                    <td>{moment(req.createdAt).fromNow()}</td>
                    <td>{req.renter ? 'Yes' : 'No'}</td>
                    <td>{req.accepted ? 'Yes' : 'No'}</td>
                    {data.tenancy.headTenantId === currentUser.id && (
                      <td>
                        <a
                          className="link link-primary"
                          onClick={() => confirmDeleteTenancyInvite(req)}>
                          Delete
                        </a>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      );
    }

    return null;
  };

  const unpaidBondCard = () => {
    if (
      !data.tenancy.managedRentEnabled ||
      data.tenancy.leaseDocumentType ===
        LeaseDocumentType.TenancyServicesBoardingHouse
    ) {
      return null;
    }
    if (!data?.bondPaid && data.bondSplit > 0.0) {
      return (
        <Card title="Bond Payment" className="mt-4 border-t-3 border-red-500">
          <Paragraph>
            Your bond has not been paid, please make a payment as soon as
            possible with these details for your bond to be registered. It is
            crucial that you enter these details accurately or we will not be
            able to track your payment.
          </Paragraph>

          <Paragraph size="sm" secondary>
            It may take up to 24 hours for your bond payment to clear within our
            system.
          </Paragraph>

          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex-1">
              <label className="label">
                <span className="label-text">Account Name</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={CompanyInfoGlobals.bankAccountName}
                  id="bond-account-name-field"
                />
                <CopyButton id="bond-account-name-field" />
              </div>
            </div>
            <div className="flex-1 lg:ml-2">
              <label className="label">
                <span className="label-text">Account Number</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={CompanyInfoGlobals.bankAccountNumber}
                  id="bond-account-number-field"
                />
                <CopyButton id="bond-account-number-field" />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex-1">
              <label className="label">
                <span className="label-text">Reference (case sensitive)</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={data?.bondReference}
                  id="bond-reference-field"
                />
                <CopyButton id="bond-reference-field" />
              </div>
            </div>
            <div className="flex-1 lg:ml-2">
              <label className="label">
                <span className="label-text">Amount ($)</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={data.bondSplit.toString()}
                  id="bond-amount-field"
                />
                <CopyButton id="bond-amount-field" />
              </div>
            </div>
          </div>

          <div className="mt-2">
            <Paragraph size="sm" secondary>
              You will receive a notification once your payment clears and this
              warning will dissapear.
            </Paragraph>
          </div>
        </Card>
      );
    } else {
      return null;
    }
  };

  const rentCard = () => {
    if (!data.tenancy.managedRentEnabled) {
      return null;
    }

    if (data?.rentSplit) {
      if (new Decimal(data.rentSplit).equals(new Decimal(0.0))) {
        return (
          <Card title="Rent Payments">
            <Paragraph>
              Your share of the rent was set as $0.00, you do not need to worry
              about making any rent payments through Keyhook.
            </Paragraph>
          </Card>
        );
      }

      let rentalPeriod;
      if (data.tenancy.rentalPeriod === 'Weekly') {
        rentalPeriod = '7 Days';
      } else if (data.tenancy.rentalPeriod === 'Fortnightly') {
        rentalPeriod = '14 Days';
      }

      let startDate = moment(data.tenancy.startDate);
      if (data.tenancy.weeksRentInAdvance > 0) {
        startDate = startDate.add(data.tenancy.weeksRentInAdvance, 'weeks');
      }

      return (
        <Card title="Rent Payments" className="mt-4 border-t-3 border-info">
          <Paragraph>
            Please use the details below to set up a recurring bank transfer for
            rent payments. It is crucial that you enter these details accurately
            or we will not be able to track your payments.
          </Paragraph>

          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex-1">
              <label className="label">
                <span className="label-text">Account Name</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={CompanyInfoGlobals.bankAccountName}
                  id="rent-account-name-field"
                />
                <CopyButton id="rent-account-name-field" />
              </div>
            </div>
            <div className="flex-1 lg:ml-2">
              <label className="label">
                <span className="label-text">Account Number</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={CompanyInfoGlobals.bankAccountNumber}
                  id="rent-account-number-field"
                />
                <CopyButton id="rent-account-number-field" />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex-1">
              <label className="label">
                <span className="label-text">Reference (case sensitive)</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={data?.rentReference}
                  id="rent-reference-field"
                />
                <CopyButton id="rent-reference-field" />
              </div>
            </div>
            <div className="flex-1 lg:ml-2">
              <label className="label">
                <span className="label-text">Amount ($)</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={data?.rentSplit.toString()}
                  id="rent-amount-field"
                />
                <CopyButton id="rent-amount-field" />
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex-1">
              <label className="label">
                <span className="label-text">Start Date</span>
              </label>
              <div className="flex flex-start">
                <input
                  type="text"
                  readOnly
                  className="w-full pr-16 input input-bordered rounded-r-none"
                  value={startDate.format(DATE_FORMAT)}
                  id="rent-start-date-field"
                />
                <CopyButton id="rent-start-date-field" />
              </div>
            </div>
            <div className="flex-1 lg:ml-2">
              <label className="label">
                <span className="label-text">Repeat Every</span>
              </label>

              <input
                type="text"
                readOnly
                className="w-full pr-16 input input-bordered"
                value={rentalPeriod}
              />
            </div>
          </div>

          <div className="mt-4">
            <h2 className="card-title mb-0 text-brand-850">
              Unpaid or pending rent payments
            </h2>
            {data.notPaidRentPayments.length === 0 ? (
              <Paragraph>You do not have any unpaid rent payments.</Paragraph>
            ) : (
              data.notPaidRentPayments.map((rp) => (
                <div key={rp.id} className="mt-2">
                  <strong className="block">
                    {moment(rp.dueDate).format(DATE_FORMAT)}
                    {data.tenancy.weeksRentInAdvance > 0 &&
                      moment(rp.dueDate).isBefore(data.tenancy.startDate) && (
                        <span> ( Rent In Advance ) </span>
                      )}
                  </strong>
                  <div>
                    <Paragraph size="sm" secondary>
                      Status: {titleize(removeUnderscores(rp.status))}
                    </Paragraph>
                    {rp.status === 'pending_clearance' ? (
                      <>
                        <Paragraph size="sm">
                          Amount Due: {toCurrency(rp.amountDue)}
                        </Paragraph>
                        <Paragraph size="sm">
                          Amount Received Pending Clearance:{' '}
                          {toCurrency(rp.amountPendingClearance)}
                        </Paragraph>
                      </>
                    ) : (
                      <>
                        <Paragraph size="sm">
                          Amount Due: {toCurrency(rp.amountDue)}
                        </Paragraph>
                        <Paragraph size="sm">
                          Amount Paid: {toCurrency(rp.amountPaid)}
                        </Paragraph>
                      </>
                    )}
                    {rp.latePaymentNotifications.length > 0 && (
                      <Paragraph size="sm">
                        Late Payment Notifications:{' '}
                        {rp.latePaymentNotifications.length}
                      </Paragraph>
                    )}

                    {rp.status === 'pending_clearance' && (
                      <Paragraph size="sm" secondary>
                        It may take up to 24 hours (or longer over the weekend)
                        for a payment to clear.
                      </Paragraph>
                    )}

                    <hr className="bg-gray-200 w-100 mt-1" />
                  </div>
                </div>
              ))
            )}
            <Link to="rent-payments" className="btn btn-neutral btn-block mt-4">
              View All Rent Payments
            </Link>
          </div>
        </Card>
      );
    } else {
      return null;
    }
  };

  const serviceRequestsCard = () => {
    return (
      <Card
        actions={[
          {
            label: 'New maintenance request',
            icon: HiOutlinePlus,
            linkTo: `service-requests/new`,
          },
        ]}
        title="Maintenance Requests"
        className="mt-4">
        <div>
          <ServiceRequestsTable
            requests={data.tenancy.serviceRequests}
            clickHandler={showServiceRequest}
            accountType="Renter"
          />
        </div>
      </Card>
    );
  };

  const inspectionsCard = () => {
    return (
      <Card title="Inspections">
        <div>
          <InspectionsTable
            inspections={inspections}
            clickHandler={showInspection}
            showCount={true}
          />
        </div>
      </Card>
    );
  };

  const documentsCard = () => {
    const documents = data.tenancy.property.documents
      .filter((d) => d.document && d.document.length > 0)
      .concat(data.tenancy.documents);

    return (
      <Card title="Documents">
        <div>
          <DocumentsTable
            documents={documents}
            clickHandler={showDocument}
            showParent={true}
          />
        </div>
      </Card>
    );
  };

  const tenantsCard = () => {
    return (
      <Card className="mt-4">
        <TenancyMembershipsTable
          tenancyMemberships={data.tenancy.tenancyMemberships}
          headTenantId={data.tenancy.headTenantId}
        />
      </Card>
    );
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Tenancy">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const property = data.tenancy.property;
    return (
      <PageWrapper
        title="Tenancy"
        actions={pageActions}
        dropdownTitle="Actions">
        <div className="flex flex-col justify-center mb-4">
          <div className="relative flex flex-col md:flex-row md:space-x-0 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 w-full mx-auto border border-white bg-white">
            <div className="w-full md:w-1/4 bg-white grid place-items-center">
              <img
                src={property.mainImage}
                alt={property.streetAddress}
                className="rounded-xl"
              />
            </div>
            <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
              <div className="flex justify-between item-center">
                <p className="text-gray-500 font-medium md:block">
                  {property.suburb}, {property.city}
                </p>
              </div>
              <h3 className="font-black text-gray-800 md:text-3xl text-xl">
                {property.streetAddress}
              </h3>
              <Paragraph size="sm" secondary>
                Landlord: {property.landlord.name}
              </Paragraph>
            </div>
          </div>
        </div>

        {alerts()}

        <EndOfTenancyCard tenancy={data.tenancy} />

        {tenancyRenewalCard()}
        {rentPriceChangesCard()}

        {unpaidBondCard()}
        {rentCard()}
        {tenancyInvitesCard()}
        {serviceRequestsCard()}
        {inspectionsCard()}
        {documentsCard()}
        {tenantsCard()}
      </PageWrapper>
    );
  }
};

export default TenancyDetailPage;
