import {Card} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import Tags from 'components_sb/typography/Tags/Tags';
import Listing from 'models/listings/Listing';

interface DescriptionCardProps {
  listing: Listing;
}

const DescriptionCard = ({listing}: DescriptionCardProps) => (
  <Card title="Description">
    <Paragraph>{listing.description}</Paragraph>

    {listing.tags.length > 0 && (
      <div>
        <Title level="h3" size="sm">
          Tags
        </Title>
        <div className="mb-2">
          <Tags>{listing.tags}</Tags>
        </div>
      </div>
    )}

    {listing.chattels && Object.keys(listing.chattels).length > 0 && (
      <>
        <Title level="h3" size="sm">
          Chattels
        </Title>
        <div className="flex flex-wrap justify-start !mt-0">
          {Object.entries(listing.chattels).map(([chattelName, count]) => (
            <div
              key={chattelName}
              className="flex justify-center badge bg-indigo-500 border-indigo-500 p-4 mr-4 mt-4 text-white">
              {chattelName} x{count}
            </div>
          ))}
        </div>
      </>
    )}
  </Card>
);

export default DescriptionCard;
