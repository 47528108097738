import {useState} from 'react';

import {type FormikProps} from 'formik';

import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';

const RenterPetField = ({
  formik,
  formikKey = 'petTypes',
}: {
  formik: FormikProps<any>;
  formikKey: string;
}) => {
  const [type, setType] = useState('Cat');
  const [otherType, setOtherType] = useState('');
  const [quantity, setQuantity] = useState(1);
  const disabled = formik.isSubmitting;

  const handleSave = () => {
    const pets = formik.values[formikKey] as Record<string, number>;
    const petType = type === 'Other' ? otherType : type;
    if (petType.length > 0) {
      if (quantity === 0) {
        delete pets[petType];
      } else {
        pets[petType] = quantity;
      }
      formik.setFieldValue(formikKey, pets);
      setOtherType('');
      setQuantity(1);
    }
  };

  return (
    <div className="mt-1">
      <strong>Add a Pet Type</strong>
      <div className="mt-2">
        <FormRow>
          <FormRowItem>
            <div className="flex-1">
              <select
                className="select select-bordered w-full"
                disabled={disabled}
                onChange={(e) => setType(e.target.value)}>
                <option value="Cat">Cat</option>
                <option value="Small Dog">Small Dog</option>
                <option value="Large Dog">Large Dog</option>
                <option value="Fish">Fish</option>
                <option value="Caged Bird">Caged Bird</option>
                <option value="Other">Other</option>
              </select>
              {type === 'Other' ? (
                <div>
                  <span className="label">
                    <span className="label-text">Type</span>
                  </span>
                  <input
                    type="text"
                    className="input input-bordered"
                    value={otherType}
                    onChange={(e) => setOtherType(e.target.value)}
                    placeholder="Specify pet type"
                  />
                </div>
              ) : null}
            </div>
          </FormRowItem>
          <FormRowItem>
            <input
              type="number"
              className="input input-bordered w-full"
              placeholder="Quanity: eg 2"
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
            />
          </FormRowItem>
          <FormRowItem>
            <button
              className="btn btn-neutral"
              type="button"
              onClick={handleSave}>
              Add
            </button>
          </FormRowItem>
        </FormRow>
      </div>
    </div>
  );
};

export default RenterPetField;
