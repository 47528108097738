import {useCallback, useMemo} from 'react';

import camelcaseKeys from 'camelcase-keys';
import {pick} from 'lodash';
import {useCookies} from 'react-cookie';
import {useQuery} from 'react-query';

import {API_URL} from 'globals/app-globals';

/**
 * The name of the cookie that stores the referral code.
 */
const COOKIE_NAME = 'referral_code';

interface FetchReferralCodeDetailsFunction {
  (referralCode: string): Promise<ReferralCodeDetails>;
}

interface Referrer {
  name: string;
  avatar?: string;
}

interface ReferralCodeDetails {
  duration: number;
  period: string;
  referrer: Referrer | null;
}

/**
 * Fetches details for a referral code.
 */
const fetchReferralCodeDetails: FetchReferralCodeDetailsFunction = async (
  referralCode,
) => {
  /**
   * Request details for the referral code from the API.
   */
  const response = await fetch(
    `${API_URL}/promo_codes/${referralCode}.json?include=associate,referring_user`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  /**
   * Handle referral code not found.
   */
  if (response.status === 404) {
    throw new Error('Referral code not found.');
  }

  /**
   * Throw an error if the response failed for any other reason.
   */
  if (!response.ok) {
    throw Error();
  }

  /**
   * Parse the response data.
   */
  const data = camelcaseKeys((await response.json()).data);

  /**
   * Return an error if the referral code is no longer valid.
   */
  if (!data.isStillValid) {
    throw new Error('Referral code is no longer valid.');
  }

  /**
   * Create the format for the response data.
   */
  const formattedData: ReferralCodeDetails = {
    ...pick(data, ['duration', 'period']),
    referrer: null, // When the referrer is null we will show a generic message.
  };

  /**
   * Set the associate details if the referral code belongs to an associate.
   */
  if (data.isAssociateCode && !!data.associate) {
    formattedData.referrer = pick(data.associate, ['name', 'avatar']);
  }

  /**
   * Set the user details as the referrer if the referral code belongs to a Keyhook user.
   */
  if (data.isReferralCode && !!data.referringUser) {
    formattedData.referrer = pick(data.referringUser, ['name', 'avatar']);
  }

  /**
   * Return the formatted data.
   */
  return formattedData;
};

interface ReferralCodeCookieHook {
  (): {
    isSet: boolean;
    isValid: boolean;
    value: string | undefined;
    clear: () => void;
    details: ReferralCodeDetails & {
      areLoaded: boolean;
    };
  };
}

/**
 * Returns useful properties and functions for handling
 * referral codes set in cookies.
 */
const useReferralCodeCookie: ReferralCodeCookieHook = () => {
  const [{[COOKIE_NAME]: referralCodeCookie}, _, removeCookie] = useCookies([
    COOKIE_NAME,
  ]);

  const clear = useCallback(() => {
    removeCookie(COOKIE_NAME);
  }, [removeCookie]);

  /**
   * Whether there is a referral code set in cookies.
   */
  const isSet = useMemo<boolean>(
    () => !!referralCodeCookie,
    [referralCodeCookie],
  );

  /**
   * Fetch the details for the referral code.
   */
  const {isSuccess, data: referralCodeDetails} = useQuery({
    retry: false,
    cacheTime: 0,
    enabled: isSet,
    queryKey: ['referral-code-details'],
    queryFn: () => fetchReferralCodeDetails(referralCodeCookie),
  });

  /**
   * The value to return from the hook.
   */
  const referralCode = useMemo(
    () => ({
      isSet,
      isValid: isSuccess, // If the query function was successful, we know the code is valid
      value: referralCodeCookie,
      clear,
      details: {
        areLoaded: isSuccess,
        ...referralCodeDetails,
      },
    }),
    [isSet, referralCodeCookie, clear, isSuccess, referralCodeDetails],
  );

  return referralCode;
};

export default useReferralCodeCookie;
