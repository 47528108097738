import {ReactNode} from 'react';

import clsx from 'clsx';

const FormRow = ({
  children,
  responsive = false,
}: {
  children: ReactNode;
  responsive?: boolean;
}) => (
  <div
    className={clsx(
      'flex justify-between gap-x-4 gap-y-4 mb-4',
      responsive ? 'flex-col lg:flex-row' : 'flex-row',
    )}>
    {children}
  </div>
);

export default FormRow;
