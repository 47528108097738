import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import InspectionItem from 'models/inspections/InspectionItem';
import User from 'models/users/User';

@Model()
class InspectionItemAttachment extends ApplicationRecord {
  static jsonapiType = 'inspection_item_attachments';

  @Attr() attachment: string;
  @Attr({persist: false}) attachmentThumbnail: string;
  @Attr({persist: false}) attachmentMimeType: string | null;

  @Attr({persist: false}) amended: boolean;

  @Attr() notes: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() inspectionItemId: string;

  @Attr() userId: string;

  @BelongsTo('inspection_items') inspectionItem: InspectionItem;
  @BelongsTo('users') user: User;
}

export default InspectionItemAttachment;
