import {Capacitor} from '@capacitor/core';
import * as Sentry from '@sentry/react';
import chalk from 'chalk';
import {isEmpty} from 'lodash';
import mixpanel from 'mixpanel-browser';

import {TARGET_ENV} from 'globals/app-globals';

import EVENT_CONSTANTS from './events/constants.json';
import EventEnum from './events/enum';

/**
 * Event properties can be of any object shape with strings as the value.
 * (the description key is reserved for the predefined event description)
 */
type EventProperties = {[key: Exclude<string, 'description'>]: any};

/**
 * Service for tracking users and events on multiple analytic platforms
 */
class _TrackingService {
  /**
   *  The supported events that can be tracked.
   */
  static readonly Event = EventEnum;
  readonly Event = _TrackingService.Event;

  /**
   * The instance of Mixpanel to use for tracking functions.
   */
  private mixpanelService: any = null;

  /**
   * Initialse the analytic platform services.
   */
  init = () => {
    if (TARGET_ENV === 'production') {
      // Only Mixpanel needs to be initialised here - other platforms are loaded in index.html
      mixpanel.init('cdaebf3efcb93f33797eef0d4b5733c7', {
        // Track across both keyhook.com and app.keyhook.com
        cross_subdomain_cookie: true,
        // Proxy tracking traffic through backend
        api_host: 'https://proxy.keyhook.com',
      });
      this.mixpanelService = mixpanel;
    }
  };

  /**
   * Set the ID of the user being tracked.
   * @param userId The unique ID of the user.
   */
  setUserId = (userId: string) => {
    if (this.mixpanelService) {
      this.mixpanelService.identify(userId);
    }
  };

  /**
   * Set details for the user being tracked.
   * @param userDetails An object containing properties reflecting the details of the user.
   */
  setUserDetails = (userDetails: Record<string | number | symbol, any>) => {
    if (this.mixpanelService) {
      const people = this.mixpanelService.people;
      for (const [key, val] of Object.entries(userDetails)) {
        people.set(key.toString(), val);
      }
    }
  };

  /**
   * Set the role(s) of the user being tracked.
   * @param userDetails The roles that the user has for their account.
   */
  setUserRoles = (roles: string[]) => {
    if (this.mixpanelService) {
      const people = this.mixpanelService.people;
      people.set('Roles', roles);
    }
  };

  /**
   * Clear the user being tracked.
   */
  clearUser = () => {
    if (this.mixpanelService) {
      this.mixpanelService.reset();
    }
  };

  /**
   * Track an event in Google Analytics.
   * @param eventId The ID for the event in Google Analytics.
   * @param eventProperties Any informative properties relating to the event.
   */
  private trackEventGoogleAnalytics = (
    eventId: string,
    eventProperties: EventProperties,
  ) => {
    try {
      const {gtag} = window as any;
      if (gtag) {
        gtag('event', eventId, eventProperties);
      } else {
        throw new Error(
          'Google tag client does not exist on window (user may have an adblocker installed).',
        );
      }
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag('action', 'track_event_ga');
        Sentry.captureException(error);
      });
    }
  };

  /**
   * Track an event in Meta Event Manager.
   * @param eventId The ID for the event in Meta Event Manager.
   * @param eventProperties Any informative properties relating to the event.
   */
  private trackEventMeta = (
    eventId: string,
    eventProperties: EventProperties,
  ) => {
    try {
      const {fbq} = window as any;
      if (fbq) {
        fbq('trackCustom', eventId, eventProperties);
      } else {
        throw new Error(
          'Meta Pixel client does not exist on window (user may have an adblocker installed).',
        );
      }
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag('action', 'track_event_fb');
        Sentry.captureException(error);
      });
    }
  };

  /**
   * Track an event in AdRoll by adding the user to the custom event audience segment.
   * @param segmentId The ID of the custom event audience segment.
   */
  private trackEventAdRoll = (segmentId: string) => {
    try {
      const {__adroll} = window as any;
      if (__adroll) {
        __adroll.record_user({adroll_segments: segmentId});
      } else {
        throw new Error(
          'Adroll client does not exist on window (user may have an adblocker installed).',
        );
      }
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag('action', 'track_event_adroll');
        Sentry.captureException(error);
      });
    }
  };

  /**
   * Track an event Mixpanel.
   * @param label The label to set as the name for the event in Mixpanel.
   * @param eventProperties Any informative properties relating to the event.
   */
  private trackEventMixpanel = (
    label: string,
    eventProperties: EventProperties,
  ) => {
    if (this.mixpanelService) {
      try {
        // Use the label for the event in the constants definition
        this.mixpanelService.track(label, eventProperties);
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setTag('action', 'track_event_mixpanel');
          Sentry.captureException(error);
        });
      }
    }
  };

  /**
   * Track an event in all supported analytic platforms.
   * @param eventKey The identifier for the event.
   * @param eventProperties Any informative properties relating to the event.
   */
  trackEvent = (
    eventKey: EventEnum,
    eventProperties: EventProperties = {},
    isDemo = false,
  ) => {
    // @ts-ignore
    const eventConstants = EVENT_CONSTANTS[eventKey];

    /**
     * Validate that constants exist for the provided event.
     */
    if (!eventConstants) {
      const errorMessage = `Constants definition for event ${eventKey} could not be found`;
      if (TARGET_ENV === 'production') {
        Sentry.captureMessage(errorMessage);
      } else {
        console.error(errorMessage);
      }
      return;
    }

    const {label, description, ids} = eventConstants;

    /**
     * Only track in production mode, but log event details to the console for other evironments.
     */
    if (TARGET_ENV !== 'production') {
      const message =
        chalk.yellow(chalk.bold('[TRACKING]') + chalk.italic(`\nEvent: `)) +
        label;
      if (!isEmpty(eventProperties)) {
        console.log(message, eventProperties);
      } else {
        console.log(message);
      }
      return;
    }

    /**
     * Don't track events when ghosting.
     */
    if (localStorage.getItem('ghosting') === 'true') {
      return;
    }

    /**
     * Don't track demo instance or Capacitor app events in GA, Meta or AdRoll.
     */
    if (!isDemo && !Capacitor.isNativePlatform()) {
      // Google Analytics
      const {googleAnalyticsEventId} = ids;
      if (googleAnalyticsEventId) {
        this.trackEventGoogleAnalytics(googleAnalyticsEventId, eventProperties);
      }

      // Meta
      const {metaEventId} = ids;
      if (metaEventId) {
        this.trackEventMeta(metaEventId, eventProperties);
      }

      // AdRoll
      const {adRollSegmentId} = ids;
      if (adRollSegmentId) {
        this.trackEventAdRoll(adRollSegmentId);
      }
    }

    // Mixpanel
    this.trackEventMixpanel(
      /**
       * Prefix the label if the event was performed in the demo instance.
       */
      isDemo ? `Demo: ${label}` : label,
      /**
       * Include the predefined event description in the event properties
       * for readability in Mixpanel.
       */
      {
        description,
        ...eventProperties,
      },
    );
  };

  /**
   * Track an event performed in the demo instance of the app.
   * @param eventKey The identifier for the event.
   * @param eventProperties Any informative properties relating to the event.
   */
  trackDemoEvent = (
    eventKey: EventEnum,
    eventProperties: EventProperties = {},
  ) => {
    this.trackEvent(eventKey, eventProperties, true);
  };
}

const TrackingService = new _TrackingService();
export default TrackingService;
