import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {positionValues, Scrollbars} from 'react-custom-scrollbars-2';
import ReactResizeDetector from 'react-resize-detector';

interface ScrollContainerProps {
  /**
   *  The content to render within the scrollable container.
   */
  children: ReactNode;
  /**
   * A unique identifier for the scroll container element.
   */
  id?: string;
  /**
   * Whether to disable the shadows that appear at the top and
   * bottom of the scroll container based on the scroll position.
   */
  disableShadows?: boolean;
}

/**
 *  A container with custom scrollbars for scrollable content.
 */
const ScrollContainer = ({
  children,
  id = undefined,
  disableShadows = false,
}: ScrollContainerProps) => {
  /**
   * State for displaying each of the shadows.
   */
  const [topShadowVisible, setTopShadowVisible] = useState<boolean>(false);
  const [bottomShadowVisible, setBottomShadowVisible] =
    useState<boolean>(false);

  const isCapacitor = useMemo(() => Capacitor.isNativePlatform(), []);

  /**
   * Triggered upon a scroll action starting.
   * DISABLED FOR NOW.
   */
  // const onScrollStart = useKeyboardDismissOnScroll();

  /**
   * Triggered whenever there is an update to the scroll position.
   */
  const onUpdate = useCallback(
    ({scrollTop, top, scrollHeight, clientHeight}: positionValues) => {
      /**
       * Only acknowledge the update if shadows are enabled.
       */
      if (!disableShadows) {
        // Show the top shadow when the scroll position is not at the top.
        if (scrollTop > 0 && !topShadowVisible) {
          setTopShadowVisible(true);
        }
        // Hide the top shadow when the scroll position is at the top.
        else if (scrollTop === 0 && topShadowVisible) {
          setTopShadowVisible(false);
        }

        /**
         * Determine whether the full scroll content is
         * currently visible.
         */
        const fullContentVisible = clientHeight === scrollHeight;

        // Hide the bottom shadow if the full content is visible.
        if (fullContentVisible) {
          if (bottomShadowVisible) {
            setBottomShadowVisible(false);
          }
        } else {
          // Show the bottom shadow if the full content is not
          // visible and the scroll position is not at the bottom.
          if (top < 1 && !bottomShadowVisible) {
            setBottomShadowVisible(true);
          }
          // Hide the bottom shadow if the full content is not
          // visible and the scroll position is not at the bottom.
          else if (top === 1 && bottomShadowVisible) {
            setBottomShadowVisible(false);
          }
        }
      }
    },
    [disableShadows, topShadowVisible, bottomShadowVisible],
  );

  /**
   * Manually invokes the update function for cases where shadows
   * require updating as a result of a non scroll event (i.e. resize
   * or after the initial render).
   */
  const [ref, setRef] = useState(null);
  const handleManualUpdate = useCallback(() => {
    if (ref) {
      onUpdate(ref.getValues());
    }
  }, [ref, onUpdate]);

  /**
   * Invoke the update function when the ref changes.
   */
  useEffect(() => {
    handleManualUpdate();
  }, [ref, handleManualUpdate]);

  return (
    <div
      className={clsx(
        'w-full min-h-0 max-h-full flex',
        isCapacitor ? 'h-full' : 'h-auto relative',
      )}>
      <Scrollbars
        id={id}
        ref={(scrollbars) => setRef(scrollbars)}
        className="flex-1 flex"
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMax="100%"
        // onScrollStart={onScrollStart}
        onUpdate={onUpdate}
        renderTrackVertical={(props) => (
          <div {...props} className="right-0 h-full !w-2" />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className="bg-brand-850 bg-opacity-20 rounded-full" />
        )}
        renderTrackHorizontal={(props) => (
          <div {...props} className="bottom-0 w-full !h-2" />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className="bg-brand-850 bg-opacity-20 rounded-full" />
        )}
        renderView={(props) => <div {...props} className="flex-1" />}>
        {/* Listening to changes to the content size allows updating the
        shadows in response to content changes or window resize without a
        change to the scroll position occurring */}
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={handleManualUpdate}>
          {({targetRef}) => (
            <div ref={targetRef as RefObject<any>}>{children}</div>
          )}
        </ReactResizeDetector>
      </Scrollbars>
      {/* Shadows */}
      {!disableShadows && (
        <>
          {/* Top shadow */}
          <div
            className={clsx(
              'pointer-events-none',
              'absolute z-30 top-0 left-0',
              'w-full h-8 md:h-12',
              'bg-gradient-to-b from-neutral-800 to-transparent',
              'transition-all duration-700 origin-top',
              topShadowVisible
                ? 'scale-y-100 opacity-[15%]'
                : 'scale-y-0 opacity-0',
            )}
          />
          {/* Bottom shadow */}
          <div
            className={clsx(
              'pointer-events-none',
              'absolute z-30 bottom-0 left-0',
              'w-full h-8 md:h-12',
              'bg-gradient-to-t from-neutral-800 to-transparent',
              'transition-all duration-700 origin-bottom',
              bottomShadowVisible
                ? 'scale-y-100 opacity-[15%]'
                : 'scale-y-0 opacity-0',
            )}
          />
        </>
      )}
    </div>
  );
};

export default ScrollContainer;
