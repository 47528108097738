import {FunctionComponent, useCallback, useMemo, useState} from 'react';

import {FaSmoking} from 'react-icons/fa';
import {IoPawOutline} from 'react-icons/io5';
import {TbBuildingCommunity} from 'react-icons/tb';

import BooleanSelect from 'components_sb/input/GridSelect/BooleanSelect';
import {getPlaceholderValue} from 'constants/listings-search';

import FilterWrapper from './shared/FilterWrapper';
import {FilterProps} from './types';

type SmokingAllowedFilterProps = FilterProps;

const SmokingAllowedFilter: FunctionComponent<SmokingAllowedFilterProps> = ({
  form,
  name,
  inline = false,
}) => {
  const hasModifiedDefault = useMemo(
    () => form.values[name] !== undefined,
    [form, name],
  );

  const value = useMemo(
    () => (!hasModifiedDefault ? getPlaceholderValue(name) : form.values[name]),
    [hasModifiedDefault, form, name],
  );

  const onChange = useCallback(
    (value: boolean) => {
      form.setFieldValue(name, value);
    },
    [form, name],
  );

  const valueLabel = useMemo(() => {
    if (!hasModifiedDefault) {
      return null;
    } else if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  }, [hasModifiedDefault, value]);

  const clearFilter = useCallback(() => {
    /**
     * We can't use setFieldValue here, because Formik will delete
     * the entry from the form values if we are setting it to undefined.
     */
    form.setFormikState((current) => ({
      ...current,
      values: {
        ...current.values,
        [name]: undefined,
      },
    }));
  }, [form, name]);

  return (
    <FilterWrapper
      inline={inline}
      label={'Smoking allowed'}
      icon={FaSmoking}
      valueLabel={valueLabel}
      onClearFilter={clearFilter}>
      <BooleanSelect
        preset="yes/no"
        mode="manual"
        name="smokingAllowed"
        value={value}
        onChange={onChange}
      />
    </FilterWrapper>
  );
};

export default SmokingAllowedFilter;
