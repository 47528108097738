import {ChangeEventHandler, FunctionComponent, useMemo} from 'react';

import clsx from 'clsx';

interface SliderTextInputProps {
  name: string;
  label?: string;
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  max: number;
  unitPrefix?: string;
}

const SliderTextInput: FunctionComponent<SliderTextInputProps> = ({
  name,
  label,
  value,
  onChange,
  max,
  unitPrefix,
}) => {
  /**
   * The width of the text input fields should be at least the width of the
   * largest possible value to avoid truncating the text (using the base
   * padding of 8px in addition to an approximation of 14px per character),
   * with a minimum width being the height of the input field (40px). If
   * a unit prefix has been provided, this must also be accounted for in the
   * character count with an additional 4px for padding.
   */
  const inputWidth = useMemo(
    () =>
      Math.max(
        40,
        8 +
          (max.toString().length + (unitPrefix?.length ?? 0)) * 13 +
          (unitPrefix ? 4 : 0),
      ),
    [max, unitPrefix],
  );

  return (
    <div className="flex flex-col items-center justify-center gap-y-2">
      <div className="relative">
        {unitPrefix && (
          <span
            className={clsx(
              'absolute pointer-events-none',
              'left-2 top-1/2 -translate-y-1/2',
              'leading-none mt-px',
            )}>
            {unitPrefix}
          </span>
        )}
        <input
          className={clsx(
            'rounded-lg',
            'h-10',
            'text-center leading-none',
            'transition-all duration-300', // Transition
            'bg-brand-50 hover:bg-brand-75 ', // Background
            'placeholder-brand-800 placeholder-opacity-30', // Placeholder
            'outline-none border-none ring-1 ring-brand-75', // Untouched
            'focus:ring-2 focus:ring-brand-500', // Focus
            'p-1',
            unitPrefix && 'pl-4',
          )}
          style={{
            width: inputWidth,
          }}
          name={name}
          type="number"
          value={value}
          onChange={onChange}
        />
      </div>
      {label && (
        <label htmlFor={`${name}-min`} className="text-sm">
          {label}
        </label>
      )}
    </div>
  );
};

export default SliderTextInput;
