import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import ChatMessage from 'models/ChatMessage';
import User from 'models/users/User';

@Model()
class Conversation extends ApplicationRecord {
  static jsonapiType = 'conversations';

  @Attr() receiverId: string | number;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @HasMany(ChatMessage) chat_messages: ChatMessage;

  @BelongsTo('users') sender: User;
  @BelongsTo('users') receiver: User;
}

export default Conversation;
