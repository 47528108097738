import {ChangeEvent, FocusEvent, forwardRef, useCallback} from 'react';

import clsx from 'clsx';
import {FormikProps, useFormik} from 'formik';

import {type FieldError, InlineError} from 'components_sb/feedback';
import {FieldLabel} from 'components_sb/typography';
import {FieldLabelSize} from 'components_sb/typography/FieldLabel/FieldLabel';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: {
    input: clsx(
      'w-full max-w-full box-border', // Size
      'transition-all duration-300', // Transition
      'bg-brand-50 hover:bg-brand-75 ', // Background
      'placeholder-brand-800 placeholder-opacity-30', // Placeholder
      'outline-none border-none ring-1 ring-brand-75', // Untouched
      'focus:ring-2 focus:ring-brand-500', // Focus
      'text-brand-850', // Text
    ),
  },

  // Classes for disabled state
  disabled: {
    input: clsx(
      // Disable interaction
      'pointer-events-none',
      // Opacity
      'opacity-50',
      // Cursor
      'cursor-not-allowed',
    ),
  },

  // Classes for error state
  error: {
    // These classes are set as important to override default hover/focus states
    input: clsx('!ring-2 !ring-error'),
  },
  // Classes based on size
  size: {
    label: {
      base: clsx(
        // Text
        'text-base',
      ),
      lg: clsx(
        // Text
        'text-base',
      ),
    },
    input: {
      base: clsx(
        // Text
        'text-base',
        // Padding
        'px-4',
        // Height
        'h-12',
        // Roundness
        'rounded-lg',
      ),
      lg: clsx(
        // Text
        'text-base',
        // Padding
        'px-6',
        // Height
        'h-14',
        // Roundness
        'rounded-xl',
      ),
    },
  },
};

interface BaseProps {
  /**
   * The unique name for the input field.
   */
  name: string;
  /**
   * The text shown above the input field.
   */
  label?: string;
  /**
   * The text shown above the input field.
   */
  // TODO: Convert all label related props to use labelProps and forward to FieldLabel
  labelSize?: FieldLabelSize;
  /**
   * Supporting text to help describe the purpose or requirement of the field.
   */
  description?: string;
  /**
   * The strategy for handling field events and states.
   */
  mode: 'manual' | 'formik';
  /**
   * The size of the input field.
   */
  size?: 'base' | 'lg';
  /**
   * The type of text input allowed for the input field.
   */
  type?: 'text' | 'number' | 'email' | 'password';
  /**
   * The maximum number of characters that may be entered.
   */
  maxLength?: number;
  /**
   * The text shown above the input field.
   */
  placeholder?: string;
  /**
   * Whether a value is required for the input field.
   */
  required?: boolean;
  /**
   * Whether the input field is disabled.
   */
  disabled?: boolean;
}

type ConditionalModeProps =
  /**
   * Types when mode is 'manual'
   */
  | {
      mode: 'manual';
      value: string | number;
      onBlur?: () => void;
      onFocus?: () => void;
      onChange: (event: ChangeEvent<any>) => void;
      error?: FieldError;
      form?: never;
    }
  /**
   * Types when mode is 'formik'
   */
  | {
      mode: 'formik';
      value?: never;
      onBlur?: never;
      onFocus?: never;
      onChange?: never;
      error?: never;
      form: ReturnType<typeof useFormik> | FormikProps<any>;
    };

/**
 * Conditional types combined with the base types
 */
type TextFieldProps = BaseProps & ConditionalModeProps;

/**
 * Properties that wrapper components must provide to the base component.
 */
interface WrapperOutput {
  value: string;
  onBlur: (event: FocusEvent<any, Element>) => void;
  onFocus?: (event: FocusEvent<any, Element>) => void;
  onChange: (event: ChangeEvent<any>) => void;
  error?: FieldError;
}

/**
 * The minimum properties that wrapper components must receive from
 * the base component.
 */
interface BaseWrapperInput {
  children: (props: WrapperOutput) => JSX.Element;
}

/**
 * A component that wraps the based component to provide functionality
 * based on particular configuration.
 */
interface WrapperComponent {
  (props: BaseWrapperInput): JSX.Element;
}

/**
 * Wraps the base component to enable manual control.
 */
const ManualWrapper = ({
  children,
  ...props
}: BaseWrapperInput & WrapperOutput) => {
  /**
   * For the manual wrapper, the input must include the base properties
   * but also include the expected output for direct passing
   */
  return children({...props});
};

/**
 * Wraps the base component to enable support for
 * integration with the current Formik context.
 */
const FormikWrapper = ({
  name,
  form,
  children,
  ...props
}: BaseWrapperInput & {
  name: string;
  form: ReturnType<typeof useFormik> | FormikProps<any>;
}) => {
  const {value, onBlur, onChange} = form.getFieldProps(name);
  const {error} = form.getFieldMeta(name);
  return children({
    value,
    onBlur,
    onFocus: undefined,
    onChange,
    error,
    ...props,
  });
};

/**
 * Primary UI component for text-based input.
 */
const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  // These properties are consistent across all wrapper types
  const {
    name,
    label,
    labelSize = 'base',
    description = null,
    mode,
    size = 'base',
    type = 'text',
    maxLength,
    placeholder = '',
    required = false,
    disabled = false,
  } = props;

  /**
   * Select the wrapper based on the mode
   */
  const Wrapper: WrapperComponent = {
    manual: ManualWrapper,
    formik: FormikWrapper,
  }[mode];

  /**
   * Since the 'max' prop does not work when the 'type' prop of
   * the input is set to 'number', this must be enforced manually.
   */
  const enforceMaxLength = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (type === 'number' && !!maxLength) {
        const {target} = event;
        const {value} = target;
        target.value = value.slice(0, maxLength);
      }
    },
    [type, maxLength],
  );

  return (
    <Wrapper {...props}>
      {({value, onBlur, onFocus, onChange, error}: WrapperOutput) => (
        <div className="flex-1 flex flex-col relative">
          {label && (
            <FieldLabel
              title={label}
              description={description}
              htmlFor={name}
              required={required}
              size={labelSize}
            />
          )}
          <input
            ref={ref}
            name={name}
            type={type}
            max={maxLength}
            onInput={type === 'number' ? enforceMaxLength : undefined}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            className={clsx(
              classes.base.input,
              classes.size.input[size],
              !!error && classes.error.input,
              !!disabled && classes.disabled.input,
            )}
          />
          {/* If the error is a boolean, a message does not need to be shown */}
          <InlineError
            error={typeof error === 'string' ? error : null}
            name={name}
          />
        </div>
      )}
    </Wrapper>
  );
});

export default TextField;
