import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useFormik, type FormikProps} from 'formik';
import {HiCheck, HiOutlineEye, HiOutlinePlus} from 'react-icons/hi';
import {useQuery} from 'react-query';

import chattelsIcon from 'assets/img/tenancy_clause_icons/chattels.svg';
import courtesyChattelsIcon from 'assets/img/tenancy_clause_icons/courtesy-chattels.svg';
import customIcon from 'assets/img/tenancy_clause_icons/custom.svg';
import clauseIcon from 'assets/img/tenancy_clause_icons/document.svg';
import maxTenantsIcon from 'assets/img/tenancy_clause_icons/max-tenants.svg';
import petIcon from 'assets/img/tenancy_clause_icons/pets.svg';
import smokingIcon from 'assets/img/tenancy_clause_icons/smoking.svg';
import LeaseClause from 'components/onboard/LeaseClause';
import ClauseLibraryModalOnboardFlow from 'components/tenancy/ClauseLibraryModalOnboardFlow';
import {Button} from 'components_sb/buttons';
import {InlineError} from 'components_sb/feedback';
import {FieldLabel} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import {Clause, ClauseLibrary} from 'models/properties/ClauseLibrary';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {useAutoAnimate} from 'utilities/hooks';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

interface Vals {
  customClauses: {
    clauses: string[];
  };
  clauses: Clause[];
}

interface LeaseConditionsProps {
  clauseLibraryEnabled: boolean;
  property: Property;
  form: ReturnType<typeof useFormik> | FormikProps<Vals>;
}

const LeaseConditionsOnboardFlow = ({
  clauseLibraryEnabled,
  property,
  form,
}: LeaseConditionsProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [customConditionText, setCustomConditionText] = useState('');
  const [modalLibraryOpen, setModalLibraryOpen] = useState(false);

  const [isEditingClause, setIsEditingClause] = useState(false);
  const [editingClauseId, setEditingClauseId] = useState('');
  const [editingClauseText, setEditingClauseText] = useState('');

  const [animateRef] = useAutoAnimate();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {currentUser} = useAuth();

  /**
   * Load the available clauses for the lease.
   */
  const {
    data: clauseLibrary,
    isLoading: loadingClauseLibrary,
    isSuccess: loadedClauseLibrary,
    isError: errorLoadingClauseLibrary,
  } = useQuery(`new-tenancy-clause-library`, async () => {
    const url = API_URL + '/lease_clauses';
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-USER-TOKEN': currentUser.meta.authenticationToken,
        'X-USER-EMAIL': currentUser.email,
      },
    });

    if (response.ok) {
      return (await response.json()) as ClauseLibrary;
    } else {
      throw Error('Failed to load clause library');
    }
  });

  const scrollConditionsToBottom = () => {
    // TODO: Support new onboarding flow layout here
  };

  const saveCondition = useCallback(() => {
    const clauses = form.values.customClauses.clauses;

    if (editingIndex === -1) {
      clauses.push(customConditionText);
    } else {
      clauses[editingIndex] = customConditionText;
    }

    form.setFieldValue('customClauses', {clauses: clauses});

    setCustomConditionText('');
    setIsEditing(false);
    setEditingIndex(-1);

    TrackingService.trackEvent(TrackingService.Event.AddCustomClause);
    scrollConditionsToBottom();
  }, [form, customConditionText, editingIndex]);

  const editAtIndex = useCallback(
    (index: number) => {
      const clauses: string[] = form.values.customClauses.clauses;
      const clause = clauses[index];

      setEditingIndex(index);
      setIsEditing(true);
      setCustomConditionText(clause);
    },
    [form],
  );

  const deleteAtIndex = useCallback(
    (index: number) => {
      const clauses: string[] = form.values.customClauses.clauses;
      clauses.splice(index, 1);
      form.setFieldValue('customClauses', {clauses: clauses});
    },
    [form],
  );

  const confirmDeleteAtIndex = useCallback(
    (index: number) => {
      setConfirmationOptions({
        title: 'Remove Custom Clause',
        message: 'Are you sure you want to remove this Custom Clause?',
        buttonTitle: 'Remove',
        action: () => deleteAtIndex(index),
        color: 'error',
      });
    },
    [deleteAtIndex, setConfirmationOptions],
  );

  const saveLibraryClause = useCallback(() => {
    const clauses = form.values.clauses;
    const index = clauses.findIndex(
      (clause: any) => clause.id === editingClauseId,
    );

    clauses[index].clause = editingClauseText;

    form.setFieldValue('clauses', clauses);

    setEditingClauseText('');
    setIsEditingClause(false);
    setEditingClauseId('');

    TrackingService.trackEvent(TrackingService.Event.EditKeyhookClause);
  }, [form, editingClauseId, editingClauseText]);

  const editLibraryClause = useCallback((clause: Clause) => {
    setEditingClauseText(clause.clause);
    setEditingClauseId(clause.id);
    setIsEditingClause(true);
  }, []);

  const addLibraryClause = useCallback(
    (clause: Clause) => {
      const clauses = form.values.clauses;
      clauses.push(clause);
      form.setFieldValue('clauses', clauses);
      scrollConditionsToBottom();
    },
    [form],
  );

  const removeLibraryClause = useCallback(
    (clause: Clause) => {
      const clauses = form.values.clauses;
      const index = clauses.findIndex((c: any) => c.id === clause.id);
      clauses.splice(index, 1);
      form.setFieldValue('clauses', clauses);
    },
    [form],
  );

  const confirmRemoveLibraryClause = useCallback(
    (clause: Clause) => {
      setConfirmationOptions({
        title: 'Remove Clause',
        message: `Are you sure you want to remove '${clause.title}'`,
        buttonTitle: 'Remove',
        action: () => removeLibraryClause(clause),
        color: 'error',
      });
    },
    [setConfirmationOptions, removeLibraryClause],
  );

  const chosenClauses = useMemo(() => form.values.clauses, [form]);

  const setInitialClauses = useCallback(() => {
    const initialClauses = [] as Clause[];
    for (const [, values] of Object.entries(clauseLibrary)) {
      for (const clause of values) {
        if (clause.checked) {
          initialClauses.push(clause);
        }
      }
    }
    form.setFieldValue('clauses', initialClauses);
  }, [form, clauseLibrary]);

  /**
   * Handle setting the initial clauses on the tenancy
   * once the clause library has loaded.
   */
  useEffect(() => {
    if (loadedClauseLibrary && chosenClauses.length === 0) {
      setInitialClauses();
    }
  }, [loadedClauseLibrary, chosenClauses, setInitialClauses]);

  const addCustomConditionClicked = useCallback(() => {
    setEditingIndex(-1);
    setIsEditing(true);
    scrollConditionsToBottom();
  }, []);

  return (
    <div className="pb-16 w-full">
      <FieldLabel
        size="xl"
        title="Lease Conditions"
        description="These conditions are automatically derived from your property
      information. You can also add extra custom conditions."
      />

      <div ref={animateRef as any}>
        {property.petsAllowed === false && (
          <LeaseClause
            title="Pets"
            clause="Pets are not allowed in this property."
            icon={petIcon}
          />
        )}

        {property.petsAllowed === true &&
          property.allowedPetTypes &&
          property.allowedPetTypes.length > 0 && (
            <LeaseClause
              title="Pets"
              clause={`Only these types of pets are allowed: ${property.allowedPetTypes
                .map((type) => titleize(removeUnderscores(type)))
                .join(', ')}`}
              icon={petIcon}
            />
          )}

        <LeaseClause
          title="Max Tenants"
          clause={`There is a maximum of ${property.maxTenants} people allowed to live in the property.`}
          icon={maxTenantsIcon}
        />

        {property.smokersAllowed === false && (
          <LeaseClause
            title="Smoking"
            clause="Smoking is not allowed anywhere in or on the property."
            icon={smokingIcon}
          />
        )}

        {property.chattels && Object.keys(property.chattels).length > 0 && (
          <LeaseClause
            title="Chattels"
            clause={`These chattels have been provided by the landlord: ${Object.entries(
              property.chattels,
            )
              .map((pair) => `${pair[0]} x${pair[1]}`)
              .join(', ')}`}
            icon={chattelsIcon}
          />
        )}

        {property.courtesyChattels &&
          Object.keys(property.courtesyChattels).length > 0 && (
            <LeaseClause
              title="Courtesy Chattels"
              clause={`These courtesy chattels have been provided by the landlord: ${Object.entries(
                property.courtesyChattels,
              )
                .map((pair) => `${pair[0]} x${pair[1]}`)
                .join(', ')}`}
              icon={courtesyChattelsIcon}
            />
          )}

        {clauseLibraryEnabled &&
          form.values.clauses.map((clause: any) => {
            if (isEditingClause && editingClauseId === clause.id) {
              return (
                <div className="mt-2" key={clause.id}>
                  <label htmlFor="edit-clause" className="label pb-0">
                    <span className="label-text">Edit {clause.title}</span>
                  </label>
                  <p className="text-sm text-secondary ml-1">
                    This must comply with New Zealand tenancy law.{' '}
                    <a
                      href="https://www.tenancy.govt.nz/disputes/breaches-of-the-residential-tenancies-act/unenforceable-clauses-in-tenancy-agreements/"
                      className="link link-primary"
                      rel="noreferrer"
                      target="_blank">
                      Click here
                    </a>{' '}
                    for more information on unenforceable clauses.
                  </p>

                  <textarea
                    id="edit-clause"
                    className="textarea textarea-bordered mt-2 w-full"
                    placeholder="Write your condition here"
                    onChange={(e) => setEditingClauseText(e.target.value)}
                    onBlur={form.handleBlur('clauses')}
                    disabled={form.isSubmitting}
                    value={editingClauseText}
                    rows={5}
                  />

                  <Button
                    category="primary"
                    size="base"
                    format="standard"
                    label="Save clause"
                    icon={HiCheck}
                    mode="manual"
                    onClick={saveLibraryClause}
                  />
                </div>
              );
            } else {
              return (
                <LeaseClause
                  key={clause.id}
                  title={clause.title}
                  onEdit={() => editLibraryClause(clause)}
                  onDelete={() => confirmRemoveLibraryClause(clause)}
                  clause={clause.clause}
                  icon={clauseIcon}
                />
              );
            }
          })}

        {form.values.customClauses.clauses.map((clause: any, index: number) => {
          if (isEditing && editingIndex === index) {
            return (
              <div className="mt-2" key={index}>
                <label htmlFor="custom-condition" className="label pb-0">
                  <span className="label-text">
                    Edit Custom Condition {index + 1}
                  </span>
                </label>
                <p className="text-sm text-secondary ml-1">
                  This must comply with New Zealand tenancy law.{' '}
                  <a
                    href="https://www.tenancy.govt.nz/disputes/breaches-of-the-residential-tenancies-act/unenforceable-clauses-in-tenancy-agreements/"
                    className="link link-primary"
                    rel="noreferrer"
                    target="_blank">
                    Click here
                  </a>{' '}
                  for more information on unenforceable clauses.
                </p>

                <textarea
                  id="custom-condition"
                  className="textarea textarea-bordered mt-2 w-full"
                  placeholder="Write your condition here"
                  onChange={(e) => setCustomConditionText(e.target.value)}
                  onBlur={form.handleBlur('customClauses')}
                  disabled={form.isSubmitting}
                  value={customConditionText}
                />

                <Button
                  category="primary"
                  size="base"
                  format="standard"
                  label="Save custom condition"
                  icon={HiCheck}
                  mode="manual"
                  onClick={saveCondition}
                />
              </div>
            );
          } else {
            return (
              <LeaseClause
                key={index}
                title={`Custom Clause ${index + 1}`}
                onEdit={() => editAtIndex(index)}
                onDelete={() => confirmDeleteAtIndex(index)}
                clause={clause}
                icon={customIcon}
              />
            );
          }
        })}
      </div>

      {loadedClauseLibrary && (
        <ClauseLibraryModalOnboardFlow
          clauseLibrary={clauseLibrary}
          modalIsOpen={modalLibraryOpen}
          setModalIsOpen={setModalLibraryOpen}
          addClause={addLibraryClause}
          removeClause={removeLibraryClause}
          chosenClauses={form.values.clauses}
        />
      )}

      {isEditing && editingIndex === -1 && (
        <div className="mt-2">
          <label htmlFor="custom-condition" className="label pb-0">
            <span className="label-text">Custom Condition</span>
          </label>
          <p className="text-sm text-secondary ml-1">
            This must comply with New Zealand tenancy law.{' '}
            <a
              href="https://www.tenancy.govt.nz/disputes/breaches-of-the-residential-tenancies-act/unenforceable-clauses-in-tenancy-agreements/"
              className="link link-primary"
              rel="noreferrer"
              target="_blank">
              Click here
            </a>{' '}
            for more information on unenforceable clauses.
          </p>

          <textarea
            id="custom-condition"
            className="textarea textarea-bordered mt-2 w-full"
            placeholder="Write your condition here"
            onChange={(e) => setCustomConditionText(e.target.value)}
            onBlur={form.handleBlur('customClauses')}
            disabled={form.isSubmitting}
            value={customConditionText}
          />

          <Button
            category="primary"
            size="base"
            format="standard"
            label="Save custom condition"
            icon={HiCheck}
            mode="manual"
            onClick={saveCondition}
          />
        </div>
      )}

      <div className="mt-8 flex justify-between items-center gap-4">
        <Button
          category="secondary"
          size="base"
          format="standard"
          label="Add custom condition"
          icon={HiOutlinePlus}
          mode="manual"
          onClick={addCustomConditionClicked}
        />
        {clauseLibraryEnabled && (
          <Button
            category="secondary"
            size="base"
            format="standard"
            label="Show clause library"
            icon={HiOutlineEye}
            mode="manual"
            loading={loadingClauseLibrary}
            disabled={errorLoadingClauseLibrary}
            onClick={() => setModalLibraryOpen(true)}
          />
        )}
      </div>

      <div className="mt-4">
        <InlineError
          error={
            clauseLibraryEnabled && errorLoadingClauseLibrary
              ? 'There was an issue loading our clause library, please try reloading the page or contact us for support.'
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default LeaseConditionsOnboardFlow;
