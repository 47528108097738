import {useMemo} from 'react';

import {Transition} from '@headlessui/react';
import clsx from 'clsx';

interface NumberBadgeProps {
  /**
   * The number to show within the badge, excluding the property
   * indicates that the badge should not be shown.
   */
  count?: number;
  /**
   * How the button should be positioned in regard to the layout.
   */
  positioning?: 'corner' | 'inline';
}

/**
 * A small badge containing a numeric value to indicate a quantity.
 */
const NumberBadge = ({count = 0, positioning = 'corner'}: NumberBadgeProps) => {
  /**
   * Evaluate the Tailwind class to use for the width
   * based on the count.
   */
  const widthClass = useMemo(() => {
    if (count > 99) {
      return 'w-8';
    }
    return count > 9 ? 'w-6' : 'w-5';
  }, [count]);

  return (
    <Transition
      show={!!count}
      as="span"
      className={clsx(
        widthClass,
        positioning === 'corner' && 'absolute left-full bottom-full -mb-2',
        positioning === 'inline' && 'mx-1',
        'h-5 rounded-full bg-red-500',
        'text-white text-xs font-medium leading-none',
        'flex items-center justify-center',
        'transform transition-all duration-300',
      )}
      enterFrom="scale-0"
      enterTo="scale-100"
      leaveFrom="scale-100"
      leaveTo="scale-0">
      {count > 99 ? '99+' : count}
    </Transition>
  );
};

export default NumberBadge;
