import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Property from 'models/properties/Property';
@Model()
class AccountingRecurringRecord extends ApplicationRecord {
  static jsonapiType = 'accounting_recurring_records';

  @Attr() subtotalAmount: string;
  @Attr() gstAmount: string;
  @Attr() totalAmount: string;

  @Attr() nextTriggerDate: string;

  @Attr() description: string;

  @Attr() periodNumber: number;
  @Attr() periodType: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() propertyId: string;

  @BelongsTo('properties') property: Property;
}

export default AccountingRecurringRecord;
