import {Outlet, Route} from 'react-router';

import DemoInspectionDetailPage from 'pages/demo/in_app/DemoInspectionDetailPage';
import DemoPropertyDetailPage from 'pages/demo/in_app/DemoPropertyDetailPage';
import DemoServiceRequestDetailPage from 'pages/demo/in_app/DemoServiceRequestDetailPage';

const DemoRoutes = [
  <Route path="demo" element={<Outlet />} key={1}>
    <Route path="properties/:propertyId">
      <Route path="service-requests">
        <Route path=":id" element={<DemoServiceRequestDetailPage />} />
      </Route>

      <Route path="inspections">
        <Route path=":id" element={<DemoInspectionDetailPage />} />
      </Route>

      <Route path="" element={<DemoPropertyDetailPage />} />
    </Route>
  </Route>,
];

export default DemoRoutes;
