import {ReactNode} from 'react';

import clsx from 'clsx';

interface ParagraphProps {
  /**
   *  The text for the paragraph.
   */
  children: string | ReactNode;
  /**
   * The size of the paragraph.
   */
  size?: 'xs' | 'sm' | 'base' | 'lg';
  /**
   * Decreases the emphasis of the paragraph visually.
   */
  secondary?: boolean;
  /**
   * Disables the standard paragraph margin.
   */
  disableMargin?: boolean;
  /**
   * Whether to display the paragraph as an inline element
   * as opposed to a block element.
   */
  inline?: boolean;
}

/**
 *  Standard body text.
 */
const Paragraph = ({
  children,
  size = 'base',
  secondary = false,
  disableMargin = false,
  inline = false,
}: ParagraphProps) => (
  <p
    className={clsx(
      'text-brand-850',
      'font-normal whitespace-pre-line',
      'leading-loose',
      size === 'xs' && 'text-xs',
      size === 'sm' && 'text-sm',
      size === 'base' && 'text-base',
      size === 'lg' && 'text-lg',
      secondary ? 'text-opacity-70' : 'text-opacity-100',
      !disableMargin && 'mb-2',
      inline ? 'inline' : 'block',
    )}>
    {children}
  </p>
);

export default Paragraph;
