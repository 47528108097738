import React, {useState} from 'react';

import {KeyIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {SubmitButton} from 'components/forms_fields';
import ChattelsField from 'components/forms_fields/ChattelsField';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const ChattelsAction = ({
  property,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    property.assignAttributes(formValues);
    property.setProfileStepAsCompleted(storeKey);

    const result = await property.save();

    if (result) {
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'detail-page'}],
        property,
      );
      toast.success('Property successfully updated!');
      setModalVisible(false);
    } else {
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const title = 'Property Chattels';
  const subtitle = 'Add the chattels for this property.';
  const iconBgColor = 'bg-violet-300';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={KeyIcon}
        iconBgColor={iconBgColor}
        onClick={() => setModalVisible(true)}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={KeyIcon}
        bgColor={iconBgColor}
        form={
          <Formik
            initialValues={{
              chattels: property.chattels || {},
              courtesyChattels: property.courtesyChattels || {},
            }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              chattels: Yup.object().required().label('Chattels'),
              courtesyChattels: Yup.object()
                .optional()
                .label('Courtesy Chattels'),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col justify-center grow space-y-3">
                    <div>
                      <ChattelsField
                        labelProps={{title: 'Chattels', size: 'base'}}
                        formik={formik}
                        name="chattels"
                      />
                    </div>

                    <div>
                      <ChattelsField
                        labelProps={{
                          title: 'Courtesy Chattels',
                          size: 'base',
                          helpText:
                            'Courtesy chattels are provided by the landlord under the understanding that the landlord is not responsible for their maintenance. An example of a courtesy chattel is a BBQ a landlord has left on their rental property for a tenant to use throughout their tenancy.',
                        }}
                        formik={formik}
                        name="courtesyChattels"
                      />
                    </div>
                  </div>

                  <SubmitButton
                    formik={formik}
                    text="Save"
                    submittingText="Saving"
                    className={`!rounded-full ${iconBgColor} border-none mt-6`}
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default ChattelsAction;
