import {Formik} from 'formik';
import moment from 'moment';

import {InputField, TextareaField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import PseudoBooleanDisplayView from 'components/rental_application/PseudoBooleanDisplayView';
import {Card} from 'components_sb/layout';
import RentalApplication from 'models/listings/RentalApplication';

const RentalApplicationDetailView = ({
  rentalApplication,
  tag = '',
}: {
  rentalApplication: RentalApplication;
  tag?: '' | 'shortlisted' | 'excluded';
}) => {
  return (
    <div>
      <Card className="mt-4">
        <div className="flex justify-start items-center gap-2">
          <div className="flex-1">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold">
                {rentalApplication.headTenantName}
              </h3>

              {tag === 'shortlisted' && (
                <div className="badge badge-success">Shortlisted</div>
              )}

              {tag === 'excluded' && (
                <div className="badge badge-error">Excluded</div>
              )}
            </div>
            {rentalApplication.headTenantDob && (
              <p className="text-secondary">
                DOB:{' '}
                {moment(rentalApplication.headTenantDob).format('DD MMM YYYY')}
              </p>
            )}
          </div>
        </div>
      </Card>

      <Formik
        initialValues={rentalApplication}
        onSubmit={() => {
          'noop';
        }}
        validateOnBlur={false}
        validateOnChange={false}>
        {(formik) => (
          <div>
            <Card title="Basic Information">
              <FormRow>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="headTenantEmail"
                    labelProps={{
                      title: 'Email address',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="headTenantPhoneNumber"
                    labelProps={{
                      title: 'Phone number',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="idType"
                    labelProps={{
                      title: 'Identification type',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="idNumber"
                    labelProps={{
                      title: 'Identification number',
                    }}
                    placeholder="E.g. LMZ123"
                    disabled
                  />
                </FormRowItem>
                {formik.values.idType === 'Drivers License' && (
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="idExtraInfo"
                      labelProps={{
                        title: 'Version number',
                      }}
                      placeholder="E.g. 653"
                      disabled
                    />
                  </FormRowItem>
                )}
              </FormRow>

              <h2 className="card-title mb-4 text-brand-850 flex justify-between">
                Employment Information
              </h2>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="occupation"
                    labelProps={{
                      title: 'Occupation',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="weeklyIncome"
                    labelProps={{
                      title: 'Weekly income before tax ($)',
                    }}
                    type="number"
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="employer"
                    labelProps={{
                      title: 'Employer',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="yearsEmployedAtCurrentPlace"
                    labelProps={{
                      title: 'Years employed there',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <div className="w-full lg:w-1/2">
                <PseudoBooleanDisplayView
                  label="Are you currently employed full time?"
                  value={formik.values.headTenantEmployedFullTime}
                />
              </div>

              <h2 className="card-title mt-2 mb-4 text-brand-850 flex justify-between">
                Current Living Situation
              </h2>
              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="currentAddress"
                    labelProps={{
                      title: 'What is your current address?',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="currentAddressStayLength"
                    labelProps={{
                      title: 'How long have you stayed there?',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <TextareaField
                formik={formik}
                name="currentAddressReasonLeaving"
                labelProps={{
                  title: 'What is your reason for leaving?',
                  size: 'base',
                }}
                disabled
              />
            </Card>

            <Card title="About Us">
              <p className="whitespace-pre-line">{rentalApplication.aboutUs}</p>
            </Card>

            <Card title="Tenancy Information">
              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="preferredTenancyLength"
                    labelProps={{
                      title: 'Preferred tenancy length',
                    }}
                    value={
                      rentalApplication.preferredTenancyLength
                        ? `${rentalApplication.preferredTenancyLength} Months`
                        : ''
                    }
                    disabled
                  />
                </FormRowItem>

                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="preferredTenancyStartDate"
                    labelProps={{
                      title: 'Preferred tenancy start date',
                    }}
                    disabled
                    value={
                      rentalApplication.preferredTenancyStartDate
                        ? moment(
                            rentalApplication.preferredTenancyStartDate,
                          ).format('DD MMMM YYYY')
                        : ''
                    }
                  />
                </FormRowItem>
              </FormRow>

              <TextareaField
                formik={formik}
                name="cantCompleteTenancyReasons"
                labelProps={{
                  title:
                    'Are there any reasons you might not be able to complete the length of the tenancy?',
                  size: 'base',
                }}
                value={rentalApplication.cantCompleteTenancyReasons || ''}
                disabled
              />

              <InputField
                formik={formik}
                name="vehiclesCount"
                labelProps={{
                  title: 'How many vehicles will be parked at the property?',
                }}
                disabled
              />

              <PseudoBooleanDisplayView
                label="Does any tenant have pets?"
                value={formik.values.hasPets}
              />

              {Object.keys(rentalApplication.petTypes).length > 0 && (
                <div className="ml-2">
                  <strong className="text-md">Pets</strong>
                  <ul className="list-disc ml-4">
                    {Object.entries(formik.values.petTypes).map(
                      ([key, val], index) => (
                        <li className="text-md" key={index}>
                          {key} x {val}
                        </li>
                      ),
                    )}
                  </ul>
                </div>
              )}
            </Card>

            <Card title="References">
              <strong>Employer Reference</strong>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="employerReferenceName"
                    labelProps={{
                      title: 'Full name',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="employerReferenceCompanyName"
                    labelProps={{
                      title: 'Company name',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <FormRow>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="employerReferenceRelationship"
                    labelProps={{
                      title: 'Relationship',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="employerReferenceContactNumber"
                    labelProps={{
                      title: 'Contact number',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <strong className="block mt-2">Landlord Reference</strong>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="landlordReferenceName"
                    labelProps={{
                      title: 'Full name',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="landlordReferenceRelationship"
                    labelProps={{
                      title: 'Relationship',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="landlordReferenceContactNumber"
                    labelProps={{
                      title: 'Contact number',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <strong className="block mt-2">Other Reference</strong>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="otherReferenceName"
                    labelProps={{
                      title: 'Full name',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="otherReferenceRelationship"
                    labelProps={{
                      title: 'Relationship',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="otherReferenceContactNumber"
                    labelProps={{
                      title: 'Contact number',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>
            </Card>

            <Card title="Other Tenants">
              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="additionalTotalOccupants"
                    labelProps={{
                      title: 'Additional number of occupants',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <span className="label">
                    <span className="label-text">
                      Additional tenants who will sign the lease
                    </span>
                  </span>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    disabled
                    value={rentalApplication.rentalApplicationApplicants.length}
                  />
                </FormRowItem>
              </FormRow>

              <div>
                {formik.values.rentalApplicationApplicants.map(
                  (applicant, index) => (
                    <div className="mt-4 bg-gray-100 p-4" key={index}>
                      <strong>Additional Tenant {index + 1}</strong>
                      <FormRow responsive>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].name`}
                            labelProps={{
                              title: 'Full name',
                            }}
                            disabled
                          />
                        </FormRowItem>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].email`}
                            labelProps={{
                              title: 'Email address',
                            }}
                            type="email"
                            disabled
                          />
                        </FormRowItem>
                      </FormRow>
                      <FormRow responsive>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].dateOfBirth`}
                            labelProps={{
                              title: 'Date of birth',
                            }}
                            value={
                              rentalApplication.rentalApplicationApplicants[
                                index
                              ].dateOfBirth
                                ? moment(
                                    rentalApplication
                                      .rentalApplicationApplicants[index]
                                      .dateOfBirth,
                                  ).format('DD MMMM YYYY')
                                : null
                            }
                            disabled
                          />
                        </FormRowItem>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].weeklyIncome`}
                            labelProps={{
                              title: 'Weekly income before tax ($)',
                            }}
                            disabled
                          />
                        </FormRowItem>
                      </FormRow>
                      <FormRow responsive>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].idType`}
                            labelProps={{
                              title: 'Identification type',
                            }}
                            disabled
                          />
                        </FormRowItem>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].idNumber`}
                            labelProps={{
                              title: 'Identification number',
                            }}
                            placeholder="E.g. LMZ123"
                            disabled
                          />
                        </FormRowItem>
                        {applicant.idType === 'Drivers License' && (
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].idExtraInfo`}
                              labelProps={{
                                title: 'Version number',
                              }}
                              placeholder="E.g. 653"
                              disabled
                            />
                          </FormRowItem>
                        )}
                      </FormRow>
                    </div>
                  ),
                )}
              </div>

              <div className="mt-2 w-full lg:w-1/2">
                <PseudoBooleanDisplayView
                  value={formik.values.applicantsCanProvideReferences}
                  label="Can all of the tenants above provide up to 3 references?"
                />
              </div>
            </Card>

            <Card title="About the tenants">
              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.anySmokers}
                  label="Are any of the proposed tenants smokers?"
                />
              </div>

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.involvedWithTenancyTribunal}
                  label="Have any of the proposed tenants been involved with the Tenancy Tribunal?"
                />
                {formik.values.involvedWithTenancyTribunal === 'Yes' ? (
                  <TextareaField
                    formik={formik}
                    name="tenancyTribunalInformation"
                    labelProps={{title: 'Further information', size: 'base'}}
                    disabled
                  />
                ) : null}
              </div>

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.pastBondDeductions}
                  label="Has any proposed tenant ever had money deducted from their bond?"
                />
              </div>

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.oweMoneyToLandlord}
                  label="Does any proposed tenant currently owe money to a landlord or property manager?"
                />
              </div>

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.hasMissedRentPayments}
                  label="Has any proposed tenant ever missed a rent payment?"
                />
              </div>

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.canPayBondAndAdvance}
                  label="Are you able to pay any rent in advance and bond immediately upon being accepted?"
                />
              </div>

              {/* <div>
                  <PseudoBooleanDisplayView
                    value={formik.values.wouldLikeHelpWithUtilities}
                    label="Would you like help setting up utilities? I.e., internet, power, gas, etc"
                  />
                </div> */}

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.anyBadCredit}
                  label="Does any proposed tenant have a low credit score? (Below 500)"
                />
              </div>

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.anyCriminalRecords}
                  label="Does any proposed tenant have a criminal record?"
                />
              </div>
            </Card>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default RentalApplicationDetailView;
