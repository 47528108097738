import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const fullTailwindConfig = resolveConfig(tailwindConfig as any);

/**
 *  Provides the the custom tailwind configurations merged with the base
 *  configuration so that the configuration can be referenced as a whole
 *  programatically. Refer to:
 *  https://tailwindcss.com/docs/configuration#referencing-in-java-script
 */
export default fullTailwindConfig;
