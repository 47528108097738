import {MouseEvent, useCallback} from 'react';

import clsx from 'clsx';
import {Collapse} from 'react-collapse';
import {
  HiOutlineCurrencyDollar,
  HiOutlineDocumentText,
  HiOutlineSearch,
  HiOutlineX,
} from 'react-icons/hi';
import {IoHammerOutline} from 'react-icons/io5';
import {Link} from 'react-router-dom';

import {Button} from 'components_sb/buttons';
import {Paragraph, Title} from 'components_sb/typography';
import useCurrentUserFlag from 'hooks/useCurrentUserFlag';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';

import '@dotlottie/player-component';

const DemoPropertyCard = ({tourIsActive}: {tourIsActive: boolean}) => {
  const isMobile = !useTailwindBreakpoint('sm');

  const hideDemoPropertyFlag = useCurrentUserFlag('hide_demo_property');

  const onHideDemoProperty = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      /**
       * Prevent parent link from navigating to demo property.
       */
      event.preventDefault();

      /**
       * Set the flag.
       */
      hideDemoPropertyFlag.set(true);
    },
    [hideDemoPropertyFlag],
  );

  return (
    <Collapse
      isOpened={hideDemoPropertyFlag.isReady && !hideDemoPropertyFlag.value}>
      <Link to="demo/properties/1">
        <div className="flex flex-col justify-center mb-6">
          <div
            data-tour="demo-property-item"
            className="relative flex flex-col md:flex-row md:space-x-0 space-y-3 md:space-y-0 rounded-xl overflow-hidden shadow-lg p-3 pt-12 w-full mx-auto  bg-white">
            {/* Example property banner */}
            <div className="absolute left-0 top-0 w-full flex flex-row items-center p-1 min-h-10 alert-info">
              <div className="text-xs font-semibold uppercase flex-1 px-3">
                Example Property
              </div>
              {/* Hide the button when the tour is active. */}
              <div className={clsx('flex-0', tourIsActive ? 'hidden' : 'flex')}>
                <Button
                  label="Don't show this again"
                  category="primary"
                  size="sm"
                  mode="manual"
                  icon={HiOutlineX}
                  onClick={onHideDemoProperty}
                />
              </div>
            </div>

            {/* Finger tap animation */}
            <div
              id="demo-card-finger-tap"
              // Hide by default
              style={{
                display: 'none',
              }}
              className={clsx(
                `absolute w-full h-full top-0 left-0 pointer-events-none`,
                'flex justify-center items-center',
              )}>
              <div className="h-48 drop-shadow-xl mt-20 md:mt-0">
                <dotlottie-player
                  autoplay
                  loop
                  mode="normal"
                  src="/assets/lottie/finger-tap.lottie"
                  style={{height: '100%', width: '100%'}}
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 bg-white grid place-items-center">
              <img
                src="https://storage.googleapis.com/propup-demo-media/property-1.jpeg"
                className="rounded-xl"
              />
            </div>
            <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
              <div className="flex justify-between item-center">
                <Paragraph size="sm" secondary>
                  Thorndon, Wellington
                </Paragraph>
              </div>
              <Title level="h3" size="lg">
                1 Example Street
              </Title>
              <Paragraph secondary>
                See Keyhook's main features in action in this demo property.
              </Paragraph>
              <Paragraph secondary>
                This will go away when you add your first property.
              </Paragraph>

              {isMobile ? (
                <div
                  className="grid grid-cols-2 gap-y-2 gap-x-2 mt-2"
                  data-tour="demo-property-item-stats">
                  <div className="flex justify-start items-center bg-white rounded-full">
                    <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
                      <HiOutlineCurrencyDollar className="w-4 h-4 text-white" />
                    </div>
                    <div className="flex flex-col ml-2 pt-1">
                      <span className="text-gray-500" style={{fontSize: 10}}>
                        Rent
                      </span>
                      <h3 className="text-md font-semibold">$820.00</h3>
                    </div>
                  </div>

                  <div className="flex justify-start items-center bg-white rounded-full">
                    <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
                      <IoHammerOutline className="w-4 h-4 text-white" />
                    </div>
                    <div className="flex flex-col ml-2 pt-1">
                      <span className="text-gray-500" style={{fontSize: 10}}>
                        Maintenance
                      </span>
                      <h3 className="text-md font-semibold">1 Request</h3>
                    </div>
                  </div>

                  <div className="flex justify-start items-center bg-white rounded-full">
                    <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
                      <HiOutlineSearch className="w-4 h-4 text-white" />
                    </div>
                    <div className="flex flex-col ml-2 pt-1">
                      <span className="text-gray-500" style={{fontSize: 10}}>
                        Next Inspection
                      </span>
                      <h3 className="text-md font-semibold">Today</h3>
                    </div>
                  </div>

                  <div className="flex justify-start items-center bg-white rounded-full">
                    <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
                      <HiOutlineDocumentText className="w-4 h-4 text-white" />
                    </div>
                    <div className="flex flex-col ml-2 pt-1">
                      <span className="text-gray-500" style={{fontSize: 10}}>
                        Lease Expires
                      </span>
                      <h3 className="text-md font-semibold">123 Days</h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  data-tour="demo-property-item-stats"
                  className="w-full bordered shadow-xl stats mmd:grid-flow-row mt-2">
                  <div className="stat">
                    <div className="stat-title">Rent</div>
                    <div className="stat-value text-2xl">$820.00</div>
                    <div className="stat-desc">Weekly</div>
                  </div>
                  <div className="stat">
                    <div className="stat-title">Maintenance Requests</div>
                    <div className="stat-value text-2xl">1</div>
                    <div className="stat-desc">Open Requests</div>
                  </div>
                  <div className="stat">
                    <div className="stat-title">Next Inspection</div>
                    <div className="stat-value text-2xl">Today</div>
                    <div className="stat-desc"></div>
                  </div>
                  <div className="stat">
                    <div className="stat-title">Lease Expires</div>
                    <div className="stat-value text-2xl">123</div>
                    <div className="stat-desc">Days</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </Collapse>
  );
};

export default DemoPropertyCard;
