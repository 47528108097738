import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import chalk from 'chalk';
import {useQuery, useQueryClient} from 'react-query';
import {Navigate, useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import PageWrapper from 'components/PageWrapper';
import RentMethodStep from 'components/walkthrough/common/RentMethodStep';
import TenancyPeriodStep from 'components/walkthrough/common/TenancyPeriodStep';
import BankingStep from 'components/walkthrough/migrate_tenancy/BankingStep';
import InviteFirstTenantStep from 'components/walkthrough/migrate_tenancy/InviteFirstTenantStep';
import RentStep from 'components/walkthrough/migrate_tenancy/RentStep';
import ListingAvailabilityStep from 'components/walkthrough/new_listing/AvailabilityStep';
import ListingDescriptionStep from 'components/walkthrough/new_listing/DescriptionStep';
import ListingFinancialsStep from 'components/walkthrough/new_listing/FinancialsStep';
import ListingGeneralInfoStep from 'components/walkthrough/new_listing/GeneralInfoStep';
import ListingPreviewStep from 'components/walkthrough/new_listing/ListingPreviewStep';
import ListingPhotosStep from 'components/walkthrough/new_listing/PhotosStep';
import ListingPropertyTypeStep from 'components/walkthrough/new_listing/PropertyTypeStep';
import ListingPublishStep from 'components/walkthrough/new_listing/PublishStep';
import ListingRequirementsStep from 'components/walkthrough/new_listing/RequirementsStep';
import ListingTagsStep from 'components/walkthrough/new_listing/TagsStep';
import BankDetailsStep from 'components/walkthrough/new_tenancy/BankDetailsStep';
import ChattelsStep from 'components/walkthrough/new_tenancy/ChattelsStep';
import NewFinancialsStep from 'components/walkthrough/new_tenancy/FinancialsStep';
import NewGeneralInfoStep from 'components/walkthrough/new_tenancy/GeneralInfoStep';
import HealthyHomesStep from 'components/walkthrough/new_tenancy/HealthyHomesStep';
import InsuranceStep from 'components/walkthrough/new_tenancy/InsuranceStep';
import InviteTenantsStep from 'components/walkthrough/new_tenancy/InviteTenantsStep';
import LeaseConditionsStep from 'components/walkthrough/new_tenancy/LeaseConditionsStep';
import PersonalProfileStep from 'components/walkthrough/new_tenancy/PersonalProfileStep';
import PreviewLeaseStep from 'components/walkthrough/new_tenancy/PreviewLeaseStep';
import NewPropertyTypeStep from 'components/walkthrough/new_tenancy/PropertyTypeStep';
import NewRequirementsStep from 'components/walkthrough/new_tenancy/RequirementsStep';
import {SpinningLoader} from 'components_sb/feedback';
import {
  OBF,
  OBFS,
  OnboardingFlow,
  OnboardingFlowStep,
} from 'constants/onboarding-flow-steps';
import {TARGET_ENV} from 'globals/app-globals';
import useKeyboardHeight from 'hooks/useKeyboardHeight';
import useNativeInsets from 'hooks/useNativeInsets';
import useScrollPositionFix from 'hooks/useScrollPositionFix';
import Listing, {ListingStatus} from 'models/listings/Listing';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {saveResource} from 'utilities/SpraypaintHelpers';

import OnboardingFlowNavigation from './OnboardingFlowNavigation';

export type OnboardingFlowStepComponent = FunctionComponent<{
  step: OnboardingFlowStep;
  property: Property;
}>;

const STEP_COMPONENTS: {
  [key in OnboardingFlowStep]?: OnboardingFlowStepComponent;
} = {
  /**
   * These do not have component definitions since they are handled by the property
   * index page, outside of the usual onboarding flow.
   */
  // [OBFS.AddPropertyAddress]: null,
  // [OBFS.SelectOnboardingType]: null,

  /**
   * New listing steps.
   */
  [OBFS.ListingPropertyType]: ListingPropertyTypeStep,
  [OBFS.ListingGeneralInformation]: ListingGeneralInfoStep,
  [OBFS.ListingRequirements]: ListingRequirementsStep,
  [OBFS.ListingFinancials]: ListingFinancialsStep,
  [OBFS.ListingAvailability]: ListingAvailabilityStep,
  [OBFS.ListingTags]: ListingTagsStep,
  [OBFS.ListingPhotos]: ListingPhotosStep,
  [OBFS.ListingDescription]: ListingDescriptionStep,
  [OBFS.ListingPreview]: ListingPreviewStep,
  [OBFS.ListingPublish]: ListingPublishStep,

  /**
   * Migrate tenancy steps.
   */
  [OBFS.MigrateRentInformation]: RentStep,
  [OBFS.MigrateInviteTenant]: InviteFirstTenantStep,
  [OBFS.MigrateRentMethod]: RentMethodStep,
  [OBFS.MigrateBanking]: BankingStep,
  [OBFS.MigrateCommencementDate]: TenancyPeriodStep,

  /**
   * New tenancy steps.
   */
  [OBFS.NewPropertyType]: NewPropertyTypeStep,
  [OBFS.NewGeneralInformation]: NewGeneralInfoStep,
  [OBFS.NewRequirements]: NewRequirementsStep,
  [OBFS.NewFinancials]: NewFinancialsStep,
  [OBFS.NewRentMethod]: RentMethodStep,
  [OBFS.NewBankDetails]: BankDetailsStep,
  [OBFS.NewAvailability]: TenancyPeriodStep,
  [OBFS.NewPersonalProfile]: PersonalProfileStep,
  [OBFS.NewChattels]: ChattelsStep,
  [OBFS.NewInsurance]: InsuranceStep,
  [OBFS.NewLeaseConditions]: LeaseConditionsStep,
  [OBFS.NewPreviewLease]: PreviewLeaseStep,
  [OBFS.NewHealthyHomes]: HealthyHomesStep,
  [OBFS.NewInviteTenants]: InviteTenantsStep,
};

interface WrapperProps {
  title: string;
  children: ReactNode;
}

// TODO: Create property if no property ID in local storage and there is instead an address

const Wrapper: FunctionComponent<WrapperProps> = ({title, children}) => {
  // IMPORTANT THIS IS HERE
  useScrollPositionFix();

  const insets = useNativeInsets();

  const paddingHeight = useKeyboardHeight();

  if (Capacitor.isNativePlatform()) {
    return (
      <div
        id="root-scroll-container"
        className="min-h-full flex flex-col overflow-y-auto"
        style={{
          paddingTop: Capacitor.getPlatform() === 'ios' ? insets.top : 0,
          paddingBottom: Capacitor.getPlatform() === 'ios' ? insets.bottom : 0,
        }}>
        <PageWrapper title={title} disablePadding disableVerticalMargin>
          <div className="w-full flex-1 flex flex-col">{children}</div>
          <div style={{height: paddingHeight}}></div>
        </PageWrapper>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

const OnboardingFlowPage = () => {
  usePageVisit('OnboardingFlowPage');
  useTitle('Property Setup');

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /**
   * Read the property ID from local storage.
   */
  const [propertyId] = useState<string | null>(
    localStorage.getItem('new-property-id'),
  );

  /**
   * Fetch the property based on the property ID set
   * in local storage.
   */
  const {
    data: property,
    isError: errorFetchingProperty,
    isSuccess: successfullyFetchedProperty,
  } = useQuery(
    ['property', {id: propertyId, context: 'onboarding-flow'}],
    async () => {
      const property = await Property.includes([
        'billing_method',
        {tenancies: 'tenancy_requests'},
        'documents',
        {listings: 'listing_photos'},
      ])
        .order({'tenancies.created_at': 'desc'})
        .find(propertyId);
      return property.data;
    },
    {
      /**
       * Only attempt to fetch if a property ID
       * was read from local storage.
       */
      enabled: !!propertyId,
      refetchOnWindowFocus: false,
    },
  );

  /**
   * Clear the property ID in local storage if there was an
   * error fetching the property.
   */
  useEffect(() => {
    if (errorFetchingProperty) {
      localStorage.removeItem('new-property-id');
    }
  }, [errorFetchingProperty]);

  /**
   * Determine the current onboarding step.
   */
  const currentOnboardingStep = useMemo<OnboardingFlowStep | null>(
    () => (!property ? null : property.currentOnboardingStep),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [property?.lastOnboardingStepCompleted, property?.noTenancyActionType],
  );

  /**
   * Determine the current onboarding flow.
   */
  const currentOnboardingFlow = useMemo<OnboardingFlow | null>(() => {
    return property ? property.currentOnboardingFlow : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property?.noTenancyActionType]);

  /**
   * Determine the title to show in the page wrapper (if applicable - it will only be shown in the native app).
   */
  const wrapperTitle = useMemo(() => {
    switch (currentOnboardingFlow) {
      case OBF.NewListing:
        return 'Listing Setup';
      case OBF.MigrateTenancy:
      case OBF.NewTenancy:
        return 'Tenancy Setup';
      case OBF.Undetermined:
      default:
        return 'Add Property';
    }
  }, [currentOnboardingFlow]);

  /**
   * Log details in development mode for the current onboarding
   * step and flow.
   */
  useEffect(() => {
    if (TARGET_ENV === 'development') {
      if (
        successfullyFetchedProperty &&
        !!currentOnboardingFlow &&
        !!currentOnboardingStep
      ) {
        /**
         * Log the current onboarding step.
         */
        console.log(
          chalk.blueBright(chalk.bold('[ONBOARDING]')) +
            chalk.blueBright(chalk.italic('\nCurrent flow: ')) +
            currentOnboardingFlow +
            chalk.blueBright(chalk.italic('\nCurrent step: ')) +
            currentOnboardingStep,
        );
      }
    }
  }, [
    currentOnboardingFlow,
    currentOnboardingStep,
    successfullyFetchedProperty,
  ]);

  /**
   * Select the component for the current step based on the
   * current onboarding step for the property.
   */
  const StepComponent = useMemo<OnboardingFlowStepComponent | null>(() => {
    /**
     * Handle no current onboarding step.
     */
    if (!currentOnboardingStep) {
      return null;
    }

    /**
     * Attempt to find the component from the mapping in the
     * STEP_COMPONENTS definition.
     */
    const component = STEP_COMPONENTS[currentOnboardingStep];

    /**
     * Return the component if found, otherwise null in all other cases.
     */
    return component ?? null;
  }, [currentOnboardingStep]);

  /**
   * Depending on the current step, the property may require
   * some preparation before the step can be rendered. This includes
   * creating a listing instance for the listing flow, and a tenancy
   * for the migrate tenancy and new tenancy flows.
   */
  const [propertyIsPrepared, setPropertyIsPrepared] = useState<boolean>(false);
  const [isPreparingProperty, setIsPreparingProperty] =
    useState<boolean>(false);
  const [errorPreparingProperty, setErrorPreparingProperty] =
    useState<boolean>(false);

  /**
   * Creates a new listing on the property if required.
   */
  const handlePrepareListing = useCallback(async () => {
    /**
     * Prevent further invocation of this function if there is no property.
     */
    if (!property) {
      console.error(
        'handlePrepareListing called without there being a property',
      );
    }

    /**
     * Check whether the property already has a listing in one
     * of the 'ready' states.
     */
    const hasCurrentListing = property?.listings.some(
      (listing) => listing.isCurrent,
    );

    /**
     * If there is already a listing in one of the 'ready' states, the
     * fact that we are on this page likely indicates an invalid state.
     * Redirect the user to the listings page for the property.
     */
    if (hasCurrentListing) {
      toast.error('There is already a current listing for this property!');
      navigate(`/listings`, {replace: true}); // TODO: Link to the listing detail page for the property instead
      return;
    }

    /**
     * Check whether the property has a draft listing.
     */
    const hasDraftListing = property?.listings.some(
      (listing) => listing.isDraft,
    );

    /**
     * If there is no draft listing for the property then
     * we need to create one.
     */
    if (!hasDraftListing) {
      /**
       * Create the new listing.
       */
      const listing = new Listing({
        propertyId: property.id,
        status: ListingStatus.Draft,
      });

      /**
       * Set the new listing on the property.
       */
      property.listings = [...property.listings, listing];

      /**
       * Save the changes to the property and listing.
       */
      if (!(await saveResource(property, {with: 'listings'}))) {
        /**
         * Indicate there was an error saving the new listing on the property.
         */
        throw new Error('Failed to prepare property for the new listing flow!');
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );
    }
  }, [property, navigate, queryClient]);

  /**
   * Creates a new tenancy on the property if required.
   */
  const handlePrepareTenancy = useCallback(async () => {
    /**
     * Prevent further invocation of this function if there is no property.
     */
    if (!property) {
      console.error(
        'handlePrepareTenancy called without there being a property',
      );
    }

    /**
     * Track whether we make any changes to the property or tenancy
     * so that we don't need to unnecessarily save the resources.
     */
    let requiresSaving = false;

    /**
     * Attempt to find an editable (draft or pending) tenancy on the property.
     */
    let editableTenancy = property?.tenancies.find(
      (tenancy) => tenancy.isDraft || tenancy.isPending,
    );

    /**
     * If there is no editable tenancy for the property then
     * we need to create one.
     */
    if (!editableTenancy) {
      /**
       * Create a new draft tenancy.
       */
      editableTenancy = new Tenancy({
        propertyId: property.id,
        status: 'draft',
      });

      /**
       * Set the new draft tenancy on the property.
       */
      property.tenancies = [...property.tenancies, editableTenancy];

      /**
       * Indicate that a save is required.
       */
      requiresSaving = true;
    }

    /**
     * Ensure that the tenancy has the correct value for isNew
     * so that we are able to distinguish between the migrate
     * and new tenancy flows.
     */
    if (
      currentOnboardingFlow === OBF.NewTenancy &&
      editableTenancy.isNew !== true
    ) {
      editableTenancy.isNew = true;
      requiresSaving = true;
    }
    if (
      currentOnboardingFlow === OBF.MigrateTenancy &&
      editableTenancy.isNew !== false
    ) {
      editableTenancy.isNew = false;
      requiresSaving = true;
    }

    /**
     * Save the changes to the property and tenancy if necessary.
     */
    if (requiresSaving) {
      if (!(await saveResource(property, {with: 'tenancies'}))) {
        /**
         * Indicate there was an error saving the new tenancy on the property.
         */
        throw new Error(
          'Failed to prepare property for the migrate/new tenancy flow!',
        );
      }
    }

    /**
     * Update the property data in the query cache.
     */
    queryClient.setQueryData(
      ['property', {id: property.id, context: 'onboarding-flow'}],
      property,
    );
  }, [currentOnboardingFlow, property, queryClient]);

  const prepareProperty = useCallback(async () => {
    /**
     * No preparation is required if there is no current onboarding flow.
     */
    if (!currentOnboardingFlow) {
      setPropertyIsPrepared(true);
      return;
    }

    /**
     * Indicate that the property is being prepared.
     */
    setIsPreparingProperty(true);

    try {
      /**
       * Handle preparation for each of the onboarding flows.
       */
      switch (currentOnboardingFlow) {
        /**
         * Create a new listing for the property if on the new listing flow,
         * and a listing does not yet exist for the property.
         */
        case OnboardingFlow.NewListing:
          await handlePrepareListing();
          break;

        /**
         * Create a new tenancy for the property if on the migrate tenancy
         * or new tenancy flows, and a tenancy does not yet exist for the property.
         */
        case OnboardingFlow.MigrateTenancy:
        case OnboardingFlow.NewTenancy:
          await handlePrepareTenancy();
          break;

        /**
         * No preparation is required since we are not yet able to
         * determine the current flow. This is the case when the
         * user has started the onboarding flow, but has not yet
         * selected the onboarding type they wish to follow.
         */
        case OnboardingFlow.Undetermined:
          /**
           * No preparation is required.
           */
          setPropertyIsPrepared(true);
          break;
      }
      /**
       * Indicarte that the preparation has completed.
       */
      setPropertyIsPrepared(true);
    } catch (error) {
      setErrorPreparingProperty(true);
    } finally {
      setIsPreparingProperty(false);
    }
  }, [currentOnboardingFlow, handlePrepareListing, handlePrepareTenancy]);

  /**
   * When the current onboarding step changes, we need to re-prepare
   * the property to ensure it is ready for the step.
   */
  useEffect(() => {
    setPropertyIsPrepared(false);
  }, [currentOnboardingStep]);

  /**
   * When there is a property that has been fetched successfully, and it has
   * not been prepared or is currently being preparing, we need to invoke the preparation.
   */
  useEffect(() => {
    if (
      successfullyFetchedProperty &&
      !propertyIsPrepared &&
      !isPreparingProperty
    ) {
      prepareProperty();
    }
  }, [
    successfullyFetchedProperty,
    propertyIsPrepared,
    isPreparingProperty,
    prepareProperty,
  ]);

  /**
   * The step should only be rendered once the property has been
   * successfully fetched, and any necessary preparations have been
   * performed on the property.
   */
  const isReady = useMemo(
    () => successfullyFetchedProperty && propertyIsPrepared,
    [successfullyFetchedProperty, propertyIsPrepared],
  );

  /**
   * An error should be shown when either the property failed to
   * be fetched, or there was an issue performing the necessary
   * preparations on the property.
   */
  const isError = useMemo(
    () => errorFetchingProperty || errorPreparingProperty,
    [errorFetchingProperty, errorPreparingProperty],
  );

  /**
   * Handle switching between /properties/new and /listings/new
   * as necessary depending on the flow.
   */
  useEffect(() => {
    if (isReady) {
      if (
        location.pathname === '/properties/new' &&
        currentOnboardingFlow === OnboardingFlow.NewListing
      ) {
        navigate('/listings/new', {replace: true});
      } else if (
        location.pathname === '/listings/new' &&
        currentOnboardingFlow !== OnboardingFlow.NewListing
      ) {
        navigate('/properties/new', {replace: true});
      }
    }
  }, [currentOnboardingFlow, isReady, navigate]);

  /**
   * Property has been successfully fetched and prepared, but
   * we need to redirect the user to the property detail page if
   * one of the following applies:
   * - The property does not currently have an onboarding step set.
   * - The property has an onboarding step set but there is no component mapping.
   * - The current onboarding step is selecting the onboarding type (this is done in the property detail page).
   * - The onboarding flow has already been completed for the property.
   */
  if (
    isReady &&
    (!currentOnboardingStep ||
      (currentOnboardingStep && !StepComponent) ||
      currentOnboardingStep === OBFS.SelectOnboardingType ||
      currentOnboardingStep === OBFS.Completed)
  ) {
    /**
     * Invalidate the query cache for the property detail page
     * before redirecting to it.
     */
    queryClient.invalidateQueries([
      'property',
      {id: property.id, context: 'detail-page'},
    ]);

    /**
     * Redirect to the property detail page.
     */
    navigate(`/properties/${propertyId}`, {replace: true});
  }

  return !propertyId ? (
    // Property ID not set in local storage, redirect the user to
    // their dashboard to select a property or to add a new one.
    <Navigate to="/properties" replace />
  ) : (
    <Wrapper title={wrapperTitle}>
      {/* Error fetching or preparing property */}
      {/* TODO: Improve handling of error state */}
      {isError ? (
        <div className="text-red-600">
          Sorry, there was an error loading this page.
        </div>
      ) : (
        <>
          {!isReady ? (
            // Property is still being fetched and prepared.
            <div className="m-auto">
              <SpinningLoader color="brand" size="lg" />
            </div>
          ) : (
            <OnboardingFlowNavigation property={property}>
              <StepComponent step={currentOnboardingStep} property={property} />
            </OnboardingFlowNavigation>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default OnboardingFlowPage;
