import moment from 'moment';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import emptyBg from 'assets/img/empty_dataset_images/rent_payments.svg';
import {Card} from 'components_sb/layout';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import RentPayment from 'models/payments/RentPayment';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const RentPaymentIndexPage = () => {
  useTitle('Rent Payments');
  usePageVisit('RentPaymentIndexPage');

  const {tenancyId} = useParams();

  const {data, isLoading, error} = useQuery(
    `tenancy-${tenancyId}-rent-payments`,
    async () => {
      const rps = await RentPayment.where({
        tenancyId: tenancyId,
      })
        .includes('late_payment_notifications')
        .order({due_date: 'desc'})
        .per(200)
        .all();

      return rps.data;
    },
  );

  const renderItem = (item: RentPayment) => {
    const statusColors = {
      paid: 'success',
      partially_paid: 'warning',
      unpaid: 'danger',
    };

    return (
      <Card
        key={item.id}
        title={moment(item.dueDate).format(DATE_FORMAT)}
        className="mb-4">
        <div>
          <p
            className={`mb-0 text-sm font-normal text-${
              // @ts-ignore
              statusColors[item.status]
            }`}>
            {titleize(removeUnderscores(item.status))}
          </p>
          <p className="mb-0 text-sm">
            Amount Due: {toCurrency(item.amountDue)}
          </p>
          <p className="mb-0 text-sm">
            Amount Paid: {toCurrency(item.amountPaid)}
          </p>
          {item.status === 'paid' && (
            <p className="mb-0 text-sm">
              Received: {moment(item.receivedAt).format(DATE_TIME_FORMAT)}
            </p>
          )}
          <p className="mb-0 text-sm">
            Late Payment Notifications: {item.latePaymentNotifications.length}
          </p>
          {item.latePaymentNotifications.map((late) => (
            <p className="mb-0 text-sm" key={late.id}>
              - {moment(late.notifiedOn).format(DATE_FORMAT)}
            </p>
          ))}
        </div>
      </Card>
    );
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Rent Payments">
        <LoadingView />
      </PageWrapper>
    );
  } else if (data?.length === 0) {
    return (
      <PageWrapper title="Rent Payments">
        <EmptyDataSet
          title="No Rent Payments Received"
          message="We have not received any rent payments for this tenancy from you yet, once we do they will show up here."
          image={emptyBg}
        />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Rent Payments">{data.map(renderItem)}</PageWrapper>
    );
  }
};

export default RentPaymentIndexPage;
