import {useEffect, useRef, RefObject} from 'react';

import autoAnimate, {
  AutoAnimateOptions,
  AutoAnimationPlugin,
} from '@formkit/auto-animate';

import TrackingService from 'services/TrackingService';

export const useTitle = (title: string) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} - Keyhook`;
    return () => {
      document.title = prevTitle;
    };
  });
};

export const usePageVisit = (name: string) => {
  useEffect(() => {
    TrackingService.trackEvent(TrackingService.Event.PageView, {
      name,
    });
  }, [name]);
};

export const useAutoAnimate = <T extends Element>(
  options: Partial<AutoAnimateOptions> | AutoAnimationPlugin = {},
): [RefObject<T>] => {
  const element = useRef<T>(null);
  useEffect(() => {
    if (element.current instanceof HTMLElement)
      autoAnimate(element.current, options);
  }, [element, options]);
  return [element];
};
