import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import {arrayMove} from '@dnd-kit/sortable';
import {useAutoAnimate} from '@formkit/auto-animate/react';
import clsx from 'clsx';
import {isMobile} from 'react-device-detect';

import {SortableItems} from 'components_sb/layout';
import {SortableItem} from 'components_sb/layout/SortableItems/SortableItems';

import * as types from '../types';
import FileItem from './FileItem';

interface FileItemsProps {
  children: types.FileItem[];
  dropzone: ReactNode;
  onDelete: (fileItem: types.FileItem) => void;
  onReorder: (fileItems: types.FileItem[]) => void;
  firstFileIsMain: boolean;
}

const FileItems: FunctionComponent<FileItemsProps> = ({
  children: fileItems,
  dropzone,
  onDelete,
  onReorder,
  firstFileIsMain,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const [listRef, enableAutoAnimate] = useAutoAnimate<HTMLUListElement>();

  /**
   * Temporarily disable auto animation when dragging to prevent
   * duplicate animations caused by both dndkit attempting to animate
   * the position changes.
   */
  useEffect(() => {
    if (isDragging) {
      enableAutoAnimate(false);
    } else {
      setTimeout(() => enableAutoAnimate(true), 1000);
    }
  }, [isDragging, enableAutoAnimate]);

  const onReorderFileItem = useCallback(
    (id: types.FileItemId, delta: number) => {
      /**
       * Swap the provided file item with either the preceeding or
       * following file item depending on the delta.
       */
      const fileItemIndex = fileItems.findIndex(
        (fileItem) => id === fileItem.id,
      );
      const reorderedFileItems = arrayMove(
        fileItems,
        fileItemIndex,
        fileItemIndex + delta,
      );

      /**
       * Invoke the provided onReorder callback with the new order
       * of file items.
       */
      onReorder(reorderedFileItems);
    },
    [fileItems, onReorder],
  );

  const sortableFileItems = useMemo(
    () =>
      fileItems.map((fileItem) => ({
        id: fileItem.id,
        dragOverlay: <FileItem isDragOverlay>{fileItem}</FileItem>,
      })),
    [fileItems],
  );

  /**
   * Dragging interferes with scrolling on mobile, so dragging is only
   * available on desktop devices. Reordering can still be performed
   * on mobile devices by using the up/down buttons on each file item.
   */
  const draggingDisabled = useMemo<boolean>(
    () => Capacitor.isNativePlatform() || isMobile,
    [],
  );

  return (
    <SortableItems
      items={sortableFileItems}
      onReorder={onReorderFileItem}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      disabled={draggingDisabled}>
      {(activeId) => (
        <ul
          ref={listRef}
          className={clsx(
            'grid gap-4',
            'grid-cols-1',
            fileItems.length >= 1 && 'sm:grid-cols-2',
            fileItems.length >= 2 && 'lg:grid-cols-3',
          )}>
          {fileItems.map((fileItem, index) => (
            <SortableItem key={fileItem.id} id={fileItem.id}>
              <FileItem
                isMain={firstFileIsMain && index === 0}
                isFirst={index === 0}
                isLast={index === fileItems.length - 1}
                isDragPlaceholder={fileItem.id === activeId}
                onDelete={() => onDelete(fileItem)}
                onReorder={(delta) => onReorderFileItem(fileItem.id, delta)}>
                {fileItem}
              </FileItem>
            </SortableItem>
          ))}
          <li>{dropzone}</li>
        </ul>
      )}
    </SortableItems>
  );
};

export default FileItems;
