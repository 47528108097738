import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';
import dayjs, {ManipulateType} from 'dayjs';
import {FormikProps, useFormik} from 'formik';
import pluralize from 'pluralize';
import {Collapse} from 'react-collapse';
import {DayPicker, Matcher} from 'react-day-picker';
import {HiCheckCircle} from 'react-icons/hi';

import {Button} from 'components_sb/buttons';
import ActionsDropdownButton from 'components_sb/buttons/ActionsDropdownButton/ActionsDropdownButton';
import {InlineError} from 'components_sb/feedback';
import {FieldLabel} from 'components_sb/typography';
import {FieldLabelProps} from 'components_sb/typography/FieldLabel/FieldLabel';

import SelectionModeButton from './SelectionModeButton';

import 'react-day-picker/dist/style.css';

type ValueMode = 'single' | 'range';
type SelectionMode = 'start' | 'end';

interface DatePickerBaseProps {
  /**
   * The type of value that the date picker handles (i.e. a single
   * date or a date range).
   */
  mode: ValueMode;
  /**
   * An instance of a formik Form.
   */
  form: ReturnType<typeof useFormik> | FormikProps<any>;
  /**
   * Will shown the full date picker inline if enabled, otherwise
   * a field will be shown with the full date picker appearing as
   * a popover on click.
   */
  inline?: boolean;
  /**
   * Props to pass through to the FieldLabel component.
   * Note that the required prop here is omitted and must
   * be passed separately to handle multiple dates.
   */
  labelProps?: Omit<FieldLabelProps, 'required'>;
}

type SingleModeNameProp = string;

interface RangeModeNameProp {
  start: string;
  end: string;
}

interface QuickSetEndDateOption {
  value: number;
  unit: ManipulateType;
}

export type DisabledDates = Matcher | Matcher[] | undefined;

type DatePickerConditionalProps =
  /**
   * Types when value mode is 'single'.
   */
  | {
      mode: 'single';
      /**
       * Only a single field name is required (for the start date).
       */
      name: SingleModeNameProp;
      /**
       * Whether the field is required.
       */
      required?: boolean;
      /**
       * Intervals for quickly setting the end date based on the
       * start date.
       */
      quickSetEndDateOptions?: never;
      /**
       * Dates that are disabled and cannot be selected.
       */
      disabledDates?: DisabledDates;
    }
  /**
   * Types when value mode is 'range'.
   */
  | {
      mode: 'range';
      /**
       * A field name is required for both the start and end dates.
       */
      name: RangeModeNameProp;
      /**
       * Whether the field is required.
       */
      required?:
        | boolean
        | {
            start: boolean;
            end: boolean;
          };
      /**
       * Dates that are disabled and cannot be selected.
       */
      disabledDates?:
        | DisabledDates
        | {
            start: DisabledDates;
            end: DisabledDates;
          };
      /**
       * Intervals for quickly setting the end date based on the
       * start date.
       */
      quickSetEndDateOptions?: QuickSetEndDateOption[];
    };

/**
 * Combine base prop types with the conditional prop types
 * and a specific subset of the DayPicker component props
 * (to be passed through).
 */
type DatePickerProps = DatePickerBaseProps & DatePickerConditionalProps;

/**
 * Define the component type.
 */
type DatePickerComponent = FunctionComponent<DatePickerProps>;

/**
 * A custom user-friendly date picker component.
 */
const DatePicker: DatePickerComponent = ({
  mode: valueMode = 'single',
  name,
  labelProps,
  form,
  // inline = true, // TODO
  required,
  disabledDates,
  quickSetEndDateOptions = [],
}) => {
  // TODO: Set default view to be the month of the provided date, otherwise current month

  /**
   * The current mode for the date being selected (i.e. start date or end date).
   * Note that for single value mode, this will always be 'start'.
   */
  const [selectionMode, setSelectionMode] = useState<SelectionMode>('start');

  /**
   * Sets the selection mode to start date selection.
   */
  const enableStartDateSelection = useCallback(
    () => setSelectionMode('start'),
    [],
  );

  /**
   * Handle the value mode changing from 'range' to 'single' while selection
   * mode is set to 'end'.
   */
  useEffect(() => {
    if (valueMode === 'single' && selectionMode === 'end') {
      enableStartDateSelection();
    }
  }, [valueMode, selectionMode, enableStartDateSelection]);

  /**
   * Sets the selection mode to end date selection.
   */
  const enableEndDateSelection = useCallback(() => setSelectionMode('end'), []);

  /**
   * Get the form properties for the field(s).
   */
  const fields = useMemo(() => {
    if (valueMode === 'single') {
      return {
        start: form.getFieldProps(name),
        end: null,
      };
    }

    if (valueMode === 'range') {
      const {start, end} = name as RangeModeNameProp;
      return {
        start: form.getFieldProps(start),
        end: form.getFieldProps(end),
      };
    }

    throw new Error(
      'Could not get form fields for DatePicker - invalid mode provided.',
    );
  }, [valueMode, form, name]);

  /**
   * Get the properties for the form field for the
   * current selection mode.
   */
  const currentField = useMemo(
    () => fields[valueMode === 'single' ? 'start' : selectionMode],
    [fields, valueMode, selectionMode],
  );

  /**
   * Define the current date for reference.
   */
  const currentDate = useMemo(() => new Date(), []);

  /**
   * Determine the initial month to display.
   * If there is a value set, use that month, otherwise
   * default to showing the current month.
   */
  const initialVisibleMonth = useMemo<Date>(
    () => currentField.value ?? currentDate,
    [currentField, currentDate],
  );

  /**
   * The month that is currently visible to the user.
   */
  const [visibleMonth, setVisibleMonth] = useState<Date>(initialVisibleMonth);

  /**
   * Change the month that is currently visible to the user.
   * (does not affect the selected date)
   */
  const jumpToMonth = useCallback((date: Date) => {
    setVisibleMonth(date);
  }, []);

  /**
   * Jump to the month of the currently selected date for the current
   * selection mode.
   */
  const jumpToSelectedMonth = useCallback(() => {
    if (currentField.value) {
      jumpToMonth(currentField.value);
    }
  }, [currentField, jumpToMonth]);

  /**
   * Change the visible month to the month of the current selected
   * date when changing selection modes.
   */
  useEffect(() => {
    jumpToSelectedMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMode]);

  /**
   * Handle selection of a day.
   */
  const onClickDay = useCallback(
    (day: Date) => {
      /**
       * Set the new value for the field for the current selection mode.
       */
      form.setFieldValue(currentField.name, day);
    },
    [form, currentField],
  );

  /**
   * Handle quick-setting the end date.
   */
  const onQuickSetEndDate = useCallback(
    ({value, unit}: QuickSetEndDateOption) => {
      if (valueMode !== 'range') {
        throw new Error('Quick setting end date requires range value mode.');
      } else if (!fields.start.value) {
        throw new Error(
          'Quick setting end date requires a start date to be set.',
        );
      } else {
        /**
         * Determine the new end date by adding the interval defined by the
         * clicked option to the start date.
         */
        const newEndDate: Date = dayjs(fields.start.value)
          .add(value, unit)
          .subtract(1, 'day') // According to Sam, the end date should be the day before when you say a 12 month lease.
          .toDate();

        jumpToMonth(newEndDate);
        form.setFieldValue(fields.end.name, newEndDate);
      }
    },
    [valueMode, fields, jumpToMonth, form],
  );

  /**
   * Determine the error messages to display.
   */
  const errorMessages = useMemo<string[]>(() => {
    let errors: string[] = [];
    if (valueMode === 'single') {
      errors = [form.getFieldMeta(fields.start.name).error];
    } else if (valueMode === 'range') {
      errors = Object.values(fields).map(
        ({name}) => form.getFieldMeta(name).error,
      );
    }
    return errors.filter((error) => !!error);
  }, [valueMode, form, fields]);

  /**
   * "All fields" are required if the value mode is "single" and required, or
   * if both start/end dates in "range" value mode are required.
   */
  const allFieldsRequired = useMemo(
    () =>
      (valueMode === 'single' && typeof required === 'boolean' && required) ||
      (valueMode === 'range' &&
        typeof required === 'object' &&
        !!required &&
        required.start &&
        required.end),
    [required, valueMode],
  );

  /**
   * Determine the dates to disable for the current selection mode.
   */
  const disabledDatesForSelectionMode = useMemo<
    DisabledDates | undefined
  >(() => {
    /**
     * Handle specific disabled dates set for each selection mode.
     */
    if (
      typeof disabledDates === 'object' &&
      'start' in disabledDates &&
      'end' in disabledDates
    ) {
      return disabledDates[selectionMode];
    } else {
      /**
       * Handle either the same disabled dates being set for both start
       * and end dates, or no disabled dates set at all.
       */
      return disabledDates;
    }
  }, [selectionMode, disabledDates]);

  return (
    <div className="flex flex-col">
      {!!labelProps && (
        <FieldLabel
          {...labelProps}
          /**
           * We only want to show the required indicator on the label itself
           * if all fields are required (i.e. start date if single, or both
           * start/end dates if range).
           */
          required={allFieldsRequired}
        />
      )}
      <div className="flex flex-col rounded-xl border-2 border-brand-50 pb-4">
        {/* Single mode selected value */}
        {valueMode === 'single' && (
          <div
            className={clsx(
              'bg-white',
              'border-b-2 border-brand-50',
              'flex flex-row',
              'items-center justify-center gap-x-2',
              'px-6 py-4',
              'text-brand-500',
              'select-none',
            )}>
            {fields.start.value ? (
              <div className="relative flex flex-row items-center gap-x-2">
                <span className="text-base font-medium ">
                  {dayjs(fields.start.value).format(
                    'dddd[, ]Do[ of ]MMMM[, ]YYYY',
                  )}
                </span>
                <HiCheckCircle className={clsx('text-green-600', 'w-6 h-6')} />
              </div>
            ) : (
              <span className="text-opacity-70">Select a date below...</span>
            )}
          </div>
        )}
        {/* Range mode controls / selected values */}
        {valueMode === 'range' && (
          <div
            className={clsx(
              'bg-brand-50 rounded-xl',
              'overflow-hidden',
              'flex-1',
              'm-4 mb-0 p-1',
            )}>
            <div className="relative">
              {/* Sliding bar indicating current selection mode */}
              <div
                className={clsx(
                  'absolute h-full w-1/2 flex-1',
                  'bg-white rounded-lg',
                  'drop-shadow-lg',
                  'transform',
                  'transition-transform',
                  'duration-500',
                  selectionMode === 'start'
                    ? 'translate-x-0'
                    : 'translate-x-full',
                )}
              />
              <div
                className={clsx(
                  'relative',
                  'flex flex-row gap-x-6 justify-between',
                )}>
                {/* Start date selection mode button */}
                <SelectionModeButton
                  label="Start date"
                  active={selectionMode === 'start'}
                  onClick={enableStartDateSelection}
                  selectedDate={fields.start.value}
                />
                {/* End date selection mode button */}
                <SelectionModeButton
                  label="End date"
                  active={selectionMode === 'end'}
                  onClick={enableEndDateSelection}
                  selectedDate={fields.end.value}
                />
              </div>
            </div>
          </div>
        )}
        {/* Core date picker component that we wrap to improve the UI and UX */}
        <DayPicker
          /**
           * Set other props explicitly.
           */
          mode={valueMode}
          month={visibleMonth}
          onMonthChange={setVisibleMonth}
          selected={
            valueMode === 'single' ? currentField.value : [currentField.value]
          }
          disabled={disabledDatesForSelectionMode}
          onDayClick={onClickDay}
          classNames={{
            root: clsx('text-brand-850', 'flex-1 relative', 'p-4 pb-0'),
            caption_label: 'font-bold text-xl',
            nav: 'flex flex-row gap-x-2',
            nav_icon: 'text-brand-500',
            button: clsx(
              'cursor-pointer',
              'select-none',
              'w-10 h-10 mx-auto',
              'rounded-full',
              'flex justify-center items-center',
              'transition-all',
              'duration-200',
              'origin-center scale-100 active:scale-95',
              'bg-transparent hover:bg-brand-50 active:bg-brand-75',
            ),
            months: 'w-full',
            table: 'w-full text-center',
            head: 'h-10',
            cell: 'py-1',
            day: 'flex flex-grow-0',
            month: 'flex flex-col gap-y-2',
            /**
             * We manually set these heights to ensure consistent height
             * across months with varying numbers of days.
             */
            tbody: 'h-[240px]',
            row: 'h-[40px]',
            tfoot: 'm-0',
          }}
          modifiersClassNames={{
            selected: '!bg-brand-500 !text-white pointer-events-none',
            disabled: 'opacity-30 pointer-events-none',
            today: 'text-brand-500',
          }}
          footer={
            <div className="flex-1 flex flex-col gap-y-2">
              {/* Quick set the end date */}
              {!!quickSetEndDateOptions.length && !!fields.start.value && (
                <Collapse
                  isOpened={selectionMode === 'end'}
                  className="flex items-center justify-center">
                  <ActionsDropdownButton
                    label="Quick set end date"
                    actions={quickSetEndDateOptions.map((option) => ({
                      label: `${option.value} ${pluralize(option.unit)}`,
                      onClick: () => onQuickSetEndDate(option),
                    }))}
                  />
                </Collapse>
              )}

              {/* Jump to the current month. */}
              <Collapse
                isOpened={currentDate.getMonth() !== visibleMonth.getMonth()}>
                <Button
                  label="Jump to today"
                  category="tertiary"
                  size="sm"
                  mode="manual"
                  onClick={() => jumpToMonth(currentDate)}
                />
              </Collapse>

              {/* Jump to the month of the selected date. */}
              <Collapse
                isOpened={
                  !!currentField.value &&
                  (visibleMonth.getMonth() !== currentField.value.getMonth() ||
                    visibleMonth.getFullYear() !==
                      currentField.value.getFullYear())
                }>
                <Button
                  label="Jump to selected date"
                  category="tertiary"
                  size="sm"
                  mode="manual"
                  onClick={jumpToSelectedMonth}
                />
              </Collapse>
            </div>
          }
        />
        <div
          className={clsx(
            'px-4',
            'transition-all duration-300',
            errorMessages.length ? 'pt-2' : 'pt-0',
          )}>
          <InlineError error={errorMessages[0]} />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
