import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Property from 'models/properties/Property';

@Model()
class ExternalValuation extends ApplicationRecord {
  static jsonapiType = 'external_valuations';

  @Attr({persist: false}) dateRecorded: string;
  @Attr({persist: false}) extraInformation: any;
  @Attr({persist: false}) lowValuation: number;
  @Attr({persist: false}) highValuation: number;
  @Attr({persist: false}) valuation: number;

  @Attr({persist: false}) propertyId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('properties') property: Property;
}

export default ExternalValuation;
