import React, {useMemo} from 'react';

import moment from 'moment';
import {RiRefund2Line} from 'react-icons/ri';

import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {DATE_FORMAT} from 'utilities/DateHelpers';

const EndOfTenancyCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy: Tenancy;
}) => {
  const actions = useMemo(
    () =>
      tenancy && tenancy.id
        ? [
            {
              label: 'Prepare bond refund',
              icon: RiRefund2Line,
              linkTo: `tenancies/${tenancy.id}/bond-refund`,
            },
          ]
        : [],
    [tenancy],
  );

  if (!property || !tenancy) return null;
  if (!tenancy.endOfTenancy) return null;

  return (
    <Card title="End of tenancy" className="mt-8" actions={actions}>
      <Paragraph>
        Notice has been given and this tenancy is set to end on{' '}
        {moment(tenancy.endOfTenancy.tenancyEndDate).format(DATE_FORMAT)}. If
        you believe this has been done in error, or this notice does not align
        with compliance requirements. Please contact support to resolve the
        issue.
      </Paragraph>
      {tenancy.endOfTenancy.bondRefundAmount ? (
        <Paragraph>
          The bond refund has been sent to your tenants to sign, you can edit it
          by clicking the button so long as no tenants have signed.
        </Paragraph>
      ) : (
        <Paragraph>
          Click the button to prepare the bond refund when you are ready.
        </Paragraph>
      )}
    </Card>
  );
};

export default EndOfTenancyCard;
