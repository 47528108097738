import {Navigate, Outlet} from 'react-router';

import useAuth from 'services/useAuth';

const LoggedOutRoute = () => {
  const {userIsLoggedIn} = useAuth();

  if (userIsLoggedIn) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default LoggedOutRoute;
