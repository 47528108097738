import {ClockIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {
  HiOutlineCurrencyDollar,
  HiOutlineDocumentText,
  HiOutlineSearch,
} from 'react-icons/hi';
import {IoHammerOutline} from 'react-icons/io5';
import {Link} from 'react-router-dom';

import ChangePhotoModal from 'components/property/landlord/ChangePhotoModal';
import {Button} from 'components_sb/buttons';
import {Modal} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';
import {pluralize, toCurrency} from 'utilities/StringHelpers';

const {useModal} = Modal.Imperative;

const LandlordPropertyIndexCard = ({
  property,
  tenancy,
  pageType = 'list',
}: {
  property: Property;
  tenancy?: Tenancy;
  pageType?: 'list' | 'detail';
}) => {
  const openModal = useModal();
  const showChangePhotoModal = () => {
    if (pageType === 'detail') {
      openModal(ChangePhotoModal, {property});
    }
  };

  const statCards = () => {
    if (
      property &&
      property.tenanciesCount > 0 &&
      tenancy &&
      ![
        TenancyStatus.Draft,
        TenancyStatus.Pending,
        TenancyStatus.AwaitingStartDate,
      ].includes(tenancy.status)
    ) {
      let nextInspectionDate: string;
      let nextInspectionSubtitle: string = null;
      if (tenancy.nextInspectionDate) {
        const today = new Date();
        const inspectionDate = new Date(tenancy.nextInspectionDate);

        const timeDifference = inspectionDate.getTime() - today.getTime();
        const days = Math.floor(Number(timeDifference / (1000 * 3600 * 24)));

        if (days < 0) {
          nextInspectionDate = 'Overdue';
        } else if (days === 0) {
          nextInspectionDate = 'Today';
        } else {
          nextInspectionDate = days.toFixed(0);
          nextInspectionSubtitle = 'Days';
        }
      } else {
        nextInspectionDate = 'Not set';
      }

      // let nextLeaseExpires: string;
      // if (tenancy.endDate) {
      //   const today = new Date();
      //   const leaseDate = new Date(tenancy.endDate);

      //   const timeDifference = leaseDate.getTime() - today.getTime();
      //   nextLeaseExpires = Number(timeDifference / (1000 * 3600 * 24)).toFixed(
      //     0,
      //   );
      // }

      let serviceCount = 0;
      if (tenancy.openServiceRequests.length > 0) {
        serviceCount = tenancy.openServiceRequests.length;
      } else {
        serviceCount = tenancy.serviceRequests.filter(
          (req) =>
            req.status !== 'landlord_rejected' && req.status !== 'completed',
        ).length;
      }

      return (
        <div className="w-full bordered shadow-xl stats mmd:grid-flow-row">
          <div className="stat">
            <div className="stat-title">Rent</div>
            <div className="stat-value text-2xl">
              {toCurrency(tenancy.totalRent)}
            </div>
            <div className="stat-desc">{tenancy.rentalPeriod}</div>
          </div>
          <div className="stat">
            <div className="stat-title">Maintenance Requests</div>
            <div className="stat-value text-2xl">{serviceCount}</div>
            <div className="stat-desc">Open Requests</div>
          </div>
          <div className="stat">
            <div className="stat-title">Next Inspection</div>
            <div className="stat-value text-2xl">
              {nextInspectionDate ? nextInspectionDate : 'N/A'}
            </div>
            <div className="stat-desc">{nextInspectionSubtitle}</div>
          </div>
          {/* <div className="stat">
            <div className="stat-title">Lease Expires</div>
            <div className="stat-value text-2xl">
              {nextLeaseExpires ? nextLeaseExpires : 'Ongoing'}
            </div>
            <div className="stat-desc">{nextLeaseExpires ? 'Days' : null}</div>
          </div> */}
        </div>
      );
    } else {
      let text: string;

      if (!tenancy) {
        if (pageType === 'list') {
          text =
            'This property is not currently tenanted, click this card to view the property and to see your options for starting a tenancy.';
        } else {
          text =
            'This property is not currently tenanted, check out the options below for starting a new tenancy.';
        }
      } else {
        if (tenancy.status === TenancyStatus.Pending) {
          text =
            'This property has a pending tenancy that the tenants have yet to accept.';
        } else if (tenancy.status === TenancyStatus.AwaitingStartDate) {
          text =
            'This tenancy has been confirmed and is waiting for the start date.';
        } else if (tenancy.isDraft) {
          text =
            'Continue the property set up process to get your tenancy started on Keyhook.';
        }
      }

      return <p className="md:text-lg text-gray-500 text-base">{text}</p>;
    }
  };

  const mobileStatCards = () => {
    if (
      property &&
      property.tenanciesCount > 0 &&
      tenancy &&
      ![
        TenancyStatus.Draft,
        TenancyStatus.Pending,
        TenancyStatus.AwaitingStartDate,
      ].includes(tenancy.status)
    ) {
      let nextInspectionDate: string;
      if (tenancy.nextInspectionDate) {
        const today = new Date();
        const inspectionDate = new Date(tenancy.nextInspectionDate);

        const timeDifference = inspectionDate.getTime() - today.getTime();
        const days = Math.floor(Number(timeDifference / (1000 * 3600 * 24)));

        if (days < 0) {
          nextInspectionDate = 'Overdue';
        } else if (days === 0) {
          nextInspectionDate = 'Today';
        } else {
          nextInspectionDate = days.toFixed(0) + ' Days';
        }
      }

      let nextLeaseExpires: string;
      if (tenancy.endDate) {
        const today = new Date();
        const leaseDate = new Date(tenancy.endDate);

        const timeDifference = leaseDate.getTime() - today.getTime();
        nextLeaseExpires = Number(timeDifference / (1000 * 3600 * 24)).toFixed(
          0,
        );
      }

      let serviceCount = 0;
      if (tenancy.openServiceRequests.length > 0) {
        serviceCount = tenancy.openServiceRequests.length;
      } else {
        serviceCount = tenancy.serviceRequests.filter(
          (req) =>
            req.status !== 'landlord_rejected' && req.status !== 'completed',
        ).length;
      }

      return (
        <div className="grid grid-cols-2 gap-y-2 gap-x-2">
          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <HiOutlineCurrencyDollar className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Rent
              </span>
              <h3 className="text-md font-semibold">
                {toCurrency(tenancy.totalRent)}
              </h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <IoHammerOutline className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Maintenance
              </span>
              <h3 className="text-md font-semibold">
                {serviceCount} {pluralize(serviceCount, 'Request')}
              </h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <HiOutlineSearch className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Next Inspection
              </span>
              <h3 className="text-md font-semibold">
                {nextInspectionDate ? nextInspectionDate : 'N/A'}
              </h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <HiOutlineDocumentText className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Lease Expires
              </span>
              <h3 className="text-md font-semibold">
                {nextLeaseExpires ? `${nextLeaseExpires} Days` : 'Ongoing'}
              </h3>
            </div>
          </div>
        </div>
      );
    } else {
      let text: string;

      if (!tenancy) {
        if (pageType === 'list') {
          text =
            'This property is not currently tenanted, click this card to view the property and to see your options for starting a tenancy.';
        } else {
          text =
            'This property is not currently tenanted, check out the options below for starting a new tenancy.';
        }
      } else {
        if (tenancy.status === TenancyStatus.Pending) {
          text =
            'This property has a pending tenancy that the tenants have yet to accept.';
        } else if (tenancy.status === TenancyStatus.AwaitingStartDate) {
          text =
            'This tenancy has been confirmed and is waiting for the start date.';
        } else if (tenancy.isDraft) {
          text =
            'Continue the property set up process to get your tenancy started on Keyhook.';
        }
      }

      return <p className="md:text-lg text-gray-500 text-base">{text}</p>;
    }
  };

  const renderStartDateBanner = () => {
    if (tenancy && tenancy.status === TenancyStatus.AwaitingStartDate) {
      let timeToGo = moment(tenancy.startDate).diff(moment(), 'days');
      let unit = 'Days';

      if (timeToGo <= 3) {
        timeToGo = moment(tenancy.startDate).diff(moment(), 'hours');
        unit = 'Hours';
      }

      return (
        <div className="alert alert-info shadow-lg my-4">
          <div>
            <ClockIcon className="w-5 h-5" />
            <span>
              <strong className="underline">
                {timeToGo} {unit}
              </strong>{' '}
              until this tenancy is active on Keyhook.
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderCard = (el: JSX.Element) => {
    if (pageType === 'list') {
      return <Link to={`/properties/${property.id}`}>{el}</Link>;
    } else {
      return el;
    }
  };

  return renderCard(
    <div>
      {/* Large screens (desktop) */}
      <div className="hidden md:flex flex-col justify-center">
        <div className="relative flex flex-col md:flex-row md:space-x-0 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 w-full mx-auto border border-white bg-white">
          <div className="flex w-60 relative mr-4">
            <div
              className="w-full h-full rounded-xl bg-cover bg-center"
              style={{backgroundImage: `url(${property.mainImage})`}}>
              {pageType === 'detail' && (
                <div className="absolute rounded-xl top-0 left-0 w-full h-full transition-color duration-300 bg-transparent hover:bg-black hover:bg-opacity-50 cursor-pointer group">
                  <div className="w-full h-full flex justify-center items-center invisible group-hover:visible">
                    <div className="mx-auto">
                      <Button
                        label="Change photo"
                        category="primary"
                        size="base"
                        mode="manual"
                        fillWidth={false}
                        onClick={showChangePhotoModal}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
            <div className="flex justify-between item-center">
              <Paragraph secondary>
                {property.suburb}, {property.city}
              </Paragraph>
            </div>
            <Title level="h3" size="lg">
              {property.streetAddress}
            </Title>
            {statCards()}
            {renderStartDateBanner()}
          </div>
        </div>
      </div>

      {/* Small screens (mobile) */}
      <div
        className={`flex md:hidden flex-col justify-center ${
          pageType === 'list' ? 'mb-4' : 'mb-0'
        }`}>
        <div className="flex flex-col w-full mx-auto shadow-lg bg-white rounded-xl overflow-hidden">
          <img
            src={property.mainImage}
            alt={property.streetAddress}
            className="w-full"
            onClick={showChangePhotoModal}
          />
          <div className="w-full flex flex-col py-4 px-6">
            <Paragraph size="sm" secondary>
              {property.suburb}, {property.city}
            </Paragraph>
            <Title level="h3" size="lg">
              {property.streetAddress}
            </Title>
            {mobileStatCards()}
            {renderStartDateBanner()}
          </div>
        </div>
      </div>
    </div>,
  );
};

export default LandlordPropertyIndexCard;
