import React, {useMemo} from 'react';

import moment from 'moment';
import {RiRefund2Line} from 'react-icons/ri';

import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Tenancy from 'models/properties/Tenancy';
import {DATE_FORMAT} from 'utilities/DateHelpers';

const EndOfTenancyCard = ({tenancy}: {tenancy: Tenancy}) => {
  const actions = useMemo(
    () => [
      {
        label: 'View bond refund',
        icon: RiRefund2Line,
        linkTo: `bond-refund`,
      },
    ],
    [],
  );

  if (!tenancy) return null;
  if (!tenancy.endOfTenancy) return null;

  return (
    <Card
      title="End of tenancy"
      className="mt-8"
      actions={tenancy.endOfTenancy.bondRefundAmount ? actions : []}>
      <Paragraph>
        Notice has been given and this tenancy is set to end on{' '}
        {moment(tenancy.endOfTenancy.tenancyEndDate).format(DATE_FORMAT)}.
      </Paragraph>
      {tenancy.endOfTenancy.bondRefundAmount ? (
        <Paragraph>
          Your landlord has prepared the bond refund, you can view and sign it
          by clicking the button.
        </Paragraph>
      ) : (
        <Paragraph>Your landlord has yet to prepare the bond refund.</Paragraph>
      )}

      {tenancy.endOfTenancy.currentUserSigned && (
        <Paragraph>You have successfully signed the bond refund.</Paragraph>
      )}
    </Card>
  );
};

export default EndOfTenancyCard;
