/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useRef, useState} from 'react';

import {
  type FilePondFile,
  type ProcessServerConfigFunction,
  registerPlugin as registerFilepondPlugin,
} from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import {FilePond} from 'react-filepond';

import {ModalDefinition} from 'components_sb/layout';
import {API_URL, TARGET_ENV} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import useInspectionStore from 'stores/InspectionStore';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerFilepondPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
);

const InspectionAddPhotosModal: ModalDefinition = {
  title: 'Add photos',
  buttonsConfig: {
    cancel: {
      label: 'Done',
    },
  },
  ContentComponent: (props) => {
    const {inspectionItem} = props;

    const {currentUser} = useAuth();

    const [inspectionItems, setInspectionItems] = useInspectionStore(
      (state) => [state.inspectionItems, state.setInspectionItems],
    );

    const [files, setFiles] = useState<FilePondFile[]>([]);
    const pond = useRef(null);

    const createAttachment = async (
      file: File,
      presignInfo: PresignResponse,
    ) => {
      let id: string;
      if (TARGET_ENV === 'development') {
        id = (presignInfo as any).id;
      } else {
        const url = new URL(presignInfo.url);
        id = url.pathname.split('/').pop();
      }
      const uploadData = {
        id,
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      };

      const attach = new InspectionItemAttachment();
      attach.inspectionItemId = inspectionItem.id;
      attach.attachment = JSON.stringify(uploadData);

      const result = await attach.save();

      if (result) {
        attach.user = new User({
          id: currentUser.id,
          name: currentUser.name,
          avatar: currentUser.avatar,
          email: currentUser.email,
        });
        attach.user.isPersisted = true;
        attach.userId = currentUser.id;

        /**
         * Update the inspection items store so that it re-renders the UI for rooms etc
         */
        const item = inspectionItems?.find(
          (i) => i.id === props.inspectionItem.id,
        );
        if (item) {
          item.inspectionItemAttachments.push(attach);
          setInspectionItems(inspectionItems);
        }
      }

      return attach;
    };

    /**
     * Handles uploading a photo.
     */
    const uploadPhoto: ProcessServerConfigFunction = async (...args: any) => {
      const [
        _fieldName,
        file,
        _metadata,
        load,
        error,
        progress,
        abort,
        _transfer,
        _options,
      ] = args;

      if (TARGET_ENV === 'development') {
        const formdata = new FormData();
        formdata.append('file', file);

        const request = new XMLHttpRequest();
        request.open(
          'POST',
          `${API_URL}/uploads/inspection_item_attachment.json?inspection_item_id=${props.inspectionItem.id}`,
        );

        request.setRequestHeader(
          'X-USER-TOKEN',
          currentUser.meta.authenticationToken,
        );
        request.setRequestHeader('X-USER-EMAIL', currentUser.email);

        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        request.onload = async () => {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            const data = JSON.parse(request.responseText);

            const attachment = await createAttachment(file, data);
            load(attachment.id);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no');
          }
        };

        request.send(formdata);

        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      } else {
        const presignResponse = await fetch(
          `${API_URL}/presigns/inspection_item_attachment.json?inspection_item_id=${inspectionItem.id}&filename=${file.name}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': currentUser.meta.authenticationToken,
              'X-USER-EMAIL': currentUser?.email,
            },
          },
        );
        const presignInfo = (await presignResponse.json()) as PresignResponse;
        if (presignInfo) {
          const request = new XMLHttpRequest();
          request.open(presignInfo.method, presignInfo.url);

          for (const [key, value] of Object.entries(presignInfo.headers)) {
            request.setRequestHeader(key, value);
          }

          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total);
          };

          request.onload = async () => {
            if (request.status >= 200 && request.status < 300) {
              // the load method accepts either a string (id) or an object
              const attachment = await createAttachment(file, presignInfo);
              load(attachment.id);
            } else {
              // Can call the error method if something is wrong, should exit after
              error('oh no');
            }
          };

          request.send(file);

          return {
            abort: () => {
              request.abort();
              abort();
            },
          };
        }
      }
    };

    return (
      <FilePond
        ref={pond}
        allowMultiple
        allowReplace={false}
        allowRevert={false}
        files={files as any}
        maxFiles={30}
        maxParallelUploads={5}
        imageTransformOutputQuality={80}
        imageTransformOutputQualityMode="always"
        imageTransformOutputMimeType="image/jpeg"
        imageResizeTargetWidth={2000}
        imageResizeMode="contain"
        imageResizeUpscale={false}
        maxFileSize="5MB"
        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
        allowReorder={false}
        imagePreviewHeight={120}
        server={{
          process: uploadPhoto,
          fetch: null,
        }}
        instantUpload={true}
        onupdatefiles={(files) => {
          setFiles(files);
        }}
        credits={false}
      />
    );
  },
};

export default InspectionAddPhotosModal;
