import {FunctionComponent, useCallback, useMemo} from 'react';

import clsx from 'clsx';
import {HiArrowLeft, HiArrowRight} from 'react-icons/hi';
import {HiEllipsisHorizontal} from 'react-icons/hi2';
import {useResizeDetector} from 'react-resize-detector';

import {Button} from 'components_sb/buttons';

interface PaginationProps {
  page: number;
  onChange: (page: number) => void;
  totalPages: number;
}

type SelectablePages = Array<number | null>;

const Pagination: FunctionComponent<PaginationProps> = ({
  page,
  onChange,
  totalPages,
}) => {
  /**
   * Throw errors for invalid prop configurations.
   */
  if (totalPages < 1) {
    throw new Error('Total pages must be greater than 0.');
  } else if (page <= 0) {
    throw new Error('Page number must be greater than 0');
  } else if (page > totalPages) {
    throw new Error(
      'Page number cannot be greater than the total number of pages.',
    );
  }
  const {width: pageSelectContainerWidth, ref: pageSelectContainerRef} =
    useResizeDetector();

  const maxSelectable = useMemo(
    () =>
      !pageSelectContainerWidth
        ? 0
        : Math.floor(pageSelectContainerWidth / 120),
    [pageSelectContainerWidth],
  );

  const onPageChange = useCallback(
    (newPage: number) => {
      onChange(newPage);
    },
    [onChange],
  );

  const onPrevious = useCallback(() => {
    onPageChange(page - 1);
  }, [onPageChange, page]);

  const onNext = useCallback(() => {
    onPageChange(page + 1);
  }, [onPageChange, page]);

  /**
   * TODO: Account for the ellipsis separators as part of the total when
   * evaluating the page options to show
   */
  const selectablePages = useMemo<SelectablePages>(() => {
    /**
     * No pages are selectable.
     */
    if (maxSelectable === 0) {
      return [];
    }

    /**
     * Only the current page is selectable.
     */
    if (maxSelectable === 1) {
      return [page];
    }

    /**
     * All possible pages are selectable.
     */
    if (totalPages <= maxSelectable) {
      return Array.from({length: totalPages}, (_, index) => ++index);
    }

    let pages: SelectablePages = [];

    const halfSelectable = Math.floor(maxSelectable / 2);

    /**
     * Current page is within the first few pages.
     * (i.e. is less than 4 if the max selectable is 8)
     */
    if (page <= halfSelectable) {
      pages = [...Array.from({length: maxSelectable}, (_, index) => ++index)];
    } else if (page >= totalPages - halfSelectable) {
      /**
       * Current page is within the last few pages.
       * (i.e. is within the last 4 pages if the max selectable is 8)
       */
      pages = [
        ...Array.from(
          {length: maxSelectable},
          (_, index) => totalPages - maxSelectable + ++index,
        ),
      ];
    } else {
      /**
       * Current page is somewhere in the middle of the list of pages, so
       * the current page and surrounding pages are shown as a priority.
       */
      pages = [
        ...Array.from(
          {length: Math.max(halfSelectable - 1, 0)},
          (_, index) => page - ++index,
        ).reverse(),
        page,
        ...Array.from(
          {length: Math.max(maxSelectable - halfSelectable - 1, 0)},
          (_, index) => page + ++index,
        ),
      ];
    }

    /**
     * Add separators where necessary.
     */
    pages = pages.reduce((result, number, index) => {
      /**
       * Check if the first number is not 1, add null to the start of the array.
       */
      if (index === 0 && number !== 1) {
        result.push(null);
      }

      /**
       * Add the current number to the result.
       */

      result.push(number);
      /**
       * Check if the next number is not sequential, add null between non-sequential numbers.
       */

      if (index < pages.length - 1 && number + 1 !== pages[index + 1]) {
        result.push(null);
      }

      /**
       * Check if the last number is not equal to totalPages, add null to the end of the array.
       */
      if (index === pages.length - 1 && number !== totalPages) {
        result.push(null);
      }
      return result;
    }, []);

    return pages;
  }, [totalPages, maxSelectable, page]);

  return (
    <div
      className={clsx(
        'w-full',
        'flex flex-row',
        'items-center justify-between',
        'gap-x-6',
      )}>
      <Button
        format="icon"
        icon={HiArrowLeft}
        category="primary"
        size="base"
        mode="manual"
        disabled={page === 1}
        onClick={onPrevious}
      />
      <div
        ref={pageSelectContainerRef}
        className="flex-1 flex flex-row gap-x-1">
        {selectablePages.map((pageNumber, index) =>
          pageNumber === null ? (
            <div
              key={`separator-${index}`}
              className="flex items-center justify-center px-6">
              <HiEllipsisHorizontal className="w-6 h-6 text-brand-300" />
            </div>
          ) : (
            <Button
              key={pageNumber}
              label={pageNumber.toString()}
              category="tertiary"
              size="base"
              mode="manual"
              disabled={page === pageNumber}
              onClick={() => onPageChange(pageNumber)}
            />
          ),
        )}
      </div>
      <Button
        format="icon"
        icon={HiArrowRight}
        category="primary"
        size="base"
        mode="manual"
        disabled={page === totalPages}
        onClick={onNext}
      />
    </div>
  );
};

export default Pagination;
