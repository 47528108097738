import {FunctionComponent, useMemo} from 'react';

import {cva} from 'class-variance-authority';
import clsx from 'clsx';
import {HiOutlineCheckCircle, HiOutlineXCircle} from 'react-icons/hi';

interface ResultMessageProps {
  children: string;
  type: 'success' | 'error';
}

const classes = {
  icon: cva(['w-14', 'h-14'], {
    variants: {
      type: {
        success: 'text-green-600',
        error: 'text-red-500',
      },
    },
  }),
  content: cva(['text-lg', 'font-normal', 'text-center'], {
    variants: {
      type: {
        success: 'text-green-600',
        error: 'text-red-500',
      },
    },
  }),
};

const ICONS = {
  success: HiOutlineCheckCircle,
  error: HiOutlineXCircle,
};

const ResultMessage: FunctionComponent<ResultMessageProps> = ({
  children: message,
  type,
}) => {
  const Icon = useMemo(() => ICONS[type], [type]);
  return (
    <div
      className={clsx(
        'flex-1 w-full',
        'p-12',
        'flex flex-col',
        'justify-center items-center',
        'gap-y-4',
      )}>
      <Icon className={classes.icon({type})} />
      <div className={classes.content({type})}>{message}</div>
    </div>
  );
};

export default ResultMessage;
