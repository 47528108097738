import {useCallback, useImperativeHandle} from 'react';

import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';

import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {OnboardingFlow} from 'constants/onboarding-flow-steps';
import {saveResource} from 'utilities/SpraypaintHelpers';

const PrepareTenancySwitchToMigrateModal: ModalDefinition = {
  title: 'What would you like to do?',
  buttonsConfig: {
    cancel: {
      label: 'Stay here',
    },
    actions: [
      {
        id: 'dashboard',
        label: 'Go to dashboard',
        handle: 'onGoToDashboard',
        closeOnSuccess: false,
      },
      {
        id: 'switch',
        label: 'Use my own lease',
        handle: 'onSwitch',
      },
    ],
  },

  ContentComponent: ({property}, ref) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const onGoToDashboard = useCallback(() => {
      navigate(`/properties/${property.id}`);
    }, [navigate, property]);

    const onSwitch = useCallback(async () => {
      /**
       * Switch to the migrate tenancy flow.
       */
      property.setOnboardingFlow(OnboardingFlow.MigrateTenancy);

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        throw Error();
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.invalidateQueries(['property']);
    }, [queryClient, property]);

    useImperativeHandle(ref, () => ({
      onGoToDashboard,
      onSwitch,
    }));
    return (
      <>
        <Paragraph>
          You're currently creating a new lease for your tenancy.
        </Paragraph>
        <Paragraph>
          If you would like to use your own lease without creating one with
          Keyhook, you may choose to do so below.
        </Paragraph>
      </>
    );
  },
};

export default PrepareTenancySwitchToMigrateModal;
