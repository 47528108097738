import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import bytes from 'bytes';
import clsx from 'clsx';
import {Accept} from 'react-dropzone';

import Dropzone from './Dropzone';
import FileItems from './FileItems';
import {FileItem, FileItemStatus, FileUploaderStorageHandler} from './types';
import {generateFileItemId} from './utils/generate-file-item-id';
import getMimeTypeFromUrl from './utils/mime-type-from-url';

interface BaseFileUploaderProps {
  /**
   * Whether the file uploader should allow only one file
   * or multiple files.
   */
  // TODO: Support single file uploads
  // mode: 'single' | 'multiple';

  /**
   * The types of files that are allowed to be uploaded.
   */
  accept?: Accept;

  /**
   * The maximum size per file that is allowed to be uploaded (can be
   * either a number indicating bytes or a string with a unit like '5MB').
   */
  maxFileSize?: number | string;

  /**
   * The maximum number of files that are allowed to be uploaded
   */
  // maxFiles?: number; TODO: Support limiting file quantity

  /**
   * Files that have already been uploaded.
   */
  files: string[];

  /**
   * Invoked with the new list of files upon either successfully
   * uploading, deleting, or re-ordering the files.
   */
  onChange: (files: string[]) => void;

  /**
   * Invoked when there are files that are being uploaded.
   */
  onActive?: () => void;

  /**
   * Invoked when there are no longer any files being uploaded.
   */
  onIdle?: () => void;

  /**
   * The handler for uploading and storing files.
   */
  storageHandler: FileUploaderStorageHandler;

  /**
   * Indicates to the user that the first file is the main file.
   * (note that this is only a visual setting and any functional
   * result of a main file must still be handled by the parent component).
   */
  firstFileIsMain?: boolean;
}

interface SingleFileUploaderProps {
  mode: 'single';
  maxFiles?: never;
}

interface MultipleFileUploaderProps {
  mode: 'multiple';
  maxFiles?: number;
}

type ModeProps = SingleFileUploaderProps | MultipleFileUploaderProps;

type FileUploaderProps = BaseFileUploaderProps & ModeProps;

const convertInputFileDataToFileItem = (data: string): FileItem => {
  const isUrl = data.startsWith('http://') || data.startsWith('https://');
  return {
    id: generateFileItemId(),
    status: FileItemStatus.DeterminingMimeType,
    mimeType: null,
    url: isUrl ? data : null,
    uploadedFileData: isUrl ? null : JSON.parse(data),
    localFile: null,
  };
};

const FileUploader: FunctionComponent<FileUploaderProps> = ({
  // mode, // TODO: Support single file uploads
  accept,
  maxFileSize,
  // maxFiles, TODO: Support limiting file quantity
  files,
  onChange,
  onActive,
  onIdle,
  storageHandler,
  firstFileIsMain = false,
}) => {
  /**
   * The file "items" are a set of files that are abstracted away from the
   * provided files and files that are returned via the onChange function.
   * This allows for the parent component to not have to worry about the
   * file upload process and instead just worry about the files that have
   * actually been uploaded. The file items include additional information
   * about the current files and also includes files that are in the process
   * of being uploaded or have had errors uploading.
   */
  const initialFileItems = useMemo(
    () => files.map((data) => convertInputFileDataToFileItem(data)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const [fileItems, setFileItems] = useState<FileItem[]>(initialFileItems);

  /**
   * Parse the maxFileSize prop if provided as bytes.
   */
  const parsedMaxFileSize = useMemo<number | undefined>(() => {
    if (!maxFileSize) {
      return undefined;
    }
    return typeof maxFileSize === 'number'
      ? maxFileSize
      : bytes.parse(maxFileSize);
  }, [maxFileSize]);

  /**
   * Determine whether there are any files currently being uploaded.
   */
  const hasUploadingFiles = useMemo(
    () => fileItems.some(({status}) => status === FileItemStatus.Uploading),
    [fileItems],
  );

  /**
   * Track the state of whether files are being uploaded. (We need this
   * in addition to the hasUploadingFiles memo because we only want this
   * to change when all files have finished uploading, not when a single
   * file has finished).
   */
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    if (hasUploadingFiles && !isUploading) {
      setIsUploading(true);
    } else if (!hasUploadingFiles && isUploading) {
      setIsUploading(false);
    }
  }, [hasUploadingFiles, isUploading]);

  /**
   * Notify the parent when the file uploader becomes either active or idle.
   */
  useEffect(() => {
    if (isUploading) {
      onActive();
    } else {
      onIdle();
    }
  }, [isUploading, onActive, onIdle]);

  /**
   * Check if there are any differences between the successfully uploaded
   * file items and the files that have been provided to the component,
   * and notify the parent of the change if there are differences.
   */
  const handleFileItemsUpdated = useCallback(() => {
    const newFiles = fileItems
      .filter(({status}) =>
        [FileItemStatus.Uploaded, FileItemStatus.DeterminingMimeType].includes(
          status,
        ),
      )
      .map(({url, uploadedFileData}) =>
        uploadedFileData ? JSON.stringify(uploadedFileData) : url,
      );

    if (JSON.stringify(files) !== JSON.stringify(newFiles)) {
      onChange(newFiles);
    }
  }, [fileItems, files, onChange]);

  /**
   * Invoke the change handler to notify the parent component
   * if necessary when there are changes to the file items.
   */
  useEffect(() => {
    if (fileItems) {
      handleFileItemsUpdated();
    }
  }, [fileItems, handleFileItemsUpdated]);

  /**
   * Determines the MIME type for a remote file and updates the file item
   * with the determined type.
   */
  const determineMimeTypeAndUpdate = useCallback(async (fileItem: FileItem) => {
    const mimeType = await getMimeTypeFromUrl(fileItem.url);
    setFileItems((current) => {
      const index = current.findIndex(({url}) => url === fileItem.url);
      return Object.assign([], current, {
        [index]: {
          ...fileItem,
          status: FileItemStatus.Uploaded,
          mimeType,
        },
      });
    });
  }, []);

  /**
   * Uploads a file and updates the file item with the resulting
   * uploaded file data.
   */
  const uploadFileAndUpdate = useCallback(
    async (fileItem: FileItem) => {
      let newFileItem = fileItem;

      try {
        const uploadedFileData = await storageHandler.upload({
          file: fileItem.localFile,
          onProgress: (progressEvent) => {
            // TODO: Handle progress
          },
        });

        /**
         * Set the URL of the uploaded file on the file item and set the
         * status to indicate it has been uploaded.
         */
        newFileItem = {
          ...fileItem,
          status: FileItemStatus.Uploaded,
          uploadedFileData,
        };
      } catch (error) {
        /**
         * Set the status on the file item to indicate
         * that there was an error uploading.
         */
        newFileItem = {
          ...fileItem,
          status: Object.values(FileItemStatus).includes(error)
            ? error
            : FileItemStatus.ErrorUploading,
        };
      }

      /**
       * Update the file item in state.
       */
      setFileItems((current) => {
        const index = current.findIndex(
          ({localFile}) => localFile === fileItem.localFile,
        );
        return Object.assign([], current, {
          [index]: newFileItem,
        });
      });
    },
    [storageHandler],
  );

  /**
   * When file items are added to fileItems, we need to check if any of them
   * have the status of determining MIME type. If they do, we need to determine
   * the MIME type and then update the file item.
   */
  useEffect(() => {
    const itemsToDetermineMimeType = fileItems.filter(
      ({status}) => status === FileItemStatus.DeterminingMimeType,
    );

    itemsToDetermineMimeType.forEach((fileItem) =>
      determineMimeTypeAndUpdate(fileItem),
    );
  }, [fileItems, determineMimeTypeAndUpdate]);

  /**
   * When file items are added to fileItems, we need to check if any of them
   * require uploading. If they do, we need to upload them and then update
   * the file item.
   */
  useEffect(() => {
    const itemsToUpload = fileItems.filter(
      ({status}) => status === FileItemStatus.Uploading,
    );

    itemsToUpload.forEach((fileItem) => uploadFileAndUpdate(fileItem));
  }, [fileItems, uploadFileAndUpdate]);

  // /**
  //  * TODO: Handle converting input files to file items when additional files
  // are added outside of this component after the initial mount.
  //  */
  // useEffect(() => {
  // TODO
  // }, [files]);

  const onFilesAdded = useCallback((newFileItems: FileItem[]) => {
    setFileItems((current) => [...current, ...newFileItems]);
  }, []);

  const onDelete = useCallback((fileItem: FileItem) => {
    setFileItems((current) => current.filter((item) => item !== fileItem));
  }, []);

  const onReorder = useCallback((fileItems: FileItem[]) => {
    setFileItems(fileItems);
  }, []);

  return (
    <div className={clsx('flex-1')}>
      <FileItems
        dropzone={
          <Dropzone
            accept={accept}
            // maxFiles={maxFiles} TODO: Support limiting file quantity
            maxFileSize={parsedMaxFileSize}
            onFilesAdded={onFilesAdded}
          />
        }
        onDelete={onDelete}
        onReorder={onReorder}
        firstFileIsMain={firstFileIsMain}>
        {fileItems}
      </FileItems>
    </div>
  );
};

export default FileUploader;
