import {useEffect, useState} from 'react';

import {useParams} from 'react-router';

import ChatDetailBody from 'components/chat2/ChatDetailBody';
import ChatDetailHeader from 'components/chat2/ChatDetailHeader';
import ChatInputBox from 'components/chat2/ChatInputBox';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import User from 'models/users/User';
import {type ChatChannel, usePusherService} from 'services/PusherService';
import {usePageVisit, useTitle} from 'utilities/hooks';

const ChatDetailPage = () => {
  useTitle('Chat');
  usePageVisit('ChatDetailPage');

  const {roomKey} = useParams();

  const {channels} = usePusherService();

  const [channel, setChannel] = useState<ChatChannel | null>(null);
  const [users, setUsers] = useState<User[]>([]);

  const {activeAccountRole} = useLocalUserSettings();

  /**
   * Set the current channel whenver the channels or room key are updated.
   */
  useEffect(() => {
    const splitKey = roomKey.split('-');
    const chanName = `${splitKey[0]}Channel`;

    if (splitKey.length == 2) {
      const chan = channels.filter(
        (c) =>
          c.identifier === chanName && c.model.id.toString() === splitKey[1],
      );

      if (chan.length > 0) {
        setChannel(chan[0]);
      }
    }
  }, [channels, setChannel, roomKey]);

  useEffect(() => {
    if (channel && channel.identifier === 'PropertyChannel') {
      if (activeAccountRole === 'Landlord') {
        Property.select({
          properties: ['id'],
          tenancies: ['id'],
          users: ['name', 'id'],
          tenancy_memberships: ['id'],
        })
          .includes([
            {current_tenancy: {tenancy_memberships: 'renter'}},
            'landlord',
          ])
          .find(channel.model.id)
          .then((p) => {
            if (p.data.currentTenancy) {
              if (p.data.currentTenancy.tenancyMemberships) {
                const renters = p.data.currentTenancy.tenancyMemberships.map(
                  (m) => m.renter,
                ) as User[];

                const chatters: User[] = renters.concat(p.data.landlord);

                setUsers(chatters);
              }
            }
          });
      } else {
        Tenancy.select({
          properties: ['id'],
          tenancies: ['id'],
          users: ['name', 'id'],
          tenancy_memberships: ['id'],
        })
          .includes({tenancy_memberships: 'renter', property: 'landlord'})
          .where({propertyId: channel.model.id})
          .first()
          .then((t) => {
            if (t.data) {
              if (t.data.tenancyMemberships) {
                const renters = t.data.tenancyMemberships.map(
                  (m) => m.renter,
                ) as User[];

                const chatters: User[] = renters.concat(
                  t.data.property.landlord,
                );

                setUsers(chatters);
              }
            }
          });
      }
    }
  }, [channel, activeAccountRole]);

  if (!channel) {
    return (
      <PageWrapper title="Chat">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Chat" disablePadding>
        <div className="relative max-h-full flex flex-col bg-gray-50">
          <div className="flex-grow w-full max-w-7xl mx-auto lg:flex">
            <div className="bg-white flex-1 p-2 sm:pb-6 justify-between flex flex-col h-full flex">
              <ChatDetailHeader channel={channel} users={users} />
              <ChatDetailBody channel={channel} users={users} />
              <ChatInputBox channel={channel} />
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
};

export default ChatDetailPage;
