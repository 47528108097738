import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragStartEvent,
  DragEndEvent,
  DragOverlay,
} from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import clsx from 'clsx';
import {motion, AnimatePresence} from 'framer-motion';

export type SortableItemId = string | number;

export interface SortableItem {
  id: SortableItemId;
  dragOverlay: ReactNode;
}

interface SortableItemsProps {
  children: (activeId: number) => ReactNode;
  items: SortableItem[];
  onReorder: (id: SortableItemId, delta: number) => void;
  onDragStart: () => void;
  onDragEnd: () => void;
  disabled?: boolean;
}

const SortableItems: FunctionComponent<SortableItemsProps> = ({
  children,
  items,
  onReorder,
  onDragStart,
  onDragEnd,
  disabled = false,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const [activeId, setActiveId] = useState(null);

  const dragOverlay = useMemo(
    () => items.find(({id}) => id === activeId)?.dragOverlay ?? null,
    [items, activeId],
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      onDragStart();
      setActiveId(event.active.id);
    },
    [onDragStart],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const {active, over} = event;
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const delta = newIndex - oldIndex;
      onReorder(active.id, delta);
      setActiveId(null);
      onDragEnd();
    },
    [items, onReorder, onDragEnd],
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}>
      <SortableContext
        items={items}
        strategy={rectSortingStrategy}
        disabled={disabled}>
        {children(activeId)}
      </SortableContext>
      <DragOverlay adjustScale={true}>
        <div
          // TODO: Resolve why the shadow isn't animating
          className={clsx(
            'transition-all duration-500',
            'cursor-grabbing',
            activeId ? 'drop-shadow-2xl' : 'drop-shadow-none',
          )}>
          {dragOverlay}
        </div>
      </DragOverlay>
    </DndContext>
  );
};

interface SortableItemProps {
  children: ReactNode;
  id: SortableItemId;
}

export const SortableItem: FunctionComponent<SortableItemProps> = ({
  children,
  id,
}) => {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({id});
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="cursor-grab">
      {children}
    </div>
  );
};

export default SortableItems;
