import {FunctionComponent, useMemo, useState} from 'react';

import clsx from 'clsx';
import dayjs, {type Dayjs} from 'dayjs';
import 'react-day-picker/dist/style.css';
import {HiCheckCircle} from 'react-icons/hi';

interface SelectionModeButtonProps {
  /**
   * The label for the button.
   */
  label: string;
  /**
   * Whether the selection mode is currently active.
   */
  active: boolean;
  /**
   * The funciton invoked when the button is clicked.
   */
  onClick: () => void;
  /**
   * The current value for the selection mode
   * (i.e. a start or end date).
   */
  selectedDate?: Date;
}

type SelectionModeButtonComponent = FunctionComponent<SelectionModeButtonProps>;

const SelectionModeButton: SelectionModeButtonComponent = ({
  label,
  active,
  onClick,
  selectedDate = null,
}) => {
  /**
   * Determine whether a current date value exists for
   * the selection mode.
   */
  const hasSelectedDate = useMemo(() => !!selectedDate, [selectedDate]);

  /**
   * Create a readable label for the date.
   */
  const dateLabel = useMemo(
    () => (hasSelectedDate ? dayjs(selectedDate).format('DD/MM/YYYY') : null),
    [hasSelectedDate, selectedDate],
  );

  return (
    <button
      onClick={onClick}
      className={clsx(
        'flex-1',
        'bg-transparent',
        'rounded-lg',
        'py-3 px-4',
        'flex flex-col items-start',
        'transition-all',
        'duration-200',
        !active &&
          clsx(
            'cursor-pointer',
            'origin-center scale-100 active:scale-95',
            'hover:bg-brand-75 active:bg-transparent',
          ),
      )}>
      <span
        className={clsx(
          'font-medium',
          'transition-colors duration-200',
          active ? 'text-brand-500' : 'text-brand-850',
        )}>
        {label}
      </span>
      <div className="flex flex-row items-center gap-x-1 relative w-28">
        <span
          className={clsx(
            'text-sm',
            'transition duration-300',
            hasSelectedDate
              ? 'text-brand-500 text-opacity-100'
              : 'text-brand-850 text-opacity-70',
          )}>
          {hasSelectedDate ? dateLabel : 'Please select'}
        </span>
        <HiCheckCircle
          className={clsx(
            'text-green-600',
            'w-4 h-4',
            'transition duration-300',
            hasSelectedDate ? 'scale-100' : 'scale-0',
          )}
        />
      </div>
    </button>
  );
};

export default SelectionModeButton;
