import {FunctionComponent} from 'react';

import clsx from 'clsx';
import {motion} from 'framer-motion';

import Listing from 'models/listings/Listing';

import ListingCard from './ListingCard/ListingCard';

interface ListingCardGridProps {
  skeletonCount: number;
  listings?: Listing[];
}

const ListingCardGrid: FunctionComponent<ListingCardGridProps> = ({
  listings,
  skeletonCount,
}) => {
  return (
    <div className="@container/listings">
      <motion.div
        transition={{
          staggerChildren: 0.1,
        }}
        initial="hidden"
        animate="show"
        className={clsx(
          'grid gap-6',
          'grid-cols-1 @2xl/listings:grid-cols-2 @5xl/listings:grid-cols-3 @7xl/listings:grid-cols-4',
        )}>
        {!listings
          ? [...Array(skeletonCount).keys()].map((index) => (
              <ListingCard key={index} />
            ))
          : listings.map((listing) => (
              <ListingCard key={listing.id} listing={listing} />
            ))}
      </motion.div>
    </div>
  );
};

export default ListingCardGrid;
