import {Model, Attr, HasMany, BelongsTo, HasOne} from 'spraypaint';

import {
  getFlowFromStep,
  OBFS,
  OnboardingFlow,
  OnboardingFlowStep,
  onboardingFlowStepOrder,
} from 'constants/onboarding-flow-steps';
import AccountingRecord from 'models/accounting/AccountingRecord';
import AccountingRecurringRecord from 'models/accounting/AccountingRecurringRecord';
import ApplicationRecord from 'models/ApplicationRecord';
import BillingMethod from 'models/billing/BillingMethod';
import ExternalValuation from 'models/insights/ExternalValuation';
import Listing from 'models/listings/Listing';
import Document from 'models/properties/Document';
import {InsurancePolicy} from 'models/properties/InsurancePolicy';
import RoomPlan from 'models/properties/RoomPlan';
import Tenancy from 'models/properties/Tenancy';
import PreferredTradesman from 'models/service_requests/PreferredTradesman';
import User from 'models/users/User';

export type PaymentMethodType = 'rent' | 'card';
export type PaymentPeriod = 'weekly' | 'yearly';

@Model()
class Property extends ApplicationRecord {
  static jsonapiType = 'properties';

  @Attr({persist: false}) completed: boolean;

  @Attr() noTenancyActionType?: string | null;

  @Attr() lastOnboardingStepCompleted: string | null | undefined;
  @Attr() completedProfileSteps: string[];

  @Attr() streetAddress: string;
  @Attr() suburb: string;
  @Attr() city: string;
  @Attr() state: string;
  @Attr() postcode: string;
  @Attr() country: string;

  @Attr() bathrooms: number;
  @Attr() bedrooms: number;
  @Attr() garages: number;

  @Attr() propertyType: string;

  @Attr() addressMeta: object;

  @Attr() chattels: Record<string, number>;
  @Attr() courtesyChattels: Record<string, number>;

  @Attr() inspectionPeriod: number;

  @Attr() maxTenants: number;

  @Attr() petsAllowed: boolean;
  @Attr() smokersAllowed: boolean;

  @Attr() authorityToFixLimit: number;

  @Attr() allowedPetTypes: string[];

  @Attr() insurancePolicies: InsurancePolicy[];
  @Attr() insuranceCoversTenants: boolean;

  @Attr() mainImage: string;

  @Attr() paymentMethodType: PaymentMethodType;
  @Attr() billingMethodId: number;
  @Attr() paymentPeriod: PaymentPeriod;

  @Attr() bankAccountName: string;
  @Attr() bankAccountNumber: string;

  @Attr() tradingName: string;

  @Attr() purchasePrice: number;
  @Attr() purchaseDate: string;
  @Attr() mortgageInterestRate: number;
  @Attr() mortgageRepaymentFrequency:
    | null
    | 'daily'
    | 'weekly'
    | 'fortnightly'
    | 'monthly'
    | 'quarterly'
    | 'semi-annually'
    | 'annually';
  @Attr() mortgageRenewalDate: string;

  @Attr({persist: false}) tenanciesCount: number;

  @Attr({persist: false}) isPendingTransfer: boolean;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr({persist: false}) landlordId: string;

  @Attr({persist: false}) extraInformation: any;

  @BelongsTo('users') landlord: User;

  @HasMany('tenancies') tenancies: Tenancy[];
  @HasOne('tenancies') currentTenancy: Tenancy;
  @HasOne('billing_methods') billingMethod: BillingMethod;
  @HasMany('documents') documents: Document[];
  @HasMany('documents') publicDocuments: Document[];
  @HasMany('preferred_tradesmen') preferredTradesmans: PreferredTradesman[];
  @HasOne('room_plans') roomPlan: RoomPlan;
  @HasMany('listings') listings: Listing[];

  @HasMany('accounting_records') accountingRecords: AccountingRecord[];
  @HasMany('accounting_recurring_records')
  accountingRecurringRecords: AccountingRecurringRecord[];

  @HasMany('external_valuations') externalValuations: ExternalValuation[];

  /**
   * Indicate that a post-onboarding property profile step has been completed.
   */
  public setProfileStepAsCompleted(step: string) {
    if (!this.completedProfileSteps.includes(step)) {
      this.completedProfileSteps.push(step);
    }
  }

  /**
   * An alternative to setting a value directly on noTenancyActionType
   * that makes it clearer that the current onboarding flow is being set.
   */
  public setOnboardingFlow(
    flow: Exclude<OnboardingFlow, OnboardingFlow.Undetermined>,
  ) {
    this.noTenancyActionType = flow;
  }

  /**
   * Indicate that an onboarding step has been completed (this updates
   * the last onboarding step completed attribute).
   */
  public setOnboardingStepAsCompleted(step: OnboardingFlowStep) {
    this.lastOnboardingStepCompleted = step;
  }

  /**
   * Explicitly set the current onboarding flow step.
   */
  public setOnboardingStep(step: OnboardingFlowStep) {
    /**
     * Since we are updating the lastOnboardingStepCompleted attribute,
     * we need to find the previous step to the step that is being set
     * as current.
     */
    const previousStep = onboardingFlowStepOrder[step].previous(this);

    /**
     * No previous step to the step provided could be determined.
     */
    if (!previousStep) {
      console.error(
        'Unable to set onboarding step - the previous step for the step provided was unable to be determined.',
      );
      return;
    }

    /**
     * Update the lastOnboardingStepCompleted attribute.
     */
    this.lastOnboardingStepCompleted = previousStep;
  }

  /**
   * Evaluates the current onboarding step for the property
   * based on the last_onboarding_step_completed attribute.
   */
  get currentOnboardingStep(): OnboardingFlowStep | null {
    /**
     * Return null if the last onboarding step has not been set,
     * or has been set to an invalid value not included in the
     * onboarding flow step definitions.
     */
    if (
      !this.lastOnboardingStepCompleted ||
      !Object.keys(onboardingFlowStepOrder).includes(
        this.lastOnboardingStepCompleted,
      )
    ) {
      return null;
    }

    /**
     * Return the next step for the last onboarding step completed
     * as the current step.
     */
    return onboardingFlowStepOrder[
      this.lastOnboardingStepCompleted as OnboardingFlowStep
    ].next(this);
  }

  get previousOnboadingStep(): OnboardingFlowStep | null {
    /**
     * Return null if there is no current onboarding flow step.
     */
    if (!this.currentOnboardingStep) {
      return null;
    }

    /**
     * Return the previous step of the current step.
     */
    return onboardingFlowStepOrder[this.currentOnboardingStep].previous(this);
  }

  get nextOnboadingStep(): OnboardingFlowStep | null {
    /**
     * Return null if there is no current onboarding flow step.
     */
    if (!this.currentOnboardingStep) {
      return null;
    }

    /**
     * Return the previous step of the current step.
     */
    return onboardingFlowStepOrder[this.currentOnboardingStep].next(this);
  }

  /**
   * Evaluates the current onboarding flow for the property
   * based on the current onboarding step.
   */
  get currentOnboardingFlow(): OnboardingFlow | null {
    const currentOnboardingStep = this.currentOnboardingStep;
    return currentOnboardingStep
      ? getFlowFromStep(currentOnboardingStep)
      : null;
  }

  /**
   * Evaluates the number of the current onboarding step for the property
   * by traversing the flow up until the current step.
   */
  get currentOnboardingStepNumber(): number {
    /**
     * If there is no current onboarding step, then we cannot
     * determined the step number.
     */
    if (!this.currentOnboardingStep) {
      return 0;
    }

    /**
     * Create a temporary clone of the property for traversal
     * to avoid making any changes to the original property.
     */
    const clone = this.dup();

    /**
     * Set the last onboarding step completed to the first step.
     */
    clone.lastOnboardingStepCompleted = OBFS.AddPropertyAddress;
    let totalCompleted = 1;

    /**
     * Traverse through the steps from the beginning of the flow, incrementing
     * the counter, until we reach the current onboarding flow step to find
     * the total number of steps completed.
     */
    while (clone.currentOnboardingStep !== this.currentOnboardingStep) {
      clone.setOnboardingStepAsCompleted(clone.currentOnboardingStep);
      totalCompleted += 1;
    }

    /**
     * Return the evaluated total number of steps completed with an
     * increment of one to include the current step, reflecting the
     * current step number.
     */
    return totalCompleted + 1;
  }

  /**
   * Estimates the total remaining steps for the current
   * onboarding flow by attempting to traverse the flow.
   */
  get estimatedRemainingOnboardingSteps(): number {
    /**
     * If there is no current onboarding step, then there
     * are no remaining steps to be completed.
     */
    if (!this.currentOnboardingStep) {
      return 0;
    }

    /**
     * Create a temporary clone of the property for traversal
     * to avoid making any changes to the original property.
     */
    const clone = this.dup();

    let totalRemaining = 0;

    /**
     * Traverse through the steps, incrementing the counter, until we
     * either reached the end of the flow, or we reach a step where
     * we cannot automatically determine the next step.
     */
    while (
      clone.currentOnboardingStep !== OBFS.Completed &&
      clone.currentOnboardingStep !== OBFS.Undetermined
    ) {
      clone.setOnboardingStepAsCompleted(clone.currentOnboardingStep);
      totalRemaining += 1;
    }

    return totalRemaining;
  }

  get currentListing(): Listing | null {
    if (this.listings === null) {
      console.error('Listings have not been loaded for this property.');
      return null;
    } else {
      return this.listings.find((listing) => listing.isCurrent);
    }
  }

  get hasCurrentListing(): boolean {
    return !!this.currentListing;
  }
}

export default Property;
