import {useState} from 'react';

import {toast} from 'react-toastify';
import ReactCodeInput from 'react-verification-code-input';

import PageWrapper from 'components/PageWrapper';
import {API_URL} from 'globals/app-globals';
import useAuth from 'services/useAuth';
import {usePageVisit, useTitle} from 'utilities/hooks';

const ConfirmEmailPage = () => {
  useTitle('Confirm Email');
  usePageVisit('ConfirmEmailPage');

  const [code, setCode] = useState('');
  const [errors, setErrors] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const {currentUser, setEmailConfirmed} = useAuth();

  const handleSubmit = async () => {
    setSubmitting(true);
    if (code.length !== 4) {
      setErrors('Code is not 4 digits long');
    } else {
      setErrors('');

      // TODO: Move the below request into the useAuth hook
      const response = await fetch(
        API_URL + `/users/${currentUser.id}/confirm_email.json`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser.email,
          },
          body: JSON.stringify({code}),
        },
      );
      const body = await response.json();

      if (response.ok) {
        await setEmailConfirmed();
      } else {
        setErrors(body.errors);
      }
    }

    setSubmitting(false);
  };

  const resendEmail = async () => {
    setSubmitting(true);

    const response = await fetch(
      API_URL + `/users/${currentUser.id}/resend_email_confirmation.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
        },
      },
    );

    const body = await response.json();

    if (response.ok) {
      toast.success('The verification code email has been resent to you.');
    } else {
      setErrors(body.errors);
    }

    setSubmitting(false);
  };

  return (
    <PageWrapper title="Confirm Email" backEnabled>
      <div className="max-w-md mx-auto pl-8 pr-8 lg:pl-24 lg:pr-24 mb-24 lg:mb-32 border-3 border-gray-200 my-24 rounded-xl flex justify-center">
        <div className="min-w-[300px]">
          <h2 className="mt-8 mb-2 flex items-center text-xl leading-[115%] md:text-2xl font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Confirm your email address
          </h2>

          <div className="space-y-8 mb-4">
            <p>
              We have sent you an email with a 4 digit verification code, please
              enter it below.
            </p>
            <div className="flex justify-center">
              <ReactCodeInput
                type="number"
                fields={4}
                placeholder={['*', '*', '*', '*']}
                onChange={setCode}
              />
            </div>

            {errors && errors.length > 0 ? (
              <p className="mt-2 text-sm text-error">{errors}</p>
            ) : null}

            <button
              className="btn btn-block btn-primary rounded-full"
              type="button"
              disabled={submitting}
              onClick={handleSubmit}>
              {submitting ? 'Verifying' : 'Verify Email'}
            </button>

            <button
              className="btn btn-block btn-neutral mt-4 rounded-full"
              disabled={submitting}
              onClick={resendEmail}>
              Resend Verification Code Email
            </button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ConfirmEmailPage;
