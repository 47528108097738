import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import AccountingReceipt from 'models/accounting/AccountingReceipt';
import ApplicationRecord from 'models/ApplicationRecord';
import Property from 'models/properties/Property';
@Model()
class AccountingRecord extends ApplicationRecord {
  static jsonapiType = 'accounting_records';

  @Attr() subtotalAmount: string;
  @Attr() gstAmount: string;
  @Attr() totalAmount: string;

  @Attr() date: string;

  @Attr() description: string;
  @Attr() notes: string;

  @Attr({persist: false}) accountingReceiptsCount: number;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() propertyId: string;
  @Attr({persist: false}) sourceableId: string;
  @Attr({persist: false}) sourceableType: string;

  @BelongsTo('properties') property: Property;
  @HasMany('accounting_receipts') accountingReceipts: AccountingReceipt[];
}

export default AccountingRecord;
