import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  ClipboardListIcon,
  CogIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from '@heroicons/react/outline';
import {HiOutlinePlus} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';

import ConfirmEmailAlert from 'components/auth/ConfirmEmailAlert';
import LoadingView from 'components/common/LoadingView';
import DemoPropertyCard from 'components/demo/DemoPropertyCard';
import LandlordWelcomeModal from 'components/onboarding/LandlordWelcomeModal';
import PageWrapper from 'components/PageWrapper';
import PromoCodeEntryBanner from 'components/PromoCodeEntry/PromoCodeEntryBanner';
import AutocompletePropertyAddressModal from 'components/property/landlord/AutocompletePropertyAddressModal';
import LandlordPropertyIndexCard from 'components/property/landlord/PropertyIndexCard';
import {Modal} from 'components_sb/layout';
import useCurrentUserFlag from 'hooks/useCurrentUserFlag';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {usePushPermissions} from 'providers/PushPermissions/_PushPermissionsProvider';
import {useTour} from 'providers/ToursProvider';
import useGlobalUIStore from 'stores/GlobalUIStore';
import LandlordWelcome from 'tours/LandlordWelcome';
import {Action} from 'types/actions';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {paginationArray} from 'utilities/paginationArray';
import {toCurrency} from 'utilities/StringHelpers';

import AddPropertyCard from './AddPropertyCard';

const {useModal} = Modal.Imperative;

const PropertyIndexPage = () => {
  useTitle('Dashboard');
  usePageVisit('PropertyIndexPage');

  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);

  const isMobile = !useTailwindBreakpoint('sm');

  const [propertyIndexSettings, setPropertyIndexSettings] = useGlobalUIStore(
    (state) => [state.propertyIndexSettings, state.setPropertyIndexSettings],
  );

  const {isLoading, error, data} = useQuery(
    'landlord-properties-index',
    async () => {
      const res = await Property.select([
        'street_address',
        'suburb',
        'city',
        'main_image',
        'tenancies_count',
      ])
        .includes({
          currentTenancy: [
            'active_tenancy_memberships',
            'open_service_requests',
          ],
        })
        .stats({total: 'count'})
        .page(propertyIndexSettings.page)
        .per(propertyIndexSettings.perPage)
        .order({
          [propertyIndexSettings.sortField]:
            propertyIndexSettings.sortDirection,
        } as any)
        .all();

      return res;
    },
    {retry: 1},
  );

  const {isLoading: statsIsLoading, data: statData} = useQuery(
    'landlord-property-stats-index',
    async () => {
      const tenancyStats = await Tenancy.where({
        status: ['active', 'active_periodic', 'awaiting_start_date'],
      })
        .select(['id'])
        .stats({
          total: 'count',
          total_rent: 'sum',
          next_inspection_date: 'minimum',
          end_date: 'minimum',
          open_service_requests: 'count',
        })
        .all();

      if (tenancyStats.meta.stats) {
        return {
          stats: {
            total_rent: tenancyStats.meta.stats.total_rent.sum,
            total_tenancies: tenancyStats.meta.stats.total.count,
            end_date_min: tenancyStats.meta.stats.end_date.minimum,
            next_inspection_date_min:
              tenancyStats.meta.stats.next_inspection_date.minimum,
            open_service_requests:
              tenancyStats.meta.stats.open_service_requests.count,
          },
        };
      } else {
        return {
          stats: {
            total_rent: null,
            total_tenancies: null,
            end_date_min: null,
            next_inspection_date_min: null,
            open_service_requests: null,
          },
        };
      }
    },
  );
  const queryClient = useQueryClient();

  const changeSettings = (key: string, value: any) => {
    const newSettings = propertyIndexSettings;
    if (key === 'sortDirection') {
      value = propertyIndexSettings.sortDirection === 'asc' ? 'desc' : 'asc';
    }
    if (key === 'viewType') {
      value = propertyIndexSettings.viewType === 'list' ? 'grid' : 'list';
    }

    // @ts-ignore
    newSettings[key] = value;
    setPropertyIndexSettings(newSettings);
    queryClient.invalidateQueries('landlord-properties-index');
  };

  let totalProperties = 0;
  let fromPagination = 0;
  let toPagination = 0;
  if (data && data.meta) {
    totalProperties = data.meta.stats.total.count;
    fromPagination = propertyIndexSettings.page * propertyIndexSettings.perPage;
    toPagination = fromPagination + data.data.length;
  }

  // ========================================================================
  // Welcome Tour
  // ========================================================================
  const openModal = useModal();
  const landlordDashboardTour = useTour(LandlordWelcome);
  const [showingWelcomeModal, setShowingWelcomeModal] = useState(false);
  const pushPermissions = usePushPermissions();

  const showWelcomeModal = useCallback(async () => {
    const startTour = await openModal(LandlordWelcomeModal);
    setShowingWelcomeModal(false);
    if (startTour) {
      landlordDashboardTour.start();
    } else {
      landlordDashboardTour.skip();
    }
  }, [setShowingWelcomeModal, landlordDashboardTour, openModal]);

  /**
   * Present the welcome modal to the user automatically once the page content
   * has loaded if they have not added a property and have not yet been presented
   * the tour before.
   */
  const hideDemoPropertyFlag = useCurrentUserFlag('hide_demo_property');
  useEffect(() => {
    if (
      // Don't show in the testing environment
      process.env.TARGET_ENV !== 'test' &&
      // Wait for the page content to load
      !isLoading &&
      !error &&
      // Don't re-show if already showing
      !showingWelcomeModal &&
      // Wait for permissions to be either denied or accepted before showing tour
      !!pushPermissions.hasResponded &&
      // Wait for the tour flag to be checked
      landlordDashboardTour.isReady &&
      !landlordDashboardTour.hasBeenPresented &&
      // Wait for the demo property flag to be checked
      hideDemoPropertyFlag.isReady &&
      !hideDemoPropertyFlag.value
    ) {
      setShowingWelcomeModal(true);
      setTimeout(() => showWelcomeModal(), 1000);
    }
  }, [
    pushPermissions,
    isLoading,
    error,
    showingWelcomeModal,
    showWelcomeModal,
    landlordDashboardTour,
    hideDemoPropertyFlag,
    openModal,
  ]);
  // ========================================================================

  const Header = () => {
    let nextInspectionDate: string;
    let nextInspectionSubtitle: string = null;
    let nextLeaseExpires: string;

    if (statData && statData.stats) {
      if (statData.stats.next_inspection_date_min) {
        const today = new Date();
        const inspectionDate = new Date(
          statData.stats.next_inspection_date_min,
        );

        const timeDifference = inspectionDate.getTime() - today.getTime();
        if (timeDifference < 0) {
          nextInspectionDate = 'Overdue';
        } else {
          nextInspectionDate = Number(
            timeDifference / (1000 * 3600 * 24),
          ).toFixed(0);
          nextInspectionSubtitle = 'Days';
        }
      }

      if (statData.stats.end_date_min) {
        const today = new Date();
        const leaseDate = new Date(statData.stats.end_date_min);

        const timeDifference = leaseDate.getTime() - today.getTime();
        nextLeaseExpires = Number(timeDifference / (1000 * 3600 * 24)).toFixed(
          0,
        );
      } else {
        nextLeaseExpires = 'N/A';
      }
    }

    return (
      <div>
        {!statsIsLoading && statData && totalProperties > 0 && (
          <div className="w-full stats mmd:grid-flow-row shadow-xl mb-8">
            <div className="stat mmd:pb-0">
              <div className="stat-figure text-secondary">
                <CurrencyDollarIcon className="w-8 block md:hidden lg:block text-secondary" />
              </div>
              <div className="stat-title mlg:text-sm">Total Rent</div>
              <div className="stat-value mlg:text-xl">
                {toCurrency(statData.stats.total_rent)}
              </div>
              <div className="stat-desc">Weekly</div>
            </div>
            <div className="stat mmd:pb-0">
              <div className="stat-figure text-secondary">
                <CogIcon className="w-8 block md:hidden lg:block text-secondary" />
              </div>
              <div className="stat-title mlg:text-sm">Maintenance Requests</div>
              <div className="stat-value mlg:text-xl">
                {statData.stats.open_service_requests}
              </div>
              <div className="stat-desc">Needing Action</div>
            </div>
            {nextInspectionDate && nextInspectionDate.length > 0 && (
              <div className="stat mmd:pb-0">
                <div className="stat-figure text-secondary">
                  <ClipboardListIcon className="w-8 block md:hidden lg:block text-secondary" />
                </div>
                <div className="stat-title mlg:text-sm">Next Inspection</div>
                <div className="stat-value mlg:text-xl">
                  {nextInspectionDate}
                </div>
                <div className="stat-desc">{nextInspectionSubtitle}</div>
              </div>
            )}

            {nextLeaseExpires && nextLeaseExpires.length > 0 && (
              <div className="stat mmd:pb-2">
                <div className="stat-figure text-secondary">
                  <DocumentTextIcon className="w-8 block md:hidden lg:block text-secondary" />
                </div>
                <div className="stat-title mlg:text-sm">Next Lease Expires</div>
                <div className="stat-value mlg:text-xl">{nextLeaseExpires}</div>
                <div className="stat-desc">Days</div>
              </div>
            )}
          </div>
        )}

        {totalProperties >= propertyIndexSettings.perPage && (
          <div className="card bg-white mb-8 shadow-xl">
            <div className="card-body p-4">
              <div className="flex justify-between items-center">
                <div>
                  <select
                    id="itemsPerPage"
                    className="select select-bordered select-sm py-0 inline-block"
                    value={propertyIndexSettings.perPage}
                    onChange={({target}) => {
                      changeSettings('perPage', Number(target.value));
                      changeSettings('page', 1);
                    }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>

                  <h6 className="hidden md:inline-block mb-0 text-nowrap ml-2 inline-block text-sm">
                    Showing {fromPagination}-{toPagination} of {totalProperties}{' '}
                    Properties
                  </h6>
                </div>

                <div>
                  <div className="form-control">
                    <div className="relative">
                      <select
                        id="PropertySortBy"
                        className="select select-sm select-bordered py-0 pr-16"
                        onChange={({target}) =>
                          changeSettings('sortField', target.value)
                        }
                        value={propertyIndexSettings.sortField}>
                        <option value="created_at">Date Added</option>
                        <option value="street_address">Address</option>
                        <option value="next_inspection_date">
                          Next Inspection
                        </option>
                        <option value="tenancy_total_rent">Rent</option>
                        <option value="lease_expires">Lease Expires</option>
                      </select>
                      <button
                        className="absolute top-0 right-0 rounded-l-none btn btn-neutral btn-sm cursor-pointer"
                        onClick={() => changeSettings('sortDirection', '')}>
                        {propertyIndexSettings.sortDirection === 'asc' ? (
                          <SortAscendingIcon className="text-white w-4 h-4" />
                        ) : (
                          <SortDescendingIcon className="text-white w-4 h-4" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="block md:hidden mb-0 text-nowrap ml-2 inline-block text-sm">
                Showing {fromPagination}-{toPagination} of {totalProperties}{' '}
                Properties
              </h6>
            </div>
          </div>
        )}
      </div>
    );
  };

  const Footer = () => {
    if (data.meta.stats.total.count > propertyIndexSettings.perPage) {
      return (
        <div className="flex flex-wrap justify-center">
          <div className="btn-group">
            <button
              className="btn"
              onClick={() =>
                changeSettings('page', propertyIndexSettings.page - 1)
              }
              disabled={propertyIndexSettings.page === 1}>
              Prev
            </button>
            {paginationArray(
              propertyIndexSettings.page,
              Math.ceil(
                data.meta.stats.total.count / propertyIndexSettings.perPage,
              ),
            ).map((page) =>
              page === '...' ? (
                <button key={page} className="btn btn-disabled">
                  {page}
                </button>
              ) : (
                <button
                  key={page}
                  className={`btn ${
                    propertyIndexSettings.page === Number(page)
                      ? 'btn-active'
                      : ''
                  }`}
                  onClick={() => changeSettings('page', Number(page))}>
                  {page}
                </button>
              ),
            )}
            <button
              className="btn"
              onClick={() =>
                changeSettings('page', propertyIndexSettings.page + 1)
              }
              disabled={data.data.length < propertyIndexSettings.perPage}>
              Next
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const showAddressModal = useCallback(() => setAddressModalIsOpen(true), []);

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      landlordDashboardTour?.isActive
        ? null
        : [
            {
              label: 'Add property',
              icon: HiOutlinePlus,
              onClick: showAddressModal,
              testId: 'add-property-button',
            },
          ],
    [landlordDashboardTour.isActive, showAddressModal],
  );

  if (error) {
    return errorViewForError(error);
  } else {
    return (
      <PageWrapper title="Dashboard" actions={pageActions}>
        {isLoading ? (
          <LoadingView />
        ) : (
          <>
            <PromoCodeEntryBanner />

            <ConfirmEmailAlert />

            {!isMobile && <Header />}

            <div className="flex flex-col gap-y-6">
              <DemoPropertyCard tourIsActive={landlordDashboardTour.isActive} />

              {data.data.map((prop) => (
                <LandlordPropertyIndexCard
                  property={prop}
                  tenancy={prop.currentTenancy}
                  key={prop.id}
                  pageType="list"
                />
              ))}
              <AddPropertyCard showAddressModal={showAddressModal} />
            </div>

            <Footer />
            <AutocompletePropertyAddressModal
              isOpen={addressModalIsOpen}
              setIsOpen={setAddressModalIsOpen}
            />
          </>
        )}
      </PageWrapper>
    );
  }
};

export default PropertyIndexPage;
