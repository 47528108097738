import {FunctionComponent, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {FormikProps, useFormik} from 'formik';
import {isMobile} from 'react-device-detect';
import {HiCheck, HiX} from 'react-icons/hi';
import Sheet from 'react-modal-sheet';

import BathroomsFilter from 'components/listing/filters/BathroomsFilter';
import BedroomsFilter from 'components/listing/filters/BedroomsFilter';
import GaragesFilter from 'components/listing/filters/GaragesFilter';
import MultipleLocationsFilter from 'components/listing/filters/MultipleLocationsFilter';
import PetsAllowedFilter from 'components/listing/filters/PetsAllowedFilter';
import PropertyTypesFilter from 'components/listing/filters/PropertyTypesFilter';
import SmokingAllowedFilter from 'components/listing/filters/SmokingAllowedFilter';
import TenantCountFilter from 'components/listing/filters/TenantCountFilter';
import WeeklyRentFilter from 'components/listing/filters/WeeklyRentFilter';
import {Button} from 'components_sb/buttons';

import FilterGroup from './FilterGroup';

interface FilterSheetProps {
  form: ReturnType<typeof useFormik> | FormikProps<any>;
  isOpen: boolean;
  onApply: () => void;
  onClearAll: () => void;
}

const FiltersSheet: FunctionComponent<FilterSheetProps> = ({
  form,
  isOpen,
  onApply,
  onClearAll,
}) => {
  const disableDrag = useMemo(
    () => !Capacitor.isNativePlatform() || !isMobile,
    [],
  );

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onApply}
      disableDrag={disableDrag}
      snapPoints={[0.85, 0]}
      tweenConfig={{ease: 'easeOut', duration: 0.3}}>
      <Sheet.Container>
        <Sheet.Content>
          <div className={clsx('w-full h-full max-h-full', 'flex flex-col')}>
            <div className="flex-1 relative h-full max-h-full overflow-hidden">
              <Sheet.Scroller draggableAt="both" className="scrollbar-hide">
                <div
                  className={clsx(
                    'flex flex-col gap-y-8 w-full max-w-7xl px-6 pb-6 pt-8 lg:px-8 mx-auto',
                  )}>
                  <FilterGroup title="Property">
                    <MultipleLocationsFilter
                      inline
                      form={form}
                      name="locations"
                    />
                    <PropertyTypesFilter
                      inline
                      form={form}
                      name="propertyTypes"
                    />
                    <BedroomsFilter inline form={form} name="bedrooms" />
                    <BathroomsFilter inline form={form} name="bathrooms" />
                    <GaragesFilter inline form={form} name="garages" />
                  </FilterGroup>
                  <FilterGroup title="Tenancy">
                    <WeeklyRentFilter inline form={form} name="weeklyRent" />
                    <TenantCountFilter inline form={form} name="tenants" />
                  </FilterGroup>
                  <FilterGroup title="Amenities & Restrictions">
                    <PetsAllowedFilter
                      inline
                      form={form}
                      name="allowedPetTypes"
                    />
                    <SmokingAllowedFilter
                      inline
                      form={form}
                      name="smokingAllowed"
                    />
                  </FilterGroup>
                </div>
              </Sheet.Scroller>
            </div>
            <div className="w-full border-t-2 border-brand-50">
              <div
                className={clsx(
                  'w-full max-w-7xl mx-auto flex-0 mt-6',
                  'px-6 pb-6 lg:pb-8 lg:px-8',
                  'flex flex-row flex-wrap gap-4',
                )}>
                <Button
                  category="secondary"
                  size="base"
                  label="Clear filters"
                  icon={HiX}
                  mode="manual"
                  onClick={onClearAll}
                />
                <Button
                  category="primary"
                  size="base"
                  label="Apply"
                  icon={HiCheck}
                  mode="manual"
                  onClick={onApply}
                />
              </div>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onApply} />
    </Sheet>
  );
};

export default FiltersSheet;
