import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import {Card} from 'components_sb/layout';
import LightboxGallery from 'components/common/LightboxGallery';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const PublicServiceRequestDetailPage = () => {
  usePageVisit('PublicServiceRequestDetailPage');
  const {id} = useParams();

  const {isLoading, data, error} = useQuery(
    `public-service-request-${id}`,
    async () => {
      const request = await ServiceRequest.includes([
        'service_request_attachments',
      ])
        .where({publicId: id})
        .selectExtra(['location'])
        .first();

      return request.data;
    },
  );

  useTitle(data ? data.title : '');

  const beforeAttachments = data
    ? data?.serviceRequestAttachments.filter(
        (attach) => attach.taken === 'before',
      )
    : [];

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Maintenance Request">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Maintenance Request">
        <Card title={data.title} className="mt-2">
          <span className="block text-sm text-secondary">
            Category: {data.category}
          </span>
          <span className="block text-sm text-secondary">
            Location: {data.location}
          </span>
          <p className="mt-2">{data.description}</p>

          {beforeAttachments.length > 0 && (
            <div>
              <strong className="block mb-4">Attachments</strong>

              <LightboxGallery
                sources={beforeAttachments.map((attachment) => {
                  if (attachment.attachmentThumbnail) {
                    return {
                      thumbnail:
                        attachment.attachmentThumbnail || attachment.attachment,
                      source: attachment.attachment,
                      type: 'image',
                    };
                  } else {
                    return {source: attachment.attachment, type: 'video'};
                  }
                })}
              />
            </div>
          )}
        </Card>
      </PageWrapper>
    );
  }
};

export default PublicServiceRequestDetailPage;
