import {Attr, BelongsTo, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Property from 'models/properties/Property';

export type RoomItem = {
  name: string;
};

export type Room = {
  name: string;
  items: RoomItem[];
};

@Model()
class RoomPlan extends ApplicationRecord {
  static jsonapiType = 'room_plans';

  @Attr() roomPlan: Room[];
  @Attr() propertyId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('properties') property: Property;
}

export default RoomPlan;
