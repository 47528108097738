import {BillingMethodInput} from 'components_sb/input';
import {ModalDefinition} from 'components_sb/layout';

const AddBillingMethodModal: ModalDefinition = {
  title: 'Add a billing method',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: true,
        toastOnSuccess: 'Your payment details have been securely saved!',
      },
    ],
  },
  ContentComponent: BillingMethodInput,
};

export default AddBillingMethodModal;
