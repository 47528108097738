import {useState} from 'react';

import moment from 'moment';
import {HiOutlineRefresh} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';

import {Card} from 'components_sb/layout';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import {Paragraph} from 'components_sb/typography';
import LandlordProfile from 'models/users/LandlordProfile';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {toCurrency} from 'utilities/StringHelpers';

import BillingMethodsSection from './BillingMethodsSection';

const BillingDetailsPage = () => {
  useTitle('Billing');
  usePageVisit('BillingDetailsPage');

  const [mutationLoading, setMutationLoading] = useState(false);

  const {currentUser, setUserIsPaid} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const lp = useQuery('landlord-profile-with-subscription', async () => {
    const profile = await LandlordProfile.includes({
      landlord_subscription: 'subscription',
    })
      .where({user_id: currentUser.id})
      .first();

    return profile.data;
  });

  const queryClient = useQueryClient();

  const confirmDowngradePlan = () => {
    setConfirmationOptions({
      title: 'Cancel Subscription',
      message:
        'Are you sure you want to cancel your subscription? Your account will go into read only mode.',
      buttonTitle: 'Confirm',
      action: downgradePlan,
      color: 'error',
    });
  };

  const downgradePlan = async () => {
    setMutationLoading(true);

    const profile = lp.data;
    profile.landlordSubscription.isActive = false;

    const result = await profile.landlordSubscription.save();
    if (result) {
      await setUserIsPaid(false);
      queryClient.setQueryData('landlord-profile-with-subscription', profile);
    }

    setMutationLoading(false);
  };

  const confirmUpgradePlan = () => {
    setConfirmationOptions({
      title: 'Activate Subscription',
      message:
        'Are you sure you want to activate your subscription? By agreeing to this you indicate that you have read and understood the terms of use.',
      buttonTitle: 'Subscribe',
      action: upgradePlan,
      color: 'success',
    });
  };

  const upgradePlan = async () => {
    setMutationLoading(true);
    const profile = lp.data;
    profile.landlordSubscription.isActive = true;
    const result = await profile.landlordSubscription.save();
    if (result) {
      await setUserIsPaid(true);
      queryClient.setQueryData('landlord-profile-with-subscription', profile);
    }
    setMutationLoading(false);
  };

  if (lp.error) {
    return errorViewForError(lp.error);
  } else if (lp.isLoading) {
    return (
      <PageWrapper title="Billing">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Billing">
        <BillingMethodsSection />

        <Card icon={HiOutlineRefresh} title="Keyhook Subscription">
          {lp.data.landlordSubscription &&
          lp.data.landlordSubscription.isActive ? (
            <div>
              <div className="flex flex-col mb-4">
                <Paragraph>
                  You currently have an active subscription for Keyhook.
                </Paragraph>

                <Paragraph>
                  The cost of this plan is{' '}
                  <strong>
                    {toCurrency(
                      lp.data.landlordSubscription.subscription.amount,
                    )}
                  </strong>{' '}
                  per week for each property (excluding GST).
                </Paragraph>

                <Paragraph>
                  Your subscription fee will be charged using the billing method
                  configured above for each property.
                </Paragraph>

                {lp.data.landlordSubscription.trialEndsAt &&
                  moment(lp.data.landlordSubscription.trialEndsAt).isAfter(
                    moment(),
                  ) && (
                    <Paragraph>
                      Your free promo offer is still active and this fee will be
                      waived until{' '}
                      {moment(lp.data.landlordSubscription.trialEndsAt).format(
                        'DD MMMM YYYY',
                      )}
                      .
                    </Paragraph>
                  )}

                <Paragraph secondary>
                  If you would like to cancel your subscription, press the
                  button below.
                </Paragraph>

                <Paragraph secondary>
                  Your account will go into read-only mode, and you will still
                  have access to your data.
                </Paragraph>
              </div>

              <div className="mt-2">
                <Button
                  label="Cancel Subscription"
                  category="danger"
                  size="base"
                  fillWidth={false}
                  mode="manual"
                  onClick={confirmDowngradePlan}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-col mb-4">
                <Paragraph>
                  You do not currently have an active subscription and therefore
                  your account is in read only mode. You can access your past
                  data but not create or modify anything within Keyhook.
                </Paragraph>

                <Paragraph>
                  You can click the button below to re-activate your
                  subscription for $4.99 / month per property (excl gst).
                </Paragraph>

                <Paragraph secondary>
                  Please note, Keyhook does not require an upfront payment as we
                  will instead deduct your subscription fee from your rental
                  payments. For more information, see our{' '}
                  <a
                    className="link link-primary"
                    href="https://keyhook.com/pricing"
                    target="_blank"
                    rel="noreferrer">
                    pricing information
                  </a>{' '}
                  and{' '}
                  <a
                    className="link link-primary"
                    href="https://keyhook.com/terms-of-use"
                    target="_blank"
                    rel="noreferrer">
                    terms of use
                  </a>
                </Paragraph>
              </div>

              <Button
                label="Subscribe"
                loadingLabel="Subscribing..."
                loading={mutationLoading}
                category="primary"
                size="base"
                fillWidth={false}
                mode="manual"
                onClick={confirmUpgradePlan}
              />
            </div>
          )}
        </Card>
      </PageWrapper>
    );
  }
};

export default BillingDetailsPage;
