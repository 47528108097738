import {useMemo} from 'react';

import {PencilAltIcon, TrashIcon, UsersIcon} from '@heroicons/react/outline';
import clsx from 'clsx';
import moment from 'moment';
import {isMobile} from 'react-device-detect';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import emptyBg from 'assets/img/empty_dataset_images/open_homes.svg';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import KeyhookLink from 'components_sb/navigation/Link/Link';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Listing from 'models/listings/Listing';
import OpenHome from 'models/listings/OpenHome';
import PrivateViewing from 'models/listings/PrivateViewing';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';
import {saveResource} from 'utilities/SpraypaintHelpers';

const ViewingsIndexPage = () => {
  usePageVisit('ViewingsIndexPage');
  const {propertyId, listingId: listingPublicId} = useParams();
  const queryClient = useQueryClient();

  /**
   * Fetch the listing.
   */
  const {
    data: listing,
    isLoading: isLoadingListing,
    error: listingError,
    isSuccess: successfullyLoadedListing,
  } = useQuery(`listing-${listingPublicId}-open-homes`, async () => {
    const listing = await Listing.select({listings: ['id']})
      .includes('open_homes')
      .order('open_homes.start_time')
      .find(listingPublicId);

    return listing.data;
  });

  /**
   * Fetch the private viewings for the listing.
   */
  const {
    data: allPrivateViewings,
    isLoading: isLoadingPrivateViewings,
    error: privateViewingsError,
  } = useQuery(
    `listing-${listingPublicId}-private-viewings`,
    async () =>
      (
        await PrivateViewing.select({listings: ['id']})
          .order('starts_at')
          .where({listingId: listing.id})
          .all()
      ).data,
    {
      /**
       * Wait for the listing to load first.
       */
      enabled: successfullyLoadedListing,
    },
  );

  /**
   * Loading state is active when either query is running.
   */
  const isLoading = useMemo(
    () => isLoadingListing || isLoadingPrivateViewings,
    [isLoadingListing, isLoadingPrivateViewings],
  );

  /**
   * Get the open homes from the listing and split them into upcoming and past.
   */
  const openHomes = useMemo(
    () =>
      listing?.openHomes?.length > 0
        ? {
            upcoming: listing.openHomes.filter(({startTime}) =>
              moment(startTime).isAfter(moment()),
            ),
            past: listing.openHomes.filter(({startTime}) =>
              moment(startTime).isBefore(moment()),
            ),
          }
        : {
            upcoming: [],
            past: [],
          },
    [listing],
  );

  /**
   * Split the private viewings into upcoming and past.
   */
  const privateViewings = useMemo(
    () =>
      allPrivateViewings?.length > 0
        ? {
            upcoming: allPrivateViewings.filter(
              ({startsAt, cancelledAt}) =>
                !cancelledAt && moment(startsAt).isAfter(moment()),
            ),
            past: allPrivateViewings.filter(
              ({startsAt, cancelledAt}) =>
                cancelledAt || moment(startsAt).isBefore(moment()),
            ),
          }
        : {
            upcoming: [],
            past: [],
          },
    [allPrivateViewings],
  );

  /**
   * Determine if there are any open homes scehduled.
   */
  const hasScheduledOpenHomes = useMemo(
    () => openHomes.upcoming.length > 0 || openHomes.past.length > 0,
    [openHomes],
  );

  /**
   * Determine if there are any private viewings scehduled.
   */
  const hasScheduledPrivateViewings = useMemo(
    () =>
      privateViewings.upcoming.length > 0 || privateViewings.past.length > 0,
    [privateViewings],
  );

  /**
   * Determine if there are any open homes or private viewings.
   */
  const hasScheduledViewings = useMemo(
    () => hasScheduledOpenHomes || hasScheduledPrivateViewings,
    [hasScheduledOpenHomes, hasScheduledPrivateViewings],
  );

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const cancelOpenHome = async (openHome: OpenHome) => {
    const result = await openHome.destroy();

    if (result) {
      toast.success('Your open home has been cancelled.');
      queryClient.invalidateQueries(`listing-${listingPublicId}-open-homes`);
    } else {
      toast.error('Sorry, there was an error cancelling your open home.');
    }
  };

  const confirmCancelOpenHome = (openHome: OpenHome) => {
    setConfirmationOptions({
      title: 'Cancel open home',
      message: 'Are you sure you want to cancel this open home?',
      color: 'error',
      buttonTitle: 'Cancel',
      action: () => cancelOpenHome(openHome),
    });
  };

  const cancelPrivateViewing = async (privateViewing: PrivateViewing) => {
    /**
     * Set the viewing as cancelled rather than destroying.
     */
    privateViewing.assignAttributes({
      cancelledAt: moment().toISOString(),
    });

    /**
     * Save the changes to the private viewing.
     */
    if (
      await saveResource(privateViewing, {
        showErrorToast: false,
      })
    ) {
      toast.success('Your private viewing has been cancelled.');
      queryClient.invalidateQueries(
        `listing-${listingPublicId}-private-viewings`,
      );
    } else {
      toast.error('Sorry, there was an error cancelling your private viewing.');
    }
  };

  const confirmCancelPrivateViewing = (privateViewing: PrivateViewing) => {
    setConfirmationOptions({
      title: 'Cancel private viewing',
      message: 'Are you sure you want to cancel this private viewing?',
      color: 'error',
      buttonTitle: 'Cancel',
      action: () => cancelPrivateViewing(privateViewing),
    });
  };

  const mobileLayout = !useTailwindBreakpoint('md');

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !propertyId || !listingPublicId
        ? null
        : [
            {
              label: 'Schedule open home',
              linkTo: `/properties/${propertyId}/listings/${listingPublicId}/viewings/public/new`,
            },
            {
              label: 'Schedule private viewing',
              linkTo: `/properties/${propertyId}/listings/${listingPublicId}/viewings/private/new`,
            },
          ],
    [propertyId, listingPublicId],
  );

  if (listingError) {
    return errorViewForError(listingError);
  } else if (privateViewingsError) {
    return errorViewForError(privateViewingsError);
  } else if (isLoading) {
    return (
      <PageWrapper title="Viewings" actions={pageActions}>
        <ManageListingMenu
          propertyId={propertyId}
          listingPublicId={listingPublicId}
        />
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (hasScheduledViewings) {
      if (mobileLayout) {
        return (
          <PageWrapper title="Viewings" actions={pageActions}>
            <ManageListingMenu
              propertyId={propertyId}
              listingPublicId={listingPublicId}
            />

            {/* Open homes */}
            {hasScheduledOpenHomes && (
              <Card className="mt-4">
                {openHomes.upcoming.length > 0 && (
                  <div>
                    <h2 className="card-title mb-4 text-brand-850">
                      Upcoming Open Homes
                    </h2>
                    {openHomes.upcoming.map((openHome) => (
                      <Card className="mt-2" key={openHome.id}>
                        <div className="flex flex-col text-sm space-y-2">
                          <div>
                            <span className="font-medium">From: </span>
                            <span>
                              {moment(openHome.startTime).format(
                                'dddd DD MMM hh:mm a',
                              )}{' '}
                            </span>
                          </div>
                          <div>
                            <span className="font-medium">Until: </span>
                            <span>
                              {moment(openHome.endTime).format(
                                'dddd DD MMM hh:mm a',
                              )}
                            </span>
                          </div>
                          <p>
                            <KeyhookLink to={openHome.id}>
                              Attendees: {openHome.openHomeAttendeesCount || 0}
                            </KeyhookLink>
                          </p>

                          <div className="flex justify-between items-center">
                            <Link
                              to={`public/${openHome.id}/edit`}
                              className="btn btn-sm btn-neutral">
                              Edit
                            </Link>

                            <a
                              onClick={() => confirmCancelOpenHome(openHome)}
                              className="btn btn-sm btn-neutral">
                              Cancel
                            </a>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                )}

                {openHomes.past.length > 0 && (
                  <div>
                    <h2 className="card-title mb-4 text-brand-850">
                      Past Open Homes
                    </h2>
                    {openHomes.past.map((openHome) => (
                      <Card className="mt-2" key={openHome.id}>
                        <div className="flex flex-col text-sm space-y-2">
                          <strong>
                            {moment(openHome.startTime).format(
                              'dddd DD MMM hh:mm a',
                            )}{' '}
                            -
                            {moment(openHome.endTime).format(
                              'dddd DD MMM hh:mm a',
                            )}
                          </strong>
                          <p>
                            <KeyhookLink to={openHome.id}>
                              Attendees: {openHome.openHomeAttendeesCount || 0}
                            </KeyhookLink>
                          </p>
                        </div>
                      </Card>
                    ))}
                  </div>
                )}
              </Card>
            )}

            {/* Private viewings */}
            {hasScheduledPrivateViewings && (
              <Card className="mt-4">
                {privateViewings.upcoming.length > 0 && (
                  <div>
                    <h2 className="card-title mb-4 text-brand-850">
                      Upcoming Private Viewings
                    </h2>
                    {privateViewings.upcoming.map((privateViewing) => (
                      <Card className="mt-2" key={privateViewing.id}>
                        <div className="flex flex-col text-sm space-y-2">
                          <div>
                            <span className="font-medium">From: </span>
                            <span>
                              {moment(privateViewing.startsAt).format(
                                'dddd DD MMM hh:mm a',
                              )}{' '}
                            </span>
                          </div>
                          <div>
                            <span className="font-medium">Until: </span>
                            <span>
                              {moment(privateViewing.endsAt).format(
                                'dddd DD MMM hh:mm a',
                              )}
                            </span>
                          </div>

                          <div>
                            <span className="font-medium">For: </span>
                            <span>{privateViewing.name}</span>
                          </div>

                          <div className="flex flex-row">
                            <div
                              className={clsx(
                                'flex flex-row items-center gap-x-2',
                                'rounded-lg px-3 py-1',
                                'text-sm font-medium',
                                'text-white',
                                privateViewing.confirmedAt
                                  ? 'bg-green-500'
                                  : 'bg-gray-500',
                              )}>
                              <span>
                                {privateViewing.confirmedAt
                                  ? 'CONFIRMED'
                                  : 'UNCONFIRMED'}
                              </span>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <Link
                              to={`private/${privateViewing.id}/edit`}
                              className="btn btn-sm btn-neutral">
                              Edit
                            </Link>

                            <a
                              onClick={() =>
                                confirmCancelPrivateViewing(privateViewing)
                              }
                              className="btn btn-sm btn-neutral">
                              Remove
                            </a>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                )}

                {privateViewings.past.length > 0 && (
                  <div>
                    <h2 className="card-title mb-4 text-brand-850">
                      Past/Cancelled Private Viewings
                    </h2>
                    {privateViewings.past.map((privateViewing) => (
                      <Card className="mt-2" key={privateViewing.id}>
                        <div className="flex flex-col text-sm space-y-2">
                          <div>
                            <span className="font-medium">From: </span>
                            <span>
                              {moment(privateViewing.startsAt).format(
                                'dddd DD MMM hh:mm a',
                              )}{' '}
                            </span>
                          </div>
                          <div>
                            <span className="font-medium">Until: </span>
                            <span>
                              {moment(privateViewing.endsAt).format(
                                'dddd DD MMM hh:mm a',
                              )}
                            </span>
                          </div>
                          <div>
                            <span className="font-medium">For: </span>
                            <span>{privateViewing.name}</span>
                          </div>
                          {privateViewing.cancelledAt ? (
                            <div className="flex flex-row">
                              <div
                                className={clsx(
                                  'flex flex-row items-center gap-x-2',
                                  'rounded-lg px-3 py-1',
                                  'text-sm font-medium',
                                  'bg-red-500 text-white',
                                )}>
                                <span>CANCELLED</span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Card>
                    ))}
                  </div>
                )}
              </Card>
            )}
          </PageWrapper>
        );
      } else {
        return (
          <PageWrapper title="Viewings" actions={pageActions}>
            <ManageListingMenu
              propertyId={propertyId}
              listingPublicId={listingPublicId}
            />
            {/* Open homes */}
            {hasScheduledOpenHomes && (
              <Card className="mt-4">
                {openHomes.upcoming.length > 0 && (
                  <div>
                    <h2 className="card-title mb-4 text-brand-850">
                      Upcoming Open Homes
                    </h2>

                    <div className="overflow-x-auto mt-2">
                      <table className="table table-zebra w-full">
                        <thead>
                          <tr>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th># Attending</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {openHomes.upcoming.map((openHome) => (
                            <tr key={openHome.id}>
                              <td>
                                {moment(openHome.startTime).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>
                                {moment(openHome.endTime).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>{openHome.openHomeAttendeesCount || 0}</td>
                              <td>
                                <div className="flex justify-start gap-4">
                                  <Link
                                    to={`public/${openHome.id}`}
                                    className="tooltip"
                                    data-tip="View Attendees">
                                    <UsersIcon className="w-5 h-5" />
                                  </Link>

                                  <Link
                                    to={`public/${openHome.id}/edit`}
                                    className="tooltip"
                                    data-tip="Change Time">
                                    <PencilAltIcon className="w-5 h-5" />
                                  </Link>

                                  <a
                                    onClick={() =>
                                      confirmCancelOpenHome(openHome)
                                    }
                                    className="cursor-pointer tooltip"
                                    data-tip="Cancel open home">
                                    <TrashIcon className="w-5 h-5" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {openHomes.past.length > 0 && (
                  <div>
                    <h2 className="card-title mb-4 text-brand-850">
                      Past Open Homes
                    </h2>

                    <div className="overflow-x-auto mt-2">
                      <table className="table table-zebra w-full">
                        <thead>
                          <tr>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th># Attending</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {openHomes.past.map((openHome) => (
                            <tr key={openHome.id}>
                              <td>
                                {moment(openHome.startTime).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>
                                {moment(openHome.endTime).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>{openHome.openHomeAttendeesCount || 0}</td>
                              <td>
                                <div className="flex justify-start gap-4">
                                  <Link
                                    to={`public/${openHome.id}`}
                                    className="tooltip"
                                    data-tip="View Attendees">
                                    <UsersIcon className="w-5 h-5" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </Card>
            )}

            {/* Private viewings */}
            {hasScheduledPrivateViewings && (
              <Card className="mt-4">
                {privateViewings.upcoming.length > 0 && (
                  <div>
                    <h2 className="card-title mb-4 text-brand-850">
                      Upcoming Private Viewings
                    </h2>

                    <div className="overflow-x-auto mt-2">
                      <table className="table table-zebra w-full">
                        <thead>
                          <tr>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>For</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {privateViewings.upcoming.map((privateViewing) => (
                            <tr key={privateViewing.id}>
                              <td>
                                {moment(privateViewing.startsAt).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>
                                {moment(privateViewing.endsAt).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>{privateViewing.name}</td>
                              <td>
                                <div className="flex flex-row">
                                  <div
                                    className={clsx(
                                      'flex flex-row items-center gap-x-2',
                                      'rounded-lg px-3 py-1',
                                      'text-sm font-medium',
                                      'text-white',
                                      privateViewing.confirmedAt
                                        ? 'bg-green-500'
                                        : 'bg-gray-500',
                                    )}>
                                    <span>
                                      {privateViewing.confirmedAt
                                        ? 'CONFIRMED'
                                        : 'UNCONFIRMED'}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="flex justify-start gap-4">
                                  <Link
                                    to={`private/${privateViewing.id}/edit`}
                                    className="tooltip"
                                    data-tip="Edit">
                                    <PencilAltIcon className="w-5 h-5" />
                                  </Link>

                                  <a
                                    onClick={() =>
                                      confirmCancelPrivateViewing(
                                        privateViewing,
                                      )
                                    }
                                    className="cursor-pointer tooltip"
                                    data-tip="Cancel private viewing">
                                    <TrashIcon className="w-5 h-5" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {privateViewings.past.length > 0 && (
                  <div>
                    <h2 className="card-title mb-4 text-brand-850">
                      Past/Cancelled Private Viewings
                    </h2>

                    <div className="overflow-x-auto mt-2">
                      <table className="table table-zebra w-full">
                        <thead>
                          <tr>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>For</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {privateViewings.past.map((privateViewing) => (
                            <tr key={privateViewing.id}>
                              <td>
                                {moment(privateViewing.startsAt).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>
                                {moment(privateViewing.endsAt).format(
                                  'dddd DD MMM hh:mm a',
                                )}
                              </td>
                              <td>{privateViewing.name}</td>
                              <td>
                                {privateViewing.cancelledAt ? (
                                  <div className="flex flex-row">
                                    <div
                                      className={clsx(
                                        'flex flex-row items-center gap-x-2',
                                        'rounded-lg px-3 py-1',
                                        'text-sm font-medium',
                                        'bg-red-500 text-white',
                                      )}>
                                      <span>CANCELLED</span>
                                    </div>
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </Card>
            )}
          </PageWrapper>
        );
      }
    } else {
      return (
        <PageWrapper title="Viewings" actions={pageActions}>
          <ManageListingMenu
            propertyId={propertyId}
            listingPublicId={listingPublicId}
          />
          <EmptyDataSet
            title="No scheduled viewings"
            message={`This listing does not currently have any open homes or private viewings scheduled, ${
              isMobile ? 'tap' : 'click'
            } the button below to add some.`}
            image={emptyBg}
          />
          <div className="w-full flex flex-col max-w-xl mx-auto gap-y-4 flex-wrap py-4">
            <Button
              label="Schedule open home"
              category="primary"
              size="base"
              mode="link"
              linkTo="public/new"
            />
            <Button
              label="Schedule private viewing"
              category="primary"
              size="base"
              mode="link"
              linkTo="private/new"
            />
          </div>
        </PageWrapper>
      );
    }
  }
};

export default ViewingsIndexPage;
