import {Navigate, Outlet} from 'react-router';

import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useAuth from 'services/useAuth';

/**
 * Ensures that only logged in users with their active account type set
 * as renter are able to access the contained route.
 */
const RenterRoute = () => {
  const {userIsLoggedIn} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  return userIsLoggedIn && activeAccountRole === 'Renter' ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default RenterRoute;
