import {FunctionComponent, ReactNode} from 'react';

import {Title} from 'components_sb/typography';

interface FilterGroupProps {
  title: string;
  children: ReactNode;
}

const FilterGroup: FunctionComponent<FilterGroupProps> = ({
  title,
  children,
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <Title level="h2" size="md">
        {title}
      </Title>
      <div className="grid grid-cols-1 mdlg:grid-cols-2 gap-y-8 gap-x-10">
        {children}
      </div>
    </div>
  );
};

export default FilterGroup;
