import React, {useState} from 'react';

import {UserIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {
  AutocompleteField,
  InputField,
  SubmitButton,
} from 'components/forms_fields';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import {API_URL} from 'globals/app-globals';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import User from 'models/users/User';
import useAuth from 'services/useAuth';

const PersonalProfileAction = ({
  property,

  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const {currentUser} = useAuth();

  const {data: model} = useQuery(
    'landlord-profile-checklist',
    async () => {
      const user = await User.includes(['landlord_profile']).find(
        currentUser.id,
      );

      return user.data;
    },
    {enabled: modalVisible},
  );

  const queryAddresses = async (str: string) => {
    if (str.length <= 2) {
      return [];
    }

    const url = `${API_URL}/addresses.json?query=${str}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
        },
      });
      const data = await response.json();
      const places = data.addresses;
      return places;
    } catch (e) {
      return [];
    }
  };

  const selectAddress = async (option: any, formik: any): Promise<any[]> => {
    if (option) {
      const url = `${API_URL}/addresses/${option['id']}.json`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser.email,
          },
        });
        const data = await response.json();

        const ad = data.address;

        formik.setFieldValue('addressMeta', ad);
        formik.setFieldValue('address', ad.full);
      } catch (e) {
        return [];
      }
    }
  };

  const queryClient = useQueryClient();

  const saveLandlordProfile = async (
    formData: any,
    actions: FormikHelpers<any>,
  ) => {
    const profile = model.landlordProfile;
    profile.assignAttributes(formData);
    const result = await profile.save();

    if (result) {
      property.setProfileStepAsCompleted(storeKey);

      const result2 = await property.save();

      if (result2) {
        queryClient.setQueryData(
          ['property', {id: property.id, context: 'detail-page'}],
          property,
        );
        toast.success('Profile successfully updated!');
        setModalVisible(false);
      }
    } else {
      for (const key of Object.keys(profile.errors)) {
        const message = profile.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const title = 'Personal Profile';
  const subtitle = 'Finish setting up your Keyhook account.';
  const iconBgColor = 'bg-green-300';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={UserIcon}
        iconBgColor={iconBgColor}
        onClick={() => setModalVisible(true)}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={UserIcon}
        bgColor={iconBgColor}
        form={
          model ? (
            <Formik
              initialValues={{
                address: model.landlordProfile.address || '',
                addressMeta: model.landlordProfile.addressMeta || null,
                phoneNumber: model.landlordProfile.phoneNumber || '',
                externalLandlordId:
                  model.landlordProfile.externalLandlordId || '',
              }}
              onSubmit={saveLandlordProfile}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                address: Yup.string().required().label('Address'),
                phoneNumber: Yup.string()
                  .min(7)
                  .required()
                  .label('Phone Number'),
                externalLandlordId: Yup.string()
                  .optional()
                  .label('Tenancy Services Landlord Id')
                  .min(5)
                  .max(7),
              })}>
              {(formik) => (
                <Form className="md:h-full flex-1">
                  <div className="flex flex-col justify-between h-full">
                    <div className="flex flex-col justify-center grow space-y-8">
                      <div>
                        <AutocompleteField
                          label="Landlord Postal Address"
                          name="address"
                          formik={formik}
                          placeholder="123 Example Street"
                          filter={queryAddresses}
                          select={(option: any) =>
                            selectAddress(option, formik)
                          }
                          value={formik.values.address}
                          className="!rounded-full"
                          autoComplete="none"
                          type="search"
                        />
                      </div>
                      <InputField
                        labelProps={{
                          title: 'Phone number',
                        }}
                        name="phoneNumber"
                        formik={formik}
                        placeholder="E.g. 027 123 4567"
                        autoCapitalize="none"
                        className="!rounded-full"
                      />
                      <InputField
                        labelProps={{
                          title: 'Tenancy Services Landlord ID',
                        }}
                        name="externalLandlordId"
                        formik={formik}
                        placeholder="If you don't know, leave blank"
                        autoCapitalize="none"
                        className="!rounded-full"
                      />
                    </div>
                    <p className="text-secondary text-sm my-2">
                      Your phone number and address will be included in leases
                      and bonds for Tenancy Services.
                    </p>

                    <SubmitButton
                      formik={formik}
                      text="Save"
                      submittingText="Saving"
                      className={`!rounded-full ${iconBgColor} border-none mt-6`}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          ) : null
        }
      />
    </div>
  );
};

export default PersonalProfileAction;
