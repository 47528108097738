import {useCallback} from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import TenancyRequest from 'models/properties/TenancyRequest';
import {errorViewForError} from 'utilities/ErrorHelpers';

const EditTenancyRequestPage = () => {
  const {propertyId, id: tenancyRequestId} = useParams();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const {
    data: tenancyRequest,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    `landlord-tenancy-request-${tenancyRequestId}`,
    async () =>
      (await TenancyRequest.includes('tenancy').find(tenancyRequestId)).data,
  );

  const handleSubmit = useCallback(
    async (formValues: any, actions: FormikHelpers<any>) => {
      tenancyRequest.renterEmail = formValues.renterEmail;
      tenancyRequest.name = formValues.name;

      const result = await tenancyRequest.save();
      if (result) {
        toast.success('Tenancy request successfully updated!');
        queryClient.invalidateQueries(
          `landlord-tenancy-request-${tenancyRequestId}`,
        );

        navigate(`/properties/${propertyId}`, {replace: true});
      }

      actions.setSubmitting(false);
    },
    [tenancyRequestId, propertyId, navigate, queryClient, tenancyRequest],
  );

  return (
    <PageWrapper title="Edit Tenancy Request">
      <Card title="Edit Tenancy Request">
        {/* Error */}
        {isError && errorViewForError(error)}

        {/* Loading */}
        {isLoading && <LoadingView />}

        {/* Success */}
        {isSuccess && (
          <>
            <Paragraph>
              Made a mistake with your tenants information? Use the form below
              to update it.
            </Paragraph>

            <Formik
              initialValues={{
                name: tenancyRequest.name,
                renterEmail: tenancyRequest.renterEmail,
              }}
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                name: Yup.string().label('Tenant Name').optional().nullable(),
                renterEmail: Yup.string()
                  .email()
                  .required('Required')
                  .label('Tenant Email'),
              })}>
              {(formik) => (
                <Form>
                  <FormRow responsive>
                    {/* When migrating a tenancy the name is not required since
                    there will already be a name on the existing lease */}
                    {tenancyRequest.tenancy.isNew && (
                      <FormRowItem>
                        <InputField
                          formik={formik}
                          name="name"
                          labelProps={{
                            title: 'Tenant name',
                            helpText:
                              'This will be the name that appears on the tenancy agreement.',
                          }}
                          placeholder="E.g. John Smith"
                        />
                      </FormRowItem>
                    )}
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="renterEmail"
                        labelProps={{
                          title: 'Tenant email address',
                        }}
                        placeholder="E.g. johnsmith@gmail.com"
                      />
                    </FormRowItem>
                  </FormRow>

                  <SubmitButton
                    text="Update Request"
                    submittingText="Updating"
                    formik={formik}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </Card>
    </PageWrapper>
  );
};

export default EditTenancyRequestPage;
