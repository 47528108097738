import {TbCertificate} from 'react-icons/tb';

import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';

const BondStatusCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  if (property && property.tenanciesCount === 0) {
    return null;
  }

  if (!tenancy) {
    return null;
  }

  if (
    tenancy.isNew &&
    ![TenancyStatus.Draft, TenancyStatus.Pending].includes(tenancy.status)
  ) {
    if (tenancy.externalBondId) {
      return (
        <Card icon={TbCertificate} title="Bond Status">
          <Paragraph>
            <strong>Status: </strong>
            <span>Lodged</span>
          </Paragraph>
          <Paragraph>
            <strong>Bond ID: </strong>
            <span>{tenancy.externalBondId}</span>
          </Paragraph>
        </Card>
      );
    } else if (tenancy.externalBondFiled) {
      return (
        <Card icon={TbCertificate} title="Bond Status">
          <Paragraph>
            <strong>Status: </strong>
            <span>Awaiting response from Tenancy Services</span>
          </Paragraph>
        </Card>
      );
    } else if (tenancy.readyToBeFiled) {
      return (
        <Card icon={TbCertificate} title="Bond Status">
          <p>
            The money for the bond for this tenancy has been received from the
            tenants and the bond will automatically be filed with Tenancy
            Services shortly.
          </p>
        </Card>
      );
    } else {
      return (
        <Card icon={TbCertificate} title="Bond Status">
          <p>
            Keyhook is currently waiting for the tenant(s) to make their bond
            payment(s). Once received, it will automatically be filed with
            Tenancy Services for you.
          </p>
        </Card>
      );
    }
  }

  return null;
};

export default BondStatusCard;
