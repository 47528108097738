import {useMemo, useCallback} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {GrGroup} from 'react-icons/gr';
import {
  HiOutlineChat,
  HiOutlinePlus,
  HiOutlineSupport,
  HiOutlineUser,
  HiOutlineUserGroup,
} from 'react-icons/hi';
import {IoPeople, IoPeopleOutline} from 'react-icons/io5';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {TARGET_ENV} from 'globals/app-globals';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import {usePusherService} from 'services/PusherService';
import useAuth from 'services/useAuth';
import {Action} from 'types/actions';
import {usePageVisit, useTitle} from 'utilities/hooks';

import ConversationsList from './ConversationsList';

const ChatIndexPage = () => {
  useTitle('Conversations');
  usePageVisit('ChatIndexPage');

  const {channels, channelsLoaded} = usePusherService();
  const {currentUser} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  /**
   * Actions that are available for the property conversations card.
   */
  const propertyConversationsActions = useMemo<Action[]>(
    () => [
      {
        label: 'New conversation',
        icon: HiOutlinePlus,
        linkTo: `/chat/new`,
      },
    ],
    [],
  );

  const showLiveSupport = useCallback(async () => {
    if (Capacitor.isNativePlatform()) {
      const params = new URLSearchParams();
      params.set('user_id', currentUser.id);
      params.set('name', currentUser.name);
      params.set('email', currentUser.email);
      params.set('env', TARGET_ENV);
      let url = `https://livesupport.keyhook.com?${params.toString()}`;
      url = url + currentUser.roles.map((role) => `&roles=${role}`).join('');

      await Browser.open({url: url});
    } else {
      if ((window as any).customerly) {
        (window as any).customerly.open();
      }
    }
  }, [currentUser]);

  const mdBreakpoint = useTailwindBreakpoint('md');

  return (
    <PageWrapper title="Chat">
      {!channelsLoaded ? (
        <LoadingView />
      ) : (
        <>
          {/* Support conversationss */}
          <Card title="Chat with Keyhook Support" icon={HiOutlineSupport}>
            <Button
              category="primary"
              size={mdBreakpoint ? 'base' : 'sm'}
              format="standard"
              label="View or create a conversation"
              icon={HiOutlineChat}
              mode="manual"
              fillWidth={false}
              onClick={showLiveSupport}
            />
          </Card>

          {/* Property conversations */}
          <Card
            icon={
              activeAccountRole === 'Renter'
                ? HiOutlineUser
                : HiOutlineUserGroup
            }
            title={`Chat with your ${
              activeAccountRole === 'Renter' ? 'Landlord' : 'Tenants'
            }`}
            actions={propertyConversationsActions}>
            <ConversationsList channels={channels} />
          </Card>
        </>
      )}
    </PageWrapper>
  );
};

export default ChatIndexPage;
