import {Link as RouterLink} from 'react-router-dom';

/**
 * A basic wrapper for an HTML anchor (a) tag.
 * @param to The URL translating to the href property.
 * @param props Arbitrary props to apply to the anchor tag.
 */
const AnchorLink = ({to, ...props}: {to: string}) => <a href={to} {...props} />;

/**
 * Returns either the appropriate component type depending on whether
 * a given link is internal or external.
 * @param linkTo A link to either an internal or external destination.
 */
const getLinkComponent = (linkTo: string) => {
  // Use the default anchor link if the link is external ( starts with http ) or
  // the react router link if the link is internal.
  return linkTo.startsWith('http') ? AnchorLink : RouterLink;
};

export default getLinkComponent;
