import React, {Fragment, ReactNode, useCallback, useMemo, useRef} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import clsx from 'clsx';

import {Button} from 'components_sb/buttons';
import {Title} from 'components_sb/typography';
import useChangeKeyboardResizeMode from 'hooks/useChangeKeyboardResizeMode';
import useKeyboardHeight from 'hooks/useKeyboardHeight';

import ScrollContainer from '../ScrollContainer/ScrollContainer';
import ImperativeModal from './ImperativeModal';

export const EXIT_DURATION = 200;

const classes = {
  modal: clsx(
    'flex flex-col',
    'text-left overflow-hidden transform transition-all',
    'bg-white rounded-xl shadow-xl',
    'my-auto mx-auto',
    'relative',
    // Width
    'max-w-none w-full lg:w-auto sm:max-w-xl sm:w-xl',
    // Height
    'max-h-full',
    // Margin
    // 'm-0 sm:m-8',
  ),
  header: clsx(
    'flex-shrink-0',
    // Padding
    'py-4 px-6 sm:py-6 sm:px-8',
    // Border
    'border border-brand-75',
  ),
  content: clsx(
    // Padding
    'p-6 sm:p-8',
    // Text
    'text-brand-850 text-base font-normal',
  ),
  footer: clsx(
    'flex-shrink-0',
    // Padding
    'p-6 sm:p-8',
    // Border
    'border-t border-brand-75',
    // Layout
    'flex flex-row flex-wrap-reverse gap-4 sm:gap-6',
  ),
};

export interface ModalButtonConfig {
  id: string;
  label?:
    | string
    | {
        idle: string;
        loading: string;
      };
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
}

export interface ModalButtonsConfig {
  cancel: Omit<ModalButtonConfig, 'id'>;
  actions?: ModalButtonConfig[];
}

export interface ModalProps {
  children?: ReactNode;
  title?: string;
  open: boolean;
  buttonsConfig: ModalButtonsConfig;
}

interface ModalButtonProps {
  config: Omit<ModalButtonConfig, 'id'>;
  category: 'primary' | 'secondary';
}

const ModalButton = ({config, category}: ModalButtonProps) => {
  /**
   * Deconstruct the button configuration.
   */
  const {label, onClick, loading, disabled} = config;

  return (
    <Button
      label={typeof label === 'string' ? label : label.idle}
      category={category}
      size="base"
      mode="manual"
      onClick={onClick}
      loading={loading}
      loadingLabel={typeof label === 'string' ? undefined : label.loading}
      disabled={disabled}
    />
  );
};

const Modal = ({children, title, open, buttonsConfig}: ModalProps) => {
  /**
   * Set this as a ref on a particular input component to focus on
   * upon opening the modal.
   */
  const initialFocusRef = useRef();

  /**
   * This is a fix for issues in Capacitor
   * We change the Keyboard resize mode to Body when the modal is open
   * and back to Native when it is closed.
   * This is to prevent the keyboard from pushing the modal up and down
   * when the user is typing.
   */
  useChangeKeyboardResizeMode();

  /**
   * Prevent the modal from being closed via clicking the background
   * when the cancel button has been explicitly set as loading or disabled.
   */
  const preventClosing = useMemo(() => {
    const {loading, disabled} = buttonsConfig.cancel;
    return loading || disabled;
  }, [buttonsConfig.cancel]);

  /**
   * Use keyboard height in capacitor to add extra padding when needed.
   */
  const keyboardHeight = useKeyboardHeight();

  /**
   * Handle clicking the cancel button or the backdrop of the modal.
   */
  const onCancel = useCallback(() => {
    if (!preventClosing) {
      buttonsConfig.cancel.onClick();
    }
  }, [preventClosing, buttonsConfig]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0"
        initialFocus={initialFocusRef}
        onClose={onCancel}>
        <div className="flex items-center justify-center h-full p-4 text-center">
          {/* Backdrop */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            // Ensure that the duration below is equal to EXIT_DURATION
            leave={`ease-in duration-200`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay
              className={clsx(
                preventClosing && 'cursor-not-allowed',
                'fixed inset-0 bg-black bg-opacity-75 transition-opacity',
              )}
            />
          </Transition.Child>

          {/* Modal */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            // Ensure that the duration below is equal to EXIT_DURATION
            leave={`ease-in duration-200`}
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className={classes.modal}>
              {/* A hidden element to be focused on upon opening the modal. This ensures
              that the focus is captured within the modal without affecting the focus
              state of any functional elements within the modal. */}
              <div ref={initialFocusRef} className="hidden" />

              {/* Modal title */}
              {title && (
                <div className={classes.header}>
                  <Title level="h2" disableMargin>
                    {title}
                  </Title>
                </div>
              )}

              {/* Modal content */}
              <ScrollContainer id="modal-content-scroll-container">
                <div className={classes.content}>{children}</div>
                <div style={{height: keyboardHeight}}></div>
              </ScrollContainer>

              {/* Modal buttons */}
              <div className={classes.footer}>
                {/* For usability, the cancel button will always be visible
                  regardless of any configuration provided. */}
                <ModalButton
                  // If there are no primary buttons provided, then the cancel button
                  // becomes the primary button by default.
                  category={buttonsConfig.actions ? 'secondary' : 'primary'}
                  config={{
                    ...buttonsConfig.cancel,
                    label: buttonsConfig.cancel.label ?? {
                      idle: 'Cancel',
                      loading: 'Cancelling',
                    },
                    onClick: onCancel,
                  }}
                />
                {buttonsConfig.actions?.map((action) => (
                  <ModalButton
                    key={action.label.toString()}
                    category={'primary'}
                    config={{
                      ...action,
                      label: action.label ?? {
                        idle: 'Submit',
                        loading: 'Submitting',
                      },
                    }}
                  />
                ))}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.Imperative = ImperativeModal;

export default Modal;
