import {omit} from 'lodash';

const removeUndefinedPropertiesFromObject = (input: object) =>
  omit(
    input,
    Object.entries(input)
      .filter(([, value]) => value === undefined)
      .map(([key]) => key),
  );

export default removeUndefinedPropertiesFromObject;
