import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import emptyImage from 'assets/img/empty_dataset_images/chat.svg';
import EmptyDataSet from 'components/common/EmptyDataSet';
import LoadingView from 'components/common/LoadingView';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import EnquiryThread from 'models/listings/EnquiryThread';
import Listing from 'models/listings/Listing';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const EnquiryThreadIndexPage = () => {
  usePageVisit('EnquiryThreadIndexPage');
  const {propertyId, listingId: listingPublicId} = useParams();

  const {data, error, isLoading} = useQuery(
    `listing-${listingPublicId}-enquiries`,
    async () => {
      const listing = await Listing.find(listingPublicId);

      const enquiries = await EnquiryThread.where({listingId: listing.data.id})
        .includes({enquiry_messages: 'user'})
        .order({updated_at: 'desc'})
        .per(100)
        .all();

      return enquiries.data;
    },
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Enquiries">
        <ManageListingMenu
          propertyId={propertyId}
          listingPublicId={listingPublicId}
        />
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.length > 0) {
      return (
        <PageWrapper title="Enquiries">
          <ManageListingMenu
            propertyId={propertyId}
            listingPublicId={listingPublicId}
          />

          {data.map((thread) => {
            const lastMessage =
              thread.enquiryMessages[thread.enquiryMessages.length - 1];
            return (
              <Link to={thread.id} key={thread.id}>
                <Card title={thread.name} className="mb-2 cursor-pointer">
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="block text-secondary text-sm">
                        {thread.email}
                      </span>
                      <span className="block text-secondary text-sm">
                        Last Message Received at{' '}
                        {moment(thread.updatedAt).format(DATE_TIME_FORMAT)}
                      </span>

                      <p className="mt-1">
                        {lastMessage ? lastMessage.message : null}
                      </p>
                    </div>
                    <div>
                      <AiOutlineRight className="w-5 h-5 text-secondary-300" />
                    </div>
                  </div>
                </Card>
              </Link>
            );
          })}
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Enquiries">
          <ManageListingMenu
            propertyId={propertyId}
            listingPublicId={listingPublicId}
          />
          <div className="mt-4">
            <EmptyDataSet
              title="No Enquiries"
              message="You have not received any enquiries for this listing yet"
              image={emptyImage}
            />
          </div>
        </PageWrapper>
      );
    }
  }
};

export default EnquiryThreadIndexPage;
