import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Dialog, Disclosure, Transition} from '@headlessui/react';
import {SearchIcon, XIcon} from '@heroicons/react/outline';
import Toggle from 'react-toggle';

import {Clause, ClauseLibrary} from 'models/properties/ClauseLibrary';
import TrackingService from 'services/TrackingService';

interface ClauseLibraryModalOnboardFlowProps {
  clauseLibrary: ClauseLibrary;
  modalIsOpen: boolean;
  setModalIsOpen: (val: boolean) => void;
  addClause: (clause: Clause) => void;
  removeClause: (clause: Clause) => void;
  chosenClauses: Clause[];
}

const ClauseLibraryModalOnboardFlow: FunctionComponent<
  ClauseLibraryModalOnboardFlowProps
> = ({
  clauseLibrary,
  modalIsOpen,
  setModalIsOpen,
  addClause,
  removeClause,
  chosenClauses,
}) => {
  const [searchText, setSearchText] = useState('');

  const searchResults = useMemo(() => {
    const results = [];
    if (clauseLibrary) {
      for (const [, values] of Object.entries(clauseLibrary)) {
        for (const clause of values) {
          if (clause.title.toLowerCase().includes(searchText.toLowerCase())) {
            results.push(clause);
          }
        }
      }
    }
    return {'Search Results': results} as ClauseLibrary;
  }, [clauseLibrary, searchText]);

  useEffect(() => {
    setSearchText('');
  }, [modalIsOpen]);

  const closeModal = () => {
    setModalIsOpen(false);
    TrackingService.trackEvent(TrackingService.Event.CloseClauseLibrary);
  };

  /**
   * Filter the clauses to display based on the search term.
   */
  const filteredClauses = useMemo(
    () => (searchText.length > 0 ? searchResults : clauseLibrary),
    [searchText, searchResults, clauseLibrary],
  );

  return (
    <Transition appear show={modalIsOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            className="inline-block py-8 h-screen w-full"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900">
                  Keyhook Clause Library
                </Dialog.Title>
                <span className="absolute left-3 top-3">
                  <button onClick={closeModal}>
                    <XIcon className="w-5 h-5" />
                  </button>
                </span>
              </div>

              <div className="flex-grow overflow-y-auto">
                <div className="form-control mx-10 mt-10">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Type here to search..."
                      className="input input-bordered input-md w-full"
                      onChange={(e) => setSearchText(e.currentTarget.value)}
                    />
                    <button className="btn btn-square">
                      <SearchIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>

                {window.location.pathname.includes('demo') && (
                  <div className="mx-10 mt-10">
                    <div className="alert alert-warning shadow-lg">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="stroke-current flex-shrink-0 h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                          />
                        </svg>
                        <span>
                          These are not legal clauses and are meant for
                          demonstration purposes only.
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                  {Object.entries(filteredClauses).map(
                    ([title, clauses], index) => (
                      <div className="py-7" key={index}>
                        <h3 className="text-xl font-medium">{title}</h3>
                        <div className="mt-6 relative ">
                          {clauses.map((clause, index) => (
                            <Disclosure as="div" className="mt-2" key={index}>
                              {({open}) => (
                                <>
                                  <Disclosure.Button
                                    className={`flex w-full justify-between bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 ${
                                      open ? 'rounded-t-lg' : 'rounded-lg'
                                    }`}>
                                    <span>{clause.title}</span>
                                    <Toggle
                                      id={`${clause.id}-toggle`}
                                      onChange={(e) => {
                                        e.target.checked
                                          ? addClause(clause)
                                          : removeClause(clause);
                                      }}
                                      checked={
                                        !!chosenClauses.find(
                                          (c) => c.id === clause.id,
                                        )
                                      }
                                    />
                                  </Disclosure.Button>
                                  <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0">
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm bg-gray-100">
                                      {clause.clause}
                                    </Disclosure.Panel>
                                  </Transition>
                                </>
                              )}
                            </Disclosure>
                          ))}
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>

              <div className="p-2 flex-shrink-0 bg-neutral-100 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                <button
                  className="btn btn-outline btn-secondary btn-sm"
                  onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ClauseLibraryModalOnboardFlow;
