import {useCallback, useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';

import ChatMessageBubble from 'components/chat2/ChatMessageBubble';
import useScrollPosition from 'hooks/useScrollPosition';
import User from 'models/users/User';
import {usePusherService} from 'services/PusherService';
import type {ChatChannel} from 'services/PusherService';
import useAuth from 'services/useAuth';

const ChatDetailBody = ({
  channel,
  users,
}: {
  channel: ChatChannel;
  users: User[];
}) => {
  const {currentUser} = useAuth();
  const {channels} = usePusherService();

  const [isLoadingEarlier, setIsLoadingEarlier] = useState(false);
  const [infiniteScrollEnabled, setInfiniteScrollEnabled] = useState(false);
  const [page, setPage] = useState(channel.lastFetchedPage || 0);

  const [messages, setMessages] = useState(channel.messages.slice().reverse());

  const updateMessages = useCallback(() => {
    setMessages(channel.messages.slice().reverse());
  }, [setMessages, channel]);

  useEffect(() => {
    updateMessages();
  }, [channels, updateMessages]);

  useEffect(() => {
    channel.markMessagesAsRead();
  }, [channel]);

  useEffect(() => {
    loadEarlier();
  }, []);

  useEffect(() => {
    if (page === 1 && messages.length > 0) {
      const el = document.getElementById('messages');

      if (Capacitor.isNativePlatform()) {
        el.scrollIntoView(false);
      } else {
        el.scrollTop = el.scrollHeight;
      }

      // This is a bit of a hack
      // But without this, it will load 2 pages
      // And then wont be scrolled to the bottom.
      setTimeout(() => {
        setInfiniteScrollEnabled(true);
      }, 1000);
    }
  }, [page, messages.length]);

  const loadEarlier = async () => {
    if (!isLoadingEarlier) {
      setIsLoadingEarlier(true);
      await channel.fetchMessages(page + 1);
      setPage((p) => p + 1);
      setIsLoadingEarlier(false);
    }
  };

  const isNative = Capacitor.isNativePlatform();
  const scrollPosition = useScrollPosition(
    isNative ? 'root-scroll-container' : 'messages-container',
  );

  const hasMoreMessages =
    messages && messages.length >= 20 && !channel.isOutOfOlderMessages;

  useEffect(() => {
    if (scrollPosition <= 20 && hasMoreMessages && infiniteScrollEnabled) {
      loadEarlier();
    }
  }, [scrollPosition, hasMoreMessages, infiniteScrollEnabled]);

  return (
    <div id="messages-container">
      <div
        id="messages"
        className="flex flex-col grow h-full lg:max-h-[60vh] space-y-4 overflow-y-auto py-2">
        {hasMoreMessages && (
          <div className="flex justify-center" key="LOAD_MORE">
            Loading ...
          </div>
        )}

        {messages &&
          messages.map((message) => {
            return (
              <div key={message.id}>
                <ChatMessageBubble
                  message={message}
                  senderUserId={currentUser.id}
                  users={users}
                />
              </div>
            );
          })}

        <div id="chat-input-padder" style={{height: 0}}></div>
      </div>
    </div>
  );
};

export default ChatDetailBody;
