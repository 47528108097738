import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Tenancy from 'models/properties/Tenancy';
import User from 'models/users/User';

@Model()
class FormalNotice extends ApplicationRecord {
  static jsonapiType = 'formal_notices';

  @Attr() noticeType: string;

  @Attr() subject: string;
  @Attr() body: string;

  @Attr() openedAt?: string;

  @Attr() attachment: string;

  @Attr() signature: string;

  @Attr() tenancyEndDate: string;

  @Attr() tenancyId: string;
  @Attr({persist: false}) sendingUserId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('users') sendingUser: User;
  @BelongsTo('tenancies') tenancy: Tenancy;
}

export default FormalNotice;
