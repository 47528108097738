import {FunctionComponent, ReactNode} from 'react';

import {UseFloatingReturn} from '@floating-ui/react';
import clsx from 'clsx';
import {motion, AnimatePresence, MotionStyle} from 'framer-motion';

interface PopoverProps {
  floating: UseFloatingReturn;
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement>,
  ) => Record<string, unknown>;
  width?: MotionStyle['width'];
  contentClassName: string;
  children: ReactNode;
}

const Popover: FunctionComponent<PopoverProps> = ({
  floating,
  getFloatingProps,
  width,
  contentClassName = '',
  children,
}) => {
  const {x, y, strategy, refs} = floating;
  return (
    <AnimatePresence>
      {floating.context.open && (
        <motion.div
          ref={refs.setFloating}
          className={clsx(
            'z-50',
            'rounded-xl drop-shadow-2xl',
            'overflow-hidden',
            'bg-white box-border',
            'pointer-events-auto',
            contentClassName,
          )}
          transition={{
            type: 'spring',
            duration: 0.6,
          }}
          initial={{opacity: 0, y: -10, scale: 0.5}}
          animate={{opacity: 1, y: 0, scale: 1}}
          exit={{opacity: 0, y: -10, scale: 0.5}}
          style={{
            width: width ?? 'auto',
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...getFloatingProps()}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Popover;
