import {useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';

/*
  Purpose of this hook is to add padding to a scrollview whenever a virtual keyboard shown
  So that fields aren't hidden by the keyboard.
*/

const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardWillShow', (info) => {
        setKeyboardHeight(info.keyboardHeight);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setKeyboardHeight(0);
      });

      return () => {
        Keyboard.removeAllListeners();
      };
    } else {
      return () => 'noop';
    }
  }, []);

  return keyboardHeight;
};

export default useKeyboardHeight;
