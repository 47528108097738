import React, {ReactNode} from 'react';

import {type FormikProps} from 'formik';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';

type Value = {
  label: string;
  value: string | number;
};

const StackedButtonSelectField = ({
  formik,
  label,
  name,
  helpText,
  options,
}: {
  formik: FormikProps<any>;
  name: string;
  label?: string | ReactNode;
  helpText?: string;
  options: Value[];
}) => {
  const handleChange = (val: string | number) => {
    formik.setFieldValue(name, val);
  };

  return (
    <div>
      {label && (
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter>{helpText}</HelpTextPresenter>
        </label>
      )}

      <div className="flex justify-start items-center gap-4">
        {options.map((val) => (
          <button
            key={val.value}
            type="button"
            className={`btn btn-sm ${
              formik.values[name] === val.value ? '' : 'btn-outline'
            }`}
            onClick={() => handleChange(val.value)}>
            {val.label}
          </button>
        ))}
      </div>

      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default StackedButtonSelectField;
