import {
  FunctionComponent,
  ReactElement,
  ReactFragment,
  createElement,
  isValidElement,
} from 'react';

import {type PseudoRowItemElement} from './PseudoRowItem';

type PseudoRowChildren = PseudoRowItemElement[];

export interface PseudoRowProps {
  children: PseudoRowChildren;
}

export type PseudoRowElement = ReactElement<PseudoRowProps>;

export type PseudoRowInputElement = PseudoRowElement | ReactFragment;

export type PseudoRowComponent = FunctionComponent<PseudoRowProps>;

const PseudoRow: PseudoRowComponent = () => <></>;

export default PseudoRow;

/**
 * Input element type guard.
 */
export const elementIsRowElement = (
  element: unknown,
): element is PseudoRowElement => {
  return (
    isValidElement(element) && element.type === createElement(PseudoRow).type
  );
};
