import logo from 'assets/img/logo-white.png';

const FooterLarge = () => {
  return (
    <section className="w-full bg-brand-900">
      <div className="px-8 py-12 mx-auto max-w-7xl">
        <div className="grid grid-cols-2 gap-10 mb-3 md:grid-cols-3 lg:grid-cols-12 lg:gap-20">
          <div className="col-span-3">
            <a href="/">
              <img src={logo} className="mr-3 h-9 sm:h-14" alt="Logo" />
            </a>
            <p className="my-4 text-xs leading-normal text-blue-100">
              Manage your property from your pocket.
            </p>
          </div>
          <nav className="col-span-1 md:col-span-1 lg:col-span-2">
            <p className="mb-3 text-xs font-semibold tracking-wider text-blue-200 uppercase">
              Links
            </p>
            <a
              href="https://keyhook.com/"
              target="_blank"
              rel="noreferrer"
              className="flex mb-3 text-sm font-medium text-blue-100 transition md:mb-2 hover:text-white">
              Home
            </a>
            <a
              href="https://keyhook.com/about"
              target="_blank"
              rel="noreferrer"
              className="flex mb-3 text-sm font-medium text-blue-100 transition md:mb-2 hover:text-white">
              About Us
            </a>
            <a
              href="https://keyhook.com/pricing"
              target="_blank"
              rel="noreferrer"
              className="flex mb-3 text-sm font-medium text-blue-100 transition md:mb-2 hover:text-white">
              Pricing
            </a>
            <a
              href="https://help.keyhook.com"
              target="_blank"
              rel="noreferrer"
              className="flex mb-3 text-sm font-medium text-blue-100 transition md:mb-2 hover:text-white">
              FAQs
            </a>
            <a
              href="https://keyhook.com/contact"
              target="_blank"
              rel="noreferrer"
              className="flex mb-3 text-sm font-medium text-blue-100 transition md:mb-2 hover:text-white">
              Contact
            </a>
          </nav>
          <nav className="col-span-1 md:col-span-1 lg:col-span-2">
            <p className="mb-3 text-xs font-semibold tracking-wider text-blue-200 uppercase">
              Apps
            </p>
            <a
              href="https://app.keyhook.com"
              target="_blank"
              rel="noreferrer"
              className="flex mb-3 text-sm font-medium text-blue-100 transition md:mb-2 hover:text-white">
              Web
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.getpropup.app"
              target="_blank"
              rel="noreferrer"
              className="flex mb-3 text-sm font-medium text-blue-100 transition md:mb-2 hover:text-white">
              Android
            </a>
            <a
              href="https://apps.apple.com/us/app/propup/id1570942532"
              className="flex mb-3 text-sm font-medium text-blue-100 transition md:mb-2 hover:text-white"
              target="_blank"
              rel="noreferrer">
              iOS
            </a>
          </nav>
          <nav className="col-span-2 md:col-span-1 lg:col-span-2"></nav>
          <div className="col-span-3">
            <span className="inline-flex justify-center w-full mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-end">
              <a
                href="https://facebook.com/GetPropUp"
                className="text-blue-100 hover:text-white">
                <span className="sr-only">Facebook</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/keyhook"
                className="text-blue-100 hover:text-white">
                <span className="sr-only">LinkedIn</span>
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true">
                  <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
              </a>
              <a
                href="https://twitter.com/get_PropUp"
                className="text-blue-100 hover:text-white">
                <span className="sr-only">Twitter</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                </svg>
              </a>
            </span>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between pt-10 mt-10 border-t border-brand-100 md:flex-row md:items-center">
          <p className="mb-6 text-sm text-left text-blue-200 md:mb-0">
            <span>
              © Copyright {new Date().getFullYear()} PropUp Group Ltd.
            </span>
            <span>All Rights Reserved.</span>
          </p>
          <div className="flex items-start justify-start space-x-6 md:items-center md:justify-center">
            <a
              href="https://keyhook.com/terms-of-use"
              className="text-sm text-blue-200 transition hover:text-white">
              Terms of Use
            </a>
            <a
              href="https://keyhook.com/privacy-policy"
              className="text-sm text-blue-200 transition hover:text-white">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterLarge;
