import {Navigate, Route} from 'react-router-dom';

import ResetPasswordPage from 'pages/shared/auth/ResetPasswordPage';

const LegacyAuthRouteRedirects = (
  <>
    <Route path="/auth/login" element={<Navigate to="/login" />} />
    <Route
      path="/auth/forgot-password"
      element={<Navigate to="/forgot-password" />}
    />
    <Route
      path="/auth/reset-password/:token"
      element={<ResetPasswordPage.LegacyRedirect />}
    />
    <Route
      path="/auth/reset-password"
      element={<Navigate to="/reset-password" />}
    />
    <Route path="/auth/register" element={<Navigate to="/welcome" />} />
    <Route path="/auth/register" element={<Navigate to="/welcome" />} />
    <Route
      path="/auth/set-email"
      element={<Navigate to="/register/set-email" />}
    />
    <Route
      path="/auth/confirm-email"
      element={<Navigate to="/register/confirm-email" />}
    />
  </>
);

export default LegacyAuthRouteRedirects;
