import {FunctionComponent, useCallback, useMemo, useState} from 'react';

import clsx from 'clsx';
import {useFormik} from 'formik';
import {HiOutlineAdjustments} from 'react-icons/hi';
import {WhereClause} from 'spraypaint';

import {Button} from 'components_sb/buttons';
import {
  AdvancedListingsSearchFormValues,
  advancedEmptyFormValues,
} from 'constants/listings-search';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';

import FiltersSheet from './FiltersSheet';
import SearchNotifications from './SearchNotifications';

interface AdvancedFiltersProps {
  onApply: (formValues: AdvancedListingsSearchFormValues) => void;
  currentFilters: AdvancedListingsSearchFormValues;
  currentWhereClause: WhereClause;
}

const AdvancedFiltersBar: FunctionComponent<AdvancedFiltersProps> = ({
  onApply,
  currentFilters,
  currentWhereClause,
}) => {
  /**
   * Apply the form filters when the form is submitted by passing
   * the values to the parent component.
   */
  const handleSubmit = useCallback(
    (formValues: AdvancedListingsSearchFormValues) => {
      onApply(formValues);
    },
    [onApply],
  );

  /**
   * The form instance for managing the input values for filters.
   */
  const form = useFormik<AdvancedListingsSearchFormValues>({
    initialValues: currentFilters,
    enableReinitialize: true, // Update the form values when the query params change
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  /**
   * The total number of filters applied (exlcuding the page number).
   */
  const totalFiltersApplied = useMemo(
    () =>
      Object.values(form.values).filter((value) => value !== undefined).length,
    [form.values],
  );

  /**
   * Determines whether the sheet for managing filters is open.
   */
  const [filtersOpen, setFiltersOpen] = useState(false);

  /**
   * Show the sheet for managing filters.
   */
  const onShowFilters = useCallback(() => {
    /**
     * Open the sheet.
     */
    setFiltersOpen(true);
  }, []);

  /**
   * Close the sheet for managing filters.
   */
  const closeFilterSheet = useCallback(() => {
    setFiltersOpen(false);
  }, []);

  /**
   * Apply the filter set by the values in the form.
   */
  const applyFilters = useCallback(() => {
    /**
     * Submit the form to invoke the query param update.
     */
    form.handleSubmit();
    /**
     * Close the sheet.
     */
    closeFilterSheet();
  }, [form, closeFilterSheet]);

  /**
   * Clear all filters.
   */
  const onClearAllFilters = useCallback(() => {
    /**
     * Set all fields to undefined.
     */
    form.setFormikState((current) => ({
      ...current,
      values: advancedEmptyFormValues,
    }));
    /**
     * Apply the filters.
     */
    applyFilters();
  }, [form, applyFilters]);

  const mdBreakpoint = useTailwindBreakpoint('md');

  return (
    <>
      <div
        className={clsx(
          'sticky top-0 z-10',
          'bg-white',
          'border-brand-50 border-b-2',
        )}>
        <div
          className={clsx(
            'w-full max-w-7xl',
            'mx-auto',
            'px-6 lg:px-8',
            'py-2 md:py-4',
          )}>
          <div className="flex flex-row gap-x-2">
            <div
              className={clsx(
                'flex-1 flex items-center',
                'text-brand-850 opacity-70',
              )}>
              {totalFiltersApplied === 0
                ? 'Showing all listings'
                : `Showing listings with ${totalFiltersApplied} ${
                    totalFiltersApplied > 1 ? 'filters' : 'filter'
                  } applied`}
            </div>

            {/* Actions */}
            <div className="flex gap-x-4">
              {/* Search notifications */}
              <SearchNotifications currentWhereClause={currentWhereClause} />

              {/* Modify filters */}
              {mdBreakpoint ? (
                // Full sized button
                <Button
                  category="primary"
                  size="sm"
                  label="Refine search"
                  icon={HiOutlineAdjustments}
                  mode="manual"
                  fillWidth={false}
                  onClick={onShowFilters}
                />
              ) : (
                // Icon button
                <Button
                  category="primary"
                  size="base"
                  format="icon"
                  icon={HiOutlineAdjustments}
                  mode="manual"
                  onClick={onShowFilters}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* The sheet containing the filters */}
      <FiltersSheet
        form={form}
        isOpen={filtersOpen}
        onApply={applyFilters}
        onClearAll={onClearAllFilters}
      />
    </>
  );
};

export default AdvancedFiltersBar;
