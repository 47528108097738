import {useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import CompletedInspectionList from 'components/inspection/CompletedInspectionList';
import InspectionInProgressCard from 'components/inspection/InspectionInProgressCard';
import LandlordScheduleInspectionCard from 'components/inspection/LandlordScheduleInspectionCard';
import PendingUploadModal from 'components/inspection/PendingUploadModal';
import RenterAwaitingInspectionTypeCard from 'components/inspection/RenterAwaitingInspectionTypeCard';
import RoomList from 'components/inspection/RoomList';
import PageWrapper from 'components/PageWrapper';
import Modal from 'components_sb/layout/Modal/Modal';
import useInspectionQueries from 'hooks/useInspectionQueries';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import {Action} from 'types/actions';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const {useModal} = Modal.Imperative;

const InspectionDetailPage = () => {
  useTitle('Inspection');
  usePageVisit('InspectionDetailPage');

  const openModal = useModal();

  const {id} = useParams();
  const {activeAccountRole} = useLocalUserSettings();

  const {
    inspection,
    inspectionIsLoading,
    inspectionError,
    inspectionItemsIsLoading,
  } = useInspectionQueries(id);

  const landlordViewForStatus = () => {
    if (inspection?.status === 'scheduled') {
      return <LandlordScheduleInspectionCard inspection={inspection} />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'landlord'
    ) {
      return <RoomList />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'tenant'
    ) {
      return <InspectionInProgressCard />;
    } else if (inspection.status === 'awaiting_sign_offs') {
      return <RoomList />;
    } else if (inspection.status === 'complete') {
      return <CompletedInspectionList />;
    }
  };

  const renterViewForStatus = () => {
    if (inspection?.status === 'scheduled') {
      return <RenterAwaitingInspectionTypeCard />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'tenant'
    ) {
      return <RoomList />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'landlord'
    ) {
      return <InspectionInProgressCard />;
    } else if (inspection.status === 'awaiting_sign_offs') {
      return <RoomList />;
    } else if (inspection.status === 'complete') {
      return <CompletedInspectionList />;
    }
  };

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(() => {
    return !inspection
      ? null
      : [
          ...(activeAccountRole === 'Landlord' &&
          inspection.status !== 'awaiting_sign_offs' &&
          inspection.status !== 'complete'
            ? [
                {
                  label: 'Edit',
                  linkTo: `edit`,
                },
              ]
            : []),
          ...(inspection.status === 'awaiting_inspection' &&
          Capacitor.isNativePlatform()
            ? [
                {
                  label: 'Pending Uploads',
                  onClick: () => openModal(PendingUploadModal),
                },
              ]
            : []),
        ];
  }, [activeAccountRole, inspection, openModal]);

  if (inspectionError) {
    return errorViewForError(inspectionError);
  } else if (inspectionIsLoading || inspectionItemsIsLoading) {
    return (
      <PageWrapper title="Inspection">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Inspection" actions={pageActions}>
        {activeAccountRole === 'Landlord'
          ? landlordViewForStatus()
          : renterViewForStatus()}
      </PageWrapper>
    );
  }
};

export default InspectionDetailPage;
