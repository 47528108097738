import {ClockIcon} from '@heroicons/react/outline';
import {pick} from 'lodash';
import moment from 'moment';

import ChangePhotoModal from 'components/property/landlord/ChangePhotoModal';
import {Button} from 'components_sb/buttons';
import {Modal} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';

import StatsCard from './StatsCard';
import RoomInfo from './StatsCard/RoomInfo';

const {useModal} = Modal.Imperative;

const LandlordPropertyDetailCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const openModal = useModal();
  const showChangePhotoModal = () => {
    openModal(ChangePhotoModal, {property});
  };

  const renderStartDateBanner = () => {
    if (tenancy && tenancy.status === TenancyStatus.AwaitingStartDate) {
      let timeToGo = moment(tenancy.startDate).diff(moment(), 'days');
      let unit = 'Days';

      if (timeToGo <= 3) {
        timeToGo = moment(tenancy.startDate).diff(moment(), 'hours');
        unit = 'Hours';
      }

      return (
        <div className="alert alert-info shadow-lg my-4">
          <div>
            <ClockIcon className="w-5 h-5" />
            <span>
              <strong className="underline">
                {timeToGo} {unit}
              </strong>{' '}
              until this tenancy is active on Keyhook.
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      {/* Large screens (desktop) */}
      <div className="hidden md:flex flex-col justify-center">
        <div className="relative flex flex-col md:flex-row md:space-x-0 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 w-full mx-auto border border-white bg-white">
          <div className="flex w-60 relative mr-4">
            <div
              className="w-full h-full rounded-xl bg-cover bg-center"
              style={{backgroundImage: `url(${property.mainImage})`}}>
              <div className="absolute rounded-xl top-0 left-0 w-full h-full transition-color duration-300 bg-transparent hover:bg-black hover:bg-opacity-50 cursor-pointer group">
                <div className="w-full h-full flex justify-center items-center invisible group-hover:visible">
                  <div className="mx-auto">
                    <Button
                      label="Change photo"
                      category="primary"
                      size="base"
                      mode="manual"
                      fillWidth={false}
                      onClick={showChangePhotoModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
            <div className="flex justify-between item-center">
              <p className="text-brand-850 text-opacity-70 font-medium md:block">
                {property.suburb}, {property.city}
              </p>
            </div>
            <h3 className="font-black text-brand-850 md:text-3xl text-xl">
              {property.streetAddress}
            </h3>
            <StatsCard property={property} />
            {renderStartDateBanner()}
          </div>
        </div>
      </div>

      {/* Small screens (mobile) */}
      <div className="flex md:hidden flex-col justify-center mb-0">
        <div className="flex flex-col w-full mx-auto">
          <img
            src={property.mainImage}
            alt={property.streetAddress}
            className="w-full"
            onClick={showChangePhotoModal}
          />

          <div className="w-full flex flex-col py-3 px-6">
            <Paragraph size="sm" secondary>
              {property.suburb}, {property.city}
            </Paragraph>
            <Title level="h3" size="lg">
              {property.streetAddress}
            </Title>
            <StatsCard mobile property={property} />
            {renderStartDateBanner()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandlordPropertyDetailCard;
