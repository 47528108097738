import {MouseEvent} from 'react';

import {IconType} from '@react-icons/all-files';

interface ActionBase {
  label: string;
  description?: string;
  icon?: IconType;
  testId?: string;
}

export interface ButtonAction extends ActionBase {
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

export const actionIsButton = (action: Action): action is ButtonAction => {
  return !!(action as ButtonAction).onClick;
};

export interface LinkAction extends ActionBase {
  linkTo: string;
}

export const actionIsLink = (action: Action): action is LinkAction => {
  return !!(action as LinkAction).linkTo;
};

export type Action = ButtonAction | LinkAction;
