module.exports = {
  mode: 'jit',
  content: ['./src/**/*.{js,jsx,ts,tsx,html}', './dist/index.html'],
  darkMode: 'class',
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '1rem',
        '2xl': '128px',
      },
    },
    fontFamily: {
      poppins: ['Poppins', 'system-ui', 'sans-serif'],
    },
    screens: {
      // min-width
      xxs: '380px',
      xs: '446px',
      sm: '640px',
      md: '768px',
      mdlg: '920px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',

      // max-width <-- These should be removed in favour of using min width
      mmd: {max: '768px'},
      mlg: {max: '1024px'},
    },
    extend: {
      colors: {
        // Import colours from theme
        ...require('./src/themes/keyhook').colors,
      },
      fontSize: {
        xxs: ['0.65rem', '0.85rem'], // 10px font size and 13px line height
        base: ['0.9375rem', '1.375rem'], // 15px font size and 22px line height
      },
      width: {
        84: '21rem' /* 336px */,
        88: '22rem' /* 352px */,
        128: '32rem' /* 512px */,
      },
      minWidth: {
        64: '16rem' /* 256px */,
      },
      spacing: {
        18: '4.5rem',
      },
      height: (theme) => ({
        auto: 'auto',
        ...theme('spacing'),
        full: '100%',
        // Override default 'screen' value to resolve
        // issues with mobile viewports
        screen: 'calc(var(--viewport-height) * 100)',
        content: 'calc((var(--viewport-height) - var(--nav-height)) * 100)',
      }),
      minHeight: (theme) => ({
        0: '0',
        ...theme('spacing'),
        full: '100%',
        // Override default 'screen' value to resolve
        // issues with mobile viewports
        screen: 'calc(var(--viewport-height) * 100)',
        content: 'calc((var(--viewport-height) - var(--nav-height)) * 100)',
      }),
      maxHeight: {
        auto: 'auto',
      },
      keyframes: {
        'fade-in-down': {
          '0%': {
            opacity: '0',
            transform: 'translateY(-10px)',
          },
          '100%': {
            opacity: '1',
            transform: 'translateY(0)',
          },
        },
      },
      animation: {
        'fade-in-down': 'fade-in-down 0.5s ease-out',
      },
      boxShadow: {
        'md-top':
          '0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
        'lg-top':
          '0 -10px 15px -3px rgb(0 0 0 / 0.1), 0 -4px 6px -4px rgb(0 0 0 / 0.1);',
        'lg-left':
          '-10px 0 15px -3px rgb(0 0 0 / 0.1), 0 -4px 0px -4px rgb(0 0 0 / 0.1);',
        'lg-right':
          '10px 0 15px -3px rgb(0 0 0 / 0.1), 0 -4px 0px -4px rgb(0 0 0 / 0.1);',
      },
      scale: {
        80: '0.8',
      },
      zIndex: {
        60: '60',
        70: '70',
        80: '80',
        90: '90',
        100: '100',
      },
      borderWidth: {
        3: '3px',
      },
    },
  },
  plugins: [
    require('daisyui'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/aspect-ratio'),
    require('tailwind-children'),
    require('tailwind-hamburgers'),
    require('@tailwindcss/container-queries'),
    require('tailwind-scrollbar-hide'),

    // The below will change the scale site-wide:
    // plugin(function ({addBase}) {
    //   addBase({
    //     html: {fontSize: '14px'},
    //   });
    // }),
  ],
  daisyui: {
    themes: [
      {
        keyhook: require('./src/themes/keyhook').daisyUITheme,
      },
    ],
  },
};
