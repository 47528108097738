import {useCallback} from 'react';

/**
 * Provides scroll functions for the given element ID.
 */
const useScroll = (elementId: string) => {
  const getElement = useCallback(
    () => document.getElementById(elementId),
    [elementId],
  );

  const scrollToTop = useCallback(() => {
    const element = getElement();
    if (element) {
      /**
       * For the ScrollContainer components, the actual scrollable element is the first child.
       */
      (element.firstChild as HTMLDivElement).scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [getElement]);

  return {
    scrollToTop,
  };
};

export default useScroll;
