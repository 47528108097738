import {useState} from 'react';

import {AiOutlineWarning} from '@react-icons/all-files/ai/AiOutlineWarning';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {Link, Navigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import ConfirmEmailAlert from 'components/auth/ConfirmEmailAlert';
import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import CurrentTenancyCard from 'components/property/renter/CurrentTenancyCard';
import UserAvatar from 'components/user/UserAvatar';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {TenancyStatus} from 'models/properties/Tenancy';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyMembership from 'models/properties/TenancyMembership';
import TenancyRequest from 'models/properties/TenancyRequest';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {toCurrency} from 'utilities/StringHelpers';

const TenancyIndexPage = () => {
  useTitle('Dashboard');
  usePageVisit('TenancyIndexPage');

  const queryClient = useQueryClient();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const [inviteActionLoading, setInviteActionLoading] = useState(false);

  const memberships = useQuery(
    'renter-tenancies',
    async () => {
      const res = await TenancyMembership.select({
        tenancy_memberships: [
          'active',
          'bond_paid',
          'rent_split',
          'created_at',
        ],
        tenancies: [
          'status',
          'bond',
          'total_rent',
          'rental_period',
          'next_inspection_date',
          'start_date',
          'end_date',
          'created_at',
          'is_new',
        ],
        properties: ['street_address', 'suburb', 'city', 'main_image'],
      })
        .includes([
          {tenancy: ['property', 'open_service_requests']},
          'not_paid_rent_payments',
        ])
        .all();
      return res.data;
    },
    {retry: 1},
  );

  const tenancyRequests = useQuery(
    'renter-tenancy-requests',
    async () => {
      const res = await TenancyRequest.includes({
        tenancy: {property: 'landlord'},
      })
        .select({
          tenancy_requests: ['accepted', 'created_at'],
          tenancies: [
            'status',
            'start_date',
            'end_date',
            'total_rent',
            'rental_period',
            'bond',
            'is_new',
          ],
          properties: ['street_address', 'suburb', 'city', 'main_image'],
          users: ['name', 'avatar'],
        })
        .all();
      return res.data;
    },
    {retry: 1},
  );

  const tenancyInvites = useQuery(
    'renter-tenancy-invites',
    async () => {
      const res = await TenancyInvite.includes([
        'sending_user',
        {tenancy: 'property'},
      ])
        .select({
          tenancies: [
            'status',
            'start_date',
            'end_date',
            'total_rent',
            'rental_period',
            'bond',
            'is_new',
          ],
          properties: ['street_address', 'suburb', 'city', 'main_image'],
          users: ['name', 'avatar'],
        })
        .all();
      return res.data;
    },
    {retry: 1},
  );

  const renderTenancyRequestCards = () => {
    if (tenancyRequests.data && tenancyRequests.data.length > 0) {
      return (
        <div>
          <h3 className="text-2xl font-semibold">Tenancy Requests</h3>

          {tenancyRequests.data.map((request) => {
            const tenancy = request.tenancy;
            const property = tenancy.property;
            const landlord = property.landlord;

            let period: string;
            if (tenancy.rentalPeriod === 'Monthly') {
              period = 'month';
            } else if (tenancy.rentalPeriod === 'Fortnightly') {
              period = 'fortnight';
            } else {
              period = 'week';
            }

            return (
              <Card className="mt-4" key={request.id}>
                <div className="alert items-start">
                  <div className="flex-1">
                    <UserAvatar user={landlord} size="8" />
                    <label className="ml-2">
                      <h4 className="font-semibold">{landlord.name}</h4>
                      <p className="text-sm text-base-content text-opacity-60">
                        Sent {moment(request.createdAt).fromNow()}
                      </p>
                    </label>
                  </div>
                </div>

                {tenancy.isNew ? (
                  <>
                    <Paragraph>
                      {landlord.name} has invited you to start a tenancy at{' '}
                      <strong>{property.streetAddress}</strong>,{' '}
                      {property.suburb}, {property.city}, for{' '}
                      <strong>{toCurrency(tenancy.totalRent)}</strong>/{period}{' '}
                      (total) with a bond of{' '}
                      <strong>{toCurrency(tenancy.bond)}</strong> (total).
                    </Paragraph>
                    <Paragraph>
                      This tenancy would begin on
                      <strong>
                        {' ' + moment(tenancy.startDate).format(DATE_FORMAT)}
                      </strong>
                      {tenancy.endDate
                        ? ' and would end on '
                        : ' and would be ongoing'}
                      {tenancy.endDate ? (
                        <strong>
                          {moment(tenancy.endDate).format(DATE_FORMAT)}
                        </strong>
                      ) : null}
                      .
                    </Paragraph>
                    {request.accepted && (
                      <p className="text-success">
                        You have accepted this request, we are currently waiting
                        for other tenants to sign.
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <Paragraph>
                      {landlord.name} has invited you to migrate your tenancy at{' '}
                      <strong>{property.streetAddress}</strong>,{' '}
                      {property.suburb}, {property.city} to Keyhook.
                    </Paragraph>
                    {request.accepted && (
                      <p className="text-success">
                        You have accepted this invite, your landlord will set a
                        commencement date soon.
                      </p>
                    )}
                  </>
                )}

                <Link to={`/tenancy-requests/${request.id}`}>
                  <button className="btn btn-neutral btn-block">
                    {request.accepted ? 'See Details' : 'NEXT'}
                  </button>
                </Link>
              </Card>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const confirmRejectTenancyInvite = (invite: TenancyInvite) => {
    setConfirmationOptions({
      title: 'Reject Tenancy Invite',
      message: 'Are you sure you want to reject the invite for this tenancy?',
      buttonTitle: 'Reject',
      action: () => rejectTenancyInvite(invite),
      color: 'error',
    });
  };

  const rejectTenancyInvite = async (invite: TenancyInvite) => {
    setInviteActionLoading(true);
    const result = await invite.destroy();
    if (result) {
      toast.success('You have successfully rejected the invitation.');
      queryClient.invalidateQueries('renter-tenancy-invites');
    }
    setInviteActionLoading(false);
  };

  const acceptInvite = async (invite: TenancyInvite) => {
    setInviteActionLoading(true);
    invite.accepted = true;

    const result = await invite.save();
    if (result) {
      toast.success('You have successfully accepted the invitation!');
      queryClient.invalidateQueries('renter-tenancy-invites');
    } else {
      toast.error('There was an issue accepting the invitation.');
    }
    setInviteActionLoading(false);
  };

  const renderTenancyInviteCards = () => {
    if (tenancyInvites.data && tenancyInvites.data.length > 0) {
      return (
        <div>
          <h3 className="text-2xl font-semibold">Tenancy Invites</h3>

          {tenancyInvites.data.map((invite) => {
            const tenancy = invite.tenancy;
            const property = tenancy.property;
            const user = invite.sendingUser;

            return (
              <Card className="mt-4" key={invite.id}>
                <div className="alert">
                  <div className="flex-1">
                    <UserAvatar user={user} size="8" />
                    <label className="ml-2">
                      <h4 className="font-semibold">{user.name}</h4>
                      <p className="text-sm text-base-content text-opacity-60">
                        Sent {moment(invite.createdAt).fromNow()}
                      </p>
                    </label>
                  </div>
                </div>

                <Paragraph>
                  {user.name} has invited you to join the tenancy at{' '}
                  <strong>{property.streetAddress}</strong>, {property.suburb},{' '}
                  {property.city} on Keyhook.
                </Paragraph>

                <Paragraph>
                  Accepting this invite will give you access to the tenancy,
                  allowing you to create maintenance requests, see documents,
                  contact your landlord and more.
                </Paragraph>

                {invite.accepted && (
                  <p className="text-success">
                    You have accepted this invite, you will be added to the
                    tenancy momentarily.
                  </p>
                )}

                {!invite.accepted && (
                  <div className="flex justify-start space-x-4">
                    <div>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => acceptInvite(invite)}
                        disabled={inviteActionLoading}>
                        Accept Invite
                      </button>
                    </div>

                    <div>
                      <button
                        type="button"
                        className="btn btn-neutral"
                        onClick={() => confirmRejectTenancyInvite(invite)}
                        disabled={inviteActionLoading}>
                        Reject Invite
                      </button>
                    </div>
                  </div>
                )}
              </Card>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const PastTenanciesTable = () => {
    const pastStatuses: TenancyStatus[] = [TenancyStatus.Ended];

    const pastTenancies = memberships.data.filter((membership) =>
      pastStatuses.includes(membership.tenancy.status),
    );

    if (pastTenancies && pastTenancies.length > 0) {
      return (
        <div className="my-2">
          <h3 className="mb-4 text-2xl font-semibold">Past Tenancies</h3>
          {pastTenancies.map((membership) => (
            <CurrentTenancyCard key={membership.id} membership={membership} />
          ))}
        </div>
      );
    }

    return null;
  };

  const UpcomingTenanciesTable = () => {
    const upcomingTenancies = memberships.data.filter(
      (membership) => membership.tenancy.isUpcoming,
    );

    if (upcomingTenancies && upcomingTenancies.length > 0) {
      return (
        <div className="my-2">
          <h3 className="mb-4 text-2xl font-semibold">Upcoming Tenancies</h3>
          {upcomingTenancies.map((membership) => (
            <CurrentTenancyCard key={membership.id} membership={membership} />
          ))}
        </div>
      );
    }

    return null;
  };

  const CurrentTenanciesTable = () => {
    const currentTenancies = memberships.data.filter(
      (membership) => membership.tenancy.isActive,
    );

    if (currentTenancies && currentTenancies.length > 0) {
      return (
        <div className="my-2">
          <h3 className="mb-4 text-2xl font-semibold">Current Tenancies</h3>
          {currentTenancies.map((membership) => (
            <CurrentTenancyCard key={membership.id} membership={membership} />
          ))}
        </div>
      );
    }

    return null;
  };

  const alerts = () => {
    if (memberships.data) {
      let count = 0;
      memberships.data.forEach((m) => {
        count += m.notPaidRentPayments.length;
      });

      if (count > 0) {
        return (
          <div>
            <div className="alert alert-error shadow-lg text-white mb-4">
              <div>
                <AiOutlineWarning className="w-6 h-6" />
                <span>
                  You have <strong>{count} unpaid rent payment(s)</strong>. If
                  you think this is a mistake,{' '}
                  <a
                    className="text-white underline font-semibold"
                    href="https://help.keyhook.com/financials/why-does-keyhook-tell-me-my-rent-is-overdue-after-i-ve-paid-it"
                    target="_blank">
                    click here
                  </a>
                  .
                </span>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    return null;
  };

  if (memberships.error) {
    return errorViewForError(memberships.error);
  } else if (tenancyRequests.error) {
    return errorViewForError(tenancyRequests.error);
  } else if (
    memberships.isLoading ||
    tenancyRequests.isLoading ||
    tenancyInvites.isLoading
  ) {
    return (
      <PageWrapper title="Dashboard">
        <LoadingView />
      </PageWrapper>
    );
  } else if (
    memberships.data.length === 0 &&
    tenancyRequests.data.length === 0 &&
    tenancyInvites.data.length === 0
  ) {
    return <Navigate to="/listings" />;
  } else {
    return (
      <PageWrapper title="Dashboard">
        {alerts()}
        <ConfirmEmailAlert />
        {renderTenancyRequestCards()}
        {renderTenancyInviteCards()}

        <CurrentTenanciesTable />
        <UpcomingTenanciesTable />
        <PastTenanciesTable />
      </PageWrapper>
    );
  }
};

export default TenancyIndexPage;
