import {useMemo} from 'react';

import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import Property from 'models/properties/Property';
import {Action} from 'types/actions';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const ListingIndexPage = () => {
  usePageVisit('ListingIndexPage');
  const {propertyId} = useParams();

  const {data, error, isLoading, isSuccess} = useQuery(
    `property-${propertyId}-listings`,
    async () => {
      const property = await Property.includes('listings').find(propertyId);
      return property.data;
    },
  );

  const hasActive =
    isSuccess && !!data.listings.find((listing) => listing.isActive);

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      hasActive || !propertyId
        ? null
        : [
            {
              label: 'New listing',
              linkTo: `/properties/${propertyId}/listings/new`,
            },
          ],
    [hasActive, propertyId],
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Listings">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    if (data.listings.length > 0) {
      return (
        <PageWrapper title="Listings" actions={pageActions}>
          <div>
            {data.listings.map((listing) => (
              <Link to={listing.publicId} key={listing.id}>
                <Card
                  title={`Listing ${listing.publicId}`}
                  className="mt-2 cursor-pointer"
                  key={listing.id}>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-secondary text-sm">
                        Created On:{' '}
                        {moment(listing.createdAt).format(DATE_FORMAT)}
                      </p>
                      {listing.isActive && (
                        <div className="badge badge-accent mt-2 text-white">
                          Active
                        </div>
                      )}
                    </div>
                    <div>
                      <AiOutlineRight className="w-5 h-5 text-secondary-300" />
                    </div>
                  </div>
                </Card>
              </Link>
            ))}
          </div>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Listings" actions={pageActions}>
          <div></div>
        </PageWrapper>
      );
    }
  }
};

export default ListingIndexPage;
