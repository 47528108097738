import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import {InlineError} from 'components_sb/feedback';

interface CheckboxFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  id?: string;
  color?: string;
  sizeClass?: string;
  label: any;
}

const CheckboxField = ({
  formik,
  label,
  name,
  color = 'accent',
  sizeClass = 'sm',
  ...rest
}: CheckboxFieldProps) => {
  let id;
  if (rest.id) {
    id = rest.id;
  } else {
    id = name;
  }

  if (rest.className) {
    rest.className = `checkbox checkbox-${color} checkbox-${sizeClass} ${rest.className}`;
  } else {
    rest.className = `checkbox checkbox-${color} checkbox-${sizeClass}`;
  }

  return (
    <div>
      <div className="form-control">
        <label className="cursor-pointer label">
          <span className="label-text">{label}</span>
          <input
            type="checkbox"
            checked={formik.values[name]}
            id={id}
            onChange={({target}) => formik.setFieldValue(name, target.checked)}
            {...rest}
          />
        </label>
      </div>
      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default CheckboxField;
