import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Associate from 'models/associates/Associate';
import User from 'models/users/User';

@Model()
class PromoCode extends ApplicationRecord {
  static jsonapiType = 'promo_codes';

  @Attr({persist: false}) code: string;

  @Attr({persist: false}) isAssociateCode: boolean;
  @Attr({persist: false}) isReferralCode: boolean;

  @Attr({persist: false}) maxUses: number;
  @Attr({persist: false}) expiresOn: string;

  @Attr({persist: false}) duration: number;
  @Attr({persist: false}) period: string;

  @Attr({persist: false}) referralAddedDuration: number;
  @Attr({persist: false}) referralAddedPeriod: string;

  @Attr({persist: false}) associateId: string;
  @Attr({persist: false}) referringUserId: string;

  @BelongsTo('users') referringUser: User;
  @BelongsTo('associates') associate: Associate;
}

export default PromoCode;
