import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {isEqual} from 'lodash';
import {
  MdOutlineNotificationsActive,
  MdOutlineNotificationsOff,
} from 'react-icons/md';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import {WhereClause} from 'spraypaint';

import {Button} from 'components_sb/buttons';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import SavedListingSearch from 'models/listings/SavedListingSearch';
import useAuth from 'services/useAuth';

interface SearchNotificationsProps {
  currentWhereClause: WhereClause;
}

// TODO: Prompt user to log in or register if they are not logged in.

const SearchNotifications: FunctionComponent<SearchNotificationsProps> = ({
  currentWhereClause,
}) => {
  const {userIsLoggedIn} = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const mdBreakpoint = useTailwindBreakpoint('md');

  const {
    data: savedSearches,
    isLoading: isLoadingSavedSearches,
    isError: errorLoadingSavedSearches,
  } = useQuery(
    /**
     * By using the stringified filters object as the query key,
     * we can retrigger the query when the filters change.
     */
    'saved_listing_searches',
    async () => (await SavedListingSearch.per(200).all()).data,
    {
      /**
       * We don't want to attempt to fetch saved searches if there
       * is no logged in user.
       */
      enabled: userIsLoggedIn,
    },
  );

  /**
   * Attempt to find a SavedListingSearch instance that
   * matches the current filter configuration using a deep
   * equality check.
   */
  const savedSearchInstance = useMemo<SavedListingSearch | null>(
    () =>
      savedSearches?.find((savedSearch) =>
        isEqual(savedSearch.params, currentWhereClause),
      ) ?? null,
    [currentWhereClause, savedSearches],
  );

  /**
   * If a SavedListingSearch instance that matches the current filter
   * configuration was found, then we know that the current search has
   * been saved.
   */
  const hasEnabledNotifications = useMemo<boolean>(
    () => !!savedSearchInstance,
    [savedSearchInstance],
  );

  /**
   * Track the state of either enabling or disabled notifications.
   */
  const [togglingNotifications, setTogglingNotifications] =
    useState<boolean>(false);

  /**
   * Enable notifications for when new listings are
   * posted that match the current filter configuration.
   */
  const onEnableNotifications = useCallback(async () => {
    if (!userIsLoggedIn) {
      navigate('/login');
      return;
    }

    setTogglingNotifications(true);
    /**
     * Attempt to enable notifications.
     */
    try {
      // TODO: Allow choosing a custom name via searchName attribute
      const newSavedSearch = new SavedListingSearch({
        notificationType: 'daily',
        params: currentWhereClause,
      });

      /**
       * Attempt to save the the new saved search instance.
       */
      const success = await newSavedSearch.save();

      /**
       * Trigger an error if the save failed.
       */
      if (!success) {
        throw new Error();
      }

      /**
       * Update the query cache to include the new saved search instance.
       */
      queryClient.setQueryData(
        ['saved_listing_searches'],
        [...savedSearches, newSavedSearch],
      );

      toast.success('Notifications for this search have been enabled!');
    } catch (error) {
      toast.error(
        'Sorry, there was an issue enabling notifications for this search.',
      );
    }
    setTogglingNotifications(false);
  }, [
    userIsLoggedIn,
    navigate,
    currentWhereClause,
    queryClient,
    savedSearches,
  ]);

  /**
   * Disable notifications for when new listings are
   * posted that match the current filter configuration.
   */
  const onDisableNotifications = useCallback(async () => {
    if (!savedSearchInstance) {
      console.warn(
        'Unable to disable notifications since no SavedListingSearch instance was found.',
      );
      return;
    }

    setTogglingNotifications(true);

    /**
     * Attempt to disable the notifications.
     */
    try {
      const success = await savedSearchInstance.destroy();

      if (!success) {
        throw new Error();
      }

      /**
       * Update the query cache to exclude the disabled saved search instance.
       */
      queryClient.setQueryData(
        ['saved_listing_searches'],
        savedSearches.filter(({id}) => id !== savedSearchInstance.id),
      );

      toast.success('Notifications for this search have been disabled.');
    } catch (error) {
      toast.error(
        'Sorry, there was an issue disabling notifications for this search.',
      );
    }

    setTogglingNotifications(false);
  }, [savedSearchInstance, savedSearches, queryClient]);

  /**
   * Do not render if the saved searches failed to load.
   */
  if (errorLoadingSavedSearches) {
    return null;
  }

  return hasEnabledNotifications ? (
    <>
      {/* Cancel notifications buttons (desktop and mobile) */}
      {mdBreakpoint ? (
        // Full sized button
        <Button
          category="danger"
          size="sm"
          label="Disable notifications"
          icon={MdOutlineNotificationsOff}
          mode="manual"
          fillWidth={false}
          loading={togglingNotifications}
          onClick={onDisableNotifications}
        />
      ) : (
        // Icon button
        <Button
          category="danger"
          size="base"
          format="icon"
          icon={MdOutlineNotificationsOff}
          mode="manual"
          loading={togglingNotifications}
          onClick={onDisableNotifications}
        />
      )}
    </>
  ) : (
    <>
      {/* Enable notifications buttons (desktop and mobile) */}
      {mdBreakpoint ? (
        // Full sized button
        <Button
          category="secondary"
          size="sm"
          label="Notify me"
          icon={MdOutlineNotificationsActive}
          mode="manual"
          fillWidth={false}
          onClick={onEnableNotifications}
          loading={isLoadingSavedSearches || togglingNotifications}
        />
      ) : (
        // Icon button
        <Button
          category="secondary"
          size="base"
          format="icon"
          icon={MdOutlineNotificationsActive}
          mode="manual"
          onClick={onEnableNotifications}
          loading={isLoadingSavedSearches || togglingNotifications}
        />
      )}
    </>
  );
};

export default SearchNotifications;
