import React, {useState} from 'react';

import {ChartBarIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import moment from 'moment';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {
  DOBField,
  InputField,
  SelectField,
  SubmitButton,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import MoneyField from 'components/forms_fields/MoneyField';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const AssetInformationAction = ({
  property,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    property.assignAttributes(formValues);
    property.setProfileStepAsCompleted(storeKey);

    const result = await property.save();

    if (result) {
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'detail-page'}],
        property,
      );
      toast.success('Property successfully updated!');
      setModalVisible(false);
    } else {
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const dismissAction = async () => {
    property.setProfileStepAsCompleted(storeKey);

    const result = await property.save();

    if (result) {
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'detail-page'}],
        property,
      );
    }
  };

  const title = 'Asset Management';
  const subtitle = "Keep track of your asset's performance with Keyhook.";
  const iconBgColor = 'bg-lime-200';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={ChartBarIcon}
        iconBgColor={iconBgColor}
        dismissable
        onClick={() => setModalVisible(true)}
        onDismiss={dismissAction}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={ChartBarIcon}
        bgColor={iconBgColor}
        form={
          <Formik
            initialValues={{
              purchasePrice: property.purchasePrice || undefined,
              purchaseDate: property.purchaseDate || undefined,
              mortgageInterestRate: property.mortgageInterestRate || undefined,
              mortgageRepaymentFrequency:
                property.mortgageRepaymentFrequency || undefined,
              mortgageRenewalDate: property.mortgageRenewalDate || undefined,
            }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              purchasePrice: Yup.number()
                .min(0)
                .optional()
                .nullable()
                .label('Purchase Price'),
              purchaseDate: Yup.string()
                .optional()
                .nullable()
                .label('Purchase Date')
                .test('is-valid-date', 'Please enter a valid date', (value) => {
                  if (!value) return true;

                  return moment(value, 'DD/MM/YYYY').isValid();
                }),
              mortgageInterestRate: Yup.number()
                .min(0)
                .nullable()
                .optional()
                .label('Interest Rate'),
              mortgageRepaymentFrequency: Yup.string()
                .oneOf([
                  'daily',
                  'weekly',
                  'fortnightly',
                  'monthly',
                  'quarterly',
                  'semi-anually',
                  'anually',
                ])
                .optional()
                .nullable()
                .label('Repayment Frequency'),
              mortgageRenewalDate: Yup.string()
                .optional()
                .nullable()
                .label('Renewal Date')
                .test('is-valid-date', 'Please enter a valid date', (value) => {
                  if (!value) return true;

                  return moment(value, 'DD/MM/YYYY').isValid();
                }),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col justify-center grow space-y-4">
                    <FormRow responsive>
                      <FormRowItem>
                        <MoneyField
                          formik={formik}
                          name="purchasePrice"
                          label="Purchase Price"
                          placeholder="E.g. 820000"
                        />
                      </FormRowItem>
                      <FormRowItem>
                        <DOBField
                          formik={formik}
                          name="purchaseDate"
                          label="Purchase Date"
                        />
                      </FormRowItem>
                    </FormRow>

                    <FormRow responsive>
                      <FormRowItem>
                        <InputField
                          formik={formik}
                          name="mortgageInterestRate"
                          labelProps={{
                            title: 'Mortgage interest rate',
                          }}
                          prefixLabel="%"
                          type="number"
                          placeholder="E.g. 3.25"
                        />
                      </FormRowItem>
                      <FormRowItem>
                        <SelectField
                          formik={formik}
                          name="mortgageRepaymentFrequency"
                          labelProps={{title: 'Repayment frequency'}}>
                          <option>Choose an option</option>
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="fortnightly">Fortnightly</option>
                          <option value="monthly">Monthly</option>
                          <option value="quarterly">Quarterly</option>
                          <option value="semi-anually">Semi-Anually</option>
                          <option value="anually">Anually</option>
                        </SelectField>
                      </FormRowItem>
                    </FormRow>

                    <div>
                      <DOBField
                        formik={formik}
                        name="mortgageRenewalDate"
                        label="Mortgage Renewal Date"
                      />
                    </div>

                    <p className="text-sm text-secondary">
                      Asset management currently in development.
                    </p>
                  </div>

                  <SubmitButton
                    formik={formik}
                    text="Save"
                    submittingText="Saving"
                    className={`!rounded-full ${iconBgColor} border-none mt-6 !text-black`}
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default AssetInformationAction;
