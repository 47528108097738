import {useCallback, useImperativeHandle} from 'react';

import * as Sentry from '@sentry/react';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';

const RemoveBillingMethodConfirmationModal: ModalDefinition = {
  title: 'Are you sure you want to remove this billing method?',
  buttonsConfig: {
    cancel: {
      label: 'No, keep billing method',
    },
    actions: [
      {
        id: 'confirm',
        label: 'Yes, remove billing method',
        handle: 'onConfirm',
      },
    ],
  },
  ContentComponent: ({billingMethod}, ref) => {
    const queryClient = useQueryClient();

    /**
     * Delete the billing method upon confirmation.
     */
    const onConfirm = useCallback(async () => {
      try {
        console.log('billingMethod', billingMethod);

        /**
         * Destory the billing method instance.
         */
        await billingMethod.destroy();
        /**
         * Invalidate the query so that the billing methods list is updated.
         */
        queryClient.invalidateQueries('fetch-user-billing-methods');
        /**
         * Notify the user that the billing method has been removed.
         */
        toast.success('Billing method successfully removed!');
      } catch (error) {
        /**
         * Log the error in Sentry.
         */
        Sentry.withScope((scope) => {
          scope.setTag('action', 'remove_billing_method');
          Sentry.captureException(error);
        });
        /**
         * Notify the user of the error.
         */
        toast.error('There was an issue removing this billing method.');
        /**
         * Throw the error to prevent the modal from automatically closing.
         */
        throw error;
      }
    }, [billingMethod, queryClient]);

    useImperativeHandle(ref, () => ({
      onConfirm,
    }));

    return (
      <>
        <Paragraph>
          You will need to set a new billing method on any properties that are
          currently using this one to avoid interruption using Keyhook.
        </Paragraph>
        <Paragraph disableMargin>
          This action can only be reversed by manually adding your card again.
        </Paragraph>
      </>
    );
  },
};

export default RemoveBillingMethodConfirmationModal;
