import React, {FunctionComponent} from 'react';

import clsx from 'clsx';
import {useFormik} from 'formik';
import _ from 'lodash';

import {SelectField} from 'components/forms_fields';
import {TextAreaField, TextField} from 'components_sb/input';
import {Paragraph} from 'components_sb/typography';
import {useAutoAnimate} from 'utilities/hooks';

type FormTenancyRequest = {
  renterEmail: string;
};

interface AddTenantsSectionProps {
  formik: ReturnType<typeof useFormik>;
  maxTenants: number;
}

const AddTenantsSection: FunctionComponent<AddTenantsSectionProps> = ({
  formik,
  maxTenants,
}) => {
  const [tenantAnimateRef] = useAutoAnimate();

  const requests = formik.values.tenancyRequests as FormTenancyRequest[];

  const handleSelectChange = (val: number) => {
    if (val < requests.length) {
      const diff = requests.length - val;
      _.times(diff, () => {
        requests.pop();
      });
    } else if (val > requests.length) {
      const diff = val - requests.length;
      _.times(diff, () => {
        requests.push({renterEmail: ''});
      });
    }

    formik.setFieldValue('tenancyRequests', requests);
  };

  return (
    <>
      <SelectField
        formik={formik}
        name="numberOfTenants"
        labelProps={{
          title: 'Number of tenants',
          size: 'xl',
          helpText:
            'Currently we only allow a maximum of 6 tenants for residential leases and 2 tenants for boarding house leases due to limitations from Tenancy Services. This should be the number of adults who will be signing the lease.',
        }}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const num = parseInt(e.target.value);

          formik.setFieldValue('numberOfTenants', num);
          handleSelectChange(num);
        }}>
        {[...Array(maxTenants).keys()].map((index) => (
          <option key={index} value={index + 1}>
            {index + 1}
          </option>
        ))}
      </SelectField>

      <div className="flex flex-col">
        <Paragraph>
          Specifying a tenants name is optional. If you provide a name, this
          will be used on lease and bond paperwork. Otherwise the name the
          tenant signs up with will be used.
        </Paragraph>

        {requests.length > 1 && (
          <Paragraph>
            The head tenant and will be responsible for inputting the portion of
            the rent / bond each tenant pays and for signing off on inspections.
          </Paragraph>
        )}
      </div>

      <div
        ref={tenantAnimateRef as any}
        className="flex w-full flex-col gap-y-6">
        {requests.map((req, index) => (
          <div
            className={clsx(
              'flex flex-row w-full gap-x-4 gap-y-6',
              'flex-col md:flex-row',
            )}
            key={index}>
            {index === 0 ? (
              <>
                <TextField
                  form={formik}
                  mode="formik"
                  name={'tenancyRequests[0].name'}
                  label={
                    requests.length === 1 ? `Tenant name` : `Head tenant name`
                  }
                  labelSize="xl"
                  placeholder="E.g. John Doe"
                />
                <TextField
                  form={formik}
                  required
                  mode="formik"
                  name={'tenancyRequests[0].renterEmail'}
                  label={
                    requests.length === 1 ? `Tenant email` : `Head tenant email`
                  }
                  labelSize="xl"
                  placeholder="E.g. name@email.com"
                  type="email"
                />
              </>
            ) : (
              <>
                <TextField
                  form={formik}
                  mode="formik"
                  name={`tenancyRequests[${index}].name`}
                  label={`Tenant ${index + 1} Name`}
                  labelSize="xl"
                  placeholder="E.g. John Doe"
                />
                <TextField
                  form={formik}
                  required
                  mode="formik"
                  name={`tenancyRequests[${index}].renterEmail`}
                  label={`Tenant ${index + 1} email`}
                  labelSize="xl"
                  placeholder="E.g. name@email.com"
                  type="email"
                />
              </>
            )}
          </div>
        ))}
      </div>

      <div className="flex w-full">
        <TextAreaField
          form={formik}
          mode="formik"
          name="messageFromLandlord"
          label="Add a message (optional)"
          labelSize="xl"
          placeholder="Include a welcome message for your tenants."
        />
      </div>
    </>
  );
};

export default AddTenantsSection;
