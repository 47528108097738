import {Model, Attr, HasOne, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import BillingMethod from 'models/billing/BillingMethod';
import OpenHome from 'models/listings/OpenHome';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import LandlordProfile from 'models/users/LandlordProfile';
import RenterProfile from 'models/users/RenterProfile';

interface NotificationOption {
  name: string;
  push: boolean;
  email: boolean;
}

export type NotificationOptions = Record<
  string,
  Record<string, NotificationOption>
>;

export interface UserInterfaceFlags {
  [key: string]: boolean;
}

@Model()
class User extends ApplicationRecord {
  static jsonapiType = 'users';

  @Attr() name: string;
  @Attr() email: string;

  @Attr() avatar: string;

  // If email is confirmed
  @Attr({persist: false}) confirmed: boolean;

  @Attr() notificationOptions: NotificationOptions;

  @Attr() userInterfaceFlags: UserInterfaceFlags;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @HasOne('landlord_profiles') landlordProfile: LandlordProfile;
  @HasOne('renter_profiles') renterProfile: RenterProfile;

  @HasMany('billing_methods') billingMethods: BillingMethod[];
  @HasMany('open_home_attendees') openHomeAttendees: OpenHomeAttendee[];
  @HasMany('open_homes') openHomes: OpenHome[];

  avatarOrPlaceholder = (size = 64) => {
    return this.avatar?.length
      ? this.avatar
      : `https://ui-avatars.com/api/?name=${this.name}&background=232A59&color=fff&rounded=true&size=${size}`;
  };
}

export default User;
