import TenancySelectField from 'components/tenancy/TenancySelectField';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

const TenancySelectCard = ({
  property,
  tenancy,
  setSelectedTenancyId,
}: {
  property: Property;
  tenancy?: Tenancy;
  setSelectedTenancyId: (id: string) => void;
}) => {
  if (property && property.tenanciesCount > 1) {
    return (
      <Card title="Choose a Tenancy">
        <Paragraph>
          Use the select field below to change between tenancies for this
          property.
        </Paragraph>

        <TenancySelectField
          property={property}
          tenancies={property.tenancies}
          selectedTenancy={tenancy}
          setSelectedTenancyId={setSelectedTenancyId}
        />
      </Card>
    );
  }

  return null;
};

export default TenancySelectCard;
