import {useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';

import {SpinningLoader} from 'components_sb/feedback';
import {Divider, ModalDefinition} from 'components_sb/layout';
import InspectionItem from 'models/inspections/InspectionItem';
import {useInspectionUploader} from 'providers/InspectionUploader';
import useInspectionStore, {
  InspectionEventEmitter,
  PendingUploadRow,
} from 'stores/InspectionStore';

interface StateItem {
  item: InspectionItem;
  row: PendingUploadRow;
}

const PendingUploadModal: ModalDefinition = {
  title: 'Pending Uploads',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [],
  },
  ContentComponent: () => {
    const [pendingUploads, setPendingUploads] = useState<StateItem[]>([]);
    const [uploadingIds, setUploadingIds] = useState(new Set());
    const [inspection, inspectionItems, db] = useInspectionStore((state) => [
      state.inspection,
      state.inspectionItems,
      state.database,
    ]);

    const [requeryKey, setRequeryKey] = useState('123');

    const {processItem} = useInspectionUploader();

    useEffect(() => {
      const updateRows = async () => {
        await db.createIndex({
          index: {
            fields: ['inspectionId'],
          },
        });

        const records = await db.find({
          selector: {
            inspectionId: inspection.id,
          },
        });

        const newDocs = records.docs.map((doc) => {
          const row = doc as any as PendingUploadRow;
          const item = inspectionItems.find(
            (item) => item.id === row.inspectionItemId,
          );

          return {
            row: row,
            item: item,
          };
        });

        setPendingUploads([...newDocs]);
      };

      updateRows();
    }, [inspection, inspectionItems, requeryKey]);

    const removeDoc = async (rowId: string) => {
      await db.createIndex({
        index: {
          fields: ['inspectionId'],
        },
      });

      const records = await db.find({
        selector: {
          _id: rowId,
        },
      });

      for (const record of records.docs) {
        await db.remove(record);
      }

      setPendingUploads(
        pendingUploads.filter((item) => item.row._id !== rowId),
      );
    };

    const uploadItem = async (rowId: string) => {
      await db.createIndex({
        index: {
          fields: ['inspectionId'],
        },
      });

      const records = await db.find({
        selector: {
          _id: rowId,
        },
      });

      for (const record of records.docs) {
        setUploadingIds((prev) => new Set(prev.add(record._id)));
        await processItem(db, record);
        setRequeryKey(Math.random().toString());

        InspectionEventEmitter.emit('INSPECTION_UPDATED', {
          inspectionId: inspection.id,
        });
      }
    };

    return (
      <div className="max-h-[50vh] overflow-y-scroll">
        {pendingUploads.map((stateItem) => (
          <div key={stateItem.row._id}>
            <div className="flex justify-start items-center gap-4 mb-4">
              <div>
                <img
                  src={Capacitor.convertFileSrc(stateItem.row.data)}
                  className="w-16 h-16 bg-gray-100 object-cover rounded-md aspect-square"
                />
              </div>
              <div>
                <strong>{stateItem.item.name}</strong>
                <p className="text-gray-500">{stateItem.item.room}</p>
                <p className="text-gray-500 text-xs">
                  {stateItem.row.data && stateItem.row.data.split('/').pop()}
                </p>

                <div className="mt-2 flex justify-start items-center gap-4">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => uploadItem(stateItem.row._id)}>
                    Upload
                  </button>

                  <button
                    className="btn btn-error btn-sm"
                    onClick={() => removeDoc(stateItem.row._id)}>
                    Delete
                  </button>
                </div>
              </div>
              <div>
                {uploadingIds.has(stateItem.row._id) && (
                  <SpinningLoader size="xs" color="brand" />
                )}
              </div>
            </div>
            <Divider />
          </div>
        ))}

        {pendingUploads.length === 0 && (
          <div>
            <p className="text-gray-500">
              All photos/videos have been uploaded.
            </p>
          </div>
        )}
      </div>
    );
  },
};

export default PendingUploadModal;
