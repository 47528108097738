import {useMemo} from 'react';

import {HiOutlineSearch} from 'react-icons/hi';
import {useNavigate} from 'react-router';

import {Card} from 'components_sb/layout';
import InspectionsTable from 'components/inspection/InspectionsTable';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Tenancy from 'models/properties/Tenancy';
import {Action} from 'types/actions';

const InspectionsCard = ({tenancy}: {tenancy?: Tenancy}) => {
  const navigate = useNavigate();

  const showInspection = (inspectionId: string) => {
    navigate(`inspections/${inspectionId}`);
  };

  const {activeAccountRole} = useLocalUserSettings();

  const showNewButton = useMemo(() => {
    if (
      activeAccountRole === 'Landlord' &&
      tenancy &&
      !tenancy.isNew &&
      tenancy.inspections.length === 0
    ) {
      return true;
    }
    return false;
  }, [tenancy, activeAccountRole]);

  /**
   * Actions that are applicable to and accessible
   * from within the card.
   */
  const cardActions = useMemo<Action[]>(
    () =>
      !showNewButton
        ? null
        : [
            {
              label: 'New inspection',
              linkTo: 'inspections/new',
            },
          ],
    [showNewButton],
  );

  return (
    <Card icon={HiOutlineSearch} title="Inspections" actions={cardActions}>
      {tenancy && tenancy.inspections.length > 0 ? (
        <InspectionsTable
          inspections={tenancy.inspections}
          clickHandler={showInspection}
          showCount={true}
        />
      ) : (
        <EmptyContentPlaceholder
          type="inspections"
          message="There are currently no scheduled inspections for this property"
        />
      )}
    </Card>
  );
};

export default InspectionsCard;
