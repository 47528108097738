import {
  ChangeEvent,
  ChangeEventHandler,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';

import BaseSlider from '../shared/BaseSlider';
import SliderTextInput from '../shared/SliderTextInput';

type InputFieldValues = [number | string, number | string];

type RangeValue = [number, number];

interface RangerSliderProps {
  name: string;
  min: number;
  max: number;
  step: number;
  unitPrefix?: string;
  value: RangeValue;
  onChange: (value: RangeValue) => void;
}

const RangeSlider: FunctionComponent<RangerSliderProps> = ({
  name,
  min,
  max,
  step,
  value,
  onChange,
  unitPrefix,
}) => {
  const [inputFieldValues, setInputFieldValues] =
    useState<InputFieldValues>(value);

  useEffect(() => {
    setInputFieldValues(value);
  }, [value]);

  const onChangeTextInput = useCallback(
    ({
      event,
      valueIndex,
    }: {
      event: ChangeEvent<HTMLInputElement>;
      valueIndex: 0 | 1;
    }) => {
      /**
       * The value entered cannot be greater in length (number of chars) than
       * the maximum value.
       */
      if (event.target.value.length > max.toString().length) {
        return;
      }

      const newInputFieldValues = [...inputFieldValues] as InputFieldValues;
      newInputFieldValues[valueIndex] = event.target.value;
      setInputFieldValues(newInputFieldValues);

      const asInt = parseInt(event.target.value, 10);
      if (isNaN(asInt)) {
        return;
      }

      const newValue = [...value] as RangeValue;
      newValue[valueIndex] = asInt;
      onChange(newValue);
    },
    [inputFieldValues, onChange, value, max],
  );

  const onChangeMin = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChangeTextInput({event, valueIndex: 0});
    },
    [onChangeTextInput],
  );

  const onChangeMax = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChangeTextInput({event, valueIndex: 1});
    },
    [onChangeTextInput],
  );

  const minTextInput = useMemo(
    () => (
      <SliderTextInput
        name={`${name}-min`}
        label="Min"
        value={inputFieldValues[0]}
        onChange={onChangeMin}
        max={max}
        unitPrefix={unitPrefix}
      />
    ),
    [name, inputFieldValues, onChangeMin, max, unitPrefix],
  );

  const maxTextInput = useMemo(
    () => (
      <SliderTextInput
        name={`${name}-max`}
        label="Max"
        value={inputFieldValues[1]}
        onChange={onChangeMax}
        max={max}
        unitPrefix={unitPrefix}
      />
    ),
    [name, inputFieldValues, onChangeMax, max, unitPrefix],
  );

  return (
    <div className="@container flex flex-col gap-y-2 w-full">
      <div
        className={clsx(
          'w-full flex flex-row gap-x-4 items-center text-brand-850 mt-4 @xs:mt-0',
        )}>
        <span className="hidden @xs:flex">{minTextInput}</span>
        <BaseSlider
          mode="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
        />
        <span className="hidden @xs:flex">{maxTextInput}</span>
      </div>
      <div className="flex @xs:hidden flex-row justify-between">
        {minTextInput}
        {maxTextInput}
      </div>
    </div>
  );
};

export default RangeSlider;
