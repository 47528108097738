import {HTMLProps, useState} from 'react';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import clsx from 'clsx';
import {type FormikProps} from 'formik';
import {omit} from 'lodash';
import Autosuggest from 'react-autosuggest';

import {errorsFor} from 'components/forms_fields/Helpers';
import {InlineError} from 'components_sb/feedback';
import Input from 'components_sb/primitives/Input/Input';
import {FieldLabel} from 'components_sb/typography';
import {FieldLabelProps} from 'components_sb/typography/FieldLabel/FieldLabel';

import './AutocompleteField.css';

interface AutocompleteFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  name: string;
  filter: (str: string) => Promise<any>;
  select: (option: any) => Promise<any[]>;
  labelProps?: FieldLabelProps;
}

const AutocompleteField = ({
  formik,
  name,
  filter,
  select,
  labelProps,
  ...rest
}: AutocompleteFieldProps) => {
  const [items, setItems] = useState([]);
  const [searchQuery] = useState(() => AwesomeDebouncePromise(filter, 300));

  const [query, setQuery] = useState('');

  const queryForSuggestions = () => {
    if (query.length > 0) {
      searchQuery(query)
        .then((newItems) => {
          setItems(newItems);
        })
        .catch();
    }
  };

  const clearItems = () => {
    setItems([]);
  };

  const getSuggestionValue = (val: any) => {
    return val.text;
  };

  const onChange = (event: any, {newValue}: any) => {
    formik.setFieldValue(name, newValue);
    setQuery(newValue.toString());
  };

  const onSelect = (event: any, {suggestion}: any) => {
    formik.setFieldValue(name, suggestion.text);
    if (select) {
      select(suggestion);
    }
  };

  const renderSuggestion = (suggestion: any) => {
    return <span>{suggestion.text}</span>;
  };

  const renderInput = (inputProps: any) => (
    <Input
      autoComplete="off"
      disabled={formik.isSubmitting}
      {...omit(inputProps, ['className'])}
    />
  );

  const inputProps = {
    placeholder: rest.placeholder,
    value: formik.values[name],
    onChange: onChange,
    className: rest.className || '',
  };

  const errors = errorsFor(formik, name);

  return (
    <div className="w-full flex flex-col">
      {labelProps && <FieldLabel {...labelProps} />}
      <Autosuggest
        suggestions={items}
        onSuggestionsFetchRequested={queryForSuggestions}
        onSuggestionsClearRequested={clearItems}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSelect}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInput}
      />
      {errors && <InlineError error={errors} />}
    </div>
  );
};

export default AutocompleteField;
