import {
  FunctionComponent,
  ReactElement,
  ReactFragment,
  createElement,
  isValidElement,
} from 'react';

export interface PseudoHeadItemProps {
  children: string;
  helpText?: string;
  minWidth?: number;
}

export type PseudoHeadItemElement = ReactElement<PseudoHeadItemProps>;

export type PseudoHeadItemInputElement = PseudoHeadItemElement | ReactFragment;

export type PseudoHeadItemComponent = FunctionComponent<PseudoHeadItemProps>;

const PseudoHeadItem: PseudoHeadItemComponent = () => <></>;

export default PseudoHeadItem;

/**
 * Input element type guard.
 */
export const elementIsHeadItemElement = (
  element: unknown,
): element is PseudoHeadItemElement => {
  return (
    isValidElement(element) &&
    element.type === createElement(PseudoHeadItem).type
  );
};
