import {Model, Attr, BelongsTo} from 'spraypaint';

import AccountingRecord from 'models/accounting/AccountingRecord';
import ApplicationRecord from 'models/ApplicationRecord';

@Model()
class AccountingReceipt extends ApplicationRecord {
  static jsonapiType = 'accounting_receipts';

  @Attr() attachment: string;
  @Attr({persist: false}) attachmentMimeType: string | null;

  @Attr({persist: false}) metadata: Record<string, any>;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() accountingRecordId: string;

  @BelongsTo('accounting_records') accountingRecord: AccountingRecord;
}

export default AccountingReceipt;
