import React from 'react';

import {type FormikHelpers} from 'formik';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';

import {Card} from 'components_sb/layout';
import LoadingView from 'components/common/LoadingView';
import RenterProfileForm from 'components/forms/RenterProfileForm';
import PageWrapper from 'components/PageWrapper';
import RenterProfile from 'models/users/RenterProfile';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';

const SettingsRenterProfilePage = () => {
  const {currentUser} = useAuth();

  const {isLoading, error, data} = useQuery(
    'user-renter-profile-page',
    async () => {
      const user = await User.includes(['renter_profile']).find(currentUser.id);

      if (!user.data.renterProfile) {
        user.data.renterProfile = new RenterProfile({
          userId: currentUser.id,
        });
      }

      return user.data;
    },
  );

  const saveRenterProfile = async (
    formData: any,
    actions: FormikHelpers<any>,
  ) => {
    const profile = data.landlordProfile;
    profile.assignAttributes(formData);
    const result = await profile.save();

    if (result) {
      toast.success('Your profile has been successfully updated!');
    } else {
      for (const key of Object.keys(profile.errors)) {
        const message = profile.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Renter Profile">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Renter Profile">
        <Card title="Your Renter Profile" className="mt-2">
          <RenterProfileForm
            model={data.renterProfile}
            submitHandler={saveRenterProfile}
          />
        </Card>
      </PageWrapper>
    );
  }
};

export default SettingsRenterProfilePage;
