import {HTMLAttributes, ReactNode} from 'react';

import {IconType} from '@react-icons/all-files';
import clsx from 'clsx';

import {ResponsiveActionSelect} from 'components_sb/buttons';
import {Title} from 'components_sb/typography';
import Tags from 'components_sb/typography/Tags/Tags';
import {Action} from 'types/actions';

export interface CardBannerConfig {
  color: 'gray' | 'amber' | 'green' | 'red';
  text: string;
}

type CardProps = Omit<HTMLAttributes<HTMLElement>, 'title' | 'icon'> & {
  icon?: IconType; // TODO: Only permit icon if a title is provided
  title?: string | ReactNode; // TODO: Change components that pass a ReactNode so that only strings are permitted
  subtitle?: string;
  tags?: string[];
  actions?: Action[];
  style?: 'normal' | 'premium' | 'success';
  banner?: CardBannerConfig;
};

const Card = ({
  icon,
  title,
  subtitle,
  tags,
  actions,
  style = 'normal',
  children,
  className,
  banner,
  ...props
}: CardProps) => (
  <div
    className={clsx(
      'relative',
      'flex flex-col',
      'mb-6',
      'scroll-m-6',
      'bg-white',
      'rounded-xl',
      'text-brand-850',
      !!banner && 'pt-6',
      style === 'normal' && 'border-2 border-brand-50',
      style === 'premium' && 'border-3 border-amber-400',
      style === 'success' && 'border-3 border-green-500',
      className,
    )}
    {...props}>
    {/* Banner */}
    {!!banner && (
      <div
        className={clsx(
          'rounded-t-xl',
          'absolute top-0 left-0 right-0 h-6',
          'text-center',
          'py-1 px-4',
          'text-xs',
          'text-white',
          banner.color === 'gray' && 'bg-gray-400 ',
          banner.color === 'amber' && 'bg-amber-500 x',
          banner.color === 'green' && 'bg-green-500',
          banner.color === 'red' && 'bg-red-500',
        )}>
        {banner.text}
      </div>
    )}

    {/* Card body */}
    <div className="flex-1 flex flex-col p-4 md:p-6 gap-y-2">
      {/* Card header */}
      {(title || actions || tags) && (
        <div className="flex flex-row flex-1 justify-between gap-x-6 gap-y-2 items-start">
          {(title || tags) && (
            <div>
              {/* Tags */}
              {tags && tags.length && (
                <div className="mb-2">
                  <Tags>{tags}</Tags>
                </div>
              )}
              {/* Title */}
              {title && (
                <div className="mb-2 lg:mb-4">
                  {title && (
                    <Title
                      icon={icon}
                      subtitle={subtitle}
                      level="h2"
                      size="md"
                      disableMargin
                      color={style === 'success' ? 'success' : 'dark'}>
                      {title}
                    </Title>
                  )}
                </div>
              )}
            </div>
          )}
          {!!actions && (
            <ResponsiveActionSelect actions={actions} justify="end" />
          )}
        </div>
      )}

      {/* Card content */}
      {children}
    </div>
  </div>
);

export default Card;
