import {ReactNode, createElement, useMemo} from 'react';

import {IconType} from '@react-icons/all-files';
import clsx from 'clsx';

import Paragraph from '../Paragraph/Paragraph';

const classes = {
  // Classes based on size
  size: {
    xs: {
      base: clsx('font-medium', 'text-base'),
      margin: 'mb-1',
      subtitleGap: 'gap-y-1',
    },
    sm: {
      base: clsx('font-semibold', 'text-lg sm:text-xl'),
      margin: 'mb-2 xs:mb-3',
      subtitleGap: 'gap-y-1',
    },
    md: {
      base: clsx('font-semibold', 'text-xl lg:text-2xl'),
      margin: 'mb-3 xs:mb-4',
      subtitleGap: 'gap-y-3',
    },
    lg: {
      base: clsx('font-bold', 'text-xl sm:text-2xl lg:text-3xl'),
      margin: 'mb-3 xs:mb-4',
      subtitleGap: 'gap-y-4',
    },
    xl: {
      base: clsx('font-bold', 'text-3xl lg:text-4xl'),
      margin: 'mb-4 xs:mb-5',
      subtitleGap: 'gap-y-6',
    },
    '2xl': {
      base: clsx('font-bold', 'text-4xl lg:text-5xl'),
      margin: 'mb-4 xs:mb-5',
      subtitleGap: 'gap-y-6',
    },
  },
  // Classes based on color
  color: {
    brand: 'text-brand-500',
    light: 'text-white',
    dark: 'text-brand-850',
    success: 'text-green-600',
  },
};

interface TitleProps {
  /**
   *  The text shown as the link.
   */
  children: string | ReactNode; // TODO: Refactor components to only allow a string
  /**
   *  The color of the title.
   */
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /**
   * Override the base title sizes with a custom scale.
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  /**
   *  The color of the title.
   */
  color?: 'brand' | 'light' | 'dark' | 'success';
  /**
   * Supporting text to append below the title.
   */
  subtitle?: string;
  /**
   * An icon to display to the left of the title.
   */
  icon?: IconType;
  /**
   * Disables the standard title margin.
   */
  disableMargin?: boolean;
}

/**
 *  A heading for use at the start of a page or section.
 */
const Title = ({
  children,
  level,
  size = 'lg',
  color = 'dark',
  subtitle,
  icon: Icon,
  disableMargin = false,
}: TitleProps) => {
  const hasSubtitle = useMemo(() => !!subtitle, [subtitle]);
  const hasIcon = useMemo(() => !!Icon, [Icon]);

  const titleElement = useMemo(() => {
    const element = createElement(
      level,
      {
        className: clsx(classes.size[size].base, classes.color[color]),
      },
      children,
    );
    return hasIcon ? (
      <div className="flex flex-row gap-x-3 items-center">
        <Icon
          className={clsx(
            'w-7 h-7 flex-shrink-0',
            color === 'brand' && 'text-brand-850',
            color === 'light' && 'text-white',
            color === 'dark' && 'text-brand-500',
            color === 'success' && 'text-green-600',
          )}
        />
        {element}
      </div>
    ) : (
      element
    );
  }, [children, hasIcon, Icon, level, size, color]);

  return (
    <div
      className={clsx(
        'flex flex-col',
        classes.size[size].subtitleGap,
        !disableMargin && classes.size[size].margin,
      )}>
      {titleElement}
      {hasSubtitle && (
        <Paragraph disableMargin secondary size="base">
          {subtitle}
        </Paragraph>
      )}
    </div>
  );
};

export default Title;
