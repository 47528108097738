import {
  FacebookLogin,
  type FacebookLoginResponse,
} from '@capacitor-community/facebook-login';
import {Capacitor} from '@capacitor/core';

import {ProviderAuthFunction} from '../authenticate';

/**
 * The object that is passed on the onSuccess function.
 */
export interface FacebookUserData {
  data: {
    token: string;
  };
}

let auth: ProviderAuthFunction;

if (Capacitor.isNativePlatform()) {
  /**
   * Authenticate a user via Facebook.
   */
  auth = async (onSuccess, onError) => {
    /**
     * Initialise the Facebook SDK.
     */
    FacebookLogin.initialize({
      appId: '734742177607868',
    });

    /**
     * Handle the response from authentication and pass releveant
     * data to the callback function if successful, otherwise invoke
     * the error callback function.
     */
    const handleResponse = (response: FacebookLoginResponse) => {
      if (response.accessToken && response.accessToken.token) {
        onSuccess({
          data: {
            token: response.accessToken.token,
          },
        });
      } else {
        onError();
      }
    };

    const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];
    const result = await FacebookLogin.login({
      permissions: FACEBOOK_PERMISSIONS,
    });

    handleResponse(result);
  };
} else {
  /**
   * Authenticate a user via Facebook.
   */
  auth = (onSuccess, onError) => {
    /**
     * Ensure the Facebook SDK script has been loaded.
     */
    if (!(<any>window).FB) {
      onError();
      return;
    }

    const {FB} = <any>window;

    /**
     * Initialise the Facebook SDK.
     */
    FB.init({
      appId: '734742177607868',
      cookie: true, // Enable cookies to allow the server to access the session.
      xfbml: true, // Parse social plugins on this webpage.
      version: 'v2.7', // Use this Graph API version for this call.
    });

    /**
     * Handle the response from authentication and pass releveant
     * data to the callback function if successful, otherwise invoke
     * the error callback function.
     */
    const handleResponse = (response: any) => {
      if (response.authResponse && response.authResponse.accessToken) {
        onSuccess({
          data: {
            token: response.authResponse.accessToken,
          },
        });
      } else {
        onError();
      }
    };

    /**
     * Trigger the authentication flow.
     */
    FB.login(handleResponse, {scope: 'public_profile,email'});
  };
}

export const authenticate = auth;
