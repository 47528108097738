import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Inspection from 'models/inspections/Inspection';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';

@Model()
class InspectionItem extends ApplicationRecord {
  static jsonapiType = 'inspection_items';

  @Attr() name: string;
  @Attr() notes: string | null;
  @Attr() room: string;

  @Attr() orderIndex: number;
  @Attr() passed: boolean | null;

  @Attr({persist: false}) isToggleable: boolean;
  @Attr() toggleState: boolean;

  // Used for completed inspection list
  @Attr({persist: false}) isExpanded: boolean;

  @Attr() inspectionId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('inspections') inspection: Inspection;
  @HasMany('inspection_item_attachments')
  inspectionItemAttachments: InspectionItemAttachment[];
  @HasMany('inspection_actions') inspectionActions: InspectionAction[];
}

export default InspectionItem;
