import {CurrencyDollarIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import {Paragraph} from 'components_sb/typography';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import LandlordPayment from 'models/payments/LandlordPayment';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const LandlordPaymentDetailPage = () => {
  const {id} = useParams();

  useTitle('Payment Information');
  usePageVisit('LandlordPaymentDetailPage');

  const isMobile = !useTailwindBreakpoint('sm');

  const {data, isLoading, error} = useQuery(
    `landlord-payment-detail-${id}`,
    async () => {
      const lp = await LandlordPayment.includes({
        tenancy: 'property',
        landlord_payment_expenses: 'expense',
        rent_payments: {tenancy_membership: 'renter'},
      })
        .select({
          tenancy: ['id'],
          property: ['street_address'],
        })
        .find(id);

      return lp.data;
    },
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Payment Information" backEnabled>
        <LoadingView />
      </PageWrapper>
    );
  } else if (isMobile) {
    return (
      <PageWrapper title="Payment Information" backEnabled>
        <Card title={moment(data.paymentDate).format(DATE_FORMAT)}>
          <span>{titleize(removeUnderscores(data.status))}</span>
          <p className="text-secondary">
            {data.tenancy.property.streetAddress}
          </p>

          <div className="mt-2">
            <strong className="block mb-2">Payment Information</strong>

            <div className="flex flex-col space-y-2">
              <div className="flex justify-start items-center bg-white rounded-full">
                <div className="bg-primary rounded-full w-8 h-8 flex justify-center items-center">
                  <CurrencyDollarIcon className="w-5 h-5 text-white" />
                </div>
                <div className="flex flex-col ml-2 pt-1">
                  <span className="text-gray-500" style={{fontSize: 10}}>
                    Gross
                  </span>
                  <h3 className="text-md font-semibold">
                    {toCurrency(data.grossAmount)}
                  </h3>
                </div>
              </div>

              <div className="flex justify-start items-center bg-white rounded-full">
                <div className="bg-warning rounded-full w-8 h-8 flex justify-center items-center">
                  <CurrencyDollarIcon className="w-5 h-5 text-white" />
                </div>
                <div className="flex flex-col ml-2 pt-1">
                  <span className="text-gray-500" style={{fontSize: 10}}>
                    Expenses
                  </span>
                  <h3 className="text-md font-semibold">
                    {toCurrency(data.expensesAmount)}
                  </h3>
                </div>
              </div>

              <div className="flex justify-start items-center bg-white rounded-full">
                <div className="bg-success rounded-full w-8 h-8 flex justify-center items-center">
                  <CurrencyDollarIcon className="w-5 h-5 text-white" />
                </div>
                <div className="flex flex-col ml-2 pt-1">
                  <span className="text-gray-500" style={{fontSize: 10}}>
                    Net
                  </span>
                  <h3 className="text-md font-semibold">
                    {toCurrency(data.netAmount)}
                  </h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              {toCurrency(data.amountOfRentPaid || 0)} has been collected from
              your tenants and has been cleared.{' '}
              {toCurrency(data.pendingAmount) || 0} has been paid but is pending
              bank clearance.
            </div>

            {data.status !== 'paid' && (
              <p className="mt-2">
                Once the entire amount has cleared our bank account, it will
                immediately be sent to you.
              </p>
            )}
          </div>

          {data.status === 'paid' && (
            <div className="mt-8">
              <p className="text-secondary">
                This payment was sent to you on{' '}
                {moment(data?.sentAt).format(DATE_TIME_FORMAT)}, it may take 1-2
                business days for this payment to reach your account. The
                reference code used was {data.reference}.
              </p>
            </div>
          )}

          <div className="mt-4">
            <strong className="block mb-2">Expenses</strong>

            {data.landlordPaymentExpenses.length === 0 ? (
              <p>There were no expenses deducted from this payment.</p>
            ) : (
              <ul className="flex flex-col">
                {data.landlordPaymentExpenses.map((item) => (
                  <MobileListItem
                    key={item.id}
                    onClick={() => {
                      'noop';
                    }}
                    title={item.expense.description}
                    icon={CurrencyDollarIcon}
                    showArrowIcon={false}
                    subtitle={
                      <>
                        <span>
                          Amount Owed: {toCurrency(item.expense.amountOwed)}
                        </span>
                        <br />
                        <span>
                          Amount Paid (This payment): {toCurrency(item.amount)}
                        </span>
                        <br />
                        <span>
                          Amount Remaining:{' '}
                          {toCurrency(
                            item.expense.amountOwed - item.expense.amountPaid,
                          )}
                        </span>
                      </>
                    }
                  />
                ))}
              </ul>
            )}
          </div>

          <div className="mt-4">
            <strong className="block mb-2">Rent Payments</strong>
            <ul className="flex flex-col">
              {data.rentPayments.map((rp) => (
                <MobileListItem
                  key={rp.id}
                  onClick={() => {
                    'noop';
                  }}
                  title={rp.tenancyMembership.renter.name}
                  icon={CurrencyDollarIcon}
                  showArrowIcon={false}
                  subtitle={
                    <>
                      <span>Amount Owed: {toCurrency(rp.amountDue)}</span>
                      <br />
                      <span>Amount Paid: {toCurrency(rp.amountPaid)}</span>
                      <br />
                      <span>
                        Amount Pending: {toCurrency(rp.amountPendingClearance)}
                      </span>
                      <br />
                      <span>
                        Received On:{' '}
                        {rp.receivedAt && rp.receivedAt.length > 0
                          ? moment(rp.receivedAt).format(DATE_TIME_FORMAT)
                          : 'N/A'}
                      </span>
                    </>
                  }
                />
              ))}
            </ul>
          </div>
        </Card>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Payment Information" backEnabled>
        <Card
          title={`${moment(data.paymentDate).format(DATE_FORMAT)} - ${titleize(
            removeUnderscores(data.status),
          )}`}>
          <Paragraph secondary>{data.tenancy.property.streetAddress}</Paragraph>

          <div className="mt-2">
            <strong className="block mb-2">Payment Information</strong>
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead className="thead-light">
                  <tr>
                    <th>Gross</th>
                    <th>Expenses</th>
                    <th>Received</th>
                    <th>Pending</th>
                    <th>Net</th>
                    <th>Reference</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{toCurrency(data.grossAmount)}</td>
                    <td>{toCurrency(data.expensesAmount)}</td>
                    <td>{toCurrency(data.amountOfRentPaid || 0)}</td>
                    <td>{toCurrency(data.pendingAmount || 0)}</td>
                    <td className="text-success">
                      {toCurrency(data.netAmount)}
                    </td>
                    <td>{data.reference}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {data.status === 'paid' && (
            <div className="mt-8">
              <p className="text-secondary">
                This payment was sent to you on{' '}
                {moment(data?.sentAt).format(DATE_TIME_FORMAT)}, it may take 1-2
                business days for this payment to reach your account.
              </p>
            </div>
          )}

          <div className="mt-8">
            <strong className="block mb-2">Expense Summary</strong>
            {data?.landlordPaymentExpenses.length === 0 ? (
              <p>There were no expenses included in this payment</p>
            ) : (
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead className="thead-light">
                    <tr>
                      <th>Description</th>
                      <th>Amount Owed</th>
                      <th>Amount Paid (This Payment)</th>
                      <th>Amount Remaining</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.landlordPaymentExpenses.map((item) => (
                      <tr key={item.id} className="hover">
                        <td>{item.expense.description}</td>
                        <td>{toCurrency(item.expense.amountOwed)}</td>
                        <td>{toCurrency(item.amount)}</td>
                        <td>
                          {toCurrency(
                            item.expense.amountOwed - item.expense.amountPaid,
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="mt-8">
            <strong className="block mb-2">Rent Payments</strong>
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead className="thead-light">
                  <tr>
                    <th>Tenant</th>
                    <th>Amount Owed</th>
                    <th>Amount Paid</th>
                    <th>Amount Pending</th>
                    <th>Received On</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.rentPayments.map((rp) => (
                    <tr key={rp.id} className="hover">
                      <td>{rp.tenancyMembership.renter.name}</td>
                      <td>{toCurrency(rp.amountDue)}</td>
                      <td>{toCurrency(rp.amountPaid)}</td>
                      <td>{toCurrency(rp.amountPendingClearance)}</td>
                      <td>
                        {rp.receivedAt && rp.receivedAt.length > 0
                          ? moment(rp.receivedAt).format(DATE_TIME_FORMAT)
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      </PageWrapper>
    );
  }
};

export default LandlordPaymentDetailPage;
