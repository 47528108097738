import {FunctionComponent} from 'react';

import clsx from 'clsx';

import {RowGroup} from '../data-types';

interface RowSeparatorProps {
  group: RowGroup;
  colSpan: number;
}

type RowSeparatorComponent = FunctionComponent<RowSeparatorProps>;

const RowSeparator: RowSeparatorComponent = ({group, colSpan}) => {
  const {id, title} = group;
  return (
    <tr id={`${id}-row-group-title`}>
      <td className={clsx('pt-2 px-2')} colSpan={colSpan}>
        <div
          className={clsx(
            'py-2 px-4',
            'bg-brand-25',
            'rounded-lg',
            'font-semibold',
            'text-brand-850 text-opacity-70',
          )}>
          {title}
        </div>
      </td>
    </tr>
  );
};

export default RowSeparator;
