import {Navigate, Route} from 'react-router-dom';

import LoggedOutRoute from 'components/routing/LoggedOutRoute';
import ForgotPasswordPage from 'pages/shared/auth/ForgotPasswordPage';
import LoginPage from 'pages/shared/auth/LoginPage';
import Welcome from 'pages/shared/auth/Register/Welcome';
import RegisterPage from 'pages/shared/auth/RegisterPage';
import ResetPasswordPage from 'pages/shared/auth/ResetPasswordPage';

const LoggedOutRoutes = (
  <Route element={<LoggedOutRoute />}>
    {/* Registration welcome page */}
    <Route path="welcome" element={<Welcome />} />
    {/* Registration pages */}
    <Route path="register">
      {/* Landlord registration */}
      <Route
        path="landlord"
        element={<RegisterPage accountType="landlord" />}
      />
      {/* Tenant registration */}
      <Route path="tenant" element={<RegisterPage accountType="tenant" />} />
      {/* Redirect any /register routes that don't match landlord to tenant to the welcome page */}
      <Route index path="*" element={<Navigate to="/welcome" />} />
    </Route>
    <Route path="login" element={<LoginPage />} />
    <Route path="forgot-password" element={<ForgotPasswordPage />} />
    <Route path="reset-password/:token" element={<ResetPasswordPage />} />
    <Route path="reset-password" element={<ResetPasswordPage />} />
    {/* <Route path="register" element={<RegisterPage />} /> */}
  </Route>
);

export default LoggedOutRoutes;
