import type {VisibilityConditionFunction} from './types';

/**
 * A predefiend set of functions conditionally determine whether a
 * nav link item should be visible.
 */

/**
 * Any type of user is logged in.
 */
export const LOGGED_IN: VisibilityConditionFunction = (currentUser) =>
  !!currentUser;

/**
 * No user user is logged in.
 */
export const LOGGED_OUT: VisibilityConditionFunction = (currentUser) =>
  !currentUser;

/**
 * A user is logged in and has the Landlord role.
 */
export const USER_IS_LANDLORD: VisibilityConditionFunction = (currentUser) =>
  currentUser && currentUser.roles.includes('Landlord');

/**
 * A user is logged in and has the Renter role.
 */
export const USER_IS_RENTER: VisibilityConditionFunction = (currentUser) => {
  return currentUser && currentUser.roles.includes('Renter');
};
