import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Property from 'models/properties/Property';

@Model()
class PreferredTradesman extends ApplicationRecord {
  static jsonapiType = 'preferred_tradesmen';

  @Attr() name: string;
  @Attr() email: string | null;
  @Attr() phoneNumber: string | null;
  @Attr() tradeCategory: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() propertyId: string;

  @BelongsTo('properties') property: Property;
}

export default PreferredTradesman;
