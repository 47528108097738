type PseudoBoolean = 'Unspecified' | 'Yes' | 'No' | undefined;
const PseudoBooleanDisplayView = ({
  label,
  value,
}: {
  label: string;
  value: PseudoBoolean;
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex-grow">
        <label className="cursor-pointer label">
          <span className="label-text">{label}</span>
        </label>
      </div>
      <div className="flex-shrink">
        <strong className="text-sm lg:text-md">{value}</strong>
      </div>
    </div>
  );
};

export default PseudoBooleanDisplayView;
