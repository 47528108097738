import {lazy, Suspense} from 'react';

import LoadingView from 'components/common/LoadingView';
import useAuth from 'services/useAuth';

const Worker: any = lazy(() =>
  import('@react-pdf-viewer/core').then((module) => {
    return {default: module.Worker};
  }),
);
const Viewer = lazy(() =>
  import('@react-pdf-viewer/core').then((module) => {
    return {default: module.Viewer};
  }),
);

import '@react-pdf-viewer/core/lib/styles/index.css';

const DocumentPreviewModal = ({
  isVisible,
  setIsVisible,
  documentUrl,
  title,
}: {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  documentUrl: string;
  title: string;
}) => {
  const {currentUser} = useAuth();

  const handleModalClick = (event: any) => {
    if (event.target.classList.contains('modal')) {
      if (isVisible) {
        setIsVisible(false);
      }
    }
  };

  // const toolbarPluginInstance = toolbarPlugin();
  // const {renderDefaultToolbar, Toolbar} = toolbarPluginInstance;

  // const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
  //   ...slot,
  //   // These slots will be empty
  //   Download: () => <></>,
  //   SwitchTheme: () => <></>,
  //   Print: () => <></>,
  //   Open: () => <></>,
  //   CurrentPageInput: () => <></>,
  //   GoToFirstPage: () => <></>,
  //   GoToLastPage: () => <></>,
  //   GoToNextPage: () => <></>,
  //   GoToPreviousPage: () => <></>,
  //   NumberOfPages: () => <></>,
  //   Rotate: () => <></>,
  //   ShowProperties: () => <></>,
  //   SwitchScrollMode: () => <></>,
  //   SwitchSelectionMode: () => <></>,
  //   SwitchSelectionModeMenuItem: () => <></>,
  //   SwitchScrollModeMenuItem: () => <></>,
  //   RotateBackwardMenuItem: () => <></>,
  //   RotateForwardMenuItem: () => <></>,
  //   GoToFirstPageMenuItem: () => <></>,
  //   GoToLastPageMenuItem: () => <></>,
  // });

  return (
    <div
      className={'modal' + (isVisible ? ' modal-open' : '')}
      onClick={handleModalClick}>
      <div
        className="modal-box max-w-full w-10/12 md:w-18/12 max-h-full h-5/6"
        onClick={handleModalClick}>
        <h5 className="text-2xl font-semibold">{title}</h5>
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              {/* <Toolbar>{renderDefaultToolbar(transform)}</Toolbar> */}
              <Viewer
                fileUrl={documentUrl}
                // plugins={[toolbarPluginInstance]}
                httpHeaders={
                  currentUser
                    ? {
                        'X-USER-TOKEN': currentUser.meta.authenticationToken,
                        'X-USER-EMAIL': currentUser.email,
                      }
                    : {}
                }
                renderLoader={() => (
                  <div>
                    <LoadingView />
                  </div>
                )}
                renderError={() => (
                  <div>
                    <p>
                      There was an issue trying to load this document, please
                      try again later.
                    </p>
                  </div>
                )}
              />
            </Worker>
          </Suspense>
        </div>
        <div className="modal-action">
          <button
            className="btn btn-neutral"
            type="button"
            onClick={() => setIsVisible(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentPreviewModal;
