import {Model, Attr, WhereClause} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';

interface Params {
  [key: string]: boolean;
}

@Model()
class SavedListingSearch extends ApplicationRecord {
  static jsonapiType = 'saved_listing_searches';
  @Attr() notificationType: 'daily' | 'real_time';
  @Attr() params: Params;
  @Attr() searchName: string;
  @Attr() unnotifiedListingIds: number[];
}

export default SavedListingSearch;
