import React from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {camelCase} from 'lodash-es';
import {HiOutlineCog} from 'react-icons/hi';
import {MdOutlineManageAccounts} from 'react-icons/md';
import {useQuery, useQueryClient} from 'react-query';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';

type AccountFormValues = {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  currentPassword: string;
};

const SettingsAccountPage = () => {
  const {currentUser, updateUser} = useAuth();

  const {isLoading, error, data} = useQuery('user-account-page', async () => {
    const user = await User.includes([
      'landlord_profile',
      'renter_profile',
    ]).find(currentUser.id);

    return user.data;
  });
  const queryClient = useQueryClient();

  const saveUserDetails = async (
    formData: AccountFormValues,
    actions: FormikHelpers<AccountFormValues>,
  ) => {
    const result = await updateUser(formData);
    if (result.status === 200 || result.status === 204) {
      queryClient.invalidateQueries('user-account-page');
    } else {
      for (const key of Object.keys(result.responseObject.errors)) {
        const error = result.responseObject.errors[key];
        const camelKey = camelCase(key);
        actions.setFieldError(camelKey, error);
      }
    }
    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Account">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Account">
        <Card icon={HiOutlineCog} title="Account Information" className="mt-2">
          <Formik
            initialValues={
              {
                name: data.name,
                email: data.email,
                password: '',
                passwordConfirmation: '',
                currentPassword: '',
              } as AccountFormValues
            }
            onSubmit={saveUserDetails}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required()
                .label('Full Name')
                .min(2)
                .max(128)
                .test(
                  'two-names-plus',
                  'Please input your first and last name',
                  (value) => {
                    return value.split(' ').length >= 2;
                  },
                ),
              email: Yup.string().email().required().label('Email'),
              password: Yup.string().min(6).max(128).label('Password'),
              passwordConfirmation: Yup.string()
                .min(6)
                .max(128)
                .label('Password Confirmation')
                .test(
                  'passwords-match',
                  'Passwords must match',
                  function (value) {
                    return this.parent.password === value;
                  },
                ),
              currentPassword: Yup.string()
                .min(6)
                .max(128)
                .label('Current Password')
                .required(),
              avatar: Yup.string().min(1).label('Avatar'),
            })}
            validateOnBlur={false}
            validateOnChange={false}>
            {(formik) => {
              return (
                <Form>
                  <InputField
                    placeholder="E.g. John Smith"
                    labelProps={{
                      title: 'Full name',
                    }}
                    name="name"
                    formik={formik}
                  />
                  <InputField
                    placeholder="E.g. johnsmith@gmail.com"
                    labelProps={{
                      title: 'Email address',
                    }}
                    name="email"
                    type="email"
                    formik={formik}
                    autoComplete="email"
                  />
                  <small className="text-secondary">
                    ( You will need to confirm your new email address if you
                    change it. )
                  </small>

                  <div className="flex flex-col lg:flex-row justify-between">
                    <div className="flex-1">
                      <InputField
                        placeholder="*********"
                        labelProps={{
                          title: 'New password',
                          description:
                            "Leave blank if you don't want to change it",
                        }}
                        name="password"
                        formik={formik}
                        type="password"
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="flex-1 lg:ml-2">
                      <InputField
                        placeholder="*********"
                        labelProps={{
                          title: 'Confirm new password',
                        }}
                        name="passwordConfirmation"
                        formik={formik}
                        type="password"
                        autoComplete="new-password"
                      />
                    </div>
                  </div>
                  <div>
                    <InputField
                      placeholder="*********"
                      labelProps={{
                        title: 'Current password',
                        description: 'We need this to confirm your changes',
                      }}
                      name="currentPassword"
                      formik={formik}
                      type="password"
                      autoComplete="password"
                    />
                  </div>

                  <SubmitButton
                    formik={formik}
                    text="Update Information"
                    submittingText="Saving"
                  />
                </Form>
              );
            }}
          </Formik>
        </Card>
      </PageWrapper>
    );
  }
};

export default SettingsAccountPage;
