import {useEffect} from 'react';

import {Capacitor} from '@capacitor/core';
import {Keyboard, KeyboardResize} from '@capacitor/keyboard';

const useChangeKeyboardResizeMode = () => {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({
        mode: KeyboardResize.Body,
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        Keyboard.setResizeMode({
          mode: KeyboardResize.Native,
        });
      }
    };
  }, []);
};

export default useChangeKeyboardResizeMode;
