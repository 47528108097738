import React from 'react';

import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';

const RenterAwaitingInspectionTypeCard = () => {
  return (
    <Card title="Inspection Information">
      <Paragraph>
        Details of this inspection have not yet been confirmed.
      </Paragraph>
    </Card>
  );
};

export default RenterAwaitingInspectionTypeCard;
