/**
 * The possible statuses for a file item in the FileUploader component.
 */
export enum FileItemStatus {
  DeterminingMimeType = 'determining_mime_type',
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  ErrorUploading = 'error_uploading',
  FileSizeTooLarge = 'file_size_too_large',
  FileTypeNotAllowed = 'file_type_not_allowed',
}

export type FileItemId = string | number;

export type MimeType = string | null;

/**
 * The format of the response returned from a file upload.
 */
export type UploadedFileData = {
  id: string;
  storage: 'cache';
  metadata: {
    size: number;
    filename: string;
    mime_type: string;
  };
};

/**
 * An instance of a file within the FileUploader component.
 */
export interface FileItem {
  id: FileItemId;
  status: FileItemStatus;
  mimeType: MimeType;
  url: string;
  uploadedFileData: UploadedFileData;
  localFile: File | null;
}

/**
 * A storage handler callback function that is invoked when
 * there is progress uploading a file.
 */
interface FileUploadProgressCallback {
  (progressEvent: ProgressEvent): void;
}

/**
 * A storage handler function for uploading a file.
 */
export interface FileUploadFunction {
  (args: {
    file: File;
    onProgress: FileUploadProgressCallback;
  }): Promise<UploadedFileData>;
}

/**
 * A storage handler is responsible for interfacing with files
 * in a remote storage location.
 */
export interface FileUploaderStorageHandler {
  upload: FileUploadFunction;
}

// enum MimeType {
//   ".aac" = "audio/aac",
//   ".abw" = "application/x-abiword",
//   ".arc" = "application/x-freearc",
//   ".avi" = "video/x-msvideo",
//   ".azw" = "application/vnd.amazon.ebook",
//   ".bin" = "application/octet-stream",
//   ".bmp" = "image/bmp",
//   ".bz" = "application/x-bzip",
//   ".bz2" = "application/x-bzip2",
//   ".csh" = "application/x-csh",
//   ".css" = "text/css",
//   ".csv" = "text/csv",
//   ".doc" = "application/msword",
//   ".docx" = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//   ".eot" = "application/vnd.ms-fontobject",
//   ".epub" = "application/epub+zip",
//   ".gz" = "application/gzip",
//   ".gif" = "image/gif",
//   ".htm" = "text/html",
//   ".html" = "text/html",
//   ".ico" = "image/vnd.microsoft.icon",
//   ".ics" = "text/calendar",
//   ".jar" = "application/java-archive",
//   ".jpeg" = ".jpg",
//   ".js" = "text/javascript",
//   ".json" = "application/json",
//   ".jsonld" = "application/ld+json",
//   ".mid" = ".midi",
//   ".mjs" = "text/javascript",
//   ".mp3" = "audio/mpeg",
//   ".mpeg" = "video/mpeg",
//   ".mpkg" = "application/vnd.apple.installer+xml",
//   ".odp" = "application/vnd.oasis.opendocument.presentation",
//   ".ods" = "application/vnd.oasis.opendocument.spreadsheet",
//   ".odt" = "application/vnd.oasis.opendocument.text",
//   ".oga" = "audio/ogg",
//   ".ogv" = "video/ogg",
//   ".ogx" = "application/ogg",
//   ".opus" = "audio/opus",
//   ".otf" = "font/otf",
//   ".png" = "image/png",
//   ".pdf" = "application/pdf",
//   ".php" = "application/php",
//   ".ppt" = "application/vnd.ms-powerpoint",
//   ".pptx" = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
//   ".rar" = "application/vnd.rar",
//   ".rtf" = "application/rtf",
//   ".sh" = "application/x-sh",
//   ".svg" = "image/svg+xml",
//   ".swf" = "application/x-shockwave-flash",
//   ".tar" = "application/x-tar",
//   ".tif" = "image/tiff",
//   ".tiff" = "image/tiff",
//   ".ts" = "video/mp2t",
//   ".ttf" = "font/ttf",
//   ".txt" = "text/plain",
//   ".vsd" = "application/vnd.visio",
//   ".wav" = "audio/wav",
//   ".weba" = "audio/webm",
//   ".webm" = "video/webm",
//   ".webp" = "image/webp",
//   ".woff" = "font/woff",
//   ".woff2" = "font/woff2",
//   ".xhtml" = "application/xhtml+xml",
//   ".xls" = "application/vnd.ms-excel",
//   ".xlsx" = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   ".xml" = "XML",
//   ".xul" = "application/vnd.mozilla.xul+xml",
//   ".zip" = "application/zip",
//   ".3gp" = "video/3gpp",
//   ".3g2" = "video/3gpp2",
//   ".7z" = "application/x-7z-compressed"
// }
