import {Form, Formik, type FormikHelpers, type FormikProps} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {
  DatetimeField,
  SubmitButton,
  TextareaField,
  ToggleField,
} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import {API_URL} from 'globals/app-globals';
import Listing from 'models/listings/Listing';
import OpenHome from 'models/listings/OpenHome';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

interface OpenHomeFormVal {
  startTime: string;
  endTime: string;
}

interface Vals {
  notes: string;
  openHomes: OpenHomeFormVal[];
  notifyEnquirers: boolean;
}

const NewOpenHomePage = () => {
  usePageVisit('NewOpenHomePage');
  const {propertyId, listingId} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {currentUser} = useAuth();

  const {data, isLoading, error} = useQuery(
    `listing-${listingId}-new-open-home`,
    async () => {
      const listing = await Listing.select({listings: ['id']}).find(listingId);

      return listing.data;
    },
  );

  const removeTimeAtIndex = (formik: FormikProps<Vals>, index: number) => {
    const openHomes = formik.values.openHomes;
    openHomes.splice(index, 1);

    formik.setFieldValue('openHomes', openHomes);
  };

  const addTime = (formik: FormikProps<Vals>) => {
    const openHomes = formik.values.openHomes || [];

    openHomes.push({
      startTime: '',
      endTime: '',
    });

    formik.setFieldValue('openHomes', openHomes);
  };

  const handleSubmit = async (
    formValues: Vals,
    actions: FormikHelpers<Vals>,
  ) => {
    const notes = formValues.notes;

    const savedOpenHomes: OpenHome[] = [];

    for (const val of formValues.openHomes) {
      const openHome = new OpenHome(val);
      openHome.listingId = data.id;
      if (notes && notes.length > 0) {
        openHome.notes = notes;
      }

      const result = await openHome.save();

      if (result) {
        savedOpenHomes.push(openHome);
      }
    }

    if (formValues.notifyEnquirers) {
      await fetch(
        `${API_URL}/open_homes/notify_enquirers_about_new_open_homes.json`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser.email,
          },
          body: JSON.stringify({
            listing_id: data.id,
            open_home_ids: savedOpenHomes.map((oh) => oh.id),
          }),
        },
      );
    }

    toast.success('Open homes successfully created!');

    actions.setSubmitting(false);

    await queryClient.invalidateQueries(`listing-${listingId}-open-homes`);
    navigate(`/properties/${propertyId}/listings/${listingId}/viewings`);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title={`Schedule Open Home`}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const initialVals = {
      notes: '',
      openHomes: [{startTime: '', endTime: ''}],
      notifyEnquirers: false,
    } as Vals;

    return (
      <PageWrapper title={`Schedule Open Home`}>
        <Card title="Schedule Open Home">
          <Formik
            initialValues={initialVals}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              notes: Yup.string().optional().nullable().label('Notes'),
              openHomes: Yup.array()
                .of(
                  Yup.object().shape({
                    startTime: Yup.string().required().label('Start Time'),
                    endTime: Yup.string().required().label('End Time'),
                  }),
                )
                .min(1)
                .label('Open Home Times'),
            })}>
            {(formik) => (
              <Form>
                <TextareaField
                  formik={formik}
                  name="notes"
                  labelProps={{
                    title: 'Notes',
                    size: 'base',
                  }}
                  placeholder="Include any notes for potential tenants, e.g., how to access the property."
                />

                <strong className="block mt-4">Times</strong>
                {formik.values.openHomes.map((oh, index) => (
                  <div
                    key={index}
                    className="flex flex-col lg:flex-row justify-start items-start lg:items-end gap-2 lg:gap-4">
                    <div>
                      <DatetimeField
                        formik={formik}
                        name={`openHomes[${index}].startTime`}
                        label="Start Time"
                        minDate={new Date()}
                        maxDate={new Date('2100-01-01')}
                        inputProps={{
                          placeholder: 'Start Time',
                          className: 'input input-bordered',
                        }}
                        onBlur={() => {
                          if (oh.startTime && oh.startTime.length > 0) {
                            formik.setFieldValue(
                              `openHomes[${index}].endTime`,
                              moment(oh.startTime).add(1, 'hour').toISOString(),
                            );
                          }
                        }}
                      />
                    </div>
                    <div>
                      <DatetimeField
                        formik={formik}
                        name={`openHomes[${index}].endTime`}
                        label="End Time"
                        minDate={
                          oh.startTime
                            ? moment(oh.startTime).toDate()
                            : new Date()
                        }
                        maxDate={new Date('2100-01-01')}
                        inputProps={{
                          placeholder: 'End Time',
                          className: 'input input-bordered',
                        }}
                      />
                    </div>

                    <div>
                      <button
                        className="btn btn-ghost btn-sm"
                        onClick={() => removeTimeAtIndex(formik, index)}>
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                {/* {formik.errors.openHomes && (
                    <p className="text-red-500 text-sm">
                      {formik.errors.openHomes}
                    </p>
                  )} */}

                <div className="mt-2 w-1/2">
                  <ToggleField
                    formik={formik}
                    name="notifyEnquirers"
                    label="Notify Interested Tenants"
                    helpText="If ticked, we will send an email to anyone who had
                      enquired to your property letting them know about the
                      times added above."
                  />
                </div>

                <div className="mt-4">
                  <button
                    className="btn btn-neutral btn-sm"
                    onClick={() => addTime(formik)}>
                    Add Another Time
                  </button>
                </div>

                <SubmitButton
                  formik={formik}
                  text={
                    formik.values.openHomes.length > 1
                      ? 'Save Open Homes'
                      : 'Save Open Home'
                  }
                  submittingText="Saving..."
                  className="mt-20"
                />
              </Form>
            )}
          </Formik>
        </Card>
      </PageWrapper>
    );
  }
};

export default NewOpenHomePage;
