import {
  FunctionComponent,
  ReactNode,
  isValidElement,
  useCallback,
  useMemo,
} from 'react';

import {IconType} from '@react-icons/all-files';
import clsx from 'clsx';
import {Collapse, UnmountClosed} from 'react-collapse';
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineXCircle,
} from 'react-icons/hi';

import {ResponsiveActionSelect} from 'components_sb/buttons';
import useCurrentUserFlag from 'hooks/useCurrentUserFlag';
import {Action, actionIsButton, actionIsLink} from 'types/actions';
import getLinkComponent from 'utilities/getLinkComponent';

interface BaseAlertProps {
  type?: 'info' | 'success' | 'warning' | 'error';
  icon?: ReactNode | IconType;
  unmountOnClose?: boolean;
}

type ConditionalContentAlertProps =
  | {
      children: string | string[];
      title?: never;
      description?: never;
    }
  | {
      children?: never;
      title: string;
      description?: string | string[];
    };

type ConditionalActionsAlertProps =
  | {
      asLink?: true;
      linkTo: string;
      actions?: never;
    }
  | {
      asLink?: never;
      linkTo?: never;
      actions?: Action[];
    };

type ConditionalVisibilityAlertProps =
  | {
      show?: never;
      showOnce?: true;
      id: string;
    }
  | {
      show?: boolean;
      showOnce?: never;
      id?: string;
    };

/**
 * Combine base props with all conditional props
 */
type AlertProps = BaseAlertProps &
  ConditionalContentAlertProps &
  ConditionalActionsAlertProps &
  ConditionalVisibilityAlertProps;

const CLASSES = {
  info: 'bg-blue-200 text-blue-800',
  success: 'bg-green-500 text-white',
  warning: 'bg-amber-400 text-amber-900',
  error: 'bg-red-500 text-red-50',
};

const ICONS = {
  info: HiOutlineInformationCircle,
  success: HiOutlineCheckCircle,
  warning: HiOutlineExclamationCircle,
  error: HiOutlineXCircle,
};

const renderDescription = (description: string | string[]) => (
  <div className="flex flex-col gap-y-1">
    {Array.isArray(description)
      ? description.map((paragraph) => <span key={paragraph}>{paragraph}</span>)
      : description}
  </div>
);

const Alert: FunctionComponent<AlertProps> = ({
  id,
  children,
  title,
  description,
  type = 'info',
  asLink = false,
  linkTo,
  show = true,
  showOnce = false,
  icon,
  unmountOnClose = true,
  ...props
}) => {
  const hideFlag = useCurrentUserFlag(`hide_alert:${id}`);

  /**
   * The visibility for the alert will be handled differently depending on whether
   * the show or showOnce properties are set. The show prop enable directly manual
   * control, whereas the showOnce (paired with the id prop) will handle automatically
   * ensuring that the alert is shown once per user (until they close it).
   */
  const shouldShow = useMemo(() => {
    return (
      // Only show prop is set
      (show && !showOnce) ||
      // The showOnce prop is set - override show prop and check user flag
      (showOnce && hideFlag.isReady && hideFlag.value !== true)
    );
  }, [show, showOnce, hideFlag]);

  /**
   * Render the icon element.
   */
  const iconElement = useMemo(() => {
    const renderReactIcon = (Icon: IconType) => (
      <Icon className="w-6 h-6 flex-shrink-0" />
    );
    if (icon) {
      /**
       * If the icon prop is an element, we rendering it directly.
       * Otherwise, we can assume it is a react-icons icon type, so
       * we render the icon as a component.
       */
      if (isValidElement(icon)) {
        return icon;
      } else {
        return renderReactIcon(icon as IconType);
      }
    } else {
      return renderReactIcon(ICONS[type]);
    }
  }, [icon, type]);

  /**
   * The close function within this component is only used when
   * the showOnce prop has been set. When the show prop is instead
   * set, any close functionality must be handled outside of the
   * component via the actions prop.
   */
  const onClose = useCallback(async () => {
    await hideFlag.set(true);
  }, [hideFlag]);

  const WrapperComponent = useMemo(
    () => (asLink ? getLinkComponent(linkTo) : 'div'),
    [asLink, linkTo],
  );

  /**
   * Merge the provided actions with the close action if the
   * showOnce prop has been set.
   */
  const actions = useMemo<Action[]>(
    () =>
      asLink
        ? []
        : [
            ...(props.actions ?? []),
            ...(showOnce ? [{label: 'Close', onClick: onClose}] : []),
          ],
    [asLink, props.actions, showOnce, onClose],
  );

  const CollapseComponent = useMemo(
    () => (unmountOnClose ? UnmountClosed : Collapse),
    [unmountOnClose],
  );

  return (
    <CollapseComponent isOpened={shouldShow}>
      <WrapperComponent id={id} to={linkTo}>
        {/* react-collapse doesn't like margins on the child
      element, so we add padding to a parent instead */}
        <div className="pb-6 relative">
          <div
            className={clsx(
              CLASSES[type],
              'flex-1',
              'rounded-xl',
              'shadow-lg',
              'px-4 py-4',
              'flex flex-row',
              'justify-start items-center',
              'gap-x-3',
              'font-normal',
            )}>
            {/* Icon */}
            {iconElement}
            {/* Content */}
            <div className="flex flex-col gap-y-1 text-sm flex-grow">
              <span className="font-medium">
                {!children ? title : renderDescription(children)}
              </span>
              {!children && description && (
                <div className="opacity-70">
                  {renderDescription(description)}
                </div>
              )}
            </div>
            {/* Actions */}
            {!!actions && actions.length > 0 && (
              <div className="max-w-md min-w-[60px] xs:min-w-[100px] flex-1">
                <ResponsiveActionSelect justify="end" actions={actions} />
              </div>
            )}
          </div>
        </div>
      </WrapperComponent>
    </CollapseComponent>
  );
};

export default Alert;
