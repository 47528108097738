import {ReactNode} from 'react';

import {create} from 'zustand';

interface ConfirmationOptions {
  title: string;
  message: ReactNode;
  color: 'success' | 'error';
  buttonTitle: string;
  action: any;
}

type ConfirmationModalStore = {
  confirmationOptions: ConfirmationOptions | null;
  setConfirmationOptions: (options: ConfirmationOptions) => void;
};

const useConfirmationModalStore = create<ConfirmationModalStore>(
  (set): ConfirmationModalStore => ({
    confirmationOptions: null,
    setConfirmationOptions: (options) =>
      set(() => ({confirmationOptions: options})),
  }),
);

export default useConfirmationModalStore;
