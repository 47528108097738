import {
  ChangeEventHandler,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
} from 'react';

import {TextField} from 'components_sb/input';
import useLocationSearchPopover from 'utilities/useLocationSearchPopover';

interface AdvancedLocationSearchFieldProps {
  onSelectLocation: (locationString: string) => void;
}

const AdvancedLocationSearchField: FunctionComponent<
  AdvancedLocationSearchFieldProps
> = ({onSelectLocation}) => {
  /**
   * Create a reference to the input element.
   */
  const inputRef = useRef<HTMLInputElement>();

  const [searchText, setSearchText] = useState('');

  /**
   * Update the value for the field on the form instance when
   * there are changes to the input field.
   */
  const onChangeSearchText = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({target: {value}}) => {
      setSearchText(value);
    },
    [setSearchText],
  );

  /**
   * Only show search suggestions when the input field is focused
   * and the user has entered at least two characters.
   */
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  // useEffect(() => {
  //   if (inputFocused && !!searchText && searchText.length > 2 && !popoverOpen) {
  //     setPopoverOpen(true);
  //   }
  // }, [inputFocused, searchText, popoverOpen]);

  const handleSelectLocation = useCallback(
    (locationString: string) => {
      /**
       * Clear the search text.
       */
      setSearchText('');
      /**
       * Notify the parent of the selection.
       */
      onSelectLocation(locationString);
      /**
       * Close the popover.
       */
      setPopoverOpen(false);
    },
    [setSearchText, onSelectLocation],
  );

  const locationSearchPopover = useLocationSearchPopover({
    open: searchText?.length > 2 && popoverOpen,
    onOpenChange: setPopoverOpen,
    searchText,
    onSelect: handleSelectLocation,
    width: 'reference',
  });

  return (
    <>
      <div
        ref={locationSearchPopover.referenceRef}
        {...locationSearchPopover.getReferenceProps()}>
        <TextField
          ref={inputRef}
          name="search"
          size="base"
          mode="manual"
          value={searchText}
          onChange={onChangeSearchText}
          placeholder="Search for a location..."
        />
      </div>
      {/* Popover showing location search suggestions */}
      {locationSearchPopover.element}
    </>
  );
};

export default AdvancedLocationSearchField;
