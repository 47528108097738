import {useEffect} from 'react';

const useScrollPositionFix = () => {
  useEffect(() => {
    const resetScroll = () => {
      setTimeout(() => {
        if (window.innerHeight == screen.height) {
          window.scrollTo(0, 0);
        }
      }, 20);
    };
    document.addEventListener('focusout', resetScroll);

    return () => {
      document.removeEventListener('focusout', resetScroll);
    };
  }, []);
};

export default useScrollPositionFix;
