import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import RentPayment from 'models/payments/RentPayment';
import Tenancy from 'models/properties/Tenancy';
import User from 'models/users/User';

@Model()
class TenancyMembership extends ApplicationRecord {
  static jsonapiType = 'tenancy_memberships';

  @Attr() active: boolean;

  @Attr({persist: false}) bondPaid: boolean;
  @Attr() bondReference: string;
  @Attr() rentReference: string;

  @Attr() rentSplit: number;
  @Attr() bondSplit: number;

  @Attr() renterId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('users') renter: User;
  @BelongsTo('tenancies') tenancy: Tenancy;

  @HasMany('rent_payments') rentPayments: RentPayment[];
  @HasMany('rent_payments') notPaidRentPayments: RentPayment[];
}

export default TenancyMembership;
