import {HTMLProps} from 'react';

import clsx from 'clsx';
import {type FormikProps} from 'formik';

import {InlineError} from 'components_sb/feedback';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';
import TrackingService from 'services/TrackingService';

interface SelectFieldProps extends Omit<HTMLProps<HTMLSelectElement>, 'label'> {
  formik: FormikProps<any>;
  labelProps?: FieldLabelProps;
}

const SelectField = ({
  formik,
  name,
  labelProps,
  children,
  ...rest
}: SelectFieldProps) => (
  <div className="flex-1 flex-col">
    {labelProps && <FieldLabel {...labelProps} />}
    <select
      name={name}
      // TODO: Refactor this to use the same styling as the TextField component
      className={clsx(
        'w-full max-w-full box-border', // Size
        'transition-all duration-300', // Transition
        'bg-brand-50 hover:bg-brand-75 ', // Background
        'placeholder-brand-800 placeholder-opacity-30', // Placeholder
        'outline-none border-none ring-1 ring-brand-75', // Untouched
        'focus:ring-2 focus:ring-brand-500', // Focus
        'text-base', // Text
        'px-4', // Padding
        'h-12', // Height
        'rounded-lg', // Roundness
        !!formik.errors[name] && '!ring-2 !ring-error', // Error
      )}
      onChange={(e) => {
        formik.setFieldValue(name, e.target.value);
        TrackingService.trackEvent(TrackingService.Event.SelectOption, {
          field: name,
        });
      }}
      value={formik.values[name]}
      {...rest}>
      {children}
    </select>
    <InlineError error={formik.errors[name]} />
  </div>
);

export default SelectField;
