import {
  useState,
  useCallback,
  ReactNode,
  useMemo,
  useContext,
  createContext,
} from 'react';

/**
 * The context used for setting and getting a badge count for a given ID.
 */
const Context = createContext({
  badgeCounts: {} as BadgeCount,
  getBadgeCount: null,
  setBadgeCount: null,
});

interface BadgeCount {
  [id: string]: number;
}

const Provider = ({children}: {children: ReactNode}) => {
  const [badgeCounts, setBadgeCounts] = useState<BadgeCount>({});

  const getBadgeCount = useCallback(
    (id: string) => {
      return badgeCounts[id] ?? 0;
    },
    [badgeCounts],
  );

  const setBadgeCount = useCallback(
    (id: string, count: number) => {
      setBadgeCounts({
        ...badgeCounts,
        [id]: count,
      });
    },
    [badgeCounts, setBadgeCounts],
  );

  return (
    <Context.Provider
      value={{
        badgeCounts,
        getBadgeCount,
        setBadgeCount,
      }}>
      {children}
    </Context.Provider>
  );
};

export const useNavItemBadgeCounts = () => {
  const {badgeCounts} = useContext(Context);
  return badgeCounts;
};

export const useNavItemBadgeCount = (id: string) => {
  const {badgeCounts, getBadgeCount, setBadgeCount} = useContext(Context);
  const value = useMemo(
    () => [
      getBadgeCount(id),
      (badgeCount: number) => setBadgeCount(id, badgeCount),
    ],
    [id, badgeCounts, getBadgeCount, setBadgeCount],
  );
  return value;
};

export default {
  Provider,
};
