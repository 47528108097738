import {useState} from 'react';

import {AiOutlineDelete} from '@react-icons/all-files/ai/AiOutlineDelete';
import {AiOutlineEdit} from '@react-icons/all-files/ai/AiOutlineEdit';
import {type FormikProps} from 'formik';

interface Chattels {
  [key: string]: number;
}

const ChattelField = ({
  formik,
  chattels,
  chattelKey,
  formikKey = 'chattels',
}: {
  formik: FormikProps<any>;
  chattels: Chattels;
  chattelKey: string;
  formikKey: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(chattelKey);

  const disabled = formik.isSubmitting;

  const increment = () => {
    chattels[chattelKey] += 1;
    formik.setFieldValue(formikKey, chattels);
  };

  const decrement = () => {
    if (chattels[chattelKey] === 0) {
      return;
    }
    chattels[chattelKey] -= 1;
    formik.setFieldValue(formikKey, chattels);
  };

  const deleteChattel = () => {
    delete chattels[chattelKey];
    formik.setFieldValue(formikKey, chattels);
  };

  const editChattel = () => {
    setIsEditing(true);
  };

  const handleEditSave = () => {
    const val = chattels[chattelKey];
    delete chattels[chattelKey];
    chattels[editedName] = val;

    formik.setFieldValue(formikKey, chattels);

    setIsEditing(false);
  };

  return (
    <div className="mt-1">
      {isEditing ? (
        <div className="form-control">
          <div className="input-group">
            <input
              type="text"
              className="input input-bordered input-sm"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-sm btn-neutral"
              onClick={handleEditSave}>
              Save
            </button>
          </div>
        </div>
      ) : (
        <div>
          <span className="block">{chattelKey}</span>
          <div className="flex justify-start">
            <div className="btn-group">
              <button
                className="btn btn-sm"
                disabled={disabled}
                onClick={decrement}
                type="button">
                -
              </button>
              <button className="btn btn-sm" disabled={disabled} type="button">
                {chattels[chattelKey].toString()}
              </button>
              <button
                className="btn btn-sm"
                disabled={disabled}
                onClick={increment}
                type="button">
                +
              </button>
            </div>
            <div className="btn-group ml-2">
              <button
                className="btn btn-sm btn-secondary"
                type="button"
                onClick={editChattel}>
                <AiOutlineEdit className="w-5 h-5 text-white" />
              </button>
              <button
                className="btn btn-sm btn-secondary"
                type="button"
                onClick={deleteChattel}>
                <AiOutlineDelete className="w-5 h-5 text-white" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChattelField;
