import moment from 'moment';

import Inspection from 'models/inspections/Inspection';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import InspectionSignOff from 'models/inspections/InspectionSignOff';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import User from 'models/users/User';

const landlord = new User({name: 'Demo Landlord', id: '-1'});
const tenant = new User({name: 'Demo Tenant', id: '-2'});

const inspection = new Inspection({
  status: 'complete',
  inspectionPeriodType: 'pre_tenancy',
  doneBy: 'landlord',
  finalizedDate: moment().subtract('90', 'days').set({hour: 10, minutes: 0}),
  reportType: 'detailed',
  tenancy: new Tenancy({
    property: new Property({
      streetAddress: '1 Example Street',
      suburb: 'Thorndon',
      city: 'Wellington',
      landlordId: '-1',
      landlord: landlord,
    }),
  }),
  inspectionSignOffs: [
    new InspectionSignOff({
      userId: landlord.id,
      user: landlord,
      role: 'landlord',
      signature:
        'https://storage.googleapis.com/propup-demo-media/inspection-1/landlord_signature.png',
      createdAt: moment().subtract('90', 'days').set({hour: 10, minutes: 0}),
    }),
    new InspectionSignOff({
      userId: tenant.id,
      user: tenant,
      role: 'renter',
      signature:
        'https://storage.googleapis.com/propup-demo-media/inspection-1/tenant_signature.png',
      createdAt: moment().subtract('88', 'days').set({hour: 10, minutes: 0}),
    }),
  ],
  inspectionItems: [
    new InspectionItem({
      room: 'Bedroom 1',
      name: 'Walls/Doors',
      orderIndex: 1,

      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '1',
          notes:
            'There are black marks scratched onto one of the wardrobe doors.',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-1.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '2',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-2.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '4',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-4.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '5',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-5.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),

    new InspectionItem({
      room: 'Bedroom 1',
      name: 'Floors/Floor Coverings',
      orderIndex: 3,
      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '7',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-7.jpg',
          attachmentMimeType: 'image/jpg',
          user: tenant,

          userId: tenant.id,
          notes: 'Some slight creases in the carpet from old furniture.',
        }),
        new InspectionItemAttachment({
          id: '8',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-8.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '9',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-9.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '10',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-10.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),
    new InspectionItem({
      room: 'Bedroom 1',
      name: 'Windows',
      orderIndex: 4,

      notes: null,
      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '6',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-6.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),
    new InspectionItem({
      room: 'Bedroom 1',
      name: 'Blinds/Curtains',
      orderIndex: 5,

      notes: null,
      inspectionItemAttachments: [],
    }),

    new InspectionItem({
      room: 'Kitchen',
      name: 'Walls/Doors',
      orderIndex: 1,

      notes: null,
      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '15',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-15.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '24',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-24.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),
    new InspectionItem({
      room: 'Kitchen',
      name: 'Floors/Floor Coverings',
      orderIndex: 3,

      notes: null,
      inspectionItemAttachments: [],
    }),
    new InspectionItem({
      room: 'Kitchen',
      name: 'Windows',
      orderIndex: 4,

      notes: null,
      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '16',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-16.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),
    new InspectionItem({
      room: 'Kitchen',
      name: 'Blinds/Curtains',
      orderIndex: 5,

      notes: null,
      inspectionItemAttachments: [],
    }),
    new InspectionItem({
      room: 'Kitchen',
      name: 'Cupboards',
      orderIndex: 6,

      notes: null,
      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '17',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-17.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '18',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-18.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '19',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-19.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '20',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-20.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
        new InspectionItemAttachment({
          id: '21',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-21.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),
    new InspectionItem({
      room: 'Kitchen',
      name: 'Sinks/Benches',
      orderIndex: 7,

      notes: null,
      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '25',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-25.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),
    new InspectionItem({
      room: 'Kitchen',
      name: 'Oven',
      orderIndex: 8,

      notes: null,
      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '23',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-23.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),
    new InspectionItem({
      room: 'Kitchen',
      name: 'Fridge',
      orderIndex: 9,

      notes: null,
      inspectionItemAttachments: [
        new InspectionItemAttachment({
          id: '22',
          attachment:
            'https://storage.googleapis.com/propup-demo-media/inspection-1/image-22.jpg',
          attachmentMimeType: 'image/jpg',
          user: landlord,
          userId: landlord.id,
        }),
      ],
    }),
    new InspectionItem({
      room: 'All',
      name: 'Lights/Power points',
      orderIndex: 10,
      toggleState: true,
    }),
    new InspectionItem({
      room: 'All',
      name: 'Smoke alarms',
      orderIndex: 11,
      toggleState: true,
    }),
  ],
});

export default inspection;
