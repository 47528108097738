import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Tenancy from 'models/properties/Tenancy';
import User from 'models/users/User';

@Model()
class TenancyInvite extends ApplicationRecord {
  static jsonapiType = 'tenancy_invites';

  @Attr() accepted: boolean;

  @Attr() name: string;
  @Attr() email: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() tenancyId: string;
  @Attr() sendingUserId: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
  @BelongsTo('users') renter: User;
  @BelongsTo('users') sendingUser: User;
}

export default TenancyInvite;
