import React from 'react';

import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';

const EnquiryThreadAutoReplyPage = () => {
  const {token} = useParams();

  const {data, isLoading} = useQuery(
    `auto-reply-enquiry-${token}`,
    async () => {
      const response = await fetch(
        `${API_URL}/enquiry_threads/${token}/mark_as_not_interested.json`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const data = await response.json();
      return data;
    },
  );

  if (isLoading) {
    return (
      <PageWrapper title="Enquiry">
        <LoadingView />
      </PageWrapper>
    );
  } else if (data) {
    return (
      <PageWrapper title="Enquiry">
        <Card className="mt-4">
          <Paragraph>
            Thank you for your response, the owner of the property has been
            notified that you are no longer intereasted.
          </Paragraph>
        </Card>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Enquiry">
        <Card className="mt-4">
          <Paragraph>
            Sorry, there was an issue notifying the owner of the property about
            your response.
          </Paragraph>
        </Card>
      </PageWrapper>
    );
  }
};

export default EnquiryThreadAutoReplyPage;
