import {useMemo} from 'react';

import moment from 'moment';
import {useQuery} from 'react-query';
import {Link} from 'react-router-dom';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import PrivateViewing from 'models/listings/PrivateViewing';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

import PrivateViewingRow from './PrivateViewingRow';

const ViewingsIndexPage = () => {
  usePageVisit('ViewingsIndex');
  const {currentUser} = useAuth();

  const {
    data,
    isLoading: isLoadingOpenHomes,
    error: openHomesError,
  } = useQuery('renter-open-homes', async () => {
    const u = await User.select({
      users: ['id'],
      listings: ['street_address', 'suburb', 'city', 'main_image', 'public_id'],
    })
      .includes({open_homes: 'listing'})
      .order({'open_homes.start_time': 'asc'})
      .find(currentUser.id);

    return u.data;
  });

  /**
   * Fetch the private viewings for the user.
   */
  const {
    data: privateViewings,
    isLoading: isLoadingPrivateViewings,
    error: privateViewingsError,
  } = useQuery(
    `renter-private-viewings`,
    async () =>
      (await PrivateViewing.order('starts_at').includes('listing').all()).data,
  );

  const isLoading = useMemo(
    () => isLoadingOpenHomes || isLoadingPrivateViewings,
    [isLoadingOpenHomes, isLoadingPrivateViewings],
  );

  if (openHomesError) {
    return errorViewForError(openHomesError);
  } else if (privateViewingsError) {
    return errorViewForError(privateViewingsError);
  } else if (isLoading) {
    return (
      <PageWrapper title="Open Homes / Viewings">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const filteredOpenHomes = data.openHomes.filter((openHome) =>
      moment(openHome.startTime).isAfter(moment()),
    );
    const filteredPrivateViewings = privateViewings.filter((privateViewing) =>
      moment(privateViewing.startsAt).isAfter(moment()),
    );

    if (
      filteredOpenHomes.length === 0 &&
      filteredPrivateViewings.length === 0
    ) {
      return (
        <PageWrapper title="Open Homes / Viewings">
          <Card title="Open Homes">
            <Paragraph>
              There are no upcoming open homes you have RSVP'd to or private
              viewings that been scheduled for you.
            </Paragraph>
          </Card>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper title="Open Homes / Viewings">
          {filteredOpenHomes.length > 0 && (
            <Card title="Open Homes">
              <div>
                {filteredOpenHomes.map((openHome) => {
                  const st = moment(openHome.startTime);
                  const et = moment(openHome.endTime);

                  return (
                    <div
                      key={openHome.id}
                      className="flex justify-between items-center mb-8">
                      <div className="flex justify-start items-center">
                        <div>
                          <strong>
                            {st.format('dddd DD MMMM hh:mm a')} -{' '}
                            {et.format('hh:mm a')}
                          </strong>
                          <p className="text-gray-500">
                            {openHome.listing.streetAddress},{' '}
                            {openHome.listing.suburb}, {openHome.listing.city}
                          </p>
                          {openHome.notes && openHome.notes.length > 0 && (
                            <p className="text-gray-500">
                              Landlord Notes: {openHome.notes}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <Link
                          to={`/listings/${openHome.listing.publicId}`}
                          className="link link-primary">
                          View listing
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Card>
          )}
          {/* We need a relation to the listings instance. */}
          {filteredPrivateViewings.length > 0 && (
            <Card title="Private Viewings">
              {filteredPrivateViewings.map((privateViewing) => (
                <PrivateViewingRow
                  key={privateViewing.id}
                  privateViewing={privateViewing}
                />
              ))}
            </Card>
          )}
        </PageWrapper>
      );
    }
  }
};

export default ViewingsIndexPage;
