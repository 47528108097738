import React, {Fragment} from 'react';

const ActionCard = ({
  IconClass,
  title,
  subtitle,
  onClick,
  iconBgColor = 'bg-blue-300',
  dismissable = false,
  onDismiss,
}: {
  IconClass: any;
  title: string;
  subtitle: string;
  onClick?: () => void;
  iconBgColor?: string;
  dismissable?: boolean;
  onDismiss?: () => void;
}) => {
  return (
    <div
      className="w-48 h-48 lg:w-64 lg:h-64 relative rounded-lg bg-white shadow-lg p-4 overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out cursor-pointer"
      onClick={onClick}>
      <div className="flex flex-col justify-between h-full">
        <div className="flex justify-between grow h-auto">
          <div
            className={`${iconBgColor} rounded-full w-12 h-12 lg:w-16 lg:h-16 flex justify-center items-center`}>
            <IconClass className="w-6 h-6 lg:w-10 lg:h-10 " />
          </div>
          {dismissable && (
            <a
              className="text-secondary text-sm cursor-pointer p-2 h-10"
              onClick={onDismiss}>
              DISMISS
            </a>
          )}
        </div>
        <div className="flex flex-col justify-end">
          {title.split(' ').length === 2 ? (
            <h3 className="text-md lg:text-2xl font-extrabold text-slate-600 leading-snug mb-2">
              {title.split(' ').map((t, index) => (
                <Fragment key={index}>
                  <span>{t}</span>
                  <br />
                </Fragment>
              ))}
            </h3>
          ) : (
            <h3 className="text-md lg:text-2xl font-extrabold text-slate-600 leading-snug mb-2">
              {title}
            </h3>
          )}

          <p className="text-grey text-xs lg:text-sm">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default ActionCard;
