import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import ServiceRequest from 'models/service_requests/ServiceRequest';

@Model()
class ServiceRequestAttachment extends ApplicationRecord {
  static jsonapiType = 'service_request_attachments';

  @Attr() attachment: string;
  @Attr({persist: false}) attachmentThumbnail: string;

  @Attr() taken: string;

  @Attr() serviceRequestId: string;

  @Attr({persist: false}) createdAt: string;

  @BelongsTo('service_requests') serviceRequest: ServiceRequest;
}

export default ServiceRequestAttachment;
