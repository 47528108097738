import {useCallback} from 'react';

import {faker} from '@faker-js/faker';
import {capitalCase} from 'change-case';
import {FormikProps, useFormik} from 'formik';
import moment from 'moment';

import {Button} from 'components_sb/buttons';
import {Paragraph} from 'components_sb/typography';

/**
 * Card numbers supported by Pin Payments for test environments to
 * simulate different response behaviours. Note that the below are
 * only for VISA card types, although other cards types are available
 * at: https://pinpayments.com/developers/api-reference/test-cards
 */
const TEST_CARD_DETAILS = [
  {
    label: 'Successful Response',
    cardNumber: 4200000000000000,
  },
  {
    label: 'Card Declined',
    cardNumber: 4100000000000001,
  },
  {
    label: 'Insufficient Funds',
    cardNumber: 4000000000000002,
  },
  {
    label: 'Invalid CVC',
    cardNumber: 4900000000000003,
  },
  {
    label: 'Invalid Card',
    cardNumber: 4800000000000004,
  },
  {
    label: 'Processing Error',
    cardNumber: 4700000000000005,
  },
  {
    label: 'Suspected Fraud',
    cardNumber: 4600000000000006,
  },
  {
    label: 'Gateway Error',
    cardNumber: 4300000000000009,
  },
  {
    label: 'Unknown',
    cardNumber: 4400000000000099,
  },
];

/**
 * A set of buttons that utilises the form context of the card payment input
 * to automatically enter card details that will result in a desired behaviour.
 * ===================================================================
 * THIS SHOULD ONLY BE RENDERED IN A DEVLOPMENT OR TESTING ENVIRONMENT
 * ===================================================================
 */
const CardTestingTools = ({
  form,
}: {
  form: ReturnType<typeof useFormik> | FormikProps<any>;
}) => {
  const handleSetValues = useCallback(
    (cardNumber: number) => {
      const expiry = moment(faker.date.future(2));
      form.setValues({
        nickname: `My ${capitalCase(faker.word.adjective())} Card`,
        name: faker.name.fullName(),
        number: cardNumber,
        expiry_month: expiry.format('MM'),
        expiry_year: expiry.format('YYYY'),
        cvc: faker.finance.creditCardCVV(),
        address_line1: faker.address.streetAddress(),
        address_line2: faker.address.secondaryAddress(),
        address_city: faker.address.city(),
        address_state: faker.address.state(),
        address_postcode: faker.address.zipCode('####'),
        address_country: faker.address.country(),
      });
    },
    [form],
  );
  return (
    <div className="border-amber-400 border rounded-lg mb-8 bg-white shadow-xl overflow-hidden">
      <div className="p-1 bg-amber-400 text-amber-800 text-center text-xs font-semibold uppercase">
        Testing Tools
      </div>
      <div className="p-4 flex flex-col">
        <Paragraph>
          Auto-fill fields to test different card behaviour.
        </Paragraph>
        <Paragraph secondary>
          Note that the chosen behaviour will only occur on backend operations
          using the card.
        </Paragraph>
        <div className="flex flex-row flex-wrap gap-2 mt-2">
          {Object.values(TEST_CARD_DETAILS).map(({label, cardNumber}) => (
            <Button
              key={label}
              label={label}
              category="primary"
              size="sm"
              mode="manual"
              onClick={() => handleSetValues(cardNumber)}
              fillWidth={false}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardTestingTools;
