import {Model, Attr, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import AssociatePayment from 'models/associates/AssociatePayment';
import PromoCode from 'models/users/PromoCode';

@Model()
class Associate extends ApplicationRecord {
  static jsonapiType = 'associates';

  @Attr({persist: false}) accessToken: string;

  @Attr({persist: false}) currency: string;

  @Attr() name: string;
  @Attr() email: string;

  @Attr() avatar: string;

  @Attr({persist: false}) perUserPayout: number;

  @Attr({persist: false}) totalReferrals: number;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @HasMany('promo_codes') promoCodes: PromoCode[];
  @HasMany('associate_payments') associatePayments: AssociatePayment[];
}

export default Associate;
