import React from 'react';

import {Capacitor} from '@capacitor/core';
import {
  AnnotationIcon,
  ChartPieIcon,
  CogIcon,
  HomeIcon,
  SearchIcon,
} from '@heroicons/react/solid';
import {useLocation, useNavigate} from 'react-router-dom';

import useNativeInsets from 'hooks/useNativeInsets';

type Tab = {
  title: string;
  path: string;
  icon: any;
};

type LinksType = Record<string, Tab[]>;

const Links: LinksType = {
  Landlord: [
    {
      title: 'Dashboard',
      path: '/',
      icon: HomeIcon,
    },
    {
      title: 'Chat',
      path: '/chat',
      icon: AnnotationIcon,
    },
    {
      title: 'Financials',
      path: '/financials',
      icon: ChartPieIcon,
    },
    {
      title: 'Settings',
      path: '/settings',
      icon: CogIcon,
    },
  ],
  Renter: [
    {
      title: 'Dashboard',
      path: '/',
      icon: HomeIcon,
    },
    {
      title: 'Chat',
      path: '/chat',
      icon: AnnotationIcon,
    },
    {
      title: 'Listings',
      path: '/listings',
      icon: SearchIcon,
    },
    {
      title: 'Settings',
      path: '/settings',
      icon: CogIcon,
    },
  ],
};

const BottomTabBar = ({accountType}: {accountType: 'Landlord' | 'Renter'}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const insets = useNativeInsets();

  return (
    <div
      className="fixed bg-brand-500 bottom-0 left-0 right-0"
      style={{
        paddingBottom: `calc(${
          // The bottom insets on iOS are huge, so we need to decrease it a bit to avoid the nav bar appearing too large
          Capacitor.getPlatform() === 'ios' ? '20px' : insets.bottom
        } + 12px)`,
      }}>
      <div className="columns-4 pt-3 px-2">
        {Links[accountType].map((tab) => {
          const isActive = tab.path === pathname;
          return (
            <div
              className="flex flex-col items-center justify-center gap-2"
              key={tab.path}
              onClick={() => navigate(tab.path, {replace: true})}>
              <tab.icon
                className={`w-5 h-5 mb-0 ${isActive ? 'text-white' : ''}`}
              />
              <span
                className={`${isActive ? 'text-white' : ''}`}
                style={{fontSize: 10}}>
                {tab.title}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BottomTabBar;
