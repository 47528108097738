import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Subscription from 'models/payments/Subscription';
import Tenancy from 'models/properties/Tenancy';
import ServiceRequest from 'models/service_requests/ServiceRequest';

@Model()
class Expense extends ApplicationRecord {
  static jsonapiType = 'expenses';

  @Attr({persist: false}) amountOwed: number;
  @Attr({persist: false}) amountPaid: number;

  @Attr({persist: false}) description: string;

  @Attr({persist: false}) status: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr({persist: false}) expensableId: string;
  @Attr({persist: false}) expensableType: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
  @BelongsTo() expensable: Subscription | ServiceRequest;
}

export default Expense;
