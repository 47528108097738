import {FunctionComponent} from 'react';

import * as RadixSlider from '@radix-ui/react-slider';
import clsx from 'clsx';

const thumbClassName = clsx(
  'block w-5 h-5 shadow-lg rounded-full',
  'cursor-grab active:cursor-grabbing',
  'transition-all duration-300',
  'border-3 border-brand-500',
  'bg-white hover:bg-brand-100',
);

interface BaseSliderProps {
  min: number;
  max: number;
  step: number;
  mode: 'single' | 'range';
  value: number[];
  onChange: (value: number[]) => void;
}

const BaseSlider: FunctionComponent<BaseSliderProps> = ({
  min,
  max,
  step,
  mode,
  value,
  onChange,
}) => {
  return (
    <div className={clsx('flex-1', mode === 'range' && 'mb-6')}>
      <RadixSlider.Root
        className="relative flex items-center select-none touch-none flex-1 h-[3px]"
        min={min}
        max={max}
        step={step}
        value={value}
        onValueChange={onChange}>
        <RadixSlider.Track
          className={clsx(
            'relative grow rounded-full h-[3px] cursor-pointer',
            'transition-colors duration-300',
            'bg-brand-100 hover:bg-brand-200',
          )}>
          <RadixSlider.Range
            className={clsx('absolute rounded-full h-full', 'bg-brand-500')}
          />
        </RadixSlider.Track>
        <RadixSlider.Thumb className={thumbClassName} />
        {mode === 'range' && <RadixSlider.Thumb className={thumbClassName} />}
      </RadixSlider.Root>
    </div>
  );
};

export default BaseSlider;
