import React, {useCallback, useEffect, useMemo, useState} from 'react';

import clsx from 'clsx';
import {omit} from 'lodash';
import {HiX} from 'react-icons/hi';
import {useQuery} from 'react-query';
import {useQueryParams} from 'use-query-params';

import ListingCardGrid from 'components/listing/ListingCardGrid';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import {Pagination} from 'components_sb/input';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import {
  advancedEmptyFormValues,
  AdvancedListingsSearchFormValues,
  AdvancedListingsSearchQueryParamsDefinition,
  advancedPlaceholderFormValues,
  LISTINGS_PER_PAGE,
} from 'constants/listings-search';
import useScroll from 'hooks/useScroll';
import Listing from 'models/listings/Listing';

import AdvancedFiltersBar from './AdvancedFiltersBar';
import constructWhereClauseFromFilters from './utils/construct-where-clause-from-filters';

const ListingSearchPage = () => {
  const {scrollToTop} = useScroll('root-scroll-container');

  /**
   * Scroll to the top of the page on load.
   */
  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  const [filters, setFilters] = useQueryParams(
    AdvancedListingsSearchQueryParamsDefinition,
    {
      removeDefaultsFromUrl: true,
    },
  );

  /**
   * Construct the where clause for the listings query based
   * on the filters.
   */
  const whereClause = useMemo(
    () =>
      constructWhereClauseFromFilters(
        // Exclude page since this is handled by .page()
        omit(filters, 'page'),
      ),
    [filters],
  );

  const {
    data: listingsResponse,
    isSuccess,
    isLoading,
    isError,
  } = useQuery(
    /**
     * By using the stringified filters object as the query key,
     * we can retrigger the query when the filters change.
     */
    ['listings-search', JSON.stringify(filters)],
    async () =>
      await Listing.page(filters.page)
        .includes('listing_photos')
        .per(LISTINGS_PER_PAGE)
        .page(filters.page)
        .order({createdAt: 'desc'})
        .where(whereClause)
        .stats({total: 'count'})
        .all(),
  );

  const [totalListings, setTotalListings] = useState<number | null>(null);

  /**
   * The total listings should only change when non-page number filters
   * have been applied - we need to retain the total number of listings
   * between page changes regardless of a new query for listings being
   * triggered for the new page.
   */
  useEffect(() => {
    if (isSuccess) {
      if (totalListings === null) {
        setTotalListings(listingsResponse.meta.stats.total.count);
      }
    }
  }, [isSuccess, totalListings, listingsResponse]);

  /**
   * Get the listings for the current page from the response data.
   */
  const listings = useMemo(
    () => (!isSuccess ? null : listingsResponse.data),
    [isSuccess, listingsResponse],
  );

  /**
   * Determine the maximum number of pages to allow the user to filter
   * by based on the total number of listings available and the number
   * of listings that are displayed per page.
   */
  const totalPages = useMemo(
    () =>
      !totalListings ? null : Math.round(totalListings / LISTINGS_PER_PAGE),
    [totalListings],
  );

  /**
   * Apply the filters when the form is submitted by setting the values
   * as query params.
   */
  const onApplyFilters = useCallback(
    (formValues: AdvancedListingsSearchFormValues) => {
      /**
       * Determine if there was any change to the filters.
       */
      const hasChanged =
        JSON.stringify(formValues) !== JSON.stringify(omit(filters, 'page'));

      /**
       * If there was no change to the filters, no action is required.
       */
      if (hasChanged) {
        setFilters({
          ...formValues,
          page: 1, // Go back to the first page when filters change
        });
        setTotalListings(null); // Reset the total listings count
        scrollToTop();
      }
    },
    [setFilters, scrollToTop, filters],
  );

  /**
   * Set the page to filter by to the given page number.
   */
  const setPage = useCallback(
    (newPage: number) => {
      setFilters((current) => ({
        ...current, // Retain existing filters
        page: newPage,
      }));
      scrollToTop();
    },
    [setFilters, scrollToTop],
  );

  /**
   * When the page filter is set to a page higher than the
   * actual number of pages that are available, set the page
   * to the last possible page.
   */
  useEffect(() => {
    if (!!totalPages && filters.page > totalPages) {
      setPage(totalPages);
    }
  }, [filters.page, totalPages, setPage]);

  const title = useMemo<string>(() => {
    const {locations} = filters;
    if (locations?.length === 1) {
      return `Properties near ${locations[0]}`;
    }
    return 'Find a rental property';
  }, [filters]);

  return (
    <PageWrapper
      title={title}
      disableMaxWidth
      disablePadding
      disableVerticalMargin>
      <AdvancedFiltersBar
        onApply={onApplyFilters}
        /**
         * We exclude the page number from the filters here because
         * it is not part of the form used for filtering and is instead
         * handled by the pagination buttons.
         */
        currentFilters={omit(filters, ['page'])}
        currentWhereClause={whereClause}
      />
      <div
        className={clsx(
          'flex-1',
          'w-full max-w-7xl',
          'mx-auto',
          'px-6 lg:px-8',
          'py-6',
          'flex flex-col gap-y-10',
        )}>
        {/* No listings to display */}
        {isSuccess && listings?.length === 0 && (
          <div className="my-auto p-12 flex flex-col items-center">
            <EmptyContentPlaceholder
              type="property"
              message={
                Object.keys(filters).length > 1
                  ? 'There are currently no listings matching your search criteria'
                  : 'There are currently no listings'
              }
            />
            <div>
              <Button
                category="secondary"
                size="base"
                label="Clear filters"
                icon={HiX}
                mode="manual"
                onClick={() => onApplyFilters(advancedEmptyFormValues)}
              />
            </div>
          </div>
        )}
        {/* Error loading listings */}
        {isError && (
          // TODO: Improve the error message styling + add retry button
          <div className="text-red-600 m-auto p-12">
            Sorry, there was an error loading the listings.
          </div>
        )}
        {/* Has listings, or loading listings */}
        {(isLoading || isSuccess) && (
          <ListingCardGrid
            listings={listings}
            skeletonCount={LISTINGS_PER_PAGE}
          />
        )}
        {/* Pagination */}
        {!!totalPages && (
          <div className="mt-auto">
            <Pagination
              page={filters.page}
              totalPages={totalPages}
              onChange={setPage}
            />
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default ListingSearchPage;
