import {useMemo} from 'react';

import {orderBy} from 'lodash';

/**
 * The minimum required shape for an object to be
 * compatible with this hook. Returns null if provided
 * with an empty array.
 */
interface CompatibleObject {
  createdAt: string;
}

/**
 * Returns the most recently created item in an array of objects
 *  that have a createdAt property.
 */
const useMostRecentlyCreated = <T extends CompatibleObject>(items: T[]) =>
  useMemo(
    () =>
      items.length
        ? orderBy(items, [(item) => new Date(item.createdAt)], ['desc'])[0]
        : null,
    [items],
  );

export default useMostRecentlyCreated;
