import * as React from 'react';

/**
 * If the user clicks on an item during holdTime milliseconds
 * and does not move the cursor further than holdDistance from the start point,
 * the 'onLongPress' method is called. Otherwise, the 'onClick' method will be called.
 */
export const useLongPress = (
  onClick: () => void,
  onLongPress: () => void,
  holdTime = 300,
  holdDistance = 3 ** 2,
) => {
  const [timer, setTimer] = React.useState<number | null>(null);
  const [startPoint, setStartPoint] = React.useState({
    x: 0,
    y: 0,
  });

  const triggerOnLongPress = () => {
    setTimer(null);
    onLongPress();
  };

  const onPointerDown = (e: React.PointerEvent<any>) => {
    setStartPoint({x: e.clientX, y: e.clientY}); // Remember start point
    const event = {...e};
    const timeoutId = window.setTimeout(
      triggerOnLongPress.bind(null, event),
      holdTime,
    );
    setTimer(timeoutId);
  };

  const onPointerUp = () => {
    if (timer) {
      window.clearTimeout(timer);
      setTimer(null);
      onClick();
    }
  };

  const onPointerMove = (e: React.PointerEvent<any>) => {
    // Abort onHold timer, if the cursor moves out of to far from starting point
    if (timer) {
      const fromStartPoint =
        (e.clientX - startPoint.x) ** 2 + (e.clientY - startPoint.y) ** 2;
      if (fromStartPoint > holdDistance) {
        setTimer(null);
        window.clearTimeout(timer);
      }
    }
  };

  return {
    onPointerDown,
    onPointerUp,
    onPointerMove,
  };
};
