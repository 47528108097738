import {mapValues} from 'lodash';
import {
  BooleanParam,
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  decodeDelimitedArray,
  encodeDelimitedArray,
  withDefault,
} from 'use-query-params';

import apartmentIcon from 'assets/img/property_types/apartment.png';
import houseIcon from 'assets/img/property_types/house.png';
import townhouseIcon from 'assets/img/property_types/townhouse.png';
import unitIcon from 'assets/img/property_types/unit.png';

export const LISTINGS_PER_PAGE = 10;

/**
 * Property type
 */
export const PROPERTY_TYPE_OPTIONS = [
  {id: 'House', label: 'House', icon: houseIcon},
  {id: 'Apartment', label: 'Apartment', icon: apartmentIcon},
  {id: 'Townhouse', label: 'Townhouse', icon: townhouseIcon},
  {id: 'Unit', label: 'Unit', icon: unitIcon},
];

/**
 * Bedrooms
 */
export const BEDROOMS = {
  MIN: 1,
  MAX: 10,
  STEP: 1,
};

/**
 * Bathrooms
 */
export const BATHROOMS = {
  MIN: 1,
  MAX: 10,
  STEP: 1,
};

/**
 * Garages
 */
export const GARAGES = {
  MIN: 1,
  MAX: 10,
  STEP: 1,
};

/**
 * Weekly rent
 */
export const WEEKLY_RENT = {
  MIN: 50,
  MAX: 2000,
  STEP: 25,
};

/**
 * Tenant count
 */
export const TENANT_COUNT = {
  MIN: 1,
  MAX: 6,
  STEP: 1,
};

/**
 * Since the default DelimitedArrayParam for the use-query-params library
 * uses underscores for delimiting, we need to defined a custom param that
 * instead uses commas for some fields, since some filter values may contain
 * underscores.
 */
const CustomDelimitedArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | string[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ','),
};

/**
 * Type for form values in the basic listings search form.
 */
export interface BasicListingsSearchFormValues {
  locations?: string[];
  propertyTypes?: string[];
  weeklyRent?: number[];
  bedrooms?: number[];
}

/**
 * Query param format definition for the basic listings search filters.
 */
export const BasicListingsSearchQueryParamsDefinition = {
  locations: DelimitedArrayParam,
  propertyTypes: CustomDelimitedArrayParam,
  weeklyRent: DelimitedNumericArrayParam,
  bedrooms: DelimitedNumericArrayParam,
};

/**
 * Placeholder form values for the basic listings search form.
 * (these values appear as the values for the filters when no value
 * has been set but are not recorded in the form instance)
 */
export const basicPlaceholderFormValues: BasicListingsSearchFormValues = {
  locations: [],
  propertyTypes: [],
  weeklyRent: [WEEKLY_RENT.MIN, WEEKLY_RENT.MAX],
  bedrooms: [BEDROOMS.MIN, BEDROOMS.MAX],
};

/**
 * Initial form values for the basic listings search form.
 * (all initialised to undefined)
 */
export const basicEmptyFormValues: BasicListingsSearchFormValues = mapValues(
  basicPlaceholderFormValues,
  () => undefined,
);

/**
 * Type for form values in the advanced listings search form.
 */
export type AdvancedListingsSearchFormValues = BasicListingsSearchFormValues & {
  bathrooms?: number[];
  garages?: number[];
  tenants?: number;
  allowedPetTypes?: string[];
  smokingAllowed?: boolean;
};

/**
 * Query param format definition for the advanced listings search
 * filters, including the page number
 */
export const AdvancedListingsSearchQueryParamsDefinition = {
  ...BasicListingsSearchQueryParamsDefinition,
  bathrooms: DelimitedNumericArrayParam,
  garages: DelimitedNumericArrayParam,
  tenants: NumberParam,
  allowedPetTypes: CustomDelimitedArrayParam,
  smokingAllowed: BooleanParam,

  page: withDefault(NumberParam, 1),
};

/**
 * Placeholder form values for the advanced listings search form.
 * (these values appear as the values for the filters when no value
 * has been set but are not recorded in the form instance)
 */
export const advancedPlaceholderFormValues: AdvancedListingsSearchFormValues = {
  ...basicPlaceholderFormValues,
  bathrooms: [BATHROOMS.MIN, BATHROOMS.MAX],
  garages: [GARAGES.MIN, GARAGES.MAX],
  tenants: TENANT_COUNT.MIN,
  allowedPetTypes: [],
  smokingAllowed: undefined,
};

/**
 * Initial form values for the advanced listings search form.
 * (all initialised to undefined)
 */
export const advancedEmptyFormValues: BasicListingsSearchFormValues = mapValues(
  advancedPlaceholderFormValues,
  () => undefined,
);

export const getPlaceholderValue = (name: string) => {
  if (name in advancedPlaceholderFormValues) {
    return Object.entries(advancedPlaceholderFormValues).find(
      ([key]) => key === name,
    )?.[1];
  }
};
