import {FunctionComponent, useCallback, useMemo} from 'react';

import clsx from 'clsx';
import {motion, AnimatePresence} from 'framer-motion';
import {
  HiOutlineArrowDown,
  HiOutlineArrowUp,
  HiOutlineDocumentText,
  HiOutlineExclamationCircle,
  HiOutlineStar,
  HiOutlineX,
  HiStar,
} from 'react-icons/hi';

import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';

import * as types from '../types';
import mimeTypeIsImage from '../utils/mime-type-is-image';

interface FileItemProps {
  children: types.FileItem;
  isMain?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isDragPlaceholder?: boolean;
  isDragOverlay?: boolean;
  onDelete?: () => void;
  onReorder?: (delta: number) => void;
}

const FileItem: FunctionComponent<FileItemProps> = ({
  children: fileItem,
  isMain = false,
  isFirst = false,
  isLast = false,
  isDragPlaceholder = false,
  onDelete,
  onReorder,
}) => {
  const {status, url, mimeType, localFile} = fileItem;

  const isImage = useMemo(() => mimeTypeIsImage(mimeType), [mimeType]);

  const onMoveUp = useCallback(() => {
    onReorder(-1);
  }, [onReorder]);

  const onMoveDown = useCallback(() => {
    onReorder(1);
  }, [onReorder]);

  // TODO: Add upload progress
  // const progressPercent = 50;

  return (
    <li
      className={clsx(
        'relative',
        'w-full h-52',
        'rounded-2xl overflow-hidden',
        'flex flex-col',
        isDragPlaceholder ? 'opacity-0' : 'opacity-100',
      )}>
      {/* File preview */}
      <div className="h-full w-full relative bg-brand-50">
        {isImage ? (
          // Image file
          <img
            src={localFile ? URL.createObjectURL(localFile) : url}
            className="h-full w-full object-contain"
          />
        ) : (
          // Generic file
          <div className="relative w-full h-full flex items-center justify-center">
            {status === types.FileItemStatus.DeterminingMimeType ? (
              <SpinningLoader size="sm" color="brand" />
            ) : (
              <HiOutlineDocumentText className="text-brand-200 w-12 h-12" />
            )}
          </div>
        )}
      </div>

      {/* Uploading indicator */}
      {status === types.FileItemStatus.Uploading && (
        <div
          className={clsx(
            'absolute w-full h-full',
            'pointer-events-none',
            'flex items-center justify-center',
            'bg-brand-100/60',
          )}>
          <SpinningLoader size="sm" color="brand" />
        </div>
      )}

      {/* Error indicator */}
      {[
        types.FileItemStatus.ErrorUploading,
        types.FileItemStatus.FileSizeTooLarge,
        types.FileItemStatus.FileTypeNotAllowed,
      ].includes(status) && (
        <div
          className={clsx(
            'absolute w-full h-full',
            'pointer-events-none',
            'flex flex-col gap-y-2 items-center justify-center',
            'bg-red-100/80 text-red-500 border-red-500 border-3 rounded-2xl',
          )}>
          <HiOutlineExclamationCircle className="w-12 h-12" />
          <div className="text-center text-sm font-medium">
            {status === types.FileItemStatus.ErrorUploading &&
              'Error uploading file'}
            {status === types.FileItemStatus.FileSizeTooLarge &&
              'File is too large'}
            {status === types.FileItemStatus.FileTypeNotAllowed &&
              'File type not allowed'}
          </div>
        </div>
      )}

      {/* File name */}
      {localFile && (
        <div className={clsx('absolute bottom-2 left-2 right-2')}>
          <div
            className={clsx(
              'p-2',
              'rounded-lg bg-white drop-shadow-md',
              'text-xs leading-none',
              'text-brand-850 text-opacity-70',
            )}>
            {localFile.name}
          </div>
        </div>
      )}

      {/* Upload progress bar */}
      {/* <div className={clsx('absolute bottom-0 left-0 h-2 w-full ')}>
        <Progress.Root
          className="relative overflow-hidden rounded-full w-full h-2"
          style={{
            // Fix overflow clipping in Safari
            // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
            transform: 'translateZ(0)',
          }}
          value={progressPercent}>
          <Progress.Indicator
            className={clsx(
              'bg-brand-500',
              'w-full h-full',
              'rounded-r-full',
              'transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]',
            )}
            style={{transform: `translateX(-${100 - progressPercent}%)`}}
          />
        </Progress.Root>
      </div> */}

      {/* Top items */}
      {![
        types.FileItemStatus.Uploading,
        types.FileItemStatus.DeterminingMimeType,
      ].includes(status) && (
        <div className="absolute top-2 left-2 right-2 flex flex-row gap-x-2 justify-between">
          {/* Reorder buttons */}
          <div className="flex flex-row gap-x-2">
            <Button
              format="icon"
              icon={HiOutlineArrowUp}
              disabled={isFirst || !onReorder}
              category="primary"
              size="sm"
              mode="manual"
              fillWidth={false}
              onClick={onMoveUp}
            />
            <Button
              format="icon"
              icon={HiOutlineArrowDown}
              disabled={isLast || !onReorder}
              category="primary"
              size="sm"
              mode="manual"
              fillWidth={false}
              onClick={onMoveDown}
            />
          </div>
          {/* Main file indicator */}
          <AnimatePresence>
            {isMain && (
              <motion.div
                transition={{
                  type: 'spring',
                  duration: 0.3,
                }}
                initial={{scale: 0}}
                animate={{scale: 1}}
                exit={{scale: 0}}
                className={clsx(
                  'origin-center',
                  'px-2 py-1',
                  'rounded-lg drop-shadow-md',
                  'flex flex-row items-center justify-center gap-x-1',
                  'bg-yellow-400 text-white',
                )}>
                <HiStar className="w-4 h-4" />
                <span className="text-sm leading-none font-medium">Main</span>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Delete button */}
          <div className="ml-auto">
            <Button
              format="icon"
              icon={HiOutlineX}
              disabled={!onDelete}
              category="danger"
              size="sm"
              mode="manual"
              fillWidth={false}
              onClick={onDelete}
            />
          </div>
        </div>
      )}
    </li>
  );
};

export default FileItem;
