import {useCallback, useEffect, useState} from 'react';

import bankAccountValidator from '@fnzc/nz-bank-account-validator';
import {type FormikHelpers, useFormik} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import StepContainer from 'components/walkthrough/common/StepContainer';
import {TextField} from 'components_sb/input';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  bankAccountName: string;
  bankAccountNumber: string;
};

const BankDetailsStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.MigrateTenancy_StartRentCollectionStep,
      {
        propertyId: property.id,
      },
    );
  }, [scrollToTop, property.id]);

  const queryClient = useQueryClient();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);

    /**
     * Set changes on the property.
     */
    property.assignAttributes(formValues);
    property.lastOnboardingStepCompleted = 'new_bank_details';

    /**
     * Save the changes to the property.
     */
    const savedProperty = await saveResource(property);

    if (savedProperty) {
      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.MigrateTenancy_CompleteRentCollectionStep,
        {
          propertyId: property.id,
        },
      );
    } else {
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    setSubmitting(false);
  };

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const form = useFormik<FormValues>({
    initialValues: {
      bankAccountName: property.bankAccountName || '',
      bankAccountNumber: property.bankAccountNumber || '',
    } as FormValues,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      bankAccountName: Yup.string().min(2).required().label('Account Name'),
      bankAccountNumber: Yup.string()
        .required()
        .test(
          'is-valid-format',
          'Must be a valid NZ account. Please include dashes.',
          (value) => {
            if (!value) return false;

            const parts = value.split('-');
            if (parts.length === 4) {
              return bankAccountValidator.isValidNZBankNumber(
                parts[0],
                parts[1],
                parts[2],
                parts[3],
              );
            } else {
              return false;
            }
          },
        )
        .label('Account Number'),
    }),
  });

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      title="Enter your rent collecting account"
      subtitle="Where does rent get deposited?">
      <div className="flex w-full">
        <TextField
          label="Account Name"
          labelSize="xl"
          name="bankAccountName"
          mode="formik"
          form={form}
          placeholder="E.g. John Smith"
        />
      </div>

      <div className="flex w-full">
        <TextField
          label="Account Number (include dashes)"
          labelSize="xl"
          name="bankAccountNumber"
          mode="formik"
          form={form}
          placeholder="E.g. 02-1234-5678900-00"
        />
      </div>
    </StepContainer>
  );
};

export default BankDetailsStep;
