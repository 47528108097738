import {useCallback, useMemo} from 'react';

import {HiOutlineCreditCard} from '@react-icons/all-files/hi/HiOutlineCreditCard';
import {HiOutlinePencil} from '@react-icons/all-files/hi/HiOutlinePencil';
import {HiOutlineTrash} from '@react-icons/all-files/hi/HiOutlineTrash';
import clsx from 'clsx';
import Tilt from 'react-parallax-tilt';
import colors from 'tailwindcss/colors';

import ChipImage from 'assets/img/credit_cards/chip.png';
import AmericanExpressLogo from 'assets/img/credit_cards/issuer_logos/american-express-white.png';
import DinersClubLogo from 'assets/img/credit_cards/issuer_logos/diners-club-white.png';
import DiscoverLogo from 'assets/img/credit_cards/issuer_logos/discover-white.png';
import JCBLogo from 'assets/img/credit_cards/issuer_logos/jcb-white.png';
import MastercardLogo from 'assets/img/credit_cards/issuer_logos/mastercard-white.png';
import VisaLogo from 'assets/img/credit_cards/issuer_logos/visa-white.png';
import {Button} from 'components_sb/buttons';
import {Modal} from 'components_sb/layout';
import BillingMethod, {
  type BillingMethodMetadata,
} from 'models/billing/BillingMethod';

import BillingMethodSummary from './BillingMethodSummary';
import RemoveBillingMethodConfirmationModal from './RemoveBillingMethodConfirmationModal';
import RenameBillingMethodModal from './RenameBillingMethodModal';

const {useModal} = Modal.Imperative;

interface PinCardSummary {
  (props: {
    billingMethod: BillingMethod;
    isDefault?: boolean;
    selectable?: boolean;
    selected?: boolean;
    onSelect?: () => void;
  }): JSX.Element;
}

const ISSUER_STYLES = {
  visa: {
    logo: VisaLogo,
    color: colors.blue[900],
  },
  master: {
    logo: MastercardLogo,
    color: colors.orange[600],
  },
  american_express: {
    logo: AmericanExpressLogo,
    color: colors.sky[600],
  },
  jcb: {
    logo: JCBLogo,
    color: colors.yellow[500],
  },
  discover: {
    logo: DiscoverLogo,
    color: colors.orange[700],
  },
  diners: {
    logo: DinersClubLogo,
    color: colors.sky[600],
  },
};

interface CardDetails {
  (props: {metadata: BillingMethodMetadata}): JSX.Element;
}

const CardDetails: CardDetails = ({metadata}) => {
  console.log();

  const {logo: Logo, color} = ISSUER_STYLES[metadata.scheme] ?? {
    logo: null,
    color: colors.neutral[500],
  };

  const last4Digits = useMemo(
    () => metadata.display_number.slice(-4),
    [metadata.display_number],
  );

  return (
    <Tilt
      className="w-60 relative"
      tiltMaxAngleX={15}
      tiltMaxAngleY={15}
      tiltReverse
      glareEnable
      glareMaxOpacity={0.4}>
      <div
        className={clsx(
          'w-full',
          'px-4 py-2',
          'rounded-lg',
          'shadow-xl',
          'flex flex-col',
          'gap-y-5',
          'justify-evenly',
          'whitespace-nowrap',
          'text-sm font-medium',
          'text-white',
          'select-none',
        )}
        style={{
          backgroundColor: color,
        }}>
        {/* Top row */}
        <div className="flex flex-row gap-x-2 justify-between items-center">
          {/* Chip */}
          <div>
            <img src={ChipImage} alt="Chip" className="h-5" />
          </div>
          {/* Issuer logo */}
          <div>
            {Logo && <img src={Logo} alt="Issuer Logo" className="h-8" />}
          </div>
        </div>
        {/* Card number */}
        <div className="flex flex-row gap-x-2 text-base">
          <span>****</span>
          <span>****</span>
          <span>****</span>
          <span>{last4Digits}</span>
        </div>
        {/* Bottom row */}
        <div className="flex flex-row gap-x-4 justify-between items-start">
          {/* Cardholder name */}
          <div className="flex flex-col">
            <span className="text-xxs opacity-70">Cardholder name</span>
            <span className="text-xs truncate">{metadata.name}</span>
          </div>
          {/* Expiry */}
          <div className="flex flex-col">
            <span className="text-xxs opacity-70">Expiry</span>
            <span className="text-xs">{`${metadata.expiry_month}/${metadata.expiry_year}`}</span>
          </div>
        </div>
      </div>
    </Tilt>
  );
};

const PinCardSummary: PinCardSummary = ({
  billingMethod,
  /**
   * TODO: Remove the isDefault prop once the billing method
   * instance itself knows whether it is the default.
   */
  isDefault = false,
  selectable = false,
  selected = false,
  onSelect = () => {
    ('');
  },
}) => {
  const openModal = useModal();

  const nickname = useMemo(
    () =>
      billingMethod.nickname ? billingMethod.nickname : 'Credit/Debit Card',
    [billingMethod.nickname],
  );

  const onRenameCard = useCallback(
    () =>
      openModal(RenameBillingMethodModal, {
        billingMethod,
      }),
    [openModal, billingMethod],
  );

  // const onReplaceCard = useCallback(() => {
  //   // TODO: Add new card and delete old one
  // }, []);

  const onRemoveCard = useCallback(
    () =>
      openModal(RemoveBillingMethodConfirmationModal, {
        billingMethod,
      }),
    [openModal, billingMethod],
  );

  return (
    <BillingMethodSummary
      title={nickname}
      icon={HiOutlineCreditCard}
      properties={[]}
      isDefault={isDefault}
      selectable={selectable}
      selected={selected}
      onSelect={onSelect}
      actions={
        <>
          <Button
            label="Rename"
            icon={HiOutlinePencil}
            category="tertiary"
            size="sm"
            fillWidth={false}
            mode="manual"
            onClick={onRenameCard}
          />
          {/* <Button
            label="Replace"
            icon={MdSwapHoriz}
            category="tertiary"
            size="sm"
            fillWidth={false}
            mode="manual"
            onClick={onReplaceCard}
          /> */}
          <Button
            label="Remove"
            icon={HiOutlineTrash}
            category="tertiary"
            size="sm"
            fillWidth={false}
            mode="manual"
            onClick={onRemoveCard}
          />
        </>
      }>
      <CardDetails metadata={billingMethod.metadata} />
      {/* <div>issuer</div>
      <div>credit/debit</div>
      <div>last 4 digits</div>
      <div>expires</div> */}
    </BillingMethodSummary>
  );
};

export default PinCardSummary;
