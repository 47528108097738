import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

interface SubmitButtonProps extends HTMLProps<HTMLButtonElement> {
  formik: FormikProps<any>;
  text: string;
  submittingText: string;
  block?: boolean;
}

const SubmitButton = ({
  formik,
  color = 'primary',
  text,
  submittingText,
  block = true,
  ...rest
}: SubmitButtonProps) => {
  if (!rest.className) {
    rest.className = 'mt-3';
  }
  if (block) {
    rest.className += ' btn-block';
  }
  if (formik.isSubmitting) {
    rest.className += ' loading';
  }
  if (!submittingText) {
    submittingText = text;
  }

  // TODO: Use Button component for consistent styling
  return (
    <button
      className={`btn btn-${color} ${rest.className}`}
      disabled={formik.isSubmitting}
      type={'submit'}>
      {formik.isSubmitting ? submittingText : text}
    </button>
  );

  // if (Object.keys(formik.errors).length > 0 && formik.submitCount > 0) {
  //   return (
  //     <button
  //       className={`btn btn-error text-white ${block ? 'btn-block' : ''}`}
  //       disabled={formik.isSubmitting}
  //       type={'submit'}>
  //       Please address the errors above and try again
  //     </button>
  //   );
  // } else {
  //   return (
  //     <button
  //       className={`btn btn-${color} ${rest.className}`}
  //       disabled={formik.isSubmitting}
  //       type={'submit'}>
  //       {formik.isSubmitting ? submittingText : text}
  //     </button>
  //   );
  // }
};

export default SubmitButton;
