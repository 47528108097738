import {useCallback, useImperativeHandle} from 'react';

import {ModalDefinition} from 'components_sb/layout';

const EndTourConfirmation: ModalDefinition = {
  title: 'Are you sure you want to end this tour?',
  buttonsConfig: {
    cancel: {
      label: 'No, continue tour',
    },
    actions: [
      {
        id: 'exit-tour',
        label: 'Yes, end tour',
        handle: 'onEndTour',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const onCancel = useCallback(() => false, []);
    const onEndTour = useCallback(() => true, []);

    useImperativeHandle(ref, () => ({
      onCancel,
      onEndTour,
    }));

    return null;
  },
};

export default EndTourConfirmation;
