import React, {lazy, Suspense, useState} from 'react';

import _ from 'lodash';

import Action from 'components/inspection/actions/Action';
import Inspection from 'models/inspections/Inspection';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItem from 'models/inspections/InspectionItem';
import {titleize} from 'utilities/StringHelpers';

const FsLightbox = lazy(() => import('fslightbox-react'));

const ActionsList = ({
  inspection,
  item,
}: {
  inspection: Inspection;
  item: InspectionItem;
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const actions = item.inspectionActions;

  const landlordActions = actions.filter(
    (a) => a.userId === inspection?.tenancy.property.landlordId,
  );

  const tenantActions = actions.filter(
    (a) => a.userId !== inspection?.tenancy.property.landlordId,
  );

  const handleClick = (action: InspectionAction) => {
    const index = actions.findIndex((a) => a.id === action.id);
    setSelectedIndex(index + 1);
    setLightboxOpen(!lightboxOpen);
  };

  if (actions.length > 0) {
    if (inspection.doneBy === 'landlord') {
      const groupedTenantActions = _.groupBy(tenantActions, (a) => a.user.name);

      return (
        <div>
          {landlordActions.length > 0 && (
            <div className="mt-2">
              {landlordActions.map((action) => (
                <Action
                  action={action}
                  key={action.id}
                  onClick={() => handleClick(action)}
                />
              ))}
            </div>
          )}

          {tenantActions.length > 0 && (
            <div className="mt-2">
              {Object.entries(groupedTenantActions).map(
                ([name, actionGroup]) => (
                  <div key={name} className="mt-2">
                    <strong>Added by {name}</strong>

                    {actionGroup.map((action) => (
                      <Action
                        action={action}
                        key={action.id}
                        onClick={() => handleClick(action)}
                      />
                    ))}
                  </div>
                ),
              )}
            </div>
          )}

          <Suspense fallback={<div />}>
            <FsLightbox
              toggler={lightboxOpen}
              sources={actions.map((a) => a.attachment)}
              thumbs={actions.map((a) => a.attachmentThumbnail)}
              slide={selectedIndex}
              captions={actions.map((a) => (
                <div key={a.id} className="flex flex-col">
                  <h5 className="text-white text-lg">
                    {item.room} - {item.name}
                  </h5>
                  <p className="text-white text-md">
                    <strong>{titleize(a.actionType)} Action: </strong>
                    {a.action}
                  </p>
                </div>
              ))}
            />
          </Suspense>
        </div>
      );
    } else {
      return (
        <div>
          {tenantActions.length > 0 && (
            <div className="mt-2">
              {tenantActions.map((action) => (
                <Action
                  action={action}
                  key={action.id}
                  onClick={() => handleClick(action)}
                />
              ))}
            </div>
          )}

          {landlordActions.length > 0 && (
            <div className="mt-2">
              <strong>Added by {landlordActions[0].user.name}</strong>
              {landlordActions.map((action) => (
                <Action
                  action={action}
                  key={action.id}
                  onClick={() => handleClick(action)}
                />
              ))}
            </div>
          )}

          <Suspense fallback={<div />}>
            <FsLightbox
              toggler={lightboxOpen}
              sources={actions.map((a) => a.attachment)}
              thumbs={actions.map((a) => a.attachmentThumbnail)}
              slide={selectedIndex}
              captions={actions.map((a) => (
                <div key={a.id} className="flex flex-col">
                  <h5 className="text-white text-lg">
                    {item.room} - {item.name}
                  </h5>
                  <p className="text-white text-md">
                    <strong>{titleize(a.actionType)} Action: </strong>
                    {a.action}
                  </p>
                </div>
              ))}
            />
          </Suspense>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default ActionsList;
