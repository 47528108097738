import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import BillingMethodCharge from 'models/billing/BillingMethodCharge';
import Property from 'models/properties/Property';
import User from 'models/users/User';

/**
 * These are the card issuers supported by Pin Payments.
 */
export type SupportedIssuer =
  | 'visa' // Visa
  | 'master' // Mastercard
  | 'american_express' // American Express
  | 'jcb' // JCB
  | 'discover' // Discover
  | 'diners'; // Diners Club

export interface BillingMethodMetadata {
  scheme: SupportedIssuer;
  name: string;
  display_number: string;
  expiry_year: number;
  expiry_month: number;
}

@Model()
class BillingMethod extends ApplicationRecord {
  static jsonapiType = 'billing_methods';

  @Attr() nickname: string;
  @Attr() billableType: string;
  @Attr() provider: string;
  @Attr() token: string;
  @Attr() billableId: string;
  @Attr() metadata: BillingMethodMetadata;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('users') user: User;
  @HasMany('billing_method_charges')
  billingMethodCharges: BillingMethodCharge[];
  @HasMany('properties') properties: Property[];
}

export default BillingMethod;
