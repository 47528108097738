import {Model, Attr} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';

@Model()
class FacebookGroup extends ApplicationRecord {
  static jsonapiType = 'facebook_groups';

  @Attr({persist: false}) name: string;
  @Attr({persist: false}) facebookId: string;
  @Attr({persist: false}) totalMembers: number;
  @Attr({persist: false}) city: string;
  @Attr({persist: false}) region: string;
  @Attr({persist: false}) allowsCommercialPosts: boolean;
  @Attr({persist: false}) petFriendly: boolean;
  @Attr({persist: false}) private: boolean;
}

export default FacebookGroup;
