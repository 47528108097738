import {LOGGED_IN, USER_IS_LANDLORD, USER_IS_RENTER} from './conditions';
import type {NavItem} from './types';

/**
 * Static configuration for nav link items.
 */
const NAV_ITEMS: NavItem[] = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    conditions: [LOGGED_IN],
    linkTo: '/',
  },
  {
    id: 'chat',
    label: 'Chat',
    conditions: [LOGGED_IN],
    linkTo: '/chat',
  },
  {
    id: 'financials',
    label: 'Financials',
    conditions: [USER_IS_LANDLORD],
    linkTo: '/financials',
  },
  {
    id: 'listings',
    label: 'Listings',
    subItems: [
      {
        id: 'view',
        label: 'View Listings',
        linkTo: '/listings',
      },
      {
        id: 'enquiries',
        label: 'Enquiries',
        linkTo: '/enquiries',
        conditions: [USER_IS_RENTER],
      },
      {
        id: 'rental-applications',
        label: 'Applications',
        linkTo: '/rental-applications',
        conditions: [USER_IS_RENTER],
      },
      {
        id: 'viewings',
        label: 'Open Homes / Viewings',
        linkTo: '/viewings',
        conditions: [USER_IS_RENTER],
      },
    ],
  },
  {
    id: 'help',
    label: 'Help',
    linkTo: 'https://help.keyhook.com',
  },
];

export default NAV_ITEMS;
