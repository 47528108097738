import {FunctionComponent, ReactNode} from 'react';

import clsx from 'clsx';
import {type FormikProps} from 'formik';
import NumericFormat from 'react-number-format';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import {InlineError} from 'components_sb/feedback';
import {FieldLabel} from 'components_sb/typography';

interface InlineMoneyFieldProps {
  formik: FormikProps<any>;
  label: string;
  name: string;
  placeholder?: string;
  additional?: ReactNode;
}

type InlineMoneyFieldComponent = FunctionComponent<InlineMoneyFieldProps>;

const InlineMoneyField: InlineMoneyFieldComponent = ({
  formik,
  label,
  name,
  placeholder,
  additional,
}) => {
  const errors = errorsFor(formik, name);
  const value = valueFor(formik, name);

  return (
    <div className="flex flex-col w-full">
      <div>
        <FieldLabel title={label} size="xl" />
        <div className="flex flex-row gap-x-4 items-center">
          <NumericFormat
            value={value}
            thousandSeparator=","
            allowNegative={false}
            className={clsx(
              'w-full max-w-full box-border', // Size
              'transition-all duration-300', // Transition
              'bg-brand-50 hover:bg-brand-75 ', // Background
              'placeholder-brand-800 placeholder-opacity-30', // Placeholder
              'outline-none border-none ring-1 ring-brand-75', // Untouched
              'focus:ring-2 focus:ring-brand-500', // Focus,
              // Text
              'text-lg',
              // Padding
              'px-6',
              // Height
              'h-14',
              // Roundness
              'rounded-xl',
            )}
            onValueChange={(values) => formik.setFieldValue(name, values.value)}
            placeholder={placeholder ?? ''}
            prefix="$"
            decimalScale={2}
            type="tel"
          />
          {additional}
        </div>
      </div>
      <InlineError error={errors} />
    </div>
  );
};

export default InlineMoneyField;
