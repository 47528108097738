import {Navigate, Route} from 'react-router';

import AccountingRecordIndexPage from 'pages/landlord/accounting/AccountingRecordIndexPage';
import EditDocumentPage from 'pages/landlord/documents/EditDocumentPage';
import NewDocumentPage from 'pages/landlord/documents/NewDocumentPage';
import EditEndOfTenancyPage from 'pages/landlord/end_of_tenancies/EditEndOfTenancyPage';
import EnquiryThreadDetailPage from 'pages/landlord/enquiry_threads/EnquiryThreadDetailPage';
import EnquiryThreadIndexPage from 'pages/landlord/enquiry_threads/EnquiryThreadIndexPage';
import EditInspectionPage from 'pages/landlord/inspections/EditInspectionPage';
import NewInspectionPage from 'pages/landlord/inspections/NewInspectionPage';
import LandlordPaymentDetailPage from 'pages/landlord/landlord_payments/LandlordPaymentDetailPage';
import LandlordPaymentIndexPage from 'pages/landlord/landlord_payments/LandlordPaymentIndexPage';
import EditListingPage from 'pages/landlord/listings/EditListingPage';
import ListingDetailPage from 'pages/landlord/listings/ListingDetailPage';
import ListingIndexPage from 'pages/landlord/listings/ListingIndexPage';
import EditPrivateViewingPage from 'pages/landlord/listings/viewings/private/EditPrivateViewingPage';
import NewPrivateViewingPage from 'pages/landlord/listings/viewings/private/NewPrivateViewingPage';
import PrivateViewingDetailPage from 'pages/landlord/listings/viewings/private/PrivateViewingDetailPage';
import EditOpenHomePage from 'pages/landlord/listings/viewings/public/EditOpenHomePage';
import NewOpenHomePage from 'pages/landlord/listings/viewings/public/NewOpenHomePage';
import OpenHomeDetailPage from 'pages/landlord/listings/viewings/public/OpenHomeDetailPage';
import ViewingsIndexPage from 'pages/landlord/listings/viewings/ViewingsIndexPage';
import EditPreferredTradesmanPage from 'pages/landlord/preferred_tradesmen/EditPreferredTradesmanPage';
import NewPreferredTradesmanPage from 'pages/landlord/preferred_tradesmen/NewPreferredTradesmanPage';
import PreferredTradesmanIndexPage from 'pages/landlord/preferred_tradesmen/PreferredTradesmanIndexPage';
import EditBankAccountPage from 'pages/landlord/properties/EditBankAccountPage';
import EditPropertyPage from 'pages/landlord/properties/EditPropertyPage';
import PropertyDetailPage from 'pages/landlord/properties/PropertyDetailPage';
import PropertyIndexPage from 'pages/landlord/properties/PropertyIndexPage';
import NewRentPriceChangePage from 'pages/landlord/rent_price_changes/NewRentPriceChangePage';
import RentalApplicationDetailPage from 'pages/landlord/rental_applications/RentalApplicationDetailPage';
import RentalApplicationIndexPage from 'pages/landlord/rental_applications/RentalApplicationIndexPage';
import EditRoomPlanPage from 'pages/landlord/room_plans/EditRoomPlanPage';
import EditServiceRequestPage from 'pages/landlord/service_requests/EditServiceRequestPage';
import NewServiceRequestPage from 'pages/landlord/service_requests/NewServiceRequestPage';
import ServiceRequestDetailPage from 'pages/landlord/service_requests/ServiceRequestDetailPage';
import EditTenancyRenewalPage from 'pages/landlord/tenancy_renewals/EditTenancyRenewalPage';
import EditTenancyRequestPage from 'pages/landlord/tenancy_requests/EditTenancyRequestPage';
import FormalNoticeIndexPage from 'pages/shared/formal_notices/FormalNoticeIndexPage';
import NewFormalNoticePage from 'pages/shared/formal_notices/NewFormalNoticePage';
import InspectionActionDetailPage from 'pages/shared/inspections/InspectionActionDetailPage';
import InspectionAmendmentsPage from 'pages/shared/inspections/InspectionAmendmentsPage';
import InspectionDetailPage from 'pages/shared/inspections/InspectionDetailPage';
import InspectionRoomDetailPage from 'pages/shared/inspections/InspectionRoomDetailPage';
import NewTenancyInvitePage from 'pages/shared/tenancy_invites/NewTenancyInvitePage';

const LandlordRoutes = [
  <Route path="financials" key={3}>
    <Route path=":id" element={<LandlordPaymentDetailPage />} />
    <Route path="" element={<AccountingRecordIndexPage />} />
  </Route>,
  <Route path="payments" key={4}>
    <Route path="" element={<Navigate to="/financials" />} />
  </Route>,
  <Route path="properties" key={2}>
    <Route path="" element={<PropertyIndexPage />} key={1} />
    <Route path=":propertyId/tenancies/:tenancyId/payments">
      <Route path=":id" element={<LandlordPaymentDetailPage />} />
      <Route path="" element={<LandlordPaymentIndexPage />} />
    </Route>
    <Route path=":propertyId/service-requests">
      <Route path=":id/edit" element={<EditServiceRequestPage />} />
      <Route path=":id" element={<ServiceRequestDetailPage />} />
    </Route>
    <Route
      path=":propertyId/tenancies/:tenancyId/service-requests/new"
      element={<NewServiceRequestPage />}
    />
    <Route path=":propertyId/inspections">
      <Route
        path=":inspectionId/actions/:actionId"
        element={<InspectionActionDetailPage />}
      />
      <Route
        path=":inspectionId/revisions"
        element={<InspectionAmendmentsPage />}
      />
      <Route path=":id/edit" element={<EditInspectionPage />} />
      <Route
        path=":inspectionId/:roomKey"
        element={<InspectionRoomDetailPage />}
      />
      <Route path="new" element={<NewInspectionPage />} />
      <Route path=":id" element={<InspectionDetailPage />} />
    </Route>
    <Route path=":propertyId/documents">
      <Route path=":id/edit" element={<EditDocumentPage />} />
      <Route path="new" element={<NewDocumentPage />} />
    </Route>
    <Route path=":propertyId/preferred-tradespeople">
      <Route path="new" element={<NewPreferredTradesmanPage />} />
      <Route path=":id/edit" element={<EditPreferredTradesmanPage />} />
      <Route path="" element={<PreferredTradesmanIndexPage />} />
    </Route>
    <Route path=":propertyId/listings">
      <Route path=":listingId">
        <Route path="enquiries">
          <Route path=":id" element={<EnquiryThreadDetailPage />} />
          <Route path="" element={<EnquiryThreadIndexPage />} />
        </Route>

        <Route path="applications">
          <Route path=":id" element={<RentalApplicationDetailPage />} />
          <Route path="" element={<RentalApplicationIndexPage />} />
        </Route>

        <Route path="viewings">
          <Route index element={<ViewingsIndexPage />} />
          <Route path="public">
            <Route index element={<Navigate to=".." />} />
            <Route path="new" element={<NewOpenHomePage />} />
            <Route path=":id/edit" element={<EditOpenHomePage />} />
            <Route path=":id" element={<OpenHomeDetailPage />} />
          </Route>
          <Route path="private">
            <Route index element={<Navigate to=".." />} />
            <Route path="new" element={<NewPrivateViewingPage />} />
            <Route
              path=":privateViewingId/edit"
              element={<EditPrivateViewingPage />}
            />
            <Route
              path=":privateViewingId"
              element={<PrivateViewingDetailPage />}
            />
          </Route>
        </Route>

        <Route path="edit" element={<EditListingPage />} />

        <Route path="" element={<ListingDetailPage />} />
      </Route>
      <Route path="" element={<ListingIndexPage />} />
    </Route>
    <Route path=":propertyId/tenancies">
      <Route path=":id/bond-refund" element={<EditEndOfTenancyPage />} />

      <Route path=":id/rent-changes">
        <Route path="new" element={<NewRentPriceChangePage />} />
      </Route>

      <Route path=":id/invite-tenants" element={<NewTenancyInvitePage />} />

      <Route path=":tenancyId/tenancy-renewals/:id">
        <Route path="edit" element={<EditTenancyRenewalPage />} />
      </Route>

      <Route path=":tenancyId/notices">
        <Route path="new" element={<NewFormalNoticePage />} />
        <Route path="" element={<FormalNoticeIndexPage />} />
      </Route>
    </Route>
    <Route
      path=":propertyId/tenancy-requests/:id/edit"
      element={<EditTenancyRequestPage />}
    />
    <Route
      path=":propertyId/inspection-template"
      element={<EditRoomPlanPage />}
    />
    <Route path=":id/edit" element={<EditPropertyPage />} />
    <Route path=":id/bank-account" element={<EditBankAccountPage />} />
    <Route path=":id" element={<PropertyDetailPage />} />
  </Route>,
  <Route path="" element={<PropertyIndexPage />} key={1} />,
];

export default LandlordRoutes;
