import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';
import {omit} from 'lodash';

import {errorsFor, valueFor, isTouched} from 'components/forms_fields/Helpers';
import {InlineError} from 'components_sb/feedback';
import Input from 'components_sb/primitives/Input/Input';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';
import TrackingService from 'services/TrackingService';

interface InputFieldProps
  extends Omit<HTMLProps<HTMLInputElement>, 'label' | 'size'> {
  formik: FormikProps<any>;
  name: string;
  prefixLabel?: string;
  labelProps?: FieldLabelProps;
}

const InputField = ({
  formik,
  name,
  prefixLabel,
  labelProps,
  children,
  ...rest
}: InputFieldProps) => {
  const errors = errorsFor(formik, name);
  const value = valueFor(formik, name);
  const touched = isTouched(formik, name);

  const classN = errors && touched ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }
  rest.className = 'input input-bordered w-full ' + rest.className;

  if (!rest.type) {
    rest.type = 'text';
  }

  const handleBlur = () => {
    formik.handleBlur(name);

    const v = value as string | undefined | null;
    if (v && v.toString().length > 0) {
      TrackingService.trackEvent(TrackingService.Event.FillField, {
        field: name,
      });
    }
  };

  return (
    <div className="form-control">
      {labelProps && <FieldLabel {...labelProps} />}
      <div className="flex flex-row items-center gap-x-2">
        {prefixLabel ? (
          <span className="text-brand-850 opacity-70">{prefixLabel}</span>
        ) : null}
        <Input
          name={name}
          onChange={(e) => formik.setFieldValue(name, e.target.value)}
          value={value}
          onBlur={handleBlur}
          {...omit(rest, ['className'])}
        />
      </div>
      {children}
      <InlineError error={errors} />
    </div>
  );
};

export default InputField;
