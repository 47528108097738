import {FunctionComponent, ReactNode} from 'react';

import clsx from 'clsx';

import {Paragraph, Title} from 'components_sb/typography';

interface StepContainerProps {
  children: ReactNode;
  title: string;
  subtitle: string | ReactNode;
  fullWidth?: boolean;
  align?: 'left' | 'center';
}

const StepContainer: FunctionComponent<StepContainerProps> = ({
  children,
  title,
  subtitle,
  fullWidth,
  align = 'left',
}) => {
  return (
    <div
      className={clsx(
        'bg-white',
        'overflow-hidden',
        'flex-1 w-full',
        'justify-center',
        'flex flex-col',
        'pt-8 md:pt-12 lg:pt-14',
        'py-10 md:py-14 lg:pb-16',
        'px-6 lg:px-8',
        'gap-y-8 md:gap-y-12',
      )}>
      {/* Title / Subtitle section */}
      <div
        className={clsx(
          'flex flex-col gap-y-2',
          'items-center',
          'text-center',
          'text-brand-850',
        )}>
        <Title level="h1" size="xl">
          {title}
        </Title>
        {subtitle && <Paragraph>{subtitle}</Paragraph>}
      </div>

      {/* Step content */}
      <div
        className={clsx(
          'w-full',
          'mx-auto',
          'flex flex-col',
          'gap-y-8',
          'justify-start',
          'relative',
          fullWidth ? 'max-w-7xl' : 'max-w-lg',
          align === 'left' ? 'items-start' : 'items-center',
        )}>
        {children}
      </div>
    </div>
  );
};

export default StepContainer;
