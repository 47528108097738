import React from 'react';

import chat from 'assets/img/empty-content-placeholders/chat.png';
import creditCards from 'assets/img/empty-content-placeholders/credit-cards.png';
import documents from 'assets/img/empty-content-placeholders/documents.png';
import financials from 'assets/img/empty-content-placeholders/financials.png';
import images from 'assets/img/empty-content-placeholders/images.png';
import inbox from 'assets/img/empty-content-placeholders/inbox.png';
import inspections from 'assets/img/empty-content-placeholders/inspections.png';
import location from 'assets/img/empty-content-placeholders/location.png';
import maintenance from 'assets/img/empty-content-placeholders/maintenance.png';
import people from 'assets/img/empty-content-placeholders/people.png';
import property from 'assets/img/empty-content-placeholders/property.png';
import relax from 'assets/img/empty-content-placeholders/relax.png';

interface EmptyContentPlaceholderProps {
  type:
    | 'chat'
    | 'credit-cards'
    | 'documents'
    | 'financials'
    | 'images'
    | 'inbox'
    | 'inspections'
    | 'location'
    | 'maintenance'
    | 'people'
    | 'property'
    | 'relax';
  message?: string;
}

const placeholders = {
  chat,
  'credit-cards': creditCards,
  documents,
  financials,
  images,
  inbox,
  inspections,
  location,
  maintenance,
  people,
  property,
  relax,
};

/**
 * A placeholder to be displayed when there is no content available.
 */
const EmptyContentPlaceholder = ({
  type,
  message,
}: EmptyContentPlaceholderProps) => {
  const placeholder = placeholders[type];

  if (!placeholder) {
    throw new Error(`Placeholder type "${type}" is not supported.`);
  }

  return (
    <div className="flex flex-col items-center justify-center px-10 pt-6 pb-14 gap-y-2 opacity-70">
      <img src={placeholder} className="h-24" />
      <p className="text-brand-200 text-sm text-center">
        {message ?? 'There are currently no items to display'}
      </p>
    </div>
  );
};

export default EmptyContentPlaceholder;
