import React, {useMemo} from 'react';

import moment from 'moment';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import {Card, TableComponents} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import FormalNotice from 'models/properties/FormalNotice';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const FormalNoticeIndexPage = () => {
  const {tenancyId} = useParams();

  const {data, isLoading, error} = useQuery(
    `tenancy-${tenancyId}-formal-notices`,
    async () => {
      const notices = await FormalNotice.includes('sending_user')
        .where({tenancy_id: tenancyId})
        .order({created_at: 'desc'})
        .per(100)
        .all();
      return notices.data;
    },
  );

  const pageActions = useMemo(() => {
    return [
      {
        label: 'Give notice',
        linkTo: `new`,
      },
    ];
  }, []);

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Notices" backEnabled actions={pageActions}>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Notices" backEnabled actions={pageActions}>
        <Card className="mt-4" title="Notices given for this tenancy">
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Sent At</TableHeadItem>
              <TableHeadItem>Notice Type</TableHeadItem>
              <TableHeadItem>Sent By</TableHeadItem>
              <TableHeadItem>Actions</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {data.map((notice) => (
              <TableRow key={notice.id}>
                <TableRowItem>
                  {moment(notice.createdAt).format(DATE_TIME_FORMAT)}
                </TableRowItem>
                <TableRowItem>
                  {titleize(removeUnderscores(notice.noticeType))}
                </TableRowItem>
                <TableRowItem>{notice.sendingUser?.name}</TableRowItem>
                <TableRowItem>
                  {notice.attachment && (
                    <Link to={notice.attachment} openInNewTab>
                      View
                    </Link>
                  )}
                </TableRowItem>
              </TableRow>
            ))}
          </Table>
        </Card>
      </PageWrapper>
    );
  }

  return <div>FormalNoticeIndexPage</div>;
};

export default FormalNoticeIndexPage;
