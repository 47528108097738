import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  Formik,
  Form,
  type FormikProps,
  useFormik,
  FormikConfig,
} from 'formik';
import {useQueryClient} from 'react-query';
import {useReward} from 'react-rewards';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {InputField, TextareaField} from 'components/forms_fields';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {Button} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  title: string | null;
  description: string;
};

const DescriptionStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartDescribePropertyStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const {currentUser} = useAuth();

  const [submitting, setSubmitting] = useState(false);

  const [descriptionRequestIsLoading, setDescriptionRequestIsLoading] =
    useState(false);

  const queryClient = useQueryClient();

  const {reward} = useReward('generateDescriptionReward', 'emoji', {
    emoji: ['⚡'],
  });

  const autogenerateDescription = async (formik: FormikProps<any>) => {
    setDescriptionRequestIsLoading(true);
    const response = await fetch(
      `${API_URL}/listings/${listing.id}/generate_description.json`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Email': currentUser.email,
          'X-User-Token': currentUser.meta.authenticationToken,
        },
      },
    );

    if (response.ok) {
      const data = await response.json();
      formik.setFieldValue('description', data.description);
      reward();
    } else {
      toast.error('There was an error generating a description.');
    }
    setDescriptionRequestIsLoading(false);
  };

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      if (formValues.title && formValues.title.length === 0) {
        formValues.title = null;
      }

      /**
       * Set changes on the listing.
       */
      listing.assignAttributes(formValues);

      /**
       * Save the changes to the listing.
       */
      if (!(await saveResource(listing))) {
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = 'listing_description';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.ListProperty_CompleteDescribePropertyStep,
        {
          propertyId: property.id,
          listingId: listing.id,
        },
      );

      setSubmitting(false);
      actions.setSubmitting(false);
    },
    [listing, property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormValues>>(
    () => ({
      initialValues: {
        description: listing.description || '',
        title: listing.title || null,
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        description: Yup.string().min(100).required().label('Description'),
        title: Yup.string().optional().nullable().label('Title'),
      }),
    }),
    [handleSubmit, listing],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      title="Describe your property"
      subtitle="What are some highlights? What's the neighbourhood like? Please use at least 100 characters.">
      <div className="flex w-full">
        <TextField
          name="title"
          label="Title"
          labelSize="xl"
          size="base"
          mode="formik"
          form={form}
          placeholder="If left blank the address will be used."
        />
      </div>
      <div className="relative flex flex-col w-full">
        <div className="pointer-events-none absolute z-50 w-full h-full top-0 left-0 flex items-center justify-center">
          <span id="generateDescriptionReward" />
        </div>
        <TextareaField
          formik={form}
          name="description"
          labelProps={{title: 'Description', size: 'xl'}}
          rows={8}
          placeholder="This property is..."
        />
        {form.values.description.length > 0 && (
          <span className="block text-xs text-secondary mt-2">
            {form.values.description.length} characters
          </span>
        )}
      </div>

      <div className="flex flex-col w-full gap-y-2">
        <div>
          <Paragraph>
            {descriptionRequestIsLoading
              ? 'This may take a few seconds to generate.'
              : "Click the button below to use Keyhook's smart A.I to write a description for you."}
          </Paragraph>
          {form.values.description.length > 0 && (
            <Paragraph>
              This will overwrite anything you have currently written.
            </Paragraph>
          )}
        </div>
        <Button
          onClick={() => autogenerateDescription(form)}
          label="Generate description"
          loadingLabel="Generating description..."
          mode="manual"
          loading={descriptionRequestIsLoading}
          category="primary"
          size="base"
        />
      </div>
    </StepContainer>
  );
};

export default DescriptionStep;
