import React from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import moment from 'moment';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {DateField, DatetimeField, SubmitButton} from 'components/forms_fields';
import StackedButtonSelectField from 'components/forms_fields/StackedButtonSelectField';
import {Card} from 'components_sb/layout';
import Inspection from 'models/inspections/Inspection';

type FormValues = {
  doneBy: string;
  finalizedDate: string;
  reportType: string;
};

const LandlordScheduleInspectionCard = ({
  inspection,
}: {
  inspection: Inspection;
}) => {
  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    inspection.assignAttributes(formValues);
    inspection.status = 'awaiting_inspection';

    const result = await inspection.save();
    if (result) {
      queryClient.setQueryData(
        `inspection-detail-${inspection.id}`,
        inspection,
      );
      await queryClient.invalidateQueries(
        `inspection-detail-${inspection.id}-items`,
        inspection,
      );
    } else {
      for (const key of Object.keys(inspection.errors)) {
        const message = inspection.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const minDate = moment().toDate();
  const maxDate = moment('2100-01-01').toDate();

  return (
    <div>
      <Formik
        initialValues={
          {
            doneBy:
              inspection.inspectionPeriodType != 'normal' ? 'landlord' : '',
            reportType: '',
            finalizedDate: inspection.estimatedDate,
          } as FormValues
        }
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          doneBy: Yup.string()
            .required()
            .oneOf(['landlord', 'tenant'])
            .label('Photos taken by'),
          reportType: Yup.string()
            .required()
            .oneOf(['detailed', 'basic'])
            .label('Report type'),
          finalizedDate: Yup.string().required().label('Date'),
        })}
        onSubmit={handleSubmit}>
        {(formik) => (
          <Form>
            <Card title="Schedule Inspection" className="mt-4 overflow-visible">
              <StackedButtonSelectField
                label="Report Type"
                helpText="Detailed reports will guide you through a detailed inspection.
                  Basic reports will enable you to inspect the property unguided."
                name="reportType"
                formik={formik}
                options={[
                  {label: 'Detailed', value: 'detailed'},
                  {label: 'Basic', value: 'basic'},
                ]}
              />

              {inspection.inspectionPeriodType === 'normal' && (
                <StackedButtonSelectField
                  label="Photos taken by"
                  helpText="You can now enable your tenant to take inspection photos."
                  name="doneBy"
                  formik={formik}
                  options={[
                    {label: 'Landlord', value: 'landlord'},
                    {label: 'Tenant', value: 'tenant'},
                  ]}
                />
              )}

              <div className="w-full lg:w-1/2">
                {formik.values.doneBy === 'tenant' ? (
                  <DateField
                    label="Choose a date"
                    name="finalizedDate"
                    formik={formik}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                ) : (
                  <DatetimeField
                    label="Choose a date"
                    name="finalizedDate"
                    formik={formik}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputProps={{
                      className: 'input input-bordered w-full',
                    }}
                  />
                )}
              </div>

              <SubmitButton
                className="mt-4"
                formik={formik}
                submittingText="Saving"
                text="Save"
              />
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LandlordScheduleInspectionCard;
