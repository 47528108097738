import {useCallback, useImperativeHandle} from 'react';

import {useFormik} from 'formik';
import type {FormikHelpers} from 'formik';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {InputField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import {TextField} from 'components_sb/input';
import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';

type FormValues = {
  streetAddress: string;
  suburb: string;
  city: string;
  country: string;
  postcode: string;
};

const ManualPropertyAddressModal: ModalDefinition = {
  title: 'Add your property address',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal} = props;

    const navigate = useNavigate();
    const {currentUser} = useAuth();

    const handleSubmit = async (
      formValues: FormValues,
      actions: FormikHelpers<FormValues>,
    ) => {
      const params = new URLSearchParams();
      params.append('street_address', formValues.streetAddress);
      params.append('suburb', formValues.suburb);
      params.append('city', formValues.city);
      params.append('country', formValues.country);
      params.append('postcode', formValues.postcode);

      const url = `${API_URL}/properties/setup_property.json?${params.toString()}`;

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-User-Email': currentUser.email,
            'X-User-Token': currentUser.meta.authenticationToken,
          },
        });

        const data = await response.json();

        if (response.ok) {
          const {property: propertyId} = data;

          /**
           * Track completion of the step.
           */
          TrackingService.trackEvent(
            TrackingService.Event.AddProperty_CompleteAddressStep,
            {
              propertyId,
            },
          );

          /**
           * Track starting the onboarding type selection step.
           */
          TrackingService.trackEvent(
            TrackingService.Event.AddProperty_StartOnboardTypeStep,
            {
              propertyId,
            },
          );

          closeModal();

          localStorage.setItem('new-property-id', propertyId);

          navigate(`/properties/${data.property}`, {
            state: {
              afterAddProperty: true,
            },
          });
        } else {
          toast.error(data.errors.join(', '));
          actions.setSubmitting(false);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const formik = useFormik<FormValues>({
      initialValues: {
        streetAddress: '',
        suburb: '',
        city: '',
        country: 'New Zealand',
        postcode: '',
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object({
        streetAddress: Yup.string()
          .required('Required')
          .label('Street Address'),
        suburb: Yup.string().required('Required').label('Suburb'),
        city: Yup.string().required('Required').label('City'),
        postcode: Yup.string()
          .required('Required')
          .length(4)
          .label('Post Code'),
      }),
    });

    const onSave = useCallback(async () => {
      formik.submitForm();
      return false;
    }, [formik]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <div>
        <Paragraph size="sm" secondary>
          Please use the format Unit/Street Number for houses with apt / unit
          numbers.
        </Paragraph>
        <Paragraph size="sm" secondary>
          E.g. "12/59 Harrington Avenue"
        </Paragraph>

        <div className="mt-4 flex flex-col gap-y-2">
          <TextField
            name="streetAddress"
            label="Street Address"
            placeholder="E.g. 123 Example Street"
            size="base"
            mode="formik"
            form={formik}
          />

          <FormRow>
            <FormRowItem>
              <TextField
                name="suburb"
                label="Suburb"
                placeholder="E.g. Tawa"
                size="base"
                mode="formik"
                form={formik}
              />
            </FormRowItem>
            <FormRowItem>
              <TextField
                name="city"
                label="City"
                placeholder="E.g. Wellington"
                size="base"
                mode="formik"
                form={formik}
              />
            </FormRowItem>
          </FormRow>
          <TextField
            name="postcode"
            label="Post Code"
            placeholder="E.g. 1234"
            size="base"
            mode="formik"
            form={formik}
            type="number"
          />
        </div>
      </div>
    );
  },
};

export default ManualPropertyAddressModal;
