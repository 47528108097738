import React, {useState} from 'react';

import {UserAddIcon} from '@heroicons/react/outline';
import {useQueryClient} from 'react-query';

import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import NewTenancyInviteForm from 'components/tenancy_invites/NewTenancyInviteForm';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';

/* This component is only used for tenancy renewals.
 * It not used in the complete property profile section.
 */

const InviteOtherTenantsAction = ({
  property,
  tenancy,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const dismissAction = async () => {
    property.setProfileStepAsCompleted(storeKey);

    const result = await property.save();

    if (result) {
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'detail-page'}],
        property,
      );
    }
  };

  const title = 'Invite Tenants';
  const subtitle =
    'Invite other tenants who have not yet signed up to Keyhook.';
  const iconBgColor = 'bg-blue-300';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={UserAddIcon}
        iconBgColor={iconBgColor}
        dismissable
        onClick={() => setModalVisible(true)}
        onDismiss={dismissAction}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={UserAddIcon}
        bgColor={iconBgColor}
        form={<NewTenancyInviteForm tenancy={tenancy} />}
      />
    </div>
  );
};

export default InviteOtherTenantsAction;
