import React, {useState} from 'react';

import {BriefcaseIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers, type FormikProps} from 'formik';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {SubmitButton, ToggleField} from 'components/forms_fields';
import InsurancePolicyField from 'components/forms_fields/InsurancePolicyField';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import {InsurancePolicy} from 'models/properties/InsurancePolicy';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {useAutoAnimate} from 'utilities/hooks';
import {isDefined} from 'utilities/MiscHelpers';

const InsurancePoliciesAction = ({
  property,
  storeKey,
  showDismiss = true,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
  showDismiss?: boolean;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    property.assignAttributes(formValues);
    property.setProfileStepAsCompleted(storeKey);

    const result = await property.save();

    if (result) {
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'detail-page'}],
        property,
      );
      toast.success('Property successfully updated!');
      setModalVisible(false);
    } else {
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const dismissAction = async () => {
    property.setProfileStepAsCompleted(storeKey);

    const result = await property.save();

    if (result) {
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'detail-page'}],
        property,
      );
    }
  };

  const title = 'Insurance Details';
  const subtitle = 'Add the current insurance details for this property.';
  const iconBgColor = 'bg-teal-300';

  const [animateInsuranceRef] = useAutoAnimate();

  const handleAddPolicy = (formik: FormikProps<any>) => {
    let policies = formik.values.insurancePolicies;
    if (!policies) {
      policies = [] as InsurancePolicy[];
    }
    policies.push({
      policy_name: '',
      insurer: '',
      excess_amount: 0,
      yearly_cost: '',
    });
    formik.setFieldValue('insurancePolicies', policies);
  };

  const policies = property.insurancePolicies || ([] as InsurancePolicy[]);

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={BriefcaseIcon}
        iconBgColor={iconBgColor}
        dismissable={showDismiss}
        onClick={() => setModalVisible(true)}
        onDismiss={dismissAction}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={BriefcaseIcon}
        bgColor={iconBgColor}
        form={
          <Formik
            initialValues={{
              insuranceCoversTenants: isDefined(property.insuranceCoversTenants)
                ? property.insuranceCoversTenants
                : false,
              insurancePolicies: policies,
            }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              insuranceCoversTenants: Yup.boolean().label(
                'Insurance Covers Tenants',
              ),
              insurancePolicies: Yup.array()
                .of(
                  Yup.object().shape({
                    policy_name: Yup.string()
                      .required()
                      .min(3)
                      .max(40)
                      .label('Policy Name'),
                    insurer: Yup.string()
                      .required()
                      .min(2)
                      .max(40)
                      .label('Insurer'),
                    excess_amount: Yup.number()
                      .min(1)
                      .required()
                      .label('Excess Amount'),
                    yearly_cost: Yup.number()
                      .optional()
                      .nullable()
                      .label('Yearly Cost')
                      .min(0),
                  }),
                )
                .min(0)
                .label('Insurance Policies')
                .optional(),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col justify-center grow space-y-2">
                    <p className="text-sm text-secondary">
                      NZ law requires the insurance information for a property
                      to be included with any leases.
                    </p>

                    <div ref={animateInsuranceRef as any}>
                      {formik.values.insurancePolicies.map(
                        (policy: any, index: number) => (
                          <InsurancePolicyField
                            formik={formik}
                            index={index}
                            key={index}
                          />
                        ),
                      )}
                    </div>

                    {formik.errors.insurancePolicies &&
                      typeof formik.errors.insurancePolicies === 'string' && (
                        <span className="block text-red-500">
                          {formik.errors.insurancePolicies}
                        </span>
                      )}

                    <div className="flex justify-between items-center mt-2">
                      <button
                        className="btn btn-block btn-info"
                        type="button"
                        onClick={() => handleAddPolicy(formik)}>
                        Add A Policy
                      </button>
                    </div>

                    <ToggleField
                      name="insuranceCoversTenants"
                      formik={formik}
                      label="Insurance Covers Tenants?"
                      helpText="There is insurance covering this rental property that is relevant
            to tenant's liability for damage to premises, including damage to
            body corporate facilities."
                    />
                  </div>

                  <SubmitButton
                    formik={formik}
                    text="Save"
                    submittingText="Saving"
                    className={`!rounded-full ${iconBgColor} border-none mt-6`}
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default InsurancePoliciesAction;
