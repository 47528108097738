import moment from 'moment';
import {Attr, BelongsTo, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import {Clause} from 'models/properties/ClauseLibrary';
import Tenancy, {CustomClauses} from 'models/properties/Tenancy';

type ContinuationAction =
  | 'go_periodic'
  | 'start_new_lease'
  | 'renew_current_lease';

@Model()
class TenancyContinuation extends ApplicationRecord {
  static jsonapiType = 'tenancy_continuations';

  @Attr() landlordRequestedAction?: ContinuationAction;
  @Attr() tenantChosenAction?: ContinuationAction;

  @Attr() landlordSignature: string;
  @Attr() messageFromLandlord: string;

  @Attr() newTotalRent: number;
  @Attr() rentChangeEffectiveDate: string;

  @Attr() customClauses: CustomClauses;
  @Attr() clauses: Clause[];

  @Attr() endDate: string;

  @Attr() tenantSignatures: object;
  @Attr({persist: false}) tenantsWhoHaveSigned: string[];

  @Attr({persist: false}) allTenantsSigned: boolean;
  @Attr({persist: false}) processed: boolean;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr({persist: false}) tenancyId: string;

  @BelongsTo('tenancies') tenancy: Tenancy;

  get landlordActionString() {
    if (this.landlordRequestedAction === 'go_periodic') {
      return 'go periodic';
    } else if (this.landlordRequestedAction === 'start_new_lease') {
      return `sign a new fixed term lease until ${moment(this.endDate).format(
        'DD MMMM YYYY',
      )}`;
    } else if (this.landlordRequestedAction === 'renew_current_lease') {
      return `renew the current lease until ${moment(this.endDate).format(
        'DD MMMM YYYY',
      )}`;
    }
  }
}

export default TenancyContinuation;
