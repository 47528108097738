import React, {useCallback, useState} from 'react';

import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import UserAvatar from 'components/user/UserAvatar';
import {Card} from 'components_sb/layout';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import User from 'models/users/User';
import useAuth from 'services/useAuth';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

const EnquiryThreadDetailPage = () => {
  usePageVisit('EnquiryThreadDetailPage');
  const {propertyId, listingId, id} = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {currentUser} = useAuth();
  const [messageText, setMessageText] = useState('');
  const [isSendingNewMessage, setIsSendingNewMessage] = useState(false);

  const {data, error, isLoading} = useQuery(`enquiry-${id}`, async () => {
    const thread = await EnquiryThread.includes({
      enquiry_messages: 'user',
    })
      .order({'enquiry_messages.created_at': 'asc'})
      .find(id);

    return thread.data;
  });

  const sendMessage = async () => {
    setIsSendingNewMessage(true);

    const message = new EnquiryMessage({
      userId: currentUser.id,
      message: messageText,
      enquiryThreadId: id,
    });

    const result = await message.save();
    if (result) {
      setMessageText('');

      const thread = data;
      message.user = new User({
        id: currentUser.id,
        name: currentUser.name,
        avatar: currentUser.avatar,
      });
      message.user.isPersisted = true;
      data.enquiryMessages.push(message);

      queryClient.setQueryData(`enquiry-${id}`, thread);
    }

    setIsSendingNewMessage(false);
  };

  const onSchedulePrivateViewing = useCallback(async () => {
    /**
     * Get the name and email address of the person enquring.
     */
    const {name, email} = data;

    /**
     * Navigate to the new private viewing page, passing the enquiry details
     * as location state so that the form is prefilled.
     */
    navigate(
      `/properties/${propertyId}/listings/${listingId}/viewings/private/new`,
      {
        state: {
          enquiry: {
            name,
            email,
          },
        },
      },
    );
  }, [navigate, propertyId, listingId, data]);

  if (error) {
    return errorViewForError(error);
  }

  return (
    <PageWrapper title={`Listing ${listingId} Enquiry`} backEnabled>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          {data.enquiryMessages.map((msg) => (
            <Card key={msg.id} className="mb-4">
              {msg.user ? (
                <div className="flex justify-start items-center">
                  <div>
                    <UserAvatar user={msg.user} size="12" />
                  </div>
                  <div className="ml-4">
                    {msg.user.id === currentUser.id ? (
                      <h2 className="card-title mb-0 text-brand-850 flex justify-between">
                        You
                      </h2>
                    ) : (
                      <>
                        <h2 className="card-title mb-0 text-brand-850 flex justify-between">
                          {msg.user.name}
                        </h2>
                        <span className="block text-secondary text-sm">
                          Keyhook User
                        </span>
                      </>
                    )}

                    <span className="block text-secondary text-sm">
                      {moment(msg.createdAt).format(DATE_TIME_FORMAT)}
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="card-title mb-0 text-brand-850 flex justify-between">
                    {msg.name}
                  </h2>
                  <span className="block text-secondary text-sm">
                    {msg.email}
                  </span>
                  <span className="block text-secondary text-sm">
                    {moment(msg.createdAt).format(DATE_TIME_FORMAT)}
                  </span>
                </div>
              )}

              {msg.message.split('\n').map((text, index) => (
                <p className="mt-1" key={index}>
                  {text}
                </p>
              ))}
            </Card>
          ))}

          <Card
            title="Reply"
            actions={[
              {
                label: 'Schedule private viewing',
                onClick: onSchedulePrivateViewing,
              },
            ]}>
            <form className="relative flex">
              <textarea
                placeholder="Your message here..."
                className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-300 pl-6 bg-white rounded-full border-gray-200 pr-20"
                value={messageText}
                onChange={(e) =>
                  setMessageText(e.currentTarget.value)
                }></textarea>

              <div className="absolute right-0 items-center inset-y-0 flex">
                <button
                  type="button"
                  disabled={messageText.length === 0 || isSendingNewMessage}
                  onClick={() => sendMessage()}
                  className="inline-flex items-center justify-center rounded-full h-10 w-10 mr-2 transition duration-500 ease-in-out text-white bg-primary hover:bg-primary-focus focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 transform rotate-90">
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                  </svg>
                </button>
              </div>
            </form>
          </Card>
        </>
      )}
    </PageWrapper>
  );
};

export default EnquiryThreadDetailPage;
