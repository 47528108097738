export const isTouched = (formik: any, formikKey: string): boolean => {
  if (formikKey.includes('[')) {
    const regex = /(\w+)\[(\d)\]\.(\w+)/;
    const matches = formikKey.match(regex);
    const key = matches[1];
    const index = matches[2];
    const field = matches[3];

    if (formik.touched[key]) {
      if (formik.touched[key][index]) {
        if (formik.touched[key][index][field]) {
          return !!formik.touched[key][index][field];
        }
      }
    }
    return false;
  } else {
    return !!formik.touched[formikKey];
  }
};

export const errorsFor = (formik: any, formikKey: string) => {
  if (formikKey.includes('[')) {
    const regex = /(\w+)\[(\d)\]\.(\w+)/;
    const matches = formikKey.match(regex);
    const key = matches[1];
    const index = matches[2];
    const field = matches[3];

    if (formik.errors[key]) {
      if (formik.errors[key][index]) {
        if (formik.errors[key][index][field]) {
          return formik.errors[key][index][field];
        }
      }
    }

    return undefined;
  } else {
    return formik.errors[formikKey];
  }
};

export const valueFor = (formik: any, formikKey: string): any => {
  if (formikKey.includes('[')) {
    const regex = /(\w+)\[(\d)\]\.(\w+)/;
    const matches = formikKey.match(regex);
    const key = matches[1];
    const index = matches[2];
    const field = matches[3];

    if (formik.values[key]) {
      if (formik.values[key][index]) {
        if (formik.values[key][index][field]) {
          return formik.values[key][index][field];
        }
      }
    }
    return undefined;
  } else {
    return formik.values[formikKey];
  }
};
