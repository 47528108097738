import {ReactNode} from 'react';

import {Capacitor} from '@capacitor/core';
import {ArrowLeftIcon, ChevronDownIcon} from '@heroicons/react/outline';
import _ from 'lodash';
import {HiArrowLeft} from 'react-icons/hi';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import {Button, ResponsiveActionSelect} from 'components_sb/buttons';
import {Title} from 'components_sb/typography';
import {Action} from 'types/actions';

export interface HeaderButton {
  text: string;
  href?: string;
  onClick?: any;
  bgColor?: string;
}

const Header = ({
  title,
  actions,
  backEnabled = false,
}: {
  title: string;
  actions?: Action[];
  showButtonsAsDropDown?: boolean;
  dropdownTitle?: string;
  backEnabled?: boolean;
  condensed?: boolean;
}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const goBack = () => {
    // Remove the scroll position if you go back.
    // Doesnt make sense to restore it when going forward again to that route..
    if (Capacitor.isNativePlatform()) {
      sessionStorage.removeItem(pathname);
    }
    navigate(-1);
  };

  const stickyClasses = Capacitor.isNativePlatform() ? `sticky top-0 z-10` : '';

  return (
    <header
      data-tour="page-header"
      className={`w-full bg-white border-brand-50 border-b-2 ${stickyClasses}`}
      id="page-header">
      <div className="flex justify-between gap-x-6 items-center max-w-7xl mx-auto py-3 lg:py-6 px-6 lg:px-8">
        <div className="flex justify-start items-center gap-4">
          {backEnabled && (
            <Button
              category="secondary"
              size="sm"
              format="icon"
              icon={HiArrowLeft}
              mode="manual"
              onClick={goBack}
            />
          )}
          <Title level="h1" size="lg" disableMargin>
            {title}
          </Title>
        </div>

        {/* Actions */}
        {actions && <ResponsiveActionSelect actions={actions} justify="end" />}
      </div>
    </header>
  );
};

export default Header;
