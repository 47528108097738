/**
 * Determines whether the provided MIME type is an image.
 * @param mimeType A MIME type string.
 * @returns Whether the provided MIME type is an image.
 */
const mimeTypeIsImage = (mimeType: string): boolean =>
  [
    'image/jpeg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/tiff',
    'image/tiff',
    'image/x-icon',
    'image/svg+xml',
  ].includes(mimeType);

export default mimeTypeIsImage;
