import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {MiddlewareStack} from 'spraypaint';

import useLocalUserSettings from 'hooks/useLocalUserSettings';
import ApplicationRecord from 'models/ApplicationRecord';
import useAuth from 'services/useAuth';

const Context = createContext({
  initialised: false,
  reset: null,
});

interface SpraypaintMiddlewareProvider {
  (props: {children: ReactNode}): JSX.Element;
}

export const SpraypaintMiddlewareProvider: SpraypaintMiddlewareProvider = ({
  children,
}) => {
  const [initialised, setInitialised] = useState(false);
  const {activeAccountRole} = useLocalUserSettings();
  const {currentUser, logOutUser} = useAuth();

  /**
   * Update the middleware to include the currently set user details.
   */
  const update = useCallback(() => {
    /**
     * De-initialise temporarily when the current user has just been set, but
     * the active account role has not be loaded based on the new current user.
     */
    if (currentUser && !activeAccountRole) {
      setInitialised(false);
      return;
    }
    const middleware = new MiddlewareStack();
    if (currentUser && activeAccountRole) {
      middleware.beforeFilters.push((url, options) => {
        /**
         * Define additional headers.
         */
        const additionalHeaders = {
          'X-USER-TOKEN': currentUser.meta.authenticationToken,
          'X-USER-EMAIL': currentUser.email,
          'X-ACCOUNT-TYPE': activeAccountRole,
        };
        /**
         * Merge existing and additional headers.
         */
        options.headers = {...options.headers, ...additionalHeaders};
      });
    }
    middleware.afterFilters.push((response) => {
      if (response.status === 401) {
        logOutUser();
      }
    });
    ApplicationRecord.middlewareStack = middleware;
    setInitialised(true);
  }, [activeAccountRole, currentUser, logOutUser]);

  /**
   * Update the middleware whenever changes are detected to the active
   * account role or the current user data.
   */
  useEffect(() => update(), [activeAccountRole, currentUser, update]);

  /**
   * Resets the initialised state. Note that the middleware will only
   * reinitialised once there is an update to the current user or the
   * current active account role.
   */
  const reset = useCallback(() => {
    setInitialised(false);
  }, [setInitialised]);

  return (
    <Context.Provider value={{initialised, reset}}>
      {initialised && children}
    </Context.Provider>
  );
};

export const useSpraypaintMiddleware = () => {
  const value = useContext(Context);
  return value;
};
