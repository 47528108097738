import {tryLoadAndStartRecorder} from '@alwaysmeticulous/recorder-loader';
import {Capacitor} from '@capacitor/core';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {setBackForwardNavigationGestures} from 'capacitor-plugin-ios-webview-configurator';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {createRoot} from 'react-dom/client';

import {TARGET_ENV} from 'globals/app-globals';
import TrackingService from 'services/TrackingService';

import App from './App';
import Providers from './Providers';
import 'models/loadModels';

import 'assets/styles/global.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if (Capacitor.isNativePlatform()) {
  setBackForwardNavigationGestures(true);
}

const initSentry = () => {
  Sentry.init({
    dsn: 'https://2ab6f77375f74b48a9b7d42fc135fef2@o4504233323855872.ingest.sentry.io/4504233326084096',
    integrations: [new BrowserTracing()],
    environment: TARGET_ENV,
    tracesSampleRate: 1.0,
  });
};

const startTrackedApp = async () => {
  // Start the Meticulous recorder before you initialise your app.
  // Note: all errors are caught and logged, so no need to surround with try/catch
  await tryLoadAndStartRecorder({
    projectId: 'fhFk0NQqf0pzUpAHuMQOWePoGqhcxR8wBSlEiMfc',
  });

  initSentry();

  root.render(
    <Providers>
      <App />
    </Providers>,
  );
};

if (
  !Capacitor.isNativePlatform() &&
  ['staging', 'development'].includes(TARGET_ENV)
) {
  startTrackedApp();
} else {
  if (['production', 'staging'].includes(TARGET_ENV)) {
    initSentry();
  }

  if (TARGET_ENV === 'production') {
    // Dont track unauthenticated users
    // Dont track when ghosting a user
    const userJson = localStorage.getItem('current_user');
    const ghosting = localStorage.getItem('ghosting');
    const isGhostPage = window.location.pathname.includes('ghost');

    if (userJson && !ghosting && !isGhostPage) {
      LogRocket.init('4rny14/keyhook-app');
      setupLogRocketReact(LogRocket);

      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });

      const user = JSON.parse(userJson);
      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,
      });
    }
  }

  TrackingService.init();

  root.render(
    <Providers>
      <App />
    </Providers>,
  );
}
