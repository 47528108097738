import {useCallback, useContext, useMemo} from 'react';

import {HiOutlineClock, HiOutlinePencil, HiOutlineTrash} from 'react-icons/hi';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import ChatableMessages from 'components/chat/ChatableMessages';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {OBFS} from 'constants/onboarding-flow-steps';
import Property from 'models/properties/Property';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';
import {FullScreenLoaderContext} from 'providers/FullScreenLoader';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {saveResource} from 'utilities/SpraypaintHelpers';

const PendingTenancyCard = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy: Tenancy;
}) => {
  const queryClient = useQueryClient();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const navigate = useNavigate();

  const fullScreenLoader = useContext(FullScreenLoaderContext);

  const onEditTenancy = useCallback(async () => {
    fullScreenLoader.activate();

    /**
     * Set the current onboaridng step to the first step in the
     * flow that the user initially followed.
     */
    property.setOnboardingStep(
      tenancy.isNew ? OBFS.ListingPropertyType : OBFS.MigrateRentInformation,
    );

    /**
     * Save the onboarding step on the property.
     */
    if (await saveResource(property)) {
      fullScreenLoader.deactivate();
      localStorage.setItem('new-property-id', property.id);
      queryClient.invalidateQueries(['property']);
      navigate('/properties/new');
    }
  }, [fullScreenLoader, navigate, property, tenancy, queryClient]);

  const destroyPendingTenancy = useCallback(async () => {
    const result = await tenancy.destroy();
    if (result) {
      await queryClient.invalidateQueries([
        'property',
        {id: property.id, context: 'detail-page'},
      ]);
      toast.success('The tenancy was successfully deleted!');
    } else {
      toast.error('This tenancy cannot be deleted as it is already active.');
    }
  }, [property, tenancy, queryClient]);

  const confirmDestroyPendingTenancy = useCallback(() => {
    if (
      property &&
      tenancy &&
      (tenancy.status === TenancyStatus.Pending || tenancy.isDraft)
    ) {
      setConfirmationOptions({
        title: 'Delete Tenancy',
        message:
          'Are you sure you want to delete this tenancy? If you do this in error you will have to remake it.',
        buttonTitle: 'Delete',
        action: destroyPendingTenancy,
        color: 'error',
      });
    } else {
      toast.error('This tenancy cannot be deleted as it is already active.');
    }
  }, [destroyPendingTenancy, property, setConfirmationOptions, tenancy]);

  const shouldRender = useMemo(
    () => property && tenancy && tenancy.status === TenancyStatus.Pending,
    [property, tenancy],
  );

  return !shouldRender ? null : (
    <Card title="Pending Tenancy" icon={HiOutlineClock}>
      <Paragraph>Your tenant is yet to accept your Keyhook tenancy.</Paragraph>

      <Paragraph>
        Modify this tenancy by clicking the buttons below. The tenancy cannot be
        modified once your tenant has accepted.
      </Paragraph>

      <div className="flex flex-row flex-wrap gap-x-4 mb-6">
        <div>
          <Button
            label="Edit tenancy"
            icon={HiOutlinePencil}
            category="primary"
            size="sm"
            mode="manual"
            onClick={onEditTenancy}
            fillWidth={false}
          />
        </div>
        <div>
          <Button
            label="Delete tenancy"
            icon={HiOutlineTrash}
            category="danger"
            size="sm"
            mode="manual"
            onClick={confirmDestroyPendingTenancy}
            fillWidth={false}
          />
        </div>
      </div>

      <ChatableMessages
        chatableId={tenancy.id}
        chatableType="Tenancy"
        isLandlord={true}
      />
    </Card>
  );
};

export default PendingTenancyCard;
