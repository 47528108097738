import bankAccountValidator from '@fnzc/nz-bank-account-validator';
import {Formik, Form} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import {InputField, SubmitButton, DOBField} from 'components/forms_fields';
import RenterProfile from 'models/users/RenterProfile';

const RenterProfileForm = ({
  model,
  submitHandler,
}: {
  model: RenterProfile;
  submitHandler: any;
}) => {
  const schema = Yup.object({
    birthDate: Yup.string()
      .test('is-valid-format', 'This is not a valid Date', (value) =>
        moment(value, 'DD/MM/YYYY').isValid(),
      )
      .test('is-not-too-old', 'This is not a valid Age', (val) =>
        moment(val, 'DD/MM/YYYY').isSameOrAfter(moment('1900-01-01')),
      )
      .test('is-not-too-young', 'This is not a valid Age', (val) =>
        moment(val, 'DD/MM/YYYY').isSameOrBefore(
          moment().subtract({years: 16}),
        ),
      )
      .required()
      .nullable()
      .optional()
      .label('Date Of Birth'),
    phoneNumber: Yup.string().min(7).required().label('Phone Number'),
  });

  if (model.isPersisted) {
    // @ts-ignore
    schema['bankAccountName'] = Yup.string().required().label('Account Name');

    // @ts-ignore
    schema['bankAccountNumber'] = Yup.string()
      .required()
      .test('is-valid-format', 'This is not a valid NZ number', (value) => {
        if (!value) return false;

        const parts = value.split('-');
        if (parts.length === 4) {
          return bankAccountValidator.isValidNZBankNumber(
            parts[0],
            parts[1],
            parts[2],
            parts[3],
          );
        } else {
          return false;
        }
      })
      .label('Account Number');
  }

  return (
    <Formik
      initialValues={{
        birthDate: model.birthDate,

        phoneNumber: model.phoneNumber || '',
        bankAccountName: model.bankAccountName || null,
        bankAccountNumber: model.bankAccountNumber || null,
      }}
      onSubmit={submitHandler}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}>
      {(formik) => (
        <Form>
          <div>
            <DOBField formik={formik} label="Date of Birth" name="birthDate" />

            <InputField
              labelProps={{
                title: 'Phone number',
              }}
              name="phoneNumber"
              formik={formik}
              placeholder="E.g. 027 123 4567"
              autoCapitalize="none"
            />
            <p className="text-secondary text-sm mt-1">
              Your phone number is only used for Tenancy Services paperwork.
            </p>

            {model.isPersisted && (
              <div>
                <strong className="block mt-4">Bank Account Information</strong>
                <p className="text-secondary text-sm">
                  This is the account we will pay your bond refunds to.
                </p>

                <InputField
                  labelProps={{
                    title: 'Account name',
                  }}
                  name="bankAccountName"
                  formik={formik}
                  placeholder="E.g. John Smith"
                  autoCapitalize="words"
                />
                <InputField
                  labelProps={{
                    title: 'Account number',
                  }}
                  name="bankAccountNumber"
                  formik={formik}
                  placeholder="E.g. 02-1234-5678900-00 include dashes"
                  autoCapitalize="words"
                />
              </div>
            )}

            <SubmitButton
              formik={formik}
              text="Save Profile"
              submittingText="Saving"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RenterProfileForm;
