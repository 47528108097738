import {Attr, BelongsTo, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import EnquiryThread from 'models/listings/EnquiryThread';
import User from 'models/users/User';

@Model()
class EnquiryMessage extends ApplicationRecord {
  static jsonapiType = 'enquiry_messages';

  @Attr() email: string;
  @Attr() name: string;

  @Attr() message: string;

  @Attr() enquiryThreadId: string;
  @Attr() userId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('enquiry_threads') enquiryThread: EnquiryThread;
  @BelongsTo('users') user: User;
}

export default EnquiryMessage;
