import React from 'react';

import {FormikHelpers, useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {Button} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {Paragraph} from 'components_sb/typography';
import Tenancy from 'models/properties/Tenancy';
import TenancyInvite from 'models/properties/TenancyInvite';

type FormValues = {
  email: string;
  name: string;
};

const NewTenancyInviteForm = ({tenancy}: {tenancy: Tenancy}) => {
  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const invite = new TenancyInvite(formValues);
    invite.tenancyId = tenancy.id;

    const result = await invite.save();
    if (result) {
      toast.success('Tenancy invitation has been sent successfully!');
      actions.resetForm();
    } else {
      for (const key of Object.keys(invite.errors)) {
        const message = invite.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const formik = useFormik<FormValues>({
    initialValues: {email: '', name: ''},
    onSubmit: handleSubmit,
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required().label('Email Address'),
      name: Yup.string().required().label('Name'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });
  return (
    <div>
      <Paragraph>
        Use the form below to invite other named tenants to join the tenancy on
        Keyhook. These should only be tenants who have signed the current lease.
      </Paragraph>

      <TextField
        name="name"
        label="Name"
        placeholder="The name of the tenant"
        form={formik}
        mode="formik"
      />

      <TextField
        name="email"
        label="Email Address"
        placeholder="The email of the tenant"
        form={formik}
        mode="formik"
        type="email"
      />

      <div className="mt-4">
        <Button
          mode="formik"
          form={formik}
          label="Invite Tenant"
          loadingLabel="Inviting Tenant"
          size="base"
          category="primary"
        />
      </div>
    </div>
  );
};

export default NewTenancyInviteForm;
