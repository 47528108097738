import {useCallback} from 'react';

import * as Sentry from '@sentry/react';
import {sentenceCase} from 'change-case';
import {useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {Button} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {AuthFormLayout} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import useAuth from 'services/useAuth';
import {usePageVisit, useTitle} from 'utilities/hooks';

/**
 * A set of form error message mappings.
 */
interface ErrorMessageMapping {
  /**
   * The keyword to find within the 'reason' to activate the mapping.
   */
  keyword: string;
  /**
   * The message that replaces the 'reason' returned for the error.
   */
  message: string;
}

/**
 * Key/value pairings of a form field name to a set of form error
 * message mappings.
 */
interface ErrorMessageMappings {
  [key: string]: ErrorMessageMapping[];
}

/**
 * Define mappings here to override the 'reason' string for the error
 * messages for particular fields.
 */
const ERROR_MESSAGE_MAPPINGS: ErrorMessageMappings = {
  email: [
    {
      keyword: 'not found',
      // Prevent username enumeration by showing a success message if the email is invalid.
      message: 'The confirmation email has been sent to your email address.',
    },
  ],
};

interface PasswordResetFormData {
  email: string;
}

const ForgotPasswordPage = () => {
  useTitle('Forgot Password');
  usePageVisit('ForgotPasswordPage');

  const {userIsLoggedIn, forgotPass} = useAuth();

  const navigate = useNavigate();

  if (userIsLoggedIn) {
    navigate('/');
  }

  const showGenericError = useCallback(() => {
    toast.error(
      `There was an issue resetting your password. Please try again later or contact us for support.`,
    );
    // Errors only need to be logged to Sentry if not caused by a user/field error
    Sentry.captureMessage('Error sending password reset email');
  }, []);

  const handleFormSubmit = useCallback(
    async (formData: PasswordResetFormData, actions: any) => {
      let response;
      try {
        // Attempt the password reset request
        response = await forgotPass(formData);
      } catch (error) {
        console.log('error', error);
        // Error performing the registration request
        showGenericError();
        // Reset the submitting state
        actions.setSubmitting(false);
        return;
      }

      // Request performed successfully
      const {responseObject, status} = response;

      // Request was performed successfully but the server returned errors
      if (![200, 201].includes(status)) {
        // Initial error response object (before message mappings applied)
        const {errors} = responseObject;

        // Server returned error messages to display
        if (errors) {
          // Replace any matched errors with messages from the defined mapping
          for (const [fieldName, reasons] of Object.entries(errors)) {
            const mappings = ERROR_MESSAGE_MAPPINGS[fieldName] ?? [];
            errors[fieldName] = (reasons as string[]).map(
              (reason: string) =>
                mappings.find(({keyword}: {keyword: string}) =>
                  reason.includes(keyword),
                ).message ?? `${sentenceCase(fieldName)} reason`,
            );
          }

          // Set error messages on the applicable fields
          for (const fieldName in errors) {
            const messages = errors[fieldName];
            for (const message of messages) {
              actions.setFieldError(fieldName, message);
            }
          }
        }

        // No specific errors to display were returned by the server
        else {
          showGenericError();
        }
      }
      // Reset the submitting state
      actions.setSubmitting(false);
    },
    [forgotPass, showGenericError],
  );

  const form = useFormik({
    onSubmit: handleFormSubmit,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <AuthFormLayout
      title="Forgot Your Password?"
      description="Fill in the form below to have a reset link sent to your email address.">
      <form className="flex flex-col items-center gap-y-8">
        <div className="w-full flex flex-col gap-y-8">
          <TextField
            name="email"
            label="Email"
            type="email"
            size="base"
            mode="formik"
            form={form}
            placeholder="Enter your email address here..."
            required
          />
        </div>
        <Button
          label="Send reset link"
          category="primary"
          size="base"
          mode="formik"
          form={form}
          loadingLabel="Sending reset link..."
        />
      </form>
      <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
        <Link to="/login">Back to login page</Link>
        <Link to="https://keyhook.com/contact?_gl=1*1hnaab6*_ga*MjAyOTQwMjM1NS4xNjczMjEwMDg0*_ga_5NQ88EK4DT*MTY3MzgzMjQ5Ny4xMS4xLjE2NzM4MzI3MjUuNTcuMC4w">
          Having trouble resetting your password?
        </Link>
      </span>
    </AuthFormLayout>
  );

  // return (
  //   <div className="max-w-md mx-auto pl-8 pr-8 lg:pl-24 lg:pr-24 mb-24 lg:mb-32 border-3 border-gray-200 my-20 rounded-xl flex justify-center">
  //     <div className="min-w-[300px]">
  //       <h2 className="mt-8 mb-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
  //         Forgot Your Password
  //       </h2>
  //       <p>Fill in the form to have a reset link sent to your email.</p>
  //       <div className="mt-4 mb-8">
  //         <Formik
  //           initialValues={{
  //             email: '',
  //           }}
  //           validationSchema={Yup.object().shape({
  //             email: Yup.string().email().required(),
  //           })}
  //           onSubmit={handleSubmit}>
  //           {(formik) => (
  //             <Form>
  //               <InputField
  //                 placeholder="Email address"
  //                 formik={formik}
  //                 name="email"
  //                 type="email"
  //                 label="Email Address"
  //                 autoComplete="email"
  //                 className="!rounded-full"
  //               />

  //               <SubmitButton
  //                 formik={formik}
  //                 color="primary"
  //                 block
  //                 text="Send reset link"
  //                 submittingText="Submitting"
  //                 className="rounded-full mt-4"
  //               />
  //               <a
  //                 className="text-sm text-secondary block mt-2"
  //                 href="https://keyhook.com/contact">
  //                 I can't recover my account using this page
  //                 <span className="inline-block ml-1">&rarr;</span>
  //               </a>
  //             </Form>
  //           )}
  //         </Formik>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default ForgotPasswordPage;
