import {FunctionComponent, useMemo} from 'react';

import clsx from 'clsx';
import moment from 'moment';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import {Button} from 'components_sb/buttons';
import PrivateViewing from 'models/listings/PrivateViewing';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {saveResource} from 'utilities/SpraypaintHelpers';

interface PrivateViewingRowProps {
  privateViewing: PrivateViewing;
}

const PrivateViewingRow: FunctionComponent<PrivateViewingRowProps> = ({
  privateViewing,
}) => {
  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  const st = moment(privateViewing.startsAt);
  const et = moment(privateViewing.endsAt);

  const isCancelled = useMemo(
    () => privateViewing.cancelledAt,
    [privateViewing.cancelledAt],
  );

  const isConfirmed = useMemo(
    () => privateViewing.confirmedAt,
    [privateViewing.confirmedAt],
  );

  const setPrivateViewingAsCancelled = async () => {
    /**
     * Set the viewing as cancelled rather than destroying.
     */
    privateViewing.assignAttributes({
      cancelledAt: moment().toISOString(),
    });

    /**
     * Save the changes to the private viewing.
     */
    if (
      await saveResource(privateViewing, {
        showErrorToast: false,
      })
    ) {
      toast.success('The private viewing has been cancelled.');
      queryClient.invalidateQueries(`renter-private-viewings`);
    } else {
      toast.error('Sorry, there was an error cancelling your private viewing.');
    }
  };

  const cancelPrivateViewing = () => {
    setConfirmationOptions({
      title: 'Cancel private viewing',
      message: 'Are you sure you want to cancel this private viewing?',
      color: 'error',
      buttonTitle: 'Cancel',
      action: setPrivateViewingAsCancelled,
    });
  };

  const setPrivateViewingAsConfirmed = async () => {
    /**
     * Set the viewing as confirmed.
     */
    privateViewing.assignAttributes({
      confirmedAt: moment().toISOString(),
    });

    /**
     * Save the changes to the private viewing.
     */
    if (
      await saveResource(privateViewing, {
        showErrorToast: false,
      })
    ) {
      toast.success('You have confirmed the private viewing!');
      queryClient.invalidateQueries(`renter-private-viewings`);
    } else {
      toast.error('Sorry, there was an error confirming your private viewing.');
    }
  };

  const confirmPrivateViewing = () => {
    setConfirmationOptions({
      title: 'Confirm private viewing',
      message:
        'Please only confirm if you are absolutely sure you will be able to make it to the scheduled viewing time.',
      color: 'success',
      buttonTitle: 'Confirm',
      action: setPrivateViewingAsConfirmed,
    });
  };

  return (
    <div
      key={privateViewing.id}
      className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
      <div className="flex flex-row justify-start items-center">
        <div>
          <span className="font-medium">
            {st.format('dddd DD MMMM hh:mm a')} - {et.format('hh:mm a')}
          </span>
          <p className="text-gray-500">
            {privateViewing.listing.streetAddress},{' '}
            {privateViewing.listing.suburb}, {privateViewing.listing.city}
          </p>
          {isCancelled && (
            <div className="flex flex-row">
              <div
                className={clsx(
                  'flex flex-row items-center gap-x-2',
                  'rounded-lg my-2',
                  'text-red-500 font-bold',
                )}>
                <span>CANCELLED</span>
              </div>
            </div>
          )}
          {isConfirmed && (
            <div className="flex flex-row">
              <div
                className={clsx(
                  'flex flex-row items-center gap-x-2',
                  'rounded-lg my-2',
                  'text-green-500 font-bold',
                )}>
                <span>CONFIRMED</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full md:w-auto flex flex-col md:flex-row gap-4">
        <Button
          label="View listing"
          category="secondary"
          size="base"
          mode="link"
          linkTo={`/listings/${privateViewing.listing.publicId}`}
        />
        {!isCancelled && !isConfirmed && (
          <>
            <Button
              label="Confirm"
              category="primary"
              size="base"
              mode="manual"
              onClick={confirmPrivateViewing}
            />
            <Button
              label="Cancel"
              category="danger"
              size="base"
              mode="manual"
              onClick={cancelPrivateViewing}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PrivateViewingRow;
