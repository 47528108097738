import {
  Dispatch,
  FunctionComponent,
  ReactHTMLElement,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {
  HiArrowDown,
  HiOutlineArrowLeft,
  HiOutlineArrowRight,
  HiOutlineFastForward,
} from 'react-icons/hi';
import {IconType} from 'react-icons/lib';
import {useInView} from 'react-intersection-observer';
import {useQueryClient} from 'react-query';
import {useResizeDetector} from 'react-resize-detector';
import {useNavigate} from 'react-router';

import {Button} from 'components_sb/buttons';
import {OBFS} from 'constants/onboarding-flow-steps';
import {TARGET_ENV} from 'globals/app-globals';
import useNativeInsets from 'hooks/useNativeInsets';
import useScrollPosition from 'hooks/useScrollPosition';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Property from 'models/properties/Property';
import useAuth from 'services/useAuth';
import {saveResource} from 'utilities/SpraypaintHelpers';

interface OnClickFunction {
  (): void | Promise<void>;
}

/**
 * Config for an individual button.
 */
interface ButtonConfig {
  label?: string;
  icon?: IconType;
  disabled?: boolean;
  loading?: boolean;
  onClick: OnClickFunction;
}

/**
 * Config for both previous and next buttons.
 */
interface ButtonsConfig {
  /**
   * The previous button uses the base button config, but has the additional
   * option of setting onClick to 'auto' for automatic handling of going to the
   * previous step.
   */
  previous?: Omit<ButtonConfig, 'onClick'> & {
    onClick?: OnClickFunction | 'auto';
  };
  skip?: ButtonConfig;
  next: ButtonConfig;
}

interface ContextValue {
  setButtonsConfig: (config: ButtonsConfig) => void;
}

const Context = createContext<ContextValue>({} as ContextValue);

interface OnboardingFlowNavigationProps {
  children: ReactNode;
  property: Property;
}

/**
 * Since the first two onboarding steps (add property and select
 * onboarding type) are handled from the property detail page, we
 * need to offset the step number by 2 so that the third step
 * appears to the user as if it is the first onboarding step.
 */
const STEP_OFFSET = 2;

const DEFAULT_BUTTONS_CONFIG: ButtonsConfig = {
  previous: {
    onClick: 'auto',
  },
  skip: null,
  next: {
    disabled: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => {},
  },
};

const OnboardingFlowNavigation: FunctionComponent<
  OnboardingFlowNavigationProps
> = ({children, property}) => {
  const [buttonsConfig, setButtonsConfig] = useState<ButtonsConfig>(
    DEFAULT_BUTTONS_CONFIG,
  );

  /**
   * Revert to the default config when the current onboarding step changes.
   */
  // useEffect(() => {
  //   setButtonsConfig(DEFAULT_BUTTONS_CONFIG);
  // }, [property.lastOnboardingStepCompleted]);

  /**
   * Handle moving the Customerly widget above the onboarding
   * flow navigation bar.
   */
  useEffect(() => {
    setTimeout(() => {
      const {customerly} = window as any;
      if (customerly) {
        customerly.update({
          position: {
            desktop: {
              bottom: 115,
              side: 25,
            },
          },
        });
      }
    }, 500);

    return () => {
      const {customerly} = window as any;
      if (customerly) {
        customerly.update({
          position: {
            desktop: {
              bottom: 25,
              side: 25,
            },
          },
        });
      }
    };
  }, []);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /**
   * Handle automatic navigation to the previous step.
   */
  const goToPreviousStep = useCallback(async () => {
    /**
     * If the user is in an onboarding flow with a current listing, then
     * we can't allow them to go back to the step where they choose their
     * onboarding type. Instead, we need to send them to the property detail
     * page.
     */
    if (
      property.hasCurrentListing &&
      property.previousOnboadingStep === OBFS.SelectOnboardingType
    ) {
      navigate(`/properties/${property.id}`, {replace: true});
      return;
    }

    /**
     * Set the current step to the previous step.
     */
    property.setOnboardingStep(property.previousOnboadingStep);

    /**
     * Save the changes to the property.
     */
    if (!(await saveResource(property))) {
      return;
    }

    /**
     * Invalidate the query cache for the property detail page.
     */
    queryClient.invalidateQueries([
      'property',
      {id: property.id, context: 'detail-page'},
    ]);

    /**
     * Update the data for the property in the query cache.
     */
    queryClient.setQueryData(
      ['property', {id: property.id, context: 'onboarding-flow'}],
      property,
    );
  }, [property, queryClient, navigate]);

  /**
   * The current step number to be presented to the user.
   * Note that due to the step offset, this will not reflect the actual number.
   */
  const currentStepNumber = useMemo(
    () => property.currentOnboardingStepNumber - STEP_OFFSET,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [property.lastOnboardingStepCompleted],
  );

  /**
   * The number of steps that are estimated to be remaining in the onboarding flow.
   * Note that due to the step offset, this will not reflect the actual number.
   */
  const estimatedRemainingSteps = useMemo(
    () => {
      return property.estimatedRemainingOnboardingSteps;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [property.lastOnboardingStepCompleted],
  );

  /**
   * The estimated total number of steps in the onboarding flow, evaluated by adding
   * the current step number with the estimated remaining steps.
   * Note that due to the step offset, this will not reflect the actual number.
   */
  const estimatedTotalSteps = useMemo(
    () => currentStepNumber + estimatedRemainingSteps,
    [currentStepNumber, estimatedRemainingSteps],
  );

  /**
   * Evaluate the progress of the onboarding flow as a percentage.
   */
  const percentageCompleted = useMemo(
    () => (currentStepNumber / estimatedTotalSteps) * 100,
    [currentStepNumber, estimatedTotalSteps],
  );

  /**
   * When any of the buttons are set with a loading state, all other buttons
   * should be disabled (e.g. disabling the back and skip buttons while a step
   * is being submitted.
   */
  const actionBeingPerformed = useMemo(
    () =>
      buttonsConfig.previous?.loading ||
      buttonsConfig.skip?.loading ||
      buttonsConfig.next?.loading,
    [buttonsConfig],
  );

  /**
   * Interceptor for setting the buttons config to add default
   * values if some options have not been provided.
   */
  const handleSetButtonsConfig = useCallback(
    (newButtonsConfig: ButtonsConfig) => {
      setButtonsConfig({
        previous: newButtonsConfig.previous ?? DEFAULT_BUTTONS_CONFIG.previous,
        skip: newButtonsConfig.skip ?? DEFAULT_BUTTONS_CONFIG.skip,
        next: newButtonsConfig.next,
      });
    },
    [],
  );

  /**
   * The context value to be provided to the hook below.
   */
  const contextValue = useMemo<ContextValue>(
    () => ({
      setButtonsConfig: handleSetButtonsConfig,
    }),
    [handleSetButtonsConfig],
  );

  const scrollPosition = useScrollPosition('root-scroll-container');

  const {height: containerHeight, ref: contentContainerRef} =
    useResizeDetector();
  const navigationBarRef = useRef<HTMLDivElement>();
  const bottomOfStepRef = useRef<HTMLSpanElement>();

  const bottomOfStepIntersectionObserver = useInView({
    /**
     * We need to specify a margin on the root container so that inView
     * is only set to true once the bottom of the step has passed the
     * height of the onbaording flow navigation bar.
     */
    rootMargin: `0px 0px -${navigationBarRef.current?.clientHeight ?? 0}px 0px`,
  });

  // Use `useCallback` so we don't recreate the function on each render
  const setBottomOfStepRefs = useCallback(
    (node: HTMLSpanElement) => {
      bottomOfStepRef.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      bottomOfStepIntersectionObserver.ref(node);
    },
    [bottomOfStepRef, bottomOfStepIntersectionObserver],
  );

  /**
   * The vertical position of the fixed bar relative to the screen. This
   * changes once scrolling past the bottom of the step to simulate a
   * 'sticky' bottom element.
   * TODO: Debounce
   */
  const barPositionY = useMemo(() => {
    if (!bottomOfStepRef.current || !bottomOfStepIntersectionObserver.inView) {
      return 0;
    } else {
      return (
        window.innerHeight -
        bottomOfStepRef.current.getBoundingClientRect().bottom -
        navigationBarRef.current?.clientHeight
      );
    }
    /**
     * We want to listen to changes on the scroll position and height of the
     * container, but we don't care about the actual value these.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scrollPosition,
    containerHeight,
    bottomOfStepRef,
    bottomOfStepIntersectionObserver,
  ]);

  const {currentUser} = useAuth();

  const showHelp = useCallback(async () => {
    const customerly = (window as any).customerly;
    if (Capacitor.isNativePlatform() || !customerly) {
      const params = new URLSearchParams();
      params.set('user_id', currentUser.id);
      params.set('name', currentUser.name);
      params.set('email', currentUser.email);
      params.set('env', TARGET_ENV);
      let url = `https://livesupport.keyhook.com?${params.toString()}`;
      url = url + currentUser.roles.map((role) => `&roles=${role}`).join('');
      await Browser.open({url: url});
    } else {
      (window as any).customerly.open();
    }
  }, [currentUser]);

  const insets = useNativeInsets();

  const mdBreakpoint = useTailwindBreakpoint('md');

  return (
    <Context.Provider value={contextValue}>
      <div
        className={clsx(
          'flex-1 w-full h-full',
          'overflow-y-auto',
          'flex flex-col',
        )}>
        {/* Main content container */}
        <div
          ref={contentContainerRef}
          className="flex-1 bg-white flex flex-col"
          style={{
            marginBottom: navigationBarRef.current?.clientHeight ?? 0,
          }}>
          {/* Step content */}
          {children}

          {/* Invisible element for tracking the bottom of the step */}
          <span id="step-content-bottom" ref={setBottomOfStepRefs} />
        </div>

        {/* Flow navigation bar */}
        <div
          ref={navigationBarRef}
          className={clsx(
            'w-full',
            'bg-brand-50',
            'flex flex-col',
            'items-center',
            'fixed left-0',
          )}
          style={{
            bottom: barPositionY,
            /**
             * In the native app we need to add some padding to the bottom so the content is within
             * the safe area (on web there should be no padding).
             */
            paddingBottom: Capacitor.isNativePlatform()
              ? Capacitor.getPlatform() === 'ios'
                ? // The bottom insets on iOS are huge, so we need to decrease it a bit to avoid the nav bar appearing too large.
                  '20px'
                : insets.bottom
              : 0,
          }}>
          {/* Progress bar container */}
          <div className={clsx('h-2 w-full relative', 'bg-brand-75')}>
            {/* Progress bar */}
            <div
              className={clsx(
                'bg-green-500',
                'rounded-r-full',
                'h-full',
                'transition-all duration-500',
              )}
              style={{width: `${percentageCompleted}%`}}
            />
          </div>

          {/* Flow navigation bar content */}
          <div
            className={clsx(
              'relative',
              'w-full max-w-7xl mx-auto',
              'flex flex-row',
              'items-center',
              'gap-x-2',
              'lg:gap-x-6',
              'px-2 md:px-6 lg:px-8',
              'py-2 md:py-4 lg:px-6',
            )}>
            {/* Previous step button */}
            <div>
              <Button
                label={buttonsConfig.previous.label ?? 'Back'}
                icon={buttonsConfig.previous.icon ?? HiOutlineArrowLeft}
                onClick={
                  buttonsConfig.previous.onClick === 'auto'
                    ? goToPreviousStep
                    : buttonsConfig.previous.onClick
                }
                disabled={
                  actionBeingPerformed ||
                  (buttonsConfig.previous.onClick !== 'auto' &&
                    (buttonsConfig.previous.disabled ?? false))
                }
                loading={buttonsConfig.previous.loading ?? false}
                category="secondary"
                size={mdBreakpoint ? 'base' : 'sm'}
                mode="manual"
              />
            </div>

            {/* Middle content */}
            <div
              className={clsx(
                'flex-1 flex flex-row items-center gap-x-2',
                'justify-center md:justify-between',
              )}>
              {/* Help button */}
              <div>
                <Button
                  label="Need help?"
                  category="tertiary"
                  size={mdBreakpoint ? 'base' : 'sm'}
                  mode="manual"
                  fillWidth={false}
                  onClick={showHelp}
                />
              </div>

              {/* Scroll down indicator */}
              <div
                className={clsx(
                  'hidden md:flex',
                  'flex-col',
                  'items-center justify-center',
                  'gap-y-2 px-2',
                  'text-brand-500 text-opacity-70',
                  'transition-opacity duration-500',
                  bottomOfStepRef.current &&
                    bottomOfStepIntersectionObserver.inView
                    ? 'opacity-0'
                    : 'opacity-1',
                )}>
                <div className="text-sm">Scroll down for more</div>
                <HiArrowDown className="w-4 h-4 animate-bounce" />
              </div>
            </div>

            <div className="flex flex-row gap-x-2">
              {/* Skip button */}
              {buttonsConfig.skip && (
                <Button
                  label={buttonsConfig.skip.label ?? 'Skip'}
                  icon={buttonsConfig.skip.icon ?? HiOutlineFastForward}
                  onClick={buttonsConfig.skip.onClick}
                  disabled={
                    actionBeingPerformed ||
                    (buttonsConfig.skip.disabled ?? false)
                  }
                  loading={buttonsConfig.skip.loading ?? false}
                  category="secondary"
                  size={mdBreakpoint ? 'base' : 'sm'}
                  mode="manual"
                />
              )}
              {/* Next step button */}
              <Button
                label={buttonsConfig.next.label ?? 'Next'}
                icon={buttonsConfig.next.icon ?? HiOutlineArrowRight}
                onClick={buttonsConfig.next.onClick}
                disabled={
                  actionBeingPerformed || (buttonsConfig.next.disabled ?? false)
                }
                loading={buttonsConfig.next.loading ?? false}
                category="primary"
                size={mdBreakpoint ? 'base' : 'sm'}
                mode="manual"
              />
            </div>
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};

export default OnboardingFlowNavigation;

interface OnboardingFlowNavigationHook {
  (props: {buttonsConfig: ButtonsConfig}): void;
}

export const useOnboardingFlowNavigation: OnboardingFlowNavigationHook = ({
  buttonsConfig,
}) => {
  /**
   * Deconstruct the context value.
   */
  const {setButtonsConfig} = useContext(Context);

  /**
   * Update the buttons config when the buttons config prop changes.
   */
  useEffect(
    () => setButtonsConfig(buttonsConfig),
    [setButtonsConfig, buttonsConfig],
  );
};
