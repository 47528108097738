import {FunctionComponent} from 'react';

import {pick} from 'lodash';
import {
  HiOutlineCurrencyDollar,
  HiOutlineDocumentText,
  HiOutlineSearch,
} from 'react-icons/hi';
import {IoHammerOutline} from 'react-icons/io5';

import Property from 'models/properties/Property';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';
import {pluralize, toCurrency} from 'utilities/StringHelpers';

import RoomInfo from './RoomInfo';

interface StatsCardProps {
  property: Property;
  tenancy?: Tenancy;
  mobile?: boolean;
}

type StatsCardComponent = FunctionComponent<StatsCardProps>;

const StatsCard: StatsCardComponent = ({property, tenancy, mobile}) => {
  const statCards = () => {
    if (
      property &&
      property.tenanciesCount > 0 &&
      tenancy &&
      ![
        TenancyStatus.Draft,
        TenancyStatus.Pending,
        TenancyStatus.AwaitingStartDate,
      ].includes(tenancy.status)
    ) {
      let nextInspectionDate: string;
      let nextInspectionSubtitle: string = null;
      if (tenancy.nextInspectionDate) {
        const today = new Date();
        const inspectionDate = new Date(tenancy.nextInspectionDate);

        const timeDifference = inspectionDate.getTime() - today.getTime();
        const days = Math.floor(Number(timeDifference / (1000 * 3600 * 24)));

        if (days < 0) {
          nextInspectionDate = 'Overdue';
        } else if (days === 0) {
          nextInspectionDate = 'Today';
        } else {
          nextInspectionDate = days.toFixed(0);
          nextInspectionSubtitle = 'Days';
        }
      } else {
        nextInspectionDate = 'Not set';
      }

      // let nextLeaseExpires: string;
      // if (tenancy.endDate) {
      //   const today = new Date();
      //   const leaseDate = new Date(tenancy.endDate);

      //   const timeDifference = leaseDate.getTime() - today.getTime();
      //   nextLeaseExpires = Number(timeDifference / (1000 * 3600 * 24)).toFixed(
      //     0,
      //   );
      // }

      let serviceCount = 0;
      if (tenancy.openServiceRequests.length > 0) {
        serviceCount = tenancy.openServiceRequests.length;
      } else {
        serviceCount = tenancy.serviceRequests.filter(
          (req) =>
            req.status !== 'landlord_rejected' && req.status !== 'completed',
        ).length;
      }

      return (
        <div className="w-full bordered shadow-xl stats mmd:grid-flow-row">
          <div className="stat">
            <div className="stat-title">Rent</div>
            <div className="stat-value text-2xl">
              {toCurrency(tenancy.totalRent)}
            </div>
            <div className="stat-desc">{tenancy.rentalPeriod}</div>
          </div>
          <div className="stat">
            <div className="stat-title">Maintenance Requests</div>
            <div className="stat-value text-2xl">{serviceCount}</div>
            <div className="stat-desc">Open Requests</div>
          </div>
          <div className="stat">
            <div className="stat-title">Next Inspection</div>
            <div className="stat-value text-2xl">
              {nextInspectionDate ? nextInspectionDate : 'N/A'}
            </div>
            <div className="stat-desc">{nextInspectionSubtitle}</div>
          </div>
          {/* <div className="stat">
            <div className="stat-title">Lease Expires</div>
            <div className="stat-value text-2xl">
              {nextLeaseExpires ? nextLeaseExpires : 'Ongoing'}
            </div>
            <div className="stat-desc">{nextLeaseExpires ? 'Days' : null}</div>
          </div> */}
        </div>
      );
    } else {
      return (
        <RoomInfo {...pick(property, ['bedrooms', 'bathrooms', 'garages'])} />
      );
    }
  };

  const mobileStatCards = () => {
    if (
      property &&
      property.tenanciesCount > 0 &&
      tenancy &&
      ![
        TenancyStatus.Draft,
        TenancyStatus.Pending,
        TenancyStatus.AwaitingStartDate,
      ].includes(tenancy.status)
    ) {
      let nextInspectionDate: string;
      if (tenancy.nextInspectionDate) {
        const today = new Date();
        const inspectionDate = new Date(tenancy.nextInspectionDate);

        const timeDifference = inspectionDate.getTime() - today.getTime();
        const days = Math.floor(Number(timeDifference / (1000 * 3600 * 24)));

        if (days < 0) {
          nextInspectionDate = 'Overdue';
        } else if (days === 0) {
          nextInspectionDate = 'Today';
        } else {
          nextInspectionDate = days.toFixed(0) + ' Days';
        }
      }

      let nextLeaseExpires: string;
      if (tenancy.endDate) {
        const today = new Date();
        const leaseDate = new Date(tenancy.endDate);

        const timeDifference = leaseDate.getTime() - today.getTime();
        nextLeaseExpires = Number(timeDifference / (1000 * 3600 * 24)).toFixed(
          0,
        );
      }

      let serviceCount = 0;
      if (tenancy.openServiceRequests.length > 0) {
        serviceCount = tenancy.openServiceRequests.length;
      } else {
        serviceCount = tenancy.serviceRequests.filter(
          (req) =>
            req.status !== 'landlord_rejected' && req.status !== 'completed',
        ).length;
      }

      return (
        <div className="grid grid-cols-2 gap-y-2 gap-x-2">
          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <HiOutlineCurrencyDollar className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Rent
              </span>
              <h3 className="text-md font-semibold">
                {toCurrency(tenancy.totalRent)}
              </h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <IoHammerOutline className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Maintenance
              </span>
              <h3 className="text-md font-semibold">
                {serviceCount} {pluralize(serviceCount, 'Request')}
              </h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <HiOutlineSearch className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Next Inspection
              </span>
              <h3 className="text-md font-semibold">
                {nextInspectionDate ? nextInspectionDate : 'N/A'}
              </h3>
            </div>
          </div>

          <div className="flex justify-start items-center bg-white rounded-full">
            <div className="bg-neutral rounded-full w-8 h-8 flex justify-center items-center">
              <HiOutlineDocumentText className="w-4 h-4 text-white" />
            </div>
            <div className="flex flex-col ml-2 pt-1">
              <span className="text-gray-500" style={{fontSize: 10}}>
                Lease Expires
              </span>
              <h3 className="text-md font-semibold">
                {nextLeaseExpires ? `${nextLeaseExpires} Days` : 'Ongoing'}
              </h3>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <RoomInfo {...pick(property, ['bedrooms', 'bathrooms', 'garages'])} />
      );
    }
  };

  return mobile ? mobileStatCards() : statCards();
};

export default StatsCard;
