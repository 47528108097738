import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import RentalApplication from 'models/listings/RentalApplication';

@Model()
class RentalApplicationApplicant extends ApplicationRecord {
  static jsonapiType = 'rental_application_applicants';

  @Attr() name: string;
  @Attr() email: string;
  @Attr() dateOfBirth: string;
  @Attr() weeklyIncome: number;

  @Attr() idType: string;
  @Attr() idNumber: string;
  @Attr() idExtraInfo: string;

  @Attr() rentalApplicationId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('rental_applications') rentalApplication: RentalApplication;
}

export default RentalApplicationApplicant;
