const ListingTags = [
  {
    label: 'Indoor Amenities',
    options: [
      {label: 'Furnished', value: 'furnished'},
      {label: 'High Water Pressure', value: 'high_water_pressure'},
      {label: 'Bath', value: 'bath'},
      {label: 'Washer', value: 'washer'},
      {label: 'Dryer', value: 'dryer'},
      {label: 'Dishwasher', value: 'dishwasher'},
      {label: 'Storage Space', value: 'storage_space'},
    ],
  },
  {
    label: 'Outdoor Amenities',
    options: [
      {label: 'Lawn/Garden', value: 'lawn_garden'},
      {label: 'No Lawn/Garden', value: 'no_lawn_garden'},
      {label: 'Lawn Maintenance Included', value: 'lawn_maintenance_included'},
      {label: 'Private Outdoor Space', value: 'private_outdoor_space'},
      {label: 'Pool', value: 'pool'},
      {label: 'Spa Pool', value: 'spa_pool'},
      {label: 'Clothesline', value: 'clothesline'},
      {label: 'Solar Energy', value: 'solar_energy'},
    ],
  },
  {
    label: 'Heating & Cooling',
    options: [
      {label: 'Air Conditioning', value: 'air_conditioning'},
      {label: 'Central Air', value: 'central_air'},
      {label: 'Electric Heating', value: 'electric_heating'},
      {label: 'Gas Heating', value: 'gas_heating'},
      {label: 'Electric Hot Water', value: 'electric_hot_water'},
      {label: 'Gas Hot Water', value: 'gas_hot_water'},
    ],
  },
  {
    label: 'Views & Sun',
    options: [
      {label: 'City View', value: 'city_view'},
      {label: 'Garden View', value: 'garden_view'},
      {label: 'Park View', value: 'park_view'},
      {label: 'Water View', value: 'water_view'},
      {label: 'Morning Sun', value: 'morning_sun'},
      {label: 'Afternoon Sun', value: 'afternoon_sun'},
    ],
  },
  {
    label: 'Building Amenities',
    options: [
      {label: 'Elevator', value: 'elevator'},
      {label: 'Doorman', value: 'doorman'},
      {label: 'Laundry (Shared)', value: 'laundry_shared'},
      {label: 'Gym', value: 'gym'},
    ],
  },
  {
    label: 'Parking',
    options: [
      {label: 'Off Street Parking', value: 'off_street_parking'},
      {label: 'Street Parking', value: 'street_parking'},
      {label: 'Paid Parking', value: 'paid_parking'},
      {label: 'E.V. Charging', value: 'ev_charging'},
    ],
  },
  {
    label: 'Other',
    options: [
      {label: 'New Build', value: 'new_build'},
      {label: 'Recently Renovated', value: 'recently_renovated'},
      {label: 'No Bond Required', value: 'no_bond_required'},
      {label: 'Landlord Nearby', value: 'landlord_nearby'},
      {label: 'Caul de saq', value: 'caul_de_saq'},
    ],
  },
];

export default ListingTags;
