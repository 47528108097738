import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import User from 'models/users/User';

@Model()
class UserBlock extends ApplicationRecord {
  static jsonapiType = 'user_blocks';

  @Attr() blockedUserId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('users') user: User;
  @BelongsTo('users') blockedUser: User;
}

export default UserBlock;
