import React, {FunctionComponent} from 'react';

import clsx from 'clsx';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';

interface StatCardProps {
  label: string;
  helpText?: string;
  value: string | number;
  blur?: boolean;
}

const StatCard: FunctionComponent<StatCardProps> = ({
  label,
  helpText,
  value,
  blur = false,
}) => {
  return (
    <div
      className={clsx(
        'flex-1',
        'relative',
        'flex flex-col',
        'items-center justify-center',
        'gap-y-2 lg:gap-y-4',
        'px-8 py-4 lg:px-10 lg:py-8',
        'mb-6',
        'bg-white',
        'rounded-xl',
        'border-2 border-brand-50',
      )}>
      {/* Label */}
      <div
        className={clsx(
          'text-brand-850 font-semibold text-base flex text-center relative',
        )}>
        {label}
        {/* Help text */}
        {helpText && (
          <div className="h-full absolute right-0 translate-x-full flex items-center">
            <HelpTextPresenter>{helpText}</HelpTextPresenter>
          </div>
        )}
      </div>
      {/* Value */}
      <div
        className={clsx(
          'font-semibold',
          'text-3xl',
          'text-brand-850',
          'opacity-70',
          blur && 'blur-md select-none',
        )}>
        {value}
      </div>
    </div>
  );
};

export default StatCard;
