import React from 'react';

import {PlayIcon} from '@heroicons/react/solid';

import {useLongPress} from 'hooks/useLongPress';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';

const DotIndicator = ({
  mediaItem,
}: {
  mediaItem: InspectionAction | InspectionItemAttachment;
}) => {
  if (mediaItem.isType('inspection_actions')) {
    return (
      <div className="w-2 h-2 rounded-full absolute top-4 left-4 bg-warning"></div>
    );
  } else if (mediaItem.isType('inspection_item_attachments')) {
    const media = mediaItem as InspectionItemAttachment;
    if (media.notes && media.notes.length > 0) {
      return (
        <div className="w-2 h-2 rounded-full absolute top-4 left-4 bg-info"></div>
      );
    }
  }

  return null;
};

const VideoIcon = ({onClick}: {onClick: () => void}) => (
  <PlayIcon
    onClick={onClick}
    className="w-[40px] h-[40px] lg:w-[100px] lg:h-[100px] text-white absolute top-[20px] left-[20px] lg:top-[50px] lg:left-[50px] opacity-50 hover:opacity-100"
  />
);

const defaultFunc = () => {
  'noop';
};

const InspectionMediaPreview = ({
  source,
  onClick,
  onLongPress,
}: {
  source: InspectionItemAttachment | InspectionAction;
  onClick: () => void;
  onLongPress?: () => void;
}) => {
  const clickProps = useLongPress(onClick, onLongPress || defaultFunc, 400);

  return (
    <div className="relative">
      <img
        {...clickProps}
        className="bg-black inline-block h-[80px] w-[80px] lg:h-[200px] lg:w-[200px] object-cover cursor-pointer rounded-lg"
        src={source.attachmentThumbnail || source.attachment}
      />
      <DotIndicator mediaItem={source} />
      {source.attachmentMimeType &&
        source.attachmentMimeType.includes('video') && (
          <VideoIcon onClick={onClick} />
        )}
    </div>
  );
};

export default InspectionMediaPreview;
