import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Expense from 'models/payments/Expense';
import LandlordPayment from 'models/payments/LandlordPayment';

@Model()
class LandlordPaymentExpense extends ApplicationRecord {
  static jsonapiType = 'landlord_payment_expenses';

  @Attr({persist: false}) amount: number;
  @Attr({persist: false}) paymentDate: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr({persist: false}) expenseId: string;
  @Attr({persist: false}) landlordPaymentId: string;

  @BelongsTo('expenses') expense: Expense;
  @BelongsTo('landlord_payments') landlordPayment: LandlordPayment;
}

export default LandlordPaymentExpense;
