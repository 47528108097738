import {useCallback, useEffect} from 'react';

import dot from 'dot-object';
import {FormikErrors, FormikValues, useFormik} from 'formik';
import {isEmpty} from 'lodash';
import {toast} from 'react-toastify';
import scrollparent from 'scrollparent';

/**
 * Returns an array of "names" in dot notation for each of the fields
 * that have errors. The error object provided by format is in the form
 * of a nested object, however the "name" property that gets set on the
 * field elements is in dot notation.
 */
const getFieldNames = (errors: FormikErrors<FormikValues>): string[] =>
  Object.keys(dot.dot(errors));

/**
 * Wrap a form in this component and provide it the Formik instance to
 * enable additional help when form errors occur, including:
 * - Scrolling to the first field that has an error.
 * - Showing a toast when there a fields that have errors.
 */
interface FormErrorHelpersHook {
  (
    form: ReturnType<typeof useFormik>,
    options?: {
      enableToast: boolean;
      enableScroll: boolean;
    },
  ): void;
}

const useFormErrorHelpers: FormErrorHelpersHook = (
  form,
  options = {enableToast: true, enableScroll: true},
) => {
  /**
   * Handles showing the error toast.
   */
  const handleToast = useCallback(
    (totalErrors: number) => {
      /**
       * Only handle if the option has been enabled.
       */
      if (options.enableToast) {
        /**
         * Determine if there are multiple errors.
         */
        const multiple = totalErrors > 1;

        /**
         * Show a toast with the number of form errors that
         * require resolution. Use singular language if there
         * is only one error.
         */
        toast.error(
          `There ${
            multiple ? `are ${totalErrors} issues` : `is an issue`
          } in the form. Please resolve ${
            multiple ? 'these' : 'this'
          } before continuing.`,
        );
      }
    },
    [options.enableToast],
  );

  /**
   * Handles scrolling to the first field that has an error.
   */
  const handleScroll = useCallback(
    (fieldNames: string[]) => {
      /**
       * Only handle if the option has been enabled.
       */
      if (options.enableScroll) {
        let elements = fieldNames.reduce<HTMLElement[]>(
          (accumulator, fieldName) => {
            /**
             * Find the element with the given field name property.
             */
            const element = document.querySelector(
              `input[name='${fieldName}']`,
            );

            /**
             * Handle no element being found.
             */
            if (!element) {
              console.warn('No form field element found for:', fieldName);
              return accumulator;
            }

            /**
             * Add the found element to the accumulator.
             */
            return [...accumulator, element as HTMLElement];
          },
          [],
        );

        /**
         * The errors returned by Formik are typically in alphabetical
         * order and not necessarily the order of the elements in the
         * page, so we need to order them by their position within their
         * container.
         */
        elements = elements.sort((elementA, elementB) => {
          const topA = elementA.getBoundingClientRect().top;
          const topB = elementB.getBoundingClientRect().top;
          /**
           * Element A is higher in the page than element B.
           */
          if (topA < topB) {
            return -1;
          }

          /**
           * Element B is higher in the page than element A.
           */
          if (topA > topB) {
            return 1;
          }

          /**
           * Elements A and B have the same vertical position.
           * (most likely are being shown in a row).
           */
          return 0;
        });

        /**
         * We only need the first element, as this represents the first
         * field vertically on the screen that has an error.
         */
        const topElement = elements[0];

        /**
         * Find the nearest scrollable parent element (we don't want to
         * scroll on the window because the fields may be within a nested
         * scrollable element, i.e. a modal).
         */
        const scrollContainerElement = scrollparent(topElement);

        /**
         * Since the top element we want to scroll to may not be directly
         * contained within the scroll container (and so we can't use
         * the offsetTop property of the top element), we need to perform
         * some calculations to determine the position to scroll to.
         */
        const evaluateScrollPosition = () => {
          /**
           * Find the Y position of the scroll container in the window.
           * (this doesn't change when the content is scrolled)
           */
          const scrollContainerElementY =
            scrollContainerElement.getBoundingClientRect().y;

          /**
           * Find the distance currently scrolled within the scroll container.
           */
          const distanceScrolled = scrollContainerElement.scrollTop;

          /**
           * Find the Y position of the top element within the window.
           */
          const topElementY = topElement.getBoundingClientRect().y;

          /**
           * Add an offset to account for field labels.
           */
          const offet = 50;

          /**
           * Evaluate the position.
           */
          return (
            distanceScrolled + topElementY - scrollContainerElementY - offet
          );
        };

        /**
         * Evaluate the position and scroll to the field with the error
         * within the scrollable container.
         */
        scrollContainerElement.scrollTo({
          top: evaluateScrollPosition(),
          behavior: 'smooth',
        });
      }
    },
    [options.enableScroll],
  );

  useEffect(() => {
    /**
     * No action required if there are no errors.
     */
    if (isEmpty(form.errors)) {
      return;
    }

    /**
     * Get the field names in dot notation for each of the
     * fields that have errors.
     */
    const fieldNames = getFieldNames(form.errors);

    /**
     * Handle showing the toast.
     */
    handleToast(fieldNames.length);

    /**
     * Handle scrolling to the first field with an error.
     */
    handleScroll(fieldNames);

    /**
     * Listen for changes on the errors object.
     */
  }, [form.errors, handleScroll, handleToast]);
};

export default useFormErrorHelpers;
