import React from 'react';

import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import {toCurrency} from 'utilities/StringHelpers';

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
);

type AccountingStat = {
  label: string;
  value: number;
  chartLineColour: string;
  chartFillColour: string;
  chartData: number[];
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  tooltips: {
    enabled: false,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        display: false,
      },
      display: false,
      ticks: {
        display: false,
      },
    },

    y: {
      grid: {
        display: false,
      },

      display: false,
      title: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

const AccountingStats = ({stats}: {stats: AccountingStat[]}) => {
  const isMobile = !useTailwindBreakpoint('sm');

  const constructChartData = (stat: AccountingStat) => {
    return {
      labels: stat.chartData.map(() => ''),
      datasets: [
        {
          fill: 'start',
          backgroundColor: stat.chartFillColour,
          borderColor: stat.chartLineColour,
          tension: 0.3,
          borderWidth: 2,
          data: stat.chartData,
        },
      ],
    };
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-full">
        <div className="-mx-2 md:flex mb-6">
          {stats.map((stat, index) => (
            <div className="w-full md:w-1/3 px-2" key={index}>
              <div className="rounded-lg shadow-sm mb-4">
                <div className="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                  <div className="px-3 pt-4 pb-5 md:pt-8 md:pb-10 text-center relative">
                    <h4 className="text-sm uppercase text-gray-500 leading-tight">
                      {stat.label}
                    </h4>
                    <h3 className="text-3xl text-gray-700 font-semibold leading-tight my-3">
                      {toCurrency(stat.value)}
                    </h3>
                  </div>
                  <div className="absolute bottom-0 inset-x-0">
                    <Line
                      height={isMobile ? 40 : 60}
                      data={constructChartData(stat)}
                      options={chartOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountingStats;
