import {WhereClause} from 'spraypaint';

import {AdvancedListingsSearchFormValues} from 'constants/listings-search';

const constructWhereClauseFromFilters = (
  filters: AdvancedListingsSearchFormValues,
): WhereClause => {
  const whereClause = {} as WhereClause;

  /**
   * Function for determining if a filter has been set.
   */
  const hasSetFilter = (key: keyof AdvancedListingsSearchFormValues) =>
    key in filters && filters[key] !== undefined;

  /**
   * Location filter
   */
  if (hasSetFilter('locations')) {
    whereClause.location = filters.locations
      .map((locationString) => locationString.replace(', ', '-'))
      .join(',');
  }

  /**
   * Property type filter
   */
  if (hasSetFilter('propertyTypes')) {
    whereClause.propertyType = filters.propertyTypes;
  }

  /**
   * Weekly rent filter
   */
  if (hasSetFilter('weeklyRent')) {
    const [min, max] = filters.weeklyRent;
    whereClause.rentAmount = {
      gte: min,
      lte: max,
    };
  }

  /**
   * Bedrooms filter
   */
  if (hasSetFilter('bedrooms')) {
    const [min, max] = filters.bedrooms;
    whereClause.bedrooms = {
      gte: min,
      lte: max,
    };
  }

  /**
   * Bathrooms filter
   */
  if (hasSetFilter('bathrooms')) {
    const [min, max] = filters.bathrooms;
    whereClause.bathrooms = {
      gte: min,
      lte: max,
    };
  }

  /**
   * Garages filter
   */
  if (hasSetFilter('garages')) {
    const [min, max] = filters.garages;
    whereClause.garages = {
      gte: min,
      lte: max,
    };
  }

  /**
   * Tenant count filter
   */
  if (hasSetFilter('tenants')) {
    whereClause.maxTenants = {
      gte: filters.tenants,
    };
  }

  /**
   * Allowed pet types filter
   */
  if (hasSetFilter('allowedPetTypes')) {
    whereClause.allowedPetTypes = {
      eq: filters.allowedPetTypes,
    };
  }

  /**
   * Smoking allowed filter
   */
  if (hasSetFilter('smokingAllowed')) {
    whereClause.smokingAllowed = {
      eq: filters.smokingAllowed,
    };
  }

  // TODO: Start date
  // if (query.has('availableFrom')) {
  //   clauses.startDate = {gte: query.get('availableFrom')};
  // }

  // TODO: Tags
  // if (query.has('tags')) {
  //   clauses.tags = {eq: query.get('tags').split(',')};
  // }

  return whereClause;
};

export default constructWhereClauseFromFilters;
