import authenticate from './authenticate';
import {Provider} from './providers';
import useAuthScripts from './useAuthScripts';

/**
 * Export the available providers defined in the enum as a type.
 */
export type SocialAuthProviderType = Provider;

export default {
  Provider,
  useAuthScripts,
  authenticate,
};
