import moment from 'moment';

export const differenceFromToday = (date: string): number => {
  const today = new Date();
  const otherDate = new Date(date);

  const time_difference = otherDate.getTime() - today.getTime();
  return Math.round(time_difference / (1000 * 3600 * 24));
};

export const taxYearDates = () => {
  const currentYear = new Date().getFullYear();
  const startDate = moment(`${currentYear - 1}-04-01`);
  const endDate = moment(`${currentYear}-03-31`);

  return {
    startDate,
    endDate,
  };
};

export const timeSince = (date: Date): string => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = 'y';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'M';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'd';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = 'h';
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = 'm';
          } else {
            interval = 0;
            intervalType = 'now';
          }
        }
      }
    }
  }

  // if (interval > 1 || interval === 0) {
  //   intervalType += 's';
  // }
  if (interval === 0) {
    return intervalType;
  }
  return interval + ' ' + intervalType;
};

const publicHolidays = new Set([
  '2023-01-01',
  '2023-01-02',
  '2023-01-03',
  '2023-02-06',
  '2023-04-07',
  '2023-04-10',
  '2023-04-25',
  '2023-06-05',
  '2023-06-14',
  '2023-08-23',
  '2023-12-25',
  '2023-12-26',
]);
export const isPublicHoliday = (date: Date): boolean => {
  const dateString = moment(date).format('YYYY-MM-DD');
  return publicHolidays.has(dateString);
};

export const LONG_DATE_TIME_FORMAT = 'ddd DD MMM YYYY, hh:mm a';
export const DATE_TIME_FORMAT = 'DD MMM YYYY, hh:mm a';
export const DATE_FORMAT = 'DD MMM YYYY';
