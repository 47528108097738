import {useCallback} from 'react';

import clsx from 'clsx';
import {useFormik} from 'formik';
import {isEmpty, omit} from 'lodash';
import queryString from 'query-string';
import {HiOutlineSearch} from 'react-icons/hi';
import {useNavigate} from 'react-router';
import {encodeQueryParams} from 'use-query-params';

import BedroomsFilter from 'components/listing/filters/BedroomsFilter';
import PropertyTypesFilter from 'components/listing/filters/PropertyTypesFilter';
import WeeklyRentFilter from 'components/listing/filters/WeeklyRentFilter';
import {Button} from 'components_sb/buttons';
import {
  type BasicListingsSearchFormValues,
  basicEmptyFormValues,
  BasicListingsSearchQueryParamsDefinition,
} from 'constants/listings-search';
import removeUndefinedPropertiesFromObject from 'utilities/remove-undefined-properties-from-object';

import BasicLocationSearchField from './BasicLocationSearchField';

const BasicFilters = () => {
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (formValues: BasicListingsSearchFormValues) => {
      /**
       * Exclude any form values that have not been filled in.
       */
      const filledInFields = removeUndefinedPropertiesFromObject(formValues);

      if (isEmpty(filledInFields)) {
        /**
         * Navigate to the search page without any query params.
         */
        navigate('search');
      } else {
        /**
         * Encode the filled in form values into the query param format
         * for the particular data types defined by the definition object.
         */
        const encodedQueryParams = encodeQueryParams(
          BasicListingsSearchQueryParamsDefinition,
          filledInFields,
        );

        /**
         * Convert the encoded query params object into a string, append it the
         * path, and navigate.
         */
        navigate(`search?${queryString.stringify(encodedQueryParams)}`);
      }
    },
    [navigate],
  );

  const form = useFormik<BasicListingsSearchFormValues>({
    initialValues: basicEmptyFormValues,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const onClickSubmit = useCallback(() => {
    form.submitForm();
  }, [form]);

  return (
    <div
      className={clsx(
        'h-auto mdlg:h-20 xl:h-24',
        'flex-col mdlg:flex-row',
        'rounded-3xl mdlg:rounded-full',
        'bg-white w-full',
        'max-w-lg mdlg:max-w-full',
        'drop-shadow-2xl',
        'flex items-center',
        'gap-x-0 lg:gap-x-2 xl:gap-x-4',
      )}>
      {/* Location search field */}
      <BasicLocationSearchField form={form} name="locations" />

      {/* Property type filter */}
      <PropertyTypesFilter form={form} name="propertyTypes" />

      {/* Weekly rent amount filter */}
      <WeeklyRentFilter form={form} name="weeklyRent" />

      {/* Number of bedrooms filter */}
      <BedroomsFilter form={form} name="bedrooms" />

      {/* Icon search button (for bar mode) */}
      <div className={clsx('hidden mdlg:flex', 'ml-auto', 'px-2 xl:px-3')}>
        <Button
          category="primary"
          size="xl"
          format="icon"
          icon={HiOutlineSearch}
          mode="manual"
          onClick={onClickSubmit}
        />
      </div>

      {/* Normal search button (for column mode) */}
      <div className="flex mdlg:hidden w-full p-6 pt-4">
        <Button
          category="primary"
          size="lg"
          format="standard"
          label="Search"
          fillWidth
          icon={HiOutlineSearch}
          mode="manual"
          onClick={onClickSubmit}
        />
      </div>
    </div>
  );
};

export default BasicFilters;
