import {FunctionComponent, MouseEvent, useMemo, useState} from 'react';

import {
  useFloating,
  useInteractions,
  offset,
  shift,
  useClick,
  useDismiss,
  flip,
} from '@floating-ui/react';
import clsx from 'clsx';
import {HiChevronDown} from 'react-icons/hi';

import {Action, actionIsButton} from 'types/actions';

import FloatingActions from '../FloatingActions/FloatingActions';

interface ActionsDropdownButtonProps {
  label: string;
  actions: Action[];
}

/**
 * TODO: Refactor to:
 * - Use the base button component
 * - Allow passing stylign related base button component props
 */
const ActionsDropdownButton: FunctionComponent<ActionsDropdownButtonProps> = ({
  label,
  actions,
}) => {
  const [floatingActionsOpen, setFloatingActionsOpen] = useState(false);

  /**
   * Create a floating instance.
   */
  const floating = useFloating({
    open: floatingActionsOpen,
    onOpenChange: setFloatingActionsOpen,
    placement: 'top',
    middleware: [
      /**
       * Add a gap between the link and the popover.
       */
      offset(10),

      /**
       * Keep the popover in view of the window along the x-axis.
       */
      shift({
        /**
         * Ensure at least 10px of horizontal padding when shifting.
         */
        padding: 10,
      }),
    ],
  });

  const click = useClick(floating.context);
  const dismiss = useDismiss(floating.context);

  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
  ]);

  /**
   * Intercept the actions provided to close the popover when
   * any button action is clicked.
   */
  const interceptedActions = useMemo(
    () =>
      actions.map((action) =>
        actionIsButton(action)
          ? {
              ...action,
              onClick: (event: MouseEvent<HTMLElement>) => {
                setFloatingActionsOpen(false);
                action.onClick(event);
              },
            }
          : action,
      ),
    [actions],
  );

  return (
    <div className="flex flex-row pt-2">
      <button
        ref={floating.refs.setReference}
        className={clsx(
          'flex-1',
          'flex',
          'flex-row',
          'gap-x-1',
          'justify-center',
          'items-center',
          'font-medium',
          'whitespace-nowrap',
          'text-center',
          'leading-none',
          'transition-all',
          'duration-200',
          'scale-100',
          'active:scale-95',
          'ring-0',
          'focus:ring-2',
          'border-2',
          'select-none',
          'bg-transparent hover:bg-brand-100 hover:bg-opacity-20',
          'border-brand-500 hover:border-brand-600',
          'text-brand-500',
          'ring-brand-200',
          'text-xs',
          'h-8',
          'min-h-8',
          'max-h-8',

          // Standard format
          'pl-4',
          'pr-3',
          'rounded-lg',
        )}
        {...getReferenceProps()}>
        <span className="flex">{label}</span>
        <HiChevronDown
          className={clsx(
            'flex',
            'w-4 h-4',
            'transition-transform duration-200',
            floatingActionsOpen ? 'rotate-0' : 'rotate-180',
          )}
        />
      </button>
      <FloatingActions
        floating={floating}
        isOpen={floatingActionsOpen}
        actions={interceptedActions}
        {...getFloatingProps()}
      />
    </div>
  );
};

export default ActionsDropdownButton;
