import {Model, Attr, HasMany, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import ExternalListingAd from 'models/listings/ExternalListingAd';
import ListingPhoto from 'models/listings/ListingPhoto';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import OpenHome from 'models/listings/OpenHome';
import RentalApplication from 'models/listings/RentalApplication';
import Property from 'models/properties/Property';

import PrivateViewing from './PrivateViewing';

export enum ListingStatus {
  Draft = 'draft',
  Active = 'active',
  Paused = 'paused',
  Completed = 'completed',
  PendingApproval = 'pending_approval',
  Rejected = 'rejected',
  UnderOffer = 'under_offer',
  Withdrawn = 'withdrawn',
}

@Model()
class Listing extends ApplicationRecord {
  static jsonapiType = 'listings';

  @Attr() status: ListingStatus;

  @Attr({persist: false}) streetAddress: string;
  @Attr({persist: false}) suburb: string;
  @Attr({persist: false}) city: string;
  @Attr({persist: false}) region: string;
  @Attr({persist: false}) country: string;

  @Attr() enquiryThreadsCount: number;
  @Attr() listingRentalApplicationsCount: number;
  @Attr() openHomesCount: number;
  @Attr() privateViewingsCount: number;

  @Attr() bathrooms: number;
  @Attr() bedrooms: number;
  @Attr() garages: number;
  @Attr() garageType: string;

  @Attr() propertyType: string;

  @Attr() chattels: object;

  @Attr() rentAmount: number;
  @Attr() bondAmount: number;
  @Attr() rentalPeriod: string;

  @Attr() startDate: string;
  @Attr() endDate: string | undefined;

  @Attr() maxTenants: number;
  @Attr() petsAllowed: boolean;
  @Attr() smokingAllowed: boolean;

  @Attr() allowedPetTypes: string[];

  @Attr() mainImage: string;

  @Attr() title: string | null | undefined;
  @Attr() description: string;

  @Attr() tags: string[];

  @Attr({persist: false}) latitude: string;
  @Attr({persist: false}) longitude: string;

  @Attr({persist: false}) viewCount: number;
  @Attr({persist: false}) listingPhotosCount: number;

  @Attr() propertyId: string;

  @Attr({persist: false}) landlordId: string;

  @Attr({persist: false}) publicId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() chosenExternalListingSites: string[];

  @BelongsTo('properties') property: Property;

  @HasMany('listing_photos') listingPhotos: ListingPhoto[];
  @HasMany('listing_rental_applications')
  listingRentalApplications: ListingRentalApplication[];
  @HasMany('rental_applications') rentalApplications: RentalApplication[];
  @HasMany('open_homes') openHomes: OpenHome[];
  @HasMany('external_listing_ads') externalListingAds: ExternalListingAd[];

  get listingTitle(): string {
    if (this.title && this.title.length > 0) {
      return this.title;
    } else {
      return this.streetAddress;
    }
  }

  /**
   * Indicates whether the listing is in a draft state.
   */
  get isDraft(): boolean {
    return this.status === ListingStatus.Draft;
  }

  /**
   * Indicates whether the listing is currently active.
   */
  get isActive(): boolean {
    return this.status === ListingStatus.Active;
  }

  /**
   * Indicates whether the listing is currently under offer.
   */
  get isUnderOffer(): boolean {
    return this.status === ListingStatus.UnderOffer;
  }

  /**
   * Indicates whether the listing is in one of the post-draft
   * states but is not yet active.
   */
  get isUpcoming(): boolean {
    return [ListingStatus.Paused, ListingStatus.PendingApproval].includes(
      this.status,
    );
  }

  /**
   * Indicates whether the listing is in one of the post-draft
   * states, including being active, but not historical.
   */
  get isCurrent(): boolean {
    return this.isActive || this.isUpcoming || this.isUnderOffer;
  }

  /**
   * Indicates whether the listing is in one of the post-active states.
   */
  get isHistorical(): boolean {
    return [
      ListingStatus.Completed,
      ListingStatus.Rejected,
      ListingStatus.Withdrawn,
    ].includes(this.status);
  }
}

export default Listing;
