import React, {useState} from 'react';

import {BadgeCheckIcon} from '@heroicons/react/outline';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {CountableNumberField, SubmitButton} from 'components/forms_fields';
import PetTypeField from 'components/forms_fields/PetTypeField';
import ActionCard from 'components/property/landlord/profile_blocks/ActionCard';
import ProfileItemModal from 'components/property/landlord/profile_blocks/ProfileItemModal';
import BooleanSelect from 'components_sb/input/GridSelect/BooleanSelect';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {isDefined} from 'utilities/MiscHelpers';

type FormValues = {
  petsAllowed: boolean;
  allowedPetTypes: string[];
  smokersAllowed: boolean;
  maxTenants: number;
};

/*
 * This component is only used for tenancy renewals.
 * It not used in the complete property profile section.
 */

const RequirementsAction = ({
  property,
  storeKey,
}: {
  property: Property;
  tenancy: Tenancy;
  storeKey: string;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    property.assignAttributes(formValues);
    property.setProfileStepAsCompleted(storeKey);

    const result = await property.save();

    if (result) {
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'detail-page'}],
        property,
      );
      toast.success('Property successfully updated!');
      setModalVisible(false);
    } else {
      for (const key of Object.keys(property.errors)) {
        const message = property.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const title = 'Property Requirements';
  const subtitle = 'Set whats allowed in your property.';
  const iconBgColor = 'bg-orange-300';

  return (
    <div>
      <ActionCard
        title={title}
        subtitle={subtitle}
        IconClass={BadgeCheckIcon}
        iconBgColor={iconBgColor}
        onClick={() => setModalVisible(true)}
      />
      <ProfileItemModal
        modalIsOpen={modalVisible}
        setModalIsOpen={setModalVisible}
        title={title}
        subtitle={subtitle}
        IconClass={BadgeCheckIcon}
        bgColor={iconBgColor}
        form={
          <Formik
            initialValues={
              {
                petsAllowed: isDefined(property.petsAllowed)
                  ? property.petsAllowed
                  : null,
                allowedPetTypes: property.allowedPetTypes || [],
                smokersAllowed: isDefined(property.smokersAllowed)
                  ? property.smokersAllowed
                  : null,
                maxTenants: property.maxTenants || 1,
              } as FormValues
            }
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              petsAllowed: Yup.boolean().required().label('Pets Allowed?'),
              allowedPetTypes: Yup.array()
                .optional()
                .nullable()
                .label('Allowed Pet Types')
                .min(0),
              smokersAllowed: Yup.boolean()
                .nullable()
                .required()
                .label('Smokers Allowed?'),

              maxTenants: Yup.number()
                .min(1)
                .max(20)
                .required()
                .label('Max Number of Tenants'),
            })}>
            {(formik) => (
              <Form className="md:h-full flex-1">
                <div className="flex flex-col justify-between h-full">
                  <div className="flex flex-col space-y-8">
                    <BooleanSelect
                      labelProps={{
                        title: 'Are pets allowed?',
                        size: 'xl',
                      }}
                      preset="yes/no"
                      mode="formik"
                      name="petsAllowed"
                      form={formik}
                    />

                    {formik.values.petsAllowed && (
                      <PetTypeField name="allowedPetTypes" form={formik} />
                    )}

                    <BooleanSelect
                      labelProps={{
                        title: 'Is smoking allowed?',
                        size: 'xl',
                      }}
                      preset="yes/no"
                      mode="formik"
                      name="smokersAllowed"
                      form={formik}
                    />

                    <CountableNumberField
                      form={formik}
                      name="maxTenants"
                      labelProps={{
                        title: 'Max tenants',
                        size: 'base',
                      }}
                    />

                    <SubmitButton
                      formik={formik}
                      text="Save"
                      submittingText="Saving"
                      className={`!rounded-full ${iconBgColor} border-none mt-6`}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default RequirementsAction;
