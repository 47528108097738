import React, {useState} from 'react';

import {sortBy} from 'lodash-es';
import moment from 'moment';
import {Link} from 'react-router-dom';

import Action from 'components/inspection/actions/Action';
import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import Inspection from 'models/inspections/Inspection';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const inspectionTitle = (inspection: Inspection): string => {
  let title;
  if (inspection.inspectionPeriodType === 'pre_tenancy') {
    title = 'Pre-tenancy';
  } else if (inspection.inspectionPeriodType === 'final') {
    title = 'Final';
  } else {
    title = 'Routine';
  }

  return title;
};

const InspectionsTable = ({
  inspections,
  clickHandler,
}: {
  inspections: Inspection[];
  clickHandler: (inspectionId: string) => void;
  showCount: boolean;
}) => {
  const sortedInspections: Inspection[] = sortBy(
    inspections,
    (i) => -parseInt(i.id),
  );

  const [actionStates, setActionStates] = useState(
    Object.fromEntries(inspections.map((i) => [i.id, false])),
  );

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    inspection: Inspection,
  ) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'BUTTON') {
      setActionStates((currentStates) => {
        currentStates[inspection.id] = !currentStates[inspection.id];
        return {...currentStates};
      });
    } else {
      clickHandler(inspection.id);
    }
  };

  return (
    <div>
      <div className="overflow-x-auto hidden lg:block">
        <table className="table w-full">
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              <th>Date</th>
              <th>Status</th>
              <th>Done by</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedInspections.map((inspection, index) => {
              return (
                <React.Fragment key={inspection.id}>
                  <tr
                    className="hover cursor-pointer"
                    onClick={(e) => handleRowClick(e, inspection)}>
                    <td>{inspections.length - index}</td>
                    <td>{inspectionTitle(inspection)}</td>
                    <td>
                      {inspection.finalizedDate
                        ? moment(inspection.finalizedDate).format(
                            DATE_TIME_FORMAT,
                          )
                        : moment(inspection.estimatedDate).format(DATE_FORMAT)}
                    </td>
                    <td>{titleize(removeUnderscores(inspection.status))}</td>
                    <td>
                      {inspection.doneBy
                        ? titleize(removeUnderscores(inspection.doneBy))
                        : 'Not set'}
                    </td>
                    {inspection.inspectionActionsCount ? (
                      <td>
                        <button
                          className="btn btn-warning btn-sm"
                          type="button">
                          {inspection.inspectionActionsCount}
                        </button>
                      </td>
                    ) : (
                      <td>0</td>
                    )}
                  </tr>
                  {inspection.inspectionActions.length > 0 && (
                    <tr>
                      {actionStates[inspection.id] && (
                        <td colSpan={6}>
                          {inspection.inspectionActions.map((action) => (
                            <Link
                              to={`inspections/${inspection.id}/actions/${action.id}`}
                              key={action.id}>
                              <Action
                                key={action.id}
                                action={action}
                                onClick={() => {
                                  console.log('');
                                }}
                              />
                            </Link>
                          ))}
                        </td>
                      )}
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="block lg:hidden">
        <ul className="flex flex-col gap-2">
          {sortedInspections.map((inspection) => (
            <MobileListItem
              key={inspection.id}
              link={`inspections/${inspection.id}`}
              title={inspectionTitle(inspection)}
              subtitle={
                <>
                  <span>
                    {inspection.finalizedDate
                      ? moment(inspection.finalizedDate).format(
                          DATE_TIME_FORMAT,
                        )
                      : moment(inspection.estimatedDate).format(DATE_FORMAT)}
                  </span>
                  <br />
                  <span>{titleize(removeUnderscores(inspection.status))}</span>
                  {inspection.doneBy && (
                    <>
                      <br />
                      <span className="text-grey-300">
                        Done by: {titleize(inspection.doneBy)}
                      </span>
                    </>
                  )}
                </>
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default InspectionsTable;
