import {Attr, BelongsTo, HasMany, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import User from 'models/users/User';

@Model()
class OpenHome extends ApplicationRecord {
  static jsonapiType = 'open_homes';

  @Attr() startTime: string;
  @Attr() endTime: string;

  @Attr() notes: string;

  @Attr() openHomeAttendeesCount: number;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() listingId: string;

  @BelongsTo('listings') listing: Listing;
  @HasMany('open_home_attendees') openHomeAttendees: OpenHomeAttendee[];
  @HasMany('users') users: User[];
}

export default OpenHome;
