import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {FormikHelpers, useFormik} from 'formik';
import moment from 'moment';
import {useQuery} from 'react-query';
import {useLocation, useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import {useInterval} from 'usehooks-ts';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {DateField, RadioField, SelectField} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import {InlineError} from 'components_sb/feedback';
import {DatePicker, TextField} from 'components_sb/input';
import HighlightTextAreaField from 'components_sb/input/HighlightTextAreaField/HighlightTextAreaField';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import FormalNotice from 'models/properties/FormalNotice';
import Tenancy from 'models/properties/Tenancy';
import useAuth from 'services/useAuth';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT, isPublicHoliday} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

type FormValues = {
  noticeType: string;
  subject: string;
  body: string;
  endTenancyReasonIndex: string;
  endTenancyDate: string;
  signature: string;
};

const landlordNoticeOptions = {
  General: {
    fourteen_day_notice_to_remedy_tenant_breach:
      '14 Day notice to remedy tenant breach',
    notice_to_end_tenancy_by_mutual_agreement:
      'Notice to end tenancy by mutual agreement',
  },
  'Periodic Tenancies': {
    antisocial_behaviour: 'Notice of antisocial behaviour',
    overdue_rent: 'Notice of overdue rent',
    sixty_three_day_notice_to_end_periodic_tenancy:
      '63 Day notice to end periodic tenancy',
    ninety_day_notice_to_end_periodic_tenancy:
      '90 Day notice to end periodic tenancy',
  },
  'Fixed Term Tenancies': {
    fourteen_day_notice_to_remedy_rent_arrears:
      '14 Day notice to remedy rent arrears',
    sixty_three_day_notice_not_to_continue_fixed_term:
      '63 Day notice not to continue fixed term',
    ninety_day_notice_not_to_continue_fixed_term:
      '90 Day notice not to continue fixed term',
  },
};

const tenantNoticeOptions = {
  General: {
    fourteen_day_notice_to_remedy_landlord_breach:
      '14 Day notice to remedy landlord breach',
  },
  'Periodic Tenancies': {
    twenty_eight_day_notice_to_end_periodic_tenancy:
      '28 Day notice to end periodic tenancy',
  },
  'Fixed Term Tenancies': {
    twenty_eight_day_notice_not_to_continue_fixed_term:
      '28 Day notice not to continue fixed term',
  },
};

const dayMappings = {
  fourteen_day_notice_to_remedy_landlord_breach: 14,
  fourteen_day_notice_to_remedy_tenant_breach: 14,
  // notice_to_end_tenancy_by_mutual_agreement: 1,
  sixty_three_day_notice_to_end_periodic_tenancy: 63,
  ninety_day_notice_to_end_periodic_tenancy: 90,
  twenty_eight_day_notice_to_end_periodic_tenancy: 28,
  fourteen_day_notice_to_remedy_rent_arrears: 14,
  // twenty_eight_day_notice_not_to_continue_fixed_term: 28,
  sixty_three_day_notice_not_to_continue_fixed_term: 63,
  ninety_day_notice_not_to_continue_fixed_term: 90,
} as Record<string, number>;

const endingTenancyOptions = {
  sixty_three_day_notice_to_end_periodic_tenancy: [
    'The owner of the premises requires the premises within 90 days after the termination date (as above) as the principal place of residence (for at least 90 days) for the owner or a member of the owner’s family.',
    'The landlord customarily uses the premises, or has acquired the premises, for occupation by employees of the landlord or by contractors under contracts for services with the landlord. That fact is clearly stated in the tenancy agreement, and the premises are required for that use.',
    'The landlord customarily uses the premises, or has acquired the premises, for occupation by employees of a school board of trustees or by contractors under contracts for services with a school board of trustees. That fact is clearly stated in the tenancy agreement, and the premises are required for that use (this reason only applies if the landlord is the Ministry of Education).',
  ],
  ninety_day_notice_to_end_periodic_tenancy: [
    'The premises are to be put on the market by the owner within 90 days after the termination date (as above) for the purposes of sale or other disposition.',
    'The owner is required, under an unconditional agreement for the sale of the premises, to give the purchaser vacant possession.',
    'The landlord is not the owner of the premises and the landlord’s interest in the premises is due to end.',
    'The landlord or owner has acquired the premises to facilitate the use of nearby land for a business activity. That fact is clearly stated in the tenancy agreement, and the premises are required to be vacant of residential tenants to facilitate that use.',
    'The premises are to be converted into commercial premises for at least 90 days by the landlord or owner.',
    'Extensive alterations, refurbishment, repairs, or redevelopment of the premises are to be carried out by the landlord or owner, and it would not be reasonably practicable for the tenant to live there while the work is being done. The work must begin, or material steps towards it are to be taken, within 90 days after the termination date (as above).',
    'The premises are to be demolished and the demolition is to begin, or material steps towards it are to be taken, within 90 days after the termination date (as above).',
  ],
  twenty_eight_day_notice_to_end_periodic_tenancy: [],
  // twenty_eight_day_notice_not_to_continue_fixed_term: [], // DELIBERATRELY REMOVED
  sixty_three_day_notice_not_to_continue_fixed_term: [
    'The owner of the premises requires the premises within 90 days after the termination date (as above) as the principal place of residence (for at least 90 days) for the owner or a member of the owner’s family.',
    'The landlord customarily uses the premises, or has acquired the premises, for occupation by employees of the landlord or by contractors under contracts for services with the landlord. That fact is clearly stated in the tenancy agreement, and the premises are required for that use.',
    'The landlord customarily uses the premises, or has acquired the premises, for occupation by employees of a school board of trustees or by contractors under contracts for services with a school board of trustees. That fact is clearly stated in the tenancy agreement, and the premises are required for that use (this reason only applies if the landlord is the Ministry of Education).',
  ],
  ninety_day_notice_not_to_continue_fixed_term: [
    'The premises are to be put on the market by the owner within 90 days after the termination date (as above) for the purposes of sale or other disposition.',
    'The owner is required, under an unconditional agreement for the sale of the premises, to give the purchaser vacant possession.',
    'The landlord is not the owner of the premises and the landlord’s interest in the premises is due to end.',
    'The landlord or owner has acquired the premises to facilitate the use of nearby land for a business activity. That fact is clearly stated in the tenancy agreement, and the premises are required to be vacant of residential tenants to facilitate that use.',
    'The premises are to be converted into commercial premises for at least 90 days by the landlord or owner.',
    'Extensive alterations, refurbishment, repairs, or redevelopment of the premises are to be carried out by the landlord or owner, and it would not be reasonably practicable for the tenant to live there while the work is being done. The work must begin, or material steps towards it are to be taken, within 90 days after the termination date (as above).',
    'The premises are to be demolished and the demolition is to begin, or material steps towards it are to be taken, within 90 days after the termination date (as above).',
  ],
  notice_to_end_tenancy_by_mutual_agreement: [],
} as Record<string, string[]>;

const NewFormalNoticePage = () => {
  const {tenancyId} = useParams();

  usePageVisit('NewFormalNoticePage');
  useTitle('Give Notice');

  const {activeAccountRole} = useLocalUserSettings();
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {pathname} = useLocation();

  const noticeOptions = useMemo<Record<string, Record<string, string>>>(
    () =>
      activeAccountRole === 'Landlord'
        ? landlordNoticeOptions
        : tenantNoticeOptions,
    [activeAccountRole],
  );

  const [isAfter5pm, setIsAfter5pm] = useState(false);

  // Update every minute whether or not its after 5pm
  // Notice periods change if its after 5pm.
  useInterval(() => {
    const now = new Date();
    const hour = now.getHours();
    setIsAfter5pm(hour >= 17);
  }, 1000 * 60);

  // Fetch tenancy
  // Used as an authorisation check
  const {isLoading, error} = useQuery(
    `new-notice-tenancy-${tenancyId}`,
    async () => {
      const tenancy = await Tenancy.find(tenancyId);
      return tenancy.data;
    },
  );

  const otherRole = useMemo(
    () => (activeAccountRole === 'Landlord' ? 'tenant(s)' : 'landlord'),
    [activeAccountRole],
  );

  const title = useMemo(() => `Give notice to your ${otherRole}`, [otherRole]);

  const confirmSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    actions.setSubmitting(false);

    setConfirmationOptions({
      title: 'Send this notice',
      message: 'Are you sure you want to send this notice?',
      buttonTitle: 'Send',
      action: () => handleSubmit(values, actions),
      color: 'success',
    });
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    actions.setSubmitting(true);

    const notice = new FormalNotice();
    notice.signature = values.signature;
    notice.subject = values.subject;
    notice.body = values.body;
    notice.noticeType = values.noticeType;
    if (values.endTenancyDate && values.endTenancyDate.length > 0) {
      notice.tenancyEndDate = values.endTenancyDate;
    }
    notice.tenancyId = tenancyId;
    try {
      const result = await notice.save();

      if (result) {
        toast.success('Notice sent successfully');
        const path = pathname.replaceAll('/new', '');
        navigate(path, {replace: true});
      } else {
        toast.error('There was an issue sending the notice');

        for (const key of Object.keys(notice.errors)) {
          const message = notice.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
      }
    } catch (e) {
      toast.error('There was an issue sending the notice, please try again.');
    }

    actions.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      noticeType: '',
      subject: '',
      body: '',
      endTenancyReasonIndex: '0',
      endTenancyDate: null,
      signature: '',
    } as FormValues,
    onSubmit: confirmSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      noticeType: Yup.string().required().label('Notice Type'),
      subject: Yup.string().required().label('Subject'),
      body: Yup.string().required().label('Notice Body'),
      endTenancyReasonIndex: Yup.string()
        .nullable()
        .optional()
        .label('Reason for Ending Tenancy'),
      endTenancyDate: Yup.string()
        .nullable()
        .optional()
        .label('Tenancy End Date'),
      signature: Yup.string().required().label('Signature').min(1),
    }),
  });

  const handleSelectChange = useCallback(
    async (
      type: string,
      endTenancyReasonIndex: string = null,
      endTenancyDate = '',
    ) => {
      if (type && type.length > 0) {
        const response = await fetch(
          `${API_URL}/formal_notices/template.json?notice_type=${type}&tenancy_id=${tenancyId}&tenancy_end_reason=${endTenancyReasonIndex}&tenancy_end_date=${endTenancyDate}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-User-Email': currentUser.email,
              'X-User-Token': currentUser.meta.authenticationToken,
            },
          },
        );

        if (response.ok) {
          const json = await response.json();
          formik.setFieldValue('subject', json.subject);
          formik.setFieldValue('body', json.body);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenancyId, currentUser],
  );

  useEffect(() => {
    const type = formik.values.noticeType;
    if (Object.keys(endingTenancyOptions).includes(type)) {
      if (
        formik.values.endTenancyReasonIndex &&
        formik.values.endTenancyReasonIndex.length > 0 &&
        formik.values.endTenancyDate
      ) {
        handleSelectChange(
          type,
          formik.values.endTenancyReasonIndex,
          formik.values.endTenancyDate,
        );
      } else if (formik.values.endTenancyDate) {
        handleSelectChange(type, null, formik.values.endTenancyDate);
      }
    } else {
      handleSelectChange(type);
    }
  }, [
    formik.values.noticeType,
    formik.values.endTenancyReasonIndex,
    formik.values.endTenancyDate,
    handleSelectChange,
  ]);

  const minNoticeDate = useMemo(() => {
    if (formik.values.noticeType) {
      const dayPeriod = dayMappings[formik.values.noticeType];

      // Friday after 5pm -> notice starts on Tues
      if (isAfter5pm && moment().day() === 5) {
        return moment().add(dayPeriod, 'days').add(4, 'days');
      }

      // If tomorrow is a public holiday, add an extra day
      const tomorrow = moment().add(1, 'days');
      if (isAfter5pm && isPublicHoliday(tomorrow.toDate())) {
        return moment().add(dayPeriod, 'days').add(2, 'days');
      }

      return moment()
        .add(dayPeriod, 'days')
        .add(isAfter5pm ? 2 : 1, 'days');
    } else {
      return null;
    }
  }, [formik.values.noticeType, isAfter5pm]);

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Give Notice" backEnabled>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="Give Notice" backEnabled>
        <Card title={title}>
          <Paragraph>
            Open dialogue should always be your first option when trying to
            remedy tenancy issues. If you haven't already, try reaching out to
            your {otherRole} before issuing any official notices. When
            communicating with your {otherRole}, use Keyhook chat to ensure all
            conversations are recorded securely and available for you to refer
            back to.
          </Paragraph>

          <Paragraph>
            Notice can only be delivered to an email address where that address
            has been provided by the {otherRole} as an address for service in
            the tenancy agreement.
          </Paragraph>

          <Paragraph>
            Select the type of notice you would like to give from the dropdown
            below, we will then load a template for you to fill out and send to
            your {otherRole}.
          </Paragraph>

          <SelectField
            name="noticeType"
            labelProps={{title: 'Notice Type'}}
            formik={formik}>
            <option value="">Select a notice type</option>
            {Object.entries(noticeOptions).map(([value, label]) => (
              <optgroup key={value} label={value}>
                {Object.entries(label).map(([value2, label2]) => (
                  <option key={value2} value={value2}>
                    {label2}
                  </option>
                ))}
              </optgroup>
            ))}
          </SelectField>

          {Object.keys(endingTenancyOptions).includes(
            formik.values.noticeType,
          ) && (
            <div>
              {endingTenancyOptions[formik.values.noticeType].length > 0 && (
                <RadioField
                  name="endTenancyReasonIndex"
                  label="Reason for ending tenancy"
                  formik={formik}
                  options={endingTenancyOptions[formik.values.noticeType].map(
                    (opt, index) => {
                      return {text: opt, value: index.toString()};
                    },
                  )}
                />
              )}

              <div className="mt-4 mb-2">
                {dayMappings[formik.values.noticeType] && (
                  <Paragraph>
                    We've done the math. The last day of this notice period will
                    be <strong>{minNoticeDate.format(DATE_FORMAT)}.</strong>
                  </Paragraph>
                )}
              </div>

              <div className="flex flex-col w-full lg:w-1/3">
                <DatePicker
                  name="endTenancyDate"
                  mode="single"
                  inline={false}
                  form={formik}
                  labelProps={{
                    title: 'Tenancy end date',
                    helpText:
                      'The date the tenancy will end. Keyhook will automatically end your tenancy in the system after this date.',
                  }}
                  disabledDates={
                    activeAccountRole === 'Renter' && minNoticeDate
                      ? [
                          {
                            from: new Date(1900, 1, 1),
                            to: minNoticeDate.toDate(),
                          },
                        ]
                      : []
                  }
                />
              </div>
            </div>
          )}

          {formik.values.noticeType &&
            formik.values.noticeType.length > 0 &&
            formik.values.body &&
            formik.values.body.length > 0 && (
              <div>
                <TextField
                  name="subject"
                  label="Subject"
                  form={formik}
                  mode="formik"
                  placeholder="The subject of the email that will be sent. "
                />
                <HighlightTextAreaField
                  name="body"
                  label="Body"
                  form={formik}
                  mode="formik"
                  placeholder="The notice that will be sent. "
                  highlight={[
                    {highlight: /\[.+\]/gi, className: 'bg-yellow-200'},
                  ]}
                />

                <div className="pl-2 pt-2">
                  <Paragraph>
                    Delivery: email to an email address given as an additional
                    address for service{' '}
                    <small>
                      (*if sent by email after 5pm, allow 1 extra working day
                      from but not including today)
                    </small>
                  </Paragraph>
                  <Paragraph>Date: {moment().format('DD MMMM YYYY')}</Paragraph>
                </div>

                <div className="mt-4">
                  <SignatureModalInput
                    name="signature"
                    mode="manual"
                    onSave={(val) => formik.setFieldValue('signature', val)}
                    signature={formik.values.signature}
                  />

                  <InlineError
                    name="signature"
                    error={formik.errors.signature}
                  />

                  <Paragraph>
                    Your signature will be placed under your name at the bottom
                    of the notice.
                  </Paragraph>
                </div>

                <div className="mt-10">
                  <Button
                    mode="formik"
                    label="Save and send notice"
                    size="base"
                    category="warning"
                    form={formik}
                    loadingLabel="Sending notice..."
                  />
                </div>
              </div>
            )}
        </Card>
      </PageWrapper>
    );
  }
};

export default NewFormalNoticePage;
