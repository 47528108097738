import {AiOutlineWarning} from '@react-icons/all-files/ai/AiOutlineWarning';

import Tenancy from 'models/properties/Tenancy';

const PretenancyInspectionAlert = ({tenancy}: {tenancy?: Tenancy}) => {
  if (tenancy && tenancy.inspections.length > 0) {
    const hasPendingPretenancyInspection =
      tenancy.inspections.filter(
        (i) =>
          i.inspectionPeriodType === 'pre_tenancy' && i.status !== 'complete',
      ).length > 0;

    if (hasPendingPretenancyInspection) {
      return (
        <div className="alert alert-warning shadow-lg mt-2">
          <div>
            <AiOutlineWarning className="w-6 h-6" />
            <span>
              You have a pre tenancy inspection that has yet to be completed, we
              recommend you conduct this inspection as soon as possible.
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default PretenancyInspectionAlert;
