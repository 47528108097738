import clsx from 'clsx';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: {
    main: 'flex items-center',
    line: 'bg-brand-50',
    label: 'text-brand-850 text-base font-medium',
  },

  // Classes based on orientation
  orientation: {
    horizontal: {
      main: {
        base: 'flex-row',
        margin: 'my-6 sm:my-8',
      },
      line: 'h-0.5',
      label: 'px-6',
    },
    vertical: {
      main: {
        base: 'flex-col',
        margin: 'mx-6 sm:mx-8',
      },
      line: 'w-0.5',
      label: 'py-4',
    },
  },
};

interface DividerProps {
  /**
   *  The direction for the divider to extend.
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   *  The label to display within the divider.
   */
  children?: string;
  /**
   *  The position of the label within the divider.
   */
  labelPosition?: 'start' | 'middle' | 'end';
  /**
   * Disables the standard divider gap between items.
   */
  disableMargin?: boolean;
}

interface LineProps {
  /**
   *  The direction for the line to extend.
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   *  Whether the line should grow as a flex item.
   */
  flex: boolean;
}

const Line = ({orientation, flex}: LineProps) => {
  return (
    <div
      className={clsx(
        classes.base.line,
        classes.orientation[orientation].line,
        flex && 'flex-1',
        !flex && (orientation === 'horizontal' ? 'w-6 min-w-6' : 'h-6 min-h-6'),
      )}
    />
  );
};

/**
 *  A subtle spearation for content within a section.
 */
const Divider = ({
  orientation = 'horizontal',
  children = null,
  labelPosition = 'middle',
  disableMargin = false,
}: DividerProps) => {
  return (
    <div
      className={clsx(
        classes.base.main,
        classes.orientation[orientation].main.base,
        !disableMargin && classes.orientation[orientation].main.margin,
      )}>
      <Line orientation={orientation} flex={labelPosition !== 'start'} />
      {children && (
        <>
          <span
            className={clsx(
              classes.base.label,
              classes.orientation[orientation].label,
            )}>
            {children}
          </span>
          <Line orientation={orientation} flex={labelPosition !== 'end'} />
        </>
      )}
    </div>
  );
};

export default Divider;
