import {useState} from 'react';

import moment from 'moment';
import {HiOutlineChat} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';

import LightboxGallery from 'components/common/LightboxGallery';
import UserAvatar from 'components/user/UserAvatar';
import {Card} from 'components_sb/layout';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import ChatMessage from 'models/ChatMessage';
import User from 'models/users/User';
import useAuth from 'services/useAuth';

const ChatableMessages = ({
  chatableId,
  chatableType,
  disableMedia = false,
}: {
  chatableId: string;
  chatableType: string;
  isLandlord?: boolean;
  disableMedia?: boolean;
}) => {
  const {data} = useQuery(
    `${chatableType}-${chatableId}-messages`,
    async () => {
      const messages = await ChatMessage.where({
        chatable_id: chatableId,
        chatable_type: chatableType,
      })
        .order('created_at')
        .includes('user')
        .all();

      return messages.data;
    },
  );
  const queryClient = useQueryClient();

  const isMobile = !useTailwindBreakpoint('sm');

  const {currentUser} = useAuth();

  const [isSendingNewMessage, setIsSendingNewMessage] = useState(false);
  const [messageText, setMessageText] = useState('');

  const sendMessage = async (
    text: string | undefined,
    image: string | undefined,
  ) => {
    setIsSendingNewMessage(true);
    const message = new ChatMessage({
      chatableId,
      chatableType,
      content: text,
    });
    if (image && image.length > 0) {
      message.media = image;
    }

    const result = await message.save();

    if (result) {
      message.user = new User({
        id: currentUser.id,
        name: currentUser.name,
        avatar: currentUser.avatar,
      });

      setMessageText('');

      queryClient.setQueryData(`${chatableType}-${chatableId}-messages`, [
        ...data,
        message,
      ]);
    } else {
      console.log(message.errors);
    }
    setIsSendingNewMessage(false);
  };

  const readImage = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (readerEvent) => {
        const base64 = readerEvent.target.result.toString();

        sendMessage(undefined, base64);
      };

      reader.readAsDataURL(file);
    }
  };

  const hintForType = () => {
    if (chatableType === 'Inspection') {
      return 'Send a message about this inspection below';
    } else if (chatableType === 'ServiceRequest') {
      return 'Send a message about this maintenance request below';
    } else if (chatableType === 'Tenancy') {
      return 'Any issues with the lease can be discussed here.';
    }
  };

  return (
    <>
      <Card title="Messages" icon={HiOutlineChat} subtitle={hintForType()}>
        <div>
          {data?.map((message) => (
            <div key={message.id} className="mb-4">
              <div className="flex justify-start items-start space-x-4">
                <UserAvatar user={message.user} size={isMobile ? 8 : 10} />
                <div>
                  <strong>{message.user.name}</strong>
                  <p className="text-sm text-base-content text-opacity-60">
                    {message.content}
                  </p>
                  {message.media && message.media.length > 0 && (
                    <div className="my-2">
                      <LightboxGallery
                        sources={[
                          {
                            source: message.media,
                            thumbnail: message.mediaPreview,
                            type: 'image',
                          },
                        ]}
                      />
                    </div>
                  )}
                  <p className="text-xs text-base-content text-opacity-60 italic">
                    {moment(message.createdAt).fromNow()}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <form className="relative flex">
          <textarea
            placeholder="Your message here..."
            className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-300 pl-6 bg-gray-100 rounded-full border-gray-200 pr-20"
            value={messageText}
            onChange={(e) => setMessageText(e.currentTarget.value)}></textarea>

          {!disableMedia && (
            <input
              className="hidden"
              type="file"
              id="chat-file-upload"
              accept=".png,.jpeg,.jpg"
              onChange={readImage}
              disabled={isSendingNewMessage}
            />
          )}

          <div className="absolute right-0 items-center inset-y-0 flex">
            {!disableMedia && (
              <label
                htmlFor="chat-file-upload"
                className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6 text-gray-600">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
                </svg>
              </label>
            )}

            <button
              type="button"
              disabled={messageText.length === 0 || isSendingNewMessage}
              onClick={() => sendMessage(messageText, undefined)}
              className="inline-flex items-center justify-center rounded-full h-10 w-10 mr-2 transition duration-500 ease-in-out text-white bg-primary hover:bg-primary-focus focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 transform rotate-90">
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
              </svg>
            </button>
          </div>
        </form>
      </Card>
    </>
  );
};

export default ChatableMessages;
