import {AppleUserData} from './apple';
import {FacebookUserData} from './facebook';
import {GoogleUserData} from './google';

/**
 * The supported social auth providers.
 */
export enum Provider {
  Facebook = 'facebook',
  Google = 'google_oauth',
  Apple = 'apple',
}

/**
 * The objects that may be passed on the onSuccess function.
 */
export type SocialAuthUserData =
  | FacebookUserData
  | GoogleUserData
  | AppleUserData;
