import {useState} from 'react';

import {DocumentDuplicateIcon} from '@heroicons/react/outline';

import TrackingService from 'services/TrackingService';

const CopyButton = ({id}: {id: string}) => {
  const [toolTipOpen, setToolTipOpen] = useState(false);

  const handleClick = () => {
    const el = document.getElementById(id) as HTMLInputElement;
    el.select();
    el.setSelectionRange(0, 99999);

    document.execCommand('copy');

    setTimeout(() => {
      setToolTipOpen(false);
    }, 2500);

    TrackingService.trackEvent(TrackingService.Event.ClickCopyButton, {
      field: id,
    });
  };
  return (
    <>
      <div
        data-tip="Copied to clipboard!"
        className={`tooltip ${
          toolTipOpen ? 'tooltip-open' : ''
        } tooltip-accent`}>
        <button
          className="btn btn-neutral rounded-l-none"
          type="button"
          onClick={handleClick}
          id={`${id}-copy`}>
          <DocumentDuplicateIcon className="w-6 h-6 text-white" />
        </button>
      </div>
    </>
  );
};

export default CopyButton;
